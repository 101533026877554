import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding
} from '@angular/core';
import { EntityMetadata } from '../shared.interface';

@Component({
  selector: 'ease-entity-icon',
  templateUrl: './entity-icon.component.html',
  styleUrls: ['./entity-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * The account (custom) icons don't have extra padding on the sides like the material icons have, hence why the account icons were always smaller by 2 pixels.
 * Since we're now using a specific Entity icon component, we're scaling down the accountSize to closely match the (size-2) look of the overall icon sizing
 */
export class EntityIconComponent implements OnInit {
  @HostBinding('class') hostClass = 'inline-flex flex-none align-middle';
  @Input()
  entity: Pick<EntityMetadata, 'entityType' | 'accountType'>;
  @Input() set size(value: number) {
    this._size = value;
    this.accountSize = Math.round(value * 0.85);
  }
  get size(): number {
    return this._size;
  }
  private _size: number;
  public accountSize: number;

  constructor() {}

  ngOnInit(): void {}
}
