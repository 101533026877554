<div class="group relative my-2 flex flex-col gap-2 py-2 text-gray-600">
  <div class="flex flex-row items-center gap-2">
    <ease-avatar class="self-start" [userId]="note?.createdBy"></ease-avatar>
    <div class="flex flex-1 flex-col gap-1 overflow-hidden">
      <div class="flex flex-row items-center gap-1">
        <span class="flex-1 text-[13px] leading-5">
          <span class="font-bold">{{ users[note?.createdBy]?.name }}</span>
          added a note
        </span>

        <ng-container
          *ngIf="sharedLayoutService.isCompact$ | async as isMobile"
        >
          <button
            *ngIf="
              isMobile.active &&
                (canPin ||
                  canShare ||
                  canReact ||
                  (editable && noteToggle.isHidden));
              else floatingActions
            "
            [matMenuTriggerFor]="noteMenu"
            [satPopoverAnchor]="reactionSelector"
            class="!h-7 !w-7 self-start !rounded-md !border-gray-300"
            mat-icon-button
            mat-stroked-button
          >
            <mat-icon class="mat-icon-18">more_vert</mat-icon>
          </button>
          <mat-menu #noteMenu="matMenu">
            <button *ngIf="canPin" (click)="togglePinned()" mat-menu-item>
              <mat-icon class="mat-icon-18">fiber_pin</mat-icon>
              <span>{{ note?.pinned ? 'Unpin note' : 'Pin note' }}</span>
            </button>
            <button
              *ngIf="canShare"
              (click)="shareNoteLink()"
              (mouseleave)="shareLeave()"
              mat-menu-item
            >
              <mat-icon class="mat-icon-18">share</mat-icon>
              <span>{{ isCopied ? 'Link Copied' : 'Share This Note' }}</span>
            </button>
            <button
              *ngIf="canReact"
              (click)="reactionSelector.toggle()"
              mat-menu-item
            >
              <mat-icon class="mat-icon-18">insert_emoticon</mat-icon>
              <span>Add Reaction</span>
            </button>
            <button
              *ngIf="editable && noteToggle.isHidden"
              (click)="editNote()"
              mat-menu-item
            >
              <mat-icon class="mat-icon-18">edit</mat-icon>
              <span>Edit</span>
            </button>
          </mat-menu>

          <ng-template #floatingActions>
            <div
              *ngIf="
                canPin ||
                canShare ||
                canReact ||
                (editable && noteToggle.isHidden)
              "
              class="absolute top-0 right-0 flex h-[28px] flex-row justify-start rounded border border-solid border-gray-300 bg-gray-100 px-1 text-gray-700 group-hover:visible md:invisible"
              [satPopoverAnchor]="reactionSelector"
            >
              <div
                *ngIf="canPin"
                (click)="togglePinned()"
                [matTooltip]="note?.pinned ? 'Unpin note' : 'Pin note'"
                class="cursor-pointer py-1 px-1"
              >
                <mat-icon
                  class="mat-icon-18"
                  [ngClass]="{ 'text-blue-500': note?.pinned }"
                >
                  fiber_pin
                </mat-icon>
              </div>

              <div
                *ngIf="canShare"
                #shareTooltip
                [matTooltip]="isCopied ? 'Link Copied' : 'Share This Note'"
                (click)="shareNoteLink()"
                (mouseleave)="shareLeave()"
                class="cursor-pointer py-1 px-1"
              >
                <mat-icon class="mat-icon-18"> share </mat-icon>
              </div>

              <div
                *ngIf="canReact"
                (click)="reactionSelector.toggle()"
                matTooltip="Add Reaction"
                class="cursor-pointer py-1 px-1"
              >
                <mat-icon
                  class="mat-icon-18"
                  [ngClass]="{ 'text-blue-500': reactionSelector.isOpen() }"
                >
                  insert_emoticon
                </mat-icon>
              </div>

              <div
                *ngIf="editable && noteToggle.isHidden"
                (click)="editNote()"
                matTooltip="Edit"
                class="cursor-pointer py-1 px-1"
              >
                <mat-icon class="mat-icon-18"> edit </mat-icon>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>

      <div
        class="flex flex-row items-center gap-2"
        *ngIf="note?.entityName && note?.accountType"
      >
        <ease-account-icon
          [size]="14"
          [accountType]="note?.accountType"
        ></ease-account-icon>
        <span class="truncate text-[13px] font-medium leading-5 text-gray-600">
          {{ note?.entityName }}
        </span>
      </div>

      <ease-toggle
        #noteToggle="toggle"
        class="relative rounded bg-white p-2 shadow-md"
        [ngClass]="{
          'border border-solid border-blue-500': note?.$isHighlighted,
          '!bg-orange-50': note?.pinned
        }"
      >
        <div
          *ngIf="
            nowTime &&
            nowTime < note?.createdAt &&
            note?.createdBy !== currentUser
          "
          class="new-badge absolute top-2 right-2 z-1"
        >
          NEW
        </div>

        <ng-container *ngIf="noteToggle.isHidden">
          <div
            *ngIf="
              note?._highlightResult?.body?.matchedWords?.length;
              else defaultMessage
            "
            [innerHTML]="note?._highlightResult?.body?.value"
          ></div>
          <ng-template #defaultMessage>
            <div [easeMarkdown]="note?.body" size="sm"></div>
          </ng-template>
        </ng-container>
        <ease-markdown-form
          *ngIf="!noteToggle.isHidden"
          (save)="saveNote($event); noteToggle.hide()"
          (cancel)="noteToggle.hide(); isEditing = false"
          [allowMentions]="canMention"
          [(ngModel)]="note.body"
          size="sm"
        >
        </ease-markdown-form>
      </ease-toggle>

      <span class="text-xs leading-4 text-gray-600">
        {{ note?.createdAt | amDateFormat: dateFormat }}
      </span>

      <div
        *ngIf="canReact && note?.reactionsArray?.length"
        class="mb-1 flex flex-row gap-1 md:mb-0"
      >
        <div
          *ngFor="let reaction of note?.reactionsArray"
          class="flex cursor-pointer flex-row items-center gap-1 rounded-full bg-gray-200 px-2 text-gray-600"
          [ngClass]="{ '!bg-blue-500 !text-white': reaction.userTriggered }"
          [matTooltip]="reaction.tooltipText"
          (click)="handleReaction(reaction, emoji.getSanitizedData())"
        >
          <ngx-emoji
            #emoji
            [isNative]="true"
            [emoji]="reaction.colons"
            [size]="12"
          ></ngx-emoji>
          <span class="text-xs font-bold">
            {{ reaction?.users?.length }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<sat-popover
  #reactionSelector
  [autoFocus]="false"
  [hasBackdrop]="true"
  horizontalAlign="center"
  verticalAlign="below"
>
  <emoji-mart
    *ngIf="reactionSelector.isOpen()"
    title=""
    emoji=""
    [showPreview]="false"
    color="#2196f3"
    [isNative]="true"
    [darkMode]="false"
    [include]="['search', 'recent', 'people']"
    [showSingleCategory]="true"
    (emojiClick)="addReaction($event); reactionSelector.close()"
  ></emoji-mart>
</sat-popover>
