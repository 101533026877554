import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

import {
  GeocodeRequest,
  TimeZoneRequest
} from '@googlemaps/google-maps-services-js';

import { TimeZoneResponseData } from '@googlemaps/google-maps-services-js/dist/timezone';
import { GeocodeResponseData } from '@googlemaps/google-maps-services-js/dist/geocode/geocode';
import { environment } from '../../environments/environment';
import { BaseHttpService } from './base-http.service';

@Injectable({ providedIn: 'root' })
export class GoogleMapsService extends BaseHttpService {
  constructor(
    public afAuth: AngularFireAuth,
    public http: HttpClient,
    public mdSnackBar: MatSnackBar
  ) {
    super(environment.GOOGLE_MAPS_URI, afAuth, http, mdSnackBar, true);
  }

  getGeoCode(
    params: Partial<GeocodeRequest['params']>
  ): Observable<GeocodeResponseData> {
    return this.get<GeocodeResponseData>(`/geocode/json`, {
      params: {
        ...params,
        key: environment.GOOGLE_MAPS_API_KEY
      }
    });
  }

  getTimezone(
    params: Partial<TimeZoneRequest['params']>
  ): Observable<TimeZoneResponseData> {
    return this.get<TimeZoneResponseData>(`/timezone/json`, {
      params: {
        ...params,
        key: environment.GOOGLE_MAPS_API_KEY
      }
    });
  }
}
