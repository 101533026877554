<ease-toggle #descriptionToggle="toggle">
  <div
    class="overflow-x-scroll 2xl:overflow-visible"
    *ngIf="description && descriptionToggle.isHidden"
    [easeMarkdown]="description"
  ></div>
  <ease-markdown-form
    [allowMentions]="true"
    *ngIf="!descriptionToggle.isHidden"
    (cancel)="descriptionToggle.hide()"
    (save)="saveDescription(); descriptionToggle.hide()"
    [(ngModel)]="description"
    placeholder="Describe your task, @mention users"
  >
  </ease-markdown-form>
  <button
    class="!mt-6 font-medium !text-gray-600"
    [ngClass]="{
      '!mt-0': !description,
      '!hidden': !descriptionToggle.isHidden || isCompleted
    }"
    (click)="descriptionToggle.show()"
    mat-stroked-button
  >
    <mat-icon class="mat-icon-18 mr-2">edit</mat-icon>
    {{ description ? 'Edit' : 'Add' }} Description
  </button>
</ease-toggle>
