import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';

import {
  TypedFormControl,
  TypedFormGroup
} from 'src/app/shared/reactive-forms';
import { ChartOptions } from 'chart.js';
import { CHART_CURRENCY_OPTIONS } from '../../../../shared/charts/charts';
import {
  CustomerAccountCost,
  CustomerBudget
} from '../customer-account-budget.interface';
import { Change } from '../../../../shared/shared.interface';

@UntilDestroy()
@Component({
  selector: 'ease-customer-account-budget',
  templateUrl: './customer-account-budget.component.html',
  styleUrls: ['./customer-account-budget.component.scss']
})
export class CustomerAccountBudgetComponent implements OnInit {
  @Input()
  set allowCustomDateRange(value: boolean) {
    this.createForm();

    this._allowCustomDateRange = !(value === false);

    if (this._allowCustomDateRange) {
      this.settingsForm.controls.customDateRange.enable();
    } else {
      this.settingsForm.controls.customDateRange.disable();
      this.settingsForm.controls.startAt.disable();
      this.settingsForm.controls.endAt.disable();
    }
  }
  get allowCustomDateRange(): boolean {
    return this._allowCustomDateRange;
  }
  @Input()
  currencyCode: string = 'CAD';
  @Input()
  set settings(settings: CustomerBudget) {
    if (settings) {
      this.settingsForm.patchValue(settings);
      this.getOldSettings();
    }
  }
  @Input()
  summary: CustomerAccountCost;
  @Input()
  showSummary: boolean = true;
  @Input() accountType: string;
  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  save: EventEmitter<Change<Partial<CustomerBudget>>> = new EventEmitter<
    Change<Partial<CustomerBudget>>
  >();
  public settingsForm: TypedFormGroup<CustomerBudget>;
  public chartOptions: ChartOptions = Object.assign(
    {},
    CHART_CURRENCY_OPTIONS,
    {
      legend: {
        display: false
      }
    }
  );

  private _allowCustomDateRange: boolean = true;
  public oldSettings: Partial<CustomerBudget>;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.createForm();
    this.getOldSettings();

    this.settingsForm.controls.customDateRange.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(customRangeEnabled => {
        if (customRangeEnabled) {
          this.settingsForm.controls.startAt.enable();
          this.settingsForm.controls.endAt.enable();
        } else {
          this.settingsForm.controls.startAt.disable();
          this.settingsForm.controls.endAt.disable();
        }
      });

    this.settingsForm.controls.budget.valueChanges
      .pipe(
        filter(
          newBudget =>
            this.settingsForm.controls.budget.dirty &&
            !!!this.oldSettings.budget &&
            !!newBudget
        ),
        untilDestroyed(this)
      )
      .subscribe(() => this.settingsForm.controls.enabled.setValue(true));
  }

  getOldSettings() {
    this.oldSettings = this.settingsForm.value;
  }

  createForm() {
    if (!this.settingsForm) {
      this.settingsForm = this.formBuilder.group<
        TypedFormControl<CustomerBudget>
      >({
        enabled: this.formBuilder.control(null),
        budget: this.formBuilder.control(null, {
          validators: [
            Validators.min(1),
            Validators.pattern(/^[0-9]\d*$/),
            Validators.required
          ]
        }),
        customDateRange: this.formBuilder.control(null),
        startAt: this.formBuilder.control(
          { value: null, disabled: true },
          { validators: Validators.required }
        ),
        endAt: this.formBuilder.control(
          { value: null, disabled: true },
          { validators: Validators.required }
        )
      });
    }
  }

  onSubmit() {
    if (this.settingsForm.valid) {
      this.save.emit({
        before: this.oldSettings,
        after: {
          ...this.settingsForm.value,
          ...(!this.settingsForm.controls.customDateRange.value
            ? { endAt: null, startAt: null }
            : {})
        }
      });
    }
  }

  onCancel() {
    this.settingsForm.patchValue(this.oldSettings);
    this.cancel.emit();
  }
}
