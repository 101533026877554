<ng-container [ngSwitch]="entity.entityType">
  <ng-container *ngSwitchCase="'account'" [ngSwitch]="!!entity.accountType">
    <ease-account-icon
      *ngSwitchCase="true"
      [accountType]="entity.accountType"
      [size]="accountSize"
    ></ease-account-icon>
    <mat-icon *ngSwitchDefault [ngClass]="'mat-icon-' + size">
      device_hub
    </mat-icon>
  </ng-container>
  <mat-icon *ngSwitchCase="'customer'" [ngClass]="'mat-icon-' + size">
    person_pin
  </mat-icon>
  <mat-icon *ngSwitchCase="'prospect'" [ngClass]="'mat-icon-' + size">
    ring_volume
  </mat-icon>
</ng-container>
