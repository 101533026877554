import {
  ComponentRef,
  Directive,
  Input,
  OnDestroy,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { isInteger } from 'lodash-es';

import { IndexerViewComponent } from './indexer-view/indexer-view.component';
import { Indexer, IndexerMeta } from './indexer.interface';

@Directive({
  selector: '[easeIndexer]'
})
export class IndexerDirective implements OnDestroy {
  @Input('easeIndexer') set indexer(indexer: Indexer) {
    this._indexer = indexer;
    this.loadIndexerViewComponent();

    if (this.compRef) {
      this.compRef.instance.indexer = indexer;
    }
  }
  get indexer(): Indexer {
    return this._indexer;
  }

  @Input() set easeIndexerMeta(meta: IndexerMeta) {
    this._easeIndexerMeta = meta;

    if (this.compRef) {
      this.compRef.instance.meta = meta;
    }
  }

  get easeIndexerMeta(): IndexerMeta {
    return this._easeIndexerMeta;
  }

  private _indexer: Indexer;
  private _easeIndexerMeta: IndexerMeta;
  private compRef: ComponentRef<IndexerViewComponent>;

  constructor(private renderer2: Renderer2, private vcr: ViewContainerRef) {}

  ngOnDestroy(): void {
    if (this.compRef) {
      this.compRef.destroy();
    }
  }

  private loadIndexerViewComponent(): void {
    if (this.compRef) {
      this.compRef.destroy();
    }

    if (isInteger(this.indexer.index)) {
      this.compRef = this.vcr.createComponent(IndexerViewComponent);

      this.renderer2.insertBefore(
        this.vcr.element.nativeElement,
        this.compRef.location.nativeElement,
        this.vcr.element.nativeElement.firstChild
      );
    }
  }
}
