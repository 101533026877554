<ease-sheet class="popover">
  <ease-popover-header>Related Tasks</ease-popover-header>
  <mat-tab-group [@.disabled]="true" mat-stretch-tabs>
    <mat-tab label="Open">
      <ng-template matTabContent>
        <ng-container *ngIf="openTasks$ | async as openTasks; else loading">
          <ng-container *ngIf="openTasks.length; else emptyOpen">
            <ease-list-selector
              [items]="openTasks$ | async"
              (selected)="onOpen()"
              [selectedItems]="[]"
              [sortFields]="['name', 'createdAt']"
            >
              <ng-template let-task="item" let-selected="selected">
                <ease-task-panel-compact
                  [easeTaskWindower]="task?.$key"
                  [task]="task"
                >
                </ease-task-panel-compact>
              </ng-template>
            </ease-list-selector>
          </ng-container>
        </ng-container>
      </ng-template>
    </mat-tab>
    <mat-tab label="Scheduled">
      <ng-template matTabContent>
        <ng-container
          *ngIf="scheduledTasks$ | async as scheduledTasks; else loading"
        >
          <ng-container *ngIf="scheduledTasks?.length; else emptyScheduled">
            <ease-list-selector
              [items]="scheduledTasks$ | async"
              [selectedItems]="[]"
              [sortFields]="[]"
            >
              <ng-template let-task="item" let-selected="selected">
                <ease-task-panel-scheduled
                  [easeTaskWindower]="task?.$key"
                  [key]="task.$key"
                  [task]="task"
                >
                </ease-task-panel-scheduled>
              </ng-template>
            </ease-list-selector>
          </ng-container>
        </ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ease-sheet>

<ng-template #loading>
  <div class="flex flex-row items-center justify-center p-5 text-center">
    <mat-progress-spinner [diameter]="24" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<ng-template #emptyOpen>
  <div class="p-5 text-center">
    No {{ excludeTasks && excludeTasks?.length ? 'other open' : 'open' }} tasks
  </div>
</ng-template>

<ng-template #emptyScheduled>
  <div class="p-5 text-center">No scheduled tasks</div>
</ng-template>
