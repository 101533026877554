import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges
} from '@angular/core';

import { FeedItemService } from '../../../shared/feed-items/feed-item.service';
import { TaskModel } from '../../task.model';
import { TaskService } from '../../task.service';

@Component({
  selector: 'ease-task-completion-message',
  templateUrl: './task-completion-message.component.html',
  styleUrls: ['./task-completion-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCompletionMessageComponent implements OnChanges {
  @Input()
  task: TaskModel;
  @Input()
  users;
  public isEditable: boolean = false;

  constructor(
    private feedItemService: FeedItemService,
    private taskService: TaskService
  ) {}

  updateMessage(completionMessage: string) {
    this.taskService.updateCompleted(this.task.$key, { completionMessage });
  }

  ngOnChanges() {
    this.isEditable = this.feedItemService.editableByType(
      this.task as any,
      'tasksCompleted'
    );
  }
}
