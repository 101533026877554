import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import { CustomerAccountMeta } from './customer-accounts.interface';

@Injectable({ providedIn: 'root' })
export class CustomerAccountRedirectGuard implements CanActivate {
  constructor(
    private entityUtilsService: EntityUtilsService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.doRedirect({
      accountType: route.params.accountType,
      accountId: route.params.accountId
    });
  }

  doRedirect(accountMeta: CustomerAccountMeta): Promise<boolean> {
    return this.entityUtilsService
      .getCustomerIdForAccount(accountMeta)
      .then(customerId => {
        if (customerId) {
          this.router.navigate([
            '/customers',
            customerId,
            'accounts',
            accountMeta.accountType,
            accountMeta.accountId
          ]);
        } else {
          this.router.navigate(['/dashboards']);
        }

        return false;
      });
  }
}
