import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CustomerContactModel } from '../customer-contact.model';
import { CustomerContactsService } from '../customer-contacts.service';

@Component({
  selector: 'ease-customer-contacts-list',
  templateUrl: './customer-contacts-list.component.html',
  styleUrls: ['./customer-contacts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerContactsListComponent implements OnInit {
  @Input()
  set customerId(customerId: string) {
    this._customerId = customerId;
    this.contacts$ = this.customerContactsService
      .get(customerId, true)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  get customerId(): string {
    return this._customerId;
  }

  private _customerId: string;
  public contacts$: Observable<CustomerContactModel[]>;

  constructor(private customerContactsService: CustomerContactsService) {}

  ngOnInit() {}
}
