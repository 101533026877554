import { Injectable } from '@angular/core';
import { GeocodeResult } from '@googlemaps/google-maps-services-js';
import { CurrencyPipe } from '@angular/common';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, firstValueFrom, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatSnackBar } from '@angular/material/snack-bar';

import { GoogleMapsService } from 'src/app/shared/google-maps.service';
import { FileUploaderFile } from 'src/app/shared/file-uploader/file-uploader.interface';
import { SkSyncHttpService } from '../../shared/sksync-http.service';
import { SharedValidators } from '../../shared/shared-validators';
import { transformCurrencyString } from '../../shared/utils/functions';
import { ListDisplayItem } from '../../shared/shared.interface';
import {
  AccBuilderPayload,
  AccountBuilderTemplate,
  AccountBuilderVertical
} from './account-builder.interface';

@Injectable({ providedIn: 'root' })
export class AccountBuilderService {
  private budgetRange =
    (min: number, max: number): ValidatorFn =>
    (c: AbstractControl): { [key: string]: any } => {
      if (
        transformCurrencyString(c.value) >= min &&
        transformCurrencyString(c.value) <= max
      )
        return null;

      return {
        budgetRange: {
          message: `Not in the right range. Min. ${this.currencyPipe.transform(
            min,
            'USD',
            'symbol',
            '1.0-2'
          )} - Max. ${this.currencyPipe.transform(
            max,
            'USD',
            'symbol',
            '1.0-2'
          )}`
        }
      };
    };
  private adUploadPath: string = 'accountBuilderAds';

  public budgetRanges = {
    hiring: { min: 500, max: 5000 },
    gold: { min: 200, max: 3000 }
  };

  public stepSections: {
    [templateSelectedId: string]: {
      [stepId: string]: boolean;
    };
  } = {
    hiring: {
      verticals: true,
      budget: true,
      geoTargeting: true,
      displayCampaigns: true,
      searchCampaigns: true,
      callExtensions: true,
      negativeKeywords: true
    },
    purple: {
      geoTargeting: true,
      displayCampaigns: true
    },
    gold: {
      budget: true,
      geoTargeting: true,
      displayCampaigns: true
    }
  };

  public sectionControls: {
    [templateSelectedId: string]: {
      [fieldId: string]: {
        enabled: boolean;
        validator?: ValidatorFn;
      };
    };
  } = {
    hiring: {
      verticalIds: {
        enabled: true,
        validator: Validators.compose([
          Validators.required,
          Validators.maxLength(3)
        ])
      },
      postalcode: {
        enabled: true,
        validator: Validators.compose([
          Validators.required,
          SharedValidators.postalzipcode
        ])
      },
      latitude: {
        enabled: true,
        validator: Validators.required
      },
      longitude: {
        enabled: true,
        validator: Validators.required
      },
      budget: {
        enabled: true,
        validator: Validators.compose([
          Validators.required,
          this.budgetRange(
            this.budgetRanges.hiring.min,
            this.budgetRanges.hiring.max
          )
        ])
      },
      radius: {
        enabled: true,
        validator: Validators.required
      },
      adSets: {
        enabled: true,
        validator: Validators.required
      },
      callExtensionCountry: {
        enabled: true
      },
      callExtensionPhoneNumber: {
        enabled: true
      },
      negativeKeywords: {
        enabled: true,
        validator: Validators.required
      }
    },
    purple: {
      postalcode: {
        enabled: true,
        validator: Validators.compose([
          Validators.required,
          SharedValidators.postalzipcode
        ])
      },
      latitude: {
        enabled: true,
        validator: Validators.required
      },
      longitude: {
        enabled: true,
        validator: Validators.required
      },
      radius: {
        enabled: true,
        validator: Validators.required
      },
      adSets: {
        enabled: true,
        validator: Validators.required
      }
    },
    gold: {
      budget: {
        enabled: true,
        validator: Validators.compose([
          Validators.required,
          this.budgetRange(
            this.budgetRanges.gold.min,
            this.budgetRanges.gold.max
          )
        ])
      },
      postalcode: {
        enabled: true,
        validator: Validators.compose([
          Validators.required,
          SharedValidators.postalzipcode
        ])
      },
      latitude: {
        enabled: true,
        validator: Validators.required
      },
      longitude: {
        enabled: true,
        validator: Validators.required
      },
      radius: {
        enabled: true,
        validator: Validators.required
      },
      adSets: {
        enabled: true,
        validator: Validators.required
      }
    }
  };

  constructor(
    private googleMapsService: GoogleMapsService,
    private skSyncHttp: SkSyncHttpService,
    private storage: AngularFireStorage,
    private matSnackBar: MatSnackBar,
    private currencyPipe: CurrencyPipe
  ) {}

  getLocationInfo(location: string): Observable<GeocodeResult> {
    return this.googleMapsService.getGeoCode({ address: location }).pipe(
      map(response => response.results[0]),
      catchError(() => EMPTY)
    );
  }

  getSupportedVerticals(
    templateId: AccountBuilderTemplate
  ): Observable<ListDisplayItem[]> {
    return this.skSyncHttp.get(`/account-builder/${templateId}/verticals`).pipe(
      map(verticals =>
        verticals.map(vertical => ({
          value: vertical,
          viewValue: vertical
        }))
      )
    );
  }

  getVerticalsMeta(
    templateId: string,
    verticals: string[]
  ): Observable<AccountBuilderVertical[]> {
    return this.skSyncHttp.get(
      `/account-builder/${templateId}/verticals/details`,
      {
        params: { verticals: verticals.join(',') }
      }
    );
  }

  async uploadFile(file: FileUploaderFile, accountId: string): Promise<any> {
    const timestamp = new Date().getTime();
    const filePath = `${this.adUploadPath}/${accountId}/${timestamp}_${file.id}_${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, file.nativeFile);
    uploadTask.catch(error =>
      this.matSnackBar.open(`Uploading error: ${error.message}`, 'Close')
    );
    return uploadTask.then(() => firstValueFrom(fileRef.getDownloadURL()));
  }

  applyTemplate(
    payload: AccBuilderPayload,
    planType: string
  ): Observable<{ queue: string; jobId: number }> {
    if (planType) {
      return this.skSyncHttp.post(`/account-builder/${planType}`, payload);
    }
  }
}
