<span
  [class]="defaultAnchorClass + ' cursor-pointer'"
  [ngClass]="options.anchorClass"
  [satPopoverAnchor]
  #anchor="satPopoverAnchor"
  [satPopoverHover]="options.showDelay"
  ><ng-template *ngTemplateOutlet="trigger"></ng-template
></span>

<sat-popover
  [panelClass]="[
    'rich-tooltip-popover',
    options.autoWidth ? 'auto-width-tooltip' : '',
    options.offsetClass ? options.offsetClass : ''
  ]"
  [anchor]="anchor"
  [verticalAlign]="options.verticalAlign"
  [horizontalAlign]="options.horizontalAlign"
>
  <div class="rich-tooltip-content bg-white py-2 px-4">
    <ng-template
      *ngTemplateOutlet="contentTemplate || contentTextTemplate"
    ></ng-template>

    <ng-template #contentTextTemplate>
      <span [easeMarkdown]="contentText" size="sm"></span>
    </ng-template>
  </div>
</sat-popover>
