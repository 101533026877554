import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding
} from '@angular/core';

@Component({
  selector: 'ease-nav-section',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./nav-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavSectionComponent implements OnInit {
  @HostBinding('class') class =
    'relative block bg-blue-gray-900 py-4 border-b border-t border-blue-gray-800 border-solid';

  constructor() {}

  ngOnInit(): void {}
}
