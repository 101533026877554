import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation,
  ChangeDetectorRef,
  Host,
  Optional,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SatPopover } from '@ncstate/sat-popover';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs';
import * as moment from 'moment';
import { DateRange } from '@angular/material/datepicker';

import { TypedFormControl } from 'src/app/shared/reactive-forms';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from '../../../shared/constants';
import { AuthService } from '../../../auth/auth.service';
import { UserModel, UserModes } from '../../user.model';
import { UserService } from '../../user.service';

type UserModeForm = FormGroup<
  TypedFormControl<Omit<UserModes<moment.Moment>, 'awayModeSettings'>> & {
    awayModeSettings: FormControl<
      Omit<UserModes<moment.Moment>['awayModeSettings'], 'awaySince'>
    >;
  }
>;

@UntilDestroy()
@Component({
  selector: 'ease-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class UserProfileDialogComponent implements OnInit {
  @Input() showUserInfo: boolean = true;
  @Input() set user(user: UserModel) {
    this._user = user;
    this.setValueForUserModeForm(this.user?.mode);
  }

  get user(): UserModel {
    return this._user;
  }

  @Output() navigated: EventEmitter<Event> = new EventEmitter<Event>();

  private _user: UserModel;
  public today: moment.Moment = moment().startOf('day');
  public maxUntil: moment.Moment = moment().add(1, 'years');
  public showReminder: boolean = false;
  public showDateRangePicker: boolean = false;
  public userModeForm: UserModeForm = this.formBuilder.group<
    UserModeForm['controls']
  >({
    away: this.formBuilder.control(null),
    awayModeSettings: this.formBuilder.control(null)
  });

  get awayModeSettingsControl(): UserModeForm['controls']['awayModeSettings'] {
    return this.userModeForm.controls.awayModeSettings;
  }

  constructor(
    @Optional() @Host() public satPopover: SatPopover,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.user?.mode?.away) {
      // Turn on reminder only once at initial load
      this.showReminder = true;
      this.satPopover.open();
    }

    this.satPopover.afterClose.pipe(untilDestroyed(this)).subscribe(() => {
      this.toggleDateRangePicker(false);
      this.turnOffReminder();
    });

    this.userModeForm.valueChanges
      .pipe(untilDestroyed(this), debounceTime(300))
      .subscribe(userModes => {
        this.toggleDateRangePicker(false);
        const modifiedModes = this.getUserModesForSaving(userModes);
        const { away, awayModeSettings } = modifiedModes || {};
        const { start, end, awaySince } = awayModeSettings || {};

        this.userService
          .updateUserModes({
            away,
            awayModeSettings: {
              start: start?.valueOf() || null,
              end: end?.valueOf() || null,
              awaySince: awaySince || null
            }
          })
          .then(() => {
            this.matSnackBar.open('Away settings updated', 'Close', {
              duration: SNACKBAR_DURATION_SUCCESS
            });
          })
          .catch(() => {
            this.matSnackBar.open(
              'There was an error updating away settings',
              'Close',
              {
                duration: SNACKBAR_DURATION_ERROR
              }
            );
          });
      });
  }

  /**
   * Determine modes to save if date range is included today
   * by checking current & previous modes.
   *
   * Previous modes:
   *
   * @see UserProfileDialogComponent.user
   *
   * Current modes:
   * @see UserProfileDialogComponent.userModeForm (subscription)
   *
   * @param userModes
   * @returns UserModes<moment.Moment>
   */
  private getUserModesForSaving(
    userModes: Omit<UserModes<moment.Moment>, 'awaySince'>
  ): UserModes<moment.Moment> {
    const { away, awayModeSettings } = userModes || {};
    const { start, end } = awayModeSettings || {};
    const modifiedModes: UserModes<moment.Moment> = {
      away,
      awayModeSettings: {
        start,
        end,
        awaySince: away
          ? this.user?.mode?.awayModeSettings?.awaySince || this.today.valueOf()
          : null
      }
    };

    const isTodayIncluded = this.isTodayIncluded({
      start,
      end
    });

    const isPreviousTodayIncluded = this.isTodayIncluded({
      start: moment(this.user?.mode?.awayModeSettings?.start),
      end: moment(this.user?.mode?.awayModeSettings?.end)
    });

    /**
     * Below logics should only apply when
     * previous/current has today included
     */
    if (isTodayIncluded || isPreviousTodayIncluded) {
      /**
       * First determine away status:
       *
       * If previous/current away status is on, toggle off away
       * If previous/current away status is off, toggle on away
       */
      if (this.user?.mode?.away && away) {
        // Only set away to off when meet blow conditions
        if (
          !(isTodayIncluded && isPreviousTodayIncluded) &&
          !(isTodayIncluded && !isPreviousTodayIncluded)
        ) {
          modifiedModes.away = false;
        }
      }

      if (!this.user?.mode?.away && !away) {
        modifiedModes.away = true;
      }

      /**
       * Next determine awayModeSettings:
       *
       * If current is away or not current/previous today & not away currently,
       * clear awaySince since today is within selected date range.
       * Else, away is off, clear all settings.
       */
      if (
        modifiedModes.away ||
        (!isTodayIncluded && isPreviousTodayIncluded && !modifiedModes.away)
      ) {
        modifiedModes.awayModeSettings.awaySince = null;
      } else {
        modifiedModes.awayModeSettings = null;
      }
    }

    return modifiedModes;
  }

  /**
   * Check today if within date range, included today
   *
   * @param dates Record<string, moment.Moment>
   * @returns boolean
   */
  private isTodayIncluded({
    start,
    end
  }: Record<string, moment.Moment>): boolean {
    return (
      !!start && !!end && this.today.isBetween(start, end, undefined, '[]')
    );
  }

  /**
   * Convert saved Firebase value for form/calendar,
   * and turn off emitEvent to avoid infinite loops
   *
   * @param userModes
   * @returns void
   */
  private setValueForUserModeForm(userModes: UserModes<number>): void {
    const { away, awayModeSettings } = userModes || {};
    const { start, end } = awayModeSettings || {};

    if (!away) {
      this.turnOffReminder();
    }

    this.userModeForm.patchValue(
      {
        away,
        awayModeSettings: new DateRange<moment.Moment>(
          !!start ? moment(start) : null,
          !!end ? moment(end) : null
        )
      },
      { emitEvent: false }
    );
  }

  turnOffReminder(): void {
    if (this.showReminder) {
      this.showReminder = false;
      setTimeout(() => this.satPopover.realign());
      this.cdr.detectChanges();
    }
  }

  toggleDateRangePicker(status: boolean): void {
    this.showDateRangePicker = status;
    setTimeout(() => this.satPopover.realign());
    this.cdr.detectChanges();
  }

  onNavigated(event: Event, closePopover: boolean = true): void {
    if (closePopover) {
      this.satPopover.close();
    }

    this.navigated.emit(event);
  }

  logout(): void {
    this.satPopover.close();
    this.authService.logout();
  }
}
