import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ease-feed-item-communication',
  templateUrl: './feed-item-communication.component.html',
  styleUrls: ['../feed-item/feed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemCommunicationComponent {
  @Input()
  item;
  @Input()
  users;
  @Input()
  dateFormat: string;
}
