import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgGridAngular } from 'ag-grid-angular';
import {
  CellValueChangedEvent,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
  ValueGetterParams
} from 'ag-grid-community';
import has from 'lodash-es/has';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import isEmpty from 'lodash-es/isEmpty';

import { GridService } from 'src/app/shared/grid-filter/grid.service';
import { HeaderCustomInfoCellComponent } from 'src/app/shared/grid-cells/header-custom-info-cell/header-custom-info-cell.component';
import { CustomersService } from 'src/app/customers/customers.service';
import {
  GridState,
  GridInstanceName
} from 'src/app/shared/grid-filter/grid.interface';
import { SharedLayoutService } from '../../../shared/shared-layout.service';
import { CustomerAccountRulesService } from '../../../customers/customer-accounts/customer-account-rules.service';
import { AlertCellComponent } from '../grid-cells/alert-cell/alert-cell.component';
import { BudgetUtilizationCellComponent } from '../grid-cells/budget-utilization-cell/budget-utilization-cell.component';
import { CompetitiveMetricCellComponent } from '../grid-cells/competitive-metric-cell/competitive-metric-cell.component';
import { DetailAccountRowRendererComponent } from '../detail-account-rows/detail-account-row-renderer/detail-account-row-renderer.component';
import { MoreActionsCellComponent } from '../grid-cells/more-actions-cell/more-actions-cell.component';
import { GridCellRendererComponent } from '../../../shared/grid-cells/grid-cell-renderer/grid-cell-renderer.component';
import { CplMetricCellComponent } from '../grid-cells/cpl-metric-cell/cpl-metric-cell.component';
import { accountTypeRenderer } from '../../../shared/grid-cells/cell-renderers';
import { CqMetricCellComponent } from '../grid-cells/cq-metric-cell/cq-metric-cell.component';
import { ResMetricCellComponent } from '../grid-cells/res-metric-cell/res-metric-cell.component';
import { Account, AccountDataFilters } from '../account-dashboard.interface';
import { TableService } from '../../../shared/table.service';
import { AdminPermissionsService } from '../../../admin/admin-roles/admin-permissions.service';
import { AccountDashboardService } from '../account-dashboard.service';
import { OsMetricCellComponent } from '../grid-cells/os-metric-cell/os-metric-cell.component';

@UntilDestroy()
@Component({
  selector: 'ease-account-dashboard-table',
  templateUrl: './account-dashboard-table.component.html',
  styleUrls: ['./account-dashboard-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountDashboardTableComponent implements OnDestroy, OnInit {
  @Input() accounts: Account[];
  @Input() quickFilter: string;
  @Input() compact: boolean = false;
  @Input() overrides: GridOptions<Account>;
  @Input() instanceName: GridInstanceName;
  @Output() tableReady: EventEmitter<GridReadyEvent> =
    new EventEmitter<GridReadyEvent>();
  @Output() ungrouped: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() rowDataUpdated: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('mainGrid', { static: true })
  mainGrid: AgGridAngular;
  @ViewChild('moreActionsCell', { static: true })
  moreActionsCell: TemplateRef<any>;
  @ViewChild('defaultCurrencyCell', { static: true })
  defaultCurrencyCell: TemplateRef<any>;
  @ViewChild('defaultMetricCell', { static: true })
  defaultMetricCell: TemplateRef<any>;
  private onDestroy$: Subject<void> = new Subject<void>();
  public gridOptions: GridOptions<Account>;
  public groupDefaultExpanded: number = 1;
  public overlayLoadingTemplate: string;
  public customerNamePresetStatus: boolean = true;
  public accountNamePresetStatus: boolean = true;
  public detailCellRendererParams: any;
  private plans: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountDashboardService: AccountDashboardService,
    private currencyPipe: CurrencyPipe,
    private customerAccountRulesService: CustomerAccountRulesService,
    private customersService: CustomersService,
    private gridService: GridService,
    public sharedLayoutService: SharedLayoutService,
    private snackBar: MatSnackBar,
    private tableService: TableService,
    private permissionService: AdminPermissionsService
  ) {}

  ngOnInit(): void {
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Loading accounts and metrics</span>';

    this.gridOptions = {
      aggFuncs: {
        butMetric: params => {
          const totals = params.rowNode.allLeafChildren.reduce(
            (acc, row) => {
              const rowCost = parseFloat(
                `${row?.data?.butMetric?.butCostToDate}`
              );
              const rowTarget = parseFloat(
                `${row?.data?.butMetric?.butTargetToDate}`
              );

              if (rowCost) {
                acc.butCostToDate += rowCost;
              }
              if (rowTarget) {
                acc.butTargetToDate += rowTarget;
              }

              return acc;
            },
            {
              butCostToDate: 0,
              butTargetToDate: 0
            }
          );

          const total = totals.butCostToDate / totals.butTargetToDate;

          /**
           * If we have a number, use Math.floor to trim the decimals
           * without rounding them. This ensures the values we display
           * match the values provided from the backend for single-account
           * customers.
           */
          if (!isNaN(total)) {
            const totalPercentage = total * 100;
            return Math.floor(totalPercentage * 100) / 100;
          } else {
            return null;
          }
        }
      },
      suppressCsvExport: !this.permissionService.hasPermission('exportAMD'),
      suppressExcelExport: !this.permissionService.hasPermission('exportAMD'),
      getRowId: params => `${params.data.accountType}_${params.data.accountId}`,
      valueCache: true,
      onSortChanged: params => {
        // set grouping disabled by colId
        const disableGroupingForColumnId = {
          butMetric: true,
          competitiveMetric: true,
          costPerLead: true,
          cqMetric: true,
          resMetric: true,
          osMetric: true,
          onlineSince: true
        };

        // Get current sort Model []
        const currentSortModel = params.columnApi.getColumnState();
        const sortedColumns = currentSortModel.filter(col => col.sort);
        const shouldUngroup = sortedColumns.some(
          col => disableGroupingForColumnId[col.colId]
        );

        // Check if currentsortModel is part of sort grouping disabled
        if (shouldUngroup) {
          // Disable grouping
          this.groupByCustomer(true);
          this.ungrouped.emit(true);
        } else {
          // Re-Group by customerName, hide column and sizeColumnsToFit
          this.groupByCustomer(false);
        }
      },
      onRowDataUpdated: () => {
        // Only apply grid preset on data updated
        this.applyFilters({ columnVisibility: true });
        this.rowDataUpdated.emit();
      },
      suppressAggFuncInHeader: true,
      getRowHeight: params => (params.node.group ? 38 : null),
      postSortRows: params => {
        /**
         * postSort is being called once for each row when grouped and then
         * a final time which contains all rows. We work around this by checking
         * whether the row count === the total row count and the only sort once.
         */
        const rowNodes = params.nodes;

        if (
          rowNodes.length &&
          rowNodes.length === this.mainGrid.api.getModel().getTopLevelRowCount()
        ) {
          rowNodes.sort((rowA, rowB) => {
            let snoozedA: number;
            let snoozedB: number;

            if (rowA.group && rowB.group) {
              snoozedA = rowA.allLeafChildren.every(
                childRow => !!childRow.data.snoozed
              )
                ? 1
                : 0;
              snoozedB = rowB.allLeafChildren.every(
                childRow => !!childRow.data.snoozed
              )
                ? 1
                : 0;
            } else {
              snoozedA = rowA.data.snoozed ? 1 : 0;
              snoozedB = rowB.data.snoozed ? 1 : 0;
            }

            // Collapse all snoozed customers
            if (snoozedA || !rowA.group) {
              rowA.expanded = false;
            }
            if (snoozedB || !rowB.group) {
              rowB.expanded = false;
            }

            return snoozedA - snoozedB;
          });
          // Only re-draw the grid with new collapsed states once
          this.mainGrid.api.onGroupExpandedOrCollapsed();
        }
      },
      cacheQuickFilter: true,
      sideBar: this.tableService.getDefaultSidebar(),
      statusBar: {
        statusPanels: [
          { statusPanel: 'agFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agTotalRowCountComponent', align: 'left' }
        ]
      },
      suppressCellSelection: false,
      processCellForClipboard: params => {
        switch (params.column.getColDef().field) {
          case 'butMetric.butPercentageToDate':
          case 'cplMetric.percentageSwingFromTarget':
          case 'comMetric.percentageSwingFromMedian':
            return params.value ? `${params.value}%` : '';
          case 'budget':
            return this.currencyPipe.transform(
              params.value,
              'USD',
              'symbol',
              '1.0-2'
            );
          default:
            return params.value;
        }
      },
      defaultColDef: {
        resizable: true,
        menuTabs: ['filterMenuTab']
      },
      masterDetail: true,
      components: {
        agColumnHeader: HeaderCustomInfoCellComponent,
        detailCellRenderer: DetailAccountRowRendererComponent
      },
      onGridReady: (params: GridReadyEvent) => {
        if (params) {
          params.columnApi.applyColumnState({
            state: [
              {
                colId: 'metadata',
                sort: 'desc'
              }
            ]
          });

          // When the grid is ready, get customer plans hashmap
          // then refresh plan column to render plan names.
          this.customersService
            .getPlansAsObject()
            .pipe(take(1))
            .subscribe(plans => {
              this.plans = plans;
              params.api.refreshCells({ columns: ['plan'] });
            });
        }
        this.gridOptions.sideBar &&
          this.tableService.sortColumnsSidebar(params);
        this.tableReady.emit(params);
      },
      getRowClass: params => {
        if (
          // check if group row
          (params.node.group &&
            params.node.allLeafChildren.every(
              childRow => !!childRow.data.snoozed
            )) ||
          // for normal accounts row
          (params.node && params.node.data && params.node.data.snoozed)
        ) {
          return 'row-snoozed';
        }

        return '';
      },
      autoGroupColumnDef: {
        minWidth: 250,
        headerName: 'Name',
        field: 'accountName',
        colId: 'name',
        width: 380,
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true
      },
      columnDefs: [
        {
          headerName: '',
          colId: 'metadata',
          field: 'metadata',
          width: 44,
          sortable: true,
          sort: 'asc',
          pinned: 'left',
          headerComponentParams: {
            enableMenu: false
          },
          cellRenderer: AlertCellComponent,
          cellClass: 'mobile-with-pointer-event',
          valueGetter: row => {
            if (row.node.group) {
              return row.node.allLeafChildren.some(
                childRow => !!childRow.data.metadata
              );
            } else {
              return !!row.data.metadata;
            }
          }
        },
        {
          headerName: this.compact ? '' : 'Type',
          field: 'accountType',
          enableRowGroup: true,
          sortable: true,
          pinned: 'left',
          minWidth: this.compact ? 40 : 80,
          maxWidth: 80,
          cellRenderer: accountTypeRenderer,
          headerComponentParams: {
            enableMenu: true
          },
          filter: true
        },
        {
          headerName: 'Customer Name',
          colId: 'customerName',
          field: 'customerName',
          hide: true,
          suppressMenu: true,
          sortable: true,
          minWidth: 250,
          width: 380,
          flex: 1,
          rowGroup: true
        },
        {
          headerName: 'Account Name',
          colId: 'accountName',
          field: 'accountName',
          hide: true,
          suppressMenu: true,
          sortable: true,
          minWidth: 250,
          width: 380,
          flex: 1,
          rowGroup: true,
          cellRenderer: 'agGroupCellRenderer'
        },
        {
          headerName: 'ID',
          colId: 'id',
          sortable: true,
          width: 145,
          suppressSizeToFit: true,
          headerComponentParams: {
            enableMenu: true
          },
          filter: true,
          valueGetter: row => {
            if (row.node.group && row.node.childrenAfterGroup.length) {
              return row.node.childrenAfterGroup[0].data?.customerId;
            } else {
              return row.data?.accountId;
            }
          },
          getQuickFilterText: row => {
            if (row.node.group && row.node.childrenAfterGroup.length) {
              return row.node.childrenAfterGroup[0].data?.customerId;
            } else {
              return row.data?.accountId;
            }
          }
        },
        {
          headerName: 'SKID',
          field: 'customerId',
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          hide: true,
          headerComponentParams: {
            enableMenu: true
          },
          filter: true
        },
        {
          headerName: 'Plan',
          colId: 'plan',
          field: 'planId',
          minWidth: 160,
          maxWidth: 180,
          filter: true,
          hide: true,
          enableValue: true,
          cellClass: 'hide-when-grouped',
          valueGetter: params => {
            const planId = params.data
              ? params.data.planId
              : params.node.childrenAfterGroup
              ? params.node.childrenAfterGroup[0].data?.planId
              : null;

            return planId ? this.getPlanName(planId) : '';
          },
          filterValueGetter: (params: ValueGetterParams) =>
            params.data?.planId ? this.getPlanName(params.data.planId) : ''
        },
        {
          headerName: 'Vertical',
          field: 'verticalName',
          minWidth: 130,
          maxWidth: 140,
          sortable: true,
          hide: true,
          headerComponentParams: {
            enableMenu: true
          },
          filter: true
        },
        {
          headerName: 'DMA',
          field: 'dmaName',
          minWidth: 130,
          maxWidth: 140,
          sortable: true,
          hide: true,
          headerComponentParams: {
            enableMenu: true
          },
          filter: true
        },
        {
          headerName: 'Budget',
          colId: 'budget',
          field: 'budget',
          minWidth: 110,
          maxWidth: 140,
          sortable: true,
          filter: 'agNumberColumnFilter',
          enableValue: true,
          type: 'numericColumn',
          aggFunc: 'sum',
          headerComponentParams: {
            enableMenu: true
          },
          valueFormatter: (params: ValueFormatterParams) => {
            const currency =
              params.data && params.data.currency ? params.data.currency : null;

            if (typeof params.value === 'undefined') {
              return '';
            }

            if (currency) {
              return this.currencyPipe.transform(
                params.value,
                currency,
                'symbol-narrow',
                '1.0-2'
              );
            } else {
              return Math.floor(Number(params.value))
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            }
          },
          cellRenderer: GridCellRendererComponent,
          cellRendererParams: {
            ngTemplate: this.defaultCurrencyCell
          },
          cellStyle: {
            textAlign: 'right'
          }
        },
        {
          headerName: 'Budget Utilization',
          headerClass: this.compact ? 'compact' : '',
          field: 'butMetric.butPercentageToDate',
          colId: 'butMetric',
          minWidth: this.compact ? 80 : 120,
          sortable: true,
          // Register aggFunc as a function above.
          // Related issue: https://github.com/ag-grid/ag-grid/issues/3784
          // Related ticket (AG-4122 marked as done, but regressed): https://www.ag-grid.com/changelog/?fixVersion=23.1.0
          aggFunc: 'butMetric',
          comparator: this.gridService.defaultComparator,
          headerComponentParams: {
            tooltipDescription: `Budget utilization measures the amount of budget you've used so far in the month, divided by the number of days in the month that the account was eligible to spend.\n\n This does not take temporary account pauses into consideration. Affirm that the monthly budget remains the budget target for the AMD, regardless of the ads being paused for any reason`,
            enableMenu: false,
            mobileHeaderName: 'BUT'
          },
          cellRenderer: BudgetUtilizationCellComponent,
          cellClass: 'mobile-with-pointer-event'
        },
        {
          headerName: 'BU to Date',
          field: 'butMetric.butCostToDate',
          hide: true,
          valueFormatter: params =>
            this.currencyPipe.transform(
              params.data?.butMetric?.butCostToDate,
              'USD',
              'symbol',
              '1.0-0'
            )
        },
        {
          headerName: 'Target BU to Date',
          field: 'butMetric.butTargetToDate',
          hide: true,
          valueFormatter: params =>
            this.currencyPipe.transform(
              params.data?.butMetric?.butTargetToDate,
              'USD',
              'symbol',
              '1.0-0'
            )
        },
        {
          headerName: 'Total Spend to Date',
          field: 'butMetric.totalCostToDate',
          hide: true,
          valueFormatter: params =>
            this.currencyPipe.transform(
              params.data?.butMetric?.totalCostToDate,
              'USD',
              'symbol',
              '1.0-0'
            )
        },
        {
          headerName: 'Cost Per Lead',
          headerClass: this.compact ? 'compact' : '',
          colId: 'costPerLead',
          field: 'cplMetric.percentageSwingFromTarget',
          minWidth: this.compact ? 80 : 120,
          sortable: true,
          comparator: this.gridService.defaultComparator,
          headerComponentParams: {
            tooltipDescription: `Measures the account CPL against the target CPL.`,
            enableMenu: false,
            mobileHeaderName: 'CPL'
          },
          cellRenderer: CplMetricCellComponent,
          cellClass: 'mobile-with-pointer-event'
        },
        {
          headerName: 'Call Quality',
          headerClass: this.compact ? 'compact' : '',
          field: 'cqMetric.callQuality',
          colId: 'cqMetric',
          minWidth: this.compact ? 80 : 120,
          sortable: true,
          comparator: this.gridService.defaultComparator,
          headerComponentParams: {
            tooltipDescription: `Call Quality measures the percentage of calls received longer than 60 seconds.\n\n Reporting shown for the last 14 days.`,
            enableMenu: false,
            mobileHeaderName: 'CQ'
          },
          cellRenderer: CqMetricCellComponent,
          cellClass: 'mobile-with-pointer-event'
        },
        {
          headerName: 'Responsiveness',
          headerClass: this.compact ? 'compact' : '',
          field: 'resMetric.responsiveness',
          colId: 'resMetric',
          minWidth: this.compact ? 80 : 120,
          sortable: true,
          comparator: this.gridService.defaultComparator,
          headerComponentParams: {
            tooltipDescription: `Responsiveness measures the percentage of calls answered (Ads/Bing) or responsiveness rating (GLS).\n\n Reporting shown for the last 14 days.`,
            enableMenu: false,
            mobileHeaderName: 'Res'
          },
          cellRenderer: ResMetricCellComponent,
          cellClass: 'mobile-with-pointer-event'
        },
        {
          headerName: 'Optiscore',
          headerClass: this.compact ? 'compact' : '',
          field: 'osMetric.optiscore',
          colId: 'osMetric',
          minWidth: this.compact ? 80 : 120,
          sortable: true,
          comparator: this.gridService.defaultComparator,
          headerComponentParams: {
            tooltipDescription: `Optiscore is the opt-in rate of the recommendations Google suggests for your account.`,
            enableMenu: false,
            mobileHeaderName: 'OS'
          },
          cellRenderer: OsMetricCellComponent,
          cellClass: 'mobile-with-pointer-event'
        },
        {
          headerName: 'Competitive Metric',
          headerClass: this.compact ? 'compact' : '',
          colId: 'competitiveMetric',
          hide: true,
          field: 'comMetric.percentageSwingFromMedian',
          minWidth: this.compact ? 80 : 120,
          sortable: true,
          comparator: this.gridService.defaultComparator,
          headerComponentParams: {
            tooltipDescription: `Measures the account CPL against the CPL of other accounts that belong to the same DMA and Vertical.\n\n A minimum of 5 accounts must be active before this metric unlocks.`,
            enableMenu: false,
            mobileHeaderName: 'COM'
          },
          cellRenderer: CompetitiveMetricCellComponent,
          cellClass: 'mobile-with-pointer-event'
        },
        {
          headerName: 'Competitive Metric: Account CPL',
          field: 'comMetric.cpl',
          hide: true,
          valueFormatter: params =>
            this.currencyPipe.transform(
              params.data?.comMetric?.cpl,
              'USD',
              'symbol',
              '1.0-2'
            )
        },
        {
          headerName: 'Competitive Metric: Average CPL',
          field: 'comMetric.medianCPL',
          hide: true,
          valueFormatter: params =>
            this.currencyPipe.transform(
              params.data?.comMetric?.medianCPL,
              'USD',
              'symbol',
              '1.0-2'
            )
        },
        {
          headerName: 'Competitive Metric: Lowest CPL',
          hide: true,
          colId: 'comMetricLowestCpl',
          valueGetter: params => {
            if (has(params, 'data.comMetric.metadata.rankingList')) {
              const verticalRankings =
                params.data.comMetric.metadata.rankingList;
              return verticalRankings.reduce(
                (lowestCpl, verticalAccount) =>
                  Math.min(lowestCpl, verticalAccount.CPL),
                verticalRankings[0].CPL
              );
            } else {
              return '';
            }
          }
        },
        {
          headerName: 'Competitive Metric: Highest CPL',
          hide: true,
          colId: 'comMetricHighestCpl',
          valueGetter: params => {
            if (has(params, 'data.comMetric.metadata.rankingList')) {
              const verticalRankings =
                params.data.comMetric.metadata.rankingList;
              return verticalRankings.reduce(
                (highestCpl, verticalAccount) =>
                  Math.max(highestCpl, verticalAccount.CPL),
                verticalRankings[0].CPL
              );
            } else {
              return '';
            }
          }
        },
        {
          headerName: 'Competitive Metric: Position on Vertical',
          field: 'comMetric.position',
          hide: true,
          valueGetter: params =>
            has(params, 'data.comMetric.position')
              ? params.data.comMetric.position
              : ''
        },
        {
          headerName: 'Currency',
          field: 'currency',
          minWidth: 120,
          maxWidth: 140,
          hide: true
        },
        {
          headerName: 'MRR',
          field: 'netMonthlyRecurringRevenue',
          minWidth: 110,
          maxWidth: 140,
          hide: true,
          sortable: true,
          valueGetter: params => {
            const rawMonthly = this.getValueForProperty(
              params,
              'netMonthlyRecurringRevenue'
            );

            return rawMonthly && parseFloat(rawMonthly);
          },
          valueFormatter: params => {
            const rawMonthly = this.getValueForProperty(
              params,
              'netMonthlyRecurringRevenue'
            );

            return (
              rawMonthly &&
              this.currencyPipe.transform(rawMonthly, 'USD', 'symbol', '1.0-2')
            );
          },
          cellStyle: {
            textAlign: 'right'
          }
        },
        {
          headerName: 'Account Manager',
          field: 'managerName',
          minWidth: 160,
          maxWidth: 220,
          filter: true,
          hide: true
        },
        {
          headerName: 'Account Reviewer',
          field: 'reviewerName',
          minWidth: 160,
          maxWidth: 220,
          filter: true,
          hide: true
        },
        {
          headerName: 'Relationship Manager',
          field: 'relationshipManagerName',
          minWidth: 160,
          maxWidth: 220,
          hide: true,
          valueGetter: params =>
            this.getValueForProperty(params, 'relationshipManagerName')
        },
        {
          headerName: 'Online Since',
          field: 'onlineAt',
          colId: 'onlineSince',
          sortable: true,
          minWidth: 140,
          maxWidth: 180,
          hide: true,
          valueFormatter: params =>
            this.gridService.getFormattedDateString(
              params?.data?.onlineAt,
              'MMM D YYYY'
            ),
          filter: 'agDateColumnFilter',
          comparator: this.gridService.dateComparator,
          filterParams: {
            inRangeInclusive: true
          },
          filterValueGetter: params =>
            this.gridService.getDateObjForFilter(params?.data?.onlineAt)
        },
        {
          headerName: 'Last Communication',
          field: 'lastCommunicationAt',
          sortable: true,
          minWidth: 140,
          maxWidth: 180,
          hide: true,
          valueGetter: params => {
            const rawDate = this.getValueForProperty(
              params,
              'lastCommunicationAt'
            );

            return rawDate && moment(rawDate).valueOf();
          },
          valueFormatter: params => {
            const rawDate = this.getValueForProperty(
              params,
              'lastCommunicationAt'
            );

            return this.gridService.getFormattedDateString(
              rawDate,
              'MMM D YYYY'
            );
          },
          comparator: this.gridService.dateComparator
        },
        {
          headerName: 'Next Communication',
          field: 'nextCommunicationAt',
          sortable: true,
          minWidth: 140,
          maxWidth: 180,
          hide: true,
          valueGetter: params => {
            const rawDate = this.getValueForProperty(
              params,
              'nextCommunicationAt'
            );

            return rawDate && moment(rawDate).valueOf();
          },
          valueFormatter: params => {
            const rawDate = this.getValueForProperty(
              params,
              'nextCommunicationAt'
            );

            return this.gridService.getFormattedDateString(
              rawDate,
              'MMM D YYYY'
            );
          },
          comparator: this.gridService.dateComparator
        },
        {
          colId: 'actions',
          headerName: this.compact ? '' : 'Actions',
          width: 95,
          suppressSizeToFit: true,
          field: 'snoozed',
          headerComponentParams: {
            enableMenu: false
          },
          cellRenderer: MoreActionsCellComponent
        }
      ]
    };

    this.detailCellRendererParams = {
      detailGridOptions: {
        detailCellRenderer: DetailAccountRowRendererComponent
      }
    };

    // gridOptions overrides from parent component, if declared otherwise, local gridOptions takes precedence
    if (this.overrides) {
      this.gridOptions = { ...this.gridOptions, ...this.overrides };
    }

    // Only apply data filter when has no params(bindQueryParams) on the initial load
    this.activatedRoute.queryParams
      .pipe(take(1))
      .subscribe(queryParams =>
        this.applyFilters({ dataFilters: isEmpty(queryParams) })
      );

    // Always apply filter preset when preset has changed(clicked) by user
    this.gridService.presetChanged
      .pipe(untilDestroyed(this))
      .subscribe(() => this.applyFilters({ dataFilters: true }));
  }

  onCellValueChanged(event: CellValueChangedEvent) {
    if (Number(event.newValue) !== Number(event.oldValue)) {
      this.customerAccountRulesService
        .update(
          {
            accountId: event.data.accountId,
            accountType: event.data.accountType
          },
          'budget',
          { budget: Number(event.newValue) }
        )
        .then(budgetSettings => {
          event.api.refreshCells({
            rowNodes: [event.node],
            force: true
          });

          this.snackBar.open(
            'Budget Updated. This will take 24h to reflect. ',
            'Close'
          );
        });
    }
  }

  groupByCustomer(disableGrouping?: boolean) {
    this.applyFilters({ columnVisibility: true });

    this.gridOptions.columnApi?.setRowGroupColumns(
      disableGrouping ? [] : ['customerName']
    );

    this.gridOptions.columnApi?.setColumnVisible(
      'customerName',
      disableGrouping ? false : !this.customerNamePresetStatus
    );

    this.gridOptions.columnApi?.setColumnVisible(
      'accountName',
      disableGrouping ? true : !this.customerNamePresetStatus
    );
    this.ungrouped.emit(false);
  }

  async getUserPreset(): Promise<GridState> {
    const savedPreset = await this.gridService.getGridSettings(
      this.instanceName
    );

    if (savedPreset) {
      const gridSettingsList = savedPreset?.gridSettingsList;
      const selectedGridState = savedPreset?.selectedGridState;

      const allGridSettingsList =
        gridSettingsList &&
        Object.entries(gridSettingsList).map(([key, value]) => ({
          id: key,
          value: value.settings
        }));

      const selectedListMap =
        allGridSettingsList &&
        (allGridSettingsList.reduce((acc, list) => {
          acc.set(list.id, list.value);
          return acc;
        }, new Map()) as Map<string, GridState>);

      // if the matched list existed call the preset
      if (gridSettingsList && selectedListMap.has(selectedGridState)) {
        return selectedListMap.get(selectedGridState);
      } else {
        return null;
      }
    }
  }

  async applyFilters(option: {
    columnVisibility?: boolean;
    dataFilters?: boolean;
  }): Promise<void> {
    const preset = await this.getUserPreset();

    if (option.columnVisibility) {
      // check these hidden items state from preset
      preset?.colState?.filter(column => {
        if (column.colId === 'customerName') {
          this.customerNamePresetStatus = column.hide;
        }
        if (column.colId === 'accountName') {
          this.accountNamePresetStatus = column.hide;
        }
      });
    }

    if (option.dataFilters) {
      this.accountDashboardService.applyDataFilters(
        preset?.customFilters as AccountDataFilters
      );
    }
  }

  getPlanName(planId: string) {
    return this.plans && this.plans[planId] ? this.plans[planId].name : '';
  }

  getValueForProperty(
    params: ValueGetterParams | ValueFormatterParams,
    property: string
  ) {
    // clear `ValueCache` when grouping is changed
    this.gridOptions.api.expireValueCache();

    if (
      params.node.childrenAfterGroup &&
      params.node.childrenAfterGroup.length &&
      params.node.childrenAfterGroup[0].data &&
      params.node.level === 0
    ) {
      return params.node.childrenAfterGroup[0].data[property];
    }

    if (params.node.level === 0) {
      return params.node.data ? params.node.data[property] : null;
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }
}
