import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'ease-vertical-tab',
  template: '<ng-template><ng-content></ng-content></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerticalTabComponent {
  @ViewChild(TemplateRef) content: TemplateRef<any>;
  @ContentChild('tabCustomTemplate') tabCustomTemplate: TemplateRef<ElementRef>;
  @Input() label: string;
  @Input() position: TooltipPosition = 'before';
  @Input() icon: string;

  constructor() {}
}
