import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ease-customer-budgets-dialog',
  templateUrl: './customer-budgets-dialog.component.html',
  styleUrls: ['./customer-budgets-dialog.component.scss']
})
export class CustomerBudgetsDialogComponent implements OnInit {
  @Input()
  customerId: string;

  constructor() {}

  ngOnInit() {}
}
