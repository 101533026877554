<ease-sheet>
  <ease-sheet-content size="md" class="overflow-y-auto overflow-x-hidden">
    <div class="flex flex-row items-center justify-center">
      <mat-progress-spinner
        *ngIf="isLoading"
        [diameter]="24"
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </div>
    <ng-container *ngIf="websites$ | async as websitesData">
      <ng-container *ngIf="websitesData?.repositories as repositories">
        <div *ngFor="let repo of repositories">
          <mat-form-field>
            <input
              placeholder="Clone URL"
              type="text"
              readonly="true"
              [value]="repo.cloneUrl"
              matInput
            />
            <mat-icon
              title="Copy Clone URL"
              class="mr-1 cursor-pointer"
              matSuffix
              [cdkCopyToClipboard]="repo.cloneUrl"
              (click)="showMessage('Copied repository URL')"
            >
              content_copy
            </mat-icon>
          </mat-form-field>
          <div class="mb-2 -mt-1.5">
            <mat-accordion>
              <mat-expansion-panel
                *ngFor="let branch of repo?.branches"
                class="!m-0 !shadow-none"
              >
                <mat-expansion-panel-header
                  class="!h-7 !pl-0 !pr-4 hover:!bg-transparent"
                >
                  <mat-panel-title class="items-center">
                    <mat-icon class="!mat-icon-16 !mr-1">call_split</mat-icon>
                    <span class="font-bold leading-none">{{
                      branch.name
                    }}</span>
                    <ng-container
                      [ngTemplateOutlet]="statusBlob"
                      [ngTemplateOutletContext]="{
                        $implicit: branch?.websites,
                        statusColor: 'bg-emerald-400',
                        message: 'In use by Google Ads'
                      }"
                    >
                    </ng-container>

                    <ng-container
                      [ngTemplateOutlet]="statusBlob"
                      [ngTemplateOutletContext]="{
                        $implicit: branch?.unusedWebsites,
                        statusColor: 'bg-orange-500',
                        message: 'Not in use'
                      }"
                    >
                    </ng-container>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <ng-container
                  [ngTemplateOutlet]="websiteList"
                  [ngTemplateOutletContext]="{
                    $implicit: branch?.websites,
                    statusColor: 'bg-emerald-400',
                    message: 'In use by Google Ads'
                  }"
                >
                </ng-container>

                <ng-container
                  [ngTemplateOutlet]="websiteList"
                  [ngTemplateOutletContext]="{
                    $implicit: branch?.unusedWebsites,
                    statusColor: 'bg-orange-500',
                    linkColor: '!text-gray-600',
                    message: 'Not in use'
                  }"
                >
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="websitesData?.websites?.length">
        <ease-sheet-divider nested></ease-sheet-divider>
        <div
          class="mb-2 flex flex-row items-center justify-start text-sm text-gray-600"
        >
          <ease-account-icon
            class="mr-1"
            [accountType]="'adwords'"
            [size]="16"
          ></ease-account-icon>
          Sites Without Known Repositories
        </div>
        <div *ngFor="let website of websitesData?.websites">
          <a class="text-blue-500" target="_blank" [href]="website.url">{{
            website.domain
          }}</a>
        </div>
      </ng-container>

      <ng-container *ngIf="websitesData?.manualSites?.length">
        <ease-sheet-divider
          nested
          *ngIf="websitesData?.repositories?.length"
        ></ease-sheet-divider>
        <div class="mb-2 text-sm text-gray-600">Sites Added Manually</div>
        <div
          *ngFor="let website of websitesData?.manualSites"
          class="flex flex-row items-center justify-start"
        >
          <a
            class="text-blue-500"
            target="_blank"
            [href]="website.url"
            class="flex-1"
            >{{ website.url }}</a
          >
          <button (click)="removeWebsite(website.$key)" mat-icon-button>
            <mat-icon class="mat-icon-18">close</mat-icon>
          </button>
        </div>
      </ng-container>
      <div
        class="text-center"
        *ngIf="
          !websitesData?.websites?.length &&
          !websitesData?.manualSites?.length &&
          !websitesData?.repositories?.length
        "
      >
        No Websites
      </div>
    </ng-container>
  </ease-sheet-content>
</ease-sheet>

<ng-template
  #statusBlob
  let-websites
  let-statusColor="statusColor"
  let-message="message"
>
  <div
    *ngIf="websites?.length"
    class="ml-1.5 rounded bg-gray-100 px-1 leading-none"
    [matTooltip]="message"
  >
    <ng-container
      [ngTemplateOutlet]="statusDot"
      [ngTemplateOutletContext]="{
        $implicit: statusColor
      }"
    >
    </ng-container>
    <span class="text-xs">{{ websites?.length }}</span>
  </div>
</ng-template>

<ng-template
  #websiteList
  let-websites
  let-statusColor="statusColor"
  let-linkColor="linkColor"
  let-message="message"
>
  <div class="mt-3" *ngIf="websites?.length">
    <p class="mt-0 mb-2">
      <ng-container
        [ngTemplateOutlet]="statusDot"
        [ngTemplateOutletContext]="{
          $implicit: statusColor
        }"
      >
      </ng-container>
      {{ message }}
    </p>
    <div *ngFor="let website of websites" class="ml-3.5">
      <a
        class="text-blue-500"
        [ngClass]="linkColor"
        target="_blank"
        [href]="website.url"
        >{{ website.domain }}</a
      >
    </div>
  </div>
</ng-template>

<ng-template #statusDot let-color>
  <span
    class="mr-1 inline-block h-2 w-2 rounded-full"
    [ngClass]="color ? color : 'bg-gray-400'"
  ></span>
</ng-template>
