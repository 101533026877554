import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ICellRendererParams } from 'ag-grid-community';
import { Account } from '../../account-dashboard.interface';

@Component({
  selector: 'ease-detail-account-row-renderer',
  templateUrl: './detail-account-row-renderer.component.html',
  styleUrls: ['./detail-account-row-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailAccountRowRendererComponent {
  public account: Account;
  public params: ICellRendererParams;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.account = this.params.data;
  }

  cellChanged(newTargetCpl: number) {
    this.account.targetCpl = newTargetCpl;
    this.params.api.refreshCells({
      rowNodes: [this.params.node],
      force: true
    });
  }
}
