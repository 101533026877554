import firebase from 'firebase/compat/app';
import {
  combineLatest,
  firstValueFrom,
  forkJoin,
  Observable,
  of,
  Subject
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take
} from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { RRule } from 'rrule';
import isEqual from 'lodash-es/isEqual';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { firebaseJSON, isEntityMetadataSame } from '../shared/utils/functions';
import { SNACKBAR_DURATION_SUCCESS } from '../shared/constants';
import {
  LISTS_PATH,
  TASKS_COMPLETED_PATH,
  TASKS_OPEN_PATH,
  TASKS_SCHEDULED_PATH,
  TASK_NOTES_PATH,
  USER_ASSIGNED_TASKS_PATH,
  USER_SUBSCRIBED_TASKS_PATH
} from '../shared/firebase-paths';
import { MappedCache } from '../shared/mapped-cache';
import { NotificationsService } from '../notifications/notifications.service';
import { UserService } from '../users/user.service';
import { EntityMetadata } from '../shared/shared.interface';
import {
  getAssignWrites,
  getSubscribeWrites,
  getUnassignWrites,
  getUnsubscribeWrites
} from '../shared/utils/firebase-functions-common';
import {
  SelectMethod,
  UserSelected
} from '../shared/user-selector/user-selector.interface';
import { ConfirmService } from '../shared/confirm/confirm.service';
import {
  TaskChecklist,
  TaskChecklistData
} from './task-modules/task-checklist/task-checklist.interface';
import { AllTaskListModel, CreatingTaskModel, TaskModel } from './task.model';
import { TaskNote } from './task-notes/task-notes.interface';
import {
  SubtasksProgress,
  SubtasksService
} from './task-project/subtasks.service';

@Injectable({ providedIn: 'root' })
export class TaskService {
  public ref: firebase.database.Reference;
  private tasksById = new MappedCache<TaskModel>();
  private tasksNotesById = new MappedCache<TaskNote[]>();
  public priorityNames = {
    0: 'High',
    1: 'Medium',
    2: 'Low'
  };
  public reloadSource: Subject<any> = new Subject<any>();
  public reload$ = this.reloadSource
    .asObservable()
    .pipe(startWith(null as any));
  private allTaskOpenGetter$ = this.angularFire
    .getList(`/${TASKS_OPEN_PATH}`, ref => ref.orderByChild('updatedAt'))
    .pipe(
      map((tasks: TaskModel[]) =>
        tasks.map(task => this.applyDisplayProps(task))
      ),
      take(1),
      map(tasks => ({ lastUpdated: new Date().getTime(), tasks }))
    );

  private allTasksOpen$: Observable<AllTaskListModel> = this.reload$.pipe(
    switchMap(() => this.allTaskOpenGetter$),
    shareReplay(1)
  );

  constructor(
    private angularFire: FirebaseDbService,
    private notificationsService: NotificationsService,
    private userService: UserService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private subtaskService: SubtasksService,
    private confirmService: ConfirmService
  ) {
    this.ref = this.angularFire.database.ref();
  }

  get(id: string): Observable<TaskModel> {
    return this.tasksById.get(
      id,
      this.angularFire.getObject(`/${TASKS_OPEN_PATH}/${id}`).pipe(
        // Check if an open task exists with this id, otherwise fetch it from the completed tasks endpoint.
        switchMap(data =>
          data.$exists()
            ? of(data)
            : this.angularFire.getObject(`/${TASKS_COMPLETED_PATH}/${id}`)
        ),
        map((task: TaskModel) => this.applyDisplayProps(task)),
        shareReplay(1)
      )
    );
  }

  getOpen(): Observable<AllTaskListModel> {
    return this.allTasksOpen$;
  }

  isTaskOpen(taskId: string): Observable<boolean> {
    return this.angularFire.getObject(`/${TASKS_OPEN_PATH}/${taskId}`).pipe(
      take(1),
      map(data => data.$exists())
    );
  }

  isTaskCompleted(taskId: string): Observable<boolean> {
    return this.angularFire
      .getObject(`/${TASKS_COMPLETED_PATH}/${taskId}`)
      .pipe(
        take(1),
        map(data => data.$exists())
      );
  }

  getWritableTaskPath(taskId: string): Promise<string> {
    // We don't want to create any empty tasks, so check if task is open or completed.
    // Only use this if task metadata could be added to tasksCompleted.
    return firstValueFrom(
      forkJoin([this.isTaskOpen(taskId), this.isTaskCompleted(taskId)]).pipe(
        map(([isOpen, isCompleted]) => {
          if (isOpen) {
            return TASKS_OPEN_PATH;
          }

          if (isCompleted && !isOpen) {
            return TASKS_COMPLETED_PATH;
          }

          throw new Error(
            `The taskId does not exist from both task paths: ${taskId}`
          );
        })
      )
    );
  }

  getForStatus(statusId: string): Observable<TaskModel[]> {
    return this.angularFire
      .getList(`/${TASKS_OPEN_PATH}`, ref =>
        ref.orderByChild(`status/${statusId}`).equalTo(true)
      )
      .pipe(
        map((tasks: TaskModel[]) =>
          tasks.map(task => this.applyDisplayProps(task))
        )
      );
  }

  getForPhase(phaseId: string): Observable<TaskModel[]> {
    return this.angularFire
      .getList(`/${TASKS_OPEN_PATH}`, ref =>
        ref.orderByChild(`phase/${phaseId}`).equalTo(true)
      )
      .pipe(
        map((tasks: TaskModel[]) =>
          tasks.map(task => this.applyDisplayProps(task))
        )
      );
  }

  getForList(listId: string): Observable<TaskModel[]> {
    return this.angularFire
      .getList(`/${TASKS_OPEN_PATH}`, ref =>
        ref.orderByChild('list').equalTo(listId)
      )
      .pipe(
        map((tasks: TaskModel[]) =>
          tasks.map(task => this.applyDisplayProps(task))
        )
      );
  }

  getForEntity(
    meta: Partial<EntityMetadata>,
    basePath: string = TASKS_OPEN_PATH
  ): Observable<TaskModel[]> {
    return this.angularFire
      .getList(`/${basePath}`, ref =>
        ref.orderByChild('entityId').equalTo(meta.entityId)
      )
      .pipe(
        map((tasks: TaskModel[]) =>
          tasks
            .filter(({ entityId, entityType, accountType }) =>
              isEntityMetadataSame(meta, { entityId, entityType, accountType })
            )
            .map(task => this.applyDisplayProps(task))
        )
      );
  }

  getIdsForAssignedUser(userId: string): Promise<string[]> {
    return this.angularFire.database
      .ref(`${USER_ASSIGNED_TASKS_PATH}/${userId}`)
      .once('value')
      .then(snap => Object.keys(snap.val() || {}));
  }

  getIdsForSubscribedUser(userId: string): Promise<string[]> {
    return this.angularFire.database
      .ref(`${USER_SUBSCRIBED_TASKS_PATH}/${userId}`)
      .once('value')
      .then(snap => Object.keys(snap.val() || {}));
  }

  getCompletedForUser(
    userId: string,
    limit: number = 250
  ): Observable<TaskModel[]> {
    return this.angularFire
      .getList(`/${TASKS_COMPLETED_PATH}`, ref =>
        ref.orderByChild('completedBy').equalTo(userId).limitToLast(limit)
      )
      .pipe(
        map((tasks: TaskModel[]) => orderBy(tasks, ['completedAt'], ['desc']))
      );
  }

  moveToEntity(
    oldEntityMeta: Partial<EntityMetadata>,
    newEntityMeta: Partial<EntityMetadata>
  ): Promise<any> {
    const openTasksPromise = firstValueFrom(
      this.getForEntity(oldEntityMeta, TASKS_OPEN_PATH)
    );

    const completedTasksPromise = firstValueFrom(
      this.getForEntity(oldEntityMeta, TASKS_COMPLETED_PATH)
    );

    return Promise.all([openTasksPromise, completedTasksPromise]).then(
      ([openTasks, completedTasks]) => {
        const openTasksUpdatePromise = openTasks.map(openTask =>
          this.angularFire
            .object(`/${TASKS_OPEN_PATH}/${openTask.$key}`)
            .update(newEntityMeta)
        );
        const completedTasksUpdatePromise = completedTasks.map(completedTask =>
          this.angularFire
            .object(`/${TASKS_COMPLETED_PATH}/${completedTask.$key}`)
            .update(newEntityMeta)
        );

        return Promise.all([
          openTasksUpdatePromise,
          completedTasksUpdatePromise
        ]);
      }
    );
  }

  getChecklists(taskId: string): Observable<TaskChecklist[]> {
    return this.get(taskId).pipe(
      map(task => task.checklists),
      filter(checklists => !!checklists),
      distinctUntilChanged(isEqual),
      map(checklists =>
        Object.keys(checklists).map(checklistId => ({
          $key: checklistId,
          data: checklists[checklistId]['data'] || {}
        }))
      )
    );
  }

  async getEntityMeta(taskId: string): Promise<EntityMetadata> {
    const task = await firstValueFrom(this.get(taskId));

    return {
      entityType: task.entityType,
      entityId: task.entityId,
      accountType: task.accountType,
      entityName: task.entityName
    };
  }

  async applyMessageGroupToTask(
    taskId: string,
    messageGroupId: string
  ): Promise<void> {
    const writableTaskPath = await this.getWritableTaskPath(taskId);

    return this.angularFire
      .object(
        `/${writableTaskPath}/${taskId}/customerTransactionalMessageGroups/${messageGroupId}`
      )
      .set(true);
  }

  addChecklist(taskId: string, checklistId: string): Promise<void> {
    return firstValueFrom(
      this.isTaskOpen(taskId).pipe(
        switchMap(isOpen =>
          isOpen
            ? this.angularFire
                .object(
                  `/${TASKS_OPEN_PATH}/${taskId}/checklists/${checklistId}`
                )
                .set({ name: 'Placeholder Name' })
            : Promise.resolve()
        )
      )
    );
  }

  saveChecklist(
    taskId: string,
    checklistKey: string,
    checklistFieldToSave: TaskChecklistData
  ): Promise<void> {
    return firstValueFrom(
      this.isTaskOpen(taskId).pipe(
        switchMap(isOpen =>
          isOpen
            ? this.angularFire
                .object(
                  `/${TASKS_OPEN_PATH}/${taskId}/checklists/${checklistKey}/data`
                )
                .update(checklistFieldToSave)
            : Promise.resolve()
        )
      )
    );
  }

  removeChecklist(taskId: string, checklistId: string): Promise<void> {
    return this.angularFire
      .object(`/${TASKS_OPEN_PATH}/${taskId}/checklists/${checklistId}`)
      .remove();
  }

  update(taskId, task): Promise<void> {
    if (taskId) {
      return this.angularFire
        .object(`/${TASKS_OPEN_PATH}/${taskId}`)
        .update(firebaseJSON(task));
    } else {
      throw new Error(`Tried to update task without taskId: ${task.name}`);
    }
  }

  /**
   * This function check project and children due dates and compare it with provided due date and prompt the user to continue with the change.
   *
   * @param task Task to check parent and children
   * @param dueDate Due Date to compare with project and children due dates
   * @returns If allowed to change the DueDate
   */
  async allowDueDateChange(task: TaskModel, dueDate: number): Promise<boolean> {
    if (dueDate) {
      const isParent = !!task.children;
      const isSubtask = !!task.parent;
      let showWarning: boolean;
      let message: string;

      if (isParent || isSubtask) {
        switch (true) {
          case isParent:
            const subtasks = await firstValueFrom(this.getChildren(task.$key));
            showWarning = subtasks.some(subtask => subtask.dueDate > dueDate);
            message =
              'Some subtasks have due dates later than the date you are setting';
            break;
          case isSubtask:
            const parentTask = await firstValueFrom(this.get(task.parent));
            showWarning = parentTask.dueDate < dueDate;
            message = `Project's is due before the date you are setting`;
            break;
        }

        if (showWarning) {
          const result = await this.confirmService.confirm({
            title: 'Confirm due date',
            message,
            confirmText: 'Change anyway',
            cancelText: 'Cancel'
          });

          return result.confirm;
        }
      }
    }

    return true;
  }

  updateCompleted(taskId: string, task): Promise<any> {
    if (taskId) {
      return this.angularFire
        .object(`/${TASKS_COMPLETED_PATH}/${taskId}`)
        .update(firebaseJSON(task))
        .then(() => firstValueFrom(this.get(taskId)));
    } else {
      throw new Error(
        `Tried to update completed task without taskId: ${task.name}`
      );
    }
  }

  async bumpUpdatedAt(taskId: string): Promise<void> {
    if (taskId) {
      const updatedAt = await this.angularFire.getServerTimestamp();

      return this.angularFire
        .object(`/${TASKS_OPEN_PATH}/${taskId}/updatedAt`)
        .set(updatedAt);
    } else {
      throw new Error(`Tried to bump updatedAt without taskId`);
    }
  }

  async complete(task: TaskModel): Promise<void> {
    const completedAt = await this.angularFire.getServerTimestamp();

    const atomicWrites = {
      [`${LISTS_PATH}/${task.list}/tasks/${task.$key}`]: null,
      [`${TASKS_OPEN_PATH}/${task.$key}`]: null,
      [`${TASKS_COMPLETED_PATH}/${task.$key}`]: firebaseJSON(
        Object.assign({}, task, {
          state: task.snoozeUntil ? 'snoozed' : 'completed',
          completedAt,
          completedBy: this.userService.currentUser.$key,
          assigned: null,
          assignedBy: null,
          delegateMethod: null,
          /**
           * Retains the list of subscribed users, while adding the assigned users,
           * so that the notificationsListener that runs on the server has users to
           * notify. Server cleans this key up after it notifies the correct users.
           */
          subscribed: {
            ...task.assigned,
            ...task.subscribed
          }
        })
      )
    };

    if (task.assigned) {
      Object.keys(task.assigned).forEach(userId => {
        atomicWrites[`${USER_ASSIGNED_TASKS_PATH}/${userId}/${task.$key}`] =
          null;
      });
    }

    if (task.subscribed) {
      Object.keys(task.subscribed).forEach(userId => {
        atomicWrites[`${USER_SUBSCRIBED_TASKS_PATH}/${userId}/${task.$key}`] =
          null;
      });
    }

    return this.ref
      .update(atomicWrites)
      .then(() => this.postCompleteActions(task));
  }

  async uncomplete(task: TaskModel): Promise<void> {
    const reopenedAt = await this.angularFire.getServerTimestamp();

    const assignTo = task.completedBy;
    const taskToReopen = Object.assign({}, task, {
      completedAt: null,
      completedBy: null,
      completionMessage: null,
      snoozeUntil: null,
      state: null,
      status: null,
      reopenedAt
    });

    await this.angularFire
      .object(`/${TASKS_OPEN_PATH}/${task.$key}`)
      .set(firebaseJSON(taskToReopen));
    await this.angularFire
      .object(`/${TASKS_COMPLETED_PATH}/${task.$key}`)
      .remove();

    if (task.completionMessage) {
      await this.addNote(task.$key, {
        body: task.completionMessage,
        createdBy: assignTo,
        createdAt: task.completedAt,
        createdAtReversed: (task.completedAt as number) * -1
      });
    }

    assignTo && (await this.assign({ taskId: task.$key, userId: assignTo }));
  }

  postCompleteActions(task: TaskModel): Promise<void> {
    switch (task.taskType) {
      case 'scheduledTask':
        return this.handleCompletedScheduledTask(task);

      case 'adwordsDeclined':
        return this.handleCompletedAdwordsDeclined(task);

      default:
        return;
    }
  }

  handleCompletedScheduledTask(task: TaskModel): Promise<void> {
    if (task.scheduledTaskTemplateId && task.cancelScheduledTask) {
      return this.angularFire
        .object(`/${TASKS_SCHEDULED_PATH}/${task.scheduledTaskTemplateId}`)
        .remove();
    }
  }

  handleCompletedAdwordsDeclined(task: TaskModel): any {
    return this.angularFire
      .object(`/${task.accountType}Accounts/${task.entityId}/billingStatus`)
      .remove();
  }

  /**
   * Returns true if the user is either assigned or subscribed to a task
   *
   * @param taskId
   * @param userId
   * @returns
   */
  async isUserOnTask(taskId: string, userId: string): Promise<boolean> {
    const task = await firstValueFrom(this.get(taskId));
    return task.assigned?.[userId] || task.subscribed?.[userId];
  }

  unassign(taskId: string, userId: string): Promise<any> {
    return this.ref.update(getUnassignWrites(taskId, userId));
  }

  async assign(params: {
    taskId: string;
    userId: string;
    method?: SelectMethod;
    subscribeAssigner?: boolean;
  }): Promise<any> {
    const { taskId, userId, method, subscribeAssigner = true } = params;

    /**
     * Subscribe the assigner if:
     * - enabled -- true by default
     * - they're not already assigned or subscribed
     * - they're not the assignee
     */
    let subAssignerWrites = {};
    if (subscribeAssigner) {
      const assigner = this.userService.currentUser.$key;
      const isAssignerOnTask = await this.isUserOnTask(taskId, assigner);
      subAssignerWrites =
        isAssignerOnTask || assigner === userId
          ? subAssignerWrites
          : getSubscribeWrites(taskId, assigner, 'direct');
    }

    return await this.ref.update({
      ...getAssignWrites(
        taskId,
        userId,
        this.userService.currentUser.$key,
        method
      ),
      ...subAssignerWrites
    });
  }

  unsubscribe(taskId: string, userId: string): Promise<void> {
    return this.ref.update(getUnsubscribeWrites(taskId, userId));
  }

  subscribe(params: {
    taskId: string;
    userId: string;
    method?: SelectMethod;
  }): Promise<void> {
    const { taskId, userId, method = 'direct' } = params;

    return this.ref.update(getSubscribeWrites(taskId, userId, method));
  }

  move(taskId: string, newBoard: string, newList: string): Promise<void> {
    const writeOps = {};
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/board`] = newBoard;
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/list`] = newList;
    return this.ref.update(writeOps);
  }

  setStatus(taskId: string, statusId?: string): Promise<any> {
    return statusId
      ? this.ref
          .child(`${TASKS_OPEN_PATH}/${taskId}/status`)
          .set({ [statusId]: true })
      : this.ref.child(`${TASKS_OPEN_PATH}/${taskId}/status`).remove();
  }

  setPhase(taskId: string, phaseId?: string): Promise<any> {
    return phaseId
      ? this.ref
          .child(`${TASKS_OPEN_PATH}/${taskId}/phase`)
          .set({ [phaseId]: true })
      : this.ref.child(`${TASKS_OPEN_PATH}/${taskId}/phase`).remove();
  }

  getNotes(taskId: string): Observable<TaskNote[]> {
    return this.tasksNotesById.get(
      taskId,
      this.angularFire.getList(`/${TASK_NOTES_PATH}/${taskId}`, ref =>
        ref.orderByChild('createdAtReversed')
      )
    );
  }

  getPinnedNotes(taskId: string): Observable<TaskNote[]> {
    return this.angularFire
      .getList(`/${TASK_NOTES_PATH}/${taskId}`, ref =>
        ref.orderByChild('pinned').equalTo(true)
      )
      .pipe(map(notes => orderBy(notes, ['createdAt'], ['desc'])));
  }

  async addNote(taskId: string, note): Promise<any> {
    const notesCountRef = this.angularFire.database.ref(
      `${TASKS_OPEN_PATH}/${taskId}/notesCount`
    );
    const timestamp = await this.angularFire.getServerTimestamp();
    const currentUser = this.userService.currentUser.$key;

    note.createdAt = note.createdAt || timestamp;
    note.createdAtReversed = note.createdAtReversed || timestamp * -1;
    note.createdBy = note.createdBy || currentUser;

    /**
     * Subscribe the author of the note if they're not already assigned
     * or subscribed.
     */
    const isUserOnTask = await this.isUserOnTask(taskId, currentUser);
    !isUserOnTask &&
      this.subscribe({ taskId, userId: currentUser, method: 'direct' });

    this.bumpUpdatedAt(taskId);

    return Promise.all([
      notesCountRef.transaction(currentCount =>
        currentCount ? currentCount + 1 : 1
      ),
      this.angularFire
        .list(`/${TASK_NOTES_PATH}/${taskId}`)
        .push(firebaseJSON(note))
    ]);
  }

  updateNote(taskId: string, noteId: string, toMerge: any): Promise<any> {
    if (taskId && noteId) {
      return this.angularFire
        .object(`/${TASK_NOTES_PATH}/${taskId}/${noteId}`)
        .update(firebaseJSON(toMerge));
    }
  }

  /**
   * Separates a list of users from their delegateMethods and returns them
   * as separate Firebase-friendly objects. Example:
   *
   * Input: `[{userId: '19', method: 'direct'}]`
   *
   * Output: `[{'19': true}, {'19': 'direct'}]`
   *
   * @param users
   * @returns
   */
  delegateUsers(users: UserSelected[]): {
    destination: Record<string, boolean>;
    delegateMethod: Record<string, SelectMethod>;
  } {
    const destination: Record<string, boolean> = {};
    const delegateMethod: Record<string, SelectMethod> = {};

    users.forEach((user: UserSelected) => {
      const { userId, method } = user;
      destination[userId] = true;
      delegateMethod[userId] = method;
    });

    return { destination, delegateMethod };
  }

  /**
   * Prepares the list of assigned and subscribed users to write to Firebase
   *
   * @param task
   * @returns
   */
  getAssignedSubbed(task: CreatingTaskModel): CreatingTaskModel {
    if (task.subscribed && task.subscribed.length) {
      const { destination: subscribed, delegateMethod } = this.delegateUsers(
        task.subscribed
      );
      task = { ...task, subscribed, delegateMethod };
    }

    if (task.assigned && task.assigned.length) {
      const { destination: assigned, delegateMethod: assignMethod } =
        this.delegateUsers(task.assigned);
      const delegateMethod = { ...task.delegateMethod, ...assignMethod };
      task = { ...task, assigned, delegateMethod };
    }

    return task;
  }

  async create(task: CreatingTaskModel, showSnackbar = true): Promise<string> {
    const createdAt = await this.angularFire.getServerTimestamp();
    const currentUser = this.userService.currentUser;
    task.createdAt = createdAt;
    task.createdBy = currentUser.$key;

    task = this.getAssignedSubbed(task);

    if (task.scheduled) {
      const taskId = await this.createScheduledTask(task);

      if (showSnackbar) {
        this.matSnackBar.open('Scheduled task created', 'Close', {
          duration: SNACKBAR_DURATION_SUCCESS
        });
      }

      return taskId;
    } else {
      const taskId = await this.createTask(task);

      if (showSnackbar) {
        this.matSnackBar
          .open('Task created', 'Go To Task', {
            duration: SNACKBAR_DURATION_SUCCESS
          })
          .afterDismissed()
          .pipe(take(1))
          .subscribe(dismissResult => {
            if (dismissResult.dismissedByAction) {
              this.router.navigate(['/tasks', taskId]);
            }
          });
      }

      return taskId;
    }
  }

  private async createTask(task: CreatingTaskModel): Promise<string> {
    const newTaskRef = await this.ref
      .child(TASKS_OPEN_PATH)
      .push(firebaseJSON(task));
    const taskId = newTaskRef.key;

    if (task.assigned) {
      /**
       * Don't autosubscribe assigner/author when creating tasks. If the
       * author wanted to subscribe, they would've added themselves as a
       * subscriber on the task form.
       */
      const subscribeAssigner = false;

      for (const userId in task.assigned) {
        if (task.assigned.hasOwnProperty(userId)) {
          const method = task.delegateMethod[userId];
          await this.assign({
            taskId,
            userId,
            method,
            subscribeAssigner
          });
        }
      }
    }

    return taskId;
  }

  private createScheduledTask(task: CreatingTaskModel): Promise<string> {
    const ruleOptions = RRule.parseString(task.rrule);
    const scheduledAt = moment(ruleOptions.dtstart).valueOf();
    const now = moment().startOf('day').valueOf();
    let futureTaskWrite;
    let createTaskWrite;

    /**
     * If the schedule is set for the future, or if the rrule interval
     * is something besides 'NEVER', create a scheduled task.
     */
    if (scheduledAt > now || !ruleOptions.count) {
      futureTaskWrite = this.ref
        .child(TASKS_SCHEDULED_PATH)
        .push(firebaseJSON(task));
    }

    /**
     * If the schedule is set for today, create a regular task immediately.
     */
    if (scheduledAt <= now) {
      task.taskType = 'scheduledTask';
      createTaskWrite = this.createTask(task);

      // If a scheduled task was pushed, and a new task was created immediately,
      // push a 'triggeredAt' timestamp of 'now'
      futureTaskWrite && futureTaskWrite.child('triggeredAt').push(now);
    }

    return createTaskWrite
      ? createTaskWrite
      : Promise.resolve(futureTaskWrite.key);
  }

  private applyDisplayProps(task: TaskModel): TaskModel {
    task.$displayPhase = task.phase ? Object.keys(task.phase) : [];
    task.$displayStatus = task.status ? Object.keys(task.status) : [];
    task.$displayAssigned = task.assigned ? Object.keys(task.assigned) : [];
    task.$displaySubscribed = task.subscribed
      ? Object.keys(task.subscribed)
      : [];
    return task;
  }

  async destroy(task: TaskModel, notifyUser = false): Promise<void> {
    if (task) {
      if (task.assigned) {
        for (const userId in task.assigned) {
          if (task.assigned.hasOwnProperty(userId)) {
            await this.unassign(task.$key, userId);
          }
        }
      }

      if (task.subscribed) {
        for (const userId in task.subscribed) {
          if (task.subscribed.hasOwnProperty(userId)) {
            await this.unsubscribe(task.$key, userId);
          }
        }
      }

      // Detach task from parent task if has one
      if (task.parent) {
        await this.subtaskService.detach(task.parent, task.$key);
      }

      // Destroy child tasks if any
      if (task.children) {
        const subtasks = await firstValueFrom(
          combineLatest(
            Object.keys(task.children).map(childKey => this.get(childKey))
          )
        );

        if (subtasks.length) {
          for (const subtask of subtasks) {
            await this.destroy(subtask, false);
          }
        }
      }

      await this.angularFire
        .object(`/${TASKS_OPEN_PATH}/${task.$key}`)
        .remove();

      await this.notificationsService.deleteForItem(task.$key);

      notifyUser &&
        this.matSnackBar.open('Task destroyed', 'Close', {
          duration: SNACKBAR_DURATION_SUCCESS
        });
    }
  }

  /**
   *  Get the subtasks of a task.
   *
   * @param taskId The id of the parent task
   * @returns  The children tasks of the parent task
   */
  getChildren(taskId: string): Observable<TaskModel[]> {
    return this.get(taskId).pipe(
      switchMap(task =>
        task.children
          ? combineLatest(
              Object.keys(task.children).map(childId => this.get(childId))
            )
          : of([])
      ),
      map(children => children.sort((a, b) => (a.state ? 1 : -1))),
      shareReplay(1)
    );
  }

  /**
   *  Get the progress of a task based on they subtask state.
   *
   * @param taskId The id of the parent task
   * @returns  The progress of the subtasks of the parent task
   */
  getProgress(taskId: string): Observable<SubtasksProgress> {
    return this.getChildren(taskId).pipe(
      map(children => ({
        total: children.length,
        completed: children.filter(child => child.state).length,
        progress:
          (children.length &&
            (children.filter(child => child.state).length / children.length) *
              100) ||
          0
      })),
      shareReplay(1)
    );
  }
}
