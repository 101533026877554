import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  combineLatest,
  EMPTY,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap
} from 'rxjs/operators';
import * as moment from 'moment';

import { environment } from 'src/environments/environment';
import { SkSyncHttpService } from '../sksync-http.service';
import { WindowService } from '../window/window.service';
import { UserService } from '../../users/user.service';
import {
  CreateFundingPayload,
  DateRange,
  FundingEventLine,
  FundingSettings,
  FundingSummary,
  FundingEventLineType,
  FundingSettingsRow
} from './funding.interface';

@Injectable({
  providedIn: 'root'
})
export class FundingService {
  /**
   * Date in which the current year's funding is
   * uploaded for the first time.
   *
   * However, for performance reasons it helps to only
   * have to query funding events for the current
   * funding period.
   */
  public startDate = '2022-02-07';
  public productCodeToAccountTypeMapping = {
    glsconsulting: 'lsa',
    googleadsgoldplan: 'adwords',
    googleadwordsconsulting: 'adwords',
    googlepurpleplanpurchase: 'adwords'
  };
  public reloadSource: ReplaySubject<void> = new ReplaySubject<void>();
  public reload$ = this.reloadSource
    .asObservable()
    .pipe(startWith(), shareReplay({ refCount: true, bufferSize: 1 }));
  private currentCustomerId: string;
  public fundingCustomerIdSubject: ReplaySubject<string> =
    new ReplaySubject<string>();
  public fundingCustomerId$ = this.fundingCustomerIdSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  public fundingSettings$: Observable<FundingSettings>;
  public fundingSummary$: Observable<FundingSummary>;

  constructor(
    private afStorage: AngularFireStorage,
    private skSyncHttp: SkSyncHttpService,
    private windowService: WindowService,
    private userService: UserService
  ) {
    this.fundingSettings$ = combineLatest([
      this.fundingCustomerId$,
      this.reload$
    ]).pipe(
      switchMap(([customerId]: [string, any]) => {
        this.currentCustomerId = customerId;
        return this.getFundingSettings(customerId);
      }),
      map(settingsData => this.parseFundingSettings(settingsData)),
      shareReplay(1)
    );

    this.fundingSummary$ = combineLatest([
      this.fundingCustomerId$,
      this.reload$
    ]).pipe(
      switchMap(([customerId]: [string, any]) => {
        this.currentCustomerId = customerId;
        return this.getFundingSummary(customerId);
      }),
      shareReplay(1)
    );
  }

  getFundingSettings(customerId: string): Observable<FundingSettingsRow[]> {
    return this.skSyncHttp.get(`/funding/settings/${customerId}`);
  }

  getFundingTypes(): Observable<FundingEventLineType[]> {
    return this.skSyncHttp.get(`/funding/types`).pipe(catchError(() => of([])));
  }

  getFundingList(
    customerId?: string,
    dateRangeParams?: DateRange
  ): Observable<FundingEventLine[]> {
    return this.skSyncHttp
      .get<FundingEventLine[]>(`/funding/events`, {
        params: {
          ...(customerId ? { customerId } : {}),
          ...dateRangeParams
        }
      })
      .pipe(catchError(() => EMPTY));
  }

  create(customerId: string) {
    this.windowService.create({
      type: 'createFunding',
      data: {
        customerId
      },
      title: 'Add New Funding',
      icon: 'card_giftcard'
    });
  }

  createFunding(FundingEventPayload: CreateFundingPayload): Observable<any> {
    return this.skSyncHttp.post(`/funding`, FundingEventPayload);
  }

  // transform backend response for front-end use
  parseFundingSettings(settingsArray: FundingSettingsRow[]): FundingSettings {
    if (!settingsArray || settingsArray.length === 0) {
      return { exist: false };
    }

    const fundingEventLineType: FundingEventLineType =
      settingsArray[0].fundingEventLineType || ({} as FundingEventLineType);

    const fundingSettings: FundingSettings = {
      exist: true,
      paymentSource: settingsArray[0].paymentSource,
      managementFee: null, // Set below
      billingDay: settingsArray[0].billingDay,
      updatedAt: settingsArray[0].updatedAt,
      customerId: this.currentCustomerId,
      advertisingAccount: null, // Set below
      fundingEventLineType
    };

    // get managmentFee
    // rounding up on second decimal
    fundingSettings.managementFee =
      Math.round(
        settingsArray
          .map(item => item.managementFee)
          .reduce((acc, cur) => acc + cur) * 10
      ) / 10;

    // parse and transform all account data as one property
    if (settingsArray) {
      fundingSettings.advertisingAccount = settingsArray.map(account => ({
        id: account.advertisingAccount.id,
        type: account.advertisingAccount.type,
        name: account.advertisingAccount.name
      }));
    }

    return fundingSettings;
  }

  saveCustomerFundingSettings(customerId: string, fundingSettingsPayload) {
    return this.skSyncHttp.post(
      `/funding/settings/${customerId}`,
      fundingSettingsPayload
    );
  }

  /**
   * Returns the breakdown for all customer-level or company-level funding.
   * - if customerId is provided on the request, returns customer-level
   * - if both startDate and endDate are provided, returns breakdown for
   * funding events within the range
   * - if there were none provided, returns company-level breakdown for
   * funding events starting from the default START_DATE
   *
   * @param customerId
   * @param dateRangeParams
   * @returns an array of funding breakdown objects
   */
  getFundingSummary(
    customerId?: string,
    dateRangeParams?: DateRange
  ): Observable<FundingSummary> {
    return this.skSyncHttp
      .get(`/funding/summary`, {
        params: {
          ...(customerId ? { customerId } : {}),
          ...dateRangeParams
        }
      })
      .pipe(catchError(() => of([])));
  }

  reloadFundingData() {
    this.reloadSource.next();
  }

  setFundingCustomerId(customerId: string) {
    this.fundingCustomerIdSubject.next(customerId);
  }

  async uploadCsv(csvFile: File) {
    return await this.afStorage.storage.app
      .storage(environment.LMG_BUCKET)
      .ref()
      .child(`SearchKings_${moment().format('YYYY-MM-DD')}.csv`)
      .put(csvFile);
  }

  async merge(sourceCustomerId: string, destinationCustomerId: string) {
    return firstValueFrom(
      this.skSyncHttp.post(`/funding/merge`, {
        sourceCustomerId,
        destinationCustomerId
      })
    );
  }

  adjustCustomerCredit(payload: any[]) {
    return this.skSyncHttp.post(`/funding/adjustment`, payload);
  }

  voidTransaction(eventId: string) {
    return this.skSyncHttp.post(`/funding/events/${eventId}/void`, {
      userId: this.userService.currentUser.$key
    });
  }
}
