import { Component, Input } from '@angular/core';

@Component({
  selector: 'ease-toggle',
  exportAs: 'toggle',
  template: ` <ng-content></ng-content> `
})
export class ToggleComponent {
  @Input()
  isHidden: boolean = true;

  hide() {
    this.isHidden = true;
  }

  show() {
    this.isHidden = false;
  }

  toggle() {
    this.isHidden = !this.isHidden;
  }
}
