import { Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
  tap
} from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { GoogleAuthProvider } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { environment } from '../../environments/environment';
import { bugsnagClient } from '../shared/error-handler';
import { EnvironmentService } from '../shared/environment.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public authToken: string;
  public firebaseUser$: Observable<firebase.User>;
  public newUserSigningIn$: Subject<string> = new Subject<string>();

  constructor(
    private afAuth: AngularFireAuth,
    private angularFire: AngularFireDatabase,
    private environmentService: EnvironmentService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.getAuthorizedUser();
    this.unAuthorizedHandler();

    this.afAuth.idToken
      .pipe(map(idToken => idToken || null))
      .subscribe(token => (this.authToken = token));
  }

  /**
   * A listener with extra logic to handle if user be (un)authStated
   */
  private getAuthorizedUser(): void {
    this.firebaseUser$ = this.afAuth.authState.pipe(
      distinctUntilChanged(),
      map(authState => {
        /**
         * While a user is(not) being authorized, (dis)connect to the Firebase database
         *
         * This could avoid permission errors if a user has no longer been authorized since
         * it is impossible to destroy all of the database subscriptions in the entire app
         */
        if (!!authState) {
          this.angularFire.database.goOnline();
        } else {
          this.angularFire.database.goOffline();
        }

        return authState;
      }),
      tap(authState => {
        if (authState) {
          /**
           * Set user detail for Bugsnag when it is authorized
           * Note, when the user becomes unauthorized, we are still
           * using the previous authState until the APP refreshed.
           */
          bugsnagClient.setUser(
            authState.uid,
            authState.email,
            authState.displayName
          );
        }
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  /**
   * A listener for all Eases(browser tabs) to trigger redirectToLogin
   * E.g. once signed out from one Ease, redirect all other Ease tabs to
   * the login page but keeping previous page(redirectTo) for resigning-in
   *
   * Also, using `pairwise` could avoid to trigger redirection at the initial state
   */
  private unAuthorizedHandler(): void {
    this.firebaseUser$
      .pipe(
        startWith(null),
        pairwise(),
        filter(
          ([previousUser, currentUser]) => !!previousUser && !!!currentUser
        )
      )
      .subscribe(() => {
        this.redirectToLogin({ redirectUrl: this.router.url });
      });
  }

  async loginWithGoogle(): Promise<firebase.auth.UserCredential> {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    const userCredentials = await this.afAuth.signInWithPopup(provider);

    if (!environment.userId && userCredentials.additionalUserInfo.isNewUser) {
      this.newUserSigningIn$.next(userCredentials.user.uid);
    }

    return userCredentials;
  }

  /**
   * Note, redirection would be handled by `unAuthorizedHandler()`
   */
  logout(): Promise<void> {
    return this.afAuth.signOut();
  }

  /**
   * To avoid growing duplicated "redirectTo=" param, deduplicated if necessary
   *
   * @param redirectUrl
   * @returns Params
   */
  private getUniqueRedirectParam(redirectUrl: string): Params {
    const existingRedirectParam =
      this.route.snapshot.queryParamMap.get('redirectTo');

    return redirectUrl !== '/'
      ? {
          redirectTo: existingRedirectParam
            ? existingRedirectParam
            : redirectUrl
        }
      : null;
  }

  async redirectToLogin({
    redirectUrl,
    reload = true
  }: {
    redirectUrl?: string;
    reload?: boolean;
  }): Promise<void> {
    const queryParams = this.getUniqueRedirectParam(redirectUrl);
    await this.router.navigate(['/auth', 'login'], {
      queryParams
    });

    // Before reloading, unregister service workers and clear all caches
    await this.environmentService.unregisterServiceWorkersAndClearCaches();
    /**
     * Once redirected to the login page, also reload the page to
     * clear any local memories/caches if needed
     * E.g. WindowPanes @see WindowService.windows
     */
    reload && window.location.reload();
  }
}
