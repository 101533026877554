import { NgControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[easeDisableControl]'
})
export class DisableControlDirective {
  @Input()
  set easeDisableControl(condition: boolean) {
    if (!this.ngControl) {
      throw new Error(
        `You must use [easeDisableControl] on an element with a reactive form directive applied. For example [formControl], formControlName, formGroupName, etc.`
      );
    }

    /**
     * Guard against race condition where ngControl.control is undefined
     * despite ngControl being defined.
     */
    if (this.ngControl.control) {
      const action = condition ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
  }

  constructor(private ngControl: NgControl) {}
}
