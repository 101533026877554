<div
  class="metric row details flex flex-col"
  [ngClass]="optiscoreMetric?.ragStatusColor"
>
  <!--header-->
  <div class="row header flex flex-1 flex-row">
    <div class="column cell flex flex-row items-center justify-center">
      <div class="graph flex flex-col">
        <div class="dot flex flex-row">
          <div class="indicator metric-background"></div>
        </div>
      </div>

      <div class="status-summary flex-1">
        {{ optiscoreMetric?.ragStatusDescription }}
      </div>
    </div>
  </div>
  <!--details-->
  <div class="detail-summary row flex flex-row">
    <div
      class="detail-summary-group column detail flex max-w-[60%] flex-full flex-row"
    >
      <div
        class="column cell detail flex flex-1 flex-col items-center justify-center"
      >
        <ease-metric-simple
          label="Optiscore"
          [value]="account.osMetric.optiscore + '%'"
        ></ease-metric-simple>
        <div
          matTooltip="This account has a high impact on our Agency-level optiscore"
          class="mt-2 rounded-full bg-gray-100 py-1 px-2 text-center"
          *ngIf="
            optiscoreMetric.optiscoreWeight >
            accountDashboardService.highImpactOptiscoreWeight
          "
        >
          <span class="flex items-center justify-center p-1 text-xs">
            <mat-icon class="mat-icon-16 mr-1">stars</mat-icon
            ><strong>High Impact Account</strong>
          </span>
        </div>
        <div class="flex items-center p-2">
          <mat-icon class="mat-icon-16 mr-2 flex-none">info</mat-icon>
          <div class="text-xs">
            This score is a snapshot as of yesterday and is not updated in
            real-time like in the Ads UI.
          </div>
        </div>
      </div>
    </div>
    <div
      class="column detail max-w-[40%] flex-full"
      *ngIf="account.osMetric?.metadata?.chartData.labels.length"
    >
      <ease-chart
        [height]="130"
        [data]="account.osMetric?.metadata?.chartData"
        [type]="'line'"
        [options]="accountDashboardService.percentageChartOptions"
      >
      </ease-chart>
    </div>
  </div>
</div>
