import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar
} from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';

import { SNACKBAR_DURATION_SUCCESS } from 'src/app/shared/constants';
import { ConfirmService } from '../../../shared/confirm/confirm.service';
import { TaskService } from '../../task.service';
import { ScheduledTask } from '../scheduled-task.interface';
import { ScheduledTaskService } from '../scheduled-task.service';

@Component({
  selector: 'ease-scheduled-task-edit-dialog',
  templateUrl: './scheduled-task-edit-dialog.component.html',
  styleUrls: ['./scheduled-task-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduledTaskEditDialogComponent implements OnInit {
  @Input()
  set taskId(taskId: string) {
    this._templateId = taskId;

    if (this.editForm) {
      this.setupEditForm(taskId);
    }
  }

  get taskId(): string {
    return this._templateId;
  }

  private _templateId: string;
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  public editForm: FormGroup<{
    fields: FormControl<ScheduledTask>;
  }> = this.formBuilder.group({
    fields: this.formBuilder.control(null)
  });
  public isWorking: boolean = false;

  constructor(
    private confirmService: ConfirmService,
    private dialogRef: MatDialogRef<ScheduledTaskEditDialogComponent>,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private scheduledTaskService: ScheduledTaskService,
    private taskService: TaskService
  ) {}

  ngOnInit() {
    if (this.taskId) {
      this.setupEditForm(this.taskId);
    }
  }

  setupEditForm(templateId: string): Promise<void> {
    return firstValueFrom(
      this.scheduledTaskService.getFormValueForTask(templateId)
    ).then(formValue => this.editForm?.controls?.fields.setValue(formValue));
  }

  remove() {
    this.confirmService
      .confirm({
        title: 'Remove Scheduled Task',
        message: 'Are you sure you want to remove this scheduled task?',
        confirmText: 'Yes, remove'
      })
      .then(confirmResult => {
        if (confirmResult.confirm) {
          this.scheduledTaskService.remove(this.taskId).then(() => {
            this.matSnackBar.open(`Scheduled task deleted.`, 'Close', {
              duration: SNACKBAR_DURATION_SUCCESS
            });
            this.dialogRef.close();
          });
        }
      });
  }

  async save() {
    if (this.editForm.valid) {
      this.isWorking = true;
      this.snackBarRef && this.snackBarRef.dismiss();
      this.snackBarRef = null;

      let { fields } = this.editForm.value;

      /**
       * If the user has applied a checklist, format it in the expected format.
       */
      if (fields.checklist) {
        fields.checklists = {
          [fields.checklist]: {
            name: 'placeholder'
          }
        };

        fields.checklist = null;
      }

      // Transform assigned and subscribed from array for Firebase saving
      fields = this.taskService.getAssignedSubbed(fields) as ScheduledTask;

      this.scheduledTaskService
        .update(this.taskId, fields)
        .then(() => {
          this.matSnackBar.open(`Scheduled task saved.`, 'Close', {
            duration: SNACKBAR_DURATION_SUCCESS
          });
          this.dialogRef.close();
        })
        .catch(() => {
          this.matSnackBar.open('An error occured', 'Close');
          this.isWorking = false;
        });
    }
  }
}
