/**
 * This file exports constants for the firebase paths that will be used across
 * the application.
 */

export const AUTH0_TOKEN_CACHE_PATH = 'auth0TokenCache';

export const ACCOUNT_METADATA_PATH = 'accountData';

export const ADWORDS_ACCOUNT_BUDGET_CHANGES_PATH =
  'adwordsAccountBudgetChanges';
export const ADWORDS_ACCOUNT_FEEDS_PATH = 'adwordsAccountFeeds';
export const ADWORDS_ACCOUNT_INVOICES_PATH = 'adwordsAccountInvoices';
export const ADWORDS_ACCOUNT_RULES_PATH = 'adwordsAccountRules';
export const ADWORDS_ACCOUNT_NOTES_PATH = 'adwordsAccountNotes';
export const ADWORDS_ACCOUNT_SNOOZED_STATES_PATH =
  'adwordsAccountSnoozedStates';
export const ADWORDS_ACCOUNT_STATUS_CHANGES_PATH =
  'adwordsAccountStatusChanges';
export const ADWORDS_ACCOUNTS_PATH = 'adwordsAccounts';
export const ADWORDS_ACCOUNT_SUBSCRIPTIONS_PATH = 'adwordsSubscribedAccounts';
export const ADWORDS_ROLE_CHANGES_PATH = 'adwordsRoleChanges';

export const ANALYTICS_LINKS_PATH = 'analyticsLinks';

export const ANNOUNCEMENTS_PATH = 'announcements';
export const ANNOUNCEMENTS_COMPANY_PATH = 'announcementsCompany';
export const ANNOUNCEMENTS_USER_PATH = 'announcementsUser';

export const APP_VERSION_PATH = 'versionInfo/version';
export const APP_CHANGELOG_PATH = 'versionInfo/changelog';

export const BING_ACCOUNT_BUDGET_CHANGES_PATH = 'bingAccountBudgetChanges';
export const BING_ACCOUNT_FEEDS_PATH = 'bingAccountFeeds';
export const BING_ACCOUNT_INVOICES_PATH = 'bingAccountInvoices';
export const BING_ACCOUNT_RULES_PATH = 'bingAccountRules';
export const BING_ACCOUNT_SNOOZED_STATES_PATH = 'bingAccountSnoozedStates';
export const BING_ACCOUNT_STATUS_CHANGES_PATH = 'bingAccountStatusChanges';
export const BING_ACCOUNT_NOTES_PATH = 'bingAccountNotes';
export const BING_ACCOUNTS_PATH = 'bingAccounts';
export const BING_ACCOUNT_SUBSCRIPTIONS_PATH = 'bingSubscribedAccounts';
export const BING_ROLE_CHANGES_PATH = 'bingRoleChanges';

export const BOARDS_PATH = 'boards';

export const CALL_TRACKING_ACCOUNTS_PATH = 'callTrackingAccounts';

export const CHECKLIST_TEMPLATES_PATH = 'checklistTemplates';
export const CHECKLIST_COLLAPSED_STATUS_PATH = 'checklistCollapseStatus';

export const CUSTOMER_ACCOUNTS_PATH = 'customerAccounts';
export const CUSTOMER_ACCOUNTS_SNOOZED_STATES_PATH =
  'customerAccountsSnoozedStates';
export const CUSTOMER_ANALYTICS_LINKS_PATH = 'customerAnalyticsLinks';
export const CUSTOMER_BUDGET_CHANGES_PATH = 'customerBudgetChanges';
export const CUSTOMER_COMBINED_FEEDS_PATH = 'customerCombinedFeeds';
export const CUSTOMER_COMMUNICATIONS_PATH = 'customerCommunications';
export const CUSTOMER_CONTACTS_PATH = 'customerContacts';
export const CUSTOMER_ID_TRANSACTION_PATH = 'currentCustomerId';
export const CUSTOMER_MESSAGES_PATH = 'customerTransactionalMessages';
export const CUSTOMER_MESSAGE_GROUPS_PATH =
  'customerTransactionalMessageGroups';
export const CUSTOMER_NOTES_PATH = 'customerNotes';
export const CUSTOMER_PLANS_PATH = 'customerPlans';
export const CUSTOMER_RULES_PATH = 'customerRules';
export const CUSTOMER_STATUS_CHANGES_PATH = 'customerStatusChanges';
export const CUSTOMER_PLAN_CHANGES_PATH = 'customerPlanChanges';
export const CUSTOMER_TAG_MANAGER_LINKS_PATH = 'customerTagManagerLinks';
export const CUSTOMER_STICKY_NOTES_PATH = 'customerStickyNotes';
export const CUSTOMERS_PATH = 'customers';
export const CUSTOMER_WEBSITES_PATH = 'customerWebsites';
export const CUSTOMER_ROLE_CHANGES_PATH = 'customerRoleChanges';

export const CONTACTS_PATH = 'contacts';
export const CONTACT_CUSTOMERS_PATH = 'contactCustomers';

export const EMAIL_TEMPLATES_SETTINGS = 'emailTemplateSettings';
export const EMAIL_TEMPLATES_SETTINGS_ARCHIVE = 'emailTemplateSettingsArchive';

export const JOBS_PATH = 'jobs';

export const LISTS_PATH = 'lists';

export const LSA_ACCOUNT_BUDGET_CHANGES_PATH = 'lsaAccountBudgetChanges';
export const LSA_ACCOUNT_FEEDS_PATH = 'lsaAccountFeeds';
export const LSA_ACCOUNT_NOTES_PATH = 'lsaAccountNotes';
export const LSA_ACCOUNT_SNOOZED_STATES_PATH = 'lsaAccountSnoozedStates';
export const LSA_ACCOUNT_STATUS_CHANGES_PATH = 'lsaAccountStatusChanges';
export const LSA_ACCOUNTS_PATH = 'lsaAccounts';
export const LSA_ACCOUNT_RULES_PATH = 'lsaAccountRules';
export const LSA_ACCOUNT_SUBSCRIPTIONS_PATH = 'lsaSubscribedAccounts';
export const LSA_ROLE_CHANGES_PATH = 'lsaRoleChanges';

export const NEGATIVE_KEYWORDS_PATH = 'negativeKeywords';
export const NEGATIVE_KEYWORDS_LISTS_PATH = 'lists';
export const NEGATIVE_KEYWORDS_LISTGROUPS_PATH = 'listGroups';

export const NOTIFICATIONS_PATH = 'notifications';
export const NOTIFICATION_TOKENS_PATH = 'notificationsTokens';

export const TAG_MANAGER_CUSTOMER_JOBS = 'tagManagerCustomerJobs';
export const TAG_MANAGER_CONTAINER_JOBS = 'tagManagerContainerJobs';
export const TAG_MANAGER_LINKS_PATH = 'tagManagerLinks';

export const TASK_STATUSES_PATH = 'taskStatuses';
export const TASK_PHASES_PATH = 'taskPhases';
export const TASK_TEMPLATES_PATH = 'taskTemplates';
export const TASK_NOTES_PATH = 'taskNotes';
export const TASKS_COMPLETED_PATH = 'tasksCompleted';
export const TASKS_OPEN_PATH = 'tasksOpen';
export const TASKS_SCHEDULED_PATH = 'tasksScheduled';

export const TYPESENSE_UPDATES_PATH = 'typesenseUpdates';
export const TYPESENSE_DISABLE_SYNC_PATH = 'typesenseDisableSync';

export const SCHEDULED_STATUSES_PATH = 'scheduledStatuses';

export const SEARCH_REQUEST_PATH = 'search/request';
export const SEARCH_RESPONSE_PATH = 'search/response';

export const USER_ASSIGNED_TASKS_PATH = 'userAssignedTasks';
export const USER_AVATARS_PATH = 'userAvatars';
export const USER_ROLES_PATH = 'userRoles';
export const USER_GROUPS_PATH = 'userGroups';
export const USER_SETTINGS_PATH = 'userSettings';
export const USER_SUBSCRIBED_TASKS_PATH = 'userSubscribedTasks';
export const USERS_PATH = 'users';
export const USER_TASK_BACKPACKS_PATH = 'userTaskBackpacks';
export const USER_TASK_DASHBOARDS_PATH = 'userTaskDashboards';

export const PERMISSION_ROLES_PATH = 'permissionRoles';
export const PERMISSION_GROUPS_PATH = 'permissionGroups';
export const PERMISSIONS_PATH = 'permissions';

export const TASK_DASHBOARDS_PATH = 'taskDashboards';
export const TASK_DASHBOARD_WIDGETS_PATH = 'taskDashboardWidgets';
