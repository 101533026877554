import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ScrollSpySectionElement } from './scrollspy.interface';

@Injectable({
  providedIn: 'root'
})
export class ScrollspyService {
  private sectionInViewSource$: Subject<string> = new Subject<string>();
  private scrollingEventSource$: ReplaySubject<Event> =
    new ReplaySubject<Event>(1);
  public scrollingEvent$: Observable<Event> =
    this.scrollingEventSource$.asObservable();
  public sectionInView$: Observable<string> =
    this.sectionInViewSource$.asObservable();
  public watchSections: ScrollSpySectionElement[] = [];

  constructor() {}

  setSectionInView(sectionId: string): void {
    this.sectionInViewSource$.next(sectionId);
  }

  addSection(sectionElement: ScrollSpySectionElement): void {
    if (sectionElement && sectionElement.id) {
      this.watchSections.push(sectionElement);
    } else {
      console.log('SectionElement needs an ID attached');
    }
  }

  removeSection(sectionId: string): void {
    if (this.watchSections) {
      this.watchSections = this.watchSections.filter(
        section => section.id !== sectionId
      );
    }
  }

  setScrollEvent(event: Event): void {
    this.scrollingEventSource$.next(event);
  }

  scrollToTop(): void {
    this.scrollingEvent$.pipe(take(1)).subscribe(event =>
      (event.target as HTMLElement).scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    );
  }
}
