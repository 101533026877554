import { RouterModule, Routes } from '@angular/router';

import { HomepageGuard } from './homepage.guard';
import { AuthGuard } from './auth/auth.guard';
import { PageTitleGuard } from './shared/page-title/page-title.guard';
import { NotFoundComponent } from './shared/layout/not-found/not-found.component';
import { CustomerAccountRedirectGuard } from './customers/customer-accounts/customer-account-redirect.guard';
import { PermissionGuard } from './admin/admin-roles/permission.guard';
import { WindowRouteComponent } from './shared/window/window-route/window-route.component';
import {
  WINDOW_ROUTER_OUTLET,
  WINDOW_ROUTER_PATH
} from './shared/window/window-options';
import { WindowCreateGuard } from './shared/window/window-create.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, HomepageGuard],
    children: [],
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('src/app/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('src/app/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, PageTitleGuard],
    canLoad: [AuthGuard],
    data: {
      title: 'Admin'
    }
  },
  {
    path: 'tasks',
    loadChildren: () =>
      import('src/app/tasks/tasks.module').then(m => m.TasksModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'dashboards',
    loadChildren: () =>
      import('src/app/tasks/task-dashboards/task-dashboards.module').then(
        m => m.TaskDashboardsModule
      ),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'boards',
    loadChildren: () =>
      import('src/app/boards/boards.module').then(m => m.BoardsModule),
    canActivate: [AuthGuard, PageTitleGuard],
    canLoad: [AuthGuard],
    data: {
      title: 'Boards'
    }
  },
  {
    path: 'accounts',
    loadChildren: () =>
      import('src/app/accounts/accounts.module').then(m => m.AccountsModule),
    canActivate: [AuthGuard, PageTitleGuard],
    canLoad: [AuthGuard],
    data: {
      title: 'Accounts'
    }
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('src/app/customers/customers.module').then(m => m.CustomersModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'funding',
    loadChildren: () =>
      import('src/app/funding/funding.module').then(m => m.FundingModule),
    canActivate: [AuthGuard, PageTitleGuard, PermissionGuard],
    canLoad: [AuthGuard],
    data: {
      title: 'Funding',
      permissionId: 'accessFundingList'
    }
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import(
        'src/app/customers/customer-onboarding/customer-onboarding-list/customer-onboarding-list.module'
      ).then(m => m.CustomerOnboardingListModule),
    canActivate: [AuthGuard, PageTitleGuard],
    canLoad: [AuthGuard],
    data: {
      title: 'Onboarding'
    }
  },
  {
    path: 'prospects',
    loadChildren: () =>
      import('src/app/customers/prospects.module').then(m => m.ProspectsModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () =>
      import('src/app/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'negative-keywords',
    loadChildren: () =>
      import('src/app/negative-keywords/negative-keywords.module').then(
        m => m.NegativeKeywordsModule
      ),
    canActivate: [AuthGuard, PageTitleGuard],
    canLoad: [AuthGuard],
    data: {
      title: 'Negative Keywords'
    }
  },
  {
    path: 'jobs',
    loadChildren: () =>
      import('src/app/jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [AuthGuard, PageTitleGuard],
    canLoad: [AuthGuard],
    data: {
      title: 'Jobs'
    }
  },
  {
    path: 'accounts',
    canActivate: [AuthGuard, PageTitleGuard],
    data: {
      title: 'Accounts'
    },
    children: [
      {
        path: ':accountType/:accountId',
        component: NotFoundComponent,
        canActivate: [CustomerAccountRedirectGuard]
      }
    ]
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('src/app/contacts/contacts-route.module').then(
        m => m.ContactsRouteModule
      ),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: `${WINDOW_ROUTER_PATH}/create/:windowType`,
    canActivate: [AuthGuard, WindowCreateGuard],
    component: NotFoundComponent
  },

  {
    path: WINDOW_ROUTER_PATH,
    outlet: WINDOW_ROUTER_OUTLET,
    component: WindowRouteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/dashboards',
    pathMatch: 'full'
  }
];

export const appRoutes = RouterModule.forRoot(routes);
