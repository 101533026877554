import { map, shareReplay } from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { BOARDS_PATH, LISTS_PATH } from '../shared/firebase-paths';
import { MappedCache } from '../shared/mapped-cache';
import { TaskService } from '../tasks/task.service';
import { TaskModel } from '../tasks/task.model';
import { ListModel } from './list.model';

@Injectable({ providedIn: 'root' })
export class ListService {
  public lists$: Observable<ListModel[]>;
  public listsAsObject: Observable<{ [index: string]: ListModel }>;
  private tasksByList = new MappedCache<TaskModel[]>();
  private listsByBoard = new MappedCache<ListModel[]>();

  constructor(
    private angularFire: FirebaseDbService,
    private taskService: TaskService
  ) {
    this.lists$ = this.angularFire
      .getList(`/${LISTS_PATH}`)
      .pipe(shareReplay(1));
    this.listsAsObject = this.angularFire
      .getObject(`/${LISTS_PATH}`)
      .pipe(shareReplay(1));
  }

  create(list: ListModel) {
    return this.angularFire
      .list(`/${LISTS_PATH}`)
      .push(list)
      .then(newListRef => newListRef.key);
  }

  get(id) {
    return this.lists$.pipe(map(lists => lists.find(list => list.$key === id)));
  }

  getAll(): Observable<ListModel[]> {
    return this.lists$;
  }

  getTasks(id: string) {
    return this.tasksByList.get(
      id,
      this.taskService
        .getForList(id)
        .pipe(map(tasks => orderBy(tasks, ['createdAt'], ['desc'])))
    );
  }

  getForBoard(boardId: string): Observable<ListModel[]> {
    return this.listsByBoard.get(
      boardId,
      this.angularFire.getList(`${LISTS_PATH}`, ref =>
        ref.orderByChild('board').equalTo(boardId)
      )
    );
  }

  getOrderedBoardLists(boardId: string): Observable<ListModel[]> {
    return combineLatest([
      this.angularFire.getObject(`/${BOARDS_PATH}/${boardId}`),
      this.getForBoard(boardId)
    ]).pipe(
      map(([board, lists]) => {
        const listsWithOrder = board?.lists;
        const rawLists = lists.map(list => ({
          ...list,
          $key: list?.$key,
          order: listsWithOrder[list?.$key]?.order
        }));

        return orderBy(rawLists, 'order');
      })
    );
  }
}
