import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  CdkPortalOutlet,
  CdkPortalOutletAttachedRef,
  ComponentPortal
} from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Output,
  Renderer2,
  Type,
  ViewChild
} from '@angular/core';
import { WindowConfig, WINDOW_CONTENT } from '../window-options';

import { WindowPane, WINDOW_PANE_ANIMATION_DURATION } from './window-pane';
import {
  WindowPaneAnimationState,
  WindowPaneAnimationEvent
} from './window-pane.interface';

@Component({
  selector: 'ease-window-pane',
  templateUrl: './window-pane.component.html',
  styleUrls: ['./window-pane.component.scss'],
  animations: [
    trigger('toggleDrawer', [
      state(
        'close',
        style({
          transform: 'translateY(30%) scaleY(50%) scaleX(75%)',
          'transform-origin': '50% 100%',
          opacity: '0'
        })
      ),
      state(
        'open',
        style({
          transform: 'translateY(0) scaleY(100%) scaleX(100%)',
          'transform-origin': '50% 100%',
          opacity: '100%'
        })
      ),
      transition(
        `${WindowPaneAnimationState.CLOSE} <=> ${WindowPaneAnimationState.OPEN}`,
        animate(`${WINDOW_PANE_ANIMATION_DURATION}ms 0ms ease-in-out`)
      )
    ])
  ]
})
export class WindowPaneComponent {
  @HostBinding('@toggleDrawer')
  get getToggleDrawer(): WindowPaneAnimationState {
    return this.windowRef.state === 'MINIMIZED'
      ? WindowPaneAnimationState.CLOSE
      : WindowPaneAnimationState.OPEN;
  }
  @HostListener('@toggleDrawer.start', ['$event']) toggleAnimationStart(
    event: WindowPaneAnimationEvent
  ) {
    this.windowRef.updateAnimationState(event);
  }
  @HostListener('@toggleDrawer.done', ['$event']) toggleAnimationDone(
    event: WindowPaneAnimationEvent
  ) {
    this.windowRef.updateAnimationState(event);
  }
  @HostBinding('class') class =
    'flex flex-col shadow-md flex-1 absolute left-0 right-0 pointer-events-auto transition-all';
  @HostBinding('class.open')
  get isOpen() {
    return this.windowRef.state === 'OPEN';
  }
  @HostBinding('class.sm:inset-x-6')
  get addOpenPadding() {
    return this.windowRef.state === 'OPEN';
  }
  @HostBinding('class.maximized')
  get isMaximized() {
    return this.windowRef.state === 'MAXIMIZED';
  }
  @HostBinding('class.minimized')
  get isMinimized() {
    return this.windowRef.state === 'MINIMIZED';
  }
  @Output()
  closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  destroy: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  opened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(CdkPortalOutlet, { static: true })
  portalOutlet: CdkPortalOutlet;

  public componentPortal: ComponentPortal<any>;
  private classesToAdd: string[] = ['flex', 'flex-col', 'min-h-0', 'h-full'];

  constructor(
    @Inject(WINDOW_CONTENT)
    public content: Type<any>,
    public windowRef: WindowPane<any>,
    public config: WindowConfig,
    private renderer2: Renderer2
  ) {
    this.componentPortal = new ComponentPortal(this.content);
  }

  /**
   * Called when a component is attached to a portal for the first time
   * Used to add a known-good set of CSS classes for styling purposes
   *
   * @param ref Reference to the component that was just inserted into the portal
   */
  onComponentAttached(ref: CdkPortalOutletAttachedRef) {
    const componentRef = ref as ComponentRef<any>;
    this.classesToAdd.forEach(c =>
      this.renderer2.addClass(componentRef.location.nativeElement, c)
    );
  }
}
