import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { SharedValidators } from '../../../shared/shared-validators';
import {
  buildFormControls,
  emptyFormArray
} from '../../../shared/utils/functions';
import {
  AccountBuilderForm,
  AccountBuilderVertical
} from '../account-builder.interface';
import { AccountBuilderService } from '../account-builder.service';
import {
  HeadlineFormArray,
  HeadlineFormControl
} from './search-campaign-builder.interface';

@Component({
  selector: 'ease-search-campaign-builder',
  templateUrl: './search-campaign-builder.component.html',
  styleUrls: ['./search-campaign-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchCampaignBuilderComponent implements OnInit {
  @Input() accountBuilderControl: AccountBuilderForm;
  @Input() set verticals(verticals: string[]) {
    this.getVerticalsMeta(verticals);
  }
  public searchSets: AccountBuilderForm['controls']['searchSets'];
  public searchHeadlinesMinLength: number = 10;
  public searchHeadlinesMaxLength: number = 15;
  public pathMaxLength: number = 15;

  constructor(
    private cdr: ChangeDetectorRef,
    public accountBuilderService: AccountBuilderService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.searchSets = this.accountBuilderControl.controls.searchSets;
  }

  async getVerticalsMeta(verticals: string[]): Promise<void> {
    if (verticals.length) {
      const verticalMeta = await firstValueFrom(
        this.accountBuilderService.getVerticalsMeta(
          this.accountBuilderControl.controls.templateSelected.value,
          verticals
        )
      );

      emptyFormArray(this.searchSets);

      verticalMeta.forEach(vertical => {
        this.searchSets.push(this.buildSetControl(vertical));
        this.cdr.detectChanges();
      });
    }
  }

  buildSetControl(
    supportedVertical: AccountBuilderVertical
  ): typeof this.searchSets.controls[0] {
    const verticalSetControl = this.formBuilder.group<
      typeof this.searchSets.controls[0]['controls']
    >({
      name: this.formBuilder.control(supportedVertical.name, {
        validators: Validators.required
      }),
      finalUrl: this.formBuilder.control(null, {
        validators: [Validators.required, SharedValidators.url]
      }),
      path1: this.formBuilder.control('', {
        validators: Validators.maxLength(this.pathMaxLength)
      }),
      path2: this.formBuilder.control('', {
        validators: Validators.maxLength(this.pathMaxLength)
      }),
      headlines: this.formBuilder.array<HeadlineFormControl>([], {
        validators: [
          Validators.required,
          Validators.minLength(this.searchHeadlinesMinLength),
          Validators.maxLength(this.searchHeadlinesMaxLength)
        ]
      }),
      descriptions: this.formBuilder.array<FormControl<string>>([])
    });

    buildFormControls(
      supportedVertical.descriptions,
      verticalSetControl.controls.descriptions
    );

    supportedVertical.headlines.forEach(headline => {
      verticalSetControl.controls.headlines.push(
        this.buildHeadlineControl(headline, true)
      );
    });

    this.onAddHeadline(verticalSetControl.controls.headlines);
    return verticalSetControl;
  }

  buildHeadlineControl(
    headline: string,
    predefined = false
  ): HeadlineFormControl {
    return this.formBuilder.group({
      text: this.formBuilder.control(headline, {
        validators: predefined
          ? Validators.required
          : [Validators.required, Validators.maxLength(25)]
      }),
      predefined: this.formBuilder.control(predefined)
    });
  }

  onAddHeadline(headlineFormArray: HeadlineFormArray): void {
    if (headlineFormArray.length <= 7) {
      for (
        let index = headlineFormArray.length;
        index < this.searchHeadlinesMinLength;
        index++
      ) {
        headlineFormArray.push(this.buildHeadlineControl(''));
      }
    } else {
      headlineFormArray.push(this.buildHeadlineControl(''));
    }

    this.cdr.detectChanges();
  }

  onRemoveHeadline(headlineFormArray: HeadlineFormArray, index: number): void {
    headlineFormArray.removeAt(index);
    this.cdr.detectChanges();
  }
}
