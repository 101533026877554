import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';

import { map, take } from 'rxjs/operators';
import { ListDisplayItem } from '../../shared/shared.interface';
import { CustomersService } from '../customers.service';

export interface CustomerPlanChange {
  oldPlan: string;
  newPlan: string;
}

@Component({
  selector: 'ease-customer-plan-selector',
  templateUrl: './customer-plan-selector.component.html',
  styleUrls: ['./customer-plan-selector.component.scss']
})
export class CustomerPlanSelectorComponent implements OnInit {
  @Input()
  disabled: boolean = false;
  @Input()
  displayStyle: 'inline' | 'form' = 'inline';
  @Input()
  displayPlanText: boolean = false;
  @Input()
  set plan(plan: string) {
    this.oldPlan = plan;
    this.currentPlan = plan ? plan.toUpperCase() : null;
  }
  @Output()
  planChanged: EventEmitter<CustomerPlanChange> = new EventEmitter<CustomerPlanChange>();

  public options$: Observable<ListDisplayItem[]>;
  public optionSettings$: Observable<{
    [key: string]: { name: string; color: string };
  }>;
  private oldPlan: string;
  private _currentPlan: string;

  get currentPlan(): string {
    return this._currentPlan;
  }

  set currentPlan(plan: string) {
    this._currentPlan = plan;
  }

  @HostListener('click', ['$event'])
  handleHostClick(event: Event) {
    event.stopPropagation();
  }

  constructor(private customersService: CustomersService) {}

  ngOnInit() {
    this.options$ = this.customersService.getPlans();
    this.optionSettings$ = this.options$.pipe(
      take(1),
      map(options =>
        options.reduce((acc, option: ListDisplayItem & { color: string }) => {
          acc[option.value] = {
            name: option.viewValue,
            color: option.color
          };
          return acc;
        }, {})
      )
    );
  }

  reset() {
    this.currentPlan = this.oldPlan;
  }

  onPlanChange(event: MatSelectChange) {
    this.planChanged.emit({
      oldPlan: this.oldPlan ? `${this.oldPlan}` : null,
      newPlan: typeof event.value !== 'string' ? null : `${event.value}`
    });
  }
}
