import { NgModule } from '@angular/core';

import { PopoverModule } from '../popover.module';
import { SharedFormModule } from '../../shared-form.module';
import { SharedModule } from '../../shared.module';
import { UserSelectorModule } from '../../user-selector/user-selector.module';
import { SelectUserPopoverComponent } from './select-user-popover.component';

@NgModule({
  imports: [PopoverModule, UserSelectorModule, SharedModule, SharedFormModule],
  declarations: [SelectUserPopoverComponent],
  exports: [SelectUserPopoverComponent, PopoverModule]
})
export class SelectUserPopoverModule {}
