import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ease-task-display-targeting',
  templateUrl: './task-display-targeting.component.html',
  styleUrls: ['./task-display-targeting.component.scss']
})
export class TaskDisplayTargetingComponent implements OnInit {
  @Input()
  items: any[];

  constructor() {}

  ngOnInit() {}
}
