<h1 mat-dialog-title>{{ data.title }}</h1>

<mat-dialog-content>
  <ng-container *ngIf="data.templateRef; else message">
    <ng-container
      *ngTemplateOutlet="
        data.templateRef;
        context: { $implicit: data.templateContext }
      "
    ></ng-container
  ></ng-container>
  <ng-template #message>
    <div [innerHTML]="data.message"></div>
  </ng-template>

  <mat-form-field *ngIf="data.showDetails">
    <textarea
      [formControl]="detailsControl"
      [placeholder]="data.detailsPlaceholder"
      matInput
    ></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button [matDialogClose]="{ confirm: false }" mat-button>
    {{ data.cancelText }}
  </button>
  <button
    mat-button
    [color]="data.confirmColor"
    [matDialogClose]="{ confirm: true, details: detailsControl.value }"
  >
    {{ data.confirmText }}
  </button>
</mat-dialog-actions>
