import { NgModule } from '@angular/core';

import { VerticalTabsModule } from 'src/app/shared/vertical-tabs/vertical-tabs.module';
import { CustomerFeedModule } from '../../customers/customer-feed/customer-feed.module';
import { SharedModule } from '../../shared/shared.module';
import { TaskNotesModule } from '../task-notes/task-notes.module';
import { TaskProjectModule } from '../task-project/task-project.module';
import { TaskSidebarRendererComponent } from './task-sidebar-renderer.component';
import { TaskSidebarComponent } from './task-sidebar.component';

@NgModule({
  declarations: [TaskSidebarComponent, TaskSidebarRendererComponent],
  imports: [
    CustomerFeedModule,
    SharedModule,
    TaskNotesModule,
    VerticalTabsModule,
    TaskProjectModule
  ],
  exports: [TaskSidebarComponent]
})
export class TaskSidebarModule {}
