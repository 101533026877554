import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  private pageTitle: string = 'Ease';
  private unreadNotificationCount: number;

  constructor(private titleHelper: Title) {}

  setTitle(title: string): void {
    this.pageTitle = title;
    this.setPageTitleFoBrowserTab();
  }

  updateNotificationTitle(unreadNotificationCount: number): void {
    this.unreadNotificationCount = unreadNotificationCount;
    this.setPageTitleFoBrowserTab();
  }

  private setPageTitleFoBrowserTab(): void {
    let pageTitleForDisplay = this.pageTitle;

    if (this.unreadNotificationCount > 0) {
      pageTitleForDisplay = `(${this.unreadNotificationCount}) ${this.pageTitle}`;
    }

    return this.titleHelper.setTitle(pageTitleForDisplay);
  }
}
