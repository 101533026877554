<ease-sheet class="popover">
  <ease-popover-header>Add Checklist</ease-popover-header>
  <ease-sheet-content class="filter-container">
    <ease-advanced-autocomplete
      (optionSelected)="addChecklist($event)"
      [options]="checklist$ | async"
      [placeholder]="'Select a checklist'"
      [autoFocus]="true"
    >
    </ease-advanced-autocomplete>
  </ease-sheet-content>
</ease-sheet>
