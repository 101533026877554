<ease-feed-item-note
  *ngIf="item?.itemType === 'customerNote' || item?.itemType === 'accountNote'"
  [users]="users"
  [note]="$any(item)"
  [dateFormat]="dateFormat"
  [editable]="isEditable"
  [canMention]="false"
  (save)="onItemSave($event)"
>
</ease-feed-item-note>

<ease-feed-item-completed-task
  *ngIf="item?.endpoint === 'tasksCompleted'"
  [users]="users"
  [task]="item"
  [dateFormat]="dateFormat"
  [editable]="isEditable"
  (save)="onItemSave($event)"
>
</ease-feed-item-completed-task>

<ease-feed-item-budget-change
  *ngIf="item?.itemType === 'budgetChange'"
  [users]="users"
  [item]="item"
  [dateFormat]="dateFormat"
>
</ease-feed-item-budget-change>

<ease-feed-item-status-change
  *ngIf="item?.itemType === 'statusChange'"
  [users]="users"
  [item]="item"
  [dateFormat]="dateFormat"
>
</ease-feed-item-status-change>

<ease-feed-item-plan-change
  *ngIf="item?.itemType === 'planChange'"
  [users]="users"
  [item]="item"
  [dateFormat]="dateFormat"
>
</ease-feed-item-plan-change>

<ease-feed-item-role-change
  *ngIf="item?.itemType === 'roleChange'"
  [users]="users"
  [item]="item"
  [dateFormat]="dateFormat"
>
</ease-feed-item-role-change>

<ease-feed-item-communication
  *ngIf="item?.endpoint === 'customerCommunications'"
  [users]="users"
  [item]="item"
  [dateFormat]="dateFormat"
>
</ease-feed-item-communication>

<ease-feed-item-message-group
  *ngIf="item?.itemType === 'customerMessageGroup'"
  [users]="users"
  [item]="$any(item)"
  [dateFormat]="dateFormat"
>
</ease-feed-item-message-group>

<ease-feed-item-snoozed-state
  *ngIf="item?.itemType === 'snoozedState'"
  [users]="users"
  [item]="$any(item)"
  [dateFormat]="dateFormat"
>
</ease-feed-item-snoozed-state>
