<div
  class="flex flex-col items-center justify-center lg:flex-row lg:justify-start"
>
  <div>
    <div class="task-budget__budget flex-1">
      <div class="task-budget__meta text-gray-400">
        Total Budget
        <mat-icon
          class="mat-icon-14 ml-1 cursor-pointer text-gray-700"
          title="Budget at the time this alert was created"
          >help</mat-icon
        >
      </div>
      <div class="task-budget__stat">
        {{ task?.budget | currency: 'USD':'symbol':'1.0-2' }}
      </div>
    </div>
  </div>
  <div class="task-budget__divider"></div>
  <div class="task-budget__spend flex-1">
    <!-- Total Spend -->
    <div class="task-budget__meta text-gray-400">
      Actual Spend (Period-To-Date)
      <mat-icon
        class="mat-icon-14 ml-1 cursor-pointer text-gray-700"
        title="Total spend for the month or the date range defined"
        >help</mat-icon
      >
    </div>
    <div class="task-budget__stat">
      {{ task?.spend | currency: 'USD':'symbol':'1.0-2' }}
      <button
        title="View Breakdown"
        class="task-budget__breakdown-toggle"
        *ngIf="task?.adwordsSpend || task?.lsaSpend || task?.lsaSpend"
        (click)="costBreakdownToggle.toggle()"
        mat-icon-button
      >
        <mat-icon>{{
          costBreakdownToggle?.isHidden ? 'expand_more' : 'expand_less'
        }}</mat-icon>
      </button>
      <ease-toggle #costBreakdownToggle="toggle">
        <ng-container *ngIf="!costBreakdownToggle?.isHidden">
          <div
            *ngIf="task?.adwordsSpend"
            class="mt-2 flex flex-row items-center justify-center text-sm"
          >
            <ease-account-icon
              class="mr-1"
              [size]="16"
              [accountType]="'adwords'"
            ></ease-account-icon>
            {{ task?.adwordsSpend | currency: 'USD':'symbol':'1.0-2' }}
          </div>
          <div
            *ngIf="task?.lsaSpend"
            class="mt-2 flex flex-row items-center justify-center text-sm"
          >
            <ease-account-icon
              class="mr-1"
              [size]="16"
              [accountType]="'lsa'"
            ></ease-account-icon>
            {{ task?.lsaSpend | currency: 'USD':'symbol':'1.0-2' }}
          </div>
          <div
            *ngIf="task?.bingSpend"
            class="mt-2 flex flex-row items-center justify-center text-sm"
          >
            <ease-account-icon
              class="mr-1"
              [size]="16"
              [accountType]="'bing'"
            ></ease-account-icon>
            {{ task?.bingSpend | currency: 'USD':'symbol':'1.0-2' }}
          </div>
        </ng-container>
      </ease-toggle>
      <div class="mt-2 text-sm text-red-500">
        {{ task?.spendDeltaDirection === 'under' ? '&minus;' : '+'
        }}{{ task?.spendDelta | currency: 'USD':'symbol':'1.0-2' }}
        <span class="ml-1">({{ task?.spendDeltaPercent }}%)</span>
      </div>
    </div>
  </div>
</div>
