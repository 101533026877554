import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { map, switchMap } from 'rxjs/operators';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CustomersService } from '../../../customers/customers.service';
import { CustomerAccountsService } from '../../../customers/customer-accounts/customer-accounts.service';
import { RoleSelectorChange } from '../../role-selector/role-selector/role-selector.component';
import { EntityMetadata } from '../../shared.interface';
import { SNACKBAR_DURATION_SUCCESS } from '../../constants';
import { EntityUtilsService } from '../../entity-utils.service';

@Component({
  selector: 'ease-role-dialog',
  templateUrl: './role-dialog.component.html',
  styleUrls: ['./role-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleDialogComponent implements OnInit {
  private customerId$: Observable<string>;
  private accountId$: Observable<string>;
  public customerRoles$: Observable<any>;
  public accountRoles$: Observable<any>;
  public accountMeta$: Observable<EntityMetadata>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EntityMetadata,
    private matSnackBar: MatSnackBar,
    private customersService: CustomersService,
    private customerAccountsService: CustomerAccountsService,
    private entityUtilsService: EntityUtilsService
  ) {}

  ngOnInit(): void {
    this.customerId$ = of(this.data.entityId);
    this.accountId$ = of(this.data.entityId);

    if (this.data.entityType === 'account') {
      this.customerId$ = from(
        this.entityUtilsService.getCustomerIdForAccount({
          accountId: this.data.entityId,
          accountType: this.data.accountType
        })
      );

      this.accountMeta$ = this.accountId$.pipe(
        map(
          accountId =>
            ({
              entityId: accountId,
              entityType: this.data.entityType,
              accountType: this.data.accountType
            } as EntityMetadata)
        )
      );

      this.accountRoles$ = this.accountMeta$.pipe(
        switchMap(meta =>
          this.customerAccountsService.getAccountUserRolesAsObject({
            accountId: meta.entityId,
            accountType: meta.accountType
          })
        )
      );
    }

    this.customerRoles$ = this.customerId$.pipe(
      switchMap(customerId => this.customersService.get(customerId)),
      map(customer => customer.roles)
    );
  }

  async setCustomerRole(roleChange: RoleSelectorChange): Promise<void> {
    await firstValueFrom(
      this.customerId$.pipe(
        switchMap(customerId =>
          this.customersService.setRole(
            customerId,
            roleChange.roleId,
            roleChange.userId
          )
        )
      )
    );

    this.roleSnackBar();
  }

  async setAccountRole(roleChange: RoleSelectorChange): Promise<void> {
    await firstValueFrom(
      this.accountMeta$.pipe(
        switchMap(meta =>
          this.customerAccountsService.setAccountUserRole(
            {
              accountId: meta.entityId,
              accountType: meta.accountType
            },
            roleChange.roleId,
            roleChange.userId
          )
        )
      )
    );

    this.roleSnackBar();
  }

  roleSnackBar() {
    this.matSnackBar.open('User role updated', 'Close', {
      duration: SNACKBAR_DURATION_SUCCESS
    });
  }
}
