import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import {
  SatPopover,
  SatPopoverHorizontalAlign,
  SatPopoverVerticalAlign
} from '@ncstate/sat-popover';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AppService } from 'src/app/app.service';
import { RegionHolidays } from '../holiday/holiday.interface';
import { HolidayService } from '../holiday/holiday.service';
import { SharedLayoutService } from '../shared-layout.service';

type DateInputType = string | number | Date;

@UntilDestroy()
@Component({
  selector: 'ease-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {
  @ViewChild('calendarPopover') calendar: SatPopover;
  @Input()
  set min(min: DateInputType | Moment) {
    this.minDate = moment(min).toDate();
  }

  @Input()
  set max(max: DateInputType | Moment) {
    this.maxDate = moment(max).toDate();
  }

  @Input() placeholder: string = 'Select a date';
  @Input() isReadOnly: boolean = false;
  // Optional sat-popover anchor override. Also hides the input when set.
  @Input() customAnchor: ElementRef<HTMLElement> | HTMLElement;
  @Input() set startDate(timestamp: number) {
    this.defaultDate = timestamp ? new Date(timestamp) : null;
  }
  @Input() hint: string;
  @Input() verticalAlign: SatPopoverVerticalAlign = 'below';
  @Input() horizontalAlign: SatPopoverHorizontalAlign = 'start';
  @Input() hasBackdrop: boolean = true;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  changed: EventEmitter<number> = new EventEmitter<number>();
  public control: FormControl<DateInputType> = new FormControl();
  private parentControl: FormControl<DateInputType>;
  public minDate: Date;
  public maxDate: Date;
  public regionHolidays$: Observable<RegionHolidays>;
  public defaultDate: Date;

  constructor(
    @Self()
    @Optional()
    private cdr: ChangeDetectorRef,
    public holidayService: HolidayService,
    public ngControl: NgControl,
    public sharedLayoutService: SharedLayoutService,
    public appService: AppService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    } else {
      throw new Error('ease-datepicker requires a FormControl instance.');
    }
  }

  ngOnInit() {
    this.parentControl = this.ngControl.control as FormControl;
    this.parentControl.validator &&
      this.control.setValidators([this.parentControl.validator]);

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(val => {
      const timestamp = moment(val).valueOf();

      if (timestamp && !isNaN(timestamp)) {
        this.changed.emit(timestamp);
        this.emitChange(timestamp);
      } else {
        this.changed.emit(null);
        this.emitChange(null);
      }
    });

    this.regionHolidays$ = this.appService.isLoggedIn$.pipe(
      filter(isLoggedIn => isLoggedIn),
      switchMap(() => this.holidayService.regionHolidays$)
    );
  }

  emitChange(value: number) {
    this.onChange(value);
    this.cdr.markForCheck();
  }

  showPicker(): void {
    this.calendar.open();
  }

  /**
   * ControlValueAccessor
   */
  onChange: (value) => any = () => {
    /* */
  };
  onTouched: () => any = () => {
    /* */
  };
  writeValue(formVal: DateInputType) {
    if (this.control) {
      this.control.setValue(formVal ? new Date(formVal) : null, {
        emitEvent: false
      });
      this.cdr.markForCheck();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  resetCalendar(event: MouseEvent) {
    this.control.setValue(null);
    event.stopPropagation();
  }
}
