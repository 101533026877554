import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import { UserSelectorComponent } from '../../user-selector/user-selector.component';
import { UserSelected } from '../../user-selector/user-selector.interface';

@Component({
  selector: 'ease-select-user-popover',
  templateUrl: './select-user-popover.component.html',
  styleUrls: ['./select-user-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectUserPopoverComponent {
  @Input()
  selectedUsers: string[] = [];
  @Input()
  title: string = 'Select User';
  @Input()
  selectIcon: string;
  @Input()
  onlyUsers: boolean;
  @Output()
  deselected: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  selected: EventEmitter<UserSelected> = new EventEmitter<UserSelected>();
  @ViewChild('userSelector', { static: true })
  userSelector: UserSelectorComponent;

  constructor() {}
}
