import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'ease-inplace-edit',
  exportAs: 'inplaceEditor',
  templateUrl: './inplace-edit.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InplaceEditComponent),
      multi: true
    }
  ]
})
export class InplaceEditComponent implements ControlValueAccessor, OnInit {
  public isEditing: boolean = false;
  public value: string = '';
  public editForm: FormGroup<{
    editInput: FormControl<string>;
  }> = this.formBuilder.group({
    editInput: this.formBuilder.control(this.value, {
      validators: Validators.required
    })
  });
  @Input()
  editOnClick: boolean = true;
  @Output()
  inputBlur: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  save: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('myInput', { read: MatInput, static: true })
  myInput: MatInput;
  @HostListener('click', ['$event'])
  onClick(event) {
    if (this.editOnClick && !this.isEditing) {
      this.isEditing = true;
      this.focusInput();
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {}

  onInputBlur() {
    this.isEditing = false;
    this.inputBlur.emit(this.value);
  }

  onSave() {
    this.isEditing = false;
    if (this.editForm.valid && this.value !== this.currentValue) {
      this.save.emit(this.currentValue);
      this.emitChange(this.currentValue);
      this.value = this.currentValue;
    }
  }

  focusInput() {
    this.editForm.controls.editInput.setValue(this.value);
    setTimeout(() => this.myInput.focus());
  }

  toggleEditMode() {
    this.isEditing = !this.isEditing;
    this.isEditing && this.focusInput();
    this.emitChange(this.value);
  }

  get currentValue(): string {
    return this.editForm.controls.editInput.value || '';
  }

  emitChange(value: string) {
    this.onChange(value);
    this.cdr.markForCheck();
  }

  /** Implemented as part of ControlValueAccessor. */
  onChange: (value) => any = () => {
    /* */
  };

  onTouched: () => any = () => {
    /* */
  };

  writeValue(value: any) {
    if (value) {
      this.value = value;
      this.cdr.markForCheck();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
