import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

import { UserAvatarModel } from '../../users/user.model';
import { AvatarSize, AvatarUserStatus } from './avatar.interface';

@Component({
  selector: 'ease-avatar-renderer',
  templateUrl: './avatar-renderer.component.html',
  styleUrls: ['./avatar-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AvatarRendererComponent {
  @Input() user: UserAvatarModel;
  @Input() size: AvatarSize;
  @Input() showTooltip: boolean;
  @Input() showInitials: boolean;
  @Input() status: AvatarUserStatus;

  public sizeStyle: { [size in AvatarSize]?: string } = {
    xlarge: 'w-64 h-64',
    larger: 'w-40 h-40',
    large: 'w-16 h-16',
    medium: 'w-12 h-12',
    small: 'w-8 h-8',
    xsmall: 'w-6 h-6'
  };
}
