import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import firebase from 'firebase/compat/app';

import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {
  EaseUnwrappedSnapshot,
  FirebaseDbService
} from 'src/app/shared/firebase-db.service';
import { ContactModel } from '../contacts/contact.model';
import {
  CONTACTS_PATH,
  CONTACT_CUSTOMERS_PATH,
  CUSTOMER_CONTACTS_PATH
} from '../shared/firebase-paths';
import { firebaseJSON } from '../shared/utils/functions';
import { SearchService } from '../shared/search/search.service';
import { UserService } from '../users/user.service';
import {
  TypesenseContactFieldList,
  TypesenseSearchResponse
} from '../shared/search/search.interface';

@Injectable({ providedIn: 'root' })
export class ContactService {
  private ref: firebase.database.Reference;

  constructor(
    private angularFire: FirebaseDbService,
    private searchService: SearchService,
    private userService: UserService
  ) {
    this.ref = this.angularFire.database.ref(CONTACTS_PATH);
  }

  get(contactId: string): Observable<EaseUnwrappedSnapshot<ContactModel>> {
    return this.angularFire.getObject(`/${CONTACTS_PATH}/${contactId}`);
  }

  getAll(): Observable<ContactModel[]> {
    return combineLatest([
      this.angularFire.getList(`/${CONTACTS_PATH}`),
      this.getAllCustomers()
    ]).pipe(
      map(([contacts, contactsCustomers]) =>
        contacts.map(contact => {
          if (contactsCustomers && contactsCustomers[contact.$key]) {
            contact.customersCount = Object.keys(
              contactsCustomers[contact.$key]
            ).length;
          } else {
            contact.customersCount = 0;
          }

          return contact;
        })
      )
    );
  }

  find(q: string): TypesenseSearchResponse<TypesenseContactFieldList> {
    return this.searchService.create<TypesenseContactFieldList>('contacts', {
      q
    });
  }

  create(contact: ContactModel): firebase.database.ThenableReference {
    return this.ref.push(firebaseJSON(contact));
  }

  async remove(contactId: string): Promise<any> {
    const relatedCustomers = await firstValueFrom(this.getCustomers(contactId));
    const removePromises: Promise<any>[] = [];

    if (relatedCustomers.length) {
      relatedCustomers.forEach(customerId => {
        removePromises.push(
          this.angularFire
            .object(`/${CUSTOMER_CONTACTS_PATH}/${customerId}/${contactId}`)
            .remove()
        );
      });
    }

    removePromises.push(
      this.angularFire.object(`/${CONTACTS_PATH}/${contactId}`).remove()
    );

    removePromises.push(
      this.angularFire
        .object(`/${CONTACT_CUSTOMERS_PATH}/${contactId}`)
        .remove()
    );

    return Promise.all(removePromises);
  }

  async save(contactId: string, contact: ContactModel): Promise<any> {
    return this.ref.child(contactId).update({
      ...contact,
      updatedBy: this.userService.currentUser.$key,
      updatedAt: await this.angularFire.getServerTimestamp()
    });
  }

  contactEmailValid(email: string, contactId?: string): Observable<boolean> {
    if (!email || !email.length) {
      return of(true);
    }

    return this.angularFire
      .getList(`/${CONTACTS_PATH}`, ref =>
        ref.orderByChild('email').equalTo(email)
      )
      .pipe(
        take(1),
        map((foundEmails: ContactModel[]) => {
          if (contactId) {
            const foundContactIndex = foundEmails.findIndex(
              contact => contact.$key !== contactId
            );
            return !(foundContactIndex > -1);
          }

          return !foundEmails.length;
        })
      );
  }

  getCustomers(contactId: string): Observable<string[]> {
    return this.angularFire
      .getList(`/${CONTACT_CUSTOMERS_PATH}/${contactId}`)
      .pipe(map(customers => customers.map(contact => contact.$key)));
  }

  getAllCustomers(): Observable<string[]> {
    return this.angularFire.getObject(`/${CONTACT_CUSTOMERS_PATH}`);
  }
}
