import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

import { LOGO_FILE_FOR_ACCOUNT_TYPE } from '../constants';

@Component({
  selector: 'ease-account-icon',
  templateUrl: './account-icon.component.html',
  styleUrls: ['./account-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountIconComponent {
  @HostBinding('class') hostClass = 'inline-flex flex-none align-middle';
  @Input()
  accountType: string;
  @Input()
  size: number = 24;

  public logoFileForAccountType = LOGO_FILE_FOR_ACCOUNT_TYPE;
}
