import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ease-task-description',
  templateUrl: './task-description.component.html'
})
export class TaskDescriptionComponent {
  @Input()
  description: string;
  @Input()
  isCompleted: boolean = false;
  @Output()
  save: EventEmitter<any> = new EventEmitter<any>();

  saveDescription() {
    this.save.emit({
      description: this.description
    });
  }
}
