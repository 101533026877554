import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { TypeformEmbedDialogComponent } from './typeform-embed-dialog/typeform-embed-dialog.component';

@NgModule({
  declarations: [TypeformEmbedDialogComponent],
  imports: [CommonModule, MatDialogModule],
  exports: [TypeformEmbedDialogComponent]
})
export class TypeformEmbedModule {}
