import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges
} from '@angular/core';
import { ProseSize } from '../markdown.interface';
import { MarkdownService } from '../markdown.service';

@Directive({
  selector: '[easeMarkdown]'
})
export class MarkdownDirective implements OnChanges {
  @HostBinding('class.max-w-none') maxWidthClass = true;
  @HostBinding('class') proseSizeClass = 'prose';

  @Input() set size(size: ProseSize) {
    this.proseSizeClass = this.markdownService.getProseClass(size);
  }
  @Input() inline: boolean = false;

  @Input()
  easeMarkdown: string;

  constructor(
    private elementRef: ElementRef,
    private markdownService: MarkdownService
  ) {}

  ngOnChanges(changes) {
    if (changes.easeMarkdown && changes.easeMarkdown.currentValue) {
      this.elementRef.nativeElement.innerHTML = this.markdownService.render(
        this.easeMarkdown,
        this.inline
      );
    } else {
      this.elementRef.nativeElement.innerHTML = '';
    }
  }
}
