import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

import { InlineDateRangePickerComponent } from './inline-date-range-picker.component';

@NgModule({
  declarations: [InlineDateRangePickerComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatDividerModule,
    MatButtonModule
  ],
  exports: [InlineDateRangePickerComponent]
})
export class InlineDateRangePickerModule {}
