import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeocodeResult } from '@googlemaps/google-maps-services-js';
import isNil from 'lodash-es/isNil';
import {
  combineLatest,
  firstValueFrom,
  Observable,
  ReplaySubject,
  Subject
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FileUploaderFile } from 'src/app/shared/file-uploader/file-uploader.interface';
import {
  TypedFormControl,
  TypedFormGroup
} from 'src/app/shared/reactive-forms';
import { ListDisplayItem, ProgressStatus } from '../../shared/shared.interface';
import { SharedValidators } from '../../shared/shared-validators';
import { transformCurrencyString } from '../../shared/utils/functions';
import { SNACKBAR_DURATION_ERROR } from '../../shared/constants';
import { JobService } from '../../jobs/job.service';
import { WindowPane } from '../../shared/window/window-pane/window-pane';
import { AccountMetadataService } from '../../shared/account-metadata.service';
import { AdminPermissionsService } from '../../admin/admin-roles/admin-permissions.service';
import { NegativeKeywordsService } from '../../negative-keywords/negative-keywords.service';
import { AccountBuilderService } from './account-builder.service';
import { AdSetItemComponent } from './ad-set-item/ad-set-item.component';
import {
  AccBuilderFormValue,
  AccBuilderPayload,
  AccountBuilderForm,
  AccountBuilderSettings,
  AccountBuilderTemplate,
  Ad,
  AdSet
} from './account-builder.interface';

@UntilDestroy()
@Component({
  selector: 'ease-account-builder',
  templateUrl: './account-builder.component.html',
  styleUrls: ['./account-builder.component.scss']
})
export class AccountBuilderComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'flex-1';
  @ViewChildren(AdSetItemComponent)
  adSetsItems: QueryList<AdSetItemComponent>;

  public accountBuilderForm: AccountBuilderForm;
  public countries$: Observable<ListDisplayItem[]>;
  public locationInfo$: Observable<GeocodeResult>;
  public progressStatusSource: Subject<ProgressStatus> =
    new Subject<ProgressStatus>();
  public progressStatus$: Observable<ProgressStatus>;
  public showStatusSource: Subject<boolean> = new Subject<boolean>();
  public showStatus$: Observable<boolean> =
    this.showStatusSource.asObservable();
  public isWorking: boolean = false;
  public locationName: string;
  public totalAdsToUpload: number;
  public totalAdsUploaded: number;
  public radiusOptions: ListDisplayItem[] = [10, 20, 30, 40, 50, 75, 100].map(
    value => ({ viewValue: value + ' Miles', value })
  );

  public templateOptions: ListDisplayItem[] = [
    { viewValue: 'Purple', value: 'purple' },
    { viewValue: 'Gold', value: 'gold' }
  ];
  public locationChangeSource: ReplaySubject<void> = new ReplaySubject<void>();
  public locationChange$ = this.locationChangeSource.asObservable();
  private onDestroy$: Subject<void> = new Subject<void>();
  public verticals$: Observable<ListDisplayItem[]>;
  public verticalsSelected: string[] = [];

  get verticalIdsControl(): FormControl<string[]> {
    return this.accountBuilderForm.controls.verticalIds;
  }

  get templateSelected(): string {
    return this.accountBuilderForm.controls.templateSelected.value || '';
  }

  public templateSelected$: Observable<string>;

  get verticalsChoosen() {
    return this.verticalsSelected.join(', ') || '';
  }

  constructor(
    private formBuilder: FormBuilder,
    public accountBuilderService: AccountBuilderService,
    private matSnackbar: MatSnackBar,
    private jobService: JobService,
    public windowPane: WindowPane<AccountBuilderSettings>,

    private accountMetadataService: AccountMetadataService,
    private permissionService: AdminPermissionsService,
    private negativeKeywordsService: NegativeKeywordsService
  ) {}

  ngOnInit() {
    this.accountBuilderForm = this.formBuilder.group<
      typeof this.accountBuilderForm.controls
    >({
      templateSelected: this.formBuilder.control(null, {
        validators: Validators.required
      }),
      verticalIds: this.formBuilder.control({
        value: [],
        disabled: true
      }),
      postalcode: this.formBuilder.control({
        value: null,
        disabled: true
      }),
      latitude: this.formBuilder.control({
        value: null,
        disabled: true
      }),
      longitude: this.formBuilder.control({
        value: null,
        disabled: true
      }),
      budget: this.formBuilder.control({
        value: null,
        disabled: true
      }),
      radius: this.formBuilder.control({ value: 30, disabled: true }),
      adSets: this.formBuilder.array<
        typeof this.accountBuilderForm.controls.adSets.controls[0]
      >([]),
      searchSets: this.formBuilder.array<
        typeof this.accountBuilderForm.controls.searchSets.controls[0]
      >([]),
      callExtensionCountry: this.formBuilder.control({
        value: null,
        disabled: true
      }),
      callExtensionPhoneNumber: this.formBuilder.control({
        value: null,
        disabled: true
      }),
      negativeKeywords: this.formBuilder.control({
        value: [],
        disabled: true
      })
    });

    this.countries$ = this.accountMetadataService
      .getCountryCodes()
      .pipe(take(1));

    this.templateSelected$ =
      this.accountBuilderForm.controls.templateSelected.valueChanges.pipe(
        shareReplay(1)
      );

    this.verticals$ = this.templateSelected$.pipe(
      switchMap(templateId =>
        this.accountBuilderService.getSupportedVerticals(
          templateId as AccountBuilderTemplate
        )
      )
    );

    this.templateSelected$
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(templateSelectedId => {
        //Disabling all controls when template change to enable it later
        Object.keys(this.accountBuilderForm.controls).forEach(controlName => {
          if (controlName !== 'templateSelected') {
            const sectionControls = this.accountBuilderService.sectionControls;
            if (sectionControls?.[templateSelectedId]?.[controlName]?.enabled) {
              this.accountBuilderForm.controls[controlName].enable();

              if (
                sectionControls?.[templateSelectedId]?.[controlName]?.validator
              ) {
                this.accountBuilderForm.controls[controlName].setValidators(
                  sectionControls?.[templateSelectedId]?.[controlName]
                    ?.validator
                );
              }
            } else {
              this.accountBuilderForm.controls[controlName].disable();
            }
          }
        });

        this.setDefaultNegativeKeywordLists();
      });

    this.locationInfo$ = combineLatest([
      this.accountBuilderForm.controls.postalcode.valueChanges.pipe(
        debounceTime(300)
      ),
      this.locationChange$
    ]).pipe(
      filter(() => this.accountBuilderForm.controls.postalcode.valid),
      switchMap(([location]) => {
        this.accountBuilderForm.controls.latitude.reset();
        this.accountBuilderForm.controls.longitude.reset();
        return this.accountBuilderService.getLocationInfo(location);
      }),
      map(geoResult => {
        if (geoResult) {
          this.accountBuilderForm.controls.postalcode.setErrors(null);
          this.accountBuilderForm.controls.latitude.setValue(
            geoResult.geometry.location.lat
          );
          this.accountBuilderForm.controls.longitude.setValue(
            geoResult.geometry.location.lng
          );
        } else {
          this.accountBuilderForm.controls.postalcode.setErrors({
            locationNotFound: {
              message: 'Postal Code not found. Try a different one'
            }
          });
        }
        return geoResult;
      }),
      shareReplay(1)
    );

    this.progressStatus$ = this.progressStatusSource.asObservable();

    // Add hiring option if has the right permissions.
    if (this.permissionService.hasPermission('useHiringTemplate')) {
      this.templateOptions.push({ viewValue: 'Hiring', value: 'hiring' });
    }
  }

  setDefaultNegativeKeywordLists() {
    const templateSelectedId =
      this.accountBuilderForm.controls.templateSelected.value;
    switch (templateSelectedId) {
      case 'hiring':
        this.accountBuilderForm.controls.negativeKeywords.setValue([
          '-MhxrF6WRMQ32ima6Aya'
        ]);
        break;

      default:
        this.accountBuilderForm.controls.negativeKeywords.setValue([]);
        break;
    }
  }

  setVerticals() {
    this.verticalsSelected =
      this.accountBuilderForm.controls.verticalIds.value || [];
  }

  addAdSet() {
    this.adSetsItems.forEach(item => item.collapse());

    this.accountBuilderForm.controls.adSets.push(this.generateAdSet());
  }

  generateAdSet(): TypedFormGroup<AdSet> {
    const newSet: TypedFormGroup<AdSet> = this.formBuilder.group<
      TypedFormControl<AdSet>
    >({
      name: this.formBuilder.control(
        { value: null, disabled: true },
        { validators: Validators.required }
      ),
      hiring: this.formBuilder.control(
        { value: false, disabled: true },
        { validators: Validators.required }
      ),
      displayUrl: this.formBuilder.control(null, {
        validators: [Validators.required, SharedValidators.url]
      }),
      files: this.formBuilder.array<TypedFormGroup<FileUploaderFile>>([], {
        validators: Validators.required
      })
    });

    if (
      this.accountBuilderForm.controls.templateSelected.value === 'gold' ||
      this.accountBuilderForm.controls.templateSelected.value === 'hiring'
    ) {
      newSet.controls.name.enable();
    }

    if (this.accountBuilderForm.controls.templateSelected.value === 'gold') {
      newSet.controls.hiring.enable();
    }

    return newSet;
  }

  onDeleteAdSet(index: number) {
    this.accountBuilderForm.controls.adSets.removeAt(index);
  }

  updateLocationInfo() {
    const location = this.accountBuilderForm.controls.postalcode.value;
    if (location !== this.locationName) {
      this.locationChangeSource.next();
    }

    this.locationName = location;
  }

  async onSubmit() {
    try {
      const { latitude, longitude, ...rest } = this.accountBuilderForm.value;

      // Check hiring account numbers
      if (
        rest.adSets.filter(adSet => adSet.hiring).length > 1 &&
        rest.templateSelected !== 'hiring'
      ) {
        this.matSnackbar.open(
          `Multiple Hiring Campaigns are not allowed. Only submit one`,
          'Close'
        );
        return;
      }

      this.isWorking = true;

      this.reportStatus(`Preflight check`);

      // Prepare payload for account creation
      const accBuilderPayload = await this.preparePayload(
        rest as AccBuilderFormValue
      );
      this.reportStatus(`Applying template...`);

      // Apply template
      this.accountBuilderService
        .applyTemplate(accBuilderPayload, rest.templateSelected)
        .pipe(
          switchMap(({ queue, jobId }) =>
            this.jobService.getSkSyncJobStatus(queue, jobId)
          ),
          takeUntil(this.onDestroy$)
        )
        .subscribe(
          jobProgress => {
            // While
            this.reportStatus(jobProgress.progress.message || 'Working...');
          },
          jobProgress => {
            // Error Here
            this.isWorking = false;
            this.matSnackbar.open(
              `An error occured: ${
                jobProgress && jobProgress.progress
                  ? jobProgress.progress.message
                  : jobProgress && jobProgress.error
                  ? jobProgress.error.message
                  : ''
              }`,
              'Close',
              { duration: SNACKBAR_DURATION_ERROR }
            );
          },
          async () => {
            // Set negative keywords
            await this.setNegativeKeywords();

            // Complete Here
            this.matSnackbar.open(
              `Successfully applied template to account`,
              'Close'
            );
            this.isWorking = false;
            this.windowPane.close();
          }
        );
    } catch (error) {
      this.isWorking = false;
      this.matSnackbar.open(`An error occurred: ${error.message}`, 'Close');
    }
  }

  async setNegativeKeywords() {
    if (this.accountBuilderForm.controls.negativeKeywords.enabled) {
      this.reportStatus('Setting up negative keywords');
      const negativeKeywords: string[] =
        this.accountBuilderForm.controls.negativeKeywords.value;

      if (negativeKeywords) {
        await Promise.all(
          negativeKeywords.map(negativeKeywordsListId =>
            this.negativeKeywordsService.addAccount(negativeKeywordsListId, {
              entityId: this.windowPane.data.accountId,
              entityType: 'account',
              entityName: this.windowPane.data.accountName,
              accountType: 'adwords'
            })
          )
        );
      }
    }
  }

  async processAdSet(adSet: AdSet) {
    return {
      ...(adSet.name ? { name: adSet.name } : {}),
      ...(!isNil(adSet.hiring) ? { hiring: adSet.hiring } : {}),
      displayUrl: adSet.displayUrl
        .replace('https://', '')
        .replace('http://', ''),
      finalUrl: adSet.displayUrl,
      imageUrls: await this.uploadAds(adSet.files)
    };
  }

  async uploadAds(files: FileUploaderFile[]) {
    return await Promise.all(
      files.map(fileItem =>
        this.accountBuilderService
          .uploadFile(fileItem, this.windowPane.data.accountId)
          .then(file => {
            this.reportStatus(
              `Uploading ads (${this.totalAdsUploaded} / ${this.totalAdsToUpload})`,
              (this.totalAdsUploaded * 100) / this.totalAdsToUpload
            );
            this.totalAdsUploaded++;
            return file;
          })
      )
    );
  }

  async handleAdsUpload(
    accBuilderFormValue: AccBuilderFormValue
  ): Promise<Ad[]> {
    this.totalAdsUploaded = 0;
    this.totalAdsToUpload = accBuilderFormValue.adSets
      .map(adSet => adSet.files.length)
      .reduce((a, b) => a + b, 0);

    this.reportStatus(
      `Uploading ads (${this.totalAdsUploaded} / ${this.totalAdsToUpload})`,
      (this.totalAdsUploaded * 100) / this.totalAdsToUpload
    );

    return Promise.all(
      accBuilderFormValue.adSets.map(adSet => this.processAdSet(adSet))
    );
  }

  async preparePayload(
    accBuilderFormValue: AccBuilderFormValue
  ): Promise<AccBuilderPayload> {
    // Get latest location information
    const locationInfo = await firstValueFrom(this.locationInfo$);

    // Upload adSets
    const adSets = await this.handleAdsUpload(accBuilderFormValue);

    // Setting up initial Account Builder Payload
    const accBuilderPayload = {
      newAccountId: this.windowPane.data.accountId,
      ads: adSets,
      postalCode: accBuilderFormValue.postalcode,
      latitude: locationInfo.geometry.location.lat,
      longitude: locationInfo.geometry.location.lng,
      radius: accBuilderFormValue.radius
    };

    // Adding verticals to the payload if present
    if (accBuilderFormValue.verticalIds) {
      accBuilderPayload['verticals'] = accBuilderFormValue.verticalIds;
    }

    // Adding Search Campaign Ad sets to the payload
    if (accBuilderFormValue.searchSets?.length) {
      accBuilderPayload['searchAds'] = accBuilderFormValue.searchSets;
    }

    // Adding formatted budget to the payload if present
    if (accBuilderFormValue.budget) {
      accBuilderPayload['budget'] = transformCurrencyString(
        accBuilderFormValue.budget
      );
    }

    // Adding call extensions to payload if present
    if (
      this.accountBuilderService.stepSections[
        this.accountBuilderForm.controls.templateSelected.value
      ].callExtensions &&
      accBuilderFormValue.callExtensionCountry &&
      accBuilderFormValue.callExtensionPhoneNumber
    ) {
      accBuilderPayload['callExtensions'] = {
        countryCode: accBuilderFormValue.callExtensionCountry,
        phoneNumber: accBuilderFormValue.callExtensionPhoneNumber
      };
    }

    return accBuilderPayload;
  }

  reportStatus(message: string, percentage?: number) {
    this.progressStatusSource.next({
      overallPercentage: percentage || 100,
      progressType: percentage === undefined ? 'indeterminate' : 'determinate',
      messageStatus: message
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }
}
