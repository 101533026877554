import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewportOffsetService {
  private totalRoofHeight: number = 0;
  private roofHeightSource$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public roofHeight$: Observable<number> =
    this.roofHeightSource$.asObservable();

  constructor() {}

  addRoofHeight(height: number): void {
    this.totalRoofHeight = this.totalRoofHeight + height;
    this.roofHeightSource$.next(this.totalRoofHeight);
  }

  subtractRoofHeight(height: number): void {
    this.totalRoofHeight = this.totalRoofHeight - height;
    this.roofHeightSource$.next(this.totalRoofHeight);
  }
}
