import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs';

import { AuthService } from './auth/auth.service';
import { EnvironmentService } from './shared/environment.service';
import { UserService } from './users/user.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public isLoggedIn$: Observable<boolean>;
  public isAdmin$: Observable<boolean>;
  public isReady$: Observable<boolean>;
  public isActive$: Observable<boolean>;
  public readyToRedirect$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private environmentService: EnvironmentService
  ) {
    this.isLoggedIn$ = this.authService.firebaseUser$.pipe(
      map(authState => !!authState),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.isActive$ = this.userService.currentUserStatus$.pipe(
      map(status => status === 'active'),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    // TODO: remove this property and instead implement permission(s)
    // to restrict functionality where needed.
    this.isAdmin$ = this.userService.currentUser$.pipe(
      map(user => !!user?.roles?.['admin']),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.isReady$ = combineLatest([this.isLoggedIn$, this.isActive$]).pipe(
      map(([isLoggedIn, isActive]) => isLoggedIn && isActive),
      tap(ready => ready && this.environmentService.init()),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.readyToRedirect$ = this.isReady$.pipe(
      map(isReady => {
        if (isReady) {
          /**
           * Redirect to 'redirectTo' after sign in if any
           * Note, use `navigateByUrl` for better parsing
           * url with window data
           */
          this.router.navigateByUrl(
            this.route?.snapshot?.queryParams?.['redirectTo'] || '/'
          );
          return true;
        }

        return false;
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    /**
     * A listener for setting up default user avatar
     * when the new user became active
     */
    this.authService.newUserSigningIn$
      .pipe(
        take(1),
        switchMap(userId =>
          this.isActive$.pipe(
            take(1),
            map(() => userId)
          )
        ),
        switchMap(userId => this.userService.updateAvatar(null, userId))
      )
      .subscribe();
  }
}
