import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, Observable, scan, startWith, Subject } from 'rxjs';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from 'src/app/shared/constants';

import { SkSyncHttpService } from '../../../shared/sksync-http.service';
import { TaskModel } from '../../task.model';

@Component({
  selector: 'ease-task-keyword-conflicts',
  templateUrl: './task-keyword-conflicts.component.html',
  styleUrls: ['./task-keyword-conflicts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskKeywordConflictsComponent {
  @Input()
  task: TaskModel;
  @Input()
  adwordsId: string;
  @Input()
  items;
  public keywordShowing = {};
  private pendingOperationsSource$: Subject<{
    operationId: string;
    status: boolean;
  }> = new Subject<{ operationId: string; status: boolean }>();
  public pendingOperations$: Observable<Record<string, boolean>> =
    this.pendingOperationsSource$.asObservable().pipe(
      scan((acc, { operationId, status }) => {
        acc[operationId] = status;
        return acc;
      }, {}),
      startWith({})
    );

  constructor(
    private mdSnackBar: MatSnackBar,
    private http: SkSyncHttpService
  ) {}

  getKeywordLimit(keywordId: string) {
    return this.keywordShowing[keywordId] ? Infinity : 5;
  }

  setKeywordLimit(keywordId: string) {
    this.keywordShowing[keywordId] = !this.keywordShowing[keywordId];
  }

  async removeKeyword({ negativeId, level, listId, campaignId, adGroupId }) {
    this.pendingOperationsSource$.next({
      operationId: negativeId,
      status: true
    });

    try {
      await firstValueFrom(
        this.http.request(
          'DELETE',
          `/adwords/${this.adwordsId}/keywords/${negativeId}`,
          {
            body: {
              taskId: this.task.$key,
              level,
              listId,
              campaignId,
              adGroupId
            }
          }
        )
      );

      this.mdSnackBar.open('Negative keyword deleted.', 'Close', {
        duration: SNACKBAR_DURATION_SUCCESS
      });
    } catch (err) {
      this.mdSnackBar.open(
        'Oops! There was an error deleting the negative keyword.',
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );
    }

    this.pendingOperationsSource$.next({
      operationId: negativeId,
      status: false
    });
  }

  async pauseKeyword(negativeId, conflicts, adGroupId) {
    const keywordIds = conflicts.map(conflict => conflict.id);
    const operationId = `${negativeId}:${adGroupId}`;
    this.pendingOperationsSource$.next({
      operationId,
      status: true
    });
    try {
      await firstValueFrom(
        this.http.put(`/adwords/${this.adwordsId}/keywords`, {
          taskId: this.task.$key,
          keywordIds,
          negativeId,
          adGroupId
        })
      );
      this.mdSnackBar.open('Keyword paused.', 'Close', {
        duration: SNACKBAR_DURATION_SUCCESS
      });
    } catch (err) {
      this.mdSnackBar.open(
        'Oops! There was an error pausing the keyword.',
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );
    }

    this.pendingOperationsSource$.next({
      operationId,
      status: false
    });
  }
}
