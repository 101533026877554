<div
  class="checklist-checkbox my-2 md:my-0.5"
  [ngClass]="{
    'checklist-checkbox--checked': group?.controls?.checked?.value
  }"
  [formGroup]="group"
>
  <mat-icon
    matTooltip="Checking this box will perform the action described automatically"
    *ngIf="templateOptions?.actionKey"
    class="action-icon"
    >offline_bolt</mat-icon
  >
  <div class="flex w-full flex-col justify-between sm:flex-row">
    <div
      class="flex w-full flex-col items-start justify-between md:flex-row md:flex-nowrap md:items-center md:gap-4"
    >
      <mat-checkbox
        #checkbox
        [checked]="group?.controls?.checked?.value"
        [disabled]="group?.controls?.checked?.disabled"
        (click)="clickCheckbox(checkbox)"
        color="primary"
        class="w-full max-w-max flex-full md:flex-auto"
      >
        <span
          [ngClass]="{
            'line-through decoration-gray-600': group?.controls?.checked?.value
          }"
          [easeMarkdown]="templateOptions?.groupLabel"
        ></span>
      </mat-checkbox>
      <div
        class="ml-6 flex w-full max-w-max flex-full flex-row items-center md:ml-0 md:flex-auto"
      >
        <mat-radio-group
          formControlName="radio"
          class="mt-1 flex flex-row flex-wrap gap-x-2 md:mt-0"
        >
          <mat-radio-button
            color="primary"
            class=""
            *ngFor="
              let checklistRadio of templateOptions?.options | objectToIterable
            "
            [value]="checklistRadio?.value"
          >
            {{ checklistRadio?.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="checklist-meta md:ml-auto">
        <div
          class="ml-6 flex items-center"
          [ngClass]="{ '!ml-[29px] md:ml-0': templateOptions?.actionKey }"
          *ngIf="group?.controls?.completedBy?.value"
        >
          <div>{{ group.controls.completedBy.value }}</div>
        </div>
        <div
          *ngIf="group?.controls?.completedAt?.value"
          class="flex items-center text-[11px] leading-snug text-gray-600"
        >
          <mat-icon class="mat-icon-12 mr-0.5 mb-px !hidden md:!block"
            >done</mat-icon
          >
          {{ group.controls.completedAt.value | amDateFormat: 'MMM D, h:mm a' }}
        </div>
      </div>
    </div>
  </div>
</div>
