<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <mat-icon class="mat-icon-24 mr-2">portrait</mat-icon>
    <span class="pr-1 text-base font-medium text-gray-900">Users</span>
    <span class="text-sm italic text-gray-600"> ({{ users.length }}) </span>
  </mat-expansion-panel-header>

  <div class="m-0 mb-2">
    <div class="flex flex-row flex-wrap">
      <button
        (click)="userPopover.open()"
        [satPopoverAnchor]="userPopover"
        matTooltip="Add Users"
        easeStopEvent
        class="!mr-2 !h-12 !rounded-full"
        color="primary"
        mat-flat-button
      >
        <mat-icon class="mr-2">person_add</mat-icon>
        <span>Add / Remove Users</span>
      </button>
      <ng-container *ngIf="users.length; else noUsers">
        <ease-avatar
          *ngFor="let user of users"
          class="mr-2 mb-3"
          [userId]="user.id"
          [showTooltip]="true"
          [showInitials]="true"
          [size]="'medium'"
        ></ease-avatar>
      </ng-container>
    </div>
  </div>

  <sat-popover
    verticalAlign="start"
    horizontalAlign="end"
    #userPopover
    [hasBackdrop]="true"
  >
    <ease-select-user-popover
      *ngIf="userPopover.isOpen()"
      (selected)="addUser($event)"
      (deselected)="removeUser($event)"
      [selectedUsers]="selectedUsers"
      [onlyUsers]="onlyUsers"
    >
    </ease-select-user-popover>
  </sat-popover>
</mat-expansion-panel>

<ng-template #noUsers>
  <ease-state-message
    [minHeight]="53"
    message="Start adding users by clicking on the button on the left"
    class="w-[initial] flex-1 rounded-[30px] border border-dashed border-gray-400"
  ></ease-state-message>
</ng-template>
