import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowCloseDirective } from './window-close.directive';

@NgModule({
  declarations: [WindowCloseDirective],
  imports: [CommonModule],
  exports: [WindowCloseDirective]
})
export class WindowCloseModule {}
