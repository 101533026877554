import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';

import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMaskModule } from 'ngx-mask';
import { AdvancedAutocompleteModule } from 'src/app/shared/advanced-autocomplete/advanced-autocomplete.module';
import { EaseMaterialModule } from '../../shared/ease-material.module';
import { SharedModule } from '../../shared/shared.module';
import { FormErrorsModule } from '../../shared/form-errors/form-errors.module';
import { CustomerPlanSelectorModule } from '../customer-plan-selector/customer-plan-selector.module';
import { MarkdownModule } from '../../shared/markdown/markdown.module';
import { CustomerOnboardingComponent } from './customer-onboarding.component';
import { CustomerOnboardingTrackerComponent } from './customer-onboarding-tracker/customer-onboarding-tracker.component';
import { CustomerOnboardingTrackerStageComponent } from './customer-onboarding-tracker-stage/customer-onboarding-tracker-stage.component';
import { CustomerOnboardingCreateEditDialogComponent } from './customer-onboarding-create-edit-dialog/customer-onboarding-create-edit-dialog.component';
import { CustomerOnboardingTaskPackageComponent } from './customer-onboarding-task-package/customer-onboarding-task-package.component';
import { CustomerOnboardingFormRendererItemComponent } from './customer-onboarding-form-renderer-item/customer-onboarding-form-renderer-item.component';
import { CustomerOnboardingFormRendererComponent } from './customer-onboarding-form-renderer/customer-onboarding-form-renderer.component';
import { CustomerOnboardingFormPreviewDialogComponent } from './customer-onboarding-form-preview-dialog/customer-onboarding-form-preview-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EaseMaterialModule,
    ReactiveFormsModule,
    FormErrorsModule,
    DragDropModule,
    CustomerPlanSelectorModule,
    NgxMaskModule,
    MarkdownModule,
    AgGridModule,
    AdvancedAutocompleteModule
  ],
  declarations: [
    CustomerOnboardingComponent,
    CustomerOnboardingTrackerComponent,
    CustomerOnboardingTrackerStageComponent,
    CustomerOnboardingCreateEditDialogComponent,
    CustomerOnboardingTaskPackageComponent,
    CustomerOnboardingFormRendererItemComponent,
    CustomerOnboardingFormRendererComponent,
    CustomerOnboardingFormPreviewDialogComponent
  ],
  exports: [CustomerOnboardingComponent, CustomerOnboardingTaskPackageComponent]
})
export class CustomerOnboardingModule {}
