<div
  class="metric row details flex flex-col"
  [ngClass]="account?.cplMetric?.ragStatusColor"
>
  <!--header-->
  <div class="row header flex flex-1 flex-row">
    <div class="column cell flex flex-row items-center justify-center">
      <div class="graph flex flex-col">
        <div class="dot flex flex-row">
          <div class="indicator metric-background"></div>
        </div>
      </div>

      <div class="status-summary flex-1">
        {{ account.cplMetric.ragStatusDescription }}
      </div>
    </div>
  </div>
  <!--details-->
  <div class="detail-summary row flex flex-row">
    <div
      class="column !flex !max-w-[33%] !flex-full !flex-col !items-center !justify-center"
    >
      <div class="metric-columns flex flex-row">
        <div
          class="column cell detail flex flex-1 flex-col items-center justify-center"
        >
          <ease-metric-simple
            [label]="'Account CPL'"
            [tooltip]="
              'The account\'s accrued spend divided by the number of leads for the period so far.'
            "
            [value]="account.cplMetric.cpl | currency: 'USD':'symbol':'1.0-2'"
            [differenceNumeric]="
              account.cplMetric.cpl && account.cplMetric.cplTarget
                ? (account.cplMetric.cpl - account.cplMetric.cplTarget
                  | currency: 'USD':'symbol':'1.0-2')
                : null
            "
            [differencePercentage]="account.cplMetric.percentageSwingFromTarget"
          ></ease-metric-simple>
        </div>
        <div
          class="column detail flex flex-1 flex-col items-center justify-center"
        >
          <ease-metric-simple
            *ngIf="!editingTarget"
            [label]="'Target CPL'"
            [value]="
              costPerLeadMetric.cplTarget | currency: 'USD':'symbol':'1.0-2'
            "
          ></ease-metric-simple>
          <div
            *ngIf="editingTarget"
            class="flex flex-col items-center justify-center"
          >
            <mat-form-field class="cpl-form">
              <input
                matInput
                placeholder="Target CPL"
                type="number"
                [formControl]="targetCPLControl"
              />
            </mat-form-field>
            <div class="flex flex-row">
              <button
                mat-icon-button
                matTooltip="Cancel"
                (click)="cancelTargetCPL()"
              >
                <mat-icon>close</mat-icon>
              </button>
              <button
                (click)="saveTargetCPL()"
                mat-icon-button
                matTooltip="Save Target CPL"
              >
                <mat-icon>save</mat-icon>
              </button>
            </div>
          </div>
          <a
            class="edit-link"
            *ngIf="!editingTarget"
            (click)="editingTarget = true"
            >Edit Target</a
          >
        </div>
      </div>
      <div
        *ngIf="account?.targetCpl !== targetCplNumber && targetCplNumber !== 0"
      >
        <div class="note-container">
          <div class="metric-note">
            New Target CPL (<strong>{{
              account.targetCpl | currency: 'USD':'symbol':'1.0-2'
            }}</strong
            >) will take 24h to be reflected in this calculation.
          </div>
        </div>
      </div>
    </div>

    <ng-container>
      <div class="column cell detail flex flex-1 flex-col justify-center">
        <ease-metric-list
          *ngIf="
            account?.cplMetric?.metadata?.last14 ||
            account?.cplMetric?.metadata?.last7 ||
            account?.cplMetric?.metadata?.last30
          "
          [list]="[
            {
              label: 'Last 7 Days',
              value:
                (account?.cplMetric?.metadata?.last7
                  | currency: 'USD':'symbol':'1.0-2') || 'Not Available'
            },
            {
              label: 'Last 14 Days',
              value:
                (account?.cplMetric?.metadata?.last14
                  | currency: 'USD':'symbol':'1.0-2') || 'Not Available'
            },
            {
              label: 'Last 30 Days',
              value:
                (account?.cplMetric?.metadata?.last30
                  | currency: 'USD':'symbol':'1.0-2') || 'Not Available'
            }
          ]"
        ></ease-metric-list>
      </div>
      <div
        class="column cell detail !flex-1"
        *ngIf="account.cplMetric?.metadata?.chartData.labels.length"
      >
        <ease-chart
          [height]="130"
          [data]="account.cplMetric?.metadata?.chartData"
          [type]="'line'"
          [options]="accountDashboardService.currencyChartOptions"
        >
        </ease-chart>
      </div>
    </ng-container>
  </div>
</div>
