import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';

import { InlineDateRangePickerModule } from 'src/app/shared/inline-date-range-picker/inline-date-range-picker.module';
import { SharedPipesModule } from 'src/app/shared/shared-pipes.module';
import { AvatarModule } from '../../shared/avatar/avatar.module';
import { RichTooltipModule } from '../../shared/rich-tooltip/rich-tooltip.module';
import { UserProfileDialogComponent } from './user-profile-dialog/user-profile-dialog.component';

@NgModule({
  imports: [
    AvatarModule,
    CommonModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule,
    RichTooltipModule,
    InlineDateRangePickerModule,
    SharedPipesModule
  ],
  exports: [UserProfileDialogComponent],
  declarations: [UserProfileDialogComponent]
})
export class UserProfileModule {}
