import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ease-chart-header',
  templateUrl: './chart-header.component.html',
  styleUrls: ['./chart-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
