import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AccountBuilderModule } from 'src/app/accounts/account-builder/account-builder.module';
import { RichTooltipModule } from '../rich-tooltip/rich-tooltip.module';
import { SharedModule } from '../shared.module';
import { WindowManagerComponent } from './window-manager/window-manager.component';
import { WindowPaneComponent } from './window-pane/window-pane.component';
import { WindowOutletComponent } from './window-outlet/window-outlet.component';
import { WindowPaneSendEmailModule } from './window-pane-send-email/window-pane-send-email.module';
import { WindowPaneCreateFundingModule } from './window-pane-create-funding/window-pane-create-funding.module';
import { WindowPaneCreateAnnouncementModule } from './window-pane-create-announcement/window-pane-create-announcement.module';
import { WindowPaneCreateTaskModule } from './window-pane-create-task/window-pane-create-task.module';
import { WindowPaneHostDirective } from './window-pane/window-pane-host.directive';
import { WindowCloseModule } from './window-close/window-close.module';
import { WindowPaneViewTaskModule } from './window-pane-view-task/window-pane-view-task.module';
import { WindowRouteComponent } from './window-route/window-route.component';

@NgModule({
  imports: [
    DragDropModule,
    PortalModule,
    SharedModule,
    RichTooltipModule,
    WindowPaneCreateAnnouncementModule,
    WindowPaneCreateTaskModule,
    WindowPaneSendEmailModule,
    WindowPaneCreateFundingModule,
    AccountBuilderModule,
    WindowCloseModule,
    WindowPaneViewTaskModule
  ],
  declarations: [
    WindowManagerComponent,
    WindowOutletComponent,
    WindowPaneComponent,
    WindowPaneHostDirective,
    WindowRouteComponent
  ],
  exports: [WindowManagerComponent, WindowOutletComponent, WindowRouteComponent]
})
export class WindowModule {}
