import { map, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  FirebaseDbService,
  ListAsObject
} from 'src/app/shared/firebase-db.service';
import { ACCOUNT_METADATA_PATH } from './firebase-paths';
import {
  AnalyticsAccountResponse,
  AnalyticsPropertyDataStreamResponse,
  AnalyticsPropertyResponse,
  AnalyticsViewResponse,
  Dma,
  EntityMetaItem,
  ListDisplayGroup,
  ListDisplayItem
} from './shared.interface';
import { SkSyncHttpService } from './sksync-http.service';

@Injectable({ providedIn: 'root' })
export class AccountMetadataService {
  private verticals$: Observable<ListDisplayItem[]> = this.angularFire
    .getList(`/${ACCOUNT_METADATA_PATH}/verticals`, ref =>
      ref.orderByChild('name')
    )
    .pipe(
      map((verticals: any[]) =>
        verticals.map(vertical => ({
          value: vertical.$key,
          viewValue: vertical.name
        }))
      ),
      shareReplay(1)
    );

  private currencies$: Observable<ListDisplayItem[]> = this.angularFire
    .getList(`/${ACCOUNT_METADATA_PATH}/currencies`)
    .pipe(
      map((currencies: any[]) =>
        currencies.map(currency => ({
          value: currency.$key,
          viewValue: currency.$value
        }))
      ),
      shareReplay(1)
    );

  private countries$: Observable<ListDisplayItem[]> = this.angularFire
    .getList(`/${ACCOUNT_METADATA_PATH}/countries`)
    .pipe(
      map((countries: any[]) =>
        countries.map(country => ({
          value: country.name,
          viewValue: country.value
        }))
      ),
      shareReplay(1)
    );

  private countryCodes$: Observable<ListDisplayItem[]> = this.angularFire
    .getList(`/${ACCOUNT_METADATA_PATH}/countries`)
    .pipe(
      map((countries: any[]) =>
        countries.map(country => ({
          value: country.code,
          viewValue: country.name
        }))
      ),
      shareReplay(1)
    );

  private timezones$: Observable<ListDisplayGroup[]> = this.angularFire
    .getList(`/${ACCOUNT_METADATA_PATH}/timezones`, ref =>
      ref.orderByChild('order')
    )
    .pipe(
      map(timezones =>
        timezones.map(timezoneGroup => ({
          name: timezoneGroup.name,
          items: Object.keys(timezoneGroup.zones).map(zoneKey => ({
            value: timezoneGroup.zones[zoneKey].value,
            viewValue: timezoneGroup.zones[zoneKey].viewValue
          }))
        }))
      ),
      shareReplay(1)
    );

  private sources$: Observable<ListDisplayItem[]> = this.angularFire
    .getList(`/${ACCOUNT_METADATA_PATH}/sources`, ref =>
      ref.orderByChild('name')
    )
    .pipe(
      map((sources: any[]) =>
        sources.map(source => ({ value: source.$key, viewValue: source.name }))
      ),
      shareReplay(1)
    );

  private dmas$: Observable<ListDisplayItem[]> = this.angularFire
    .getList(`/${ACCOUNT_METADATA_PATH}/dmas`, ref => ref.orderByChild('name'))
    .pipe(
      map((dmas: any[]) =>
        dmas.map(dma => ({ value: dma.$key, viewValue: dma.name }))
      ),
      shareReplay(1)
    );

  private tags$: Observable<ListDisplayItem[]> = this.angularFire
    .getList(`/${ACCOUNT_METADATA_PATH}/tags`, ref => ref.orderByChild('name'))
    .pipe(
      map((tags: any[]) =>
        tags.map(tag => ({ value: tag.$key, viewValue: tag.name }))
      ),
      shareReplay(1)
    );

  private distributors$: Observable<ListDisplayItem[]> = this.angularFire
    .getList(`/${ACCOUNT_METADATA_PATH}/distributors`, ref =>
      ref.orderByChild('name')
    )
    .pipe(
      map((distributors: any[]) =>
        distributors.map(distributor => ({
          value: distributor.$key,
          viewValue: distributor.name
        }))
      ),
      shareReplay(1)
    );

  public dmasAsObject$: Observable<ListAsObject<Dma>> = this.angularFire
    .getObject(`/${ACCOUNT_METADATA_PATH}/dmas`)
    .pipe(shareReplay(1));

  public tagsAsObject$: Observable<ListAsObject<EntityMetaItem>> =
    this.angularFire
      .getObject(`/${ACCOUNT_METADATA_PATH}/tags`)
      .pipe(shareReplay(1));

  public verticalsAsObject$: Observable<ListAsObject<EntityMetaItem>> =
    this.angularFire
      .getObject(`/${ACCOUNT_METADATA_PATH}/verticals`)
      .pipe(shareReplay(1));

  public distributorsAsObject$: Observable<ListAsObject<EntityMetaItem>> =
    this.angularFire
      .getObject(`/${ACCOUNT_METADATA_PATH}/distributors`)
      .pipe(shareReplay(1));

  public sourcesAsObject$: Observable<ListAsObject<EntityMetaItem>> =
    this.angularFire
      .getObject(`/${ACCOUNT_METADATA_PATH}/sources`)
      .pipe(shareReplay(1));

  constructor(
    private angularFire: FirebaseDbService,
    private http: SkSyncHttpService
  ) {}

  getCurrencies(): Observable<ListDisplayItem[]> {
    return this.currencies$;
  }

  getCountries(): Observable<ListDisplayItem[]> {
    return this.countries$;
  }

  getCountryCodes(): Observable<ListDisplayItem[]> {
    return this.countryCodes$;
  }

  getTimezones(): Observable<ListDisplayGroup[]> {
    return this.timezones$;
  }

  getVerticals(): Observable<ListDisplayItem[]> {
    return this.verticals$;
  }

  getSources(): Observable<ListDisplayItem[]> {
    return this.sources$;
  }

  getDmas(): Observable<ListDisplayItem[]> {
    return this.dmas$;
  }

  getTags(
    filterTagIds?: boolean,
    tagIds?: Record<string, boolean>
  ): Observable<ListDisplayItem[]> {
    if (filterTagIds) {
      return this.tags$.pipe(
        map(allTags => this.listDisplayFilter(allTags, tagIds))
      );
    } else {
      return this.tags$;
    }
  }

  getDistributors(): Observable<ListDisplayItem[]> {
    return this.distributors$;
  }

  createVertical(name: string) {
    if (name) {
      return this.angularFire
        .list(`/${ACCOUNT_METADATA_PATH}/verticals`)
        .push({ name });
    }
  }

  createSource(name: string) {
    if (name) {
      return this.angularFire
        .list(`/${ACCOUNT_METADATA_PATH}/sources`)
        .push({ name });
    }
  }

  createTag(name: string) {
    if (name) {
      return this.angularFire
        .list(`/${ACCOUNT_METADATA_PATH}/tags`)
        .push({ name });
    }
  }

  createDistributor(name: string) {
    if (name) {
      return this.angularFire
        .list(`/${ACCOUNT_METADATA_PATH}/distributors`)
        .push({ name });
    }
  }

  getDma(dmaId: string): Observable<Dma> {
    return this.angularFire.getObject(
      `/${ACCOUNT_METADATA_PATH}/dmas/${dmaId}`
    );
  }

  getAnalyticsAccounts(email: string): Observable<AnalyticsAccountResponse[]> {
    return this.http.get(`/analytics/${email}/accounts`);
  }

  getPropertiesForAnalyticsAccount(
    email: string,
    accountId: string
  ): Observable<AnalyticsPropertyResponse[]> {
    return this.http.get(
      `/analytics/${email}/accounts/${accountId}/properties`
    );
  }

  getDataStreamsForAnalyticsProperty(
    email: string,
    propertyId: string
  ): Observable<AnalyticsViewResponse[]> {
    return this.http.get(
      `/analytics/${email}/properties/${propertyId}/dataStreams`
    );
  }

  getWebStreamDataForAnalyticsDataStream(
    email: string,
    propertyId: string,
    streamId: string
  ): Observable<AnalyticsPropertyDataStreamResponse> {
    return this.http.get(
      `/analytics/${email}/properties/${propertyId}/dataStreams/${streamId}`
    );
  }

  listDisplayFilter(
    lists: ListDisplayItem[],
    listIds: Record<string, boolean>
  ): ListDisplayItem[] {
    return listIds
      ? lists.filter(list => Object.keys(listIds).includes(`${list.value}`))
      : null;
  }
}
