import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Account } from '../../account-dashboard.interface';

@Component({
  selector: 'ease-alert-cell',
  templateUrl: './alert-cell.component.html',
  styleUrls: ['./alert-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertCellComponent {
  public account: Account;
  public isGroup: boolean;
  public hasError: boolean = false;

  constructor() {}

  agInit(params): void {
    this.account = params.data;
    this.isGroup = params.node.group;

    if (this.isGroup) {
      this.hasError = params.node.allLeafChildren.some(
        childRow => !!childRow.data.metadata
      );
    }
  }
}
