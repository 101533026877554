<div class="my-2 flex flex-col gap-2 py-2 text-gray-600">
  <div class="flex flex-row items-center gap-2">
    <ease-avatar class="self-start" [userId]="item?.createdBy"></ease-avatar>

    <div class="flex flex-1 flex-col gap-1 overflow-hidden">
      <div class="text-[13px] leading-5">
        <span class="font-bold">
          {{ users[item?.createdBy]?.name }}
        </span>
        changed the plan
      </div>

      <div
        class="flex flex-row items-center gap-2 text-[13px] font-medium leading-5 text-gray-600"
      >
        {{ item?.oldPlanName || 'No Plan' }}
        <mat-icon class="mat-icon-16">arrow_forward</mat-icon>
        {{ item?.newPlanName }}
      </div>

      <div class="text-xs leading-5 text-gray-600">
        {{ item?.createdAt | amDateFormat: dateFormat }}
      </div>
    </div>
  </div>
</div>
