import { NgModule } from '@angular/core';
import { SharedFormModule } from '../shared-form.module';
import { SharedModule } from '../shared.module';
import { SharedPipesModule } from '../shared-pipes.module';
import { EntityStatusSelectorComponent } from './entity-status-selector.component';

@NgModule({
  imports: [SharedModule, SharedFormModule, SharedPipesModule],
  declarations: [EntityStatusSelectorComponent],
  exports: [EntityStatusSelectorComponent]
})
export class EntityStatusSelectorModule {}
