import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { EmojiData, EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { SatPopover } from '@ncstate/sat-popover';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ToggleComponent } from '../../toggle/toggle.component';
import { TaskNotesReactionService } from '../../../tasks/task-notes/task-notes-reaction.service';
import { UserService } from '../../../users/user.service';
import {
  Reaction,
  TaskNote
} from '../../../tasks/task-notes/task-notes.interface';
import { SNACKBAR_DURATION_SUCCESS } from '../../constants';
import { SharedLayoutService } from '../../shared-layout.service';
import { FeedItemNote } from './feed-item-note.interface';

@Component({
  selector: 'ease-feed-item-note',
  templateUrl: './feed-item-note.component.html',
  styleUrls: ['../feed-item/feed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemNoteComponent implements AfterViewInit {
  @Input()
  users;
  @Input()
  taskId: string;
  @Input()
  dateFormat: string;
  @Input()
  editable: boolean = false;
  @Input()
  currentUser: string;
  @Input()
  nowTime: number;
  @Input()
  canMention: boolean = true;
  @Input()
  canPin: boolean = false;
  @Input()
  canReact: boolean = false;
  @Input() canShare: boolean = false;
  @Output()
  save: EventEmitter<Partial<FeedItemNote & TaskNote>> = new EventEmitter<
    Partial<FeedItemNote & TaskNote>
  >();
  private _note: FeedItemNote & TaskNote;
  @Input()
  set note(note: FeedItemNote & TaskNote) {
    // skip updating note if user is still editing
    if (!this.isEditing) {
      const noteFormatted = {
        ...note,
        $key: note.$key
      };

      // Prepare reaction data
      if (note.reactions) {
        noteFormatted.reactionsArray = Object.keys(noteFormatted.reactions).map(
          reactionKey => {
            const reaction = { ...noteFormatted.reactions[reactionKey] };
            const names = [];
            if (reaction.users) {
              reaction.users = Object.keys(reaction.users).reduce(
                (acc, userKey) => {
                  names.push(reaction.users[userKey].shortName);
                  acc.push(reaction.users[userKey]);
                  return acc;
                },
                []
              );
            }

            // Guard against any existing reactions that are missing `colons`
            if (!reaction.colons) {
              // If no `colons`, use previously-saved reaction `id` instead
              reaction.colons = `:${reaction.id}:`;
            }

            reaction.userTriggered =
              !!note.reactions[reactionKey]?.users?.[
                this.userService.currentUser.$key
              ];

            reaction.tooltipText = `${names.join(', ')} reacted with ${
              reaction.colons
            }`;

            return reaction;
          }
        );
      }
      this._note = noteFormatted;
    }
  }
  get note(): FeedItemNote & TaskNote {
    return this._note;
  }

  public noteLink: string;
  public isCopied: boolean = false;
  public isEditing: boolean = false;

  @ViewChild('noteToggle', { static: true }) noteToggle: ToggleComponent;
  @ViewChild('reactionSelector', { static: true }) reactionSelector: SatPopover;
  @ViewChild('shareTooltip', { static: false, read: MatTooltip })
  shareNoteTooltip: MatTooltip;

  constructor(
    private clipboard: Clipboard,
    private matSnackBar: MatSnackBar,
    private reactionService: TaskNotesReactionService,
    public sharedLayoutService: SharedLayoutService,
    private userService: UserService
  ) {}

  ngAfterViewInit() {
    this.noteLink = `${window.location.origin}/tasks/${this.taskId}?noteId=${this.note?.$key}`;
  }

  shareNoteLink(): void {
    this.isCopied = true;
    this.clipboard.copy(this.noteLink);
    this.shareNoteTooltip?.show();
  }

  shareLeave(): void {
    this.shareNoteTooltip?.hide();
    setTimeout(() => {
      this.isCopied = false;
    }, 300);
  }

  editNote(): void {
    this.isEditing = true;
    this.noteToggle.show();
  }

  saveNote(body: string): void {
    this.isEditing = false;
    this.save.emit({ body });
  }

  togglePinned(): void {
    this.matSnackBar.open(
      `Note ${this.note?.pinned ? 'unpinned' : 'pinned'}`,
      'OKAY',
      { duration: SNACKBAR_DURATION_SUCCESS }
    );
    this.save.emit({ pinned: !!!this.note?.pinned });
  }

  addReaction(event: EmojiEvent): void {
    this.reactionService
      .addReactionToNote(this.taskId, this.note?.$key, event.emoji)
      .then(() => this.reactionSelector && this.reactionSelector.close());
  }

  handleReaction(reaction: Reaction, emoji: EmojiData): void {
    if (reaction.userTriggered && reaction.users.length <= 1) {
      this.reactionService.removeReaction(this.taskId, this.note?.$key, emoji);
    } else if (reaction.userTriggered) {
      this.reactionService.removeUserFromReaction(
        this.taskId,
        this.note?.$key,
        emoji
      );
    } else {
      this.reactionService.addReactionToNote(
        this.taskId,
        this.note?.$key,
        emoji
      );
    }
  }
}
