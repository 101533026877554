import { moveItemInArray } from '@angular/cdk/drag-drop';
import { PlatformLocation } from '@angular/common';
import {
  ComponentRef,
  Injectable,
  Injector,
  StaticProvider,
  Type,
  ViewContainerRef
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Params, Router } from '@angular/router';
import { isEqual, pick } from 'lodash-es';
import {
  BehaviorSubject,
  firstValueFrom,
  fromEvent,
  Observable,
  Subject
} from 'rxjs';
import { filter, map, pairwise, skip, tap } from 'rxjs/operators';

import { AppService } from 'src/app/app.service';
import { ConfirmService } from '../confirm/confirm.service';
import { SNACKBAR_DURATION_ERROR } from '../constants';
import {
  WindowConfig,
  WINDOW_CONTENT,
  WINDOW_DATA,
  WINDOW_ROUTER_OUTLET
} from './window-options';
import { WindowPane } from './window-pane/window-pane';
import { WindowPaneComponent } from './window-pane/window-pane.component';
import { WindowType, windowTypes } from './window-types';

@Injectable({ providedIn: 'root' })
export class WindowService {
  public selected$: BehaviorSubject<WindowPane> =
    new BehaviorSubject<WindowPane>(null);
  private reachedMaxWindowsSource: Subject<boolean> = new Subject<boolean>();
  public reachedMaxWindows$ = this.reachedMaxWindowsSource.asObservable();
  private paneHost$: Subject<ViewContainerRef> =
    new Subject<ViewContainerRef>();
  private paneHost: ViewContainerRef;
  private storageKey: string = 'windowState';
  private MAX_WINDOWS: number = 50;
  private selected: WindowPane;
  private recentlyClosed: { index: number; window: WindowPane<any> }[] = [];
  private maxRecentWindows: number = 10;

  constructor(
    private appService: AppService,
    private matSnackBar: MatSnackBar,
    private location: PlatformLocation,
    private router: Router,
    private confirmService: ConfirmService
  ) {
    this.subscribeToBackButton();
    this.subscribeToRouterEvents();
    this.subscribeToAuthState();
    this.subscribeToStorageEvents();
  }

  setSelected(windowPane: WindowPane<any>): void {
    this.selected$.next(windowPane);
    this.selected = windowPane;
  }

  getSelected(): Observable<WindowPane> {
    return this.selected$.asObservable();
  }

  /**
   * Find the task window with the same taskId and close the window
   */
  async closeTaskWindow(taskId: string): Promise<boolean> {
    const window = this.windows.find(windowPane => windowPane.data === taskId);
    return window ? window.close() : false;
  }

  /**
   * Make `windows` property readonly from outside
   * this class to shield it from unintended modification
   */
  private _windows: WindowPane<any>[] = [];
  public get windows(): WindowPane<any>[] {
    return this._windows;
  }

  /**
   * Create a window pane and load a given component into it
   *
   * @param content Component that will be loaded into the window
   * @param windowConfig Window configuration
   * @param minimizeCurrentWindow After creation, minimize other windows
   * @param index Index to create new window at, defaults to end of the list
   */
  public async create<R>(
    windowConfig: WindowConfig<R>,
    minimizeCurrentWindow = true,
    saveState = true,
    index?: number
  ): Promise<WindowPane<R>> {
    /**
     * Important: if paneHost is not ready for injection yet, wait
     * for it to resolved before create any `windowComponentRef`
     */
    if (!!!this.paneHost) {
      await firstValueFrom(this.paneHost$);
    }

    const config = new WindowConfig<R>({
      ...windowTypes[windowConfig.type].config,
      ...windowConfig
    });

    const windowRef = new WindowPane<R>(config, this);

    /**
     * If the new window configuration prevents duplicates,
     * check whether an identical window exists and open that instead
     */
    if (!windowRef.allowDuplicates) {
      const existing = this.exists(windowRef);

      if (existing) {
        if (existing.state === 'MINIMIZED') {
          existing.maximize();
        }

        return existing;
      }
    }

    /**
     * Before we take any further action, check if we are within
     * the maximum number of windows and return early if we won't
     * be after opening the new one
     */
    if (this.windows.length + 1 > this.MAX_WINDOWS) {
      this.reachedMaxWindowsSource.next(true);
      this.matSnackBar.open('Maximum number of windows reached', 'Close', {
        duration: SNACKBAR_DURATION_ERROR
      });
      return;
    }

    const windowComponentRef = this.appendToHost(
      windowTypes[windowConfig.type].component,
      config,
      windowRef
    );
    windowRef.componentRef = windowComponentRef;
    this.subscribeToRefEvents(windowRef);
    index >= 0
      ? this._windows.splice(index, 0, windowRef)
      : this._windows.push(windowRef);
    saveState && this.saveState();

    /**
     * Only minimize current window if specified and the newly-created
     * window has an initial state that is non-minimized
     */
    if (minimizeCurrentWindow && config.state !== 'MINIMIZED') {
      this.minimizeAllWindows(windowRef.id);
    }

    await windowRef.updateRoute();
    return windowRef;
  }

  /**
   * Move a window to a new index
   *
   * @param currentIndex Current index of the window to be moved
   * @param newIndex New index of the window to be moved
   */
  public move(currentIndex: number, newIndex: number) {
    moveItemInArray(this.windows, currentIndex, newIndex);
    this.saveState();
  }

  /**
   * Close all windows
   */
  public async closeAll() {
    const confirmResult = await this.confirmService.confirm({
      title: 'Close all windows',
      message: 'Are you sure you want to close all windows?',
      confirmText: 'Close All',
      cancelText: 'Cancel'
    });
    if (confirmResult.confirm) {
      const unclosedWindows = [];
      /**
       * Reverses the current window order so that we can reopen them
       * starting with the first window of the group
       */
      const reversedWindows = [...this._windows].reverse();
      for (const window of reversedWindows) {
        /**
         * We pass emitEvent: false to avoid having the subscriptions
         * from this.subscribeToRefEvents calling .splice on the windows
         * list while we are also modifying it here.
         */
        const isClosed = await window.close({
          emitEvent: false,
          saveState: true
        });
        /**
         * A window can prevent closing. In that case, we keep track of these
         * windows while we continue and close the rest.
         */
        !isClosed && unclosedWindows.push(window);
      }

      /**
       * We then remove all the windows on the window manager except those
       * that weren't closed, if there were any.
       */
      this._windows = unclosedWindows;
      if (this._windows.length) {
        this.matSnackBar.open(
          `${this._windows.length} window(s) can't be closed`,
          'Close',
          {
            duration: SNACKBAR_DURATION_ERROR
          }
        );
      }

      this.saveState();

      // Reset the selected window to a valid one (or none) after closing all
      this.setSelected(this.windows[0] || null);
    }
  }

  public async subscribeToStorageEvents() {
    fromEvent(window, 'storage')
      .pipe(filter((e: StorageEvent) => e.key === 'windowState'))
      .subscribe(async (e: StorageEvent) => {
        const newWindows: WindowPane[] = JSON.parse(e.newValue) || [];
        const newWindowIds: string[] = newWindows.map(window => window.id);
        const currentWindowIds = this._windows.map(window => window.id);

        /**
         * Open any windows that were created
         */
        const toOpen = newWindows.filter(
          window => !currentWindowIds.includes(window.id) && window.type
        );

        const recentlyClosedId =
          this.recentlyClosed[this.recentlyClosed.length - 1]?.window.id;

        for (const window of toOpen) {
          const { id, type, data } = window;

          /**
           * Skip if the window matches the ID of the recently closed window.
           * Identical IDs mean that the window was opened via the sync and not
           * through WindowService.create(). This guards against re-opening windows
           * that were programmatically closed due to an event/condition.
           */
          if (recentlyClosedId === id) {
            continue;
          }

          await this.create(
            {
              id,
              type,
              data
            },
            true,
            false
          );
        }

        /**
         * Close any windows that were closed
         */
        const toClose = this.windows.filter(
          window => !newWindowIds.includes(window.id)
        );

        for (const window of toClose) {
          await window.close({ saveState: false });
        }

        /**
         * Finally, sort windows to match the source windows list
         * Only sort if the new and existing ids arrays are not equal,
         * and that the finalized list of windows is > 1. No point in
         * sorting a 1-item array.
         */

        const afterOperationsWindowIds = this._windows.map(window => window.id);

        if (
          !isEqual(newWindowIds, afterOperationsWindowIds) &&
          this.windows.length > 1
        ) {
          this._windows.sort(
            (a, b) => newWindowIds.indexOf(a.id) - newWindowIds.indexOf(b.id)
          );
        }
      });
  }

  /**
   * Configures the pane host once the window outlet component is booted up
   *
   * @param vcr ViewContainerRef to set as the pane host for all future window panes
   */
  public configurePaneHost(vcr: ViewContainerRef) {
    this.paneHost = vcr;
    this.paneHost$.next(vcr);
    this.loadState();
    this.openWindowFromUrl();
  }

  /**
   * Check whether an identical window already exists by comparing type and data
   *
   * @param newPane Newly-instantiated window pane we should inspect for similar ones
   */
  private exists<R>(newPane: WindowPane<R>): WindowPane<R> | void {
    return this.windows.find(
      window =>
        window.type === newPane.type && isEqual(window.data, newPane.data)
    );
  }

  /**
   * Clears any saved window state
   */
  private clearState() {
    localStorage.removeItem(this.storageKey);
  }

  /**
   * Saves the current window state to localStorage
   */
  private saveState() {
    const toStore = this._windows.map(window =>
      pick(window, ['id', 'type', 'icon', 'data', 'state', 'title', 'subtitle'])
    );

    localStorage.setItem(this.storageKey, JSON.stringify(toStore));
  }

  public getConfigFromRoute(): WindowConfig<any> | void {
    const { segments } = this.getRouteSegments(
      this.router,
      WINDOW_ROUTER_OUTLET
    );
    if (segments.length) {
      const windowData = segments.shift();
      const { type, data } = windowData.parameters;
      const currentWindow: WindowConfig<any> = {
        type: type as WindowType,
        data
      };

      return currentWindow;
    }
  }

  /**
   * Creates a new window from a URL with type and data
   *
   */
  private openWindowFromUrl() {
    const windowConfig = this.getConfigFromRoute();

    if (windowConfig) {
      this.create(windowConfig);
    }
  }

  /**
   * Restores the saved window state and rehydrates with any windows
   */
  private loadState() {
    const state: WindowConfig<any>[] = JSON.parse(
      localStorage.getItem(this.storageKey) || '[]'
    );

    // re-create all the windows with the store configs
    for (const toOpen of state) {
      this.create(toOpen, false, false);
    }

    // set the opened/maximized window as selected, if there were any
    const openedWindow = this._windows.find(
      window => window.state === 'MAXIMIZED' || window.state === 'OPEN'
    );
    openedWindow && this.setSelected(openedWindow);
  }

  /**
   * Minimize the current window. Used when opening another to ensure only
   * one is open at a time
   *
   * @param excludeId Window to exclude from being minimized
   */
  minimizeAllWindows(excludeId?: string) {
    for (const window of this._windows) {
      if (
        (window.state === 'OPEN' || window.state === 'MAXIMIZED') &&
        window.id !== excludeId
      ) {
        window.minimize();
      }
    }
  }

  /**
   * Maximizes the current window. Used when opening another to ensure only
   * one is open at a time
   *
   * @param excludeId Window to exclude from being minimized
   */
  maximizeCurrentWindow(excludeId?: string) {
    if (this.selected?.id !== excludeId) {
      this.selected.maximize();
    }
  }

  /**
   * Toggles the shrink/grow on the current window.
   *
   * @param excludeId Window to exclude from being shrunk/grown
   */
  toggleShrinkCurrentWindow(excludeId?: string) {
    if (this.selected?.id !== excludeId) {
      switch (this.selected.state) {
        case 'MAXIMIZED':
          this.selected.open();
          break;
        case 'OPEN':
          this.selected.maximize();
      }
    }
  }

  /**
   * finds the current windows index.
   *
   * @param windowId Window ID to find the index of
   */
  findWindowIndex(windowId: string) {
    return this._windows.findIndex(window => window.id === windowId);
  }

  /**
   * finds the next windows index for when closing and then updating selected to the next window
   *
   * @param windowId Window ID to find the nextindex of
   */
  setNextSelectedWindow(windowId: string) {
    const index = this.findWindowIndex(windowId);

    if (index === this._windows.length - 1 && index > 0) {
      this.setSelected(this._windows[index - 1]);
    } else {
      this.setSelected(this._windows[index + 1]);
    }
  }

  /**
   * Closes the current window.
   *
   * @param excludeId Window to exclude from being closed
   */
  closeCurrentWindow() {
    this.selected?.close();
  }

  /**
   * Restores the most recently closed window by passing
   * it through the create function.
   *
   * @returns the restored window
   */
  restoreRecentlyClosed(): Promise<WindowPane<any>> {
    if (this.recentlyClosed.length === 0) {
      this.matSnackBar.open(
        'No more windows are available to be re-opened',
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );
      return;
    }
    const lastWindow = this.recentlyClosed.pop();
    const index = lastWindow.index;

    return this.create(
      {
        type: lastWindow.window.type,
        state: lastWindow.window.state,
        data: lastWindow.window.data
      },
      true,
      true,
      index
    );
  }

  /**
   * When a window is closed, we add it to the recently closed list
   * for when a user wants to restore it
   *
   */
  saveRecentlyClosed(window: WindowPane<any>): number {
    const index = this.findWindowIndex(window?.id);
    const recentWindow: {
      window: WindowPane<any>;
      index: number;
    } = {
      window,
      index
    };
    this.recentlyClosed.length === this.maxRecentWindows &&
      this.recentlyClosed.shift();
    return this.recentlyClosed.push(recentWindow);
  }

  /**
   * Toggles the direction of the active window and maximizes.
   *
   * @param isNext checks the direction to iterate through the windows based on the arrow key press
   */
  nextWindow(isNext: boolean) {
    let nextIndex = 0;

    const currentIndex = this.findWindowIndex(this.selected?.id);

    if (isNext) {
      nextIndex =
        currentIndex !== this._windows.length - 1 ? currentIndex + 1 : 0;
    } else {
      nextIndex =
        currentIndex !== 0 ? currentIndex - 1 : this._windows.length - 1;
    }
    this._windows[nextIndex]?.maximize();
  }

  /**
   * Listen for router navigations, parse the URL of the
   * primary router outlet, and minimize the current window
   * when it changes.
   *
   * Ensure we don't auto-minimize a window if a user is
   * directly linked to a window-producing url:
   *
   * e.g. directly visiting /tasks/:taskId should produce a
   * window and not minimize it
   */
  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const { path } = this.parsePathAndParams(this.router);

          return path;
        }),
        pairwise(),
        filter(([beforePath, afterPath]) => beforePath !== afterPath)
      )
      .subscribe(() => this.minimizeAllWindows());
  }

  /**
   * Listen for location popstate events (back button) and
   * minimize the last open window if there aren't any window-related
   * routing path segments left in the URL
   */
  private subscribeToBackButton() {
    this.location.onPopState(() => {
      const { path } = this.parsePathAndParams(
        this.router,
        WINDOW_ROUTER_OUTLET
      );

      if (!path?.length) {
        this.minimizeAllWindows();
      }
    });
  }

  /**
   * When a user logs out, destroy their window state to
   * prevent any windows being loaded on the login page
   */
  private subscribeToAuthState() {
    this.appService.isLoggedIn$
      .pipe(filter(isLoggedIn => !isLoggedIn))
      .subscribe(() => this.clearState());
  }

  /**
   * Subscribe to state change events for a given WindowRef.
   * Remove a window from the list of windows when it closes.
   *
   * @param windowRef WindowRef that has events to subscribe to
   */
  private subscribeToRefEvents(windowRef: WindowPane<any>) {
    windowRef.onStateChanged
      .pipe(
        skip(1),
        tap(() => this.saveState()),
        filter(state => state === 'OPEN' || state === 'MAXIMIZED')
      )
      .subscribe(() => {
        this.minimizeAllWindows(windowRef.id);
      });

    windowRef.onTitleChanged.pipe(skip(1)).subscribe(() => this.saveState());
    windowRef.onSubtitleChanged.pipe(skip(1)).subscribe(() => this.saveState());

    windowRef.onClose.subscribe(({ saveState }) => {
      this.setNextSelectedWindow(windowRef.id);
      this._windows.splice(this._windows.indexOf(windowRef), 1);
      saveState && this.saveState();
    });
  }

  /**
   * Appends a window pane into the window host
   *
   * @param content Component to prepare for insertion into the window pane
   * @param config Window Configuration
   * @param windowRef Window to append to the window host
   */
  private appendToHost<R>(
    content: Type<any>,
    config: Partial<WindowConfig<any>>,
    windowRef: WindowPane<R>
  ): ComponentRef<WindowPaneComponent> {
    const providers: StaticProvider[] = [
      { provide: WINDOW_CONTENT, useValue: content },
      { provide: WINDOW_DATA, useValue: windowRef.onDataChanged },
      { provide: WindowConfig, useValue: config },
      { provide: WindowPane, useValue: windowRef }
    ];
    const parentInjector = this.paneHost.injector;
    const injector = Injector.create({ parent: parentInjector, providers });
    const componentRef = this.paneHost.createComponent(WindowPaneComponent, {
      injector
    });

    componentRef.changeDetectorRef.detectChanges();

    return componentRef;
  }

  /**
   * Gets the new window's data from the outlet
   *
   * @param router A router instance.
   * @param outlet looking to parse the window outlet
   */
  private getRouteSegments = (router: Router, outlet: string) => {
    const pathname = `${window.location.pathname}${window.location.search}`;
    const parsed = router.parseUrl(pathname);
    return {
      segments: parsed.root.children?.[outlet]?.segments || [],
      parsed
    };
  };

  /**
   * Parse the URL and return the results for a given router outlet
   *
   * @param router A Router instance, injected via the constructor of wherever you are using this utility
   * @param outlet The outlet name you are looking to parse and return results for, defaults to 'primary'
   */
  parsePathAndParams = (
    router: Router,
    outlet: string = 'primary'
  ): {
    path: string;
    search: string;
    queryParams: Params;
  } => {
    const { segments, parsed } = this.getRouteSegments(router, outlet);
    const path = segments.map(p => p.path).join('/');

    return {
      path,
      search: window.location.search,
      queryParams: parsed.queryParams
    };
  };
}
