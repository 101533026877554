import { NgModule } from '@angular/core';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { FinderModule } from '../../shared/finder/finder.module';
import { MarkdownModule } from '../../shared/markdown/markdown.module';
import { SharedFormModule } from '../../shared/shared-form.module';
import { SharedModule } from '../../shared/shared.module';
import { TaskEditFormModule } from '../task-edit-form/task-edit-form.module';
import { TaskPanelsModule } from '../task-panels/task-panels.module';
import { ScheduledTasksListComponent } from './scheduled-tasks-list/scheduled-tasks-list.component';
import { ScheduledTaskEditDialogComponent } from './scheduled-task-edit-dialog/scheduled-task-edit-dialog.component';
import { ScheduledTasksViewComponent } from './scheduled-tasks-view/scheduled-tasks-view.component';

@NgModule({
  imports: [
    FinderModule,
    MarkdownModule,
    SharedFormModule,
    SharedModule,
    TaskEditFormModule,
    TaskPanelsModule,
    VirtualScrollerModule
  ],
  declarations: [
    ScheduledTaskEditDialogComponent,
    ScheduledTasksListComponent,
    ScheduledTasksViewComponent
  ],
  exports: [ScheduledTasksListComponent]
})
export class ScheduledTasksModule {}
