import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { UserService } from './users/user.service';

@Injectable({ providedIn: 'root' })
export class HomepageGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    firstValueFrom(this.userService.getHomepage()).then(response =>
      this.router.navigateByUrl(response)
    );
    return true;
  }
}
