<div class="header-wrapper flex flex-row items-center justify-between">
  <h3 class="m-0">Platform Spend</h3>
  <button mat-button color="primary" [matMenuTriggerFor]="accounts_menu">
    + Add Platform Spend
  </button>
</div>

<div class="spend-list">
  <ng-container
    *ngFor="
      let control of fundingSpend.controls.platformSpend.controls | keyvalue
    "
  >
    <div
      class="spend-item flex flex-row items-center justify-center"
      *ngFor="let subControl of control.value.controls | keyvalue"
    >
      <div class="icon-container mr-2">
        <ease-account-icon
          class="mat-icon mr-2"
          [accountType]="subControl.value.controls.accountType.value"
          [size]="24"
        ></ease-account-icon>
      </div>
      <div class="metadata flex-1">
        <div>{{ subControl.value.controls.accountName.value }}</div>
        <div class="flex flex-row items-center justify-start">
          <div class="mr-2 text-gray-400">
            ({{ subControl.value.controls.id.value }})
          </div>
          <div>
            <button
              class="remove-btn"
              (click)="
                removeSpend(
                  subControl.value.controls.accountType.value,
                  subControl.value.controls.id.value,
                  'platform'
                )
              "
            >
              <mat-icon class="mat-icon-14 mr-2">close</mat-icon>
              Remove
            </button>
          </div>
        </div>
      </div>
      <div class="amount-form" [formGroup]="subControl.value">
        <mat-form-field>
          <input
            matInput
            placeholder="Amount"
            formControlName="amount"
            type="text"
            prefix="$"
            mask="separator.2"
            thousandSeparator=","
            [dropSpecialCharacters]="false"
            autofocus
          />
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  <div
    class="no-spend"
    *ngIf="!object.keys(fundingSpend.controls.platformSpend.controls).length"
  >
    No accounts added
  </div>
</div>

<div class="header-wrapper flex flex-row items-center justify-between">
  <h3 class="m-0">Management Fees</h3>
  <button mat-button color="primary" [matMenuTriggerFor]="management_menu">
    + Add Management Fees
  </button>
</div>

<div class="spend-list">
  <ng-container
    *ngFor="
      let control of fundingSpend.controls.managementSpend.controls | keyvalue
    "
  >
    <div
      class="spend-item flex flex-row items-center justify-center"
      *ngFor="let subControl of control.value.controls | keyvalue"
    >
      <div class="icon-container mr-2">
        <ease-account-icon
          class="mat-icon mr-2"
          [accountType]="
            fundingService.productCodeToAccountTypeMapping[
              subControl.value.controls.accountType.value
            ] || 'searchKings'
          "
          [size]="24"
        ></ease-account-icon>
      </div>
      <div class="metadata flex-1">
        <div>{{ subControl.value.controls.accountName.value }}</div>
        <div class="flex flex-row items-center justify-start">
          <div class="mr-2 text-gray-400">
            ({{ subControl.value.controls.id.value }})
          </div>
          <div>
            <button
              class="remove-btn"
              (click)="
                removeSpend(
                  subControl.value.controls.accountType.value,
                  subControl.value.controls.id.value,
                  'management'
                )
              "
            >
              <mat-icon class="mat-icon-14 mr-2">close</mat-icon>
              Remove
            </button>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4" [formGroup]="subControl.value">
        <mat-form-field class="top-[1px]">
          <input
            matInput
            placeholder="Amount"
            formControlName="amount"
            type="text"
            prefix="$"
            mask="separator.2"
            thousandSeparator=","
            [dropSpecialCharacters]="false"
            autofocus
          />
        </mat-form-field>
        <mat-form-field
          *ngIf="subControl.value.controls.frequencyId; else oneTimePurchase"
        >
          <mat-select placeholder="Frequency" formControlName="frequencyId">
            <mat-option
              *ngFor="let frequency of planFrequencies$ | async"
              [value]="frequency.id"
            >
              {{ frequency | planFrequency }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  <div
    class="no-spend"
    *ngIf="!object.keys(fundingSpend.controls.managementSpend.controls).length"
  >
    No management fees added
  </div>
</div>

<div class="header-wrapper flex flex-row items-center justify-between"></div>

<mat-form-field class="example-full-width">
  <mat-label>Note to customer</mat-label>
  <textarea matInput [formControl]="fundingDetailsNotes"></textarea>
  <mat-hint align="start"
    >This message will be sent with funding email</mat-hint
  >
</mat-form-field>

<mat-menu #accounts_menu="matMenu">
  <ng-container *ngFor="let mainItem of accountsSpendList$ | async">
    <button mat-menu-item [matMenuTriggerFor]="sub_menu">
      <ease-account-icon
        class="mat-icon mr-2"
        [accountType]="mainItem.accountType"
        [size]="24"
      ></ease-account-icon>
      {{ mainItem.displayValue }}
    </button>
    <mat-menu #sub_menu="matMenu">
      <button
        class="sub-item-extra"
        *ngFor="let subItem of mainItem.nestedItems"
        mat-menu-item
        (click)="addSpend(subItem, 'platform')"
        [disabled]="
          !!fundingSpend.controls.platformSpend?.controls?.[subItem.accountType]?.controls?.[subItem.value]
        "
      >
        <ease-account-icon
          class="mat-icon mr-2"
          [accountType]="mainItem.accountType"
          [size]="24"
        ></ease-account-icon>
        <span class="mr-2 w-0 flex-1 truncate">{{ subItem.displayValue }}</span>
      </button>
    </mat-menu>
  </ng-container>
</mat-menu>

<mat-menu #management_menu="matMenu">
  <ng-container *ngFor="let mainItem of managementList$ | async">
    <button mat-menu-item (click)="addSpend(mainItem, 'management')">
      <ease-account-icon
        class="mat-icon mr-2"
        [accountType]="
          fundingService.productCodeToAccountTypeMapping[
            mainItem.accountType
          ] || 'searchKings'
        "
        [size]="24"
      ></ease-account-icon>
      {{ mainItem.displayValue }}
    </button>
  </ng-container>
</mat-menu>

<ng-template #oneTimePurchase>
  <div
    class="flex items-center justify-center self-center rounded-lg border border-solid border-gray-300 bg-gray-200 p-1"
  >
    <mat-icon class="mat-icon-18 mr-1">info</mat-icon>
    <span class="text-xs">One-time purchase</span>
  </div>
</ng-template>
