import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'ease-metric-simple',
  templateUrl: './metric-simple.component.html',
  styleUrls: ['./metric-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricSimpleComponent implements OnInit {
  @Input() matIcon: string;
  @Input() label: string;
  @Input() value: number | string;
  @Input() tooltip: string;
  @Input() differenceNumeric: string | number;
  @Input() differencePercentage: number;
  @Input() notes: string;

  constructor() {}

  ngOnInit() {}

  isNil(val: any): boolean {
    return val === null || val === undefined;
  }
}
