<ease-sheet
  class="popover popover-metric shadow-md"
  [ngClass]="competitiveMetric.ragStatusColor"
>
  <div class="header metric-background">
    <span class="description">
      {{ competitiveMetric.ragStatusDescription }}
    </span>
  </div>
  <div class="details">
    <div class="row flex flex-row">
      <div class="column detail flex-1">
        <ease-metric-simple
          [label]="'Account CPL'"
          [tooltip]="
            'The account\'s accrued spend divided by the number of leads for the period so far.'
          "
          [value]="competitiveMetric.cpl | currency: 'USD':'symbol':'1.0-2'"
          [differenceNumeric]="
            competitiveMetric.cpl - competitiveMetric.medianCPL
              | currency: 'USD':'symbol':'1.0-2'
          "
          [differencePercentage]="competitiveMetric.percentageSwingFromMedian"
        ></ease-metric-simple>
      </div>
      <div class="column detail flex-1">
        <ease-metric-simple
          [label]="'Median CPL'"
          [tooltip]="
            'The median of all the cost per leads from all the accounts on the same vertical and DMA.'
          "
          [value]="
            competitiveMetric.medianCPL | currency: 'USD':'symbol':'1.0-2'
          "
        ></ease-metric-simple>
      </div>
    </div>
    <div class="row">
      <div
        class="not-enough-data"
        *ngIf="!competitiveMetric?.metadata?.rankingList"
      >
        <mat-icon class="mat-icon-24"> error_outline </mat-icon>
        <div class="message">Not enough data for vertical ranking</div>
      </div>
      <ease-metric-list
        *ngIf="competitiveMetric?.metadata?.rankingList"
        [list]="[
          {
            label: 'Lowest CPL',
            value: lowestVerticalCPL | currency: 'USD':'symbol':'1.0-2'
          },
          {
            label: 'Highest CPL',
            value: highestVerticalCPL | currency: 'USD':'symbol':'1.0-2'
          },
          {
            label: 'Your Ranking',
            value:
              accountVerticalRank + ' out of ' + competitiveMetric.numAccounts
          }
        ]"
      ></ease-metric-list>
      <ease-vertical-ranking
        *ngIf="competitiveMetric?.metadata?.rankingList"
        [colorSelected]="competitiveMetric.ragStatusColor"
        [verticalRankings]="competitiveMetric?.metadata?.rankingList"
      ></ease-vertical-ranking>
    </div>
  </div>
</ease-sheet>
