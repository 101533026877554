import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import { AdminPermissionsService } from 'src/app/admin/admin-roles/admin-permissions.service';
import { TaskTemplatesService } from '../../../admin/task-templates/task-templates.service';
import { TaskModel } from '../../../tasks/task.model';
import { TaskService } from '../../../tasks/task.service';
import { ConfirmService } from '../../confirm/confirm.service';
import { GridTargetingService } from '../../grid-toolbar/grid-targeting/grid-targeting.service';
import { ListDisplayItem } from '../../shared.interface';
import { WINDOW_DATA } from '../window-options';
import { TaskCreateWindowData } from '../window-pane/window-pane.interface';

type Quantity = 'single' | 'bulk';

@UntilDestroy()
@Component({
  selector: 'ease-window-pane-create-task',
  templateUrl: './window-pane-create-task.component.html',
  styleUrls: ['./window-pane-create-task.component.scss']
})
export class WindowPaneCreateTaskComponent implements OnInit, OnDestroy {
  @Output()
  create: EventEmitter<string> = new EventEmitter<string>();
  public quantity: FormControl<Quantity> = new FormControl();
  public currentQuantity: Quantity;
  public taskTemplates$: Observable<ListDisplayItem[]> = of([]);
  public templateControl: FormControl<string> = new FormControl();
  public queued = false;
  public parentTask$: Observable<TaskModel>;
  public enableBulkCreation: boolean = true;

  constructor(
    private confirmService: ConfirmService,
    private gridTargetingService: GridTargetingService,
    public permissionService: AdminPermissionsService,
    private taskTemplatesService: TaskTemplatesService,
    private taskService: TaskService,
    @Inject(WINDOW_DATA)
    public data$: Observable<TaskCreateWindowData>
  ) {}

  ngOnInit() {
    this.setQuantity('single');

    this.taskTemplates$ = combineLatest([
      this.taskTemplatesService.getAll(),
      this.data$
    ]).pipe(
      take(1),
      map(([templates, data]) =>
        templates.reduce((acc, template) => {
          // Check if we are creating a subtask
          if (data?.parent) {
            // Filter out scheduled and templates that has subtasks
            if (
              !template.fields?.scheduled &&
              !template.fields?.childrenTemplates
            ) {
              acc.push({
                value: template.$key,
                viewValue: template.name,
                viewSubvalue: template.description
              });
            }
          } else {
            // push all templates
            acc.push({
              value: template.$key,
              viewValue: template.name,
              viewSubvalue: template.description
            });
          }
          return acc;
        }, [])
      )
    );

    if (
      this.gridTargetingService.getData() &&
      !this.gridTargetingService.getActiveWindow()
    ) {
      this.setQuantity('bulk');
    }

    this.quantity.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((quantity: Quantity) => {
        if (this.currentQuantity !== quantity) {
          if (this.queued) {
            this.confirmService
              .confirm({
                message: `Are you sure you want to switch? All current progress will be lost.`,
                confirmText: `Switch`,
                cancelText: 'Cancel'
              })
              .then(confirmResult => {
                if (confirmResult.confirm) {
                  this.setQuantity(quantity);
                } else {
                  this.setQuantity(this.currentQuantity);
                }
              });
          } else {
            this.setQuantity(quantity);
          }
        }
      });

    this.parentTask$ = this.data$.pipe(
      switchMap(data =>
        data?.parent ? this.taskService.get(data?.parent) : of(null)
      ),
      //We disable bulk creation if the user is about to create a subtask.
      tap(parentTask => (this.enableBulkCreation = !parentTask))
    );
  }

  setQuantity(quantity: Quantity) {
    this.quantity.setValue(quantity);
    this.currentQuantity = this.quantity.value;
  }

  ngOnDestroy() {}
}
