import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CUSTOMER_ROLES } from 'src/app/customers/customer-constants';
import { ListDisplayItem } from 'src/app/shared/shared.interface';
import { AssignedRole, AssignedRoles } from '../../task.model';

@UntilDestroy()
@Component({
  selector: 'ease-task-role-selector',
  templateUrl: './task-role-selector.component.html',
  styleUrls: ['./task-role-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TaskRoleSelectorComponent
    }
  ]
})
export class TaskRoleSelectorComponent implements OnInit {
  @Input() disabled = false;
  private touched = false;
  public assignedRole: AssignedRoles;
  public selectedRoles: FormControl<AssignedRole[]>;
  public allRoles: ListDisplayItem[];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.selectedRoles = this.formBuilder.control([]);
    this.selectedRoles.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(selectedRoles => this.onSelectionChange(selectedRoles));

    this.allRoles = CUSTOMER_ROLES;
  }

  onSelectionChange(selectedRoles: AssignedRole[]) {
    if (selectedRoles) {
      const assignedRole: AssignedRoles = {};
      selectedRoles.forEach(role => {
        assignedRole[role] = true;
      });
      this.onChange({ ...assignedRole });
    }
  }

  /**
   * ControlValueAccessor
   */
  writeValue(assignedRole: AssignedRoles) {
    if (assignedRole) {
      this.assignedRole = assignedRole;
      this.selectedRoles.setValue(Object.keys(assignedRole) as AssignedRole[]);
    }
  }

  onChange = (value: AssignedRoles) => {};

  onTouched = () => {};

  registerOnChange(onChangeFn) {
    this.onChange = onChangeFn;
  }

  registerOnTouched(onTouchedFn) {
    this.onTouched = onTouchedFn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
