import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { AgRendererComponent } from 'ag-grid-angular';
import { Account, CPLMetric } from '../../account-dashboard.interface';

@Component({
  selector: 'ease-cpl-metric-cell',
  templateUrl: './cpl-metric-cell.component.html',
  styleUrls: ['./cpl-metric-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CplMetricCellComponent implements AgRendererComponent {
  public costPerLeadMetric: CPLMetric;
  public account: Account;
  public isGroup: boolean;

  private params: ICellRendererParams;
  public targetCplNumber: number;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.account = params.data;
    this.isGroup = params.node.group;

    if (this.account) {
      this.costPerLeadMetric = this.account.cplMetric;
      this.targetCplNumber = this.account.cplMetric
        ? Number(this.costPerLeadMetric.cplTarget)
        : null;
    }
  }

  refresh(params: ICellRendererParams) {
    return true;
  }

  cellChanged(newTargetCpl: number) {
    this.account.targetCpl = newTargetCpl;
    this.params.api.refreshCells({
      rowNodes: [this.params.node],
      force: true
    });
  }
}
