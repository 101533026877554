import { Component, Input, OnInit } from '@angular/core';

import { TypedFormGroup } from 'src/app/shared/reactive-forms';

@Component({
  selector: 'ease-email-variables',
  templateUrl: './email-variables.component.html',
  styleUrls: ['./email-variables.component.scss']
})
export class EmailVariablesComponent implements OnInit {
  @Input() controls: TypedFormGroup<{
    [variableName: string]: string;
  }>;
  @Input() hiddenVariables: string[] = [];

  constructor() {}

  ngOnInit() {}

  isHidden(fieldKey: string) {
    return this.hiddenVariables && this.hiddenVariables.includes(fieldKey);
  }
}
