import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { EntityStatusSelectorModule } from '../entity-status-selector/entity-status-selector.module';
import { SharedModule } from '../shared.module';
import { RichTooltipModule } from '../rich-tooltip/rich-tooltip.module';
import { GridCellRendererComponent } from './grid-cell-renderer/grid-cell-renderer.component';
import { HeaderCustomInfoCellComponent } from './header-custom-info-cell/header-custom-info-cell.component';
import { NumericEditorComponent } from './cell-editors/numeric-editor/numeric-editor.component';
import { CellTooltipComponent } from './cell-tooltip/cell-tooltip.component';

@NgModule({
  imports: [
    EntityStatusSelectorModule,
    SharedModule,
    FormsModule,
    RichTooltipModule
  ],
  declarations: [
    GridCellRendererComponent,
    HeaderCustomInfoCellComponent,
    NumericEditorComponent,
    CellTooltipComponent
  ],
  exports: [
    GridCellRendererComponent,
    HeaderCustomInfoCellComponent,
    CellTooltipComponent
  ]
})
export class GridCellsModule {}
