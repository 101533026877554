import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColDef, GridOptions } from 'ag-grid-community';

import { GridEntity } from '../../grid-toolbar/grid-targeting/grid-targeting.interface';
import {
  Separator,
  TargetMember,
  UserInputTarget
} from '../bulk-targeting.interface';
import { BulkTargetingService } from '../bulk-targeting.service';

@UntilDestroy()
@Component({
  selector: 'ease-bulk-targeting-method-paste',
  templateUrl: './bulk-targeting-method-paste.component.html',
  styleUrls: ['./bulk-targeting-method-paste.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkTargetingMethodPasteComponent implements OnInit {
  @Input() entity: GridEntity = 'customer';
  @Output() valueChanged: EventEmitter<TargetMember[]> = new EventEmitter<
    TargetMember[]
  >();
  public pasteArea: FormControl<string> = new FormControl();
  public separator: FormControl<Separator> = new FormControl('\t');
  private data: string;

  public rowData: UserInputTarget[] = [];
  public gridOptions: GridOptions;
  public columnDefs: ColDef[];
  public overlayLoadingTemplate: string;

  public hasInvalidEntity = false;
  public isPristine = true;

  constructor(
    private bulkTargetingService: BulkTargetingService,
    public cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.overlayLoadingTemplate =
      this.bulkTargetingService.getOverlayLoadingTemplate(this.entity);

    this.separator.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.data && this.initTable());

    this.gridOptions = {
      onRowDataUpdated: async params => {
        this.hasInvalidEntity = await this.bulkTargetingService.getEntities(
          this.entity,
          params
        );
        const members = this.bulkTargetingService.getTargetDataFromGrid(
          this.rowData
        );
        this.valueChanged.emit(members);
      },
      rowClassRules: {
        warning: params => !params.data.name
      },
      defaultColDef: {
        cellClassRules: {
          warning: params => !params.value
        }
      }
    };
  }

  paste(event: ClipboardEvent) {
    this.data = event.clipboardData.getData('text');

    if (this.data) {
      this.isPristine = false;
      this.initTable();
    }
  }

  initTable() {
    const { headers, rowData } = this.bulkTargetingService.parse({
      data: this.data,
      entity: this.entity,
      separator: this.separator.value
    });

    this.columnDefs = this.bulkTargetingService.getColumnDefinitions(
      this.entity,
      headers
    );
    this.rowData = rowData;
  }
}
