import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase from 'firebase/compat/app';
import { firstValueFrom, Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { CUSTOMER_ROLES } from 'src/app/customers/customer-constants';
import { CustomerContactModel } from 'src/app/customers/customer-contacts/customer-contact.model';
import { CustomerContactsService } from 'src/app/customers/customer-contacts/customer-contacts.service';
import { CustomerRolesService } from 'src/app/customers/customer-roles.service';
import {
  EmailTemplateSettings,
  EmailTemplateSettingsObject,
  SubscriberList,
  Recipient,
  RecipientField,
  RecipientSettings
} from 'src/app/shared/emails/email.interface';
import {
  EaseUnwrappedSnapshot,
  FirebaseDbService
} from 'src/app/shared/firebase-db.service';
import { EMAIL_TEMPLATES_SETTINGS } from 'src/app/shared/firebase-paths';
import { ChipFilterFn } from 'src/app/shared/material-chips-input/material-chips-input.interface';
import { EMAIL_REGEXP } from 'src/app/shared/shared-validators';
import {
  ListDisplayGroup,
  ListDisplayItem
} from 'src/app/shared/shared.interface';
import { SkSyncHttpService } from 'src/app/shared/sksync-http.service';
import { arrayToFirebaseMap } from 'src/app/shared/utils/functions';
import { SNACKBAR_DURATION_ERROR } from 'src/app/shared/constants';
import { UserModel } from 'src/app/users/user.model';
import { UserService } from 'src/app/users/user.service';

@Injectable({ providedIn: 'root' })
export class EmailTemplateSettingsService {
  private settingsRef: firebase.database.Reference;
  public recipientOptions$: Observable<ListDisplayGroup[]>;
  public recipientFilters$: Observable<ChipFilterFn[]>;
  private contactTypes: ListDisplayItem[];
  private DOT_REPLACEMENT = ',';
  private allowedRoles: ListDisplayItem[];

  constructor(
    private angularFire: FirebaseDbService,
    private customerContactsService: CustomerContactsService,
    private customerRolesService: CustomerRolesService,
    private matSnackBar: MatSnackBar,
    private sksyncHttp: SkSyncHttpService,
    private userService: UserService
  ) {
    const region = this.userService.currentUser.region || 'canada';
    this.settingsRef = this.angularFire.database
      .ref()
      .child(`/${EMAIL_TEMPLATES_SETTINGS}/${region}`);

    this.allowedRoles = CUSTOMER_ROLES.filter(
      role => role.value === 'relationshipManager'
    );
    /**
     * Get all valid recipients options by combining:
     * - customer contact types
     * - SearchKings customer and account roles
     * - valid user emails
     */
    this.recipientOptions$ = this.userService.emails.pipe(
      map(userEmails => [
        {
          name: 'Contact Types',
          items: this.getContactTypes()
        },
        {
          name: 'Customer Roles',
          items: this.allowedRoles
        },
        {
          name: 'Users',
          items: userEmails
        }
      ]),
      shareReplay(1)
    );

    this.recipientFilters$ = this.recipientOptions$.pipe(
      map(recipientOptions => [
        (item: ListDisplayItem) =>
          EMAIL_REGEXP.test(`${item.value}`) ||
          recipientOptions.some(recipientGroup =>
            recipientGroup.items.some(
              recipient => recipient.value === item.value
            )
          )
      ]),
      shareReplay(1)
    );
  }

  getContactTypes(): ListDisplayItem[] {
    if (!this.contactTypes) {
      const type = this.customerContactsService.types;
      this.contactTypes = Object.entries(type).map(([key, value]) => ({
        value: key,
        viewValue: value.label
      }));
    }
    return this.contactTypes;
  }

  recipientFromFirebase(recipients: Record<string, boolean>): string[] {
    return recipients
      ? Object.keys(recipients).map(recipient =>
          recipient.replace(new RegExp(this.DOT_REPLACEMENT, 'g'), '.')
        )
      : [];
  }

  recipientToFirebase(recipients: string[]): Record<string, boolean> {
    const firebaseFriendlyEmails = recipients.map(recipient =>
      EMAIL_REGEXP.test(recipient)
        ? recipient.replace(/\./g, this.DOT_REPLACEMENT)
        : recipient
    );
    return arrayToFirebaseMap(firebaseFriendlyEmails);
  }

  getAll(): Observable<Record<string, EmailTemplateSettingsObject>> {
    return this.angularFire.getObject(this.settingsRef);
  }

  get(templateId: string): Observable<EmailTemplateSettings> {
    return this.angularFire
      .getObject(this.settingsRef.child(`/${templateId}`))
      .pipe(
        map((settings: EaseUnwrappedSnapshot<EmailTemplateSettingsObject>) => ({
          description: settings.description || '',
          To: this.recipientFromFirebase(settings.To),
          applyFirstAndCcRemaining: settings.applyFirstAndCcRemaining || false,
          CC: this.recipientFromFirebase(settings.CC),
          BCC: this.recipientFromFirebase(settings.BCC),
          listId: settings.listId || ''
        }))
      );
  }

  getLists(): Observable<ListDisplayItem[]> {
    return this.sksyncHttp.get('/lists/').pipe(
      catchError(error => {
        this.matSnackBar.open(
          'There was an error getting the available email templates',
          'Close',
          {
            duration: SNACKBAR_DURATION_ERROR
          }
        );
        throw error;
      }),
      map((lists: SubscriberList[]) =>
        lists.map(list => ({
          value: list.ListID,
          viewValue: list.Name
        }))
      )
    );
  }

  async getUserForRole(customerId: string, role: string): Promise<UserModel> {
    const customerRoles = customerId
      ? await firstValueFrom(
          this.customerRolesService.getRolesAsObject(customerId)
        )
      : {};
    const userId = customerRoles[role];
    const user = userId
      ? await firstValueFrom(this.userService.get(userId))
      : ({} as UserModel);

    return user;
  }

  async getEmails(params: {
    recipients: string[];
    isApplyFirstEnabled?: boolean;
    ccRemaining?: Recipient[];
    customerContacts: CustomerContactModel[];
    userRole: Record<string, string>;
  }): Promise<Recipient[]> {
    const {
      recipients,
      isApplyFirstEnabled,
      ccRemaining,
      customerContacts,
      userRole
    } = params;

    // List of recipients and a set to protect against duplication
    const emails: Recipient[] = [];
    const addedEmails: Set<string> = new Set();

    /**
     * Small inline utility to protect against duplicate recipients
     *
     * @param toAdd Recipient to attempt to add
     */
    const addRecipient = (toAdd: Recipient) => {
      if (toAdd?.email && !addedEmails.has(toAdd.email)) {
        emails.push(toAdd);
        addedEmails.add(toAdd.email);
      }
    };

    for (const recipientType of recipients) {
      const contactType = this.getContactTypes().find(
        type => type.value === recipientType
      )?.value as string;

      switch (true) {
        case !!contactType:
          const contacts = customerContacts
            .filter(contact => contact.type[contactType] && contact?.email)
            .map(contact => ({
              email: contact.email,
              contactId: contact.$key
            }));

          /**
           * If no contacts match the types specified, skip
           * this possible contact type since there are none
           */
          if (!contacts?.length) {
            continue;
          }

          if (isApplyFirstEnabled) {
            const [main, ...rest] = contacts;
            addRecipient(main);
            ccRemaining.push(...rest);
          } else {
            contacts.forEach(contact => addRecipient(contact));
          }
          break;
        case !!userRole[recipientType]:
          addRecipient({
            email: userRole[recipientType]
          });
          break;
        default:
          addRecipient({ email: recipientType });
      }
    }

    return emails;
  }

  /**
   * Get's all the emails from customer contact types and user roles from
   * a given customer ID.
   *
   * @param customerId
   * @param recipientSettings
   * @returns a record of each field (To, CC, BCC) and the recipients emails (and contact ID for customer contacts)
   */
  async getRecipients(
    customerId: string,
    recipientSettings: RecipientSettings
  ): Promise<Record<RecipientField, Recipient[]>> {
    const customerContacts = await firstValueFrom(
      this.customerContactsService.get(customerId)
    );

    const userRole: Record<string, string> = {};
    for (const role of this.allowedRoles) {
      userRole[role.value] = (
        await this.getUserForRole(customerId, `${role.value}`)
      )?.email;
    }

    const defaultParams = { customerContacts, userRole };

    const ccRemaining: Recipient[] = [];
    const To = await this.getEmails({
      recipients: recipientSettings.To,
      isApplyFirstEnabled: recipientSettings.applyFirstAndCcRemaining,
      ccRemaining,
      ...defaultParams
    });

    const CC = await this.getEmails({
      recipients: recipientSettings.CC,
      ...defaultParams
    });
    CC.push(...ccRemaining);

    const BCC = await this.getEmails({
      recipients: recipientSettings.BCC,
      ...defaultParams
    });

    return { To, CC, BCC };
  }

  update(templateId: string, settings: EmailTemplateSettings): Promise<any> {
    const { description, applyFirstAndCcRemaining, listId } = settings;
    const payload: EmailTemplateSettingsObject = {
      description,
      To: this.recipientToFirebase(settings.To),
      applyFirstAndCcRemaining,
      CC: this.recipientToFirebase(settings.CC),
      BCC: this.recipientToFirebase(settings.BCC),
      listId
    };
    return this.settingsRef.child(`/${templateId}`).set(payload);
  }
}
