import { NgModule } from '@angular/core';

import { AccountIconModule } from '../../shared/account-icon/account-icon.module';
import { CustomerCommunicationModule } from '../customer-communication/customer-communication.module';
import { FeedItemsModule } from '../../shared/feed-items/feed-items.module';
import { MarkdownModule } from '../../shared/markdown/markdown.module';
import { ScrollModule } from '../../shared/scroll/scroll.module';
import { SharedFormModule } from '../../shared/shared-form.module';
import { SharedModule } from '../../shared/shared.module';
import { CustomerFeedRouteComponent } from './customer-feed-route/customer-feed-route.component';
import { CustomerFeedComponent } from './customer-feed/customer-feed.component';

@NgModule({
  imports: [
    AccountIconModule,
    CustomerCommunicationModule,
    FeedItemsModule,
    MarkdownModule,
    ScrollModule,
    SharedFormModule,
    SharedModule
  ],
  declarations: [CustomerFeedComponent, CustomerFeedRouteComponent],
  exports: [CustomerFeedComponent, CustomerFeedRouteComponent]
})
export class CustomerFeedModule {}
