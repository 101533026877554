import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';

@Component({
  selector: 'ease-task-priority-popover',
  templateUrl: './task-priority-popover.component.html',
  styleUrls: ['./task-priority-popover.component.scss']
})
export class TaskPriorityPopoverComponent implements OnInit {
  @Input()
  priority: number;
  @Output()
  selected: EventEmitter<number> = new EventEmitter<number>();

  constructor(@Optional() private parentPopover: SatPopover) {}

  ngOnInit() {}

  setPriority(priority: number): void {
    this.selected.emit(priority);
    this.parentPopover && this.parentPopover.close();
  }
}
