<div
  class="absolute z-50 flex h-full w-full flex-row items-center justify-center bg-white"
  *ngIf="isWorking"
>
  <div
    class="mr-5 flex max-w-[40%] flex-full flex-col items-center justify-center"
  >
    <ng-container
      *ngIf="progressStatus$ | async as progressStatus; else submitting"
    >
      <div class="job-status mb-5 whitespace-nowrap italic text-gray-500">
        {{ progressStatus.messageStatus }}
      </div>
      <mat-progress-bar
        class="mr-1"
        [mode]="progressStatus.progressType"
        [value]="progressStatus.overallPercentage"
      >
      </mat-progress-bar>
    </ng-container>
  </div>
</div>

<div
  class="flex-1 overflow-y-auto overflow-x-hidden"
  [formGroup]="accountBuilderForm"
>
  <div class="header-banner">
    <div class="container-lg flex flex-row items-center justify-start">
      <div class="text-container">
        <h1 class="my-0">Account Builder</h1>
        <p class="my-0">
          Fill out the form below to populate
          <span class="italic"
            ><ease-account-icon
              class="mr-1"
              accountType="adwords"
              [size]="20"
            ></ease-account-icon>
            {{ windowPane.data.accountName }}</span
          >
          with a selected template
        </p>
      </div>
    </div>
  </div>

  <div class="stepper-container container-lg mt-5">
    <mat-stepper orientation="vertical" #stepper linear>
      <mat-step
        [stepControl]="accountBuilderForm?.controls?.templateSelected"
        class="bg-red-500"
      >
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Account Template',
                closedStateSublabel: templateSelected | titlecase,
                closedStateStatement: templateSelected
              }
            }"
          ></ng-container>
        </ng-template>

        <div class="step-description">
          Choose a template to apply to the account
        </div>

        <div class="step-content">
          <mat-form-field appearance="standard">
            <mat-label>Template</mat-label>
            <mat-select formControlName="templateSelected">
              <mat-option
                *ngFor="let template of templateOptions"
                [value]="template.value"
              >
                {{ template.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button
            mat-raised-button
            matStepperNext
            color="primary"
            [disabled]="accountBuilderForm.controls.templateSelected.invalid"
          >
            Next
          </button>
        </div>
      </mat-step>

      <mat-step
        *ngIf="accountBuilderService.stepSections[templateSelected]?.verticals"
        [stepControl]="accountBuilderForm.controls.verticalIds"
      >
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Verticals',
                closedStateSublabel: verticalsChoosen,
                isSelected: stepper.selectedIndex === 1,
                closedStateStatement: verticalsChoosen
              }
            }"
          ></ng-container>
        </ng-template>

        <div class="step-description">
          Choose up to 3 verticals for the search and display campaigns
        </div>

        <div class="step-content">
          <div class="mb-2">
            <ng-container
              *ngIf="verticals$ | async as verticals; else loadingVerticals"
            >
              <ease-material-chips-input
                [control]="verticalIdsControl"
                [items]="verticals"
                placeholder="Verticals"
                [showNextSuggestions]="verticalIdsControl.value?.length < 2"
              >
              </ease-material-chips-input>
              <mat-error
                easeFormErrors
                *ngIf="verticalIdsControl.hasError"
                [control]="verticalIdsControl"
                class="-mt-4"
              ></mat-error>
            </ng-container>

            <ng-template #loadingVerticals>
              <div class="flex flex-row items-center justify-start space-x-2">
                <mat-spinner [diameter]="20"></mat-spinner>
                <p class="italic text-gray-600">
                  Obtaining verticals from template, please wait ...
                </p>
              </div>
            </ng-template>
          </div>

          <button
            mat-raised-button
            matStepperNext
            color="primary"
            [disabled]="accountBuilderForm.controls.verticalIds.invalid"
            (click)="setVerticals()"
          >
            Next
          </button>
        </div>
      </mat-step>

      <mat-step
        *ngIf="accountBuilderService.stepSections[templateSelected]?.budget"
        [stepControl]="accountBuilderForm.controls.budget"
      >
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Budget Allocation',
                closedStateSublabel: accountBuilderForm.controls.budget.value
                  ? '$' + accountBuilderForm.controls.budget.value
                  : '',
                isSelected: stepper.selectedIndex === 2,
                closedStateStatement: accountBuilderForm.controls.budget.value
              }
            }"
          ></ng-container>
        </ng-template>
        <div class="step-description">
          Specify the total budget for all campaigns
        </div>

        <mat-form-field class="mr-2">
          <input
            matInput
            placeholder="Total Budget"
            formControlName="budget"
            type="text"
            prefix="$"
            mask="separator.2"
            thousandSeparator=","
            [dropSpecialCharacters]="false"
            formControlName="budget"
            autofocus
          />
          <mat-hint
            >Min. ${{
              accountBuilderService.budgetRanges[
                accountBuilderForm.controls.templateSelected.value
              ].min
            }}
            - Max. ${{
              accountBuilderService.budgetRanges[
                accountBuilderForm.controls.templateSelected.value
              ].max
            }}</mat-hint
          >
          <mat-error
            easeFormErrors
            [control]="accountBuilderForm.controls.budget"
          ></mat-error>
        </mat-form-field>
        <button
          mat-raised-button
          matStepperNext
          color="primary"
          [disabled]="accountBuilderForm.controls.budget.invalid"
        >
          Next
        </button>
      </mat-step>

      <mat-step
        *ngIf="
          accountBuilderService.stepSections[templateSelected]?.geoTargeting
        "
        [stepControl]="accountBuilderForm.controls.postalcode"
      >
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Geotargeting',
                closedStateSublabel: accountBuilderForm.controls.postalcode
                  .value
                  ? accountBuilderForm.controls.radius.value +
                    ' miles around ' +
                    accountBuilderForm.controls.postalcode.value
                  : '',
                isSelected: stepper.selectedIndex === 3,
                closedStateStatement:
                  accountBuilderForm.controls.postalcode.value
              }
            }"
          ></ng-container>
        </ng-template>

        <div class="step-description">
          Please provide geotargeting information
        </div>

        <div class="form-container flex flex-1 flex-row">
          <mat-form-field class="mr-2">
            <input
              matInput
              placeholder="Postal Code / ZIP Code"
              formControlName="postalcode"
              (blur)="updateLocationInfo()"
            />
            <mat-hint *ngIf="locationInfo$ | async as locationInfo">{{
              locationInfo.formatted_address
            }}</mat-hint>
            <mat-error
              easeFormErrors
              [control]="accountBuilderForm.controls.postalcode"
            ></mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Radius" formControlName="radius">
              <mat-option
                *ngFor="let item of radiusOptions"
                [value]="item.value"
                >{{ item.viewValue }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <button
          mat-raised-button
          matStepperNext
          color="primary"
          [disabled]="accountBuilderForm.controls.postalcode.invalid"
        >
          Next
        </button>
      </mat-step>
      <mat-step
        *ngIf="
          accountBuilderService.stepSections[templateSelected]?.displayCampaigns
        "
        [stepControl]="accountBuilderForm.controls.adSets"
      >
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Ad Sets'
              }
            }"
          ></ng-container>
        </ng-template>
        <div class="step-description">
          Please provide assets for the display campaigns
        </div>

        <div class="text-container flex flex-row items-center justify-center">
          <div class="flex w-full flex-row justify-end">
            <button
              mat-raised-button
              *ngIf="accountBuilderForm.controls.adSets.controls.length"
              color="primary"
              (click)="addAdSet()"
              [disabled]="
                accountBuilderForm.controls.templateSelected.value ===
                  'hiring' &&
                accountBuilderForm.controls.adSets.controls.length >=
                  accountBuilderForm?.controls?.verticalIds?.value?.length
              "
            >
              <mat-icon class="mr-2">add_circle_outline</mat-icon>
              Add New Ad Set
            </button>
          </div>
        </div>
        <div class="form-container mt-5">
          <ng-container
            *ngIf="
              accountBuilderForm.controls.adSets.controls.length;
              else noAdSet
            "
          >
            <ng-container
              *ngFor="
                let adSetControl of accountBuilderForm.controls.adSets.controls;
                let i = index
              "
            >
              <ease-ad-set-item
                [dropdownName]="
                  accountBuilderForm.controls.templateSelected.value ===
                  'hiring'
                "
                [names]="accountBuilderForm.controls.verticalIds.value"
                [adSetControl]="adSetControl"
                [adSetsControl]="accountBuilderForm.controls.adSets"
                [index]="i"
                (delete)="onDeleteAdSet(i)"
              ></ease-ad-set-item>
            </ng-container>
            <button
              mat-raised-button
              matStepperNext
              color="primary"
              *ngIf="
                accountBuilderForm.controls.templateSelected.value === 'hiring'
              "
              [disabled]="accountBuilderForm.controls.adSets.invalid"
            >
              Next
            </button>
          </ng-container>

          <ng-template #noAdSet>
            <div class="push-lg--top text-center">
              <h3>No Ad Sets, start adding a new set below</h3>
              <button mat-raised-button color="primary" (click)="addAdSet()">
                <mat-icon class="mr-2">add_circle_outline</mat-icon>Add New Ad
                Set
              </button>
            </div>
          </ng-template>
        </div>
      </mat-step>
      <mat-step
        *ngIf="
          accountBuilderService.stepSections[templateSelected]?.searchCampaigns
        "
      >
        <ng-template matStepLabel>
          <div (click)="setVerticals()">
            <ng-container
              [ngTemplateOutlet]="dynamicStepLabel"
              [ngTemplateOutletContext]="{
                data: {
                  mainLabel: 'Search Campaigns',
                  isSelected: stepper.selectedIndex === 4
                }
              }"
            ></ng-container>
          </div>
        </ng-template>

        <div class="step-description">
          Please provide the needed information to generate search campaigns
        </div>
        <div class="p-4">
          <ease-search-campaign-builder
            [accountBuilderControl]="accountBuilderForm"
            [verticals]="verticalsSelected"
          ></ease-search-campaign-builder>
        </div>

        <button
          mat-raised-button
          matStepperNext
          color="primary"
          *ngIf="
            accountBuilderForm.controls.templateSelected.value === 'hiring'
          "
          [disabled]="accountBuilderForm.controls.searchSets.invalid"
        >
          Next
        </button>
      </mat-step>

      <mat-step
        *ngIf="
          accountBuilderService.stepSections[templateSelected]?.callExtensions
        "
      >
        <ng-template matStepLabel>
          <div>
            <ng-container
              [ngTemplateOutlet]="dynamicStepLabel"
              [ngTemplateOutletContext]="{
                data: {
                  mainLabel: 'Call Extensions',
                  isSelected: stepper.selectedIndex === 5
                }
              }"
            ></ng-container>
          </div>
        </ng-template>

        <div class="step-description mb-2">
          Please provide the needed information to generate Calls extensions
          <span *ngIf="templateSelected === 'hiring'">(Optional)</span>
        </div>

        <div class="form-container flex flex-row space-x-2">
          <ease-advanced-autocomplete
            class="flex-1"
            formControlName="callExtensionCountry"
            [options]="countries$ | async"
            [placeholder]="'Country'"
          ></ease-advanced-autocomplete>

          <mat-form-field class="flex-1">
            <input
              matInput
              placeholder="Phone Number"
              formControlName="callExtensionPhoneNumber"
            />
            <mat-hint> Example: (201) 555-0123 </mat-hint>

            <mat-error
              easeFormErrors
              [control]="accountBuilderForm.controls.callExtensionPhoneNumber"
            ></mat-error>
          </mat-form-field>
        </div>

        <button
          mat-raised-button
          matStepperNext
          color="primary"
          [disabled]="
            accountBuilderForm.controls.callExtensionCountry.invalid ||
            accountBuilderForm.controls.callExtensionPhoneNumber.invalid
          "
        >
          Next
        </button>
      </mat-step>
      <mat-step
        *ngIf="
          accountBuilderService.stepSections[templateSelected]?.negativeKeywords
        "
      >
        <ng-template matStepLabel>
          <div>
            <ng-container
              [ngTemplateOutlet]="dynamicStepLabel"
              [ngTemplateOutletContext]="{
                data: {
                  mainLabel: 'Negative Keywords',
                  isSelected: stepper.selectedIndex === 6
                }
              }"
            ></ng-container>
          </div>
        </ng-template>

        <div class="step-description mb-2">
          Please provide desired negative keyword list to apply to the account
        </div>

        <ease-negative-keywords-selector
          [control]="accountBuilderForm.controls.negativeKeywords"
        ></ease-negative-keywords-selector>
      </mat-step>
    </mat-stepper>
  </div>
</div>
<div
  class="flex flex-row justify-end border-t border-solid border-gray-100 bg-gray-50 p-3"
>
  <div class="flex-1"></div>

  <button
    color="primary"
    mat-raised-button
    [disabled]="accountBuilderForm.invalid || isWorking"
    (click)="onSubmit()"
  >
    Apply Template
  </button>
</div>

<ng-template #dynamicStepLabel let-data="data">
  <div class="flex flex-row">
    <div class="flex flex-1 flex-col font-normal">
      <div class="text-base font-bold">
        {{ data.mainLabel }}
      </div>
      <!-- Open State Step Text -->
      <p class="m-0 text-sm">
        {{ data.openStateSublabel }}
      </p>
    </div>
    <div
      class="pr-2 font-semibold italic text-gray-700"
      *ngIf="data.closedStateSublabel"
    >
      {{ data.closedStateSublabel }}
    </div>
  </div>
</ng-template>

<ng-template #submitting>
  <div class="job-status mb-5 whitespace-nowrap italic text-gray-500">
    Submitting ...
  </div>
  <mat-progress-bar class="mr-1" mode="indeterminate"> </mat-progress-bar>
</ng-template>
