import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Bugsnag from '@bugsnag/js';

import { environment } from '../../environments/environment';
import { EnvironmentService } from './environment.service';

export const bugsnagClient = Bugsnag.start({
  apiKey: '0fd29319e5f2fa04921ebe5d4ed0ea7f',
  appVersion: environment.VERSION.version,
  releaseStage: environment.name,
  enabledReleaseStages: ['staging', 'production'],
  enabledBreadcrumbTypes: environment.prodMode
    ? ['error', 'log', 'navigation', 'request', 'user']
    : []
});

@Injectable()
export class EaseErrorHandler implements ErrorHandler {
  private matSnackBar: MatSnackBar;
  private environmentService: EnvironmentService;

  constructor(private injector: Injector) {
    this.matSnackBar = this.injector.get(MatSnackBar);
    this.environmentService = this.injector.get(EnvironmentService);
  }

  async handleError(err: any): Promise<void> {
    if (err.message?.includes('Loading chunk')) {
      await this.environmentService.unregisterServiceWorkersAndClearCaches();
      const snackBarRef = this.matSnackBar.open(
        'An error occured while loading the page, reload to fix it',
        'Reload'
      );
      snackBarRef.onAction().subscribe(() => window.location.reload());
    } else if (
      err.message?.includes('auth/popup-closed-by-user') ||
      err.message?.includes('auth/network-request-failed') ||
      err.message?.includes('auth/cancelled-popup-request') ||
      err.message?.includes('auth/popup-blocked')
    ) {
    } else {
      bugsnagClient.notify(err);
      throw err;
    }
  }
}
