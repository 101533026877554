import { Injectable } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, Column, GridReadyEvent, SideBarDef } from 'ag-grid-community';

@Injectable({ providedIn: 'root' })
export class TableService {
  constructor() {}

  sortColDefByHeaderName(columns: Column[]): ColDef[] {
    // changing the case (to upper or lower) ensures a case insensitive sort
    return columns
      .map(column => column.getColDef())
      .sort((colDefA, colDefB) => {
        const headerNameA = colDefA.headerName
          ? colDefA.headerName.toUpperCase()
          : '';
        const headerNameB = colDefB.headerName
          ? colDefB.headerName.toUpperCase()
          : '';
        return headerNameA < headerNameB
          ? -1
          : headerNameA > headerNameB
          ? 1
          : 0;
      });
  }

  getDefaultSidebar(): SideBarDef {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true
          }
        }
      ]
    };
  }

  /**
   * Defines custom alphabetical order on the columns panel on the sidebar
   * for all the columns defined. Overrides the default behaviour where
   * the order of the columns on the list is synced to the order of the columns
   * on the table.
   *
   * Should only be called once the Ag-Grid table is ready (onGridReady)
   *
   * @param params
   */
  sortColumnsSidebar(params: GridReadyEvent | AgGridAngular) {
    const columnsToolPanel: any = params.api.getToolPanelInstance('columns');
    const sortedColDefs = this.sortColDefByHeaderName(
      params.columnApi.getColumns()
    );
    columnsToolPanel.setColumnLayout(sortedColDefs);
  }
}
