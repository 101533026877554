<div class="banner theme-info mb-5 flex flex-row items-center justify-center">
  <mat-icon class="mr-2">info</mat-icon>
  <span
    >The location targeting for these campaigns has been updated to
    <strong>People in your targeted locations</strong>.<br />If you
    intentionally want to target a wider audience, add a label:
    <strong>Broad Location Targeting</strong></span
  >
</div>
<ease-list-group size="sm">
  <ease-list-group-item *ngFor="let campaign of items">{{
    campaign.campaignName
  }}</ease-list-group-item>
</ease-list-group>
