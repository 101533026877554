import { NgModule } from '@angular/core';

import { PopoverModule } from '../popover.module';
import { SharedModule } from '../../shared.module';
import { AdvancedAutocompleteModule } from '../../advanced-autocomplete/advanced-autocomplete.module';
import { ChecklistTemplatesPopoverComponent } from './checklist-templates-popover.component';

@NgModule({
  imports: [PopoverModule, SharedModule, AdvancedAutocompleteModule],
  declarations: [ChecklistTemplatesPopoverComponent],
  exports: [ChecklistTemplatesPopoverComponent]
})
export class ChecklistTemplatesPopoverModule {}
