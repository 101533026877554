<div class="mt-5 flex flex-row gap-5" [satPopoverAnchor]="help">
  <button
    type="button"
    (click)="help.toggle()"
    [ngClass]="{ '!text-blue-500': help.isOpen() }"
  >
    <mat-icon>help_outline</mat-icon>
  </button>
  <mat-form-field>
    <input
      [formControl]="pasteArea"
      [placeholder]="'To add ' + entity + 's, paste SKIDs here'"
      (paste)="paste($event)"
      (focus)="help.open()"
      autocomplete="off"
      matInput
    />
    <mat-hint *ngIf="!isPristine">
      Pasting will overwrite the currently selected {{ entity }}s
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="max-w-xs">
    <mat-label>Separator</mat-label>
    <mat-select [formControl]="separator">
      <mat-option [value]="'\t'">Tab</mat-option>
      <mat-option value=",">Comma</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<ag-grid-angular
  #agGrid
  [gridOptions]="gridOptions"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [overlayLoadingTemplate]="overlayLoadingTemplate"
  class="ag-theme-material mt-3 h-64"
  [ngClass]="{ hidden: isPristine }"
>
</ag-grid-angular>

<div *ngIf="isPristine" class="relative">
  <ease-state-message
    [minHeight]="120"
    [message]="
      entity === 'account'
        ? 'Paste account IDs and types above to add accounts'
        : 'Paste customer IDs above to add ' + entity + 's'
    "
    class="mt-1 w-[initial] flex-1 rounded border border-dashed border-gray-400"
  ></ease-state-message>
  <button
    (click)="help.open()"
    class="!absolute top-1 right-1 !text-[13px]"
    mat-button
    color="primary"
    type="button"
  >
    <mat-icon class="mat-icon-18 mr-1">help_outline</mat-icon>
    Required Format
  </button>
</div>

<mat-error
  *ngIf="!isPristine && (hasInvalidEntity || !rowData?.length)"
  class="mt-5"
>
  *<span *ngIf="hasInvalidEntity">
    All invalid {{ entity }}s will be ignored and skipped. </span
  ><span *ngIf="!rowData.length"> No {{ entity }}s detected. </span>
  Check your column headers and see if it follows the
  <span (click)="help.open()" class="cursor-pointer font-semibold text-blue-500"
    >required format</span
  >.
</mat-error>

<sat-popover
  #help
  verticalAlign="below"
  horizontalAlign="start"
  [autoFocus]="false"
  [hasBackdrop]="true"
  [restoreFocus]="false"
  (opened)="cdr.detectChanges()"
  (closed)="cdr.detectChanges()"
  panelClass="bulk-help"
>
  <ease-bulk-targeting-help
    [entity]="entity"
    [separator]="separator.value === ',' ? 'comma' : 'tab'"
  ></ease-bulk-targeting-help>
</sat-popover>
