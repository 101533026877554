import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { Account, CQMetric } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';
@Component({
  selector: 'ease-cq-tab-content',
  templateUrl: './cq-tab-content.component.html',
  styleUrls: ['./cq-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CqTabContentComponent implements OnInit {
  @Input() account: Account;
  public callQualityMetric: CQMetric;
  public highQualityCalls: number;

  constructor(public accountDashboardService: AccountDashboardService) {}

  ngOnInit() {
    this.callQualityMetric = this.account.cqMetric;
    this.highQualityCalls =
      parseInt(this.callQualityMetric.totalUniqueCalls, 10) -
      parseInt(this.callQualityMetric.lowQualityCalls, 10);
  }
}
