import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  ViewChild,
  HostBinding,
  TemplateRef,
  ContentChild,
  ViewEncapsulation,
  ElementRef,
  Optional
} from '@angular/core';
import {
  AsyncValidatorFn,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  startWith,
  switchMap,
  tap
} from 'rxjs';

import { ListDisplayItemValue } from '../shared.interface';
import { AdvancedAutocompleteOption } from './advanced-autocomplete.interface';
import {
  AdvancedAutocompleteApplyValuePipe,
  AdvancedAutocompleteFilterPipe
} from './advanced-autocomplete.pipes';
import { suggestedItemValidator } from './advanced-autocomplete.utils';

@Component({
  selector: 'ease-advanced-autocomplete',
  templateUrl: './advanced-autocomplete.component.html',
  styleUrls: ['./advanced-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdvancedAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AdvancedAutocompleteComponent),
      multi: true
    },
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'mat-advanced-autocomplete' }
    }
  ]
})
export class AdvancedAutocompleteComponent
  implements ControlValueAccessor, Validator, OnInit
{
  @HostBinding('class') hostClass = 'flex';
  @Input() set options(options: AdvancedAutocompleteOption<true | false>[]) {
    if (options?.length) {
      this.grouped = (options as AdvancedAutocompleteOption<true>[]).some(
        option => option?.name && option?.items
      );
    }

    this._options = options;
    this.options$.next(options);
  }

  get options(): AdvancedAutocompleteOption<true | false>[] {
    return this._options;
  }

  @Input() placeholder: string = 'Select a suggested item';
  @Input() prefixIcon: string;
  @Input() autoFocus: boolean = false;
  @Input() autoBlur: boolean = false;
  @Input() hint: string;
  @Input() formControlName: string;
  @Input() formControl: FormControl<ListDisplayItemValue>;
  @ContentChild('panelFooter') panelFooter: TemplateRef<any>;
  @Output() optionSelected: EventEmitter<ListDisplayItemValue> =
    new EventEmitter<ListDisplayItemValue>();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;

  private options$: BehaviorSubject<
    AdvancedAutocompleteOption<true | false>[]
  > = new BehaviorSubject<AdvancedAutocompleteOption<true | false>[]>([]);
  private _options: AdvancedAutocompleteOption<true | false>[];
  private valueFromWrite$: BehaviorSubject<ListDisplayItemValue> =
    new BehaviorSubject<ListDisplayItemValue>(null);
  public grouped: boolean;
  public control: FormControl<ListDisplayItemValue> = new FormControl(null);
  public filteredOptions$: Observable<
    AdvancedAutocompleteOption<true | false>[]
  >;

  constructor(
    @Optional() private controlContainer: ControlContainer,
    private filterValue: AdvancedAutocompleteFilterPipe,
    private applyValue: AdvancedAutocompleteApplyValuePipe
  ) {}

  ngOnInit(): void {
    this.filteredOptions$ = combineLatest([
      this.options$,
      this.valueFromWrite$
    ]).pipe(
      tap(([options, valueFromWrite]) => {
        this.setValidators(options);
        this.setInitialSelected(valueFromWrite);
      }),
      switchMap(([options]) =>
        this.control.valueChanges.pipe(
          startWith(this.control.value),
          tap(query => this.humanInputHandler(query)),
          map((query: string) =>
            this.filterValue.transform(query, options, this.grouped)
          ),
          tap(() => this.setFocus())
        )
      )
    );
  }

  onBlur(filteredOptions: AdvancedAutocompleteOption<true | false>[]): void {
    return this.applyValue.transform(
      this.control,
      filteredOptions,
      this.grouped
    );
  }

  setFocus(): void {
    setTimeout(() => {
      if (this.autoFocus && this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    });
  }

  panelOpened(): void {
    // To avoid the latency of blank options when panel opened,
    // refresh current items in the viewport
    this.virtualScroller?.refresh();
  }

  setSelected(optionValue: ListDisplayItemValue): void {
    this.onChange(optionValue);
    this.optionSelected.emit(optionValue);

    // Once an option is selected, remove the focus on the search input
    // Note: this is the automatic way to trigger async validator error
    // without user extra interaction
    setTimeout(() => {
      if (this.autoBlur && this.searchInput) {
        this.searchInput.nativeElement.blur();
      }
    }, 300);
  }

  clearSelected(): void {
    this.control.reset();
    this.onChange(null);
  }

  private setInitialSelected(optionValue: ListDisplayItemValue): void {
    this.control.patchValue(optionValue);
    this.onChange(optionValue);
  }

  private humanInputHandler(query: ListDisplayItemValue): void {
    /**
     * To clear selected option when user choose to
     * remove option by deleting from text input
     */
    if (query === '') {
      this.clearSelected();
    }

    /**
     * Revalidate every human input change
     */
    this.onValidationChange();
  }

  /**
   * Get validators from parent formGroup or individually formControl for this.control
   */
  private controlValidators(): {
    validator: ValidatorFn;
    asyncValidator: AsyncValidatorFn;
  } {
    let validator: ValidatorFn;
    let asyncValidator: AsyncValidatorFn;

    if (this.formControlName) {
      validator =
        this.controlContainer?.control?.get([this.formControlName])
          ?.validator || null;

      asyncValidator =
        this.controlContainer?.control?.get([this.formControlName])
          ?.asyncValidator || null;
    } else {
      validator = this.formControl?.validator || null;
      asyncValidator = this.formControl?.asyncValidator || null;
    }

    return {
      validator,
      asyncValidator
    };
  }

  private setValidators(
    options: AdvancedAutocompleteOption<true | false>[]
  ): void {
    const controlValidators = this.controlValidators();

    this.control.setValidators(
      Validators.compose([
        controlValidators.validator,
        suggestedItemValidator(options, this.grouped)
      ])
    );

    if (controlValidators.asyncValidator) {
      this.control.addAsyncValidators(controlValidators.asyncValidator);
    }

    this.control.updateValueAndValidity();
  }

  onChange: (optionValue: ListDisplayItemValue | null) => any = () => {};

  onValidationChange: () => any = () => {};

  onTouched: () => any = () => {};

  writeValue(optionValue: ListDisplayItemValue) {
    this.valueFromWrite$.next(optionValue);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: any): void {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  validate(): ValidationErrors | null {
    return this.control?.invalid ? this.control.errors : null;
  }
}
