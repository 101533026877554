import { Directive, OnInit, Input } from '@angular/core';

import { EntityType } from '../shared.interface';
import { MarkdownEditorComponent } from './markdown-editor/markdown-editor.component';
import { MarkdownVariableService } from './markdown-variable.service';

@Directive({
  selector: 'ease-markdown-editor[easeMarkdownVariable]'
})
export class MarkdownVariableDirective implements OnInit {
  /**
   * Gets all the "default" variables from the entity provided
   */
  @Input() set easeMarkdownVariable(entity: EntityType) {
    this.entityVariables = entity
      ? this.markdownVariableService.getVariables(entity)
      : [];
    this.markdownEditor.variables = this.entityVariables;
  }
  /**
   * Adds an array of strings as custom variables. Values from default
   * variables from entities, if there were any provided, take precedence.
   */
  @Input() set easeMarkdownVariableCustom(customVariables: string[]) {
    const allVariables = [...this.entityVariables, ...customVariables];
    this.markdownEditor.variables = allVariables;
  }
  private entityVariables: string[] = [];

  constructor(
    private markdownEditor: MarkdownEditorComponent,
    private markdownVariableService: MarkdownVariableService
  ) {}

  ngOnInit() {}
}
