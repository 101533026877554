import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map } from 'rxjs/operators';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';

import { EmailTemplateSettingsService } from 'src/app/admin/email-templates/email-templates-settings/email-templates-settings.service';
import { SkSyncHttpService } from '../sksync-http.service';
import { DeepPartial, ListDisplayItem } from '../shared.interface';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from '../constants';
import {
  EmailMessage,
  EmailSendResponse,
  EmailTemplate,
  EmailTemplateDetails
} from './email.interface';

@Injectable({ providedIn: 'root' })
export class EmailTemplateService {
  public templateForNotificationType: { [type: string]: string } = {
    paymentDeclined: '0954c256-b5f9-49ef-a014-604133034c52'
  };

  constructor(
    private emailTemplateSettingsService: EmailTemplateSettingsService,
    private matSnackBar: MatSnackBar,
    private sksyncHttp: SkSyncHttpService
  ) {}

  getAll(): Observable<EmailTemplate[]> {
    return this.sksyncHttp.get(`/emails`).pipe(
      catchError(error => {
        this.matSnackBar.open(
          'There was an error getting the available email templates',
          'Close',
          {
            duration: SNACKBAR_DURATION_ERROR
          }
        );
        throw error;
      })
    );
  }

  getAllForDisplay(): Observable<ListDisplayItem[]> {
    return combineLatest([
      this.getAll(),
      this.emailTemplateSettingsService.getAll()
    ]).pipe(
      map(([templates, settings]) =>
        templates?.map(template => {
          const description = settings[template.ID]?.description;
          return {
            value: template.ID,
            viewValue: template.Name,
            viewSubvalue: description
          };
        })
      )
    );
  }

  async getDetails(templateId: string): Promise<EmailTemplateDetails> {
    const template = await firstValueFrom(
      this.sksyncHttp.get(`/emails/${templateId}`)
    );

    const { description, listId, ...recipientSettings } = await firstValueFrom(
      this.emailTemplateSettingsService.get(templateId)
    );
    template.AddRecipientsToList = !!listId;
    template.ListId = listId;
    template.RecipientSettings = recipientSettings;

    return template;
  }

  async send(emails: DeepPartial<EmailMessage>[]): Promise<EmailSendResponse> {
    try {
      const response = await firstValueFrom(
        this.sksyncHttp.post(`/emails`, emails)
      );
      this.matSnackBar.open('Message sent', 'Close', {
        duration: SNACKBAR_DURATION_SUCCESS
      });

      return response;
    } catch (e) {
      this.matSnackBar.open(
        e?.error?.message || 'There was an error sending the message',
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );
    }
  }
}
