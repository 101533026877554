import { ListDisplayItem } from '../shared/shared.interface';
import { CustomerCommunicationDelay } from './customer-rules/customer-rules.interface';

export const CUSTOMER_ROLES: ListDisplayItem[] = [
  {
    value: 'relationshipManager',
    viewValue: 'Customer Relationship Manager'
  },
  { value: 'accountManager', viewValue: 'Account Manager' },
  { value: 'accountReviewer', viewValue: 'Account Reviewer' }
];

/**
 * This value needs to be in sync with sksync's customer communication job:
 * https://github.com/SearchKings/sksync-api/blob/develop/src/snake/queues/jobs/customers/customer-communication-init.ts
 */
export const DEFAULT_NEXT_COMMUNICATION_DELAY: CustomerCommunicationDelay = {
  value: 15,
  unit: 'day'
};
