import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { AgRendererComponent } from 'ag-grid-angular';
import { Account, CQMetric } from '../../account-dashboard.interface';

@Component({
  selector: 'ease-cq-metric-cell',
  templateUrl: './cq-metric-cell.component.html',
  styleUrls: ['./cq-metric-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CqMetricCellComponent implements AgRendererComponent {
  public callQualityMetric: CQMetric;
  public account: Account;
  public isGroup: boolean;

  private params: ICellRendererParams;
  public targetCplNumber: number;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.account = params.data;
    this.isGroup = params.node.group;

    if (this.account) {
      this.callQualityMetric = this.account.cqMetric;
    }
  }

  refresh(params: ICellRendererParams) {
    return true;
  }

  cellChanged(newTargetCpl: number) {
    this.account.targetCpl = newTargetCpl;
    this.params.api.refreshCells({
      rowNodes: [this.params.node],
      force: true
    });
  }
}
