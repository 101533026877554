import { catchError, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { defaultsDeep, merge } from 'lodash-es';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Observable, throwError } from 'rxjs';

import { bugsnagClient } from './error-handler';
import { SNACKBAR_DURATION_ERROR } from './constants';

export interface EaseRequestOptions {
  displayError?: boolean;
  cacheBust?: boolean;
  throwError?: boolean;
}

export interface HttpErrorResponseWithCode extends HttpErrorResponse {
  code?: string;
}

export class BaseHttpService {
  private REST_URI: string;
  private defaultHeaders = {
    'Content-Type': 'application/json'
  };
  private defaultOptions = { headers: this.defaultHeaders };
  private ignoreErrorCodes = {
    ONBOARDING_PACKAGE_DELETE_REJECTED: true
  };
  private options$: Observable<any>;

  constructor(
    restUri: string,
    private tokenGetter: AngularFireAuth,
    private baseHttp: HttpClient,
    private snackBar: MatSnackBar,
    noDefaultOptions?: boolean
  ) {
    this.REST_URI = restUri;

    this.options$ = this.tokenGetter.idToken.pipe(
      map(token => ({ Authorization: `Bearer ${token}` })),
      map(headers =>
        noDefaultOptions ? {} : merge({}, this.defaultOptions, { headers })
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  get<T>(
    url: string,
    extraOptions = {},
    requestOptions?: EaseRequestOptions
  ): Observable<any> {
    return this.options$.pipe(
      map(opts =>
        merge(
          {},
          opts,
          extraOptions,
          this.getRequestOptionsForEaseOptions(requestOptions)
        )
      ),
      switchMap(options =>
        this.baseHttp.get<any>(`${this.REST_URI}${url}`, options).pipe(
          map(this.extractData.bind(this)),
          catchError(err => this.handleError(err, requestOptions))
        )
      ),
      first()
    );
  }

  put<T>(
    url: string,
    data: any,
    requestOptions?: EaseRequestOptions
  ): Observable<any> {
    return this.options$.pipe(
      map(opts =>
        merge({}, opts, this.getRequestOptionsForEaseOptions(requestOptions))
      ),
      switchMap(options =>
        this.baseHttp.put<any>(`${this.REST_URI}${url}`, data, options).pipe(
          map(this.extractData.bind(this)),
          catchError(err => this.handleError(err, requestOptions))
        )
      ),
      first()
    );
  }

  patch<T>(
    url: string,
    data: any,
    requestOptions?: EaseRequestOptions
  ): Observable<any> {
    return this.options$.pipe(
      map(opts =>
        merge({}, opts, this.getRequestOptionsForEaseOptions(requestOptions))
      ),
      switchMap(options =>
        this.baseHttp.patch<any>(`${this.REST_URI}${url}`, data, options).pipe(
          map(this.extractData.bind(this)),
          catchError(err => this.handleError(err, requestOptions))
        )
      ),
      first()
    );
  }

  post<T>(
    url: string,
    data = {},
    requestOptions?: EaseRequestOptions
  ): Observable<any> {
    return this.options$.pipe(
      map(opts =>
        merge({}, opts, this.getRequestOptionsForEaseOptions(requestOptions))
      ),
      switchMap(options =>
        this.baseHttp.post<any>(`${this.REST_URI}${url}`, data, options).pipe(
          map(this.extractData.bind(this)),
          catchError(err => this.handleError(err, requestOptions))
        )
      ),
      first()
    );
  }

  delete<T>(
    url: string,
    extraOptions = {},
    requestOptions?: EaseRequestOptions
  ): Observable<any> {
    return this.options$.pipe(
      map(opts =>
        merge(
          {},
          opts,
          extraOptions,
          this.getRequestOptionsForEaseOptions(requestOptions)
        )
      ),
      switchMap(options =>
        this.baseHttp.delete<any>(`${this.REST_URI}${url}`, options).pipe(
          map(this.extractData.bind(this)),
          catchError(err => this.handleError(err, requestOptions))
        )
      ),
      first()
    );
  }

  request<T>(
    method: string,
    url: string,
    extraOptions = {},
    requestOptions?: EaseRequestOptions
  ): Observable<any> {
    return this.options$.pipe(
      map(opts =>
        merge(
          {},
          opts,
          extraOptions,
          this.getRequestOptionsForEaseOptions(requestOptions)
        )
      ),
      switchMap(options =>
        this.baseHttp
          .request<any>(method, `${this.REST_URI}${url}`, options)
          .pipe(
            map(this.extractData.bind(this)),
            catchError(err => this.handleError(err, requestOptions))
          )
      ),
      first()
    );
  }

  protected extractData(response) {
    return response;
  }

  private getRequestOptionsForEaseOptions(easeOptions: EaseRequestOptions) {
    let baseOptions = {};

    if (easeOptions && easeOptions.cacheBust) {
      baseOptions = {
        headers: {
          cachebuster: 'true',
          'cache-control': 'no-cache'
        }
      };
    }

    return baseOptions;
  }

  private handleError(
    error: HttpErrorResponseWithCode,
    requestOptions: EaseRequestOptions = {}
  ): Observable<any> {
    const defaultOptions = {
      displayError: true,
      throwError: true
    };

    const mergedOptions = defaultsDeep(requestOptions, defaultOptions);

    /**
     * Ignore client-side errors -- these are usually due to network connectivity issues
     * See: https://angular.io/guide/http#getting-error-details
     */
    if (error?.status === 0) {
      this.snackBar.open(
        'An error occured, please ensure you are connected to the internet',
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );

      return EMPTY;
    }

    const foundError = error && error.error ? error.error : error;

    if (!this.ignoreErrorCodes[foundError?.code]) {
      bugsnagClient.notify(error, err => {
        err.addMetadata('details', error);
      });
    }

    if (foundError instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(`An error occurred: ${foundError.message}`);
    } else if (foundError instanceof ProgressEvent) {
      console.error(`An error occurred: ${foundError.type}`);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, body was: ${foundError.message}`
      );
    }

    if (mergedOptions.displayError !== false) {
      const message = `${
        foundError.message || 'Sorry, it looks like an error occured'
      }`;
      this.snackBar.open(message, 'CLOSE', {
        duration: SNACKBAR_DURATION_ERROR
      });
    }
    return mergedOptions.throwError ? throwError(() => error) : EMPTY;
  }
}
