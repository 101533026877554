import { InjectionToken, TemplateRef, Type } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

import { WindowType } from './window-types';

/**
 * Configuration options for a WindowPane
 */
export class WindowConfig<R = void> {
  /**
   * Will be provided only when restoring a window so that the id can be used to compare when syncing windows
   */
  id?: string;
  /**
   * The type of window that should be opened
   * Must be a known type defined in WindowType
   */
  type: WindowType;
  /**
   * Arbitrary data that can be injected into a loaded component with @Inject(WINDOW_DATA)
   */
  data?: R;
  /**
   * The initial state for the window when it is first created
   */
  state?: WindowState;
  /**
   * A state to be used when a window is toggled for the first time
   * Useful if a window can start minimized and you need to provide a
   * "first opened" state
   */
  firstToggleState?: WindowState;
  /**
   * Icon to display before window name in the window bar
   */
  icon?: string;
  /**
   * Icon template for the window, a TemplateRef
   */
  iconTemplate?: TemplateRef<any>;
  /**
   * Title for the window, a simple string
   */
  title?: string;
  /**
   * Title template for the window, a TemplateRef
   */
  titleTemplate?: TemplateRef<any>;
  /**
   * Subtitle for the window, a simple string
   */
  subtitle?: string;
  /**
   * Subtitle template for the window, a TemplateRef
   */
  subtitleTemplate?: TemplateRef<any>;
  /**
   * Optional context object to be passed to the templates for rendering
   */
  templateContext?: { [contextKey: string]: any };
  /**
   * Optional context object to be passed to the templates for rendering
   */
  highlighted?: boolean;
  /**
   * Allow duplicate windows to be created
   * Windows will be de-duplicated based on their type and data
   */
  allowDuplicates?: boolean;
  /**
   * Whether a window should update the URL so it can be shared
   * and deep-linked to by other users (e.g. viewing a task)
   */
  routeable?: boolean;
  /**
   * An optional list of parameters to pass to the routeable component
   */
  routeableParams?: Params;
  /**
   * Whether to clear the params after initial navigation
   */
  clearRouteableParams?: boolean;

  constructor(config: Partial<WindowConfig<R | void>> = {}) {
    Object.assign(this, config);
  }
}

export type WindowState = 'MINIMIZED' | 'OPEN' | 'MAXIMIZED';

/**
 * Tokens that will be injectable for each WindowPane component (and it's children)
 */
export const WINDOW_DATA = new InjectionToken<Observable<any>>('Window Data');
export const WINDOW_CONFIG = new InjectionToken<WindowConfig>('Window Config');
export const WINDOW_CONTENT = new InjectionToken<TemplateRef<any> | Type<any>>(
  'Window Content'
);

export const WINDOW_ROUTER_OUTLET = 'windows';
export const WINDOW_ROUTER_PATH = 'windows';
