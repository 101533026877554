import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { EmailsModule } from '../../emails/emails.module';
import { WindowPaneSendEmailComponent } from './window-pane-send-email.component';

@NgModule({
  imports: [EmailsModule, CommonModule, MatIconModule],
  declarations: [WindowPaneSendEmailComponent],
  exports: [WindowPaneSendEmailComponent]
})
export class WindowPaneSendEmailModule {}
