<div
  class="metric row details flex flex-col"
  [ngClass]="account.comMetric.ragStatusColor"
>
  <!--header-->
  <div class="row header flex flex-1 flex-row">
    <div class="column cell flex flex-row items-center justify-center">
      <div class="graph flex flex-col">
        <div class="dot flex flex-row">
          <div class="indicator metric-background"></div>
        </div>
      </div>

      <div class="status-summary flex-1">
        {{ account.comMetric.ragStatusDescription }}
      </div>
    </div>
  </div>
  <!--details-->
  <div class="detail-summary row flex flex-row">
    <div class="column flex !max-w-[33%] flex-full flex-row">
      <div
        class="column cell detail flex flex-1 flex-col items-center justify-center"
      >
        <ease-metric-simple
          [label]="'Account CPL'"
          [tooltip]="
            'The account\'s accrued spend divided by the number of leads for the period so far.'
          "
          [value]="account.comMetric.cpl | currency: 'USD':'symbol':'1.0-2'"
          [differenceNumeric]="
            account.comMetric.cpl - account.comMetric.medianCPL
              | currency: 'USD':'symbol':'1.0-2'
          "
          [differencePercentage]="account.comMetric.percentageSwingFromMedian"
        ></ease-metric-simple>
      </div>
      <div class="column cell detail flex flex-1 flex-col justify-center">
        <ease-metric-simple
          [label]="'Median CPL'"
          [tooltip]="
            'The median of all the cost per leads from all the accounts on the same vertical and DMA.'
          "
          [value]="
            account.comMetric.medianCPL | currency: 'USD':'symbol':'1.0-2'
          "
        ></ease-metric-simple>
      </div>
    </div>

    <ng-container
      *ngIf="account?.comMetric?.metadata?.rankingList; else noRankingData"
    >
      <div class="column cell detail flex flex-1 flex-col justify-center">
        <ease-metric-list
          [list]="[
            {
              label: 'Lowest CPL',
              value: lowestVerticalCPL | currency: 'USD':'symbol':'1.0-2'
            },
            {
              label: 'Highest CPL',
              value: highestVerticalCPL | currency: 'USD':'symbol':'1.0-2'
            },
            {
              label: 'Your Ranking',
              value:
                accountVerticalRank + ' out of ' + account.comMetric.numAccounts
            }
          ]"
        ></ease-metric-list>
      </div>
      <div class="column cell detail flex-1">
        <ease-vertical-ranking
          [colorSelected]="account.comMetric.ragStatusColor"
          [verticalRankings]="account?.comMetric?.metadata?.rankingList"
        ></ease-vertical-ranking>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noRankingData>
  <div
    class="column cell detail flex-1"
    *ngIf="!account?.comMetric?.metadata?.rankingList"
  >
    <div class="not-enough-data flex-1">
      <mat-icon class="mat-icon-24"> error_outline </mat-icon>
      <div class="message">Not enough data for vertical ranking</div>
    </div>
  </div>
</ng-template>
