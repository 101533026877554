import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';

import { UserService } from 'src/app/users/user.service';
import { SNACKBAR_DURATION_ERROR } from '../constants';
import { environment } from '../../../environments/environment';
import {
  HolidayResponse,
  RegionHolidays,
  SupportedCountries,
  SupportedCountriesList
} from './holiday.interface';

@Injectable({ providedIn: 'root' })
export class HolidayService {
  private nextYear: number = moment().add(1, 'years').year();
  public currentYear: number = moment().year();
  public maxYear: number = moment().add(10, 'years').year();
  public regionHolidays$: Observable<RegionHolidays> =
    this.getUserRegionHolidays();

  constructor(
    private http: HttpClient,
    private matSnackbar: MatSnackBar,
    private userService: UserService
  ) {}

  /**
   * Gets all the holidays of a country in a given year
   *
   * @param countryCode
   * @param year
   * @returns
   */
  private getHolidays(
    countryCode: SupportedCountries,
    year: number
  ): Observable<HolidayResponse[]> {
    return this.http
      .get(
        `https://holidays.abstractapi.com/v1/?api_key=${environment.ABSTRACT_HOLIDAYS_KEY}&country=${countryCode}&year=${year}`
      )
      .pipe(
        catchError(() => {
          this.matSnackbar.open(
            `There was a problem getting the holidays`,
            'Close',
            { duration: SNACKBAR_DURATION_ERROR }
          );
          return of([]);
        })
      ) as Observable<HolidayResponse[]>;
  }

  /**
   * Gets all the relevant holidays based on the current user's region, for this year and the next
   * - CA users will get both US and CA holidays
   * - SA users will get SA holidays
   *
   * @returns
   */
  private getUserRegionHolidays(): Observable<RegionHolidays> {
    switch (this.userService.currentUser.region) {
      case 'southAfrica':
        return combineLatest([
          this.getHolidays('ZA', this.currentYear),
          this.getHolidays('ZA', this.nextYear)
        ]).pipe(
          map(([current, next]) => this.processHolidays([...current, ...next])),
          shareReplay(1)
        );
      default:
        return combineLatest([
          this.getHolidays('US', this.currentYear),
          this.getHolidays('US', this.nextYear),
          this.getHolidays('CA', this.currentYear),
          this.getHolidays('CA', this.nextYear)
        ]).pipe(
          map(([usCurrent, usNext, caCurrent, caNext]): RegionHolidays => {
            const allHolidays = [
              ...caCurrent,
              ...caNext,
              ...usCurrent,
              ...usNext
            ];
            return this.processHolidays(allHolidays);
          }),
          shareReplay(1)
        );
    }
  }

  /**
   * Filters the holidays to only include the relevant holiday types,
   * destructures the object to only include relevant data and groups it
   * by date.
   *
   * @param allHolidays
   * @returns
   */
  private processHolidays(allHolidays: HolidayResponse[]): RegionHolidays {
    const regionHolidays = {
      countries: {} as SupportedCountriesList,
      holidays: {}
    } as RegionHolidays;

    allHolidays
      .filter(
        holiday =>
          holiday.type === 'National' || holiday.type === 'Local holiday'
      )
      .forEach(holiday => {
        regionHolidays.countries[holiday.country] = true;

        // reformat date for easier comparison with PrimeNG's date format
        // https://momentjs.com/docs/#/parsing/string-format/
        const primengDate = moment(holiday.date, 'MM/DD/YYYY').format(
          'M/D/YYYY'
        );

        // initialize date object when it's undefined
        if (!regionHolidays.holidays[primengDate]) {
          regionHolidays.holidays[primengDate] = {
            countries: {} as SupportedCountriesList,
            tooltip: []
          };
        }

        regionHolidays.holidays[primengDate].countries[holiday.country] = true;
        regionHolidays.holidays[primengDate].tooltip.push({
          name: holiday.name,
          location: holiday.location,
          country: holiday.country
        });
      });

    return regionHolidays;
  }
}
