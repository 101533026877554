import { Injectable } from '@angular/core';

import { FirebaseDbService } from '../../shared/firebase-db.service';
import { TASKS_OPEN_PATH } from '../../shared/firebase-paths';

export interface SubtasksProgress {
  completed: number;
  total: number;
  progress: number;
}

@Injectable({
  providedIn: 'root'
})
export class SubtasksService {
  constructor(private angularFire: FirebaseDbService) {}
  /**
   * Attach any task to another task making it a subtask.
   *
   * @param parentTaskId The id of the parent task
   * @param subtaskId If oh children task to atach to the parent task
   */
  async attach(parentTaskId: string, subtaskId: string): Promise<void> {
    await this.angularFire
      .object(`/${TASKS_OPEN_PATH}/${subtaskId}/parent`)
      .set(parentTaskId);
    await this.angularFire
      .object(`/${TASKS_OPEN_PATH}/${parentTaskId}/children/${subtaskId}`)
      .set(true);
  }

  /**
   * Detach a task from his parent task.
   *
   * @param parentTaskId The id of the parent task
   * @param subtaskId If oh children task to atach to the parent task
   */
  async detach(parentTaskId: string, subtaskId: string): Promise<void> {
    await this.angularFire
      .object(`/${TASKS_OPEN_PATH}/${subtaskId}/parent`)
      .remove();
    await this.angularFire
      .object(`/${TASKS_OPEN_PATH}/${parentTaskId}/children/${subtaskId}`)
      .remove();
  }

  /**
   *  Set the expanded state of a task in the tasks dashboard.
   *
   * @param widgetId The ID of the widget where the task is locates
   * @param taskId The ID of the task
   * @param state The state of the subtask panel
   */
  setSubtaskPanelDashboardState(
    widgetId: string,
    taskId: string,
    state: boolean
  ) {
    //Save expanded status on serssion storage
    const states = this.getSubtaskPanelDashboardStates() || {};
    sessionStorage.setItem(
      `TaskDashboardSubtaskStates`,
      JSON.stringify({
        ...states,
        [widgetId]: {
          ...states[widgetId],
          [taskId]: state
        }
      })
    );
  }

  /**
   * Get the expanded state of a task in the tasks dashboard.
   *
   * @param widgetId The ID of the widget where the task is located
   * @param taskId  The ID of the task
   * @returns  The expanded state of the task subtask panel
   */
  getSubtaskPanelState(widgetId: string, taskId: string): boolean {
    const states = this.getSubtaskPanelDashboardStates();

    return !!states?.[widgetId]?.[taskId];
  }

  /**
   *  Get the expanded state of all tasks in the tasks dashboard.
   *
   * @returns All the expanded states of the tasks in the tasks dashboard
   */
  getSubtaskPanelDashboardStates(): Record<string, Record<string, boolean>> {
    return sessionStorage.getItem(`TaskDashboardSubtaskStates`)
      ? JSON.parse(sessionStorage.getItem(`TaskDashboardSubtaskStates`))
      : null;
  }
}
