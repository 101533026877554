<ng-container *ngIf="summary$ | async as summary; else loading">
  <ng-container *ngIf="summary?.length; else empty">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of summary" #panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="truncate" [attr.title]="item.accountName">
            <ease-account-icon
              class="mr-2 hidden lg:inline-block"
              [size]="20"
              accountType="lsa"
            ></ease-account-icon>
            <span
              class="account-name truncate"
              [ngClass]="{ 'font-medium': panel.expanded }"
              >{{ item.accountName }}</span
            >
          </mat-panel-title>
          <mat-panel-description *ngIf="!panel.expanded">
            <div class="flex flex-row">
              <div class="header-item" matTooltip="Cost (Last 30d)">
                <mat-icon class="mat-icon-16">attach_money</mat-icon>
                {{ item.spendLast30 | currency: '':'':'1.0-2' }}
              </div>
              <div class="header-item" matTooltip="Leads (Last 30d)">
                <mat-icon class="mat-icon-16">add_call</mat-icon>
                {{ item.leadsLast30 }}
              </div>
              <div class="header-item" matTooltip="Responsiveness (Last 90d)">
                <mat-icon class="mat-icon-16">phone_in_talk</mat-icon>
                {{ item.currResponsiveness | number: '1.0-2' }}%
              </div>
              <div
                class="header-item hidden md:block"
                matTooltip="Median Call Duration (Last 90d)"
              >
                <mat-icon class="mat-icon-16">timer</mat-icon>
                {{ item.callMedian | minuteSeconds }}
              </div>
              <div
                class="header-item hidden md:block"
                matTooltip="Total Reviews"
              >
                <mat-icon class="mat-icon-16">rate_review</mat-icon>
                {{ item.totalReviews }}
              </div>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div>
          <div class="summary-row flex flex-row flex-wrap">
            <h4 class="summary-header">Summary</h4>
            <ease-metric-simple
              class="summary-metric"
              matIcon="attach_money"
              [value]="item.spendLast30 | currency: 'USD':'symbol':'1.0-2'"
              label="Cost"
              notes="Last 30d"
            ></ease-metric-simple>
            <ease-metric-simple
              class="summary-metric"
              matIcon="add_call"
              [value]="item.leadsLast30"
              label="Leads"
              notes="Last 30d"
            ></ease-metric-simple>
            <ease-metric-simple
              class="summary-metric"
              matIcon="phone"
              [value]="
                item.currResponsiveness
                  ? (item.currResponsiveness | number: '1.0-2') + '%'
                  : null
              "
              label="Responsiveness"
              notes="Last 90d"
              [differencePercentage]="item.responsivenessChange"
            ></ease-metric-simple>
            <ease-metric-simple
              class="summary-metric"
              matIcon="timer"
              [value]="item.callMedian | minuteSeconds"
              label="Call Duration"
              notes="Median Last 90d"
            ></ease-metric-simple>
          </div>

          <div class="summary-row flex flex-row flex-wrap">
            <h4 class="summary-header">Reviews</h4>
            <ease-metric-simple
              class="summary-metric"
              matIcon="rate_review"
              [value]="item.totalReviews"
              label="Total"
            ></ease-metric-simple>
            <ease-metric-simple
              class="summary-metric"
              matIcon="add_comment"
              [value]="item.newReviewsLast7"
              label="New Last 7d"
            ></ease-metric-simple>
            <ease-metric-simple
              class="summary-metric"
              matIcon="add_comment"
              [value]="item.newReviewsLast30"
              label="New Last 30d"
            ></ease-metric-simple>
            <ease-metric-simple
              class="summary-metric"
              matIcon="star"
              [value]="item.rating"
              label="Rating"
            ></ease-metric-simple>
          </div>

          <div class="summary-row flex flex-row flex-wrap">
            <h4 class="summary-header flex flex-row items-center justify-start">
              Benchmark
              <mat-icon
                class="mat-icon-18 ml-1 cursor-pointer"
                matTooltip="Compared to others in same DMA and vertical"
                >help</mat-icon
              >
            </h4>
            <ease-metric-simple
              class="summary-metric"
              matIcon="device_hub"
              [value]="item.totalMedianCustomers"
              label="Similar Accounts"
            ></ease-metric-simple>
            <ease-metric-simple
              class="summary-metric"
              matIcon="add_call"
              [value]="item.leadsMedian"
              label="Leads"
            ></ease-metric-simple>
            <ease-metric-simple
              class="summary-metric"
              matIcon="phone"
              [value]="
                item.responsivenessMedian
                  ? (item.responsivenessMedian | number: '1.0-2') + '%'
                  : null
              "
              label="Responsiveness"
            ></ease-metric-simple>
            <ease-metric-simple
              class="summary-metric"
              matIcon="rate_review"
              [value]="item.reviewsMedian"
              label="Reviews"
            ></ease-metric-simple>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</ng-container>

<ng-template #loading>
  <ease-state-loading
    [minHeight]="80"
    class="widget-empty shadow-md"
    [message]="'Loading...'"
  ></ease-state-loading>
</ng-template>
<ng-template #empty>
  <ease-state-message
    [minHeight]="80"
    class="widget-empty shadow-md"
    [message]="'30 days of data is required to display a summary.'"
  ></ease-state-message>
</ng-template>
