import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { EntityUtilsService } from '../../entity-utils.service';
import { WINDOW_DATA } from '../window-options';
import { WindowPane } from '../window-pane/window-pane';

@Component({
  selector: 'ease-window-pane-create-funding',
  templateUrl: './window-pane-create-funding.component.html',
  styleUrls: ['./window-pane-create-funding.component.scss']
})
export class WindowPaneCreateFundingComponent implements OnInit, AfterViewInit {
  @ViewChild('windowSubtitleTemplate', { read: TemplateRef })
  windowSubtitleTemplate: TemplateRef<any>;

  public customerName$: Observable<string>;

  constructor(
    @Inject(WINDOW_DATA) public data$: Observable<{ customerId: string }>,
    private entityUtilsService: EntityUtilsService,
    private windowPane: WindowPane
  ) {}

  ngOnInit() {
    this.customerName$ = this.data$.pipe(
      switchMap(({ customerId }) =>
        this.entityUtilsService.getEntityField(
          { entityId: customerId, entityType: 'customer' },
          'name'
        )
      )
    );
  }

  ngAfterViewInit() {
    this.windowPane.updateSubtitleTemplate(this.windowSubtitleTemplate);
  }

  fundingCreated() {
    this.windowPane.close();
  }
}
