<h1 class="title" mat-dialog-title>Funding Settings</h1>
<div mat-dialog-content>
  <ng-container
    *ngIf="fundingService.fundingSettings$ | async; else loadingSettings"
  >
    <ng-container *ngIf="billingCustomer$ | async as billingCustomer">
      <form class="form" [formGroup]="fundingSettingsForm">
        <div class="item">
          <div class="label">Management Fee Payment Type</div>
          <mat-radio-group
            aria-label="Management Fee Payment Type"
            formControlName="paymentType"
            class="flex flex-col"
          >
            <mat-radio-button
              value="funding"
              (change)="paymentTypeChange($event)"
              [disabled]="!billingCustomer?.id"
              >Funding</mat-radio-button
            >
            <mat-accordion
              class="advanced-settings"
              [formGroup]="fundingPaymentTypeSettingsGroup"
            >
              <mat-expansion-panel [expanded]="viewFundingPaymentOptions">
                <mat-form-field>
                  <mat-label>Management Fee Type </mat-label>
                  <mat-select formControlName="managementFeeType">
                    <ng-container *ngFor="let type of fundingTypes">
                      <mat-option [value]="type.id">
                        {{ type.name }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
                <div class="flex flex-1 flex-row">
                  <mat-form-field class="flex-1">
                    <mat-label>Management Fee Amount</mat-label>
                    <input
                      formControlName="managementFeeAmount"
                      type="number"
                      min="0"
                      matInput
                    />
                  </mat-form-field>
                  <div class="gap"></div>
                  <mat-form-field class="flex-1">
                    <mat-label>Billing Day</mat-label>
                    <input
                      formControlName="billingDay"
                      type="number"
                      min="1"
                      max="28"
                      matInput
                    />
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-radio-button
              value="fundingPercentage"
              (change)="paymentTypeChange($event)"
              [disabled]="!billingCustomer?.id"
              >Funding Percentage
            </mat-radio-button>
            <mat-accordion
              class="advanced-settings"
              [formGroup]="fundingPercentagePaymentTypeSettingsGroup"
            >
              <mat-expansion-panel
                [expanded]="viewFundingPercentagePaymentOptions"
              >
                <mat-form-field>
                  <mat-label>Management Fee Type </mat-label>
                  <mat-select formControlName="managementFeeType">
                    <ng-container *ngFor="let type of fundingTypes">
                      <mat-option [value]="type.id">
                        {{ type.name }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-radio-button
              value="customer"
              (change)="paymentTypeChange($event)"
              >Out of Pocket</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div class="item">
          <div class="header flex flex-row items-center justify-between">
            <div class="label">Accounts to Fund</div>
            <button
              mat-button
              color="primary"
              [matMenuTriggerFor]="accounts_menu"
            >
              + Add Account
            </button>
          </div>

          <div class="account-list">
            <ng-container
              *ngIf="
                fundingSettingsForm.controls.advertisingAccount.controls;
                else noAccountsToFund
              "
            >
              <ng-container
                *ngIf="
                  fundingSettingsForm.controls.advertisingAccount.controls
                    .length !== 0;
                  else noAccountsToFund
                "
              >
                <ng-container
                  *ngFor="
                    let account of fundingSettingsForm.controls
                      .advertisingAccount.controls;
                    let index = index
                  "
                >
                  <!--account item template-->
                  <div
                    class="item flex flex-row"
                    *ngIf="account.value as account"
                  >
                    <div class="icon flex flex-col justify-center">
                      <ease-account-icon
                        class="icon"
                        [accountType]="account.type"
                        [size]="18"
                        class="mr-2"
                      >
                      </ease-account-icon>
                    </div>
                    <div class="details flex flex-1 flex-col justify-center">
                      <span class="label">{{ account.name }}</span>
                      <span class="id">{{ account.id }}</span>
                    </div>
                    <div class="action">
                      <button mat-icon-button (click)="removeAccount(index)">
                        <mat-icon class="mat-icon-18">close</mat-icon>
                      </button>
                    </div>
                  </div>
                  <!--end of account item template-->
                </ng-container>
                <mat-error
                  easeFormErrors
                  [control]="fundingSettingsForm"
                ></mat-error>
              </ng-container>
            </ng-container>
            <ng-template #noAccountsToFund>
              <div class="notice">
                <div class="content">
                  There are no accounts to fund yet. Add at least one by
                  modifying the <strong>Funding Settings</strong>.
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </form>
    </ng-container>
  </ng-container>
  <ng-template #loadingSettings>
    <div class="loading flex flex-1 flex-row items-center justify-center">
      <mat-progress-spinner
        [diameter]="48"
        color="primary"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>
  </ng-template>
</div>
<ng-container *ngIf="fundingService.fundingSettings$ | async">
  <div class="actions" mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">Cancel</button>
    <button
      color="primary"
      mat-button
      (click)="onSubmit()"
      [disabled]="fundingSettingsForm.invalid"
    >
      Save Changes
    </button>
  </div>
</ng-container>

<mat-menu #accounts_menu="matMenu">
  <ng-container *ngFor="let platform of customerAccountsList$ | async">
    <button mat-menu-item [matMenuTriggerFor]="sub_menu">
      <ease-account-icon
        class="mat-icon mr-2"
        [accountType]="platform.type"
        [size]="24"
      ></ease-account-icon>
      {{ platform.name }}
    </button>
    <mat-menu #sub_menu="matMenu">
      <button
        class="sub-item-extra"
        *ngFor="let account of platform.accounts"
        mat-menu-item
        (click)="addAccount(account)"
        [disabled]="checkAdvertisingAccounts(account.id)"
      >
        <ease-account-icon
          class="mr-2"
          [accountType]="platform.type"
          [size]="24"
        ></ease-account-icon>
        <span class="mr-2 w-0 flex-1 truncate">{{ account.name }}</span>
      </button>
    </mat-menu>
  </ng-container>
</mat-menu>
