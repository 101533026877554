<div class="summary flex flex-1 flex-row">
  <ng-container
    *ngIf="
      fundingService.fundingSummary$ | async as fundingSummary;
      else loading
    "
  >
    <ease-metric-simple
      [label]="'Credit Balance'"
      [value]="
        fundingSummary?.summary?.currentBalance
          | currency: 'USD':'symbol':'1.2-2'
      "
      [notes]="fundingManagementFee$ | async"
      class="flex-1"
    ></ease-metric-simple>
    <ease-metric-simple
      [label]="'Average LMG Credit'"
      [tooltip]="'Average LMG credit per month'"
      [value]="
        fundingSummary?.summary?.avgLmgCredit | currency: 'USD':'symbol':'1.2-2'
      "
      class="flex-1"
    ></ease-metric-simple>
  </ng-container>
</div>
<ng-template #loading>
  <ease-state-loading></ease-state-loading>
</ng-template>
