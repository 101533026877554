import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { SatPopover } from '@ncstate/sat-popover';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { Moment } from 'moment';
import { AccountType } from 'src/app/customers/customer-accounts/customer-accounts.interface';
import { CustomerAccountsService } from 'src/app/customers/customer-accounts/customer-accounts.service';
import { MatSelect } from '@angular/material/select';
import { AccountDashboardService } from 'src/app/accounts/account-dashboard/account-dashboard.service';
import { CustomersService } from '../../../customers/customers.service';
import { TaskService } from '../../../tasks/task.service';
import { TaskModel } from '../../../tasks/task.model';
import { getSnoozeTimes, milliDaysFromNow } from '../../utils/functions';
import { ACCOUNT_FEED_TYPES, SNACKBAR_DURATION_ERROR } from '../../constants';
import { DatepickerComponent } from '../../datepicker/datepicker.component';
import { ListDisplayItem } from '../../shared.interface';
import { EntityUtilsService } from '../../entity-utils.service';
import { WindowService } from '../../window/window.service';

@UntilDestroy()
@Component({
  selector: 'ease-complete-task-popover',
  templateUrl: './complete-task-popover.component.html',
  styleUrls: ['./complete-task-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteTaskPopoverComponent implements OnInit {
  @HostBinding('class') hostClass = 'block';
  @Input()
  set taskId(taskId: string) {
    this._taskId = taskId;
    this.taskIdSource.next(taskId);
  }

  get taskId(): string {
    return this._taskId;
  }
  @Output()
  completeStart: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  completed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('snoozePicker') snoozePicker: DatepickerComponent;
  @ViewChild('snoozeAccountPicker') snoozeAccountPicker: MatSelect;
  @ViewChild('completeMenu') completeMenu: MatSelect;
  public selectedCompleteSetting: string;
  completeOptionSelected(selectedOption) {
    this.selectedCompleteSetting = selectedOption;

    localStorage.setItem('selectedCompleteSetting', selectedOption);
  }
  public snoozeAccounts$: Observable<AccountType[]>;
  public completeTaskForm: FormGroup<{
    completionMessage: FormControl<string>;
    snoozeAccountToggle: FormControl<boolean>;
    snoozeAccountSelectAll: FormControl<boolean>;
    snoozeAccounts: FormControl<AccountType[]>;
    snoozeUntil: FormControl<number | 'custom'>;
    snoozeUntilDate: FormControl<number>;
    cancelScheduledTask: FormControl<boolean>;
    nextCommunicationAt: FormControl<number>;
    hasCommunicated: FormControl<boolean>;
  }>;
  public completionMessage: string;
  public nextCommunicationAt: number;
  public now: Moment = moment();
  public maxSnoozeDate: Moment = moment().add(90, 'days');
  public snoozeTimes: ListDisplayItem[];
  public allSnoozeAccountsSelected: boolean = true;
  public hasSnoozeAccountToggled: boolean = false;
  public isWorking: boolean = false;
  public customerId: string;
  private customerIdSource$: ReplaySubject<string> =
    new ReplaySubject<string>();
  private _taskId: string;
  public task$: Observable<TaskModel>;
  private taskIdSource: ReplaySubject<string> = new ReplaySubject<string>();
  private taskId$: Observable<string>;
  private totalSnoozeAccounts: number;
  public incompleteSubtasks$: Observable<boolean>;
  public isSnoozeable$: Observable<boolean>;

  constructor(
    private accountDashboardService: AccountDashboardService,
    private cdr: ChangeDetectorRef,
    private customerAccountsService: CustomerAccountsService,
    private customerService: CustomersService,
    private entityUtilsService: EntityUtilsService,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private parentPopover: SatPopover,
    private taskService: TaskService,
    private windowService: WindowService
  ) {}

  ngOnInit() {
    if (!localStorage.getItem('selectedCompleteSetting')) {
      localStorage.setItem('selectedCompleteSetting', 'complete');
    }
    this.selectedCompleteSetting = localStorage.getItem(
      'selectedCompleteSetting'
    );

    this.snoozeTimes = getSnoozeTimes();

    this.completeTaskForm = this.formBuilder.group({
      completionMessage: this.formBuilder.control(null),
      snoozeAccountToggle: this.formBuilder.control(null),
      snoozeAccountSelectAll: this.formBuilder.control(
        this.allSnoozeAccountsSelected
      ),
      snoozeAccounts: this.formBuilder.control(
        { value: null, disabled: true },
        { validators: Validators.required }
      ),
      snoozeUntil: this.formBuilder.control(null),
      snoozeUntilDate: this.formBuilder.control(
        { value: null, disabled: true },
        { validators: Validators.required }
      ),
      cancelScheduledTask: this.formBuilder.control(null),
      nextCommunicationAt: this.formBuilder.control(null, {
        validators: Validators.required
      }),
      hasCommunicated: this.formBuilder.control(null, {
        validators: Validators.required
      })
    });

    this.taskId$ = this.taskIdSource.asObservable().pipe(shareReplay(1));

    this.task$ = this.taskId$.pipe(
      switchMap(taskId => this.taskService.get(taskId)),
      switchMap(async task => {
        await this.getCustomerIdForEntity(task);
        await this.handleAccountSnooze(task);
        await this.handleTaskChange(task);
        this.cdr.detectChanges();
        return task;
      }),
      shareReplay(1)
    );

    this.completeTaskForm.controls.snoozeUntil.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(snoozeValue => {
        if (snoozeValue === 'custom') {
          this.completeTaskForm.controls.snoozeUntilDate.enable();
        } else {
          this.completeTaskForm.controls.snoozeUntilDate.disable();
        }
      });

    this.snoozeAccounts$ = this.customerIdSource$.asObservable().pipe(
      tap(customerId => {
        this.isWorking = true;
        this.customerId = customerId;
      }),
      switchMap(customerId =>
        this.customerAccountsService
          .getAccountsOfTypes(customerId, ACCOUNT_FEED_TYPES)
          .pipe(
            map(accounts =>
              accounts.filter(account => account.status === 'ONLINE')
            ),
            tap(filteredAccounts => {
              this.totalSnoozeAccounts = filteredAccounts.length;
              this.completeTaskForm.controls.snoozeAccounts.setValue(
                filteredAccounts
              );
            })
          )
      ),
      tap(() => (this.isWorking = false)),
      take(1)
    );

    this.incompleteSubtasks$ = this.taskId$.pipe(
      switchMap(taskId => this.taskService.getChildren(taskId)),
      map(subtasks => subtasks.some(subtask => !subtask.state))
    );

    this.isSnoozeable$ = this.task$.pipe(
      map(task => this.isSnoozeableTask(task)),
      shareReplay(1)
    );
  }

  async handleTaskChange(task: TaskModel) {
    if (this.completeTaskForm && task) {
      if (this.isTaskOfType(task, 'customerCommunication')) {
        this.completeTaskForm.controls.nextCommunicationAt.enable();
        this.completeTaskForm.controls.hasCommunicated.enable();
        this.completeTaskForm.controls.nextCommunicationAt.setValue(
          moment().startOf('day').add(30, 'days').valueOf()
        );
      } else {
        this.completeTaskForm.controls.nextCommunicationAt.disable();
        this.completeTaskForm.controls.hasCommunicated.disable();
      }

      if (this.isTaskOfType(task, 'scheduledTask')) {
        this.completeTaskForm.controls.cancelScheduledTask.enable();
      } else {
        this.completeTaskForm.controls.cancelScheduledTask.disable();
      }

      await this.handleSnoozeUntil(task);
    }
  }

  async handleSnoozeUntil(task: TaskModel): Promise<void> {
    if (this.isSnoozeableTask(task) || this.hasSnoozeAccountToggled) {
      this.completeTaskForm.controls.snoozeUntil.enable();
    } else {
      this.completeTaskForm.controls.snoozeUntil.disable();
    }
  }

  /**
   * Checks if the task provided is snoozable under the current circumstances:
   * - Task is a budget alert task AND the name includes 'overBudget
   * - Task was created by ease/system
   * - Task is not a scheduled task based on a scheduled template
   *
   * @param task Task to check if it is a snoozable task
   * @returns  True if the task is snoozable, false otherwise
   */
  isSnoozeableTask(task: TaskModel): boolean {
    return (task.taskType === 'budgetAlert' &&
      !task.name.toLowerCase().includes('trending') &&
      task.name.toLowerCase().includes('over budget')) ||
      !task.createdBy ||
      task.scheduledTaskTemplateId
      ? true
      : false;
  }

  async handleAccountSnooze(task: TaskModel): Promise<void> {
    this.completeTaskForm.controls.snoozeAccountToggle.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(async toggleStatus => {
        this.hasSnoozeAccountToggled = toggleStatus;

        if (toggleStatus === true) {
          this.completeTaskForm.controls.snoozeUntil.setValidators([
            Validators.required
          ]);

          // If no snoozeUntil, set default to 30 days for account snoozing
          if (!this.completeTaskForm.controls.snoozeUntil.value) {
            this.completeTaskForm.controls.snoozeUntil.patchValue(30);
          }

          // If account snoozing, reset snoozeUntilDate because only max 90-day is allow
          if (this.completeTaskForm.controls.snoozeUntil.value === 'custom') {
            this.completeTaskForm.controls.snoozeUntilDate.setValue(null);
          }

          this.completeTaskForm.controls.completionMessage.setValidators([
            Validators.required
          ]);
          this.completeTaskForm.controls.snoozeAccounts.enable();
        } else {
          this.completeTaskForm.controls.snoozeUntil.clearValidators();
          this.completeTaskForm.controls.snoozeUntil.setValue(null);

          this.completeTaskForm.controls.completionMessage.clearValidators();
          this.completeTaskForm.controls.snoozeAccounts.disable();
        }

        await this.handleSnoozeUntil(task);
        this.completeTaskForm.controls.snoozeUntil.updateValueAndValidity();
        this.completeTaskForm.controls.completionMessage.updateValueAndValidity();
      });

    this.completeTaskForm.controls.snoozeAccounts.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.snoozeAccountOptionChanged());

    this.completeTaskForm.controls.snoozeAccountSelectAll.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(selectStatus => {
        if (selectStatus) {
          this.snoozeAccountPicker.options.forEach(option => option.select());
        } else {
          this.snoozeAccountPicker.options.forEach(option => option.deselect());
        }
      });
  }

  async completeTask(task?: TaskModel): Promise<any> {
    if (!task) {
      task = await firstValueFrom(this.task$);
    }

    this.completeStart.emit();
    this.isWorking = true;

    if (this.isTaskOfType(task, 'customerCommunication')) {
      const nextCommunicationAt = this.completeTaskForm.get(
        'nextCommunicationAt'
      ).value;
      const hasCommunicated =
        this.completeTaskForm.controls.hasCommunicated.value;

      if (task.entityId && task.entityType) {
        if (task.entityType === 'account') {
          task.entityType = 'customer';
          task.entityId = this.customerId;
          task.accountType = null;
        }

        if (this.customerId) {
          await this.customerService.setCommunicationAt(this.customerId, {
            // only change last communication date when spoke to customer
            lastCommunicationAt: hasCommunicated ? moment().valueOf() : null,
            nextCommunicationAt
          });
        } else {
          this.matSnackBar.open(
            `Couldn't find customer for this task, so can't be completed`,
            'Close',
            {
              duration: SNACKBAR_DURATION_ERROR
            }
          );

          return;
        }
      } else {
        this.matSnackBar.open(
          `You must apply a customer to this task before completing it.`,
          'Close',
          {
            duration: SNACKBAR_DURATION_ERROR
          }
        );

        return;
      }
    }

    let snoozeTaskUntil: number;
    const snoozeUntil = this.completeTaskForm.controls.snoozeUntil.value;

    if (snoozeUntil === 'custom') {
      snoozeTaskUntil = this.completeTaskForm.controls.snoozeUntilDate.value;
    } else if (snoozeUntil) {
      // Convert task snoozeTimes with current time to Firebase timestamp
      const snoozeUntilNowTimestamp = milliDaysFromNow(snoozeUntil);

      snoozeTaskUntil = snoozeUntilNowTimestamp
        ? +snoozeUntilNowTimestamp
        : null;
    }

    const completionMessage =
      this.completeTaskForm.controls.completionMessage.value;

    const cancelScheduledTask = this.completeTaskForm.get(
      'cancelScheduledTask'
    ).value;

    task.completionMessage = completionMessage || null;
    task.cancelScheduledTask = cancelScheduledTask || null;

    // For communication tasks, we internally remove snoozeUntil that set to null
    task.snoozeUntil =
      snoozeTaskUntil && !this.isTaskOfType(task, 'customerCommunication')
        ? snoozeTaskUntil
        : null;

    await this.snoozeAmdAccount();

    // Complete all subtasks
    const subtasks = await firstValueFrom(
      this.taskService.getChildren(task.$key)
    );
    if (subtasks.length) {
      for (const subtask of subtasks) {
        if (!subtask.state) {
          await this.completeTask(subtask);
        }
      }
    }

    await this.taskService.complete(task);

    this.isWorking = false;
    this.completed.emit(true);
    this.cdr.detectChanges();

    if (this.selectedCompleteSetting === 'completeAndClose') {
      await this.windowService.closeTaskWindow(task.$key);
    }
    this.parentPopover.close();
  }

  isTaskOfType(task: TaskModel, type: string): boolean {
    return task && task.taskType === type;
  }

  async getCustomerIdForEntity(task: TaskModel) {
    if (task.entityType === 'account') {
      const entityId = await this.entityUtilsService.getCustomerIdForAccount({
        accountId: task.entityId,
        accountType: task.accountType
      });

      this.customerIdSource$.next(entityId);
    } else {
      this.customerIdSource$.next(task.entityId);
    }
  }

  snoozeAccountOptionChanged(): void {
    let selectStatus: boolean = true;

    if (this.snoozeAccountPicker?.options?.some(option => !option?.selected)) {
      selectStatus = false;
    }

    if (this.allSnoozeAccountsSelected !== selectStatus) {
      this.allSnoozeAccountsSelected = selectStatus;

      this.completeTaskForm.controls.snoozeAccountSelectAll.patchValue(
        selectStatus,
        { emitEvent: false }
      );
    }
  }

  async snoozeAmdAccount(): Promise<void> {
    if (
      this.hasSnoozeAccountToggled &&
      this.completeTaskForm.controls.snoozeAccounts?.value?.length
    ) {
      const duration = this.accountDashboardService.getSnoozeDuration(
        this.completeTaskForm.controls.snoozeUntil.value,
        this.completeTaskForm.controls.snoozeUntilDate.value
      );

      const allSelectedAccounts: AccountType[] =
        this.completeTaskForm.controls.snoozeAccounts?.value;

      const body: string =
        this.completeTaskForm.controls.completionMessage.value;

      // Check the number of selected accounts and total online AMD accounts to
      // run either snoozeAll(customer level) or individual account snoozing
      if (
        this.allSnoozeAccountsSelected &&
        this.totalSnoozeAccounts === allSelectedAccounts.length
      ) {
        // Customer level snoozing
        await this.accountDashboardService.snoozeAccount(
          {
            entityType: 'customer',
            entityId: this.customerId
          },
          {
            body,
            duration
          }
        );
      } else {
        // Account level snoozing
        await Promise.all(
          allSelectedAccounts.map(account =>
            this.accountDashboardService.snoozeAccount(
              {
                entityType: 'account',
                entityId: account.$key,
                accountType: account.$accountType
              },
              {
                body,
                duration
              }
            )
          )
        );
      }
    }
  }
}
