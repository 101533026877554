import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limit'
})
export class LimitPipe implements PipeTransform {
  transform(inputArray: any[], limitCount): any[] {
    if (inputArray && limitCount) {
      return !isNaN(limitCount) ? inputArray.slice(0, limitCount) : inputArray;
    } else if (inputArray && !limitCount) {
      return inputArray;
    } else {
      return [];
    }
  }
}
