import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { ChartOptions } from 'chart.js';

import { CHART_CURRENCY_OPTIONS, CHART_DEFAULT_OPTIONS } from '../charts';
import { ChartMetric } from '../charts.interface';

@Component({
  selector: 'ease-chart-group',
  templateUrl: './chart-group.component.html',
  styleUrls: ['./chart-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartGroupComponent implements OnInit {
  @Input()
  metrics: ChartMetric[];
  @Input()
  set options(options: ChartOptions) {
    if (options) {
      this.chartOptions = Object.assign({}, this.chartOptions, options);
      this.chartOptions = Object.assign({}, this.chartCurrencyOptions, options);
    }
  }
  @Input()
  showTabSummary: boolean = false;
  public chartOptions: ChartOptions = CHART_DEFAULT_OPTIONS;
  public chartCurrencyOptions: ChartOptions = CHART_CURRENCY_OPTIONS;
  constructor() {}

  ngOnInit() {}
}
