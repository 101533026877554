<div class="funding-totals flex flex-row">
  <div class="spend-totals flex flex-1 flex-col items-start justify-center">
    <div
      class="spend-totals_platforms flex flex-row items-center justify-center"
      *ngIf="fundingTotals?.platformSpend?.length"
    >
      <div class="totals-header mr-2">Platform Spend:</div>
      <div class="item-spend-container flex flex-1 flex-row">
        <div
          class="item-spend mr-2 flex flex-row items-center justify-center"
          *ngFor="let item of fundingTotals?.platformSpend"
        >
          <ease-account-icon
            class="mr-1"
            [accountType]="item.spendType"
            [size]="14"
          ></ease-account-icon>
          {{ item.total | currency: 'USD':'symbol':'1.2-2' }}
        </div>
      </div>
    </div>
    <div
      class="spend-totals_management flex flex-row items-center justify-center"
      *ngIf="fundingTotals?.managementSpend?.length"
    >
      <div class="totals-header mr-2">Management Spend:</div>
      <div class="item-spend-container flex flex-1 flex-row">
        <div
          [matTooltip]="item?.fundingEventLineType?.name"
          class="item-spend mr-2 flex flex-row items-center justify-center"
          *ngFor="let item of fundingTotals?.managementSpend"
        >
          <ease-account-icon
            class="mr-1"
            [accountType]="
              fundingService.productCodeToAccountTypeMapping[item.spendType] ||
              'searchKings'
            "
            [size]="14"
          ></ease-account-icon>
          {{ item.total | currency: 'USD':'symbol':'1.2-2' }}
        </div>
      </div>
    </div>
  </div>
  <div
    class="balance"
    [ngClass]="{
      error: fundingTotals?.totalFunding > fundingTotals?.balanceAvailable
    }"
  >
    <ease-metric-simple
      [label]="'Funding / Available Balance'"
      value="{{
        fundingTotals?.totalFunding || 0 | currency: 'USD':'symbol':'1.2-2'
      }} / {{
        fundingTotals?.balanceAvailable | currency: 'USD':'symbol':'1.2-2'
      }}"
      [tooltip]="
        'Total Funding: ' +
        (fundingTotals?.totalFunding || 'NULL') +
        ' -- Available Balance: ' +
        (fundingTotals?.balanceAvailable || 'NULL')
      "
      *ngIf="fundingTotals?.balanceAvailable; else loading"
    ></ease-metric-simple>
  </div>
</div>
<div
  class="disclamer-blocking"
  *ngIf="fundingService.fundingSettings$ | async as fundingSettings"
>
  <ng-container *ngIf="fundingSettings?.managementFee">
    <strong>{{
      fundingSettings.managementFee | currency: 'USD':'symbol':'1.2-2'
    }}</strong>
    reserved for management fee.
  </ng-container>
</div>

<ng-template #loading>
  <div class="list-state-container flex flex-row items-center justify-center">
    <mat-progress-spinner [diameter]="36" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>
