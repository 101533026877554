import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Account } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';

@Component({
  selector: 'ease-com-tab-content',
  templateUrl: './com-tab-content.component.html',
  styleUrls: ['./com-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComTabContentComponent implements OnInit {
  @Input() account: Account;
  public lowestVerticalCPL: number;
  public highestVerticalCPL: number;
  public accountVerticalRank: string;

  constructor(private accountDashboardService: AccountDashboardService) {}

  ngOnInit() {
    if (this.account.comMetric) {
      this.lowestVerticalCPL =
        this.accountDashboardService.getLowestVerticalCPL(
          this.account.comMetric
        );
      this.highestVerticalCPL =
        this.accountDashboardService.getHighestVerticalCPL(
          this.account.comMetric
        );
      this.accountVerticalRank =
        this.accountDashboardService.getAccountVerticalRank(
          this.account.comMetric
        );
    }
  }
}
