<div
  *ngIf="announcement$ | async as announcement"
  class="wrapper flex flex-1 flex-row"
>
  <div class="content flex flex-1 flex-col">
    <div class="header flex flex-row">
      <div class="target-level">
        <ng-container [ngSwitch]="announcement.targetLevel">
          <div *ngSwitchCase="'company'" class="level company">
            <img class="mat-icon-20" src="/assets/images/crown.png" />
          </div>
          <div *ngSwitchCase="'user'" class="level user">
            <mat-icon class="mat-icon-20">portrait</mat-icon>
          </div>
        </ng-container>
      </div>
      <div class="summary">{{ announcement.summary }}</div>
      <button
        (click)="close()"
        class="close inline-block md:!hidden"
        mat-icon-button
      >
        <mat-icon class="mat-icon-20">close</mat-icon>
      </button>
    </div>
    <div class="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
      <div class="body flex-1">
        <div
          class="description-rendered"
          [easeMarkdown]="announcement.details"
        ></div>
        <div class="divider"></div>

        <div class="flex flex-col lg:flex-row">
          <div class="flex-1">
            <div class="label">Created by</div>
            <div class="author">
              <div class="avatar">
                <ease-avatar [userId]="announcement.createdBy.id"></ease-avatar>
              </div>
              <div class="meta">
                <div>{{ announcement.createdBy.name }}</div>
                <div class="date">
                  {{
                    announcement.createdAt
                      | timezoneDateFormat: 'MMMM Do YYYY, h:mm:ss a'
                  }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="announcement.lastUpdateAt" class="flex-1">
            <div class="label">Last updated by</div>
            <div class="author">
              <div class="avatar">
                <ease-avatar
                  [userId]="announcement.lastUpdateBy.id"
                ></ease-avatar>
              </div>
              <div class="meta">
                <div>{{ announcement.lastUpdateBy.name }}</div>
                <div class="date">
                  {{
                    announcement.lastUpdateAt
                      | timezoneDateFormat: 'MMMM Do YYYY, h:mm:ss a'
                  }}
                </div>
              </div>
            </div>
          </div>

          <div
            [matTooltip]="
              'The day the announcement stops showing. Read or unread.'
            "
            matTooltipPosition="left"
            class="flex-1"
          >
            <div class="label">
              Expires at
              <mat-icon class="mat-icon-12"> help_outline </mat-icon>
            </div>
            <div>
              {{ announcement.expiryDate | timezoneDateFormat: 'MMMM Do YYYY' }}
            </div>
          </div>
        </div>

        <div class="label">Applies to</div>
        <ease-announcements-target
          [targetLevel]="announcement.targetLevel"
          [target]="announcement.target"
        ></ease-announcements-target>
      </div>

      <div class="actions">
        <ng-container [ngSwitch]="isMarkedRead">
          <button *ngSwitchCase="true" (click)="markUnread()" mat-raised-button>
            <mat-icon class="mat-icon-20 mr-2">close</mat-icon>
            Mark as unread
          </button>
          <button
            *ngSwitchCase="false"
            (click)="markRead()"
            color="primary"
            mat-raised-button
          >
            <mat-icon class="mat-icon-20 mr-2">check</mat-icon>
            Mark as read
          </button>
        </ng-container>
        <button
          (click)="toggleMarkedBy()"
          class="toggle-mark-by !ml-2 !inline-block md:!hidden"
          mat-raised-button
        >
          <mat-icon class="mat-icon-20">library_add_check</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <ease-announcements-marked-by
    [users]="markedReadUsers"
  ></ease-announcements-marked-by>
</div>
