import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CommonModule } from '@angular/common';
import { StateLoadingComponent } from './state-loading/state-loading.component';
import { StateMessageComponent } from './state-message/state-message.component';

@NgModule({
  imports: [MatIconModule, MatProgressSpinnerModule, CommonModule],
  declarations: [StateLoadingComponent, StateMessageComponent],
  exports: [StateLoadingComponent, StateMessageComponent]
})
export class StatesModule {}
