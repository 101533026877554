<ng-container *ngIf="sharedLayoutService.isCompact$ | async as isCompact">
  <a
    [routerLink]="['/']"
    (click)="onNavigated($event)"
    class="flex h-16 flex-none items-center justify-center"
  >
    <img
      [matTooltip]="homeTooltip"
      [matTooltipPosition]="'right'"
      src="/assets/images/ease-logo-white.svg"
      class="nav-logo__logo w-24"
    />
  </a>

  <div
    class="block w-full flex-auto justify-end overflow-y-auto overflow-x-hidden bg-blue-gray-900"
  >
    <ease-nav-section>
      <ease-nav-section-header>
        <div class="flex-1">Tasks</div>
        <button
          (click)="createTask()"
          mat-icon-button
          [matTooltipPosition]="'right'"
          matTooltip="New Task"
        >
          <mat-icon>add</mat-icon>
        </button>
      </ease-nav-section-header>
      <ease-nav-section-items>
        <ease-nav-item *ngIf="isCollapsed" (click)="createTask()">
          <button
            mat-button
            [matTooltipPosition]="'right'"
            matTooltip="New Task"
          >
            <mat-icon>playlist_add</mat-icon>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/dashboards']"
          routerLinkActive="active"
        >
          <button
            mat-button
            class="!flex flex-row items-center justify-start"
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Tasks"
          >
            <mat-icon>dashboard</mat-icon>
            <span>Tasks</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/tasks/scheduled']"
          routerLinkActive="active"
        >
          <button
            mat-button
            class="!flex flex-row items-center justify-start"
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Scheduled Tasks"
          >
            <mat-icon>alarm</mat-icon>
            <span>Scheduled Tasks</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/tasks/completed']"
          routerLinkActive="active"
        >
          <button
            mat-button
            class="!flex flex-row items-center justify-start"
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Completed Tasks"
          >
            <mat-icon>history</mat-icon>
            <span>Completed Tasks</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          *ngIf="permissionService.hasPermission('allTasks')"
          (click)="onNavigated($event)"
          [routerLink]="['/tasks']"
          routerLinkActive="active"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="All Tasks"
          >
            <mat-icon>filter_list</mat-icon>
            <span>All Tasks</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/boards']"
          routerLinkActive="active"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="By Department"
          >
            <mat-icon>web</mat-icon>
            <span>By Department</span>
          </button>
        </ease-nav-item>
      </ease-nav-section-items>
    </ease-nav-section>
    <ease-nav-section>
      <mat-menu #createMenu="matMenu">
        <button
          mat-menu-item
          (click)="onNavigated($event)"
          [routerLink]="['/customers', 'create']"
          [queryParams]="{ phase: 'prospect' }"
          routerLinkActive="active"
          [routerLinkActiveOptions]="routerLinkActiveOptions"
        >
          New Prospect
        </button>
        <button
          mat-menu-item
          (click)="onNavigated($event)"
          [routerLink]="['/customers', 'create']"
          [queryParams]="{ phase: 'customer' }"
          routerLinkActive="active"
          [routerLinkActiveOptions]="routerLinkActiveOptions"
        >
          New Customer
        </button>
      </mat-menu>

      <ng-template #createAsideMenu let-data>
        <button
          mat-button
          class="mat-button-aside-menu"
          [ngClass]="data.buttonType"
          [matTooltipPosition]="'right'"
          [matTooltip]="'New Prospect / Customer'"
          (click)="menuTrigger.openMenu()"
        >
          <mat-icon>{{ data.icon }}</mat-icon>
          <div
            #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="createMenu"
          ></div>
        </button>
      </ng-template>

      <ease-nav-section-header>
        <div class="flex-1">Customers</div>
        <ng-container
          [ngTemplateOutlet]="createAsideMenu"
          [ngTemplateOutletContext]="{
            $implicit: {
              icon: 'add',
              buttonType: 'mat-icon-button'
            }
          }"
        ></ng-container>
      </ease-nav-section-header>
      <ease-nav-section-items>
        <ease-nav-item *ngIf="isCollapsed">
          <ng-container
            [ngTemplateOutlet]="createAsideMenu"
            [ngTemplateOutletContext]="{
              $implicit: {
                icon: 'person_add',
                buttonType: 'mat-button'
              }
            }"
          ></ng-container>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/customers']"
          [queryParams]="{ phase: 'prospect' }"
          routerLinkActive="active"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Prospects"
          >
            <mat-icon>ring_volume</mat-icon>
            <span>Prospects</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/customers']"
          [queryParams]="{ phase: 'customer' }"
          routerLinkActive="active"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Customers"
          >
            <mat-icon>person_pin</mat-icon>
            <span>Customers</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/funding']"
          routerLinkActive="active"
          *ngIf="permissionService.hasPermission('accessFundingList')"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Funding"
          >
            <mat-icon>card_giftcard</mat-icon>
            <span>Funding</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/onboarding']"
          routerLinkActive="active"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Onboarding"
          >
            <mat-icon>source</mat-icon>
            <span>Onboarding</span>
          </button>
        </ease-nav-item>
      </ease-nav-section-items>
    </ease-nav-section>

    <ease-nav-section>
      <ease-nav-section-header>
        <div class="flex-1">Accounts</div>
        <button
          (click)="openAdwordsManagerLinksDialog()"
          mat-icon-button
          [matTooltipPosition]="'right'"
          matTooltip="Ads / GLS Link Requests"
        >
          <mat-icon>add_link</mat-icon>
        </button>
      </ease-nav-section-header>
      <ease-nav-section-items>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/accounts/dashboard']"
          routerLinkActive="active"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Dashboard"
          >
            <mat-icon>dashboard</mat-icon>
            <span>Dashboard</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/accounts']"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="active"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Accounts"
          >
            <mat-icon>device_hub</mat-icon>
            <span>Accounts</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/negative-keywords']"
          routerLinkActive="active"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Negative Keywords"
          >
            <mat-icon>not_interested</mat-icon>
            <span>Negative Keyword Manager</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/jobs']"
          routerLinkActive="active"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Build Jobs"
          >
            <mat-icon>hourglass_empty</mat-icon>
            <span>Build Jobs</span>
          </button>
        </ease-nav-item>
      </ease-nav-section-items>
    </ease-nav-section>

    <ease-nav-section
      *ngIf="permissionService.hasPermission('accessContactsList')"
    >
      <ease-nav-section-header>Contacts</ease-nav-section-header>
      <ease-nav-section-items>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/contacts']"
          routerLinkActive="active"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Contacts"
          >
            <mat-icon>contacts</mat-icon>
            <span>Contacts</span>
          </button>
        </ease-nav-item>
      </ease-nav-section-items>
    </ease-nav-section>

    <ease-nav-section
      *ngIf="
        permissionService.hasPermission('accessTaskTemplates') ||
        permissionService.hasPermission('accessUserManagement') ||
        permissionService.hasPermission('accessChecklistManager') ||
        permissionService.hasPermission('accessMetadata')
      "
    >
      <ease-nav-section-header>
        Admin
        <mat-icon
          class="mat-icon-14 ml-1"
          matTooltip="Only visible to administrators"
          >lock</mat-icon
        >
      </ease-nav-section-header>
      <ease-nav-section-items>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/admin/announcements']"
          routerLinkActive="active"
          *ngIf="permissionService.hasPermission('manageAnnouncements')"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Announcements"
          >
            <mat-icon>campaign</mat-icon>
            <span>Announcements</span>
          </button>
        </ease-nav-item>

        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/admin/roles']"
          routerLinkActive="active"
          *ngIf="permissionService.hasPermission('accessRolesAndPermissions')"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Roles & Permissions"
          >
            <mat-icon>security</mat-icon>
            <span>Roles & Permissions</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/admin/task-templates']"
          routerLinkActive="active"
          *ngIf="permissionService.hasPermission('accessTaskTemplates')"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Task Templates"
          >
            <mat-icon>file_copy</mat-icon>
            <span>Task Templates</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/admin/email-templates']"
          routerLinkActive="active"
          *ngIf="permissionService.hasPermission('accessEmailTemplates')"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Email Templates"
          >
            <mat-icon>email</mat-icon>
            <span>Email Templates</span>
          </button>
        </ease-nav-item>

        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/admin/users']"
          routerLinkActive="active"
          *ngIf="permissionService.hasPermission('accessUserManagement')"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Users Manager"
          >
            <mat-icon>portrait</mat-icon>
            <span>Users Manager</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/admin/checklists']"
          routerLinkActive="active"
          *ngIf="permissionService.hasPermission('accessChecklistManager')"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Checklist Manager"
          >
            <mat-icon>list</mat-icon>
            <span>Checklist Manager</span>
          </button>
        </ease-nav-item>
        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/admin/metadata']"
          routerLinkActive="active"
          *ngIf="permissionService.hasPermission('accessMetadata')"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Metadata"
          >
            <mat-icon>scatter_plot</mat-icon>
            <span>Metadata</span>
          </button>
        </ease-nav-item>

        <ease-nav-item
          (click)="onNavigated($event)"
          [routerLink]="['/admin/onboarding']"
          routerLinkActive="active"
          *ngIf="permissionService.hasPermission('accessOnboarding')"
        >
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Onboarding Settings"
          >
            <mat-icon>folder</mat-icon>
            <span>Onboarding</span>
          </button>
        </ease-nav-item>
      </ease-nav-section-items>
    </ease-nav-section>
  </div>

  <div class="relative flex-1 bg-blue-gray-900"></div>

  <ease-nav-section
    [ngClass]="{
      '!pt-0 ': !isCollapsed,
      '!pb-2': isCompact.active
    }"
  >
    <ease-nav-section-items>
      <ease-nav-item class="relative !h-auto">
        <ng-container *ngIf="userService.currentUser$ | async as user">
          <div
            class="h-full lg:h-auto"
            (click)="userProfileDialog.toggle()"
            [satPopoverAnchor]="userProfileDialog"
          >
            <button
              mat-button
              matTooltip="User Profile"
              matTooltipPosition="right"
              class="!inline-block"
              [ngClass]="{
                '!hidden': !isCollapsed
              }"
            >
              <mat-icon>face</mat-icon>
              <span>You</span>
            </button>

            <div
              class="flex cursor-pointer items-center p-4"
              [ngClass]="{
                '!hidden': isCollapsed
              }"
            >
              <ease-avatar
                class="mr-2"
                [userId]="user.$key"
                [size]="'medium'"
                [status]="{
                  mode: user?.mode,
                  borderColor: 'border-blue-gray-900'
                }"
              ></ease-avatar>

              <div class="truncate">
                <div class="truncate font-medium leading-5 text-gray-100">
                  {{ user.name }}
                </div>
                <div class="truncate text-sm text-gray-400">
                  {{ user.email }}
                </div>
              </div>
            </div>
          </div>

          <sat-popover
            panelClass="user-profile-dialog"
            verticalAlign="center"
            horizontalAlign="end"
            [autoFocus]="false"
            [hasBackdrop]="true"
            #userProfileDialog
          >
            <ease-user-profile-dialog
              [user]="user"
              [showUserInfo]="isCollapsed"
              (navigated)="onNavigated($event)"
            ></ease-user-profile-dialog>
          </sat-popover>
        </ng-container>
      </ease-nav-item>

      <ease-nav-item (click)="openKeyboardDialog()" *ngIf="!isCompact.active">
        <button
          mat-button
          [matTooltipPosition]="'right'"
          matTooltip="Press Shift + ? to view keyboard shortcuts"
        >
          <mat-icon>keyboard</mat-icon>
          <span>Keyboard Shortcuts</span>
        </button>
      </ease-nav-item>
    </ease-nav-section-items>
  </ease-nav-section>

  <ease-nav-section *ngIf="!isCompact.active">
    <ease-nav-section-items>
      <ng-container>
        <ease-nav-item (click)="toggleCollapse()">
          <button
            mat-button
            [matTooltipDisabled]="!isCollapsed"
            [matTooltipPosition]="'right'"
            matTooltip="Expand"
          >
            <mat-icon>{{
              isCollapsed ? 'chevron_right' : 'chevron_left'
            }}</mat-icon>
            <span *ngIf="!isCollapsed">Collapse</span>
          </button>
        </ease-nav-item>
      </ng-container>
    </ease-nav-section-items>
  </ease-nav-section>
</ng-container>
