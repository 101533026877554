<div
  class="metric row details flex flex-col"
  [ngClass]="callQualityMetric?.ragStatusColor"
>
  <!--header-->
  <div class="row header flex flex-1 flex-row">
    <div class="column cell flex flex-row items-center justify-center">
      <div class="graph flex flex-col">
        <div class="dot flex flex-row">
          <div class="indicator metric-background"></div>
        </div>
      </div>

      <div class="status-summary flex-1">
        {{ callQualityMetric?.ragStatusDescription }}
      </div>
    </div>
  </div>
  <!--details-->
  <div class="detail-summary row flex flex-row">
    <div
      class="detail-summary-group column detail flex max-w-[60%] flex-full flex-row"
    >
      <div
        class="column cell detail flex flex-1 flex-col items-center justify-center"
      >
        <ease-metric-simple
          label="Call Quality"
          [value]="callQualityMetric.callQuality + '%'"
          [differenceNumeric]="
            highQualityCalls +
            ' of ' +
            callQualityMetric.totalUniqueCalls +
            ' unique calls were good'
          "
        ></ease-metric-simple>
      </div>
    </div>
    <div
      class="column detail max-w-[40%] flex-full"
      *ngIf="account.cqMetric?.metadata?.chartData.labels.length"
    >
      <ease-chart
        [height]="130"
        [data]="account.cqMetric?.metadata?.chartData"
        [type]="'line'"
        [options]="accountDashboardService.percentageChartOptions"
      >
      </ease-chart>
    </div>
  </div>
</div>
