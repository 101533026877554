<ease-sheet class="popover">
  <ease-popover-header>Select Task to attach</ease-popover-header>
  <ng-container *ngIf="openTasks$ | async as openTasks; else loading">
    <ng-container *ngIf="openTasks?.length; else emptyOpen">
      <ease-list-selector
        [items]="openTasks$ | async"
        [selectedItems]="[]"
        [sortFields]="['name', 'createdAt']"
      >
        <ng-template let-task="item" let-selected="selected">
          <ease-task-panel-compact
            [task]="task"
            [actionsDisabled]="true"
            (click)="makeParent(task)"
          >
          </ease-task-panel-compact>
        </ng-template>
      </ease-list-selector>
    </ng-container>
  </ng-container>
</ease-sheet>

<ng-template #loading>
  <div class="flex flex-row items-center justify-center p-5 text-center">
    <mat-progress-spinner [diameter]="24" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<ng-template #emptyOpen>
  <div class="p-5 text-center">No tasks available to attach</div>
</ng-template>

<ng-template #emptyScheduled>
  <div class="p-5 text-center">No scheduled tasks</div>
</ng-template>
