<div
  class="checklist-checkbox group relative my-1 border-y border-gray-200 border-transparent md:my-0"
  [ngClass]="{
    'checklist-checkbox--disabled': group?.controls?.disabled?.value,
    'checklist-checkbox--checked ': group?.controls?.checked?.value,
    'bg-transparent hover:rounded-lg md:hover:bg-gray-100':
      !group?.controls?.checked?.disabled
  }"
  [formGroup]="group"
>
  <div
    class="flex w-full flex-col content-start justify-between text-xs md:min-h-[31px] md:flex-row md:content-center md:items-center md:text-sm"
  >
    <div class="flex w-full flex-wrap items-start">
      <div
        class="flex flex-1 flex-row items-center justify-between md:flex-row md:justify-start"
      >
        <div class="flex">
          <mat-icon
            matTooltip="Checking this box will perform the action described automatically"
            *ngIf="templateOptions?.actionKey"
            class="action-icon -ml-1 mr-1.5 -mt-0.5 !w-full !max-w-max cursor-help md:mt-0"
            >offline_bolt</mat-icon
          >
          <mat-checkbox
            #checkbox
            [checked]="group?.controls?.checked?.value"
            [disabled]="group?.controls?.checked?.disabled"
            (click)="clickCheckbox(checkbox)"
            color="primary"
            class="!self-center"
          >
            <div
              [ngClass]="{
                'line-through decoration-gray-600':
                  group?.controls?.checked?.value
              }"
              [easeMarkdown]="templateOptions?.label"
              [inline]="true"
            ></div>
          </mat-checkbox>
        </div>
        <button
          mat-icon-button
          [matMenuTriggerFor]="itemSettings"
          class="!ml-5 block !h-max !w-max self-start md:!hidden"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <div
          class="pointer-events-auto isolate z-5 -mt-px hidden items-center justify-center rounded-sm !no-underline opacity-100 group-hover:opacity-100 md:mt-0 md:ml-2 md:flex md:opacity-0"
          *ngIf="!group?.controls?.checked?.disabled"
        >
          <button
            (click)="addNote()"
            mat-icon-button
            title="{{
              group?.controls?.note?.controls?.body?.value
                ? 'Edit Note'
                : 'Add Note'
            }}"
            class="!h-7 !w-8 justify-end"
          >
            <mat-icon class="mat-icon-18">add_comment</mat-icon>
          </button>
          <button
            (click)="toggleDisabled()"
            mat-icon-button
            title="{{
              group?.controls?.disabled?.value ? 'Restore' : 'Not Applicable'
            }}"
            class="!h-7 !w-8 justify-end"
          >
            <mat-icon
              [ngClass]="
                group?.controls?.disabled?.value ? 'mat-icon-20' : 'mat-icon-18'
              "
              >{{
                group?.controls?.disabled?.value
                  ? 'settings_backup_restore'
                  : 'block'
              }}</mat-icon
            >
          </button>
        </div>
      </div>
      <div
        class="mb-1 mt-1 ml-6 w-full max-w-full flex-full"
        [ngClass]="{ '!ml-12': templateOptions?.actionKey }"
        *ngIf="!addingNote && group?.controls?.note?.controls?.body?.value"
      >
        <div
          class="flex w-full max-w-max items-center rounded-xl bg-blue-500 text-white md:mb-2"
        >
          <span
            class="max-w-md whitespace-normal break-words break-all border-r border-gray-100 py-1.5 pr-2 pl-3 text-xs leading-none md:py-0.5 md:text-sm"
            >{{ group?.controls.note.controls.body.value }}</span
          >
          <span
            class="ml-2 w-full max-w-max flex-full flex-shrink-0 flex-grow-0 pr-2.5 text-xs"
            >{{ group?.controls?.note?.controls?.createdBy?.value }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="checklist-meta"
      [ngClass]="
        !group?.controls?.note?.controls?.body?.value
          ? 'md:!self-center'
          : 'md:mt-1'
      "
    >
      <div
        class="ml-6 flex items-center"
        [ngClass]="{ '!ml-[29px] md:ml-0': templateOptions?.actionKey }"
        *ngIf="group?.controls?.completedBy?.value"
      >
        <div>{{ group.controls.completedBy.value }}</div>
      </div>
      <div
        *ngIf="group?.controls?.completedAt?.value"
        class="flex items-center text-[11px] leading-snug text-gray-600"
      >
        <mat-icon class="mat-icon-12 mr-0.5 mb-px !hidden md:!block"
          >done</mat-icon
        >
        {{ group.controls.completedAt.value | amDateFormat: 'MMM D, h:mm a' }}
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="
      templateOptions?.details &&
      !group?.controls?.checked?.value &&
      !group?.controls?.disabled?.value
    "
  >
    <ul class="m-0 block flex-full list-disc pl-10 pb-2.5 pr-6 md:pr-1.5">
      <li
        *ngFor="let detail of templateOptions?.details"
        [ngClass]="detail?.class"
      >
        <span [easeMarkdown]="detail?.body" size="sm"></span>
      </li>
    </ul>
  </ng-container>
</div>

<form
  class="px-6 pt-3 pb-4"
  *ngIf="addingNote"
  (ngSubmit)="saveNote(noteInput?.value)"
>
  <mat-form-field>
    <input
      easeAutofocus
      #noteInput
      [value]="group?.controls?.note?.controls?.body?.value"
      placeholder="Add a note"
      matInput
      class="text-sm md:text-base"
    />
  </mat-form-field>
  <button type="button" (click)="cancelNote()" mat-button>CANCEL</button>
  <button type="submit" mat-button>
    {{ group?.controls?.note?.controls?.body?.value ? 'UPDATE' : 'ADD' }}
  </button>
</form>
<mat-menu class="block w-max md:!hidden" #itemSettings="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="addNote()">
      <mat-icon>add_comment</mat-icon>
      <span>{{
        group?.controls?.note?.controls?.body?.value ? 'Edit Note' : 'Add Note'
      }}</span>
    </button>
    <button mat-menu-item (click)="toggleDisabled()">
      <mat-icon>{{
        group?.controls?.disabled?.value ? 'settings_backup_restore' : 'block'
      }}</mat-icon>
      <span>{{
        group?.controls?.disabled?.value ? 'Restore' : 'Not Applicable'
      }}</span>
    </button>
  </ng-template>
</mat-menu>
