import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ProseSize } from '../markdown.interface';

const MARKDOWN_FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MarkdownFormComponent),
  multi: true
};

@Component({
  selector: 'ease-markdown-form',
  templateUrl: './markdown-form.component.html',
  providers: [MARKDOWN_FORM_CONTROL_VALUE_ACCESSOR]
})
export class MarkdownFormComponent implements ControlValueAccessor {
  @Input()
  persistenceKey: string;
  @Input()
  allowMentions: boolean = false;
  @Input()
  saveText: string = 'Save';
  @Input()
  cancelText: string = 'Cancel';
  @Input()
  hideActionsUntilEntry: boolean = false;
  @Input()
  placeholder: string = 'Start typing here...';
  @Input()
  disabled: boolean;
  @Input() size: ProseSize;
  @Input() autoCollapse: boolean;
  @Output()
  save: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  cancel: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('markdownEditor', { static: true })
  markdownEditor;
  private originalValue: string = '';
  public currentValue: string = '';

  constructor(private cdr: ChangeDetectorRef) {}

  onSave() {
    this.emitChange(this.currentValue);
    this.save.emit(this.currentValue);
  }

  onRevert() {
    this.currentValue = this.originalValue;
    this.emitChange(this.currentValue);
    this.cancel.emit(this.currentValue);
  }

  clear() {
    this.markdownEditor.clear();
  }

  emitChange(value: string) {
    this.onChange(value);
    this.cdr.markForCheck();
  }

  /** Implemented as part of ControlValueAccessor. */
  onChange: (value) => any = () => {
    /* */
  };
  onTouched: () => any = () => {
    /* */
  };

  writeValue(value: any) {
    if (value) {
      this.currentValue = value;
      this.originalValue = value;
      this.cdr.detectChanges();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
