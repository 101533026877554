<ease-sheet
  class="popover popover-metric shadow-md"
  [ngClass]="costPerLeadMetric.ragStatusColor"
>
  <div class="header metric-background">
    <span class="description" *ngIf="costPerLeadMetric.ragStatusDescription">
      {{ costPerLeadMetric.ragStatusDescription }}
    </span>

    <span class="description" *ngIf="!costPerLeadMetric.ragStatusDescription">
      Not enough data to calculate current CPL
    </span>
  </div>
  <div class="details">
    <div class="row flex flex-row">
      <div class="column detail flex-1">
        <ease-metric-simple
          [label]="'Account CPL'"
          [tooltip]="
            'The account\'s accrued spend divided by the number of leads for the last 14 days.'
          "
          [value]="costPerLeadMetric.cpl | currency: 'USD':'symbol':'1.0-2'"
          [differenceNumeric]="
            costPerLeadMetric.cpl && costPerLeadMetric.cplTarget
              ? (costPerLeadMetric.cpl - costPerLeadMetric.cplTarget
                | currency: 'USD':'symbol':'1.0-2')
              : null
          "
          [differencePercentage]="costPerLeadMetric.percentageSwingFromTarget"
        ></ease-metric-simple>
      </div>
      <div
        class="column detail flex flex-1 flex-col items-center justify-center"
      >
        <ease-metric-simple
          *ngIf="!editingTarget; else editField"
          [label]="'Target CPL'"
          [value]="account.targetCpl | currency: 'USD':'symbol':'1.0-2'"
        ></ease-metric-simple>
        <ng-template #editField>
          <div class="metric-form flex flex-col items-center justify-center">
            <mat-form-field class="cpl-form">
              <input
                matInput
                placeholder="Target CPL"
                type="number"
                [formControl]="targetCPLControl"
                matTooltip="Set 0 to disable this account's CPL checks"
              />
              <mat-error *ngIf="targetCPLControl.invalid"
                >Min value is 0</mat-error
              >
            </mat-form-field>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="actions row flex flex-row">
      <button
        *ngIf="!editingTarget; else editActions"
        (click)="editingTarget = true"
        mat-raised-button
        class="flex-1"
      >
        <mat-icon class="mr-2">vertical_align_center</mat-icon>
        Edit Target CPL
      </button>
      <ng-template #editActions>
        <button
          [disabled]="targetCPLControl.invalid"
          (click)="saveTargetCPL()"
          class="mr-2 flex-1"
          color="primary"
          mat-raised-button
        >
          <mat-icon class="mr-2">save</mat-icon>
          Save Target CPL
        </button>
        <button (click)="cancelTargetCPL()" mat-raised-button>
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </div>
    <div
      class="row"
      *ngIf="account?.targetCpl !== targetCplNumber && targetCplNumber !== 0"
    >
      <div class="note-container">
        <div class="metric-note">
          New Target CPL (<strong>{{
            account.targetCpl | currency: 'USD':'symbol':'1.0-2'
          }}</strong
          >) will take 24h to be reflected in this calculation.
        </div>
      </div>
    </div>
    <div class="row">
      <ease-metric-list
        [list]="[
          {
            label: 'Last 7 Days',
            value:
              (costPerLeadMetric?.metadata?.last7
                | currency: 'USD':'symbol':'1.0-2') || 'Not Available'
          },
          {
            label: 'Last 14 Days',
            value:
              (costPerLeadMetric?.metadata?.last14
                | currency: 'USD':'symbol':'1.0-2') || 'Not Available'
          },
          {
            label: 'Last 30 Days',
            value:
              (costPerLeadMetric?.metadata?.last30
                | currency: 'USD':'symbol':'1.0-2') || 'Not Available'
          }
        ]"
      ></ease-metric-list>
    </div>
    <div
      class="row"
      *ngIf="costPerLeadMetric?.metadata?.chartData.labels.length"
    >
      <ease-chart
        [height]="200"
        [data]="costPerLeadMetric?.metadata?.chartData"
        [type]="'line'"
        [options]="accountDashboardService.currencyChartOptions"
      >
      </ease-chart>
    </div>
  </div>
</ease-sheet>
