import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { SharedPipesModule } from '../shared-pipes.module';
import { AdvancedMultiSelectorComponent } from './advanced-multi-selector.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
    SharedPipesModule,
    VirtualScrollerModule
  ],
  declarations: [AdvancedMultiSelectorComponent],
  exports: [AdvancedMultiSelectorComponent]
})
export class AdvancedMultiSelectorModule {}
