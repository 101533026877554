import * as moment from 'moment';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { Moment } from 'moment';

import { getSnoozeTimes } from 'src/app/shared/utils/functions';
import {
  EntityMetadata,
  ListDisplayItem
} from '../../../../shared/shared.interface';
import { Account } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';

@UntilDestroy()
@Component({
  selector: 'ease-snooze-popover',
  templateUrl: './snooze-popover.component.html',
  styleUrls: ['./snooze-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnoozePopoverComponent implements OnInit {
  @Input() account: Account;
  @Input() cellParams: ICellRendererParams;
  @Output() snoozeSuccess: EventEmitter<void> = new EventEmitter<void>();
  @Output() showRelatedAccounts: EventEmitter<string> =
    new EventEmitter<string>();
  public snoozeTimes: ListDisplayItem[];
  public snoozeForm: FormGroup<{
    body: FormControl<string | null>;
    duration: FormControl<number | 'custom'>;
    snoozeDate: FormControl<number>;
  }> = this.formBuilder.group({
    body: this.formBuilder.control(null, { validators: Validators.required }),
    duration: this.formBuilder.control(
      14 as typeof this.snoozeForm.controls.duration.value,
      {
        validators: Validators.required
      }
    ),
    snoozeDate: this.formBuilder.control(
      { value: null, disabled: true },
      { validators: Validators.required }
    )
  });
  public snoozeMetadata: EntityMetadata;
  public relatedAccounts: number;
  public now: Moment = moment();
  public maxSnoozeDate: Moment = moment().add(90, 'days');

  constructor(
    private formBuilder: FormBuilder,
    private accountDashboardService: AccountDashboardService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.snoozeTimes = getSnoozeTimes();

    this.snoozeForm.controls.duration.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(snoozeValue => {
          if (snoozeValue === 'custom') {
            this.snoozeForm.controls.snoozeDate.enable();
          } else {
            this.snoozeForm.controls.snoozeDate.disable();
          }
        })
      )
      .subscribe();

    this.snoozeMetadata = this.getSnoozeEntityMetadata();
    this.relatedAccounts = this.getRelatedAccounts();
  }

  async snoozeAccount(): Promise<any> {
    if (this.snoozeForm.valid) {
      const duration = this.snoozeForm.controls.duration.value;
      const snoozeDate = this.snoozeForm.controls.snoozeDate.value;
      const snoozeDuration = this.accountDashboardService.getSnoozeDuration(
        duration,
        snoozeDate
      );

      await this.accountDashboardService.snoozeAccount(this.snoozeMetadata, {
        body: this.snoozeForm.controls.body.value,
        duration: snoozeDuration
      });

      this.snoozeSuccess.emit();
      this.matSnackBar.open(
        `${this.snoozeMetadata.entityName} ${
          this.account ? 'account' : 'accounts'
        } snoozed for ${duration} Days`,
        'Close'
      );
    }
  }

  getRelatedAccounts(): number {
    if (this.account) {
      const accountRows: RowNode[] = [];
      this.cellParams.api.forEachNode(row => {
        if (!row.group && row.data.customerId === this.account.customerId) {
          accountRows.push(row);
        }
      });
      return accountRows.length;
    } else {
      return this.cellParams?.node.allChildrenCount;
    }
  }

  onShowRelatedAccounts() {
    this.showRelatedAccounts.emit();
  }

  getSnoozeEntityMetadata(): EntityMetadata {
    // Get customer info from account RowNodes
    const customerAccountRows: RowNode[] =
      this.cellParams?.node?.allLeafChildren;

    if (!this.account && customerAccountRows?.length) {
      // Customer level snooze metadata
      return {
        entityType: 'customer',
        entityId: customerAccountRows[0]?.data?.customerId,
        entityName: customerAccountRows[0]?.data?.customerName
      };
    } else {
      // Account level snooze metadata
      return {
        entityType: 'account',
        entityId: this.account.accountId,
        entityName: this.account.customerName,
        accountType: this.account.accountType
      };
    }
  }
}
