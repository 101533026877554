import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EaseMaterialModule } from '../ease-material.module';
import { InplaceEditComponent } from './inplace-edit.component';

@NgModule({
  imports: [CommonModule, EaseMaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [InplaceEditComponent],
  exports: [InplaceEditComponent]
})
export class InplaceEditModule {}
