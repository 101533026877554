<ease-vertical-tab-group
  #tabGroup
  [canToggleState]="true"
  (toggled)="onTabToggle($event)"
  class="h-full bg-gray-200"
>
  <ease-vertical-tab label="Task Notes" icon="speaker_notes">
    <ease-task-notes
      [taskId]="task.$key"
      [completed]="!!task?.completedAt"
    ></ease-task-notes>
  </ease-vertical-tab>
  <ease-vertical-tab
    *ngIf="task?.entityId"
    label="Customer Feed"
    icon="person_pin"
  >
    <ease-customer-feed
      class="h-full min-h-0 overflow-auto"
      [viewingEntity]="viewingEntity$ | async"
    >
    </ease-customer-feed>
  </ease-vertical-tab>

  <ease-vertical-tab label="Project Overview" icon="subdirectory_arrow_right">
    <ng-template #tabCustomTemplate>
      <div class="text-gray-600">
        <mat-icon>folder</mat-icon>
        <div
          class="flex flex-row items-center justify-center rounded-full bg-gray-300 px-2 text-xs font-bold text-gray-500"
          *ngIf="projectProgress$ | async as progress"
        >
          {{ progress.completed }}/{{ progress.total }}
        </div>
      </div>
    </ng-template>
    <ease-project-overview
      class="h-full min-h-0"
      [task]="task"
    ></ease-project-overview>
  </ease-vertical-tab>
</ease-vertical-tab-group>
