import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ease-adblock-detector-dialog',
  templateUrl: './adblock-detector-dialog.component.html',
  styleUrls: ['./adblock-detector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdblockDetectorDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  reload(): void {
    // reload without cache
    return window.location.reload();
  }
}
