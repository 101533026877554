<ng-container *ngIf="optionSettings$ | async as optionSettings">
  <mat-form-field
    *ngIf="!(disabled && displayStyle === 'inline')"
    [floatLabel]="displayStyle === 'inline' ? 'never' : 'auto'"
    [ngClass]="{
      'display-style--inline': displayStyle === 'inline',
      'display-style--form': displayStyle === 'form',
      'is--populated': currentPlan,
      'is--empty': !currentPlan,
      'has--plan-text': displayPlanText
    }"
  >
    <mat-select
      placeholder="Plan"
      (selectionChange)="onPlanChange($event)"
      [(ngModel)]="currentPlan"
      [disabled]="disabled"
    >
      <mat-select-trigger>
        <span>
          <ease-color-blob
            [color]="optionSettings[currentPlan]?.color"
            [size]="'xs'"
            >{{ optionSettings[currentPlan]?.name }}</ease-color-blob
          >
        </span>
      </mat-select-trigger>
      <mat-option class="plan-option" [value]="">
        <ease-color-blob [hollow]="true"></ease-color-blob>
        No Plan
      </mat-option>
      <mat-option
        class="plan-option"
        *ngFor="let plan of options$ | async"
        [value]="plan.value"
      >
        <ease-color-blob [color]="plan?.color"></ease-color-blob>
        {{ plan.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="disabled && displayStyle === 'inline'">
    <ease-color-blob
      [hollow]="!!!currentPlan"
      [color]="optionSettings[currentPlan]?.color"
      [size]="'xs'"
    >
      <span *ngIf="displayPlanText">{{
        currentPlan ? optionSettings[currentPlan]?.name : 'No Plan'
      }}</span>
    </ease-color-blob>
  </ng-container>
</ng-container>
