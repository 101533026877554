<div class="flex flex-row items-start justify-start">
  <ease-avatar [userId]="task?.completedBy" [size]="'large'"> </ease-avatar>
  <div class="ml-6 flex-1">
    <ease-toggle #messageToggle="toggle">
      <div
        *ngIf="messageToggle.isHidden"
        [easeMarkdown]="task?.completionMessage"
      ></div>
      <ease-markdown-form
        [allowMentions]="true"
        *ngIf="!messageToggle.isHidden"
        (save)="updateMessage($event); messageToggle.hide()"
        (cancel)="messageToggle.hide()"
        [(ngModel)]="task.completionMessage"
        size="sm"
      >
      </ease-markdown-form>
    </ease-toggle>

    <div
      class="flex flex-col items-start justify-start text-[13px] text-gray-400 md:flex-row md:items-center"
    >
      <span
        class="mr-2 flex flex-row items-center justify-start"
        title="Completed"
      >
        <mat-icon class="mat-icon-18 mr-1">done</mat-icon>
        {{ users[task?.completedBy]?.name }} completed
        {{ task?.completedAt | amDateFormat: 'MMM D YYYY' }}
      </span>
      <span
        class="flex flex-row items-center justify-start"
        *ngIf="task?.snoozeUntil"
        title="Snoozed"
      >
        <mat-icon class="mat-icon-18 mr-1">snooze</mat-icon>
        {{ task?.snoozeUntil | amDateFormat: 'MMM DD' }}
      </span>

      <button
        *ngIf="isEditable && messageToggle?.isHidden"
        (click)="messageToggle.show()"
        class="!mt-2 font-medium !text-gray-600 md:!mt-0 md:!ml-3"
        mat-stroked-button
      >
        <mat-icon class="mat-icon-18 mr-2">edit</mat-icon>
        <span>{{ task?.completionMessage ? 'Edit' : 'Add' }} Message</span>
      </button>
    </div>
  </div>
</div>
