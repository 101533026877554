import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  GridEntity,
  GridTarget,
  GridTargetingState,
  GridTargetRow
} from '../../grid-toolbar/grid-targeting/grid-targeting.interface';
import { GridTargetingService } from '../../grid-toolbar/grid-targeting/grid-targeting.service';
import { LOGO_FILE_FOR_ACCOUNT_TYPE } from '../../constants';
import { WindowPane } from '../../window/window-pane/window-pane';
import { TargetMember } from '../bulk-targeting.interface';

@Component({
  selector: 'ease-bulk-targeting-method-table',
  templateUrl: './bulk-targeting-method-table.component.html',
  styleUrls: ['./bulk-targeting-method-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkTargetingMethodTableComponent implements OnInit {
  @Input() entity: GridEntity = 'customer';
  @Output() valueChanged: EventEmitter<TargetMember[]> = new EventEmitter<
    TargetMember[]
  >();
  set data(data: GridTarget) {
    this._data = data;
    this.rowData = data ? data.rowData : [];

    this.valueChanged.emit(this.data.rowData);
  }
  get data(): GridTarget {
    return this._data;
  }
  private _data: GridTarget;
  public state$: Observable<GridTargetingState>;
  public MAX_FILTERS: number = 5;

  public rowData: GridTargetRow[];
  public gridOptions: GridOptions;
  public columnDefs: ColDef[];

  constructor(
    private cdr: ChangeDetectorRef,
    public gridTargetingService: GridTargetingService,
    public windowPane: WindowPane<void>
  ) {}

  ngOnInit(): void {
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        suppressMenu: true
      }
    };

    this.initTable();

    this.state$ = this.gridTargetingService.getState().pipe(
      tap(state => {
        switch (state) {
          case 'SAVED':
            const activeWindow = this.gridTargetingService.getActiveWindow();
            if (activeWindow?.id === this.windowPane.id) {
              this.data = this.gridTargetingService.getData();

              // clear the data stored in the service to finalize the transaction
              this.gridTargetingService.setData(null);
              this.gridTargetingService.setActiveWindow(null);
              this.gridTargetingService.setState('READY');

              this.initTable();
              this.cdr.detectChanges();
            }
            break;
          case 'MODIFYING':
            if (
              this.gridTargetingService.getActiveWindow().id ===
              this.windowPane.id
            ) {
              this.windowPane.highlight();
              this.windowPane.disableClose();
            } else {
              this.windowPane.unhighlight();
            }
            break;
          default:
            this.windowPane.enableClose();
            this.windowPane.unhighlight();
        }
      })
    );
  }

  initTable() {
    const typeColumnDef =
      this.data?.entity === 'account'
        ? {
            headerName: `{accountType}`,
            field: 'type',
            width: 130,
            cellRenderer: (params: ICellRendererParams) => {
              const { type } = params.data as any;
              return `<div class="flex h-full items-center justify-center"><img class="mat-icon-18" src="/assets/images/${LOGO_FILE_FOR_ACCOUNT_TYPE[type]}" /></div>`;
            }
          }
        : null;

    this.columnDefs = this.data
      ? [
          {
            headerName: `{${this.data.entity}Id}`,
            field: 'id',
            pinned: 'left'
          },
          ...(typeColumnDef ? [typeColumnDef] : []),
          {
            headerName: `{${this.data.entity}Name}`,
            field: 'name',
            flex: 1,
            minWidth: 360
          }
        ]
      : [];
  }

  modify() {
    this.gridTargetingService.modify(this.entity, this.data, this.windowPane);
  }

  cancel() {
    this.gridTargetingService.cancel();
  }
}
