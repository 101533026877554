<ng-container *ngIf="!showReminder; else awayReminder">
  <div class="px-4 py-2" [formGroup]="userModeForm">
    <div
      class="-mx-2 mb-2 block border-b border-dashed border-gray-300"
      [ngClass]="{ hidden: !showUserInfo }"
    >
      <div class="flex items-center px-2 pt-2 pb-4">
        <ease-avatar
          class="mr-2"
          [userId]="user.$key"
          [size]="'medium'"
          [status]="{
            mode: user?.mode
          }"
        ></ease-avatar>

        <div class="text-xs text-gray-900">
          <div class="text-sm font-medium">{{ user.name }}</div>
          <div class="flex items-center" *ngIf="user?.email">
            <mat-icon class="mat-icon-12 mr-1 text-gray-800">email</mat-icon
            >{{ user.email }}
          </div>
          <div class="flex items-center" *ngIf="user?.extension">
            <mat-icon class="mat-icon-12 mr-1 text-gray-800">phone</mat-icon
            >{{ user.extension }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="divide-y divide-dashed divide-gray-300"
      *ngIf="!showDateRangePicker; else dateRangePicker"
    >
      <mat-nav-list class="!pt-0 pb-2">
        <mat-list-item
          disableRipple
          class="!cursor-default hover:!bg-transparent"
        >
          <mat-icon mat-list-icon class="mat-icon-18"
            >notifications_paused</mat-icon
          >
          <div mat-line class="!flex flex-wrap items-center gap-2">
            <div class="flex items-center">
              Away Mode
              <mat-icon
                class="mat-icon-16 ml-1"
                *easeRichTooltip="awayModeTooltip"
                >help_outline</mat-icon
              >
            </div>

            <div class="flex items-center gap-1">
              <div class="relative">
                <button
                  mat-icon-button
                  [matTooltip]="
                    (!!awayModeSettingsControl.value?.start &&
                    !!awayModeSettingsControl.value?.end
                      ? 'Update'
                      : 'Schedule') + ' away dates'
                  "
                  (click)="toggleDateRangePicker(true)"
                >
                  <mat-icon class="!text-gray-700">calendar_month</mat-icon>
                </button>

                <span
                  *ngIf="
                    !!awayModeSettingsControl.value?.start &&
                    !!awayModeSettingsControl.value?.end
                  "
                  class="absolute top-1 right-0.5 block h-2 w-2 rounded-full bg-red-600"
                ></span>
              </div>

              <mat-slide-toggle
                class="!m-0"
                formControlName="away"
                matTooltip="Toggle Away Mode"
              ></mat-slide-toggle>
            </div>
          </div>
        </mat-list-item>

        <mat-list-item
          *ngIf="
            !!awayModeSettingsControl.value?.start &&
            !!awayModeSettingsControl.value?.end
          "
          disableRipple
          class="custom !mx-3 !mb-2 !h-6 !w-auto !cursor-default hover:!bg-transparent"
        >
          <mat-form-field class="!-z-50">
            <span class="mx-2.5">
              <input
                class="rounded-md !text-xs !text-gray-600"
                matInput
                disabled
                [value]="
                  (awayModeSettingsControl.value.start
                    | timezoneDateFormat: 'ddd, MMM DD') +
                  ' — ' +
                  (awayModeSettingsControl.value.end
                    | timezoneDateFormat: 'ddd, MMM Do')
                "
              />
            </span>
          </mat-form-field>
        </mat-list-item>

        <a
          mat-list-item
          (click)="onNavigated($event)"
          [routerLink]="['/users/settings']"
          routerLinkActive="active"
        >
          <mat-icon mat-list-icon class="mat-icon-18">settings</mat-icon>
          <div mat-line>User Settings</div>
        </a>
      </mat-nav-list>

      <mat-nav-list>
        <mat-list-item (click)="logout()">
          <mat-icon mat-list-icon class="mat-icon-18">logout</mat-icon>
          <div mat-line>Log Out</div>
        </mat-list-item>
      </mat-nav-list>
    </div>

    <ng-template #dateRangePicker>
      <ease-inline-date-range-picker
        formControlName="awayModeSettings"
        [minDate]="today"
        [maxDate]="maxUntil"
        [showActionButtons]="true"
        (cancel)="toggleDateRangePicker(false)"
      >
      </ease-inline-date-range-picker>
    </ng-template>
  </div>
</ng-container>

<ng-template #awayReminder>
  <div class="flex justify-center">
    <div class="flex-none py-4 pl-2 pr-3">
      <mat-icon class="!text-3xl">notifications_paused</mat-icon>
    </div>
    <div class="max-w-[160px] flex-initial px-1 pb-3 text-sm">
      <p>Away mode is enabled. If you have returned, be sure to disable it.</p>

      <button color="primary" (click)="turnOffReminder()" mat-stroked-button>
        Okay
      </button>
    </div>

    <div class="flex-none p-2">
      <button class="!h-7 !w-7" mat-icon-button (click)="satPopover.close()">
        <mat-icon class="mat-icon-20">close</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #awayModeTooltip>
  <h5 class="mt-2 mb-0">About Away Mode</h5>
  <p>
    <span class="block font-medium">Schedule Away Mode</span>
    Schedule a range of days that will automatically activate/deactivate Away
    Mode on your behalf.
  </p>

  <p>
    <span class="block font-medium">Auto-Assignment of Tasks</span>
    All system-generated tasks (e.g., ad disapprovals) will be auto-assigned to
    the opposing user role on all accounts you are assigned. For example, if you
    are the Account Manager, your system-generated tasks will be assigned to the
    Reviewer on all those accounts and vice versa.
  </p>

  <p>
    <span class="block font-medium">Muted Notifications </span>
    You will only receive notifications when they are direct assignments or
    mentions. All other notifications (e.g., user group mentions) will be muted
    until Away Mode is disabled.
  </p>
</ng-template>
