import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {
  SatPopoverHorizontalAlign,
  SatPopoverVerticalAlign
} from '@ncstate/sat-popover';
import { isInteger } from 'lodash-es';
import { RichTooltipComponent } from './rich-tooltip.component';
import { RichTooltipOptions } from './rich-tooltip.interface';

/**
 * Rich Tooltip Structural Directive
 *
 * Options:
 * showDelay: number;
 * horizontalAlign: string;
 * verticalAlign: string;
 *
 * Examples of usage, either template or markdown text:
 *
  <mat-icon *easeRichTooltip="
    tooltipTemplate;
    showDelay: 1000;
    horizontalAlign: 'before';
    verticalAlign: 'above';
    autoWidth: true;
    anchorClass: 'xxx';
    offsetClass: 'xxx'
  ">live_help</mat-icon>
  <ng-template #tooltipTemplate>
    Tooltip text in template
  </ng-template>
 *
 *
  <mat-icon *easeRichTooltip="
    '**Percentage** of accounts with a green BU metric';
  ">help_outline</mat-icon>
 */
@Directive({
  selector: '[easeRichTooltip]'
})
export class RichTooltipDirective implements OnInit {
  private content: TemplateRef<any> | string;
  private options: RichTooltipOptions = {
    showDelay: 300,
    verticalAlign: 'below',
    horizontalAlign: 'center',
    autoWidth: false,
    anchorClass: null,
    offsetClass: null
  };

  constructor(
    private templateRef: TemplateRef<any>,
    private vcr: ViewContainerRef
  ) {}

  @Input()
  set easeRichTooltip(content: TemplateRef<any> | string) {
    this.content = content;
  }

  @Input()
  set easeRichTooltipShowDelay(delay: number) {
    if (isInteger(delay)) {
      this.options.showDelay = delay;
    }
  }

  @Input()
  set easeRichTooltipVerticalAlign(verticalAlign: SatPopoverVerticalAlign) {
    if (verticalAlign) {
      this.options.verticalAlign = verticalAlign;
    }
  }

  @Input()
  set easeRichTooltipHorizontalAlign(
    horizontalAlign: SatPopoverHorizontalAlign
  ) {
    if (horizontalAlign) {
      this.options.horizontalAlign = horizontalAlign;
    }
  }

  @Input()
  set easeRichTooltipAutoWidth(autoWidth: boolean) {
    this.options.autoWidth = autoWidth;
  }

  @Input()
  set easeRichTooltipAnchorClass(anchorClass: string) {
    if (anchorClass && typeof anchorClass === 'string') {
      this.options.anchorClass = anchorClass;
    }
  }

  @Input()
  set easeRichTooltipOffsetClass(offsetClass: string) {
    if (offsetClass && typeof offsetClass === 'string') {
      this.options.offsetClass = offsetClass;
    }
  }

  ngOnInit() {
    // @Input() values are available here, then next to create component
    this.createRichTooltipComponent(this.content, this.options);
  }

  private createRichTooltipComponent(
    content: TemplateRef<any> | string,
    options: RichTooltipOptions
  ) {
    // Attaching the instances of components to the host elements
    const compRef = this.vcr.createComponent(RichTooltipComponent);

    // Assigning all @Input() to "RichTooltipComponent"
    compRef.instance.trigger = this.templateRef;
    compRef.instance.options = options;

    if (content instanceof TemplateRef) {
      compRef.instance.contentTemplate = content;
    } else {
      compRef.instance.contentText = content;
    }
  }
}
