<ease-sheet class="popover">
  <ease-popover-header>Task Priority</ease-popover-header>
  <ease-sheet-content class="flex flex-col gap-1 p-2">
    <button
      [color]="priority === null || priority === undefined ? 'primary' : null"
      (click)="setPriority(null)"
      mat-button
    >
      No Priority
    </button>
    <button
      [color]="priority === 2 ? 'primary' : null"
      (click)="setPriority(2)"
      mat-button
    >
      Low
    </button>
    <button
      [color]="priority === 1 ? 'primary' : null"
      (click)="setPriority(1)"
      mat-button
    >
      Medium
    </button>
    <button
      [color]="priority === 0 ? 'primary' : null"
      (click)="setPriority(0)"
      mat-button
    >
      High
    </button>
  </ease-sheet-content>
</ease-sheet>
