import {
  Component,
  HostBinding,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { WindowService } from '../window.service';
import { WindowPaneHostDirective } from '../window-pane/window-pane-host.directive';

@Component({
  selector: 'ease-window-outlet',
  templateUrl: './window-outlet.component.html',
  styleUrls: ['./window-outlet.component.scss']
})
export class WindowOutletComponent {
  @HostBinding('class') class =
    'absolute top-0 bottom-0 left-0 right-0 z-1 flex items-end pointer-events-none';

  @ViewChild(WindowPaneHostDirective, { static: true, read: ViewContainerRef })
  set windowPaneHost(containerRef: ViewContainerRef) {
    if (!!containerRef) {
      this.windowService.configurePaneHost(containerRef);
    }
  }
  constructor(private windowService: WindowService) {}
}
