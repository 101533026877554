import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import * as moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AdminAnnouncementsService } from 'src/app/admin/admin-announcements/admin-announcements.service';
import { UserService } from 'src/app/users/user.service';
import {
  Announcement,
  AnnouncementsMetaForm
} from 'src/app/announcements/announcements.interface';
import { WindowPane } from '../window-pane/window-pane';
import { Target } from '../../bulk-targeting/bulk-targeting.interface';

@UntilDestroy()
@Component({
  selector: 'ease-window-pane-create-announcement',
  templateUrl: './window-pane-create-announcement.component.html',
  styleUrls: ['./window-pane-create-announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowPaneCreateAnnouncementComponent implements OnInit {
  @Input() data: any = {};

  get metaFormGroup(): AnnouncementsMetaForm {
    return this.announcementForm.controls.meta;
  }

  get targetControl(): FormControl<Target> {
    return this.announcementForm.controls.target;
  }

  private DEFAULT_EXPIRY_DATE: moment.Moment = moment().add(1, 'months');
  public announcementForm: FormGroup<{
    meta: AnnouncementsMetaForm;
    target: FormControl<Target>;
  }> = this.formBuilder.group({
    meta: this.formBuilder.group({
      summary: this.formBuilder.control(null, {
        validators: Validators.required
      }),
      details: this.formBuilder.control(null, {
        validators: Validators.required
      }),
      expiryDate: this.formBuilder.control(this.DEFAULT_EXPIRY_DATE, {
        validators: Validators.required
      })
    }),
    target: this.formBuilder.control(null, { validators: Validators.required })
  });

  constructor(
    private adminAnnouncementsService: AdminAnnouncementsService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private windowPane: WindowPane
  ) {}

  ngOnInit(): void {
    this.metaFormGroup.controls.summary.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(summary => this.windowPane.updateTitle(summary));
  }

  async create() {
    const { level, members } = this.targetControl.value;

    const announcement: Announcement = {
      type: 'informative',
      summary: this.metaFormGroup.controls.summary.value,
      details: this.metaFormGroup.controls.details.value,
      targetLevel: level,
      target: members,
      expiryDate: moment(
        this.metaFormGroup.controls.expiryDate.value
      ).valueOf(),
      isExpired: false,
      createdAt: moment().valueOf(),
      createdBy: {
        id: this.userService.currentUser.$key,
        name: this.userService.currentUser.name
      }
    };

    try {
      await this.adminAnnouncementsService.create(announcement);
      this.windowPane.close();
    } catch {}
  }
}
