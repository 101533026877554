import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { SharedLayoutService } from '../shared-layout.service';
import { ViewportOffsetService } from './viewport-offset.service';

@UntilDestroy()
@Directive({
  selector: '[easeViewportOffsetReceiver]'
})
export class ViewportOffsetReceiverDirective
  implements AfterViewInit, OnDestroy
{
  @Input() easeViewportOffsetReceiver: 'width' | 'height';
  @Input() addOffsetPixels: number = 0;
  @Input() restMobileOffset: boolean = true;
  @Input() fixedPosition: boolean = false;

  private isMobile: boolean;
  private distanceRoof: number;
  private distanceLeft: number;
  private distanceTop: number;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private sharedLayoutService: SharedLayoutService,
    private viewportOffsetService: ViewportOffsetService
  ) {}

  ngAfterViewInit() {
    combineLatest([
      this.viewportOffsetService.roofHeight$,
      this.sharedLayoutService.isCompact$
    ])
      // For initial setup, debounceTime here to ensure receiving correct values
      .pipe(untilDestroyed(this), debounceTime(280))
      .subscribe(([roofHeight, isMobile]) => {
        this.isMobile = isMobile.active;
        this.distanceRoof = roofHeight;
        this.setOffset();
      });
  }

  ngOnDestroy() {}

  private setOffset(): void {
    // Element has delay on transforming, so timeout here to ensure receiving correct values
    setTimeout(() => {
      if (this.element) {
        // For normal relative element/component, calculating from HOST element's left to Viewport left directly.
        this.distanceLeft =
          this.element.nativeElement.getBoundingClientRect().left;

        // For normal relative element/component, calculating from HOST element's top to Viewport top directly.
        this.distanceTop =
          this.element.nativeElement.getBoundingClientRect().top;

        return this.createOffset();
      }
    });
  }

  private createOffset(): void {
    if (this.easeViewportOffsetReceiver === 'width') {
      const widthStyle = `calc(100vw - ${
        this.distanceLeft + this.addOffsetPixels
      }px)`;

      return this.setStyles(
        'max-width',
        this.isMobile && this.restMobileOffset ? '100%' : widthStyle
      );
    }

    if (this.easeViewportOffsetReceiver === 'height') {
      // For fixed or absolute element/component that could overlay the entire app,
      // assign the total heights of all roof "Pusher(s)" to avoid overlapping issues.
      const heightStyle = `calc(100vh - ${
        this.fixedPosition
          ? this.distanceRoof + this.addOffsetPixels
          : this.distanceTop + this.addOffsetPixels
      }px)`;

      return this.setStyles(
        'max-height',
        this.isMobile && this.restMobileOffset ? '100%' : heightStyle
      );
    }
  }

  private setStyles(property: string, value: string): void {
    // With renderer for adapting styles to HOST element
    return this.renderer.setStyle(this.element.nativeElement, property, value);
  }
}
