import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { UserModel } from 'src/app/users/user.model';
import { UserService } from 'src/app/users/user.service';

@Component({
  selector: 'ease-announcements-marked-by',
  templateUrl: './announcements-marked-by.component.html',
  styleUrls: ['./announcements-marked-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsMarkedByComponent implements OnInit {
  @HostBinding('class.expanded')
  get isNavCollapsed() {
    return this.isExpanded;
  }
  @Input() users: string[] = [];
  public allUsers$: Observable<{ [userId: string]: UserModel }>;
  public isExpanded: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.allUsers$ = this.userService.usersAsObject.pipe(take(1));
  }

  public toggle() {
    this.isExpanded = !this.isExpanded;
    this.cdr.detectChanges();
  }
}
