<div class="chart-summary-header">Summary</div>
<table class="chart-summary">
  <tr *ngFor="let item of summary">
    <td class="chart-summary__key">
      <ease-account-icon
        class="mr-1 !align-baseline"
        *ngIf="item?.accountType"
        [size]="18"
        [accountType]="item?.accountType"
      ></ease-account-icon>
      {{ item.label }}
    </td>
    <td class="chart-summary__value">
      {{
        metricLabel === 'Conversions'
          ? item.data
          : (item.data | currency: 'USD':'symbol':'1.0-0')
      }}
    </td>
  </tr>
</table>
