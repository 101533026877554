<div class="flex flex-col gap-1" cdkDropListGroup>
  <div class="flex flex-row items-center gap-2">
    <div class="p-1">
      <mat-icon matTooltip="Assigned Users">people</mat-icon>
    </div>
    <ease-user-selector-input
      [formControl]="assigned"
      [onlyUsers]="true"
      (selected)="assign(task.$key, $event)"
      (deselected)="taskService.unassign(task.$key, $event)"
      label="Assign User"
      class="flex-1 !pb-0"
    >
      <ng-template #menu let-userId>
        <ease-task-user-menu
          (move)="subscribe(task.$key, { userId, method: 'direct' })"
          (remove)="taskService.unassign(task.$key, userId)"
        ></ease-task-user-menu>
      </ng-template>
    </ease-user-selector-input>
  </div>
  <div class="flex flex-row items-center gap-2">
    <div class="p-1">
      <mat-icon matTooltip="Watchers">remove_red_eye</mat-icon>
    </div>
    <ease-user-selector-input
      [formControl]="subscribed"
      (selected)="subscribe(task.$key, $event)"
      (deselected)="taskService.unsubscribe(task.$key, $event)"
      label="Add Watcher"
      class="flex-1 !pb-0"
    >
      <ng-template #menu let-userId>
        <ease-task-user-menu
          mode="subscribe"
          (move)="assign(task.$key, { userId, method: 'direct' })"
          (remove)="taskService.unsubscribe(task.$key, userId)"
        ></ease-task-user-menu>
      </ng-template>
    </ease-user-selector-input>
  </div>
</div>
