import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable, shareReplay, tap } from 'rxjs';

import { EnvironmentService } from '../../environment.service';
import { FinderComponent, FinderSettings } from '../../finder/finder.component';
import { SharedLayoutService } from '../../shared-layout.service';
import { DeepPartial, EntityMetadata } from '../../shared.interface';

@Component({
  selector: 'ease-app-finder',
  templateUrl: './app-finder.component.html',
  styleUrls: ['./app-finder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFinderComponent {
  @HostBinding('class') class = 'relative max-w-3xl m-auto text-lg';
  @ViewChild('finder') finder: FinderComponent;
  public panelOpenSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public panelOpen: boolean;
  public panelOpen$: Observable<boolean> = this.panelOpenSource
    .asObservable()
    .pipe(
      tap(state => (this.panelOpen = state)),
      shareReplay(1)
    );

  public userFinderSettings: Partial<FinderSettings> = JSON.parse(
    localStorage.getItem('finderSettings')
  );

  constructor(
    private sharedLayoutService: SharedLayoutService,
    public environmentService: EnvironmentService,
    private cdr: ChangeDetectorRef
  ) {}

  goToItem(meta: EntityMetadata): void {
    this.sharedLayoutService.navigateToEntity(meta);
  }

  openFinder(): void {
    this.finder.focusSearch();
  }

  onFilterChange(filters: DeepPartial<FinderSettings>): void {
    const { query, ...settings } = filters;
    localStorage.setItem('finderSettings', JSON.stringify(settings));
  }

  setPanelState(isOpen: boolean): void {
    this.panelOpenSource.next(isOpen);
    this.cdr.detectChanges();
  }
}
