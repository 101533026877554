<div
  (click)="statusPopover.open()"
  [satPopoverAnchor]="statusPopover"
  class="flex cursor-pointer flex-row items-center gap-2 text-[13px] font-medium leading-6 text-gray-800"
>
  <mat-icon class="mat-icon-18 text-gray-600">linear_scale</mat-icon>

  <ng-container *ngIf="!!selectedStatusId; else expandMore">
    <ease-task-status-blob
      [statusId]="selectedStatusId"
    ></ease-task-status-blob>
    <mat-icon
      (click)="onStatusChange(null); $event.stopPropagation()"
      class="mat-icon-18 text-gray-600"
    >
      close
    </mat-icon>
  </ng-container>
  <ng-template #expandMore>
    <span>Set status</span>
    <mat-icon class="mat-icon-18 text-gray-600">expand_more</mat-icon>
  </ng-template>
</div>

<sat-popover
  verticalAlign="start"
  horizontalAlign="start"
  #statusPopover
  [hasBackdrop]="true"
>
  <ease-task-status-popover
    *ngIf="statusPopover.isOpen()"
    (selected)="onStatusChange($event)"
    [selectedStatus]="[selectedStatusId]"
  >
  </ease-task-status-popover>
</sat-popover>
