import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { OnboardingStage } from '../customer-onboarding.interface';
import { CustomerOnboardingService } from '../customer-onboarding.service';

@Component({
  selector: 'ease-customer-onboarding-form-renderer',
  templateUrl: './customer-onboarding-form-renderer.component.html',
  styleUrls: ['./customer-onboarding-form-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerOnboardingFormRendererComponent implements OnInit {
  @Input() stageId: number;
  public stage$: Observable<OnboardingStage>;
  public collapseAll: boolean = false;
  constructor(private customerOnboardingService: CustomerOnboardingService) {}

  ngOnInit(): void {
    this.stage$ = this.customerOnboardingService.getStage(this.stageId);
  }
}
