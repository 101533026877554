import { NgModule } from '@angular/core';

import { PopoverModule } from '../popover.module';
import { SharedModule } from '../../shared.module';
import { TaskScheduledDatesPopoverComponent } from './task-scheduled-dates-popover.component';

@NgModule({
  imports: [PopoverModule, SharedModule],
  declarations: [TaskScheduledDatesPopoverComponent],
  exports: [TaskScheduledDatesPopoverComponent, PopoverModule]
})
export class TaskScheduledDatesPopoverModule {}
