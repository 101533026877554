<ease-sheet class="popover" *ngIf="task$ | async as task">
  <form [formGroup]="completeTaskForm" (ngSubmit)="completeTask()">
    <ease-popover-header
      ><span
        >Complete
        {{
          !task.parent && task.children
            ? 'Project'
            : !task.children && task.parent
            ? 'Subtask'
            : 'Task'
        }}</span
      ></ease-popover-header
    >
    <ease-sheet-content class="pb-0">
      <div
        *ngIf="incompleteSubtasks$ | async"
        class="mb-2 flex flex-row items-center gap-3 rounded-lg border border-orange-500 bg-orange-50 p-2 text-xs text-orange-800"
      >
        <div><mat-icon class="mat-icon-16">warning_outlined</mat-icon></div>
        <div class="flex-1">
          <div class="font-bold">Project has incomplete subtasks</div>
          <div>Completing this project will complete all subtasks</div>
        </div>
      </div>
      <div class="form-group mb-2">
        <ease-markdown-editor
          [easeMentions]="true"
          placeholder="Completion message w/ @mentions"
          formControlName="completionMessage"
          size="sm"
          [autoFocus]="true"
          class="max-h-[25vh]"
        ></ease-markdown-editor>
        <mat-error
          class="!mt-1"
          easeFormErrors
          [errorMessages]="{
            required: 'A completion message is required when snoozing accounts'
          }"
          [control]="completeTaskForm.controls.completionMessage"
        ></mat-error>
      </div>

      <ng-container *ngIf="isSnoozeable$ | async as isSnoozeable">
        <div class="form-group mb-2">
          <ng-container *ngIf="snoozeAccounts$ | async as snoozeAccounts">
            <mat-slide-toggle
              *ngIf="snoozeAccounts.length"
              color="primary"
              class="!mt-4 !mb-4 !block !text-sm"
              formControlName="snoozeAccountToggle"
              >Snooze AMD accounts
              <mat-icon
                class="mat-icon-14"
                matTooltip="Snooze AMD accounts while completing this task"
              >
                help
              </mat-icon></mat-slide-toggle
            >
            <mat-form-field
              *ngIf="snoozeAccounts.length && hasSnoozeAccountToggled"
            >
              <mat-label>Account to snooze</mat-label>
              <mat-select
                #snoozeAccountPicker
                formControlName="snoozeAccounts"
                multiple
              >
                <mat-select-trigger>
                  {{ allSnoozeAccountsSelected ? 'All' : 'Only' }}
                  {{
                    completeTaskForm?.controls?.snoozeAccounts?.value?.length +
                      ' account(s) selected'
                  }}
                </mat-select-trigger>

                <mat-option
                  class="!py-1 !leading-none"
                  *ngFor="let snoozeAccount of snoozeAccounts"
                  [value]="snoozeAccount"
                >
                  <ease-account-icon
                    class="mt-3 mr-1"
                    [size]="16"
                    [accountType]="snoozeAccount.$accountType"
                  ></ease-account-icon>
                  <span class="text-sm">{{ snoozeAccount.name }}</span>
                  <small class="-mt-1 ml-5 block text-xs text-gray-500"
                    >({{ snoozeAccount.$key }})</small
                  >
                </mat-option>
                <div
                  class="sticky -bottom-px border-t border-gray-200 bg-white px-3 py-2 text-right text-sm"
                >
                  <mat-slide-toggle
                    color="primary"
                    class="!m-0"
                    formControlName="snoozeAccountSelectAll"
                    >{{
                      allSnoozeAccountsSelected ? 'Deselect All' : 'Select All'
                    }}</mat-slide-toggle
                  >
                </div>
              </mat-select>
            </mat-form-field></ng-container
          >

          <ng-container
            *ngIf="
              !isTaskOfType(task, 'customerCommunication') ||
              hasSnoozeAccountToggled
            "
          >
            <mat-form-field>
              <mat-select
                formControlName="snoozeUntil"
                [placeholder]="
                  isTaskOfType(task, 'customerCommunication')
                    ? 'Snooze account(s) for...'
                    : hasSnoozeAccountToggled
                    ? 'Snooze account(s) & task for...'
                    : 'Snooze task for...'
                "
              >
                <mat-option
                  *ngFor="let snoozeTime of snoozeTimes"
                  [value]="snoozeTime.value"
                  >{{ snoozeTime.viewValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <ease-datepicker
              #snoozePicker
              [min]="now"
              [max]="hasSnoozeAccountToggled && maxSnoozeDate"
              *ngIf="completeTaskForm.controls.snoozeUntilDate.enabled"
              formControlName="snoozeUntilDate"
              [placeholder]="
                hasSnoozeAccountToggled
                  ? 'Snooze until(max 90 days)'
                  : 'Snooze until'
              "
            ></ease-datepicker>
          </ng-container>
        </div>

        <ease-sheet-divider
          class="-mt-2"
          *ngIf="
            (isTaskOfType(task, 'scheduledTask') ||
              isTaskOfType(task, 'customerCommunication')) &&
            hasSnoozeAccountToggled
          "
        ></ease-sheet-divider>

        <div class="form-group" *ngIf="isTaskOfType(task, 'scheduledTask')">
          <mat-checkbox
            class="mb-4"
            color="primary"
            formControlName="cancelScheduledTask"
            >Stop this task from recurring</mat-checkbox
          >
        </div>

        <div
          class="form-group-sm"
          *ngIf="isTaskOfType(task, 'customerCommunication')"
        >
          <ease-datepicker
            placeholder="Next Scheduled Communication"
            [min]="now"
            formControlName="nextCommunicationAt"
          ></ease-datepicker>

          <div class="banner theme-info mb-5 text-sm">
            Auto-set to 30 days from today.
          </div>

          <mat-radio-group
            formControlName="hasCommunicated"
            class="mb-4 flex flex-row"
          >
            <mat-radio-button [value]="true"
              >Zoom/Phone Meeting</mat-radio-button
            >
            <mat-radio-button class="ml-4" [value]="false"
              >Email/Chat/Calendar Invite</mat-radio-button
            >
          </mat-radio-group>
        </div>
      </ng-container>
    </ease-sheet-content>
    <ease-sheet-footer>
      <mat-progress-spinner
        *ngIf="isWorking"
        [diameter]="20"
        color="primary"
        mode="indeterminate"
        class="ml-4"
      >
      </mat-progress-spinner>
      <div class="flex-1"></div>
      <div class="my-2 mr-1.5 flex">
        <button
          class="flex h-7 items-center justify-center rounded-l-md border border-r-0 border-gray-300 px-3 text-sm font-bold text-blue-500 transition-colors hover:bg-gray-50 disabled:opacity-50 disabled:grayscale"
          [disabled]="isWorking || completeTaskForm.invalid"
          type="submit"
        >
          <span *ngIf="selectedCompleteSetting === 'complete'">
            Complete
            {{
              !task.parent && task.children
                ? 'Project'
                : !task.children && task.parent
                ? 'Subtask'
                : 'Task'
            }}
          </span>
          <span *ngIf="selectedCompleteSetting === 'completeAndClose'">
            Complete
            {{
              !task.parent && task.children
                ? 'Project'
                : !task.children && task.parent
                ? 'Subtask'
                : 'Task'
            }}
            and Close
          </span>
        </button>
        <button
          class="flex h-7 items-center justify-center rounded-r-md border border-gray-300 transition-colors hover:bg-gray-50"
          type="button"
          (click)="completeMenu.open()"
        >
          <mat-icon class="mat-icon-24">arrow_drop_down</mat-icon>
        </button>
      </div>
      <mat-select
        #completeMenu
        (selectionChange)="completeOptionSelected($event.value)"
        class="toolbar-control"
      >
        <mat-option value="complete">
          <div class="flex flex-col leading-snug">
            <span>
              Complete
              {{
                !task.parent && task.children
                  ? 'Project'
                  : !task.children && task.parent
                  ? 'Subtask'
                  : 'Task'
              }}
            </span>
            <small class="text-gray-700"
              >Keep window open when
              {{
                !task.parent && task.children
                  ? 'project'
                  : !task.children && task.parent
                  ? 'subtask'
                  : 'task'
              }}
              is completed</small
            >
          </div>
        </mat-option>
        <mat-option value="completeAndClose">
          <div class="flex flex-col leading-snug">
            Complete
            {{
              !task.parent && task.children
                ? 'Project'
                : !task.children && task.parent
                ? 'Subtask'
                : 'Task'
            }}
            and Close
            <small class="text-gray-700"
              >Close window when
              {{
                !task.parent && task.children
                  ? 'project'
                  : !task.children && task.parent
                  ? 'subtask'
                  : 'task'
              }}
              is completed</small
            >
          </div>
        </mat-option>
      </mat-select>
    </ease-sheet-footer>
  </form>
</ease-sheet>
