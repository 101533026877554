<div class="settings flex flex-1 flex-row">
  <div
    class="preview flex-1"
    [satPopoverAnchor]="accountsToFund"
    (mouseenter)="accountsToFund.open()"
    (mouseleave)="accountsToFund.close()"
  >
    <ng-container
      *ngIf="
        fundingService.fundingSettings$ | async as fundingSettings;
        else loading
      "
    >
      <ease-metric-list
        [list]="[
          {
            label: 'Mgmt Fee Payment Type',
            value: !fundingSettings.exist
              ? 'Not set'
              : labelManagementFee[fundingSettings.paymentSource]
          },
          {
            label: 'Accounts to Fund',
            value: !fundingSettings.exist
              ? 'Incomplete'
              : fundingSettings.advertisingAccount.length + ' Accounts'
          },
          {
            label: 'Last Updated',
            value: !fundingSettings.exist
              ? 'Never'
              : (fundingSettings.updatedAt | amDateFormat: 'LL')
          }
        ]"
      ></ease-metric-list>
    </ng-container>
  </div>

  <ng-container *ngIf="!isWidget">
    <div class="actions">
      <button (click)="openFundingSettingsDialog()" mat-raised-button>
        <mat-icon class="mr-2">settings</mat-icon>
        Funding Settings
      </button>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <ease-state-loading></ease-state-loading>
</ng-template>

<!--POPOVER-->
<sat-popover
  verticalAlign="below"
  horizontalAlign="center"
  [autoFocus]="false"
  #accountsToFund
>
  <div id="account-list-popover" class="account-list">
    <ng-container
      *ngIf="fundingService.fundingSettings$ | async as fundingSettings"
    >
      <ng-container
        *ngIf="fundingSettings.advertisingAccount; else noAccountsToFund"
      >
        <ng-container
          *ngFor="let account of fundingSettings.advertisingAccount"
        >
          <!--account item template-->
          <div class="item flex flex-row">
            <div class="icon flex flex-col justify-center">
              <ease-account-icon
                class="icon"
                [accountType]="account.type"
                [size]="18"
                class="mr-2"
              >
              </ease-account-icon>
            </div>
            <div class="details flex flex-1 flex-col justify-center">
              <span class="label">{{ account.name }}</span>
              <span class="id">{{ account.id }}</span>
            </div>
          </div>
          <!--end of account item template-->
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noAccountsToFund>
      <div class="notice">
        <div class="content">
          There are no accounts to fund yet. Add at least one by modifying the
          <strong>Funding Settings</strong>.
        </div>
      </div>
    </ng-template>
  </div>
</sat-popover>
<!--end of POPOVER-->
