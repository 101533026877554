import { ElementRef, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, finalize, Observable, Subject, tap } from 'rxjs';

import { DrawerWidth } from './drawer-sidebar.interface';

@Injectable()
export class DrawerSidebarService {
  private width = new BehaviorSubject<DrawerWidth>({
    expanded: '600px',
    collapsed: '60px'
  });

  constructor(private ngZone: NgZone) {}

  /**
   * Sets the width for either or both the expanded or collapsed states.
   * We might want to add some delay before getting an element's width dynamically
   * to give time for the styles to apply.
   *
   * @param widths
   */
  setWidth(widths: Partial<DrawerWidth>): void {
    const current = this.width.value;
    this.width.next({
      expanded: widths.expanded || current.expanded,
      collapsed: widths.collapsed || current.collapsed
    });
  }

  getWidth(): Observable<DrawerWidth> {
    return this.width.asObservable();
  }

  /**
   * Returns an observable where a component can subscribe, to dynamically
   * set the drawer widths. Component needs to unsubscribe once its destroyed
   * to disconnect the resize observer.
   *
   * @param type pass "drawer" if setting width for the whole drawer. "partial"
   * if setting width for the visible element when the drawer is collapsed.
   * @param element
   * @returns
   */
  getResizeObserver(
    type: 'drawer' | 'partial',
    element: ElementRef
  ): Observable<void> {
    const resize = new Subject<void>();
    const resizeObserver = new ResizeObserver(() =>
      this.ngZone.runOutsideAngular(() =>
        window.requestAnimationFrame(() => resize.next())
      )
    );
    resizeObserver.observe(element.nativeElement);
    return resize.pipe(
      tap(() =>
        this.setWidth({
          [type === 'drawer'
            ? 'expanded'
            : 'collapsed']: `${element.nativeElement.offsetWidth}px`
        })
      ),
      finalize(() => resizeObserver.disconnect())
    );
  }
}
