import { Clipboard } from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ConfirmService } from '../../../shared/confirm/confirm.service';
import { ListDisplayItem } from '../../../shared/shared.interface';
import { SNACKBAR_DURATION_SUCCESS } from '../../../shared/constants';
import { CustomersService } from '../../customers.service';
import { CustomerOnboardingCreateEditDialogComponent } from '../customer-onboarding-create-edit-dialog/customer-onboarding-create-edit-dialog.component';
import {
  OnboardingPackage,
  OnboardingStage
} from '../customer-onboarding.interface';
import { CustomerOnboardingService } from '../customer-onboarding.service';

@Component({
  selector: 'ease-customer-onboarding-task-package',
  templateUrl: './customer-onboarding-task-package.component.html',
  styleUrls: ['./customer-onboarding-task-package.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerOnboardingTaskPackageComponent implements OnInit {
  @Input() set packageId(packageId: string) {
    this._packageId = packageId;
    this.reloadSource.next(true);
  }

  get packageId(): string {
    return this._packageId;
  }

  private _packageId: string;
  public onbPackage$: Observable<OnboardingPackage>;
  public stageLoadedHash: { [key: string]: boolean } = {};
  public plans: {
    [key: string]: { name: string; color: string };
  };
  public reloadSource: Subject<boolean> = new Subject<boolean>();
  public reload$ = this.reloadSource.asObservable().pipe(startWith(true));

  constructor(
    private customerOnboardingService: CustomerOnboardingService,
    private customerService: CustomersService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {
    this.onbPackage$ = this.reload$.pipe(
      switchMap(() =>
        this.customerOnboardingService.getPackage(this.packageId)
      ),
      shareReplay(1)
    );

    this.customerService
      .getPlans()
      .pipe(
        take(1),
        map(options =>
          options.reduce((acc, option: ListDisplayItem & { color: string }) => {
            acc[option.value] = {
              name: option.viewValue,
              color: option.color
            };
            return acc;
          }, {})
        )
      )
      .subscribe(plans => {
        this.plans = plans;
        this.cdr.detectChanges();
      });
  }

  markAsLoaded(stageId: number) {
    this.stageLoadedHash[stageId] = true;
  }

  async editOnboarding() {
    const onbPackage = await firstValueFrom(this.onbPackage$);
    const dialogRef = this.matDialog.open(
      CustomerOnboardingCreateEditDialogComponent
    );

    dialogRef.componentInstance.operation = {
      action: 'edit',
      data: {
        customerId: onbPackage.customer.id,
        package: onbPackage
      }
    };

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.reloadSource.next(true));
  }

  copyLink() {
    this.clipboard.copy(`${environment.ONBOARDING_HUB}/${this.packageId}`);

    this.snackBar.open('Link copied to your clipboard', 'Close', {
      duration: SNACKBAR_DURATION_SUCCESS
    });
  }

  async openForm(stage: OnboardingStage) {
    if (stage.status === 'completed') {
      const confirmResult = await this.confirmService.confirm({
        title: 'Form already complete',
        message:
          'By filling this questionnaire, the existing results will be overwritten',
        confirmText: 'Go to form',
        cancelText: 'Cancel'
      });

      if (!confirmResult.confirm) {
        return false;
      }
    }

    window.open(
      `${stage.form.url}?packageId=${this.packageId}&stageId=${stage.id}`,
      '_blank'
    );
  }
}
