import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(input: string, forceLowerCase: boolean = true) {
    if (!input) {
      return '';
    }

    const finalInput = forceLowerCase ? input.toLowerCase() : input;
    return finalInput.charAt(0).toUpperCase() + finalInput.slice(1);
  }
}
