import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared.module';
import { MarkdownDirective } from './markdown/markdown.directive';
import { MarkdownEditorComponent } from './markdown-editor/markdown-editor.component';
import { MarkdownFormComponent } from './markdown-form/markdown-form.component';
import { MarkdownVariableDirective } from './markdown-variable.directive';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, SharedModule],
  declarations: [
    MarkdownDirective,
    MarkdownEditorComponent,
    MarkdownFormComponent,
    MarkdownVariableDirective
  ],
  exports: [
    MarkdownDirective,
    MarkdownEditorComponent,
    MarkdownFormComponent,
    MarkdownVariableDirective
  ]
})
export class MarkdownModule {}
