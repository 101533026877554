<div class="flex flex-row items-center gap-2">
  <mat-icon *ngIf="showIcon" class="mat-icon-18">linear_scale</mat-icon>
  <div
    *ngIf="status | async as taskStatus"
    class="flex flex-row items-center gap-1"
  >
    <ease-color-blob size="xxs" [color]="taskStatus.color"></ease-color-blob>
    <span class="text-[13px] font-medium leading-6 text-gray-800">
      {{ taskStatus.name }}
    </span>
  </div>
</div>
