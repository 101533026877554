import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { shareReplay, startWith } from 'rxjs/operators';
import { DateRange } from '../../shared/funding/funding.interface';

import { SkSyncHttpService } from '../../shared/sksync-http.service';
import {
  OnboardingCreatePayload,
  OnboardingForm,
  OnboardingPackage,
  OnboardingPackageSuccess,
  OnboardingStage,
  OnboardingType
} from './customer-onboarding.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomerOnboardingService {
  public reloadTableSource: Subject<DateRange> = new Subject<DateRange>();
  public reloadTable$ = this.reloadTableSource
    .asObservable()
    .pipe(startWith(), shareReplay(1));

  constructor(private skSyncHttp: SkSyncHttpService) {}

  get(customerId: string): Observable<OnboardingPackage[]> {
    return this.skSyncHttp.get(`/onboarding/${customerId}`);
  }

  getPackage(packageId: string): Observable<OnboardingPackage> {
    return this.skSyncHttp.get(`/onboarding/package/${packageId}`);
  }

  deletePackage(packageId: string) {
    return this.skSyncHttp.delete(`/onboarding/package/${packageId}`);
  }

  getAll(dateRangeParams?: DateRange): Observable<OnboardingPackage[]> {
    return this.skSyncHttp.get<OnboardingPackage[]>(`/onboarding`, {
      params: {
        ...dateRangeParams
      }
    });
  }

  getPackagesByIncompleteForm(formId: number): Observable<OnboardingPackage[]> {
    return this.skSyncHttp.get(`/onboarding/form/${formId}`);
  }

  getStage(stageId: number): Observable<OnboardingStage> {
    return this.skSyncHttp.get(`/onboarding/stage/${stageId}`);
  }

  getForms(): Observable<OnboardingForm[]> {
    return this.skSyncHttp.get(`/onboarding/forms`);
  }

  deleteForm(formId: number) {
    return this.skSyncHttp.delete(`/onboarding/forms/${formId}`);
  }

  createForm(formInfo: Partial<OnboardingForm>) {
    return this.skSyncHttp.post(`/onboarding/forms`, formInfo);
  }

  getOnboardingTypes(): Observable<OnboardingType[]> {
    return this.skSyncHttp.get(`/onboarding/types`);
  }

  createOnboardingTypes(onboardingType: OnboardingType) {
    return this.skSyncHttp.post(`/onboarding/types`, onboardingType);
  }

  deleteOnboardingType(onboardingTypeId: number) {
    return this.skSyncHttp.delete(`/onboarding/types/${onboardingTypeId}`);
  }

  createPackage(
    packageInfo: OnboardingCreatePayload
  ): Observable<OnboardingPackageSuccess> {
    return this.skSyncHttp.post(`/onboarding`, packageInfo);
  }

  updatePackage(
    packageId: string,
    packageInfo: OnboardingCreatePayload
  ): Observable<OnboardingPackageSuccess> {
    return this.skSyncHttp.post(
      `/onboarding/package/${packageId}`,
      packageInfo
    );
  }

  reloadTable(dateRange?: DateRange) {
    this.reloadTableSource.next(dateRange);
  }

  async merge(sourceCustomerId: string, destinationCustomerId: string) {
    return firstValueFrom(
      this.skSyncHttp.post(`/onboarding/merge`, {
        sourceCustomerId,
        destinationCustomerId
      })
    );
  }
}
