import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { formatCurrency } from '@angular/common';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { SkSyncHttpService } from 'src/app/shared/sksync-http.service';
import { firebaseJSON } from '../../shared/utils/functions';
import { SNACKBAR_DURATION_SUCCESS } from '../../shared/constants';
import { CustomerAccountsBudgetSummary } from '../customer-budgets/customer-budget.interface';
import { Change } from '../../shared/shared.interface';
import { FundingService } from '../../shared/funding/funding.service';
import { getRulesPathForAccountType } from '../../shared/utils/firebase-functions-common';
import { CustomerAdWordsAccountRules } from './customer-account-rules.interface';
import { CustomerAccountMeta } from './customer-accounts.interface';
import {
  CustomerAccountCost,
  CustomerBudget
} from './customer-account-budget/customer-account-budget.interface';
import { CustomerAccountsService } from './customer-accounts.service';

@Injectable({ providedIn: 'root' })
export class CustomerAccountRulesService {
  public defaultCpcSettings = {
    enabled: false,
    costPerConversion: 50
  };

  constructor(
    private angularFire: FirebaseDbService,
    private customerAccountsService: CustomerAccountsService,
    private matSnackBar: MatSnackBar,
    private skSyncHttp: SkSyncHttpService,
    private fundingService: FundingService
  ) {}

  getAll(meta: CustomerAccountMeta): Observable<CustomerAdWordsAccountRules> {
    return this.angularFire.getObject(
      `/${getRulesPathForAccountType(meta.accountType)}/${meta.accountId}`
    );
  }

  get(meta: CustomerAccountMeta, ruleName: string): Observable<any> {
    return this.angularFire.getObject(
      `/${getRulesPathForAccountType(meta.accountType)}/${
        meta.accountId
      }/${ruleName}`
    );
  }

  getCostSummary(meta: CustomerAccountMeta): Promise<CustomerAccountCost> {
    return firstValueFrom(
      this.skSyncHttp.get<CustomerAccountCost>(
        `/accounts/${meta.accountType}/${meta.accountId}/costSummary`
      )
    );
  }

  getAllAccountBudgetSettings(
    customerId: string,
    verify: boolean = true
  ): Observable<CustomerAccountsBudgetSummary> {
    // if there's no need to verify if it the budget is managed by Ease or not
    if (!verify) {
      return this.customerAccountsService
        .getAllGrouped(customerId)
        .pipe(
          switchMap(groupedAccounts =>
            this.destructureAccountBudgetSettings(groupedAccounts)
          )
        );
    }

    this.fundingService.setFundingCustomerId(customerId);
    this.fundingService.reloadFundingData();

    const accountManagedByEase$ = this.fundingService.fundingSettings$.pipe(
      map(settings => {
        if (
          settings.exist &&
          settings.paymentSource === 'fundingPercentage' &&
          settings.advertisingAccount &&
          settings.advertisingAccount.length
        ) {
          // Returning only account managed by Ease. In order to paymentSource be 'fundingPercentage' can only have ONE account
          return {
            id: settings.advertisingAccount[0].id,
            type: settings.advertisingAccount[0].type
          };
        } else {
          return {};
        }
      })
    );

    return combineLatest([
      this.customerAccountsService.getAllGrouped(customerId),
      accountManagedByEase$
    ]).pipe(
      switchMap(([groupedAccounts, budgetByEase]) =>
        this.destructureAccountBudgetSettings(groupedAccounts, budgetByEase)
      )
    );
  }

  destructureAccountBudgetSettings(
    groupedAccounts: Observable<any>,
    budgetByEase?: any
  ): Promise<CustomerAccountsBudgetSummary> {
    return Object.keys(groupedAccounts).reduce(async (acc, accountType) => {
      const accountsForType = groupedAccounts[accountType];
      const previousResult = await acc;

      if (!previousResult[accountType]) {
        previousResult[accountType] = [];
      }

      for (const account of accountsForType) {
        if (account.status === 'ONLINE') {
          const budgetSettings: CustomerBudget = await firstValueFrom(
            this.get({ accountId: account.$key, accountType }, 'budget')
          );

          previousResult[accountType].push({
            name: account.name,
            enabled: !!budgetSettings.enabled,
            ...(budgetByEase && {
              budgetByEase: !!(
                budgetByEase.id === account.$key &&
                budgetByEase.type === accountType
              )
            }),
            budget:
              budgetSettings && budgetSettings.budget
                ? budgetSettings.budget
                : null,
            budgetFormatted:
              budgetSettings && budgetSettings.budget
                ? formatCurrency(
                    budgetSettings.budget,
                    'en-us',
                    account.currency || 'USD'
                  )
                : null,
            $key: account.$key
          });
        }
      }

      return previousResult;
    }, Promise.resolve({}));
  }

  async assertCustomerAccountBudgetsEnabled(customerId: string): Promise<{
    enabled: boolean;
    accounts: CustomerAccountMeta[];
  }> {
    const allAccountSettings = await firstValueFrom(
      this.getAllAccountBudgetSettings(customerId)
    );

    if (allAccountSettings) {
      return Object.keys(allAccountSettings).reduce(
        (acc, accountType) => {
          const accounts = allAccountSettings[accountType];

          const accountsWithEnabled: CustomerAccountMeta[] = accounts
            .filter(accountSettings => accountSettings.enabled)
            .map(accountSettings => ({
              accountId: accountSettings.$key,
              accountType
            }));

          if (!acc.enabled) {
            acc.enabled = accountsWithEnabled.length > 0;
          }

          acc.accounts = [...acc.accounts, ...accountsWithEnabled];

          return acc;
        },
        {
          enabled: false as boolean,
          accounts: []
        }
      );
    } else {
      return {
        enabled: false as boolean,
        accounts: []
      };
    }
  }

  update(
    meta: CustomerAccountMeta,
    ruleName: string,
    ruleSettings: any
  ): Promise<void> {
    return this.angularFire
      .object(
        `/${getRulesPathForAccountType(meta.accountType)}/${
          meta.accountId
        }/${ruleName}`
      )
      .update(firebaseJSON(ruleSettings));
  }

  async updateBudget(
    meta: CustomerAccountMeta,
    budgetChange: Change<Partial<CustomerBudget>>
  ): Promise<any> {
    return this.update(meta, 'budget', budgetChange.after).then(() =>
      this.matSnackBar.open('Settings saved', 'Close', {
        duration: SNACKBAR_DURATION_SUCCESS
      })
    );
  }

  getSettingsForCplValue(costPerConversion: number): {
    enabled: boolean;
    costPerConversion: number;
  } {
    switch (costPerConversion !== null) {
      case costPerConversion > 0:
        return {
          enabled: true,
          costPerConversion
        };
      case costPerConversion === 0:
        return {
          enabled: false,
          costPerConversion
        };
      default:
        return this.defaultCpcSettings;
    }
  }
}
