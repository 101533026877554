import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Moment } from 'moment';
import { distinctUntilChanged } from 'rxjs';

import { TypedFormGroup } from '../reactive-forms';
import { InlineDateRangeValue } from './inline-date-range-picker.interface';

@UntilDestroy()
@Component({
  selector: 'ease-inline-date-range-picker',
  templateUrl: './inline-date-range-picker.component.html',
  styleUrls: ['./inline-date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlineDateRangePickerComponent),
      multi: true
    }
  ]
})
export class InlineDateRangePickerComponent
  implements ControlValueAccessor, OnInit
{
  @Input() minDate: Moment;
  @Input() maxDate: Moment;
  @Input() dateRangeForm: TypedFormGroup<DateRange<Moment>>;
  @Input() showActionButtons: boolean;
  @Output() dateChange: EventEmitter<DateRange<Moment>> = new EventEmitter<
    DateRange<Moment>
  >();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  public selectedRangeValue: DateRange<Moment>;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (!this.dateRangeForm) {
      this.dateRangeForm = this.formBuilder.group({
        start: this.formBuilder.control(null),
        end: this.formBuilder.control(null)
      });
    }

    this.dateRangeForm.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(
          (a, b) => a?.start?.isSame(b?.start) && a?.end?.isSame(b?.end)
        )
      )
      .subscribe(({ start, end }) => {
        const dateRange = new DateRange<Moment>(start, end);
        this.onChange(dateRange);
        this.dateChange.emit(dateRange);
      });
  }

  private setDateRange(range: InlineDateRangeValue): void {
    if (range?.start && range?.end && this.dateRangeForm) {
      const dateRange = new DateRange<Moment>(range.start, range.end);
      this.selectedRangeValue = dateRange;
      this.dateRangeForm.patchValue(dateRange, { emitEvent: false });
    }
  }

  selectedChange(date: Moment) {
    if (!!!this.selectedRangeValue?.start || !!this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Moment>(date, null);
    } else {
      const startDate = this.selectedRangeValue.start;
      const endDate = date;

      this.selectedRangeValue = new DateRange<Moment>(
        startDate.startOf('day'),
        endDate.endOf('day')
      );

      if (endDate.isBefore(startDate)) {
        this.selectedRangeValue = new DateRange<Moment>(
          endDate.startOf('day'),
          startDate.endOf('day')
        );
      }
    }

    // To avoid unnecessary emission, only emit when both start and end defined
    if (!!this.selectedRangeValue?.start && !!this.selectedRangeValue?.end) {
      this.dateRangeForm.patchValue(this.selectedRangeValue);
    }
  }

  clearDateRange(): void {
    const dateRange = new DateRange<Moment>(null, null);
    this.selectedRangeValue = dateRange;
    this.dateRangeForm.patchValue(dateRange);
  }

  onChange: (dateRange: DateRange<Moment> | null) => any = () => {};

  onTouched: () => any = () => {};

  writeValue(dateRangeValue: InlineDateRangeValue) {
    this.setDateRange(dateRangeValue);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.dateRangeForm.disable() : this.dateRangeForm.enable();
  }
}
