import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ScrollspyService } from './scrollspy.service';

@Directive({
  selector: '[easeScrollspySection]'
})
export class ScrollspySectionDirective implements OnInit, OnDestroy {
  @Input() easeScrollspySection: string;
  constructor(
    private element: ElementRef,
    private scrollSpyService: ScrollspyService
  ) {}

  ngOnInit(): void {
    this.scrollSpyService.addSection({
      id: this.easeScrollspySection,
      element: this.element.nativeElement
    });
  }

  ngOnDestroy(): void {
    this.scrollSpyService.removeSection(this.easeScrollspySection);
  }
}
