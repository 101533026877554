import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Change } from 'src/app/shared/shared.interface';
import { Observable } from 'rxjs';
import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import {
  CustomerAccountCost,
  CustomerBudget
} from '../customer-account-budget/customer-account-budget.interface';
import { CustomerAccountRulesService } from '../customer-account-rules.service';
import { CustomerAccountMeta } from '../customer-accounts.interface';

@Component({
  selector: 'ease-customer-account-budget-wrapper',
  templateUrl: './customer-account-budget-wrapper.component.html',
  styleUrls: ['./customer-account-budget-wrapper.component.scss']
})
export class CustomerAccountBudgetWrapperComponent implements OnInit {
  @Input() accountId;
  @Input() accountType;
  @Output() cancel: EventEmitter<any> = new EventEmitter<void>();
  @Output() save: EventEmitter<any> = new EventEmitter<void>();
  public budgetSettings$: Observable<CustomerBudget>;
  public costSummary: CustomerAccountCost;
  public customerId: string;

  constructor(
    private customerAccountRulesService: CustomerAccountRulesService,
    private entityUtilsService: EntityUtilsService
  ) {}

  ngOnInit() {
    this.entityUtilsService
      .getCustomerIdForAccount({
        accountId: this.accountId,
        accountType: this.accountType
      })
      .then(customerId => (this.customerId = customerId));

    this.budgetSettings$ = this.customerAccountRulesService.get(
      { accountId: this.accountId, accountType: this.accountType },
      'budget'
    );
    this.getCostSummary({
      accountId: this.accountId,
      accountType: this.accountType
    });
  }

  updateBudget(budgetChange: Change<Partial<CustomerBudget>>) {
    this.customerAccountRulesService
      .updateBudget(
        { accountId: this.accountId, accountType: this.accountType },
        budgetChange
      )
      .then(success => {
        this.save.emit(budgetChange);
      });
    this.getCostSummary({
      accountId: this.accountId,
      accountType: this.accountType
    });
  }

  getCostSummary({ accountId, accountType }: CustomerAccountMeta) {
    this.customerAccountRulesService
      .getCostSummary({ accountId, accountType })
      .then(summary => (this.costSummary = summary));
  }

  cancelEmitter() {
    this.cancel.emit();
  }
}
