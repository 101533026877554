import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

import { UserModel } from 'src/app/users/user.model';
import { UserService } from 'src/app/users/user.service';
import { UserSelected } from '../../user-selector/user-selector.interface';
import { TargetMember } from '../bulk-targeting.interface';

@Component({
  selector: 'ease-bulk-targeting-users',
  templateUrl: './bulk-targeting-users.component.html',
  styleUrls: ['./bulk-targeting-users.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BulkTargetingUsersComponent
    }
  ]
})
export class BulkTargetingUsersComponent implements OnInit {
  @Input() disabled = false;
  @Input() onlyUsers: boolean;
  private touched = false;
  public users: TargetMember[] = [];
  private allUsers: { [userId: string]: UserModel };
  public selectedUsers: string[] = [];

  constructor(public userService: UserService) {}

  ngOnInit(): void {}

  /**
   * Only adds the user when the ID exists and if they're not currently
   * a target
   *
   * @param id
   */
  async addUser(userSelected: UserSelected, name?: string): Promise<void> {
    const { userId: id } = userSelected;
    if (this.selectedUsers.includes(id)) {
      return;
    }

    if (!this.allUsers) {
      this.allUsers = await firstValueFrom(this.userService.usersAsObject);
    }

    this.users = [...this.users, { id, name: name || this.allUsers[id]?.name }];
    this.selectedUsers = [...this.selectedUsers, id];
    this.onChange(this.getValue());
  }

  removeUser(id: string) {
    this.users.splice(
      this.users.findIndex(user => user.id === id),
      1
    );
    this.selectedUsers.splice(this.selectedUsers.indexOf(id), 1);
    this.onChange(this.getValue());
  }

  getValue(): TargetMember[] {
    return this.users;
  }

  /**
   * ControlValueAccessor
   */
  writeValue(members: TargetMember[]): void {
    this.markAsTouched();
    const method = 'direct';

    members.map(member => {
      // this allows setting the value without the need of provided user metadata (name)
      switch (typeof member) {
        case 'string':
          this.addUser({ userId: member, method });
          break;
        case 'object':
          this.addUser({ userId: member.id, method }, member.name);
      }
    });
  }

  onChange = value => {};

  onTouched = () => {};

  registerOnChange(onChangeFn) {
    this.onChange = onChangeFn;
  }

  registerOnTouched(onTouchedFn) {
    this.onTouched = onTouchedFn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
