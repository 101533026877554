import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountIconComponent } from './account-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AccountIconComponent],
  exports: [AccountIconComponent]
})
export class AccountIconModule {}
