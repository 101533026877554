import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';

import { AdvancedAutocompleteModule } from 'src/app/shared/advanced-autocomplete/advanced-autocomplete.module';
import { MaterialChipsInputModule } from 'src/app/shared/material-chips-input/material-chips-input.module';
import { RichTooltipModule } from 'src/app/shared/rich-tooltip/rich-tooltip.module';
import { UserSelectorInputModule } from 'src/app/shared/user-selector-input/user-selector-input.module';
import { FinderModule } from '../../shared/finder/finder.module';
import { MarkdownModule } from '../../shared/markdown/markdown.module';
import { SharedFormModule } from '../../shared/shared-form.module';
import { SharedModule } from '../../shared/shared.module';
import { TaskPhaseModule } from '../task-phase/task-phase.module';
import { TaskStatusModule } from '../task-status/task-status.module';
import { TaskUserMenuModule } from '../task-user-menu/task-user-menu.module';

import { TaskProjectModule } from '../task-project/task-project.module';
import { TaskEditFormComponent } from './task-edit-form.component';
import { TaskRoleSelectorComponent } from './task-role-selector/task-role-selector.component';

@NgModule({
  imports: [
    DragDropModule,
    FinderModule,
    MarkdownModule,
    MaterialChipsInputModule,
    RichTooltipModule,
    SharedFormModule,
    SharedModule,
    TaskPhaseModule,
    TaskStatusModule,
    TaskUserMenuModule,
    UserSelectorInputModule,
    AdvancedAutocompleteModule,
    TaskProjectModule
  ],
  declarations: [TaskEditFormComponent, TaskRoleSelectorComponent],
  exports: [TaskEditFormComponent, TaskRoleSelectorComponent]
})
export class TaskEditFormModule {}
