<form novalidate [formGroup]="form" *ngIf="form">
  <div
    class="flex flex-row items-center"
    *ngFor="
      let checklistInput of previewMode ? template?.fields : fields;
      let index = index;
      trackBy: getKey
    "
    [easeIndexer]="{
      index: previewMode ? index : null,
      scrollTo: true
    }"
  >
    <div class="w-full flex-1" [ngSwitch]="checklistInput?.type">
      <div *ngSwitchCase="'sectionHeadingDivider'">
        <div
          class="flex flex-row"
          [ngClass]="
            checklistInput?.hideExpression
              | getHideExpressionClass: form?.value:previewMode
          "
          (click)="toggleCollapseStatus(checklistInput)"
        >
          <h1 class="task-checklist__section-heading-divider flex-1">
            {{ checklistInput?.templateOptions?.label }}
          </h1>
          <button
            mat-icon-button
            class="pointer-events-none !mt-4 !w-6 md:!w-auto"
            *ngIf="collapseStatus[checklistInput.$dbKey]"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>

          <button
            mat-icon-button
            class="pointer-events-none !mt-4 !w-6 md:!w-auto"
            *ngIf="!collapseStatus[checklistInput.$dbKey]"
          >
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
        <div *ngIf="!collapseStatus[checklistInput.$dbKey]">
          <ease-checklist-fields-renderer
            *ngIf="!previewMode"
            [fields]="checklistInput.fields"
            [form]="form"
            [template]="template"
            [taskId]="taskId"
            (actionTriggered)="actionTriggered.emit($event)"
          ></ease-checklist-fields-renderer>
        </div>
      </div>
      <div *ngSwitchCase="'sectionHeading'">
        <h3
          class="task-checklist__section-heading"
          [ngClass]="
            checklistInput?.hideExpression
              | getHideExpressionClass: form?.value:previewMode
          "
        >
          {{ checklistInput?.templateOptions?.label }}
        </h3>
      </div>
      <div *ngSwitchCase="'toggle'">
        <div
          [ngClass]="
            checklistInput?.hideExpression
              | getHideExpressionClass: form?.value:previewMode
          "
          class="my-1 flex min-h-[39px] flex-row items-center gap-2"
        >
          <mat-icon
            matTooltip="Switching this toggle will perform the action described automatically"
            *ngIf="checklistInput?.templateOptions?.actionKey"
            >offline_bolt</mat-icon
          >
          <mat-slide-toggle
            class="!m-0 !h-max"
            color="primary"
            [formControlName]="checklistInput?.key"
            (toggleChange)="
              handleActionButtonClick(
                checklistInput?.templateOptions?.actionKey,
                form?.get(checklistInput?.key)
              )
            "
          >
            <span
              [easeMarkdown]="checklistInput?.templateOptions?.label"
              class="whitespace-normal text-sm md:text-base"
            ></span>
          </mat-slide-toggle>
        </div>
      </div>
      <div *ngSwitchCase="'inputHorizontal'">
        <div
          [ngClass]="
            checklistInput?.hideExpression
              | getHideExpressionClass: form?.value:previewMode
          "
          class="form-group-xs"
        >
          <mat-form-field>
            <input
              [formControlName]="checklistInput?.key"
              [placeholder]="checklistInput?.templateOptions?.label"
              matInput
            />
          </mat-form-field>
        </div>
      </div>
      <div *ngSwitchCase="'checklistCheckbox'">
        <div
          [ngClass]="
            checklistInput?.hideExpression
              | getHideExpressionClass: form?.value:previewMode
          "
        >
          <ease-task-checklist-checkbox
            [group]="$any(form)?.get(checklistInput?.key)"
            [value]="$any(form)?.get(checklistInput?.key)?.value"
            [templateOptions]="checklistInput?.templateOptions"
            (actionClicked)="
              handleActionButtonClick(
                checklistInput?.templateOptions?.actionKey,
                form?.get(checklistInput?.key)
              )
            "
          >
          </ease-task-checklist-checkbox>
        </div>
      </div>
      <div *ngSwitchCase="'checkboxRadio'">
        <div
          [ngClass]="
            checklistInput?.hideExpression
              | getHideExpressionClass: form?.value:previewMode
          "
        >
          <ease-task-checklist-checkbox-radio
            [group]="$any(form)?.get(checklistInput?.key)"
            [value]="$any(form)?.get(checklistInput?.key)?.value"
            [templateOptions]="checklistInput?.templateOptions"
            (actionClicked)="
              handleActionButtonClick(
                checklistInput?.templateOptions?.actionKey,
                form?.get(checklistInput?.key)
              )
            "
          >
          </ease-task-checklist-checkbox-radio>
        </div>
      </div>
      <div *ngSwitchCase="'checkboxInput'">
        <div
          [ngClass]="
            checklistInput?.hideExpression
              | getHideExpressionClass: form?.value:previewMode
          "
        >
          <ease-task-checklist-checkbox-input
            [group]="$any(form)?.get(checklistInput?.key)"
            [value]="$any(form)?.get(checklistInput?.key)?.value"
            [templateOptions]="checklistInput?.templateOptions"
            (actionClicked)="
              handleActionButtonClick(
                checklistInput?.templateOptions?.actionKey,
                form?.get(checklistInput?.key)
              )
            "
          >
          </ease-task-checklist-checkbox-input>
        </div>
      </div>
      <div *ngSwitchCase="'checkboxList'">
        <div
          [ngClass]="
            checklistInput?.hideExpression
              | getHideExpressionClass: form?.value:previewMode
          "
        >
          <ease-task-checklist-checkbox-list
            [group]="$any(form)?.get(checklistInput?.key)"
            [value]="$any(form)?.get(checklistInput?.key)?.value"
            [templateOptions]="checklistInput?.templateOptions"
            (actionClicked)="
              handleActionButtonClick(
                checklistInput?.templateOptions?.actionKey,
                form?.get(checklistInput?.key)
              )
            "
          >
          </ease-task-checklist-checkbox-list>
        </div>
      </div>
      <div *ngSwitchCase="'actionButton'">
        <div
          [ngClass]="
            checklistInput?.hideExpression
              | getHideExpressionClass: form?.value:previewMode
          "
        >
          <ease-task-checklist-actionbutton
            [group]="$any(form)?.get(checklistInput?.key)"
            [value]="$any(form)?.get(checklistInput?.key)?.value"
            [templateOptions]="checklistInput?.templateOptions"
            (actionClicked)="
              handleActionButtonClick(
                checklistInput?.templateOptions?.actionKey,
                form?.get(checklistInput?.key)
              )
            "
          >
          </ease-task-checklist-actionbutton>
        </div>
      </div>
      <div *ngSwitchDefault>CODE NEEDED {{ checklistInput?.type }}</div>
    </div>
  </div>
</form>
