import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { TypedFormArray } from 'src/app/shared/reactive-forms';
import { EntityAssign } from 'src/app/admin/entity-assign/entity-assign.interface';
import { AccountBasicMeta } from '../../customers/customer-accounts/customer-accounts.interface';
import { Customer } from '../../customers/customer.interface';
import { SNACKBAR_DURATION_ERROR } from '../../shared/constants';
import { ScheduledTaskService } from '../../tasks/scheduled-tasks/scheduled-task.service';
import { TaskService } from '../../tasks/task.service';
import { UserModel, UserRetireEntities } from '../user.model';
import { UserService } from '../user.service';

@UntilDestroy()
@Component({
  selector: 'ease-retire-user-dialog',
  templateUrl: './retire-user-dialog.component.html',
  styleUrls: ['./retire-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetireUserDialogComponent implements OnInit {
  @HostBinding('class') hostClass =
    'max-h-[80vh] flex flex-col overflow-y-auto';
  public users$: Observable<UserModel[]>;
  public filteredUsers$: Observable<UserModel[]>;
  public retireForm: FormGroup<{
    sourceUser: FormControl<UserModel>;
    destinationUser: FormControl<UserModel>;
    customers: TypedFormArray<EntityAssign>;
    accountsByManager: TypedFormArray<EntityAssign>;
    accountsByReviewer: TypedFormArray<EntityAssign>;
  }>;

  public customersByRm$: Observable<Customer[]>;
  public accountsByManager$: Observable<AccountBasicMeta[]>;
  public showCustomers: boolean = false;
  public showAccounts: boolean = false;
  public loadingCustomers: boolean = false;
  public loadingAccounts: boolean = false;
  private sourceUser$: Observable<UserModel>;
  public isWorking: boolean = false;
  public totalNumberOfTasks: number;
  public scheduledTasks: number;

  constructor(
    private dialogRef: MatDialogRef<RetireUserDialogComponent>,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private mdSnackBar: MatSnackBar,
    private scheduledTaskService: ScheduledTaskService,
    private taskService: TaskService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.retireForm = this.formBuilder.group({
      sourceUser: this.formBuilder.control(null, {
        validators: Validators.required
      }),
      customers: this.formBuilder.array<
        typeof this.retireForm.controls.customers.controls[0]
      >([]),
      accountsByManager: this.formBuilder.array<
        typeof this.retireForm.controls.accountsByManager.controls[0]
      >([]),
      accountsByReviewer: this.formBuilder.array<
        typeof this.retireForm.controls.accountsByReviewer.controls[0]
      >([]),
      destinationUser: this.formBuilder.control(null, {
        validators: Validators.required
      })
    });

    this.users$ = this.userService.users.pipe(
      map(users =>
        users.sort((userA, userB) => {
          if (userA.name < userB.name) {
            return -1;
          }
          if (userA.name > userB.name) {
            return 1;
          }
          return 0;
        })
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.sourceUser$ = this.retireForm.controls.sourceUser.valueChanges.pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.filteredUsers$ = combineLatest([this.users$, this.sourceUser$]).pipe(
      map(([users, sourceUser]) =>
        users.filter(user => user.$key !== sourceUser.$key)
      )
    );

    this.sourceUser$
      .pipe(
        switchMap(user =>
          combineLatest([
            this.taskService
              .getIdsForAssignedUser(user.$key)
              .then(ids => ids.length),
            this.taskService
              .getIdsForSubscribedUser(user.$key)
              .then(ids => ids.length),
            this.scheduledTaskService
              .getForUser(user.$key)
              .pipe(map(tasks => tasks.length))
          ])
        ),
        tap(
          ([
            numberOfAssignedTasks,
            numberOfSubscribedTasks,
            numberOfScheduledTasks
          ]) => {
            this.totalNumberOfTasks =
              numberOfAssignedTasks + numberOfSubscribedTasks;
            this.scheduledTasks = numberOfScheduledTasks;
            this.cdr.detectChanges();
          }
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }

  submit() {
    this.isWorking = true;
    const { sourceUser, destinationUser, ...rest } = this.retireForm.value;

    firstValueFrom(
      this.userService.retireUser({
        ...(rest as UserRetireEntities),
        sourceUserId: sourceUser?.$key,
        destinationUserId: destinationUser?.$key
      })
    )
      .then(() => {
        this.dialogRef.close(sourceUser);
        this.isWorking = false;
      })
      .catch(error => {
        this.mdSnackBar.open(`${error.message}`, 'Close', {
          duration: SNACKBAR_DURATION_ERROR
        });
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
