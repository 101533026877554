import { Client } from 'typesense';
import {
  SearchParams,
  SearchOptions,
  SearchResponse
} from 'typesense/lib/Typesense/Documents';
import {
  MultiSearchRequestSchema,
  MultiSearchRequestsSchema,
  MultiSearchResponse
} from 'typesense/lib/Typesense/MultiSearch';
import { CollectionSchema } from 'typesense/lib/Typesense/Collection';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { UserService } from '../../users/user.service';
import {
  TypesenseSearchResponse,
  TypesenseCollection,
  TypesenseCollectionDefaults,
  SearchHook
} from './search.interface';

@Injectable({ providedIn: 'root' })
export class SearchService {
  private searchConstants: Record<string, string | number>;
  private typesenseClient = new Client(environment.TYPESENSE);
  private defaultSearchHooks: SearchHook[] = [
    {
      hook: results => this.applyCollectionQueryDefaultsHook(results)
    },
    {
      hook: results => this.replaceSearchConstantHook(results)
    }
  ];

  public collectionQueryDefaults: TypesenseCollectionDefaults = {
    contacts: {
      queryBy: [
        'firstName',
        'lastName',
        'email',
        'emailSuffix',
        'phone',
        'phoneSecondary'
      ]
    },
    customers: {
      queryBy: ['name', 'customerId', 'reference'],
      numTypos: [2, 0, 0]
    },
    accounts: {
      queryBy: ['name', 'accountId'],
      numTypos: [2, 0]
    },
    customerFeeds: {
      queryBy: [
        'name',
        'completionMessage',
        'body',
        'description',
        'details',
        'itemTypeSeparated'
      ]
    },
    tasks: {
      queryBy: ['name', 'entityName'],
      numTypos: [0, 0]
    }
  };

  constructor(private userService: UserService) {}

  public async getCollection(
    collectionName: TypesenseCollection
  ): Promise<CollectionSchema> {
    return this.typesenseClient
      .collections(this.getCollectionNameWithEnvironment(collectionName))
      .retrieve();
  }

  public async createMultiple<T>(
    queries: MultiSearchRequestsSchema,
    extraHooks: SearchHook[] = []
  ): Promise<MultiSearchResponse<T>> {
    const parsedQueries =
      // Reduce the array of hooks into a single promise that resolves to the final queries object
      await [...this.defaultSearchHooks, ...extraHooks].reduce(
        // Asynchronously apply the current hook to the previous result
        async (acc, searchHook) =>
          // Call the hook function with `this` and the previous result as arguments
          await searchHook.hook(acc instanceof Promise ? await acc : acc),
        // Initialize the reduce function with the queries object, which may or may not be a promise
        queries as
          | MultiSearchRequestsSchema
          | Promise<MultiSearchRequestsSchema>
      );

    return this.typesenseClient.multiSearch.perform(
      parsedQueries
    ) as unknown as Promise<MultiSearchResponse<T>>;
  }

  applyCollectionQueryDefaultsHook(
    queries: MultiSearchRequestsSchema
  ): MultiSearchRequestsSchema {
    return {
      searches: queries.searches.map(query => {
        query = this.applyCollectionQueryDefaults(
          query,
          query.collection as TypesenseCollection
        );

        /**
         * Important that this comes last so we don't affect
         * the collectionQueryByDefaults lookup above
         */
        query.collection = this.getCollectionNameWithEnvironment(
          query.collection as TypesenseCollection
        );

        /**
         * Exclude the `id` field from Typesense results because
         * we set $key to the same value when indexing the data.
         */
        query.exclude_fields = ['id'].join(',');

        return query;
      })
    };
  }

  replaceSearchConstantHook(
    queries: MultiSearchRequestsSchema
  ): MultiSearchRequestsSchema {
    return {
      searches: this.replaceSearchConstants(
        queries.searches
      ) as MultiSearchRequestSchema[]
    };
  }

  public async create<T>(
    collectionName: TypesenseCollection,
    params: Partial<SearchParams>,
    options?: SearchOptions
  ): TypesenseSearchResponse<T> {
    let finalParams = params as SearchParams;
    finalParams = this.applyCollectionQueryDefaults(
      finalParams,
      collectionName
    );

    /**
     * Exclude the `id` field from Typesense results because
     * we set $key to the same value when indexing the data.
     */
    finalParams.exclude_fields = ['id'].join(',');
    finalParams = this.replaceSearchConstants(finalParams) as SearchParams;

    return this.typesenseClient
      .collections<T>(this.getCollectionNameWithEnvironment(collectionName))
      .documents()
      .search(finalParams, options) as TypesenseSearchResponse<T>;
  }

  public getPageCount(searchResponse: SearchResponse<any>): number {
    const result =
      searchResponse.found / searchResponse.request_params.per_page;
    if (Number.isFinite(result)) {
      return Math.ceil(result);
    } else {
      return 1;
    }
  }

  public getCollectionNameWithEnvironment(
    collection: TypesenseCollection
  ): string {
    return `${environment.TYPESENSE_PREFIX}${collection}`;
  }

  public getCollectionNameWithoutEnvironment(
    collection: string
  ): TypesenseCollection | null {
    return collection
      ? (collection.replace(
          environment.TYPESENSE_PREFIX,
          ''
        ) as TypesenseCollection)
      : null;
  }

  /**
   * Populate search constants that are used as replacements
   * at query-time to support dynamic values
   */
  private populateConstants(): void {
    this.searchConstants = {
      CURRENT_USER: this.userService.currentUser.$key,
      TODAY: moment().startOf('day').valueOf(),
      YESTERDAY: moment().subtract(1, 'day').endOf('day').valueOf(),
      NEXT_30_DAYS: moment().add('30', 'days').endOf('day').valueOf(),
      NEXT_14_DAYS: moment().add('14', 'days').endOf('day').valueOf(),
      NEXT_7_DAYS: moment().add('7', 'days').endOf('day').valueOf(),
      START_CURRENT_MONTH: moment().startOf('month').valueOf(),
      END_CURRENT_MONTH: moment().endOf('month').valueOf(),
      START_NEXT_MONTH: moment().add('1', 'month').startOf('month').valueOf(),
      END_NEXT_MONTH: moment().add('1', 'month').endOf('month').valueOf()
    };
  }

  /**
   * Search a query definition for constants (e.g. CURRENT_USER) and replace them with dynamic values
   * as defined in `this.searchConstants`.
   *
   * @param search The query definition to be searched for constant value replacement
   * @returns The query definition with all constants replaced by values
   */
  private replaceSearchConstants(
    search: MultiSearchRequestSchema[] | SearchParams
  ): MultiSearchRequestSchema[] | SearchParams {
    this.populateConstants();

    let searchToReplace = JSON.stringify(search);
    const regex = new RegExp(Object.keys(this.searchConstants).join('|'), 'g');
    searchToReplace = searchToReplace.replace(
      regex,
      matched => `${this.searchConstants[matched]}`
    );

    return JSON.parse(searchToReplace);
  }

  private applyCollectionQueryDefaults(
    query: SearchParams,
    collection: TypesenseCollection
  ): SearchParams {
    if (
      query.query_by ||
      this.collectionQueryDefaults[collection as TypesenseCollection]?.queryBy
    ) {
      query.query_by =
        query.query_by ||
        this.collectionQueryDefaults[
          collection as TypesenseCollection
        ]?.queryBy.join(',');
    }

    if (
      query.num_typos ||
      this.collectionQueryDefaults[collection as TypesenseCollection]?.numTypos
    ) {
      query.num_typos =
        query.num_typos ||
        this.collectionQueryDefaults[
          collection as TypesenseCollection
        ].numTypos.join(',');
    }

    return query;
  }
}
