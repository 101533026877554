import * as RRule from 'rrule';
import { ListDisplayItem } from './shared.interface';

export const COLOR_CHOICES = [
  'grey',
  'purple',
  'green',
  'orange',
  'pink',
  'blue',
  'red',
  'black'
];

export const ACCOUNT_FEED_TYPES = ['adwords', 'lsa', 'bing'];

export const SNACKBAR_DURATION_SUCCESS = 3000;

export const SNACKBAR_DURATION_ERROR = 6000;

export const ICON_FOR_STATUS = {
  ONLINE: 'play_arrow',
  PAUSED: 'pause',
  OFFLINE: 'stop',
  NEW: 'priority_high',
  ENGAGED: 'email',
  QUOTED: 'insert_drive_file',
  WON: 'attach_money',
  LOST: 'sentiment_dissatisfied',
  ONBOARDING: 'account_tree'
};

export const LOGO_FILE_FOR_ACCOUNT_TYPE = {
  adwords: 'google-ads-logo.svg',
  analytics: 'analytics-logo.svg',
  bing: 'bing-logo.svg',
  callTracking: 'call-tracking-logo.svg',
  lsa: 'google-green-shield.svg',
  searchKings: 'crown.png',
  tagManager: 'google-tag-manager-logo.png'
};

export const COMPANY_REGIONS: {
  name: string;
  value: string;
}[] = [
  { name: 'South Africa', value: 'southAfrica' },
  { name: 'Canada', value: 'canada' }
];

export const RRULE_FREQUENCIES = [
  { text: 'days', value: RRule.DAILY },
  { text: 'weeks', value: RRule.WEEKLY },
  { text: 'months', value: RRule.MONTHLY },
  { text: 'years', value: RRule.YEARLY }
];

export const BASIC_ENTITY_STATUSES: ListDisplayItem[] = [
  { value: 'ONBOARDING', viewValue: 'Onboarding' },
  { value: 'ONLINE', viewValue: 'Online' },
  { value: 'PAUSED', viewValue: 'Paused' },
  { value: 'OFFLINE', viewValue: 'Offline' }
];

export const PROSPECT_STATUSES: ListDisplayItem[] = [
  { value: 'LOST', viewValue: 'Lost' },
  { value: 'NEW', viewValue: 'New' },
  { value: 'ENGAGED', viewValue: 'Engaged' },
  { value: 'QUOTED', viewValue: 'Quoted' },
  { value: 'ONBOARDING', viewValue: 'Onboarding' },
  { value: 'WON', viewValue: 'Won' }
];
