<ng-container *ngFor="let section of items">
  <h4 class="flex flex-row items-center justify-start">
    <mat-icon class="mr-2 text-orange-500">warning</mat-icon>
    <div>{{ section.reason }} ({{ section?.items?.length }})</div>
  </h4>
  <ease-list-group size="sm">
    <ease-list-group-item
      *ngFor="let item of section?.items"
      class="flex flex-row items-center justify-start"
    >
      <div class="flex-1">
        <div>{{ item.campaign }}</div>
        <div class="text-sm text-gray-400">{{ item.adGroup }}</div>
      </div>
      <div *ngFor="let reason of item?.evidence" class="text-right">
        <div>"{{ reason.value }}"</div>
        <div class="text-sm text-gray-400">{{ reason.trigger }}</div>
      </div>
      <div *ngIf="!item?.evidence" class="text-sm text-gray-400">
        No details given
      </div>
    </ease-list-group-item>
  </ease-list-group>
</ng-container>
