import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuDirective } from './context-menu.directive';
import { ContextMenuComponent } from './context-menu.component';

@NgModule({
  declarations: [ContextMenuDirective, ContextMenuComponent],
  imports: [CommonModule],
  exports: [ContextMenuDirective]
})
export class ContextMenuModule {}
