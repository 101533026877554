import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewportOffsetReceiverDirective } from './viewport-offset-receiver.directive';
import { ViewportOffsetPusherDirective } from './viewport-offset-pusher.directive';

@NgModule({
  declarations: [
    ViewportOffsetReceiverDirective,
    ViewportOffsetPusherDirective
  ],
  imports: [CommonModule],
  exports: [ViewportOffsetReceiverDirective, ViewportOffsetPusherDirective]
})
export class ViewportOffsetModule {}
