import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AnimationEvent,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AnnouncementsService } from 'src/app/announcements/announcements.service';
import { ViewportOffsetPusherDirective } from 'src/app/shared/viewport-offset/viewport-offset-pusher.directive';
import { Announcement } from '../announcements.interface';
import { AnnouncementsDetailComponent } from '../announcements-detail/announcements-detail.component';
import {
  activeStyle,
  bringForwardSteps,
  bringToFrontSteps,
  defaultStyle,
  emptyStyle,
  inactiveStyle,
  markedAsRead,
  newToAnything,
  nextStyle,
  prevStyle,
  sendBackwardSteps,
  sendToBackSteps,
  sendToNextSteps,
  soloStyle,
  toActive,
  toDefault,
  toEmpty,
  toInactive,
  toNext,
  toPrev,
  toUnitSet,
  unitSetStyle
} from './announcements-bar.animations';

type WrapperState = 'default' | 'unitSet' | 'empty';
type CardState = 'prev' | 'active' | 'next' | 'inactive' | 'second' | 'solo';

@Component({
  selector: 'ease-announcements-bar',
  templateUrl: './announcements-bar.component.html',
  styleUrls: ['./announcements-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('card', [
      state('prev', style(prevStyle)),
      state('active', style(activeStyle)),
      state('next', style(nextStyle)),
      state('inactive', style(inactiveStyle)),
      state('second', style(nextStyle)),
      state('solo', style(soloStyle)),

      // next transitions
      transition('inactive => next', toNext),
      transition('next => active', bringForwardSteps),
      transition('active => prev', sendToBackSteps),

      // previous transitions
      transition('inactive => prev', toPrev),
      transition('prev => active', bringToFrontSteps),
      transition('active => next', sendBackwardSteps),

      // 3 announcement transitions
      transition('next => prev', toPrev),
      transition('prev => next', toNext),

      // 2 announcement transitions
      transition('active => second', sendToNextSteps),
      transition('second => active', bringForwardSteps),

      // new announcements
      transition('void => prev', [...newToAnything, ...toPrev]),
      transition('void => active', [...newToAnything, ...toActive]),
      transition('void => next', [...newToAnything, ...toNext]),
      transition('void => inactive', [...newToAnything, ...toInactive]),

      // misc transitions
      transition('* => inactive', toInactive),
      transition('* => void', markedAsRead)
    ]),

    // animations are executed sequentially
    trigger('wrapper', [
      state('default', style(defaultStyle)),
      state('unitSet', style(unitSetStyle)),
      state('empty', style(emptyStyle)),

      transition('* => default', toDefault),
      transition('* => unitSet', toUnitSet),
      transition('* => empty', toEmpty)
    ])
  ]
})
export class AnnouncementsBarComponent implements OnInit {
  @ViewChild(ViewportOffsetPusherDirective)
  viewOffset: ViewportOffsetPusherDirective;
  public allUnread$: Observable<Announcement[]>;
  public wrapperState: WrapperState;
  public cardState: CardState[];
  public isWrapperReady: boolean = false;

  constructor(
    private announcementsService: AnnouncementsService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.wrapperState = 'empty';
    this.cardState = [];

    this.allUnread$ = this.announcementsService.getActive().pipe(
      map(allActive => this.findAllUnread(allActive)),
      tap(allUnread => this.initState(allUnread))
    );
  }

  getWrapperState(length: number): WrapperState {
    switch (length) {
      case 0:
        return 'empty';
      case 1:
        return 'unitSet';
      default:
        return 'default';
    }
  }

  /**
   * Initializes both the wrapper and card animation states
   * depending on the number of unread announcements. When the
   * state of the wrapper changes, this disables the card animations
   * before it gets triggered.
   *
   * @param allUnread
   */
  initState(allUnread: Announcement[]): void {
    const newWrapperState = this.getWrapperState(allUnread.length);
    if (this.wrapperState !== newWrapperState) {
      this.wrapperState = newWrapperState;
      this.isWrapperReady = false;
    }

    this.cardState.length = 0;
    allUnread.forEach(() => {
      this.cardState.push('inactive');
    });

    switch (this.cardState.length) {
      case 1:
        this.cardState[0] = 'solo';
        break;
      case 2:
        this.cardState[0] = 'active';
        this.cardState[1] = 'second';
        break;
      default:
        this.cardState[allUnread.length - 1] = 'prev';
        this.cardState[0] = 'active';
        this.cardState[1] = 'next';
    }

    this.cdr.detectChanges();
  }

  findAllUnread(allActive: Announcement[]): Announcement[] {
    return allActive.filter(active =>
      active.markedBy
        ? !this.announcementsService.isMarkedRead(active.markedBy)
        : true
    );
  }

  navigate(direction: 'prev' | 'next'): void {
    switch (direction) {
      case 'prev':
        this.cardState.push(this.cardState.shift());
        break;
      case 'next':
        this.cardState.unshift(this.cardState.pop());
        break;
    }
    this.cdr.detectChanges();
  }

  openDetails(id: string): void {
    const dialogRef = this.matDialog.open(AnnouncementsDetailComponent, {
      width: '80vw',
      height: '80vh',
      panelClass: 'trim-dialog'
    });
    dialogRef.componentInstance.announcementId = id;
  }

  /**
   * Re-enables the card transitions after the wrapper is done
   * animating. The wrapper always start with a transition from
   * "void" to "empty" -- skipping the complete event of this
   * transition ensures that the cards won't animate on load.
   *
   * @param event
   */
  enableCardTransitions(event: AnimationEvent) {
    if (event.fromState !== 'void') {
      this.isWrapperReady = true;
    }
    this.viewOffset && this.viewOffset.updateResizedRoofHeight();
    this.cdr.detectChanges();
  }

  getKey(index: number, announcement: Announcement) {
    return announcement.$key;
  }
}
