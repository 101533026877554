import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ease-customer-websites-popover',
  templateUrl: './customer-websites-popover.component.html',
  styleUrls: ['./customer-websites-popover.component.scss']
})
export class CustomerWebsitesPopoverComponent implements OnInit {
  @Input()
  customerId: string;

  constructor() {}

  ngOnInit() {}
}
