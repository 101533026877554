<div class="mb-5 flex flex-1 flex-col items-center md:flex-row">
  <h3 class="target-title m-0">{{ title }}</h3>
  <div class="flex-grow-[2]"></div>
  <div class="mt-2 flex-grow sm:mt-0">
    <mat-button-toggle-group [formControl]="level" class="w-full">
      <mat-button-toggle
        *ngIf="targetLevels.includes('company')"
        value="company"
        class="flex-1"
      >
        <div class="flex items-center text-[14px] font-medium">
          <img
            class="mr-2 hidden w-[24px] max-w-none sm:block"
            src="/assets/images/crown.png"
          />
          SK {{ userService.currentUser.region | capitalize }}
        </div>
      </mat-button-toggle>
      <mat-button-toggle
        *ngIf="targetLevels.includes('user')"
        value="user"
        class="flex-1"
      >
        <ng-container
          [ngTemplateOutlet]="toggle"
          [ngTemplateOutletContext]="{
            $implicit: {
              label: 'Users',
              icon: 'portrait'
            }
          }"
        ></ng-container>
      </mat-button-toggle>
      <mat-button-toggle
        *ngIf="targetLevels.includes('customer')"
        value="customer"
        class="flex-1"
      >
        <div class="flex items-center text-[14px] font-medium">
          <mat-icon class="mr-2 !hidden sm:!block">person_pin</mat-icon>
          Customers
        </div>
      </mat-button-toggle>
      <mat-button-toggle
        *ngIf="targetLevels.includes('prospect')"
        value="prospect"
        class="flex-1"
      >
        <ng-container
          [ngTemplateOutlet]="toggle"
          [ngTemplateOutletContext]="{
            $implicit: {
              label: 'Prospects',
              icon: 'ring_volume'
            }
          }"
        ></ng-container>
      </mat-button-toggle>
      <mat-button-toggle
        *ngIf="targetLevels.includes('account')"
        value="account"
        class="flex-1"
      >
        <ng-container
          [ngTemplateOutlet]="toggle"
          [ngTemplateOutletContext]="{
            $implicit: {
              label: 'Accounts',
              icon: 'device_hub'
            }
          }"
        ></ng-container>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<div [ngClass]="{ 'min-h-[120px]': !level.value }">
  <mat-accordion *ngIf="currentLevel; else noTargets">
    <ng-container [ngSwitch]="currentLevel">
      <mat-expansion-panel *ngSwitchCase="'company'" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="items-center">
            <ng-container>
              <img class="mat-icon-20 mr-2" src="/assets/images/crown.png" />
              <span class="pr-1 text-base font-medium text-gray-900">
                SearchKings {{ members.value[0]?.id | capitalize }}
              </span>
            </ng-container>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="mt-4 flex flex-row justify-center rounded-md bg-gray-100 p-2 text-sm italic leading-6 text-gray-600"
        >
          Everyone at SearchKings {{ members.value[0]?.id | capitalize }} will
          see this announcement
        </div>
      </mat-expansion-panel>

      <ease-bulk-targeting-users
        *ngSwitchCase="'user'"
        [formControl]="members"
        [onlyUsers]="onlyUsers"
      ></ease-bulk-targeting-users>

      <ease-bulk-targeting-entity
        *ngSwitchCase="'customer'"
        entity="customer"
        [formControl]="members"
      ></ease-bulk-targeting-entity>

      <ease-bulk-targeting-entity
        *ngSwitchCase="'prospect'"
        entity="prospect"
        [formControl]="members"
      ></ease-bulk-targeting-entity>

      <ease-bulk-targeting-entity
        *ngSwitchCase="'account'"
        entity="account"
        [formControl]="members"
      ></ease-bulk-targeting-entity>
    </ng-container>
  </mat-accordion>
</div>

<ng-template #toggle let-item>
  <div class="flex items-center text-[14px] font-medium">
    <mat-icon class="mr-2 !hidden sm:!block">{{ item.icon }}</mat-icon>
    {{ item.label }}
  </div>
</ng-template>

<ng-template #noTargets>
  <ease-state-message
    [minHeight]="120"
    [message]="emptyMessage"
    class="rounded border border-dashed border-gray-400"
  ></ease-state-message>
</ng-template>
