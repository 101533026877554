<button (click)="move.emit()" mat-menu-item>
  <ng-container *ngIf="mode === 'assign'; else moveWatcher">
    <mat-icon>remove_red_eye</mat-icon>
    <span>Move to watchers</span>
  </ng-container>
  <ng-template #moveWatcher>
    <mat-icon>person_outline</mat-icon>
    <span>Move to assigned</span>
  </ng-template>
</button>
<button (click)="remove.emit()" mat-menu-item>
  <ng-container *ngIf="mode === 'assign'; else removeWatcher">
    <mat-icon>person_remove_alt_1</mat-icon>
    <span>Unassign</span>
  </ng-container>
  <ng-template #removeWatcher>
    <mat-icon>visibility_off</mat-icon>
    <span>Remove as watcher</span>
  </ng-template>
</button>
