import { Pipe, PipeTransform } from '@angular/core';

import { decamelize } from './utils/firebase-functions-common';

@Pipe({
  name: 'decamelize'
})
export class DecamelizePipe implements PipeTransform {
  transform(input: string, separator: string = ' ') {
    return input ? decamelize(input, separator) : '';
  }
}
