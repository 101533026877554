import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timezoneDateFormat'
})
export class TimezoneDateFormatPipe implements PipeTransform {
  transform(input: moment.MomentInput, format: string, timezone?: string) {
    return input
      ? timezone
        ? moment(input).tz(timezone).format(format)
        : moment(input).format(format)
      : '';
  }
}
