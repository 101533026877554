<ease-funding-summary
  [customerId]="customerId ? customerId : null"
  [isWidget]="true"
></ease-funding-summary>
<div class="actions flex flex-1 flex-row">
  <button
    (click)="addNewFunding()"
    mat-raised-button
    class="mr-2"
    color="primary"
    [disabled]="
      !!!(fundingService.fundingSummary$ | async)?.summary?.currentBalance ||
      disableFunding
    "
  >
    <mat-icon class="mr-2">add_circle_outline</mat-icon>
    Add New Funding
  </button>
  <button
    (click)="openFundingSettings(customerId)"
    mat-raised-button
    class="mr-2"
  >
    <mat-icon class="mr-2">settings</mat-icon>
    Modify Funding Settings
  </button>
  <button
    (click)="goToCustomerFunding(customerId ? customerId : null)"
    mat-raised-button
    class="mr-2"
  >
    <mat-icon class="mr-2">card_giftcard</mat-icon>
    View Funding History
  </button>
</div>
