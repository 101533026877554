import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { WindowPane } from 'src/app/shared/window/window-pane/window-pane';
import { FundingService } from '../../../shared/funding/funding.service';
import { FundingSummarySettingsDialogComponent } from '../../../shared/funding/funding-summary/funding-summary-settings/funding-summary-settings-dialog/funding-summary-settings-dialog.component';

@Component({
  selector: 'ease-task-funding',
  templateUrl: './task-funding.component.html',
  styleUrls: ['./task-funding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskFundingComponent implements OnInit {
  @Input() customerId: string;
  @Input() disableFunding: boolean = false;

  constructor(
    public fundingService: FundingService,
    private matDialog: MatDialog,
    private router: Router,
    private vcr: ViewContainerRef,
    private windowPane: WindowPane
  ) {}

  ngOnInit() {}

  addNewFunding() {
    this.fundingService.create(this.customerId);
  }

  openFundingSettings(customerId): void {
    // open funding sendings dialog through either viewChild or subscribe
    const dialogRef = this.matDialog.open(
      FundingSummarySettingsDialogComponent,
      { viewContainerRef: this.vcr, width: '540px' }
    );
    dialogRef.componentInstance.customerId = customerId;
  }

  goToCustomerFunding(customerId: string) {
    if (customerId) {
      this.windowPane.minimize();
      this.router.navigate(['/customers', customerId, 'funding']);
    }
  }
}
