<h1 mat-dialog-title>Log Communication</h1>

<form
  [formGroup]="communicationForm"
  (submit)="
    createCommunication(communicationForm.value, communicationForm.valid)
  "
>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-select
        formControlName="contactId"
        placeholder="Who did you speak with?"
      >
        <mat-option
          [value]="contact.value"
          *ngFor="let contact of contacts$ | async"
        >
          {{ contact.viewValue }}
        </mat-option>
        <mat-option [value]="'other'">Other</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="form-group">
      <mat-button-toggle-group name="communicationType" formControlName="type">
        <mat-button-toggle
          value="phone"
          class="flex flex-row items-center justify-start"
          ><mat-icon class="mat-icon-20">phone</mat-icon>
          Phone</mat-button-toggle
        >
        <mat-button-toggle
          value="email"
          class="flex flex-row items-center justify-start"
          ><mat-icon class="mat-icon-20">mail</mat-icon>
          Email</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
    <div class="form-group">
      <ease-markdown-editor
        [easeMentions]="true"
        formControlName="body"
        placeholder="What did you discuss?"
        size="sm"
        class="max-h-[40vh]"
      ></ease-markdown-editor>
    </div>
    <div class="form-group">
      <ease-datepicker
        placeholder="Next Scheduled Communication"
        formControlName="nextCommunicationAt"
        [min]="now"
      ></ease-datepicker>
      <div *ngIf="hasDateBeenBumped" class="banner theme-info">
        Next date was automatically set to 30 days from today.
      </div>
    </div>
  </div>

  <mat-dialog-actions align="end">
    <button (click)="dialogRef.close()" type="button" mat-button>Cancel</button>
    <button
      [disabled]="communicationForm.invalid || isWorking"
      color="primary"
      type="submit"
      mat-button
    >
      Save
    </button>
  </mat-dialog-actions>
</form>
