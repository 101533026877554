import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  FirebaseDbService,
  ListAsObject
} from 'src/app/shared/firebase-db.service';
import { TASK_PHASES_PATH } from '../../shared/firebase-paths';
import { TaskService } from '../task.service';
import { firebaseJSON } from '../../shared/utils/functions';
import { TaskPhase } from './task-phase.interface';

@Injectable({ providedIn: 'root' })
export class TaskPhaseService {
  public phaseObjectGetter: Observable<ListAsObject<TaskPhase>>;
  private taskPhases$ = this.angularFire
    .getList(`/${TASK_PHASES_PATH}`)
    .pipe(shareReplay(1));

  constructor(
    private angularFire: FirebaseDbService,
    private taskService: TaskService
  ) {
    this.phaseObjectGetter = this.angularFire
      .getObject(`/${TASK_PHASES_PATH}`)
      .pipe(
        map(phase => (phase.$exists() ? phase : {})),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
  }

  getAllAsObject(): Observable<ListAsObject<TaskPhase>> {
    return this.phaseObjectGetter;
  }

  getAll(includeNoPhase = false): Observable<TaskPhase[]> {
    return this.taskPhases$.pipe(
      map((phases: TaskPhase[]) => {
        const noPhaseExists = phases.some(phase => phase.$key === 'noPhase');

        if (!includeNoPhase || noPhaseExists) {
          return phases;
        }

        phases.unshift({
          $key: 'noPhase',
          name: 'No Phase',
          color: 'grey'
        });

        return phases;
      })
    );
  }

  get(phaseId: string) {
    return this.angularFire
      .getObject(`/${TASK_PHASES_PATH}/${phaseId}`)
      .pipe(map(phase => (phase.$exists() ? phase : null)));
  }

  create(phase: Partial<TaskPhase>): firebase.database.ThenableReference {
    return this.angularFire
      .list(`/${TASK_PHASES_PATH}`)
      .push(firebaseJSON(phase));
  }

  update(phaseId: string, phaseFields: Partial<TaskPhase>): Promise<void> {
    return this.angularFire
      .object(`/${TASK_PHASES_PATH}/${phaseId}`)
      .update(firebaseJSON(phaseFields));
  }

  validateName(name: string): Observable<boolean> {
    return this.angularFire
      .getList(`/${TASK_PHASES_PATH}`, ref =>
        ref.orderByChild('name').equalTo(name)
      )
      .pipe(
        take(1),
        map((phases: TaskPhase[]) => (phases && phases.length ? false : true))
      );
  }

  remove(phaseId: string) {
    return this.taskService.getForPhase(phaseId).pipe(
      take(1),
      switchMap(tasks =>
        Promise.all(tasks.map(task => this.taskService.setPhase(task.$key)))
      ),
      switchMap(() =>
        this.angularFire.object(`/${TASK_PHASES_PATH}/${phaseId}`).remove()
      ),
      take(1)
    );
  }
}
