<div class="group relative my-2 flex flex-col gap-2 rounded py-2 text-gray-600">
  <div class="flex flex-row items-center gap-2">
    <ease-avatar class="self-start" [userId]="note?.createdBy"></ease-avatar>
    <div class="flex flex-1 flex-col gap-1">
      <div class="flex flex-row items-center gap-1">
        <div class="flex-1 text-[13px] leading-5">
          <span class="font-bold">
            {{ note.createdByName }}
          </span>
          added a sticky note
        </div>

        <div
          class="top-0 right-0 z-1 flex h-[28px] flex-row rounded border border-solid border-gray-300 bg-gray-100 px-1 text-gray-700 group-hover:visible md:invisible md:absolute"
        >
          <div
            (click)="removeNote()"
            matTooltip="Delete"
            class="cursor-pointer py-1 px-1"
          >
            <mat-icon class="mat-icon-18"> delete </mat-icon>
          </div>
        </div>
      </div>

      <div
        [easeMarkdown]="note?.body"
        size="sm"
        class="cursor-text rounded bg-orange-50 p-2 shadow-md"
      ></div>
      <span class="text-xs leading-5 text-gray-600">
        {{ note?.createdAt | amDateFormat: dateFormat }}
      </span>
    </div>
  </div>
</div>
