import { Component, Input, OnInit } from '@angular/core';

import { FundingTotals } from '../funding.interface';
import { FundingService } from '../funding.service';

@Component({
  selector: 'ease-funding-totals',
  templateUrl: './funding-totals.component.html',
  styleUrls: ['./funding-totals.component.scss']
})
export class FundingTotalsComponent implements OnInit {
  @Input() fundingTotals: FundingTotals;

  constructor(public fundingService: FundingService) {}

  ngOnInit() {}
}
