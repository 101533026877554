import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import * as moment from 'moment';
import { AnnouncementsMetaForm } from 'src/app/announcements/announcements.interface';

@Component({
  selector: 'ease-create-announcement-meta',
  templateUrl: './create-announcement-meta.component.html',
  styleUrls: ['./create-announcement-meta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateAnnouncementMetaComponent implements OnInit {
  @Input() form: AnnouncementsMetaForm;
  @Input() isReadOnly: boolean = false;
  public MIN_EXPIRY_DATE: moment.Moment = moment();

  constructor() {}

  ngOnInit(): void {}
}
