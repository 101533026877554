import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class TaskUserMenuService {
  constructor() {}

  move(userId: string, source: AbstractControl, target: AbstractControl): void {
    this.remove(userId, source);
    const users = [...(target.value || []), { userId, method: 'direct' }];
    target.setValue(users);
  }

  remove(userId: string, source: AbstractControl): void {
    const users = source.value.filter(user => user.userId !== userId);
    source.setValue(users);
  }
}
