<div
  class="task-panel--compact__main flex flex-1 flex-row items-center justify-start"
>
  <div class="task-panel--compact__body relative flex-1">
    <div
      class="task-panel__name flex cursor-pointer flex-row items-center justify-start"
    >
      {{ task.name }}
    </div>
    <a
      *ngIf="task.entityId"
      class="task-panel__client flex flex-row items-center justify-start"
    >
      <ease-entity-icon
        class="mr-1"
        [entity]="{
          entityType: task.entityType,
          accountType: task.accountType
        }"
        [size]="16"
      >
      </ease-entity-icon>
      <span class="flex-1">{{ task.entityName }}</span>
    </a>
    <div
      class="task-panel__summary flex flex-row gap-2"
      *ngIf="task.$displayStatus.length || task.$displayPhase.length"
    >
      <ease-task-status-blob
        *ngFor="let statusId of task.$displayStatus"
        [showIcon]="true"
        [statusId]="statusId"
      ></ease-task-status-blob>
      <ease-task-phase-blob
        *ngFor="let phaseId of task.$displayPhase"
        [showIcon]="true"
        [phaseId]="phaseId"
      ></ease-task-phase-blob>
    </div>
  </div>
  <div>
    <div
      class="task-panel__swap"
      [ngClass]="{ 'md:!z-[-9999] md:!opacity-0': isHovered }"
    >
      <div
        class="notes-counter-due-date-wrapper flex flex-col items-end justify-center"
      >
        <div
          class="flex flex-row items-center justify-start text-gray-400"
          *ngIf="task.startDate"
        >
          <mat-icon class="mat-icon-14 mr-1">play_circle_outline</mat-icon>
          <div class="text-xs">
            {{ task.startDate | amDateFormat: 'MMM D' }}
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-start text-gray-400"
          *ngIf="task.dueDate"
          [ngClass]="{
            'text-red-600': task.dueDate < todayTimestamp,
            'text-orange-600': task.dueDate === todayTimestamp
          }"
        >
          <mat-icon class="mat-icon-14 mr-1">date_range</mat-icon>

          <div class="text-xs">
            {{ task.dueDate | amDateFormat: 'MMM D' }}
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-start text-gray-400"
          *ngIf="task.priority !== undefined && task.priority !== null"
        >
          <mat-icon class="mat-icon-14 mr-1">low_priority</mat-icon>
          <div class="text-xs capitalize">
            {{ taskService.priorityNames[task.priority] }}
          </div>
        </div>
      </div>
      <div class="new-wrapper">
        <div *ngIf="task.$unreadNotifications" class="task-panel__unread-count">
          NEW
        </div>
      </div>
    </div>

    <div
      class="task-panel-compact__actions hidden items-center justify-center md:flex"
      easeStopEvent
      *ngIf="!actionsDisabled && isHovered && !!!task.completedAt"
    >
      <button
        (click)="statusPopover.open()"
        [satPopoverAnchor]="statusPopover"
        title="Set Status"
        mat-icon-button
      >
        <mat-icon class="mat-icon-18">linear_scale</mat-icon>
      </button>
      <sat-popover
        (opened)="popoverToggled(true)"
        (closed)="popoverToggled(false)"
        verticalAlign="above"
        horizontalAlign="end"
        #statusPopover
        [hasBackdrop]="true"
      >
        <ease-task-status-popover
          *ngIf="statusPopover.isOpen()"
          (selected)="onStatusChange($event)"
          [selectedStatus]="task.$displayStatus"
        >
        </ease-task-status-popover>
      </sat-popover>
      <button
        (click)="phasePopover.open()"
        [satPopoverAnchor]="phasePopover"
        title="Set Phase"
        mat-icon-button
      >
        <mat-icon class="mat-icon-18">widgets</mat-icon>
      </button>
      <sat-popover
        (opened)="popoverToggled(true)"
        (closed)="popoverToggled(false)"
        verticalAlign="above"
        horizontalAlign="end"
        #phasePopover
        [hasBackdrop]="true"
      >
        <ease-task-phase-popover
          *ngIf="phasePopover.isOpen()"
          (selected)="onPhaseChange($event)"
          [selectedPhase]="task.$displayPhase"
        >
        </ease-task-phase-popover>
      </sat-popover>
      <button
        (click)="assignPopover.open()"
        [satPopoverAnchor]="assignPopover"
        title="Quick Assign"
        mat-icon-button
      >
        <mat-icon class="mat-icon-18">assignment_ind</mat-icon>
      </button>
      <sat-popover
        (opened)="popoverToggled(true)"
        (closed)="popoverToggled(false)"
        verticalAlign="above"
        horizontalAlign="end"
        #assignPopover
        [hasBackdrop]="true"
      >
        <ease-select-user-popover
          *ngIf="assignPopover.isOpen()"
          (selected)="onAssign($event)"
          (deselected)="onUnassign($event)"
          [selectedUsers]="task.$displayAssigned"
          [onlyUsers]="true"
        >
        </ease-select-user-popover>
      </sat-popover>
      <button
        (click)="completeTaskPopover.open()"
        [satPopoverAnchor]="completeTaskPopover"
        title="Complete"
        mat-icon-button
      >
        <mat-icon class="mat-icon-18">done</mat-icon>
      </button>
      <sat-popover
        (opened)="popoverToggled(true)"
        (closed)="popoverToggled(false)"
        verticalAlign="above"
        horizontalAlign="end"
        #completeTaskPopover
        [hasBackdrop]="true"
      >
        <ease-complete-task-popover
          *ngIf="completeTaskPopover.isOpen()"
          [taskId]="task.$key"
        ></ease-complete-task-popover>
      </sat-popover>
    </div>
  </div>
</div>
