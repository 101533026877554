<ng-container *ngIf="!isGroup && responsivenessMetric">
  <div
    class="metric pointer flex flex-row items-center justify-start"
    (click)="metricInfo.toggle()"
    [satPopoverAnchor]="metricInfo"
    [ngClass]="responsivenessMetric.ragStatusColor"
  >
    <div class="metric-details metric-color flex-1">
      <strong>{{ responsivenessMetric.responsiveness }}%</strong>
    </div>
  </div>

  <sat-popover [autoFocus]="false" [hasBackdrop]="true" #metricInfo>
    <ease-res-popover
      *ngIf="metricInfo.isOpen()"
      [account]="account"
    ></ease-res-popover>
  </sat-popover>
</ng-container>
