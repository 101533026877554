import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { TaskKeywordDisapproval } from '../../task.model';

@Component({
  selector: 'ease-task-keyword-disapprovals',
  templateUrl: './task-keyword-disapprovals.component.html',
  styleUrls: ['./task-keyword-disapprovals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskKeywordDisapprovalsComponent implements OnInit {
  @Input() items: TaskKeywordDisapproval[];
  constructor() {}

  ngOnInit() {}
}
