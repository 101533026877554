<mat-card class="!w-[280px] !p-0 !shadow-none">
  <mat-card-content class="!mb-0">
    <mat-calendar
      [minDate]="minDate"
      [maxDate]="maxDate"
      [selected]="selectedRangeValue"
      [startAt]="selectedRangeValue?.start"
      (selectedChange)="selectedChange($event)"
    ></mat-calendar>
  </mat-card-content>
  <ng-container *ngIf="showActionButtons">
    <mat-divider inset></mat-divider>
    <mat-card-actions class="!mb-2 !flex !items-center !justify-between">
      <button
        color="warn"
        mat-button
        (click)="clearDateRange()"
        [disabled]="
          (!!selectedRangeValue?.start && !!!selectedRangeValue?.end) ||
          (!!!selectedRangeValue?.start && !!!selectedRangeValue?.end)
        "
      >
        Clear
      </button>
      <button mat-button (click)="cancel.emit()">Close</button>
    </mat-card-actions>
  </ng-container>
</mat-card>
