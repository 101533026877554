import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { CustomerMessagePreviewModule } from '../customer-message-preview/customer-message-preview.module';
import { SharedModule } from '../../../shared/shared.module';
import { SharedPipesModule } from '../../../shared/shared-pipes.module';
import { CustomerMessageGroupsListComponent } from './customer-message-groups-list/customer-message-groups-list.component';

@NgModule({
  imports: [
    CustomerMessagePreviewModule,
    MatExpansionModule,
    SharedModule,
    SharedPipesModule,
    VirtualScrollerModule
  ],
  declarations: [CustomerMessageGroupsListComponent],
  exports: [CustomerMessageGroupsListComponent]
})
export class CustomerMessageGroupModule {}
