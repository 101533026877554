import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  defer,
  delayWhen,
  filter,
  firstValueFrom,
  Observable,
  of,
  switchMap,
  take,
  tap,
  timeout,
  timer
} from 'rxjs';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from 'src/app/shared/constants';
import { TaskService } from 'src/app/tasks/task.service';
import { TaskModel } from '../../../task.model';
import { TaskDashboardsViewService } from '../../task-dashboards-view/task-dashboards-view.service';

@Injectable({
  providedIn: 'root'
})
export class TaskItemActionMenuService {
  public operations: Record<string, Observable<any>[]> = {};

  constructor(
    private matSnackBar: MatSnackBar,
    private taskService: TaskService,
    private taskDashboardViewService: TaskDashboardsViewService
  ) {}

  addOperation(taskId: string, operation: Observable<any>): void {
    // Add the operation to the list for the task
    if (!this.operations[taskId]) {
      this.operations[taskId] = [];
    }

    this.operations[taskId].push(operation);
  }

  /**
   *
   * @param task Task to run operations against
   * @returns Boolean indicating whether any opererations were executed
   */
  async executeOperations(
    task: TaskModel,
    allowUndo: boolean = true
  ): Promise<boolean> {
    try {
      // Check that are operations to run
      if (this.operations[task.$key]?.length) {
        // Copy the operations to execute into a dedicated list to avoid fast
        // sequences of changes causing problems
        const toRun = [...this.operations[task.$key]];
        this.operations[task.$key] = [];

        // Get the value of the task before executing the operations
        const originalTaskValue = await firstValueFrom(
          this.taskService.get(task.$key)
        );

        // Mark the operation as started to add the loading indicator
        this.taskDashboardViewService.doTaskOperation({
          taskId: task.$key,
          operation: 'start'
        });

        const listener$ = this.taskDashboardViewService.listenForTaskUpdate(
          task.$key
        );

        /**
         * Get the initial 'Typesense indexed' timestamp from Firebase
         * for a given task ID
         */
        listener$
          .pipe(
            take(1),
            switchMap(async timestamp => {
              // Once we've retrieved the initial timestamp, execute the operations
              await this.doOperations(toRun);

              return timestamp;
            }),
            /**
             * switchMap to the timestamp observable again, and wait until the timestamp
             * has changed from the original timestamp we retrieved above
             */
            switchMap(originalTimestamp =>
              listener$.pipe(
                filter(
                  newTimestamp =>
                    originalTimestamp?.timestamp !== newTimestamp?.timestamp
                ),
                take(1)
              )
            ),
            /**
             * If the timestamp hasn't changed in 5 seconds, we timeout to avoid
             * sitting on a loading spinner indefinitely
             */
            timeout({
              first: 5000,
              with: () => of(null)
            }),
            /**
             * If we receive a timestamp (e.g. not null), then we didn't timeout,
             * so add a delay to ensure Typesense has time to index the data.
             *
             * If we timed out, no delay is needed (since we waited 5 seconds already)
             */
            delayWhen(timestamp => (timestamp ? timer(1750) : timer(0))),
            /**
             * Refresh the dashboard, and then wait for refreshed$ to indicate
             * the refresh finished
             */
            tap(() => this.taskDashboardViewService.refresh()),
            switchMap(() => this.taskDashboardViewService.refreshed$),
            take(1)
          )
          .subscribe(() => {
            // Mark the operation as finished to remove the loading indicator
            this.taskDashboardViewService.doTaskOperation({
              taskId: task.$key,
              operation: 'finish'
            });

            // Show a notification that the task has been updated
            const matSnackBarRef = this.matSnackBar.open(
              `Task updated`,
              allowUndo ? 'Undo' : 'Okay',
              {
                duration: allowUndo ? 6000 : SNACKBAR_DURATION_SUCCESS
              }
            );

            if (allowUndo) {
              // Listen to the undo button being clicked
              matSnackBarRef.afterDismissed().subscribe(async action => {
                if (action.dismissedByAction) {
                  /**
                   * If the user clicked 'undo', add a new operation
                   * that updates the task back to the original value
                   * we stored above
                   */
                  this.addOperation(
                    task.$key,
                    defer(() =>
                      this.taskService.update(task.$key, originalTaskValue)
                    )
                  );
                  await this.executeOperations(task, false);

                  this.matSnackBar.open(`Changes undone`, 'Okay', {
                    duration: SNACKBAR_DURATION_SUCCESS
                  });
                }
              });
            }
          });
      }
    } catch (error) {
      this.matSnackBar.open(`Error updating task: ${error.message}`, 'Close', {
        duration: SNACKBAR_DURATION_ERROR
      });
    }

    return false;
  }

  /**
   * Execute a given list of operations
   *
   * @param operations List of operations that should be executed
   */
  private async doOperations(operations: Observable<any>[]): Promise<void> {
    for (const operation of operations) {
      await firstValueFrom(operation);
    }
  }
}
