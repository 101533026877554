<virtual-scroller #scroll [items]="groups" [enableUnequalChildrenSizes]="true">
  <mat-accordion #container class="flex flex-1 flex-col">
    <mat-expansion-panel
      *ngFor="let group of scroll.viewPortItems"
      (afterExpand)="scrollToExpanded(group)"
      (closed)="refreshVirtualScrollerView()"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="truncate font-medium">
          <div class="truncate">{{ group.smartEmailName }}</div>
        </mat-panel-title>
        <mat-panel-description class="flex flex-row items-center justify-start">
          <mat-icon class="mat-icon-18 mr-1">person</mat-icon>
          {{ group.recipientsCount }}
          <div class="flex-1"></div>
          <ease-avatar
            class="mr-2"
            [userId]="group.createdBy"
            [showTooltip]="true"
            [size]="'xsmall'"
          ></ease-avatar>
          {{ group.createdAt | amCalendar }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div
          class="flex cursor-pointer flex-row items-center justify-start"
          *ngFor="let message of group.messages | keyvalue"
          [easeCustomerMessagePreview]="$any(message).key"
        >
          <mat-icon
            [title]="$any(message).value.status | titlecase"
            *ngIf="$any(message).value.status === 'accepted'"
            class="mat-icon-14 mr-1 text-emerald-500"
            >check_circle</mat-icon
          >
          <mat-icon matTooltip="Recipient" class="mat-icon-14 mr-1">{{
            group.type
          }}</mat-icon>
          <span class="text-blue-500">{{ $any(message).value.to }}</span>
          <div
            *ngIf="$any(message).value.cc"
            class="flex flex-row items-center justify-start"
          >
            <mat-icon matTooltip="CC" class="mat-icon-14 ml-2 mr-1"
              >file_copy</mat-icon
            >
            <span class="text-blue-500">{{ $any(message).value.cc }}</span>
          </div>
          <div
            *ngIf="$any(message).value.bcc"
            class="flex flex-row items-center justify-start"
          >
            <mat-icon matTooltip="BCC" class="mat-icon-14 ml-2 mr-1"
              >visibility_off</mat-icon
            >
            <span class="text-blue-500">{{ $any(message).value.bcc }}</span>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</virtual-scroller>
