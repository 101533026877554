import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import {
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup
} from 'src/app/shared/reactive-forms';
import { AccountBasicMeta } from '../../customers/customer-accounts/customer-accounts.interface';
import { Customer } from '../../customers/customer.interface';
import { EntityUtilsService } from '../../shared/entity-utils.service';
import { EntityMetadata } from '../../shared/shared.interface';
import { emptyFormArray } from '../../shared/utils/functions';
import { UserModel } from '../../users/user.model';
import { UserService } from '../../users/user.service';
import { EntityAssign } from './entity-assign.interface';

@UntilDestroy()
@Component({
  selector: 'ease-entity-assign',
  templateUrl: './entity-assign.component.html',
  styleUrls: ['./entity-assign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityAssignComponent implements OnInit {
  @Input() set user(user: UserModel) {
    this.userToRetireSource.next(user);
    this._user = user;
  }

  get user() {
    return this._user;
  }

  @Input() entity: Pick<EntityMetadata, 'entityType' | 'accountType'>;
  @Input() role: {
    id: 'relationshipManager' | 'manager' | 'reviewer';
    label: string;
  };
  @Input() entityListControl: TypedFormArray<EntityAssign>;

  public replaceUserControl: FormControl<UserModel> = new FormControl(null);
  public showList: boolean = false;
  public users$: Observable<UserModel[]>;
  public filteredUsers$: Observable<UserModel[]>;
  private userToRetireSource: ReplaySubject<UserModel> =
    new ReplaySubject<UserModel>();
  private userToRetire$: Observable<UserModel> =
    this.userToRetireSource.asObservable();
  private _user: UserModel;
  public entityList$: Observable<Customer[] | AccountBasicMeta[]>;

  constructor(
    private userService: UserService,
    private entityUtilsService: EntityUtilsService
  ) {}

  ngOnInit(): void {
    this.users$ = this.userService.users.pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );
    this.filteredUsers$ = combineLatest([this.users$, this.userToRetire$]).pipe(
      map(([users, userToRetire]) =>
        users.filter(user => user.$key !== userToRetire.$key)
      )
    );

    this.entityList$ = this.userToRetire$.pipe(
      switchMap(user =>
        this.entityUtilsService.getEntitiesByUserRole(
          this.role.id,
          user.$key,
          this.entity.entityType
        )
      ),
      tap(entityList => {
        this.replaceUserControl.reset();
        const entityArray = this.entityListControl;
        emptyFormArray(entityArray);

        if (entityList.length) {
          entityList.forEach(entity => {
            const entityGroup: TypedFormGroup<EntityAssign> = new FormGroup<
              TypedFormControl<EntityAssign>
            >({
              entityName: new FormControl(entity.name),
              entityId: new FormControl(entity.$key),
              entityType: new FormControl(this.entity.entityType),
              accountType: new FormControl({ value: null, disabled: true }),
              destinationUserId: new FormControl(null, {
                validators: Validators.required
              })
            });

            if (this.entity.entityType === 'account' && entity.$accountType) {
              entityGroup.controls.accountType.enable();
              entityGroup.controls.accountType.patchValue(entity.$accountType);
            }

            entityArray.push(entityGroup);
          });

          //solving virtual scrolling issues with new controls
          this.entityListControl.controls = [
            ...this.entityListControl.controls
          ];
          this.entityListControl.setValidators(Validators.required);
        } else {
          this.entityListControl.setValidators([]);
        }
        this.entityListControl.updateValueAndValidity();
      }),
      untilDestroyed(this)
    );

    this.replaceUserControl.valueChanges
      .pipe(
        tap(replaceUser => {
          const entityArray = this.entityListControl;
          entityArray.controls.forEach(entityGroup => {
            entityGroup.controls.destinationUserId.setValue(replaceUser?.$key);
          });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
