<ease-sheet class="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
  <form
    [formGroup]="sendFrom"
    (ngSubmit)="emailComposer.sendEmail()"
    class="flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
  >
    <ease-sheet-content
      size="md"
      class="flex-1 overflow-y-auto overflow-x-hidden"
    >
      <ease-advanced-autocomplete
        [formControl]="templateSelectorControl"
        [options]="emailTemplates$ | async"
        [autoFocus]="true"
        [placeholder]="'Select an email template'"
        [prefixIcon]="'alternate_email'"
        (optionSelected)="templateSelected($event)"
      ></ease-advanced-autocomplete>

      <ease-email-composer
        #emailComposer
        [templateId$]="templateId$"
        [layoutStyle]="layoutStyle"
        [data]="data"
        [emailsFormArray]="emailsFormArray"
        (workingOutput)="isWorking = $event"
        (templateChange)="onTemplateChanged($event)"
        (send)="emailSent($event)"
      ></ease-email-composer>
    </ease-sheet-content>

    <mat-progress-bar
      *ngIf="isWorking"
      mode="indeterminate"
      color="primary"
    ></mat-progress-bar>

    <ease-sheet-footer class="flex-none">
      <ng-container *ngIf="templateDetails$ | async as templateDetails">
        <a
          mat-button
          target="_blank"
          [href]="templateDetails?.Properties?.HtmlPreviewUrl"
          *ngIf="templateDetails?.Properties?.HtmlPreviewUrl"
        >
          <mat-icon>open_in_new</mat-icon> <span>View Preview</span>
        </a>
        <div class="flex-1"></div>
        <button
          class="order-last"
          type="submit"
          [disabled]="emailsFormArray.invalid || isWorking"
          color="primary"
          mat-button
        >
          {{ isWorking ? 'Sending...' : 'Send Email' }}
        </button>
      </ng-container>
      <button easeWindowClose mat-button>Cancel</button>
    </ease-sheet-footer>
  </form>
</ease-sheet>
