import { NgModule } from '@angular/core';

import { AccountIconModule } from '../../shared/account-icon/account-icon.module';
import { ChartsModule } from '../../shared/charts/charts.module';
import { CustomerAccountBudgetModule } from '../customer-accounts/customer-account-budget/customer-account-budget.module';
import { SharedFormModule } from '../../shared/shared-form.module';
import { SharedModule } from '../../shared/shared.module';
import { CustomerBudgetComponent } from './customer-budget/customer-budget.component';
import { CustomerBudgetsComponent } from './customer-budgets/customer-budgets.component';
import { CustomerBudgetsDialogComponent } from './customer-budgets-dialog/customer-budgets-dialog.component';

@NgModule({
  imports: [
    AccountIconModule,
    ChartsModule,
    CustomerAccountBudgetModule,
    SharedFormModule,
    SharedModule
  ],
  declarations: [
    CustomerBudgetComponent,
    CustomerBudgetsComponent,
    CustomerBudgetsDialogComponent
  ],
  exports: [CustomerBudgetComponent, CustomerBudgetsComponent]
})
export class CustomerBudgetsModule {}
