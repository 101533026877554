<div class="flex flex-row flex-wrap items-center justify-start text-sm">
  <ng-container *ngIf="!(type | emptyContactType); else missingContactType">
    <ng-container *ngFor="let item of type | keyvalue">
      <div
        *ngIf="item.value"
        class="mr-2 flex flex-row items-center justify-center"
      >
        <mat-icon class="mat-icon-16 mr-1 text-gray-700">{{
          customerContactsService.types[item.key].icon
        }}</mat-icon>
        {{ customerContactsService.types[item.key].label }}
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #missingContactType>
  <div
    class="rounded-md bg-orange-100 p-1.5 text-center text-xs font-medium leading-none text-gray-700"
  >
    No contact type
  </div>
</ng-template>
