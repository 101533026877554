import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, tap } from 'rxjs/operators';
import { HotkeysHelpComponent, HotkeysService } from '@ngneat/hotkeys';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NotificationsComponent } from './notifications/notifications/notifications.component';
import { FinderService } from './shared/finder/finder.service';
import { AppNavComponent } from './shared/layout/app-nav/app-nav.component';
import { UserService } from './users/user.service';
import { AppFinderComponent } from './shared/layout/app-finder/app-finder.component';
import { WindowService } from './shared/window/window.service';
import { SharedLayoutService } from './shared/shared-layout.service';
import { AppService } from './app.service';

@UntilDestroy()
@Component({
  selector: 'ease-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('class') class = 'flex flex-col w-full h-full';
  @HostBinding('class.nav-collapsed')
  get isNavCollapsed() {
    this.cdr.detectChanges();
    return this.appNav && this.appNav.isCollapsed;
  }
  @ViewChild('appNav') appNav: AppNavComponent;
  @ViewChild('notifications') notifications: NotificationsComponent;
  @ViewChild('finder') finder: AppFinderComponent;
  private userLoggedIn = false;
  private helpModalRef: MatDialogRef<HotkeysHelpComponent>;

  constructor(
    private cdr: ChangeDetectorRef,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private windowService: WindowService,
    private hotkeys: HotkeysService,
    private dialog: MatDialog,
    public sharedLayoutService: SharedLayoutService,
    public appService: AppService,
    public finderService: FinderService,
    public userService: UserService
  ) {
    this.iconRegistry
      .addSvgIcon(
        'source',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/source-icon.svg'
        )
      )
      .addSvgIcon(
        'distributor',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/distributor-icon.svg'
        )
      )
      .addSvgIcon(
        'vertical',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/vertical-icon.svg'
        )
      );
  }

  ngOnInit() {
    this.appService.isLoggedIn$
      .pipe(untilDestroyed(this))
      .subscribe(isLoggedIn => {
        this.userLoggedIn = isLoggedIn;
      });

    this.hotkeys
      .addShortcut({
        keys: 'meta.,',
        group: 'General Shortcuts',
        description: 'Open first unread notification'
      })
      .pipe(
        filter(
          event =>
            this.userLoggedIn &&
            this.sharedLayoutService.isShortcutAllowed(event)
        ),
        tap(event => event && event.preventDefault())
      )
      .subscribe(() => this.notifications.openLastNotification());

    this.hotkeys
      .addShortcut({
        keys: 'meta.x',
        group: 'Window Shortcuts',
        description: 'Close current window',
        preventDefault: false
      })
      .pipe(
        filter(
          event =>
            this.userLoggedIn &&
            this.sharedLayoutService.isShortcutAllowed(event)
        ),
        tap(event => event && event.preventDefault())
      )
      .subscribe(() => this.windowService.closeCurrentWindow());

    this.hotkeys
      .addShortcut({
        keys: 'shift.meta.x',
        group: 'Window Shortcuts',
        description: 'Reopen recently closed window',
        preventDefault: false
      })
      .pipe(
        filter(
          event =>
            this.userLoggedIn &&
            this.sharedLayoutService.isShortcutAllowed(event)
        ),
        tap(event => event && event.preventDefault())
      )
      .subscribe(() => this.windowService.restoreRecentlyClosed());

    this.hotkeys
      .addShortcut({
        keys: 'meta.k',
        group: 'General Shortcuts',
        description: 'Focus Finder'
      })
      .pipe(
        filter(
          event =>
            this.userLoggedIn &&
            this.sharedLayoutService.isShortcutAllowed(event)
        ),
        tap(event => event && event.preventDefault())
      )
      .subscribe(() => this.finder.openFinder());

    this.hotkeys
      .addShortcut({
        keys: 'meta.j',
        group: 'General Shortcuts',
        description: 'View notifications'
      })
      .pipe(
        filter(
          event =>
            this.userLoggedIn &&
            this.sharedLayoutService.isShortcutAllowed(event)
        ),
        tap(event => event && event.preventDefault())
      )
      .subscribe(() => this.notifications.panelToggle());

    this.hotkeys
      .addShortcut({
        keys: 'meta.ArrowUp',
        group: 'Window Shortcuts',
        description: 'Maximize current window',
        preventDefault: false
      })
      .pipe(
        filter(
          event =>
            this.userLoggedIn &&
            this.sharedLayoutService.isShortcutAllowed(event)
        ),
        tap(event => event && event.preventDefault())
      )
      .subscribe(() => this.windowService.maximizeCurrentWindow());

    this.hotkeys
      .addShortcut({
        keys: 'meta.g',
        group: 'Window Shortcuts',
        description: 'Grow or shrink current window'
      })
      .pipe(
        filter(
          event =>
            this.userLoggedIn &&
            this.sharedLayoutService.isShortcutAllowed(event)
        ),
        tap(event => event && event.preventDefault())
      )
      .subscribe(() => this.windowService.toggleShrinkCurrentWindow());

    this.hotkeys
      .addShortcut({
        keys: 'meta.ArrowDown',
        group: 'Window Shortcuts',
        description: 'Minimize current window',
        preventDefault: false
      })
      .pipe(
        filter(
          event =>
            this.userLoggedIn &&
            this.sharedLayoutService.isShortcutAllowed(event)
        ),
        tap(event => event && event.preventDefault())
      )
      .subscribe(() => this.windowService.minimizeAllWindows());

    this.hotkeys
      .addShortcut({
        keys: 'meta.ArrowRight',
        group: 'Window Shortcuts',
        description: 'Next window',
        preventDefault: false
      })
      .pipe(
        filter(
          event =>
            this.userLoggedIn &&
            this.sharedLayoutService.isShortcutAllowed(event)
        ),
        tap(event => event && event.preventDefault())
      )
      .subscribe(() => this.windowService.nextWindow(true));

    this.hotkeys
      .addShortcut({
        keys: 'meta.ArrowLeft',
        group: 'Window Shortcuts',
        description: 'Previous window',
        preventDefault: false
      })
      .pipe(
        filter(
          event =>
            this.userLoggedIn &&
            this.sharedLayoutService.isShortcutAllowed(event)
        ),
        tap(event => event && event.preventDefault())
      )
      .subscribe(() => this.windowService.nextWindow(false));

    this.hotkeys.registerHelpModal(() => {
      if (!this.helpModalRef || this.helpModalRef.getState() !== 0) {
        this.helpModalRef = this.dialog.open(HotkeysHelpComponent, {
          width: '700px',
          panelClass: 'help-panel'
        });
        this.helpModalRef.componentInstance.dimiss.subscribe(() =>
          this.helpModalRef.close()
        );
      }
    });
  }
}
