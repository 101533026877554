import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  FormControl
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import * as moment from 'moment';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import { ConfirmService } from '../../shared/confirm/confirm.service';
import { GridCellRendererComponent } from '../../shared/grid-cells/grid-cell-renderer/grid-cell-renderer.component';
import { ListDisplayItem } from '../../shared/shared.interface';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from '../../shared/constants';
import { AdwordsManagerLinkRequest } from '../accounts.interface';
import { AccountsService } from '../accounts.service';

@Component({
  selector: 'ease-adwords-manager-links-dialog',
  templateUrl: './adwords-manager-links-dialog.component.html',
  styleUrls: ['./adwords-manager-links-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdwordsManagerLinksDialogComponent
  implements AfterViewInit, OnInit
{
  @ViewChild('actionsCell')
  actionsCell: TemplateRef<any>;
  private _isLinking: boolean = false;

  get isLinking(): boolean {
    return this._isLinking;
  }

  private reload$: Subject<boolean> = new Subject<boolean>();
  public managerLinkRequests$: Observable<AdwordsManagerLinkRequest[]>;
  public gridOptions: GridOptions;
  public linkRequestLoading: boolean = false;
  public linkingForm: FormGroup<{
    accountId: FormControl<string>;
    managerId: FormControl<string>;
  }> = this.formBuilder.group({
    accountId: this.formBuilder.control(null, {
      validators: Validators.required
    }),
    managerId: this.formBuilder.control(null, {
      validators: Validators.required
    })
  });
  public managerAccounts$: Observable<ListDisplayItem[]>;

  constructor(
    private cdr: ChangeDetectorRef,
    private confirmService: ConfirmService,
    private accountsService: AccountsService,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.managerAccounts$ = this.accountsService
      .getAdwordsManagerLinkAccounts()
      .pipe(
        map(managers =>
          managers.map(manager => ({
            value: manager.id,
            viewValue: manager.descriptive_name
          }))
        )
      );
    this.managerLinkRequests$ = this.reload$.pipe(
      startWith(false),
      switchMap(cacheBust =>
        this.accountsService.getAdwordsManagerLinkRequests(cacheBust)
      ),
      tap(linkedRequests => {
        const linkedAccountIds = linkedRequests.map(
          param => param.client_customer_id
        );
        this.linkingForm.controls.accountId.setValidators(
          this.noDuplicateNumber(linkedAccountIds)
        );
      })
    );
  }

  ngAfterViewInit() {
    this.setGridOptions();
  }

  startLinking() {
    this._isLinking = true;
  }

  stopLinking() {
    this._isLinking = false;
    this.linkingForm.reset();
  }

  /**
   * Creates a link request  when the form is submitted and valid
   */
  async createLinkRequest() {
    if (this.linkingForm.valid) {
      try {
        this.linkRequestLoading = true;
        await firstValueFrom(
          this.accountsService.createAdwordsManagerLinkRequest(
            this.linkingForm.value.accountId,
            this.linkingForm.value.managerId
          )
        );
        this.reload$.next(true);
        this.matSnackBar.open(
          `Link request sent to the administrators of ${this.linkingForm.value.accountId}`,
          'Close',
          {
            duration: SNACKBAR_DURATION_SUCCESS
          }
        );
        this.stopLinking();
      } catch {
        this.matSnackBar.open(
          'Link request failed. This is usually because there is already a pending link request for this account',
          'Close',
          {
            duration: SNACKBAR_DURATION_ERROR
          }
        );
      } finally {
        this.linkRequestLoading = false;
        this.cdr.markForCheck();
      }
    }
  }

  /**
   * Revoke a link request for a given row
   *
   * @param event Event containing details for a given cell renderer
   */
  async revokeLinkRequest(event: ICellRendererParams) {
    const confirmResult = await this.confirmService.confirm({
      title: 'Revoke Link Request',
      message: `Are you sure you want to remove the link request for ${event.data.client_customer_id}?`,
      confirmText: 'Revoke Link Request',
      cancelText: 'Cancel'
    });

    if (confirmResult.confirm) {
      try {
        event.api.showLoadingOverlay();
        await firstValueFrom(
          this.accountsService.removeAdwordsManagerLinkRequest(
            event.data.manager_id,
            event.data.resource_name
          )
        );
        this.matSnackBar.open('Link request cancelled', 'Close', {
          duration: SNACKBAR_DURATION_SUCCESS
        });
        this.reload$.next(true);
      } catch {
        this.matSnackBar.open('Link request could not be cancelled', 'Close', {
          duration: SNACKBAR_DURATION_ERROR
        });
      } finally {
        event.api.hideOverlay();
      }
    }
  }

  /**
   * Prepares the grid options to be used by the ag-grid instances
   */
  setGridOptions() {
    this.gridOptions = {
      suppressCellSelection: true,
      columnTypes: {
        dateColumn: {
          valueFormatter: params => moment(params.value).format('MMM DD, YYYY')
        },
        actions: {
          headerName: 'Actions'
        }
      },
      defaultColDef: {
        suppressMenu: true
      },
      onGridReady: params => {
        params.api.sizeColumnsToFit();
      },

      columnDefs: [
        {
          headerName: 'Account ID',
          field: 'client_customer_id'
        },
        {
          headerName: 'Manager Name',
          field: 'manager_descriptive_name'
        },
        {
          headerName: 'Status',
          field: 'status'
        },
        {
          type: ['rightAligned', 'actions'],
          cellRenderer: GridCellRendererComponent,
          cellRendererParams: {
            ngTemplate: this.actionsCell
          }
        }
      ]
    };
  }
  /**
   * Checks if an account ID exists in the managerLinkRequests array
   *
   * @param source
   * @returns
   */
  noDuplicateNumber =
    (source: number[]): ValidatorFn =>
    (control: AbstractControl) =>
      source.includes(control.value)
        ? {
            duplicateNumber: {
              message: 'An invitation already exists for this account'
            }
          }
        : null;
}
