import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../../shared/shared.module';
import { FundingSummaryModule } from '../../../shared/funding/funding-summary/funding-summary.module';
import { TaskFundingComponent } from './task-funding.component';

@NgModule({
  imports: [CommonModule, FundingSummaryModule, SharedModule],
  declarations: [TaskFundingComponent],
  exports: [TaskFundingComponent]
})
export class TaskFundingModule {}
