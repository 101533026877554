<div class="my-2 flex flex-col gap-2 py-2 text-gray-600">
  <div class="flex flex-row items-center gap-2">
    <ease-avatar class="self-start" [userId]="item?.createdBy"></ease-avatar>

    <div class="flex flex-1 flex-col gap-1 overflow-hidden">
      <div class="text-[13px] leading-5">
        <span class="font-bold">
          {{ users[item?.createdBy]?.name }}
        </span>
        snoozed
        {{ item?.entityType === 'customer' ? ' all accounts' : ' an account' }}
      </div>

      <div
        *ngIf="item?.entityName && item?.accountType"
        class="flex flex-row items-center gap-2"
      >
        <ease-account-icon
          [size]="14"
          [accountType]="item?.accountType"
        ></ease-account-icon>
        <span class="truncate text-[13px] font-medium leading-5 text-gray-600">
          {{ item.entityName }}
        </span>
      </div>

      <div
        *ngIf="item?._highlightResult?.body?.matchedWords?.length || item?.body"
        class="cursor-text rounded bg-white p-2 shadow-md"
      >
        <div
          *ngIf="
            item?._highlightResult?.body?.matchedWords?.length;
            else defaultMessage
          "
          [innerHTML]="item._highlightResult.body.value"
        ></div>
        <ng-template #defaultMessage>
          <div [easeMarkdown]="item?.body" size="sm"></div>
        </ng-template>
      </div>

      <div class="flex flex-row gap-3">
        <div
          class="flex flex-row items-center gap-1 text-xs leading-5 text-gray-600"
        >
          <mat-icon class="mat-icon-16">check</mat-icon>
          <span>
            {{ item?.createdAt | amDateFormat: dateFormat }}
          </span>
        </div>
        <div
          *ngIf="item?.snoozeUntil"
          class="flex flex-row items-center gap-1 text-xs leading-5 text-gray-600"
        >
          <mat-icon class="mat-icon-16">snooze</mat-icon>
          <span>
            {{ item?.snoozeUntil | amDateFormat: dateFormat }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
