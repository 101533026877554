import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { TypedFormGroup } from 'src/app/shared/reactive-forms';
import { UserService } from '../../../../users/user.service';
import {
  TaskChecklistCheckboxInput,
  TaskChecklistTemplateFieldOptions
} from '../task-checklist.interface';

@Component({
  selector: 'ease-task-checklist-checkbox-input',
  templateUrl: './task-checklist-checkbox-input.component.html',
  styleUrls: ['./task-checklist-checkbox-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskChecklistCheckboxInputComponent {
  @Input()
  templateOptions: TaskChecklistTemplateFieldOptions;
  @Input()
  group: TypedFormGroup<TaskChecklistCheckboxInput>;
  /**
   * `value` input exists solely to support OnPush change
   * detection being triggered when the field value updates
   */
  @Input()
  value: any;
  @Output()
  actionClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private angularFire: FirebaseDbService,
    private userService: UserService
  ) {}

  async clickCheckbox(checkbox: MatCheckbox): Promise<void> {
    const completedAt = await this.angularFire.getServerTimestamp();

    setTimeout(() => {
      if (!this.group.disabled) {
        const checked = checkbox.checked;
        if (checked) {
          this.group.patchValue({
            checked,
            completedBy: this.userService.currentUser.name,
            completedAt
          });
        } else {
          this.group.patchValue({
            checked,
            completedBy: '',
            completedAt: null
          });
        }

        this.actionClicked.emit();
      }
    });
  }
}
