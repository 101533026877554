/**
 * Domain helpers to extract pieces of URLs
 * We were using tld.js package prior to this,
 * but it was overkill for simple parsing like this
 *
 * Source: https://stackoverflow.com/a/23945027/1243466
 */

export const extractHostname = (url: string): string => {
  if (!url) {
    return url;
  }

  let hostname: string;

  // Find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // Find & remove port number
  hostname = hostname.split(':')[0];
  // Find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
};

export const extractRootDomain = (url: string): string => {
  if (!url) {
    return url;
  }

  let domain = extractHostname(url);
  const splitArr = domain.split('.');
  const arrLen = splitArr.length;

  // Not a valid domain, no dots in it. Returning early.
  if (arrLen === 1) {
    return;
  }

  // Extracting the root domain here
  // If there is a subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
    // Check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (
      splitArr[arrLen - 2].length === 2 &&
      splitArr[arrLen - 1].length === 2
    ) {
      // This is using a ccTLD
      domain = splitArr[arrLen - 3] + '.' + domain;
    }
  }

  return domain;
};
