import { ChartOptions } from 'chart.js';
import { merge } from 'lodash-es';

export const CHART_DEFAULT_OPTIONS: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  datasets: {
    line: {
      tension: 0.4,
      fill: true
    }
  },
  plugins: {
    tooltip: {
      displayColors: true,
      mode: 'index',
      intersect: false,
      bodySpacing: 4
    },
    legend: {
      labels: {
        boxWidth: 14
      }
    }
  },
  hover: {
    mode: 'index',
    intersect: false
  },
  elements: {
    line: {
      fill: false
    }
  },
  layout: {
    padding: 30
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      beginAtZero: true,
      grid: {
        drawBorder: false,
        drawTicks: false
      },
      ticks: {
        maxTicksLimit: 3,
        padding: 20
      }
    }
  }
};

const currencyOptions: ChartOptions<'line'> = {
  plugins: {
    tooltip: {
      displayColors: true,
      callbacks: {
        label: ctx => `${ctx.dataset.label}: ${formatCurrency(ctx.raw)}`
      }
    }
  },
  scales: {
    y: {
      ticks: {
        callback: value => formatCurrency(value)
      }
    }
  }
};
export const CHART_CURRENCY_OPTIONS: ChartOptions<'line'> = merge(
  {},
  CHART_DEFAULT_OPTIONS,
  currencyOptions
);

const percentageOptions: ChartOptions<'line'> = {
  plugins: {
    tooltip: {
      displayColors: true,
      callbacks: {
        label: ctx => `${ctx.label}: ${ctx.raw}%`
      }
    }
  },
  scales: {
    y: {
      ticks: {
        callback: value => `${value}%`
      }
    }
  }
};
export const CHART_PERCENTAGE_OPTIONS: ChartOptions<'line'> = merge(
  {},
  CHART_DEFAULT_OPTIONS,
  percentageOptions
);

const formatCurrency = (val: any): string =>
  `${
    '$' +
    Number(val)
      .toFixed(2)
      .replace(/./g, (c, i, a) =>
        i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
      )
  }`;
