import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom, Observable } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { BoardModel } from 'src/app/boards/board.model';
import { BoardService } from 'src/app/boards/board.service';
import {
  EntityMetadata,
  EntityType,
  ListDisplayItem,
  ListDisplayItemValue
} from 'src/app/shared/shared.interface';
import { ListModel } from 'src/app/boards/list.model';
import { Target } from 'src/app/shared/bulk-targeting/bulk-targeting.interface';
import { AccountType } from 'src/app/customers/customer-accounts/customer-accounts.interface';
import { CustomerAccountsService } from 'src/app/customers/customer-accounts/customer-accounts.service';
import { bugsnagClient } from 'src/app/shared/error-handler';
import { GridTargetingService } from 'src/app/shared/grid-toolbar/grid-targeting/grid-targeting.service';
import { TaskFormService } from 'src/app/tasks/task-form.service';
import { ScheduledFrequency } from 'src/app/tasks/task.interface';
import { TaskRoleSelectorComponent } from 'src/app/tasks/task-edit-form/task-role-selector/task-role-selector.component';
import { TaskChecklistTemplateService } from 'src/app/tasks/task-modules/task-checklist/task-checklist-template.service';
import { TaskUserMenuService } from 'src/app/tasks/task-user-menu/task-user-menu.service';
import { TaskFormValidators } from 'src/app/tasks/task-form.validators';
import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import {
  TaskRuleForm,
  TaskTemplateFieldsGroup
} from 'src/app/tasks/task-form.interface';
import { Options } from 'rrule';
import { WindowPane } from '../../window-pane/window-pane';
import { SNACKBAR_DURATION_ERROR } from '../../../constants';
import { CreateTaskService } from '../create-task.service';
import { TaskQueueItem } from './create-task-bulk.interface';
import { CreateTaskBulkQueueComponent } from './create-task-bulk-queue/create-task-bulk-queue.component';
import { CellAction } from './create-task-bulk-queue/create-task-bulk-queue.interface';

@UntilDestroy()
@Component({
  selector: 'ease-create-task-bulk',
  templateUrl: './create-task-bulk.component.html',
  styleUrls: ['./create-task-bulk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTaskBulkComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @Input() data: EntityMetadata;
  @Input() taskTemplates: ListDisplayItem[];
  @Input() templateControl: FormControl<string>;
  @Output() queued: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  create: EventEmitter<string> = new EventEmitter<string>();
  @Output() creating: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('roleSelector') roleSelector: TaskRoleSelectorComponent;
  @ViewChild('windowIconTemplate', { read: TemplateRef })
  windowIconTemplate: TemplateRef<any>;
  @ViewChild('windowTitleTemplate', { read: TemplateRef })
  windowTitleTemplate: TemplateRef<any>;
  @ViewChild('windowSubtitleTemplate', { read: TemplateRef })
  windowSubtitleTemplate: TemplateRef<any>;
  public submitted = false;

  // Task form
  public taskForm: TaskTemplateFieldsGroup;
  public rruleForm: TaskRuleForm;
  public boards$: Observable<BoardModel[]>;
  public checklists$: Observable<ListDisplayItem[]>;
  public listsForCurrentBoard$: Observable<ListModel[]>;
  public scheduledFrequencies: ScheduledFrequency[];
  public now = moment().startOf('day').toDate();
  public entityType: EntityType;
  public customVariables: string[] = [];

  // Bulk task creation
  public target: FormControl<Target> = new FormControl(
    null,
    Validators.required
  );
  public queue: TaskQueueItem[] = [];
  @ViewChild('queueTracker') set queueTracker(
    queueTracker: CreateTaskBulkQueueComponent
  ) {
    if (queueTracker) {
      this._queueTracker = queueTracker;
    }
  }
  get queueTracker(): CreateTaskBulkQueueComponent {
    return this._queueTracker;
  }
  private _queueTracker: CreateTaskBulkQueueComponent;
  set inProgress(inProgress: boolean) {
    inProgress ? this.windowPane.disableClose() : this.windowPane.enableClose();
    this.creating.emit(inProgress);
    this._inProgress = inProgress;
  }
  get inProgress(): boolean {
    return this._inProgress;
  }
  private _inProgress: boolean;
  public cancelled = false;
  public hasFailedTasks = false;
  private BATCH_MAX = 5;

  constructor(
    private boardService: BoardService,
    private cdr: ChangeDetectorRef,
    private createTaskService: CreateTaskService,
    private customerAccountsService: CustomerAccountsService,
    private entityUtilsService: EntityUtilsService,
    private gridTargetingService: GridTargetingService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private taskFormService: TaskFormService,
    private windowPane: WindowPane<EntityMetadata>,
    private taskChecklistTemplateService: TaskChecklistTemplateService,
    public taskUserMenuService: TaskUserMenuService
  ) {}

  ngOnInit(): void {
    this.createTaskService.setWindowPane(this.windowPane);
    this.taskForm = this.taskFormService.getTaskForm(false, false);

    /**
     * Whenever assigned role changes, trigger missing role validation
     */
    this.taskForm.controls.assignedRoles.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const target = this.target.value;
        target?.members.length && this.validateAssignedRoles(target);
      });

    this.target.valueChanges.pipe(untilDestroyed(this)).subscribe(target => {
      /**
       * Set the actual entity type when there's at least one target member. Otherwise,
       * there's really no target entity.
       */
      if (target?.members.length) {
        /**
         * Update entity type every time the target is changed to re-validate the form
         * (validEntityForAssignedRole).
         */
        this.entityType = target.level as EntityType;
        this.taskForm.controls.entityType.setValue(this.entityType);

        this.validateAssignedRoles(target);

        /**
         * Check if there are any custom data included. If there are, update markdown
         * -editor variables.
         */
        if (target.members[0].customData) {
          this.customVariables = Object.keys(target.members[0].customData);
        }
      } else {
        this.taskForm.controls.entityType.setValue(null);
        this.entityType = null;
      }

      this.cdr.detectChanges();
    });

    this.rruleForm = this.taskFormService.getRruleForm();
    this.scheduledFrequencies = this.taskFormService.getScheduledFrequencies();
    this.rruleForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(val =>
        this.taskForm.controls.rrule.setValue(
          this.taskFormService.getTaskRule(val as Options)
        )
      );
    this.rruleForm.controls.interval.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(interval =>
        this.rruleForm.controls.count.setValue(
          this.taskFormService.getCountFromInterval(interval)
        )
      );
    this.taskForm.controls.scheduled.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(scheduled =>
        this.taskFormService.toggleScheduled(
          this.taskForm,
          this.rruleForm,
          scheduled,
          false
        )
      );

    this.boards$ = this.boardService.getAll();

    this.checklists$ = this.taskChecklistTemplateService.getAllForSelect();

    this.listsForCurrentBoard$ = this.taskForm.controls.board.valueChanges.pipe(
      untilDestroyed(this),
      startWith(null),
      switchMap(() =>
        this.taskFormService.getWritableBoardList(
          this.taskForm.controls.board.value
        )
      ),
      tap(lists => {
        if (lists && lists.length) {
          const listControl = this.taskForm.controls.list;
          listControl.setValue(
            this.taskFormService.getInitialList(lists, listControl.value)
          );
        }
      })
    );

    const gridData = this.gridTargetingService.getData();
    if (gridData && !this.gridTargetingService.getActiveWindow()) {
      this.target.setValue({ level: gridData.entity, members: [] });
    }

    this.taskFormService
      .dedupeUserControls(
        this.taskForm.controls.assigned,
        this.taskForm.controls.subscribed
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  ngAfterViewInit() {
    this.windowPane.updateIconTemplate(this.windowIconTemplate);
    this.windowPane.updateTitleTemplate(this.windowTitleTemplate);
    this.windowPane.updateSubtitleTemplate(this.windowSubtitleTemplate);

    this.createTaskService.startNameSubscription(this.taskForm.controls.name);

    /**
     * intentionally reset the formgroup after the name subscription to emit an event,
     * trigger subscription, and update the window title
     */
    this.taskFormService.reset(this.templateControl, this.taskForm);
  }

  onAssign() {
    this.taskFormService.subscribeAssigner(
      this.taskForm.controls.assigned,
      this.taskForm.controls.subscribed
    );
  }

  /**
   * Removes linked entity related properties from the selected template before patching
   * the form. These and their validation are managed by the bulk-targeting component.
   */
  async setTemplate(templateId: ListDisplayItemValue) {
    const template = await firstValueFrom(
      this.createTaskService.getTemplate(templateId as string)
    );
    const entity = ['accountType', 'entityId', 'entityName', 'entityType'];
    entity.forEach(field => delete template[field]);
    this.taskForm.patchValue(template as any);
  }

  changeStatus(statusId: string): void {
    this.taskForm.controls.status.setValue(
      statusId ? { [statusId]: true } : null
    );
  }

  changePhase(phaseId: string): void {
    this.taskForm.controls.phase.setValue(phaseId ? { [phaseId]: true } : null);
  }

  /**
   * Builds the queue item and adds queue related properties (status, taskId) to
   * the target. Starts every item with the "QUEUED" status.
   *
   * @param target
   * @returns
   */
  initQueue(target: Target): TaskQueueItem[] {
    return target.members.map(member => ({
      ...member,
      status: 'QUEUED',
      taskId: null
    }));
  }

  createTasks() {
    this.submitted = true;
    this.queued.emit(true);

    const target: Target = this.target.value;
    this.queue = this.initQueue(target);

    this.processQueue();
  }

  /**
   * Builds the the item's task metadata and creates it. Assigns the
   * appropriate status depending on the result.
   *
   * @param item
   */
  async proccessItem(item: TaskQueueItem) {
    item.status = 'IN PROGRESS';
    this.queueTracker && this.queueTracker.updateQueue();

    const task = {
      ...this.taskForm.value,
      entityType: this.target.value.level,
      entityId: item.id,
      entityName: item.name,
      ...(item.type ? { accountType: item.type } : {})
    };
    const { customData } = item;

    try {
      const taskId = await this.taskFormService.createTask({
        fields: task,
        isBulk: true,
        customData
      });

      item.status = 'DONE';
      item.taskId = taskId;
      this.create.emit(taskId);
    } catch (error) {
      item.status = 'FAILED';
      this.hasFailedTasks = true;
      bugsnagClient.notify(error);
    }

    this.queueTracker && this.queueTracker.updateQueue();
  }

  /**
   * Processes all the items in a batch and returns an empty array to
   * get ready for the next batch.
   *
   * @param batch
   * @returns
   */
  async processBatch(batch: TaskQueueItem[]): Promise<TaskQueueItem[]> {
    await Promise.all(batch.map(item => this.proccessItem(item)));
    return [];
  }

  /**
   * Builds batches and processes the items by number defined by BATCH_MAX.
   * Skips any items that are 'DONE' or 'IN PROGRESS'.
   */
  async processQueue() {
    this.inProgress = true;
    this.cancelled = false;

    let batch: TaskQueueItem[] = [];
    for (const item of this.queue) {
      if (this.cancelled) {
        break;
      }

      if (item.status === 'QUEUED' || item.status === 'FAILED') {
        batch.push(item);

        if (batch.length === this.BATCH_MAX) {
          batch = await this.processBatch(batch);
        }
      }
    }

    /**
     * Once the queue finishes and there's still items left on the batch,
     * typically happens when the total number of items is not divisible by
     * the BATCH_MAX set, process the remaining items.
     */
    if (batch.length !== 0) {
      batch = await this.processBatch(batch);
    }

    this.inProgress = false;
    this.hasFailedTasks = !!this.queueTracker?.summary['FAILED'];
    this.cdr.detectChanges();
  }

  /**
   * Retries a task (if provided) or the whole queue
   *
   * @param task
   */
  async retry(task?: TaskQueueItem) {
    if (task) {
      await this.proccessItem(task);
    } else {
      await this.processQueue();
    }
  }

  async cancel() {
    this.cancelled = true;
  }

  close() {
    this.windowPane.close();
  }

  async getCustomerIdFromAccount(
    accountId: string,
    accountType: string
  ): Promise<string> {
    const accountMeta = await firstValueFrom(
      this.customerAccountsService.getAccount<AccountType>({
        accountId,
        accountType
      })
    );

    const { customerId } = accountMeta || {};
    return customerId;
  }

  async cellAction(action: CellAction) {
    const data = action.data as TaskQueueItem;

    switch (action.name) {
      case 'view':
        /**
         * If the task scheduled, redirect to the customer's scheduled tasks page.
         * Otherwise, view task directly.
         */
        const isScheduled = this.taskForm.controls.scheduled.value;
        if (isScheduled) {
          const customerId = data.type
            ? await this.getCustomerIdFromAccount(data.id, data.type)
            : data.id;

          if (customerId) {
            this.router.navigate([
              '/customers',
              customerId,
              'tasks',
              'scheduled'
            ]);
          } else {
            this.matSnackBar.open('Customer not found', null, {
              duration: SNACKBAR_DURATION_ERROR
            });
          }
        } else {
          this.router.navigate(['/tasks', data.taskId]);
        }
        break;
      case 'retry':
        if (!this.inProgress) {
          this.inProgress = true;
          await this.retry(data);
          this.inProgress = false;
        } else {
          this.matSnackBar.open('The queue is still in progress', null, {
            duration: SNACKBAR_DURATION_ERROR
          });
        }
    }
  }

  async validateAssignedRoles(target: Target): Promise<void> {
    const errors = this.taskForm.errors;

    // skip missing roles check if required entity doesn't match
    if (!errors?.entityError) {
      const invalidEntities: any[] = [];
      await Promise.all(
        target.members.map(async entity => {
          const { id: entityId, type: accountType, name: entityName } = entity;
          const assignedRoles = this.taskForm.controls.assignedRoles.value;
          const entityType = this.entityType;
          const entityParams = {
            entityId,
            entityType,
            accountType
          };
          const missingRoles = await firstValueFrom(
            TaskFormValidators.checkMissingRoles(
              {
                ...entityParams,
                assignedRoles
              },
              this.entityUtilsService
            )
          );
          if (missingRoles) {
            invalidEntities.push({
              ...entityParams,
              entityName,
              missingRoles
            });
          }
        })
      );

      if (invalidEntities.length) {
        this.taskForm.setErrors({ missingRoles: invalidEntities });
        this.cdr.detectChanges();
      }
    }
  }

  ngOnDestroy() {
    this.createTaskService.stopNameSubscription();
  }
}
