import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SatPopoverModule } from '@ncstate/sat-popover';

import { MarkdownModule } from '../markdown/markdown.module';
import { RichTooltipComponent } from './rich-tooltip.component';
import { RichTooltipDirective } from './rich-tooltip.directive';

@NgModule({
  declarations: [RichTooltipComponent, RichTooltipDirective],
  imports: [CommonModule, MarkdownModule, SatPopoverModule],
  exports: [RichTooltipComponent, RichTooltipDirective]
})
export class RichTooltipModule {}
