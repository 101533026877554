import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { AccountDashboardService } from '../../account-dashboard.service';
import { COMMetric } from '../../account-dashboard.interface';

@Component({
  selector: 'ease-competitive-metric-popover',
  templateUrl: './competitive-metric-popover.component.html',
  styleUrls: ['./competitive-metric-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompetitiveMetricPopoverComponent implements OnInit {
  public lowestVerticalCPL: number;
  public highestVerticalCPL: number;
  public accountVerticalRank: string;
  private _competitiveMetric: COMMetric;

  @Input() set competitiveMetric(metric: COMMetric) {
    if (metric) {
      this._competitiveMetric = metric;
      this.lowestVerticalCPL =
        this.accountDashboardService.getLowestVerticalCPL(metric);
      this.highestVerticalCPL =
        this.accountDashboardService.getHighestVerticalCPL(metric);
      this.accountVerticalRank =
        this.accountDashboardService.getAccountVerticalRank(metric);
    }
  }

  get competitiveMetric(): COMMetric {
    return this._competitiveMetric;
  }

  constructor(private accountDashboardService: AccountDashboardService) {}

  ngOnInit() {}
}
