import { filter, startWith, take } from 'rxjs/operators';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostBinding,
  Inject,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import scrollIntoView from 'scroll-into-view-if-needed';

/**
 * Expansion panel content that will be rendered lazily
 * after the panel is opened for the first time.
 */
@Directive({
  selector:
    'ng-template[easeSheetExpandContent], ng-template[sheet-expand-after-lazy]'
})
export class SheetExpandContentDirective {
  constructor(public template: TemplateRef<any>) {}
}

/**
 * Sheet Expander
 */
@Component({
  selector: 'ease-sheet-expand',
  exportAs: 'sheetExpand',
  templateUrl: './sheet-expander.component.html',
  styleUrls: ['./sheet-expander.component.scss']
})
export class SheetExpandComponent implements AfterContentInit {
  @ContentChild(SheetExpandContentDirective)
  lazyContent: SheetExpandContentDirective;
  @HostBinding('class.is--expanded')
  get expanded() {
    return !!this.isExpanded;
  }
  @Input()
  expandIcon: string = 'expand_more';
  @Input()
  expandViaHost: boolean = true;
  @Input()
  disableExpandIcon: boolean = false;

  @Output()
  opened: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  closed: EventEmitter<void> = new EventEmitter<void>();
  public isExpanded: boolean = false;
  public portal: TemplatePortal;
  public expander: SheetExpanderDirective;

  constructor(
    @Host()
    @Inject(forwardRef(() => SheetExpanderDirective))
    expander,
    private vcr: ViewContainerRef,
    private elementRef: ElementRef
  ) {
    this.expander = expander as SheetExpanderDirective;
  }

  ngAfterContentInit() {
    if (this.lazyContent) {
      // Render the content as soon as the panel becomes open.
      this.opened
        .pipe(
          startWith(),
          filter(() => this.isExpanded && !this.portal),
          take(1)
        )
        .subscribe(() => {
          this.portal = new TemplatePortal(this.lazyContent.template, this.vcr);
        });
    }
  }

  handleHostClick() {
    this.expandViaHost && !this.isExpanded && this.open();
  }

  open() {
    this.expander.closeAll();
    this.isExpanded = true;
    this.expander.hasExpanded = true;
    this.opened.next();
  }

  scrollToSelf() {
    // Buffer a bit before expander has fully opened
    setTimeout(() =>
      scrollIntoView(this.elementRef.nativeElement, {
        behavior: 'smooth',
        block: 'start',
        boundary: document.body
      })
    );
  }

  toggle() {
    this.isExpanded ? this.close() : this.open();
  }

  close() {
    this.isExpanded = false;
    this.expander.hasExpanded = false;
    this.closed.emit();
  }
}

/**
 * Sheet Expander
 */
@Directive({
  selector: '[easeSheetExpander]',
  exportAs: 'sheetExpander'
})
export class SheetExpanderDirective {
  @Input()
  pushSurrounding: boolean = true;
  @ContentChildren(forwardRef(() => SheetExpandComponent))
  sheetsList: QueryList<SheetExpandComponent>;
  @HostBinding('class.has--expanded')
  get expanded() {
    return !!this.hasExpanded;
  }
  public hasExpanded: boolean = false;

  closeAll() {
    this.sheetsList.toArray().forEach(sheet => sheet.close());
  }
}
