import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel } from 'src/app/users/user.model';

export interface UserDragMeta {
  active: boolean;
  containerId?: string;
  user?: UserModel;
  isOverContainer?: boolean;
}

@Injectable()
export class UserSelectorInputService {
  private isDragging: BehaviorSubject<UserDragMeta> = new BehaviorSubject({
    active: false
  });
  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  setRenderer(renderer: Renderer2) {
    this.renderer = renderer;
  }

  setIsDragging(isDragging: UserDragMeta): void {
    this.isDragging.next(isDragging);

    // If renderer is defined, toggle drag cursor style
    this.renderer?.[isDragging.active ? 'addClass' : 'removeClass'](
      this.document.body,
      'cursor-grabbing'
    );
  }

  getIsDragging(): Observable<UserDragMeta> {
    return this.isDragging.asObservable();
  }
}
