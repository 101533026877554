import { NgModule } from '@angular/core';

import { ContactPhoneIconModule } from 'src/app/contacts/contact-phone-icon/contact-phone-icon.module';
import { SharedFormModule } from '../../shared/shared-form.module';
import { SharedModule } from '../../shared/shared.module';
import { ContactTypeViewComponent } from './contact-type-view/contact-type-view.component';
import { CustomerContactEditComponent } from './customer-contact-edit/customer-contact-edit.component';
import { CustomerContactsListComponent } from './customer-contacts-list/customer-contacts-list.component';
import { CustomerContactViewComponent } from './customer-contact-view/customer-contact-view.component';
import { EmptyContactTypePipe } from './contact-type-view/empty-contact-type.pipe';

@NgModule({
  imports: [ContactPhoneIconModule, SharedFormModule, SharedModule],
  declarations: [
    CustomerContactEditComponent,
    CustomerContactViewComponent,
    CustomerContactsListComponent,
    ContactTypeViewComponent,
    EmptyContactTypePipe
  ],
  exports: [
    CustomerContactEditComponent,
    CustomerContactViewComponent,
    CustomerContactsListComponent,
    ContactTypeViewComponent
  ]
})
export class CustomerContactsModule {}
