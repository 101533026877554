import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding
} from '@angular/core';

import { GridEntity } from '../../grid-toolbar/grid-targeting/grid-targeting.interface';

@Component({
  selector: 'ease-bulk-targeting-help',
  templateUrl: './bulk-targeting-help.component.html',
  styleUrls: ['./bulk-targeting-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkTargetingHelpComponent implements OnInit {
  @HostBinding('class') class = 'block bg-white';
  @Input() entity: GridEntity = 'customer';
  @Input() method: 'paste' | 'upload' = 'paste';
  @Input() separator: 'tab' | 'comma' = 'tab';

  constructor() {}

  ngOnInit(): void {}
}
