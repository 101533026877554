<div class="relative flex h-full w-full flex-row items-center justify-center">
  <ease-finder
    #finder
    class="custom-app-finder flex-1"
    appearance="fill"
    [showSearchTypeFilter]="true"
    [clearOnSelect]="true"
    [finderSettings]="userFinderSettings"
    [ngClass]="{
      'panel-open': panelOpen$ | async
    }"
    (opened)="setPanelState(true)"
    (closed)="setPanelState(false)"
    (filterChanged)="onFilterChange($event)"
    (selected)="goToItem($event)"
  ></ease-finder>

  <ng-container
    *ngIf="{
      isOpen: panelOpen$ | async
    } as panelState"
  >
    <div
      class="absolute right-2 hidden flex-row items-center justify-center space-x-2 rounded-md border border-solid px-1 text-sm opacity-50 md:flex"
      [ngClass]="{
        'border-black text-black': panelState.isOpen,
        'border-white text-white': !panelState.isOpen
      }"
      *ngIf="environmentService.platformType$ | async as platformType"
    >
      <ng-container *ngIf="!panelState.isOpen">
        <span *ngIf="platformType !== 'MAC'">Ctrl K</span>
        <span *ngIf="platformType === 'MAC'">⌘K</span>
      </ng-container>

      <span *ngIf="panelState.isOpen">esc</span>
    </div>
  </ng-container>
</div>
