<div *ngFor="let checklist of taskChecklists$ | async; trackBy: getKey">
  <ease-task-checklist
    *ngIf="templates[checklist?.$key] | async as checklistTemplate"
    (checklistChanged)="onChecklistChanged(checklist?.$key, $event)"
    (checklistOnRemove)="onChecklistRemove($event)"
    (actionTriggered)="
      taskChecklistActionsService.checklistActionTrigger(
        $event,
        taskId,
        checklist?.$key
      )
    "
    [disabled]="disabled"
    [checklistData]="checklist.data"
    [checklistTemplate]="checklistTemplate"
    [taskId]="taskId"
    [taskState]="taskState"
  >
  </ease-task-checklist>
</div>
