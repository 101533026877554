import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { SheetsModule } from '../sheet/sheets.module';
import { EaseMaterialModule } from '../ease-material.module';

import { AdblockDetectorDialogComponent } from './adblock-detector-dialog.component';

@NgModule({
  declarations: [AdblockDetectorDialogComponent],
  imports: [CommonModule, SheetsModule, MatButtonModule, EaseMaterialModule],
  exports: [AdblockDetectorDialogComponent]
})
export class AdblockDetectorDialogModule {}
