import {
  ActivatedRouteSnapshot,
  CanActivate,
  Params,
  Router
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { WindowService } from './window.service';
import { WindowType } from './window-types';

/**
 * This guard is in charge of creating windows from a url. it can receive query parameters and
 * get transformed into data accessible through the window.
 *
 * Examples
 * Create task: https://ease.searchkings.ca/windows/create/createTask?data_entityId=SKC1002005226&data_entityType=customer
 * View task: https://ease.searchkings.ca/windows/create/viewTask?data=-MeaLkKXjdzpUlwdtU6N
 *
 * QueryParameters can be transformed into a data object when each query is prefixed with "data_"
 * ?data_entityId=SKC1002005226&data_entityType=customer => {"entityId": "SKC1002005226", "entityType": "customer"}
 *
 */

@Injectable({ providedIn: 'root' })
export class WindowCreateGuard implements CanActivate {
  constructor(
    private windowService: WindowService,
    private router: Router,
    private location: Location
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    this.location.replaceState('/');

    if (route.queryParams['backgroundUrl']) {
      await this.router.navigateByUrl(route.queryParams['backgroundUrl'], {
        replaceUrl: true
      });
    } else {
      await this.router.navigate(['/dashboards'], {
        replaceUrl: true
      });
    }

    await this.windowService.create({
      type: route.paramMap.get('windowType') as WindowType,
      data: this.parseDataFromQueryParams(route.queryParams),
      routeableParams: route.queryParams
    });

    return false;
  }

  parseDataFromQueryParams(queryParams: Params) {
    const parsedQueriesToData = Object.keys(queryParams).reduce((acc, key) => {
      if (key.includes('data_')) {
        acc[key.replace('data_', '')] = queryParams[key];
      }
      return acc;
    }, {});

    // Check if data query is present
    if (queryParams['data']) {
      return queryParams['data'];

      // Check if data_ prefix is present
    } else if (Object.keys(parsedQueriesToData).length) {
      return parsedQueriesToData;

      // otherwise return data as null
    } else {
      return null;
    }
  }
}
