import { Pipe, PipeTransform } from '@angular/core';

import { TypesenseCollection } from './search.interface';
import { SearchService } from './search.service';

@Pipe({
  name: 'stripTypesensePrefix'
})
export class StripTypesensePipe implements PipeTransform {
  constructor(private searchService: SearchService) {}

  transform(collectionName: string): TypesenseCollection {
    return this.searchService.getCollectionNameWithoutEnvironment(
      collectionName
    );
  }
}
