import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ease-sheet-divider',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./sheet-divider.component.scss']
})
export class SheetDividerComponent {
  @HostBinding('class') hostClass = 'block h-[1px] bg-gray-200 mt-5 mb-5';
}
