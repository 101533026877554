import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MarkdownModule } from '../../shared/markdown/markdown.module';
import { SharedModule } from '../../shared/shared.module';
import { SharedPipesModule } from '../../shared/shared-pipes.module';
import { TaskBudgetModule } from './task-budget/task-budget.module';
import { TaskChecklistModule } from './task-checklist/task-checklist.module';
import { TaskCustomerMessageGroupsModule } from './task-customer-message-groups/task-customer-message-groups.module';
import { TaskFundingModule } from './task-funding/task-funding.module';

import { TaskAdDisapprovalsComponent } from './task-ad-disapprovals/task-ad-disapprovals.component';
import { TaskAdExtensionDisapprovalsComponent } from './task-ad-extension-disapprovals/task-ad-extension-disapprovals.component';
import { TaskCampaignLocationTargetingComponent } from './task-campaign-location-targeting/task-campaign-location-targeting.component';
import { TaskCampaignNegativeKeywordsComponent } from './task-campaign-negative-keywords/task-campaign-negative-keywords.component';
import { TaskCompletionMessageComponent } from './task-completion-message/task-completion-message.component';
import { TaskDescriptionComponent } from './task-description/task-description.component';
import { TaskDisplayCampaignAdScheduleComponent } from './task-display-campaign-ad-schedule/task-display-campaign-ad-schedule.component';
import { TaskDisplayLocationTargetingComponent } from './task-display-location-targeting/task-display-location-targeting.component';
import { TaskDisplayTargetingComponent } from './task-display-targeting/task-display-targeting.component';
import { TaskDisplayTextAdFeedComponent } from './task-display-text-ad-feed/task-display-text-ad-feed.component';
import { TaskKeywordConflictsComponent } from './task-keyword-conflicts/task-keyword-conflicts.component';
import { TaskMatchedItemsComponent } from './task-matched-items/task-matched-items.component';
import { TaskConsultingPaymentDeclinedComponent } from './task-consulting-payment-declined/task-consulting-payment-declined.component';
import { TaskScheduledStatusComponent } from './task-scheduled-status/task-scheduled-status.component';
import { TaskKeywordDisapprovalsComponent } from './task-keyword-disapprovals/task-keyword-disapprovals.component';
import { TaskShoppingAdIssueComponent } from './task-shopping-ad-issue/task-shopping-ad-issue.component';

@NgModule({
  imports: [
    FormsModule,
    MarkdownModule,
    ReactiveFormsModule,
    SharedModule,
    SharedPipesModule,
    TaskBudgetModule,
    TaskChecklistModule,
    TaskCustomerMessageGroupsModule,
    TaskFundingModule
  ],
  declarations: [
    TaskAdDisapprovalsComponent,
    TaskAdExtensionDisapprovalsComponent,
    TaskCampaignLocationTargetingComponent,
    TaskCampaignNegativeKeywordsComponent,
    TaskCompletionMessageComponent,
    TaskConsultingPaymentDeclinedComponent,
    TaskDescriptionComponent,
    TaskDisplayCampaignAdScheduleComponent,
    TaskDisplayLocationTargetingComponent,
    TaskDisplayTargetingComponent,
    TaskDisplayTextAdFeedComponent,
    TaskKeywordConflictsComponent,
    TaskMatchedItemsComponent,
    TaskScheduledStatusComponent,
    TaskShoppingAdIssueComponent,
    TaskKeywordDisapprovalsComponent
  ],
  exports: [
    TaskAdDisapprovalsComponent,
    TaskAdExtensionDisapprovalsComponent,
    TaskBudgetModule,
    TaskCampaignLocationTargetingComponent,
    TaskCampaignNegativeKeywordsComponent,
    TaskChecklistModule,
    TaskCompletionMessageComponent,
    TaskConsultingPaymentDeclinedComponent,
    TaskCustomerMessageGroupsModule,
    TaskDescriptionComponent,
    TaskDisplayCampaignAdScheduleComponent,
    TaskDisplayLocationTargetingComponent,
    TaskDisplayTargetingComponent,
    TaskDisplayTextAdFeedComponent,
    TaskFundingModule,
    TaskKeywordConflictsComponent,
    TaskMatchedItemsComponent,
    TaskScheduledStatusComponent,
    TaskShoppingAdIssueComponent,
    TaskKeywordDisapprovalsComponent
  ]
})
export class TaskModulesModule {}
