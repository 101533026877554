<form [formGroup]="settingsForm" (ngSubmit)="onSubmit()">
  <ease-sheet class="shadow-md">
    <ease-sheet-header class="!flex flex-row items-center justify-start">
      <mat-icon class="mat-icon-30 mr-2">merge_type</mat-icon> Shared Budget
    </ease-sheet-header>
    <ease-sheet-content>
      <div class="form-group">
        <mat-form-field>
          <input
            formControlName="budget"
            placeholder="Monthly Budget"
            type="text"
            matInput
          />
          <mat-error
            easeFormErrors
            [errorMessages]="{
              min:
                'Value must be at least ' +
                settingsForm.controls.budget?.errors?.min?.min,
              pattern: 'Budgets may only contain whole numbers'
            }"
            [control]="settingsForm.controls.budget"
          ></mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-checkbox
          formControlName="enabled"
          color="primary"
          (change)="toggleEnableAlerts($event)"
          >Enable alerts and manage budget as a combined total</mat-checkbox
        >
      </div>
      <ng-container *ngIf="settingsForm.controls.enabled.value">
        <div>
          <label class="form-control-label">Above Trigger</label>
          <input formControlName="upperLimit" type="range" min="0" max="50" />
          <div class="text-center">
            <div class="font-medium">
              {{ settingsForm.controls.upperLimit.value }}%
              <span class="text-gray-400">{{
                upperLimitDelta | currency: currencyCode:'symbol':'1.0-2'
              }}</span>
            </div>
          </div>
        </div>
        <div>
          <label class="form-control-label">Below Trigger</label>
          <input formControlName="lowerLimit" type="range" min="0" max="50" />
          <div class="text-center">
            <div class="font-medium">
              {{ settingsForm.controls.lowerLimit.value }}%
              <span class="text-gray-400">{{
                lowerLimitDelta | currency: currencyCode:'symbol':'1.0-2'
              }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ease-sheet-content>
    <ease-sheet-footer>
      <button
        *ngIf="settingsForm.dirty"
        (click)="onCancel()"
        type="button"
        mat-button
      >
        Cancel
      </button>
      <button
        type="submit"
        color="primary"
        mat-button
        [disabled]="settingsForm.invalid"
      >
        Save
      </button>
    </ease-sheet-footer>
  </ease-sheet>
</form>
