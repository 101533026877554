import { NgModule } from '@angular/core';

import { SharedModule } from '../shared.module';
import { RoleSelectorComponent } from './role-selector/role-selector.component';
import { RoleDialogComponent } from './role-dialog/role-dialog.component';

@NgModule({
  imports: [SharedModule],
  declarations: [RoleSelectorComponent, RoleDialogComponent],
  exports: [RoleSelectorComponent]
})
export class RoleSelectorModule {}
