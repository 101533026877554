import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { COMMetric } from '../../account-dashboard.interface';

@Component({
  selector: 'ease-competitive-metric-cell',
  templateUrl: './competitive-metric-cell.component.html',
  styleUrls: ['./competitive-metric-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompetitiveMetricCellComponent implements AgRendererComponent {
  public competitiveMetric: COMMetric;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    if (params.data) {
      this.competitiveMetric = params.data.comMetric;
    }
  }

  refresh(params: ICellRendererParams) {
    return true;
  }
}
