<form [formGroup]="form" class="flex flex-1 flex-col">
  <mat-form-field>
    <mat-label>Summary</mat-label>
    <input
      formControlName="summary"
      placeholder="A brief, one-line summary of the announcement"
      [readonly]="isReadOnly"
      matInput
    />
  </mat-form-field>

  <ease-markdown-editor
    *ngIf="!isReadOnly; else renderDescription"
    [placeholder]="
      'Detailed information that the user can read after clicking “Read More”'
    "
    formControlName="details"
  >
  </ease-markdown-editor>
  <ng-template #renderDescription>
    <div
      class="description-rendered"
      [easeMarkdown]="form.controls.details.value"
    ></div>
  </ng-template>

  <ease-datepicker
    formControlName="expiryDate"
    placeholder="Expiry Date"
    [min]="isReadOnly ? null : MIN_EXPIRY_DATE"
    [isReadOnly]="isReadOnly"
  ></ease-datepicker>
</form>
