import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Account, CPLMetric } from '../../account-dashboard.interface';
import { CustomerAccountRulesService } from '../../../../customers/customer-accounts/customer-account-rules.service';
import { AccountDashboardService } from '../../account-dashboard.service';

@Component({
  selector: 'ease-cpl-popover',
  templateUrl: './cpl-popover.component.html',
  styleUrls: ['./cpl-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CplPopoverComponent implements OnInit {
  @Input() account: Account;
  @Output() cplChanged: EventEmitter<number> = new EventEmitter<number>();
  public costPerLeadMetric: CPLMetric;
  public targetCPLControl: FormControl<number>;
  public editingTarget = false;
  public targetCplNumber: number;

  constructor(
    private formBuilder: FormBuilder,
    private customerAccountRulesService: CustomerAccountRulesService,
    private matSnackBar: MatSnackBar,
    public accountDashboardService: AccountDashboardService
  ) {}

  ngOnInit() {
    this.costPerLeadMetric = this.account.cplMetric;
    this.targetCPLControl = this.formBuilder.control(this.account.targetCpl, [
      Validators.min(0)
    ]);
    this.targetCplNumber = Number(this.costPerLeadMetric.cplTarget);
  }

  cancelTargetCPL() {
    this.editingTarget = false;
    this.targetCPLControl.patchValue(this.costPerLeadMetric.cplTarget);
  }

  saveTargetCPL() {
    this.customerAccountRulesService
      .update(
        {
          accountId: this.account.accountId,
          accountType: this.account.accountType
        },
        'costPerConversion',
        this.customerAccountRulesService.getSettingsForCplValue(
          Number(this.targetCPLControl.value)
        )
      )
      .then(response => {
        this.matSnackBar.open(
          'Target CPL Updated. This will take 24h to reflect. ',
          'Close'
        );
        this.cplChanged.emit(this.targetCPLControl.value);

        this.editingTarget = false;
      });
  }
}
