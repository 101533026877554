import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { UserModes } from 'src/app/users/user.model';
import { TimezoneDateFormatPipe } from '../timezone-date-format.pipe';

@Pipe({
  name: 'avatarAwayToolTip'
})
export class AvatarAwayTooltipPipe implements PipeTransform {
  constructor(private timezoneDateFormatPipe: TimezoneDateFormatPipe) {}
  transform(mode: UserModes<number>) {
    let message: string = 'At work';

    if (mode?.away && mode?.awayModeSettings) {
      message = 'Away recently';
      const dateFormat = 'ddd, MMM DD';
      const today = moment().startOf('day');
      const { start, end, awaySince } = mode.awayModeSettings || {};
      const duration = moment.duration(today.diff(moment(awaySince)));
      const awaySinceInDays = Math.ceil(duration.asDays());

      if (awaySince && awaySinceInDays > 1) {
        message = `Away ${awaySinceInDays} days`;
      }

      if (!!start && !!end && !!!awaySince && start !== end) {
        const startDate = this.timezoneDateFormatPipe.transform(
          start,
          dateFormat
        );
        const endDate = this.timezoneDateFormatPipe.transform(end, dateFormat);
        message = `Away from ${startDate} — ${endDate}`;
      }
    }

    return message;
  }
}
