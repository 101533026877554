import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SatPopoverModule } from '@ncstate/sat-popover';

import { SharedModule } from '../../shared.module';
import { UserProfileModule } from '../../../users/user-profile/user-profile.module';
import { AdwordsManagerLinksDialogModule } from '../../../accounts/adwords-manager-links-dialog/adwords-manager-links-dialog.module';
import { NavSectionComponent } from './nav-section/nav-section.component';
import { NavSectionHeaderComponent } from './nav-section-header/nav-section-header.component';
import { NavSectionItemsComponent } from './nav-section-items/nav-section-items.component';
import { NavItemComponent } from './nav-item/nav-item.component';
import { AppNavComponent } from './app-nav.component';

@NgModule({
  declarations: [
    AppNavComponent,
    NavItemComponent,
    NavSectionComponent,
    NavSectionHeaderComponent,
    NavSectionItemsComponent
  ],
  imports: [
    AdwordsManagerLinksDialogModule,
    RouterModule,
    UserProfileModule,
    SatPopoverModule,
    SharedModule
  ],
  exports: [AppNavComponent]
})
export class AppNavModule {}
