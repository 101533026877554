import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { map, take } from 'rxjs/operators';
import { USER_GROUPS_PATH } from '../../shared/firebase-paths';

export interface UserGroup {
  $key?: string;
  name: string;
  users: string[];
}

@Injectable({ providedIn: 'root' })
export class UserGroupsService {
  constructor(private angularFire: FirebaseDbService) {}

  create(userGroup) {
    const promises = userGroup.users.map(user =>
      this.angularFire
        .object(`/${USER_GROUPS_PATH}/${userGroup.alias}/users/${user}`)
        .set(true)
    );
    promises.push(
      this.angularFire
        .object(`/${USER_GROUPS_PATH}/${userGroup.alias}/name`)
        .set(userGroup.name)
    );
    return Promise.all(promises);
  }

  update(key, userGroup) {
    const promises = [];
    if (key !== userGroup.alias) {
      promises.push(
        this.angularFire.object(`/${USER_GROUPS_PATH}/${key}`).remove()
      );
    }
    promises.push(
      this.angularFire
        .object(`/${USER_GROUPS_PATH}/${userGroup.alias}/users`)
        .remove(),
      this.create(userGroup)
    );
    return Promise.all(promises);
  }

  remove(key: string) {
    return this.angularFire.object(`${USER_GROUPS_PATH}/${key}`).remove();
  }

  getAll(): Observable<UserGroup[]> {
    return this.angularFire.getList(`/${USER_GROUPS_PATH}/`).pipe(
      map(userGroups =>
        userGroups.map(userGroup => {
          userGroup.users = userGroup.users ? Object.keys(userGroup.users) : [];
          return userGroup;
        })
      )
    );
  }

  groupValid(name: string): Observable<boolean> {
    if (!name || !name.length) {
      return of(true);
    }

    return this.angularFire.getObject(`/${USER_GROUPS_PATH}/${name}`).pipe(
      take(1),
      map(foundGroups => !foundGroups.$exists())
    );
  }
}
