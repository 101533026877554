import * as moment from 'moment';
import forEach from 'lodash-es/forEach';
import isObject from 'lodash-es/isObject';
import isEqual from 'lodash-es/isEqual';
import isPlainObject from 'lodash-es/isPlainObject';
import { Options, RRule } from 'rrule';
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';

import { AccountBasicMeta } from 'src/app/customers/customer-accounts/customer-accounts.interface';
import {
  ADWORDS_ACCOUNTS_PATH,
  ADWORDS_ACCOUNT_NOTES_PATH,
  ADWORDS_ACCOUNT_SUBSCRIPTIONS_PATH,
  BING_ACCOUNTS_PATH,
  BING_ACCOUNT_NOTES_PATH,
  BING_ACCOUNT_SUBSCRIPTIONS_PATH,
  CALL_TRACKING_ACCOUNTS_PATH,
  LSA_ACCOUNTS_PATH,
  LSA_ACCOUNT_NOTES_PATH,
  LSA_ACCOUNT_SUBSCRIPTIONS_PATH
} from '../firebase-paths';
import {
  EntityMetadata,
  ListDisplayItem,
  RRuleDateRange
} from '../shared.interface';
import {
  SelectMethod,
  UserSelected
} from '../user-selector/user-selector.interface';
import { RRULE_FREQUENCIES } from '../constants';

export const milliFromDays = (days: number): number => {
  if (!days) {
    throw new Error(
      'milliFromDays requires a number of days greater than 0 to be passed in'
    );
  }

  return days * 86400000;
};

export const milliDaysFromNow = (days: number): number => {
  if (!days) {
    throw new Error(
      'milliDaysFromNow requires a number of days greater than 0 to be passed in'
    );
  }

  return milliFromDays(days) + new Date().getTime();
};

export const firebaseJSON = (item: any | any[]): any | any[] =>
  stripDollarPrefixedKeys(item);

const stripDollarPrefixedKeys = data => {
  if (!isObject(data)) {
    return data;
  }

  const out = Array.isArray(data) ? [] : {};

  forEach(data, (v, k: any) => {
    if (typeof k !== 'string') {
      out[k] = stripDollarPrefixedKeys(v);
    }

    if (typeof k === 'string' && k.charAt(0) !== '$') {
      out[k] = stripDollarPrefixedKeys(v);
    }

    if (typeof v === 'undefined') {
      out[k] = null;
    }
  });
  return out;
};

export const initialsFromName = (name: string): string =>
  name ? name.replace(/[^A-Z]/g, '') : '';

export const fastReverse = (array: any[]): any[] => {
  let left = null;
  let right = null;
  const length = array.length;
  for (left = 0; left < length / 2; left += 1) {
    right = length - 1 - left;
    const temporary = array[left];
    array[left] = array[right];
    array[right] = temporary;
  }
  return array;
};

/**
 *
 *
 * Taken/modified from angularfire2 for internal use.
 *
 * Unwraps the data returned in the DataSnapshot. Exposes the DataSnapshot key and exists methods through the $key and $exists properties respectively. If the value is primitive, it is unwrapped using a $value property. The $ properies mean they cannot be saved in the Database as those characters are invalid.
 *
 *
 *
 * @param snapshot - The snapshot to unwrap
 * @return AFUnwrappedDataSnapshot
 * @example
 * unwrapMapFn(snapshot) => { name: 'David', $key: 'david', $exists: Function }
 */
export const unwrapObject = (data: any): any[] => {
  if (data) {
    return Object.keys(data).map(key => {
      let unwrapped = data[key];

      if (/string|number|boolean/.test(typeof unwrapped)) {
        unwrapped = {
          $value: unwrapped
        };
      }

      Object.defineProperty(unwrapped, '$key', {
        value: key,
        enumerable: false
      });

      return unwrapped;
    });
  } else {
    return null;
  }
};

export const blobToFile = (blob: Blob, fileName: string): File => {
  const b: any = blob;
  b.lastModifiedDate = new Date();
  b.name = fileName;

  return blob as File;
};

export const filterObjectsList = (
  query: string,
  list: any[],
  fields: any[]
): any[] =>
  list.filter(item =>
    fields.some(field => {
      if (item[field] && typeof item[field] === 'string') {
        return item[field].toLowerCase().includes(query.toLowerCase());
      } else {
        return false;
      }
    })
  );

export const sortByStatus = (
  account1: AccountBasicMeta,
  account2: AccountBasicMeta
): number => {
  if (
    account1.status === 'ONLINE' &&
    (account2.status === 'PAUSED' || account2.status === 'OFFLINE')
  ) {
    return -1;
  }

  if (account1.status === 'PAUSED' && account2.status === 'OFFLINE') {
    return -1;
  }

  if (account1.status === 'PAUSED' && account2.status === 'ONLINE') {
    return 1;
  }

  if (account1.status === 'OFFLINE') {
    return 1;
  }

  return 0;
};

export const getSnoozeTimes = (): ListDisplayItem[] =>
  [1, 3, 5, 7, 14, 21, 30, 45, 60, 90]
    .map(dayCount => ({
      value: dayCount as number | string,
      viewValue: `${dayCount} Day(s)`
    }))
    .concat([
      {
        value: 'custom',
        viewValue: 'Custom Date'
      }
    ]);

export const getSelectedUsers = (
  users: Record<string, boolean>,
  delegateMethod: Record<string, SelectMethod>
): UserSelected[] =>
  Object.keys(users).map(userId => ({
    userId,
    method:
      delegateMethod && delegateMethod[userId]
        ? delegateMethod[userId]
        : 'direct'
  }));

export const getFormValueForTask = (task: any, isTemplate?: boolean): any => ({
  accountType: task.accountType || null,
  assigned: task.assigned
    ? Array.isArray(task.assigned)
      ? task.assigned
      : getSelectedUsers(task.assigned, task.delegateMethod)
    : [],
  subscribed: task.subscribed
    ? Array.isArray(task.subscribed)
      ? task.subscribed
      : getSelectedUsers(task.subscribed, task.delegateMethod)
    : [],
  assignCurrentUser: task.assignCurrentUser || null,
  assignedRoles: task.assignedRoles ? task.assignedRoles : {},
  board: task.board || null,
  checklist: task.checklists
    ? Object.keys(task.checklists)[0]
    : task.checklist || null,
  description: task.description || null,
  entityId: task.entityId || null,
  entityName: task.entityName || null,
  entityType: task.entityType || null,
  list: task.list || null,
  name: task.name || null,
  startDate: task.startDate || null,
  dueDate: task.dueDate || null,
  scheduled: task.scheduled || null,
  rrule: task.rrule ? getBumpedRRuleForTask(task.rrule, isTemplate) : null,
  status: task.status || null,
  phase: task.phase || null,
  childrenTemplates: task.childrenTemplates
    ? Array.isArray(task.childrenTemplates)
      ? task.childrenTemplates
      : Object.keys(task.childrenTemplates)
    : []
});

/**
 * Bumps the dtstart value for scheduled tasks / task templates with schedules
 * This will be called when viewing a scheduled task, and also when editing a
 * task template with a schedule defined, or when applying a task template with
 * a schedule defined.
 */
export const getBumpedRRuleForTask = (
  rrule: string,
  isTemplate?: boolean
): string => {
  const ruleOptions = RRule.parseString(rrule);

  if (!isTemplate) {
    ruleOptions.dtstart = getBumpedDateForTask(ruleOptions);

    if (ruleOptions.count === 1) {
      ruleOptions.interval = 1;
    }
  }

  return new RRule(ruleOptions).toString();
};

export const getFrequencyForInteger = (frequency: number) =>
  RRULE_FREQUENCIES.find(freq => freq.value.toFixed() === frequency.toFixed());

/**
 * Bumps the dtstart for a given set of RRule options
 */
export const getBumpedDateForTask = (ruleOptions: Options): Date => {
  /**
   * If the rule has a start date defined, and the start date is in the future
   * (i.e. after today), or the rule is configured to only occur once, then
   * we return the start date (dtstart) as-is, without any modifications.
   */
  if (
    ruleOptions.dtstart &&
    (moment(ruleOptions.dtstart).isAfter(moment(), 'day') ||
      ruleOptions.count === 1)
  ) {
    return ruleOptions.dtstart;
  }

  /**
   * Bump the dtstart by inspecting the options
   */
  return getNextOccurrenceDate(ruleOptions);
};

/**
 * Determines the next occurrence date for a set of rrule options
 * and returns that date.
 */
export const getNextOccurrenceDate = (ruleOptions: Options): Date => {
  /**
   * If there is no start date defined, we look at the interval and frequency
   * values for the rule, and determine the soonest occurring date and return that
   * E.g. if interval is 3 (every 3 'units'), and frequency is "days", then we
   * add that number to today's date -- today + 3 days to get the next occurrence.
   */
  if (!ruleOptions.dtstart) {
    const frequency = getFrequencyForInteger(ruleOptions.freq);
    return moment()
      .startOf('day')
      .add(ruleOptions.interval, frequency.text as any)
      .toDate();
  }

  /**
   * If there is a start date defined, and it is after today (i.e. in the future)
   * then we return that date as the next occurrence date.
   */
  if (moment(ruleOptions.dtstart).isAfter(moment().toDate())) {
    return ruleOptions.dtstart;
  }

  /**
   * If there is a start date defined but it is in the past,
   * we use RRule's .after() feature to determine the next
   * occurrence date.
   */
  return new RRule(ruleOptions).after(moment().toDate(), true);
};

export const RRuleToDateRange = (rrule: string): RRuleDateRange[] => {
  const nowValue = moment().valueOf();
  const rruleOptions = RRule.parseString(rrule);
  const rule = new RRule(rruleOptions);
  const prevOccurrenceDate = rule.before(moment().toDate());
  const nextOccurrenceDate = rule.after(moment().toDate(), true);

  // limit result to between but multiplying times
  const dates = rule.between(
    moment(prevOccurrenceDate)
      .subtract(rruleOptions.interval * 3, 'day')
      .toDate(),
    moment(nextOccurrenceDate)
      .add(rruleOptions.interval * 5, 'day')
      .toDate()
  );

  // format result
  return dates.map(date => {
    const dateValue = moment(date).valueOf();

    if (dateValue === moment(nextOccurrenceDate).valueOf()) {
      return { date, isScheduled: true, isNext: true };
    }

    if (dateValue < nowValue) {
      return { date, isScheduled: false };
    } else {
      return { date, isScheduled: true };
    }
  });
};

export const arrayToFirebaseMap = (list: any[]): Record<string, boolean> =>
  list && Array.isArray(list)
    ? list.reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {})
    : {};

export const setFormGroupTouched = (formGroup: UntypedFormGroup) => {
  Object.values(formGroup.controls).forEach((control: UntypedFormGroup) => {
    control.markAsTouched();

    if (control.controls) {
      setFormGroupTouched(control);
    }
  });
};

export const getFileBase64 = (file: File | Blob): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const buildFormControls = <T>(
  baseValues: any,
  initialGroup: T
): void => {
  const formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

  forEach(baseValues, (val, key: any) => {
    let newControl;

    if (Array.isArray(val)) {
      newControl = formBuilder.array([]);
    } else if (isPlainObject(val)) {
      newControl = formBuilder.group({});
    } else {
      newControl = formBuilder.control(val);
    }

    if (initialGroup instanceof FormGroup) {
      initialGroup.addControl(key, newControl);
    }

    if (initialGroup instanceof FormArray) {
      initialGroup.push(newControl);
    }

    if (Array.isArray(val) || isPlainObject(val)) {
      buildFormControls(val, newControl);
    }
  });
};

export const getUserNameParts = (
  fullName: string
): { firstName: string; lastName: string } => ({
  firstName: fullName ? fullName.slice(0, fullName.indexOf(' ')) : '',
  lastName: fullName ? fullName.slice(fullName.indexOf(' ') + 1) : ''
});

export const transformCurrencyString = (amount: string): number =>
  amount ? Number(amount.replace(/[^0-9.-]+/g, '')) : null;

/**
 * Compare two sets of entity metadata and verify if they are the same
 *
 * If we receive an accountType for both sets of metadata, we ensure the
 * accountType and entityId fields match.
 *
 * If we don't receive an accountType for both sets of metadata,
 * we are likely dealing with a customer/prospect, where a simple
 * comparison of the entityId will suffice.
 */
export const isEntityMetadataSame = (
  { entityId: entityId1, accountType: accountType1 }: Partial<EntityMetadata>,
  { entityId: entityId2, accountType: accountType2 }: Partial<EntityMetadata>
): boolean => {
  if (accountType1 && accountType2) {
    return isEqual(
      {
        entityId: entityId1,
        accountType: accountType1
      },
      {
        entityId: entityId2,
        accountType: accountType2
      }
    );
  } else {
    return entityId1 === entityId2;
  }
};

export const uuidv4 = (): string =>
  (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

export const ucFirst = (text: string) => {
  if (!text) {
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const emptyFormArray = (formArray: UntypedFormArray) => {
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }
};

export const patchFormArray = (
  control: FormArray,
  value: any[],
  options: { onlySelf?: boolean; emitEvent?: boolean } = { emitEvent: true }
): void => {
  value.forEach((newValue: any, index: number) => {
    if (control.at(index)) {
      control.at(index).patchValue(newValue, {
        onlySelf: true,
        emitEvent: options.emitEvent
      });
    }
  });
  control.updateValueAndValidity(options);
};

export const getPathForAccountType = (accountType: string): string => {
  switch (accountType) {
    case 'callTracking':
      return CALL_TRACKING_ACCOUNTS_PATH;

    case 'adwords':
      return ADWORDS_ACCOUNTS_PATH;

    case 'bing':
      return BING_ACCOUNTS_PATH;

    case 'lsa':
      return LSA_ACCOUNTS_PATH;
  }
};

export const getNotesPathForAccountType = (accountType: string): string => {
  switch (accountType) {
    case 'adwords':
      return ADWORDS_ACCOUNT_NOTES_PATH;

    case 'bing':
      return BING_ACCOUNT_NOTES_PATH;

    case 'lsa':
      return LSA_ACCOUNT_NOTES_PATH;
  }
};

export const getSubscriptionsPathForAccountType = (
  accountType: string
): string => {
  switch (accountType) {
    case 'adwords':
      return ADWORDS_ACCOUNT_SUBSCRIPTIONS_PATH;

    case 'lsa':
      return LSA_ACCOUNT_SUBSCRIPTIONS_PATH;

    case 'bing':
      return BING_ACCOUNT_SUBSCRIPTIONS_PATH;
  }
};

export const getFirebaseAccountPath = (accountType: string): string => {
  switch (accountType) {
    case 'adwords':
      return ADWORDS_ACCOUNTS_PATH;
    case 'bing':
      return BING_ACCOUNTS_PATH;
    case 'lsa':
      return LSA_ACCOUNTS_PATH;

    default:
      break;
  }
};

// Guard against zero-width space for user input
// https://en.wikipedia.org/wiki/Zero-width_space
export const zeroWidthRegExp: RegExp = new RegExp(/[\u200B-\u200D\uFEFF]/g);

export const removeZeroWidth = (string: string): string =>
  string.replace(zeroWidthRegExp, '').trim();
