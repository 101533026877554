<div
  class="relative flex h-full w-full cursor-pointer flex-col items-center"
  (click)="indexerService.setActiveIndexer(indexer)"
>
  <ng-container
    [ngTemplateOutlet]="indexerTemplate"
    [ngTemplateOutletContext]="{
      $implicit: {
        index: indexer.index + 1,
        color: isParent
          ? 'bg-gradient-to-b from-red-600 bg-red-700 text-white'
          : isChild
          ? 'bg-gradient-to-b from-orange-600 bg-orange-700 text-white'
          : indexer.index === activeIndex
          ? 'bg-gradient-to-b from-blue-400 bg-blue-500 text-white'
          : 'bg-white',
        tooltip: isParent
          ? 'This item controls the visibility of the currently selected item (blue).'
          : isChild
          ? 'This item&rsquo;s visibility is controlled by the currently selected item (blue).'
          : null
      }
    }"
  >
  </ng-container>
</div>

<ng-template #indexerTemplate let-data>
  <div
    class="inline-block h-8 w-9 rounded-md p-1.5 text-center align-middle font-medium shadow-md"
    [ngClass]="data?.color"
    [matTooltip]="data?.tooltip"
    [matTooltipPosition]="'left'"
  >
    {{ data.index }}
  </div>

  <div
    *ngIf="data?.tooltip"
    class="absolute -top-1 -right-1 h-2 w-2 rounded-full outline outline-[3px] outline-white"
    [ngClass]="data?.color"
  ></div>
</ng-template>
