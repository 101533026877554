<mat-toolbar class="mat-toolbar-white toolbar shadow-md">
  <mat-toolbar-row [formGroup]="dateForm">
    <mat-button-toggle-group class="tabs" formControlName="grouping">
      <mat-button-toggle value="platforms">Platforms</mat-button-toggle>
      <mat-button-toggle value="accounts">Accounts</mat-button-toggle>
    </mat-button-toggle-group>
    <div class="flex-none md:flex-1"></div>
    <mat-form-field class="dropdown flex md:block">
      <mat-select formControlName="date_range">
        <mat-option value="last_7_days">Last 7 Days</mat-option>
        <mat-option value="last_14_days">Last 14 Days</mat-option>
        <mat-option value="last_30_days">Last 30 Days</mat-option>
        <mat-option value="last_month">Last Month</mat-option>
        <mat-option value="this_month">This Month</mat-option>
      </mat-select>
      <mat-icon matPrefix>date_range</mat-icon>
    </mat-form-field>
  </mat-toolbar-row>
</mat-toolbar>
<ease-sheet class="rounded-none shadow-md">
  <ease-chart-group
    [showTabSummary]="dateForm.controls.grouping.value === 'platforms'"
    [metrics]="metrics$ | async"
  >
  </ease-chart-group>
</ease-sheet>

<div class="p-5 md:p-10">
  <ng-container *ngIf="customerAccountBudgetSettings">
    <ng-container *ngIf="customerAccountBudgetSettings.adwords?.length">
      <ease-customer-account-budget-group
        (saved)="getBudgetSummary()"
        [customerId]="customerId"
        [accounts]="customerAccountBudgetSettings.adwords"
        accountType="adwords"
        accountTypeLabel="Ads"
      >
      </ease-customer-account-budget-group>
      <div class="h-5"></div>
    </ng-container>

    <ng-container *ngIf="customerAccountBudgetSettings.bing?.length">
      <ease-customer-account-budget-group
        (saved)="getBudgetSummary()"
        [customerId]="customerId"
        [accounts]="customerAccountBudgetSettings.bing"
        accountType="bing"
        accountTypeLabel="Bing"
      >
      </ease-customer-account-budget-group>
      <div class="h-5"></div>
    </ng-container>

    <ng-container *ngIf="customerAccountBudgetSettings?.lsa?.length">
      <ease-customer-account-budget-group
        (saved)="getBudgetSummary()"
        [customerId]="customerId"
        [accounts]="customerAccountBudgetSettings.lsa"
        accountType="lsa"
        accountTypeLabel="GLS"
      >
      </ease-customer-account-budget-group>
      <div class="h-5"></div>
    </ng-container>
  </ng-container>

  <ease-customer-budget
    (save)="saveCustomerSettings($event)"
    [settings]="customerBudgetSettings$ | async"
  >
  </ease-customer-budget>
</div>
