import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { UserAvatarModel } from '../../users/user.model';
import { UserService } from '../../users/user.service';
import { AvatarSize, AvatarUserStatus } from './avatar.interface';

@Component({
  selector: 'ease-avatar',
  templateUrl: './avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Input() set userId(id: string) {
    this.avatarData$ = this.userService.getAvatar(id);
  }
  @Input() size: AvatarSize = 'small';
  @Input() showTooltip: boolean;
  @Input() showInitials: boolean;
  @Input() status: AvatarUserStatus;
  public avatarData$: Observable<UserAvatarModel>;
  constructor(private userService: UserService) {}
}
