<ng-container
  *ngIf="
    notificationsService.notificationsFilter$ | async as notificationFilters
  "
>
  <ease-sheet-panel class="popover">
    <mat-toolbar
      [ngClass]="{ 'is--not-searching': !isSearching }"
      class="floating-toolbar mat-toolbar-white relative z-10 shadow-md"
    >
      <ng-container *ngIf="!isSearching">
        <nav mat-tab-nav-bar>
          <a
            class="flex flex-row items-center justify-center"
            mat-tab-link
            *ngFor="let tab of tabs"
            (click)="setActiveStatus(tab.value)"
            [active]="activeStatus === tab.value"
            >{{ tab.viewValue }}

            <ng-container
              *ngIf="notificationsService.unreadCount$ | async as unreadCount"
            >
              <ease-color-blob
                *ngIf="
                  tab.value === 'unread' &&
                  (unreadCount.filtered || unreadCount.total)
                "
                class="scale-[0.85]"
                size="xs"
              >
                {{
                  notificationFilters.length &&
                  unreadCount.filtered !== unreadCount.total
                    ? unreadCount.filtered + '/' + unreadCount.total
                    : unreadCount.total
                }}
              </ease-color-blob>
            </ng-container>

            <mat-icon
              *ngIf="tab.tooltip"
              [matTooltip]="tab.tooltip"
              class="mat-icon-14 ml-1"
              >help</mat-icon
            ></a
          >
        </nav>
        <span class="flex-1"></span>
        <button
          mat-icon-button
          [matTooltip]="
            notificationFilters.length ? 'Filter enabled' : 'Filter'
          "
          (click)="filterSelect.open()"
        >
          <span
            *ngIf="notificationFilters.length"
            class="absolute top-1 right-0.5 block h-2 w-2 rounded-full bg-red-600"
          ></span>
          <mat-icon>filter_alt</mat-icon>
        </button>

        <mat-select
          multiple
          [disableOptionCentering]="true"
          #filterSelect
          [formControl]="notificationsService.notificationFilterControl"
          class="toolbar-control"
        >
          <mat-option
            *ngFor="let option of filterOptions"
            [value]="option.value"
            >{{ option.viewValue }}</mat-option
          >
        </mat-select>
        <button mat-icon-button matTooltip="Sort" (click)="sortSelect.open()">
          <mat-icon>sort</mat-icon>
        </button>
        <mat-select
          [disableOptionCentering]="true"
          #sortSelect
          [formControl]="notificationsService.notificationSortControl"
          class="toolbar-control"
        >
          <mat-option
            *ngFor="let option of sortOptions"
            [value]="option.value"
            >{{ option.viewValue }}</mat-option
          >
        </mat-select>
        <button matTooltip="Search" mat-icon-button (click)="startSearch()">
          <mat-icon>search</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="isSearching">
        <mat-form-field floatLabel="never">
          <input
            #searchInput
            [formControl]="searchControl"
            matInput
            placeholder="Search notifications..."
          />
          <button mat-button matSuffix mat-icon-button (click)="stopSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </ng-container>
    </mat-toolbar>
    <ng-container
      [ngTemplateOutlet]="tabTemplate"
      [ngTemplateOutletContext]="{
        list: this.unreadNotifications$ | async,
        status: 'unread'
      }"
    >
    </ng-container>
    <ng-container
      [ngTemplateOutlet]="tabTemplate"
      [ngTemplateOutletContext]="{
        list: this.readNotifications$ | async,
        status: 'read'
      }"
    >
    </ng-container>
  </ease-sheet-panel>

  <ng-template #empty>
    <ease-state-message
      [message]="
        searchControl.value || notificationFilters.length
          ? 'No notifications found'
          : 'No notifications'
      "
      icon="done"
      class="flex h-full flex-row items-center justify-center"
    >
    </ease-state-message>
    <div
      *ngIf="notificationFilters.length"
      class="flex items-center justify-center"
    >
      <small class="mr-2 text-gray-600"> Filters are enabled </small>
      <button
        mat-button
        color="primary"
        class="text-small"
        (click)="clearFilters()"
      >
        Clear filters
      </button>
    </div>
  </ng-template>

  <ng-template #tabTemplate let-status="status" let-list="list">
    <div
      class="flex h-full flex-col sm:h-[75vh]"
      *ngIf="activatedOnceStatus[status]"
      [ngClass]="{ hidden: activeStatus !== status }"
    >
      <ng-container *ngIf="list?.length; else empty">
        <ng-container
          [ngTemplateOutlet]="listTemplate"
          [ngTemplateOutletContext]="{
            list: list
          }"
        >
        </ng-container>
      </ng-container>

      <ease-sheet-footer class="!mb-2">
        <span class="flex-1"></span>
        <button
          (click)="closeNotifications()"
          mat-button
          class="inline-block md:!hidden"
        >
          Close
        </button>
        <button
          *ngIf="list?.length && activeStatus === 'unread'"
          (click)="onMarkAllRead(list)"
          color="primary"
          mat-button
        >
          {{
            searchControl.value || notificationFilters.length
              ? 'Mark Filtered Read'
              : 'Mark All Read'
          }}
        </button>
      </ease-sheet-footer>
    </div>
  </ng-template>

  <ng-template
    #listTemplate
    let-notifications="list"
    let-scrollContainer="scrollContainer"
  >
    <virtual-scroller
      #scroll
      [items]="notifications"
      [enableUnequalChildrenSizes]="true"
    >
      <ease-sheet
        class="shadow-md"
        *ngFor="
          let notification of scroll.viewPortItems;
          trackBy: getItemId;
          let itemIndex = index;
          let first = first;
          let last = last
        "
      >
        <ng-container
          [ngTemplateOutlet]="itemTemplate"
          [ngTemplateOutletContext]="{
            notification: notification,
            itemIndex: itemIndex
          }"
        >
        </ng-container>
      </ease-sheet>
    </virtual-scroller>
  </ng-template>

  <ng-template
    #itemTemplate
    let-notification="notification"
    let-itemIndex="itemIndex"
  >
    <ease-notification-panel
      (clicked)="onNotificationClick($event, notification)"
      (dismiss)="onNotificationDismiss(notification)"
      [notification]="notification"
      [ngClass]="{
        'notification-panel--selected': selectedItemIndex === itemIndex
      }"
    >
    </ease-notification-panel>
  </ng-template>
</ng-container>
