import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ICON_FOR_STATUS } from '../../constants';

@Component({
  selector: 'ease-feed-item-status-change',
  templateUrl: './feed-item-status-change.component.html',
  styleUrls: ['../feed-item/feed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemStatusChangeComponent {
  @Input()
  item;
  @Input()
  users;
  @Input()
  dateFormat: string;
  public iconForStatus = ICON_FOR_STATUS;
}
