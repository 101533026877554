<mat-tab-group mat-stretch-tabs [@.disabled]="true">
  <mat-tab *ngFor="let metric of metrics">
    <ng-template mat-tab-label>
      <div class="chart-tab flex flex-col">
        <div
          [ngClass]="{ 'has--summary': showTabSummary }"
          class="chart-tab__label"
        >
          {{ metric.label }}
        </div>
        <div
          class="chart-tab__summary flex flex-row items-center justify-center"
          *ngIf="showTabSummary"
        >
          <div
            class="chart-tab__summary-item flex flex-row items-center justify-start"
            *ngFor="let summary of metric.summary"
          >
            <ease-account-icon
              class="mr-1"
              *ngIf="summary?.accountType"
              [size]="18"
              [accountType]="summary?.accountType"
            ></ease-account-icon>

            {{
              metric.label === 'Conversions'
                ? summary.data
                : (summary.data | currency: 'USD':'symbol':'1.0-0')
            }}
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template matTabContent>
      <ease-chart
        *ngIf="metric?.chartData?.labels?.length"
        [data]="metric?.chartData"
        [type]="'line'"
        [options]="
          metric.label === 'Conversions' ? chartOptions : chartCurrencyOptions
        "
      >
        <ease-chart-header>{{ metric.label }}</ease-chart-header>
        <ease-chart-summary
          [summary]="metric.summary"
          [metricLabel]="metric.label"
        ></ease-chart-summary>
      </ease-chart>

      <div
        class="p-5 text-center text-gray-400"
        *ngIf="!metric?.chartData?.labels?.length"
      >
        No Data Available
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<ease-sheet *ngIf="!metrics">
  <div class="flex flex-row items-center justify-center p-5 text-center">
    <mat-progress-spinner [diameter]="24" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ease-sheet>
