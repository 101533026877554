import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { UserModel } from 'src/app/users/user.model';
import { ListAsObject } from '../../firebase-db.service';
import { TypesenseCustomerFeedFieldList } from '../../search/search.interface';

import { FeedItem } from '../feed-item.interface';
import { FeedItemService } from '../feed-item.service';

export interface FeedItemChange {
  item: Partial<FeedItem | TypesenseCustomerFeedFieldList>;
  change: any;
}

@Component({
  selector: 'ease-feed-item',
  templateUrl: './feed-item.component.html',
  styleUrls: ['./feed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemComponent {
  @Input()
  dateFormat: string = 'MMM DD, YYYY';
  @Input()
  set editable(editable: boolean) {
    if (typeof editable !== 'undefined' && editable !== null) {
      this.isEditable = editable;
    }
  }
  @Input()
  set item(item: FeedItem | TypesenseCustomerFeedFieldList) {
    this._item = item;

    if (typeof this.isEditable !== 'undefined' && this.isEditable !== null) {
      this.isEditable = this.editableByType();
    }
  }

  get item(): FeedItem | TypesenseCustomerFeedFieldList {
    return this._item;
  }

  @Input()
  type: string;
  @Input()
  endpoint: string;
  @Input()
  users: ListAsObject<UserModel>;
  @Output()
  save: EventEmitter<FeedItemChange> = new EventEmitter<FeedItemChange>();
  public isEditable: boolean = false;
  private _item: FeedItem | TypesenseCustomerFeedFieldList;

  constructor(private feedItemService: FeedItemService) {}

  editableByType(): boolean {
    return this.feedItemService.editableByType(this.item);
  }

  onItemSave(toEmit: any) {
    this.save.emit({
      item: this.item,
      change: toEmit
    });
  }
}
