<ease-sheet class="h-full bg-gray-100">
  <div class="flex flex-1 flex-col gap-1">
    <mat-toolbar>
      <span class="text-lg">Project Overview</span>
      <mat-icon
        class="mat-icon-16 ml-2 cursor-pointer"
        [satPopoverAnchor]="subtaskPopover"
        (click)="subtaskPopover.toggle()"
        >help_outline</mat-icon
      >
      <sat-popover
        verticalAlign="below"
        horizontalAlign="start"
        [autoFocus]="false"
        [hasBackdrop]="true"
        #subtaskPopover
      >
        <ease-subtask-help-popover></ease-subtask-help-popover>
      </sat-popover>
      <div class="flex-1"></div>
      <button
        matTooltip="Add Sub-Task"
        class="!h-8 !w-8 rounded-full"
        type="button"
        mat-icon-button
        mat-stroked-button
        (click)="createTask()"
        matTooltip="Add subtask"
        *ngIf="!task.state"
      >
        <mat-icon class="mat-icon-18">add</mat-icon>
      </button>
    </mat-toolbar>

    <div class="flex h-full flex-1 flex-col shadow-inner">
      <div
        class="flex flex-col gap-2 border-b border-gray-200 px-3 py-3"
        *ngIf="task.parent || task.children"
      >
        <ng-container
          *ngIf="currentOrParentTask$ | async as currentOrParentTask"
        >
          <ease-subtask-item
            [task]="currentOrParentTask"
            [isParent]="true"
            [currentlyViewing]="!task.parent"
          ></ease-subtask-item>
        </ng-container>
      </div>

      <ng-container
        *ngIf="childrenOrSiblingTasks$ | async as childrenOrSiblingTasks"
      >
        <ng-container *ngIf="childrenOrSiblingTasks.length; else empty">
          <div class="flex flex-1 flex-col gap-2 px-3 py-2">
            <div
              class="flex flex-row items-center"
              *ngIf="childrenOrSiblingTasks.length"
            >
              <div class="flex flex-1 flex-row items-center">
                <div class="gap-1 p-2 text-sm font-bold text-gray-500">
                  Subtasks
                </div>

                <span
                  class="inline-block rounded-full bg-zinc-200 px-2 text-xs font-bold text-zinc-400"
                  >{{ childrenOrSiblingTasks.length }}</span
                >
              </div>
            </div>

            <ease-subtask-item
              *ngFor="let subtask of childrenOrSiblingTasks; trackBy: getKey"
              [task]="subtask"
              [currentlyViewing]="subtask.$key === task.$key"
            ></ease-subtask-item>
          </div> </ng-container
      ></ng-container>
    </div>
  </div>
</ease-sheet>

<ng-template #empty let-tasks>
  <div
    class="flex h-full w-full flex-1 flex-col items-center justify-center gap-2"
  >
    <div class="my-0 text-2xl font-light text-gray-500">No Subtasks</div>
    <div
      class="mt-2 text-sm font-normal text-gray-600"
      *ngIf="!tasks.length && task.state; else addSubtask"
    >
      Re-open this task to add a subtask.
    </div>
  </div>
</ng-template>

<ng-template #addSubtask>
  <button
    mat-raised-button
    color="primary"
    *ngIf="!task.state"
    (click)="createTask()"
  >
    <mat-icon>add</mat-icon>
    <span>Add subtask</span>
  </button>
</ng-template>
