import { NgModule } from '@angular/core';

import { CustomerMessageGroupModule } from '../../../customers/customer-messages/customer-message-groups/customer-message-groups.module';
import { CustomerMessagePreviewModule } from '../../../customers/customer-messages/customer-message-preview/customer-message-preview.module';
import { SharedModule } from '../../../shared/shared.module';
import { SharedPipesModule } from '../../../shared/shared-pipes.module';
import { TaskCustomerMessageGroupsComponent } from './task-customer-message-groups.component';

@NgModule({
  imports: [
    CustomerMessageGroupModule,
    CustomerMessagePreviewModule,
    SharedModule,
    SharedPipesModule
  ],
  declarations: [TaskCustomerMessageGroupsComponent],
  exports: [TaskCustomerMessageGroupsComponent]
})
export class TaskCustomerMessageGroupsModule {}
