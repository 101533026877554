import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { UploadOutput } from 'ngx-uploader';

import { GridEntity } from '../../grid-toolbar/grid-targeting/grid-targeting.interface';
import {
  Separator,
  TargetMember,
  UserInputTarget
} from '../bulk-targeting.interface';
import { BulkTargetingService } from '../bulk-targeting.service';

@Component({
  selector: 'ease-bulk-targeting-method-upload',
  templateUrl: './bulk-targeting-method-upload.component.html',
  styleUrls: ['./bulk-targeting-method-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkTargetingMethodUploadComponent implements OnInit {
  @Input() entity: GridEntity = 'customer';
  @Output() valueChanged: EventEmitter<TargetMember[]> = new EventEmitter<
    TargetMember[]
  >();

  public rowData: UserInputTarget[] = [];
  public gridOptions: GridOptions;
  public columnDefs: ColDef[];
  public overlayLoadingTemplate: string;

  public hasInvalidEntity = false;
  public isPristine = true;
  public file: File;
  private supportedFileType: { [type: string]: Separator } = {
    'text/csv': ',',
    'text/tab-separated-values': '\t'
  };
  public invalidFileType = false;

  constructor(
    private bulkTargetingService: BulkTargetingService,
    public cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.overlayLoadingTemplate =
      this.bulkTargetingService.getOverlayLoadingTemplate(this.entity);

    this.gridOptions = {
      onRowDataUpdated: async params => {
        this.hasInvalidEntity = await this.bulkTargetingService.getEntities(
          this.entity,
          params
        );
        const members = this.bulkTargetingService.getTargetDataFromGrid(
          this.rowData
        );
        this.valueChanged.emit(members);
      },
      rowClassRules: {
        warning: params => !params.data.name
      },
      defaultColDef: {
        cellClassRules: {
          warning: params => !params.value
        }
      }
    };
  }

  async parseFile(file: File) {
    this.file = file;
    const data = await this.file.text();

    if (data) {
      this.isPristine = false;
      this.initTable(data, this.supportedFileType[this.file.type]);
      this.cdr.detectChanges();
    }
  }

  onFileDrop(event: UploadOutput) {
    if (event.type === 'addedToQueue') {
      const file = event.file.nativeFile;

      if (Object.keys(this.supportedFileType).includes(file.type)) {
        this.invalidFileType = false;
        this.parseFile(file);
      } else {
        this.rowData = [];
        this.invalidFileType = true;
      }
    }
  }

  onFileSelected(event) {
    const file = event.target.files[0];
    if (file) {
      this.parseFile(file);
    } else {
      return;
    }
  }

  initTable(data: string, separator: Separator) {
    const { headers, rowData } = this.bulkTargetingService.parse({
      data,
      entity: this.entity,
      separator
    });

    this.columnDefs = this.bulkTargetingService.getColumnDefinitions(
      this.entity,
      headers
    );
    this.rowData = rowData;
  }
}
