<ease-sheet class="popover popover-metric shadow-md">
  <div [ngClass]="responsivenessMetric?.ragStatusColor">
    <div class="header metric-background">
      <span
        class="description"
        *ngIf="responsivenessMetric?.ragStatusDescription"
      >
        {{ responsivenessMetric?.ragStatusDescription }}
      </span>

      <span
        class="description"
        *ngIf="!responsivenessMetric?.ragStatusDescription"
      >
        Not enough data to calculate responsiveness
      </span>
    </div>
  </div>
  <div class="details">
    <div class="row flex flex-row">
      <div
        class="column detail flex flex-1 flex-col items-center justify-center"
      >
        <ease-metric-simple
          label="Responsiveness"
          [value]="responsivenessMetric.responsiveness + '%'"
          [differenceNumeric]="
            responsivenessMetric.answeredCalls ||
            responsivenessMetric.totalCalls
              ? 'answered ' +
                responsivenessMetric.answeredCalls +
                ' of ' +
                responsivenessMetric.totalCalls +
                ' calls'
              : null
          "
        ></ease-metric-simple>
      </div>
    </div>
    <div
      class="row"
      *ngIf="responsivenessMetric?.metadata?.chartData.labels.length"
    >
      <ease-chart
        [height]="200"
        [data]="responsivenessMetric?.metadata?.chartData"
        [type]="'line'"
        [options]="accountDashboardService.percentageChartOptions"
      >
      </ease-chart>
    </div>
  </div>
</ease-sheet>
