import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ColorBlobComponent } from './color-blob.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ColorBlobComponent],
  exports: [ColorBlobComponent]
})
export class ColorBlobModule {}
