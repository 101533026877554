<div
  (click)="phasePopover.open()"
  [satPopoverAnchor]="phasePopover"
  class="flex cursor-pointer flex-row items-center gap-2 text-[13px] font-medium leading-6 text-gray-800"
>
  <mat-icon class="mat-icon-18 text-gray-600">widgets</mat-icon>

  <ng-container *ngIf="!!selectedPhaseId; else expandMore">
    <ease-task-phase-blob [phaseId]="selectedPhaseId"></ease-task-phase-blob>
    <mat-icon
      (click)="onPhaseChange(null); $event.stopPropagation()"
      class="mat-icon-18 text-gray-600"
    >
      close
    </mat-icon>
  </ng-container>
  <ng-template #expandMore>
    <span>Set phase</span>
    <mat-icon class="mat-icon-18 text-gray-600">expand_more</mat-icon>
  </ng-template>
</div>

<sat-popover
  verticalAlign="start"
  horizontalAlign="start"
  #phasePopover
  [hasBackdrop]="true"
>
  <ease-task-phase-popover
    *ngIf="phasePopover.isOpen()"
    (selected)="onPhaseChange($event)"
    [selectedPhase]="[selectedPhaseId]"
  >
  </ease-task-phase-popover>
</sat-popover>
