import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AccountIconModule } from '../account-icon/account-icon.module';
import { EntityIconComponent } from './entity-icon.component';

@NgModule({
  declarations: [EntityIconComponent],
  imports: [CommonModule, AccountIconModule, MatIconModule],
  exports: [EntityIconComponent]
})
export class EntityIconModule {}
