import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';
import { TaskTemplateFields } from 'src/app/admin/task-templates/task-template.interface';
import { TaskTemplatesService } from 'src/app/admin/task-templates/task-templates.service';
import { UserService } from 'src/app/users/user.service';
import { EntityMetadata } from '../../shared.interface';
import { WindowPane } from '../window-pane/window-pane';

@Injectable({ providedIn: 'root' })
export class CreateTaskService {
  private windowPane: WindowPane<EntityMetadata>;
  private nameSubscription: Subscription;

  constructor(
    private taskTemplatesService: TaskTemplatesService,
    private userService: UserService
  ) {}

  setWindowPane(windowPane: WindowPane<EntityMetadata>) {
    this.windowPane = windowPane;
  }

  startNameSubscription(field: AbstractControl) {
    this.nameSubscription = field.valueChanges
      .pipe(debounceTime(200))
      .subscribe(taskName => this.windowPane.updateTitle(taskName));
  }

  stopNameSubscription() {
    this.nameSubscription.unsubscribe();
  }

  getTemplate(templateId: string): Observable<TaskTemplateFields> {
    return this.taskTemplatesService.getFormValueForTemplate(templateId).pipe(
      take(1),
      map(template => {
        if (template.fields.assignCurrentUser) {
          const currentUserId = this.userService.currentUser.$key;

          /**
           * Assign current user if they're not already assigned
           */
          !template.fields.assigned?.some(
            user => user.userId === currentUserId
          ) &&
            template.fields.assigned.push({
              userId: currentUserId,
              method: 'direct'
            });

          /**
           * Remove the current user from the subscribed list
           */
          template.fields.subscribed = template.fields.subscribed?.filter(
            user => user.userId !== currentUserId
          );
        }

        return template.fields;
      })
    );
  }
}
