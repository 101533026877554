import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';

import { Announcement } from 'src/app/announcements/announcements.interface';
import { bugsnagClient } from 'src/app/shared/error-handler';
import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from 'src/app/shared/constants';
import {
  ANNOUNCEMENTS_COMPANY_PATH,
  ANNOUNCEMENTS_PATH,
  ANNOUNCEMENTS_USER_PATH
} from 'src/app/shared/firebase-paths';

@Injectable({ providedIn: 'root' })
export class AdminAnnouncementsService {
  private ref: firebase.database.Reference;

  constructor(
    private angularFire: FirebaseDbService,
    private matSnackbar: MatSnackBar
  ) {
    this.ref = this.angularFire.database.ref();
  }

  getAll(): Observable<Announcement[]> {
    return this.angularFire.getList(`/${ANNOUNCEMENTS_PATH}`);
  }

  getVisibilityWrites(
    announcement: Announcement,
    id: string,
    isCreating = true
  ): { [index: string]: any } {
    switch (announcement.targetLevel) {
      case 'company':
        return {
          [`/${ANNOUNCEMENTS_COMPANY_PATH}/${announcement.target[0].id}/${id}`]:
            isCreating ? true : null
        };
      case 'user':
        const users = announcement.target;
        const writes = {};
        users.forEach(user => {
          writes[`/${ANNOUNCEMENTS_USER_PATH}/${user.id}/${id}`] = isCreating
            ? true
            : null;
        });
        return writes;
    }
  }

  /**
   * - creates announcement
   * - sets created announcement as active
   * - adds announcement visibility
   *
   * @param announcement
   */
  async create(announcement: Announcement): Promise<void> {
    const newRef = this.ref.child(ANNOUNCEMENTS_PATH).push();

    const newBatch = {
      [`/${ANNOUNCEMENTS_PATH}/${newRef.key}`]: announcement,
      ...this.getVisibilityWrites(announcement, newRef.key)
    };

    try {
      await this.angularFire.database.ref().update(newBatch);
      this.showSuccess(`Announcement created`);
      return;
    } catch (error) {
      const errorMessage = 'There was a problem creating this announcement';
      const errorMetadata = {
        action: 'create announcement',
        data: { announcement }
      };
      this.handleError(errorMessage, error, errorMetadata);
      throw new Error(errorMessage);
    }
  }

  async update(id: string, metadata: Partial<Announcement>): Promise<void> {
    try {
      this.angularFire.object(`/${ANNOUNCEMENTS_PATH}/${id}`).update(metadata);
      this.showSuccess(`Announcement updated`);
      return;
    } catch (error) {
      const errorMessage = 'There was a problem updating this announcement';
      const errorMetadata = {
        action: 'update announcement',
        data: { announcementId: id, announcementMetadata: metadata }
      };
      this.handleError(errorMessage, error, errorMetadata);
      throw new Error(errorMessage);
    }
  }

  /**
   * - remove visibility
   * - deletes the announcement's metadata
   *
   * @param id
   */
  async delete(announcement: Announcement, id: string): Promise<void> {
    const deleteBatch = {
      [`/${ANNOUNCEMENTS_PATH}/${id}`]: null,
      ...this.getVisibilityWrites(announcement, id, false)
    };

    try {
      await this.ref.update(deleteBatch);
      this.showSuccess(`Announcement deleted`);
      return;
    } catch (error) {
      const errorMessage = 'There was a problem deleting this announcement';
      const errorMetadata = {
        action: 'delete announcement',
        data: { announcementId: id }
      };
      this.handleError(errorMessage, error, errorMetadata);
      throw new Error(errorMessage);
    }
  }

  handleError(
    message: string,
    error: any,
    errorMedata?: { action: string; data: { [key: string]: any } }
  ) {
    errorMedata &&
      bugsnagClient.addMetadata(errorMedata.action, {
        ...errorMedata.data
      });
    bugsnagClient.notify(error);
    this.matSnackbar.open(message, `Close`, {
      duration: SNACKBAR_DURATION_ERROR
    });
  }

  showSuccess(message: string) {
    this.matSnackbar.open(message, `Close`, {
      duration: SNACKBAR_DURATION_SUCCESS
    });
  }
}
