import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';
import { TaskChecklistTemplateService } from 'src/app/tasks/task-modules/task-checklist/task-checklist-template.service';

import { TaskModel } from '../../../tasks/task.model';
import { TaskService } from '../../../tasks/task.service';
import { ListDisplayItem, ListDisplayItemValue } from '../../shared.interface';

@Component({
  selector: 'ease-checklist-templates-popover',
  templateUrl: './checklist-templates-popover.component.html',
  styleUrls: ['./checklist-templates-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistTemplatesPopoverComponent implements OnInit {
  @Input()
  task: TaskModel;
  @Output()
  selected: EventEmitter<void> = new EventEmitter<void>();

  public checklist$: Observable<ListDisplayItem[]>;

  constructor(
    private taskService: TaskService,
    private taskChecklistTemplateService: TaskChecklistTemplateService
  ) {}

  ngOnInit(): void {
    this.checklist$ = this.taskChecklistTemplateService.getAllForSelect();
  }

  addChecklist(checklistKey: ListDisplayItemValue): void {
    this.taskService.addChecklist(this.task.$key, checklistKey as string);
    this.selected.emit();
  }
}
