import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SNACKBAR_DURATION_SUCCESS } from 'src/app/shared/constants';
import { AnnouncementsMarkedByComponent } from '../announcements-marked-by/announcements-marked-by.component';
import { Announcement } from '../announcements.interface';
import { AnnouncementsService } from '../announcements.service';

@Component({
  selector: 'ease-announcements-detail',
  templateUrl: './announcements-detail.component.html',
  styleUrls: ['./announcements-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsDetailComponent implements OnInit {
  @Input() announcementId: string;
  @ViewChild(AnnouncementsMarkedByComponent)
  markedBy: AnnouncementsMarkedByComponent;
  public announcement$: Observable<Announcement>;
  public isMarkedRead: boolean = false;
  public markedReadUsers: string[] = [];

  constructor(
    private announcementsService: AnnouncementsService,
    private dialog: MatDialog,
    private matSnackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.announcement$ = this.announcementsService
      .get(this.announcementId)
      .pipe(
        tap(announcement => {
          if (announcement.markedBy) {
            this.isMarkedRead = this.announcementsService.isMarkedRead(
              announcement.markedBy
            );

            this.markedReadUsers = Object.values(announcement.markedBy);
          }
        })
      );
  }

  close() {
    this.dialog.closeAll();
  }

  toggleMarkedBy() {
    this.markedBy?.toggle();
  }

  async markRead() {
    try {
      await this.announcementsService.markAsRead(this.announcementId);
      this.matSnackbar.open(`Marked as read`, `Close`, {
        duration: SNACKBAR_DURATION_SUCCESS
      });
      this.close();
    } catch {}
  }

  async markUnread() {
    try {
      this.announcementsService.markAsUnread(this.announcementId);
      this.matSnackbar.open(`Marked as unread`, `Close`, {
        duration: SNACKBAR_DURATION_SUCCESS
      });
      this.close();
    } catch {}
  }
}
