import { firstValueFrom, Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import compact from 'lodash-es/compact';
import uniq from 'lodash-es/uniq';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { EntityMetadata } from '../shared/shared.interface';
import {
  NEGATIVE_KEYWORDS_LISTGROUPS_PATH,
  NEGATIVE_KEYWORDS_LISTS_PATH,
  NEGATIVE_KEYWORDS_PATH
} from '../shared/firebase-paths';
import { UserService } from '../users/user.service';
import { firebaseJSON } from '../shared/utils/functions';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from '../shared/constants';
import {
  NegativeKeywordsBasicList,
  NegativeKeywordsListAccount,
  NegativeKeywordsListGroup,
  NegativeKeywordsListKeyword
} from './negative-keywords.interface';

const VALIDATION = {
  keywordCharacterLimit: 80,
  keywordWordLimit: 10,
  isInvalidKeyword: /[^\da-zA-Z #$&_"+./:\-\[\]\'àâçéèêëîïôûùüÿæœ]/
};

@Injectable({ providedIn: 'root' })
export class NegativeKeywordsService {
  public keywordLists;
  public invalidKeywords;
  // Google Ads API has a 5000 keyword limit when adding a keyword to a shared set.
  public maxNumberOfKeywords: number = 5000;

  constructor(
    private angularFire: FirebaseDbService,
    private snackBar: MatSnackBar,
    private router: Router,
    private userService: UserService
  ) {
    this.keywordLists = this.angularFire.getList(
      `/${NEGATIVE_KEYWORDS_PATH}/${NEGATIVE_KEYWORDS_LISTS_PATH}`
    );
  }

  async createList(name: string, groupId: string): Promise<void> {
    const groupKey = groupId || null;

    const item = await this.angularFire
      .list(`/${NEGATIVE_KEYWORDS_PATH}/${NEGATIVE_KEYWORDS_LISTS_PATH}`)
      .push({
        name,
        groupKey
      });

    this.router.navigate(['/negative-keywords/', item.key]);
  }

  async createListGroup(name: string): Promise<void> {
    await this.angularFire
      .list(`/${NEGATIVE_KEYWORDS_PATH}/${NEGATIVE_KEYWORDS_LISTGROUPS_PATH}`)
      .push({
        name
      });

    this.snackBar.open(
      `New negative keyword list group created: ${name}`,
      'Close',
      {
        duration: SNACKBAR_DURATION_SUCCESS
      }
    );
  }

  updateList(
    listId: string,
    list: Partial<NegativeKeywordsBasicList>
  ): Promise<void> {
    return this.angularFire
      .object(
        `/${NEGATIVE_KEYWORDS_PATH}/${NEGATIVE_KEYWORDS_LISTS_PATH}/${listId}`
      )
      .update(firebaseJSON(list));
  }

  updateListGroupName(groupId: string, name: string): Promise<void> {
    return this.angularFire
      .object(
        `/${NEGATIVE_KEYWORDS_PATH}/${NEGATIVE_KEYWORDS_LISTGROUPS_PATH}/${groupId}`
      )
      .update({ name });
  }

  async removeList(listId: string): Promise<void> {
    await this.angularFire
      .object(
        `/${NEGATIVE_KEYWORDS_PATH}/${NEGATIVE_KEYWORDS_LISTS_PATH}/${listId}`
      )
      .remove();

    this.snackBar.open(`Negative keyword list deleted`, 'Close', {
      duration: SNACKBAR_DURATION_SUCCESS
    });
  }

  async removeListGroup(group: NegativeKeywordsListGroup): Promise<void> {
    await Promise.all([
      // clearing `groupKey` instead of deleting all child lists as well
      group.lists.map(list =>
        this.angularFire
          .object(
            `/${NEGATIVE_KEYWORDS_PATH}/${NEGATIVE_KEYWORDS_LISTS_PATH}/${list.$key}/groupKey`
          )
          .remove()
      ),
      this.angularFire
        .object(
          `/${NEGATIVE_KEYWORDS_PATH}/${NEGATIVE_KEYWORDS_LISTGROUPS_PATH}/${group.$key}`
        )
        .remove()
    ]);

    this.snackBar.open('Negative keyword list group deleted', 'Close', {
      duration: SNACKBAR_DURATION_SUCCESS
    });
  }

  getAccounts(listId: string): Observable<NegativeKeywordsListAccount[]> {
    return this.angularFire.getList(
      `/${NEGATIVE_KEYWORDS_PATH}/accounts/${listId}`
    );
  }

  getKeywords(listId: string): Observable<NegativeKeywordsListKeyword[]> {
    return this.angularFire.getList(
      `/${NEGATIVE_KEYWORDS_PATH}/keywords/${listId}`
    );
  }

  get(listId: string, listPath: string): Observable<NegativeKeywordsBasicList> {
    return this.angularFire.getObject(
      `/${NEGATIVE_KEYWORDS_PATH}/${listPath}/${listId}`
    );
  }

  getAll(listPath: string): Observable<NegativeKeywordsBasicList[]> {
    return this.angularFire
      .getList(`/${NEGATIVE_KEYWORDS_PATH}/${listPath}`, ref =>
        ref.orderByChild('name')
      )
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }

  async addAccount(
    listId: string,
    entityMeta: EntityMetadata
  ): Promise<void | any> {
    if (entityMeta.accountType !== 'adwords') {
      return this.snackBar.open(
        'Account not added, only Google Ads accounts are currently supported',
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );
    }

    if (entityMeta.entityId) {
      const createdAt = await this.angularFire.getServerTimestamp();

      return this.angularFire
        .object(
          `/${NEGATIVE_KEYWORDS_PATH}/accounts/${listId}/${entityMeta.entityId}`
        )
        .update({
          accountName: entityMeta.entityName,
          adwordsId: entityMeta.entityId,
          createdAt,
          dirty: true
        });
    }
  }

  removeAccount(listId: string, accountId: string): Promise<void> {
    return this.angularFire
      .object(`/${NEGATIVE_KEYWORDS_PATH}/accounts/${listId}/${accountId}`)
      .remove();
  }

  parseNewKeywords(newKeywords: string): string[] {
    let keywords = newKeywords.split('\n');
    keywords = compact(keywords);
    keywords = keywords.map(keyword => keyword.trim());
    keywords = uniq(keywords);

    return keywords;
  }

  async addKeywords(
    listId: string,
    newKeywords: string
  ): Promise<any[] | void> {
    if (!newKeywords) {
      return [];
    }

    const createdAt = await this.angularFire.getServerTimestamp();
    const keywords = this.parseNewKeywords(newKeywords);

    return firstValueFrom(
      this.getKeywords(listId).pipe(
        take(1),
        map(currentKeywords => {
          // check if current keyword list + new keywords is more than max; if true, display message and don't add the new keywords
          const totalWithNewKeywords: number =
            currentKeywords.length + keywords.length;

          if (totalWithNewKeywords > this.maxNumberOfKeywords) {
            this.snackBar.open(
              `The keywords you are trying to add would put this list above the limit of ${
                this.maxNumberOfKeywords
              } words. Please remove ${
                totalWithNewKeywords - this.maxNumberOfKeywords
              } word(s) to complete this request.`,
              'Close',
              {
                duration: SNACKBAR_DURATION_ERROR
              }
            );
            return null;
          }

          return keywords.filter(newKeyword => {
            let shouldBeAdded = true;
            for (const keywordWrapper of currentKeywords) {
              if (keywordWrapper['keyword'] === newKeyword) {
                shouldBeAdded = false;
                break;
              }
            }
            return shouldBeAdded;
          });
        }),
        filter(
          filteredKeywords => filteredKeywords && !!filteredKeywords.length
        ),
        map(filteredKeywords => {
          const invalids = [];
          const updates = {};

          filteredKeywords.forEach(keyword => {
            /**
             * Check for invalid characters, keywords over 80 characters, and keywords over 10 words.
             * Push them to an invalids array for use later.
             */
            if (
              keyword.search(VALIDATION.isInvalidKeyword) > -1 ||
              keyword.length > VALIDATION.keywordCharacterLimit ||
              keyword.split(' ').length > VALIDATION.keywordWordLimit
            ) {
              invalids.push(keyword);
            } else {
              if (keyword && keyword.length) {
                const newKeywordId = this.angularFire
                  .list(`/${NEGATIVE_KEYWORDS_PATH}/keywords/${listId}`)
                  .push(null).key;

                updates[newKeywordId] = {
                  keyword,
                  isSingle: filteredKeywords.length === 1 ? true : null,
                  createdAt,
                  createdBy: this.userService.currentUser.$key,
                  createdByName: this.userService.currentUser.name
                };
              }
            }
          });

          this.angularFire
            .object(`/${NEGATIVE_KEYWORDS_PATH}/keywords/${listId}`)
            .update(updates);

          // Display success message when keywords are added
          this.snackBar.open(
            `Successfully added ${filteredKeywords.length} new keyword(s).`,
            'Close',
            {
              duration: SNACKBAR_DURATION_SUCCESS
            }
          );

          /**
           * Display an alert with the rejected keywords if there are any
           */
          if (invalids.length) {
            const invalidKeywordList = invalids.join(', ');
            this.snackBar.open(
              `Invalid keywords added: ${invalidKeywordList}`,
              'Close'
            );
          }
        })
      )
    );
  }

  removeKeyword(listId: string, keywordId: string): Promise<void> {
    return this.angularFire
      .object(`/${NEGATIVE_KEYWORDS_PATH}/keywords/${listId}/${keywordId}`)
      .remove();
  }
}
