import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  ViewEncapsulation,
  HostBinding
} from '@angular/core';
import { RichTooltipOptions } from './rich-tooltip.interface';

@Component({
  selector: 'ease-rich-tooltip',
  templateUrl: './rich-tooltip.component.html',
  styleUrls: ['./rich-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTooltipComponent implements OnInit {
  public defaultAnchorClass: string = 'relative inline-flex';
  @HostBinding('class') get hostClasses() {
    if (this.options.anchorClass) {
      this.defaultAnchorClass += ` ${this.options.anchorClass}`;
    }
    return this.defaultAnchorClass;
  }
  @Input() trigger: TemplateRef<any>;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() contentText: string;
  @Input() options: RichTooltipOptions;

  constructor() {}

  ngOnInit(): void {}
}
