import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TaskModelState } from 'src/app/tasks/task.model';
import { TypedFormGroup } from 'src/app/shared/reactive-forms';
import {
  TaskChecklistData,
  TaskChecklistTemplate,
  TaskChecklistTemplateField
} from '../task-checklist.interface';
import { CHECKLIST_COLLAPSED_STATUS_PATH } from '../../../../shared/firebase-paths';
import { UserService } from '../../../../users/user.service';
import { ChecklistActionButtonEvent } from '../task-checklist-actions.service';

@UntilDestroy()
@Component({
  selector: 'ease-checklist-fields-renderer',
  templateUrl: './checklist-fields-renderer.component.html',
  styleUrls: ['./checklist-fields-renderer.component.scss']
})
export class ChecklistFieldsRendererComponent implements OnInit {
  @Input() fields: TaskChecklistTemplateField[];
  @Input() template: TaskChecklistTemplate;
  @Input() form: TypedFormGroup<TaskChecklistData>;
  @Input() taskId: string;
  @Input() taskState: TaskModelState;
  @Input() previewMode: boolean;
  @Output()
  actionTriggered: EventEmitter<ChecklistActionButtonEvent> = new EventEmitter<ChecklistActionButtonEvent>();

  public collapseStatus = {};
  constructor(
    private angularFire: AngularFireDatabase,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.angularFire
      .object(
        `/${CHECKLIST_COLLAPSED_STATUS_PATH}/${this.taskId}/${this.userService.currentUser.$key}/${this.template.$key}`
      )
      .valueChanges()
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(status =>
        status ? (this.collapseStatus = status) : (this.collapseStatus = {})
      );
  }

  getKey(
    index: number,
    currentValue: TaskChecklistTemplateField
  ): string | number {
    return currentValue ? currentValue.key : index;
  }

  handleActionButtonClick(
    actionKey: string,
    formControl: AbstractControl
  ): void {
    /**
     * setTimeout is important here so that the event that is emitted has the value
     * of the formControl AFTER change detection has run
     */
    setTimeout(() => this.actionTriggered.emit({ actionKey, formControl }));
  }

  async toggleCollapseStatus(field: TaskChecklistTemplateField): Promise<void> {
    const statusToSave = !this.collapseStatus[field.$dbKey];
    if (this.taskId && !!!this.taskState) {
      await this.angularFire
        .object(
          `/${CHECKLIST_COLLAPSED_STATUS_PATH}/${this.taskId}/${this.userService.currentUser.$key}/${this.template.$key}/${field.$dbKey}`
        )
        .set(statusToSave);
      this.cdr.detectChanges();
    }
  }
}
