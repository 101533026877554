<ng-container *ngIf="onbPackage$ | async as package">
  <ng-container *ngIf="package.id; else noPackage">
    <div class="pack-header flex flex-row items-center justify-center">
      <div class="flex-1">
        <div class="flex flex-row items-center justify-start">
          <h2 class="package-type-name my-0 mr-5">
            {{ package.onboardingName }}
          </h2>
          <div class="status-badge" [ngClass]="package.status">
            {{ package.status | titlecase }}
          </div>
        </div>
        <div class="metadata flex flex-row">
          <div class="item" *ngIf="plans">
            <ease-color-blob
              size="md"
              [color]="plans[package.planId]?.color"
            ></ease-color-blob>
            {{ plans[package.planId]?.name }} Plan
          </div>
          <div class="item">
            <mat-icon class="mat-icon-14 mr-1">monetization_on</mat-icon>
            <strong>{{ package.fee | currency }}</strong>
          </div>
          <div class="item">
            <mat-icon class="mat-icon-14 mr-1">payments</mat-icon>
            {{ package.feeNotes }}
          </div>

          <div class="item">
            <mat-icon class="mat-icon-14 mr-1">event</mat-icon>
            {{ package.createdAt | amDateFormat: 'LLL' }}
          </div>
        </div>
      </div>
      <div class="actions">
        <button
          mat-icon-button
          matTooltip="Copy Onboarding Link"
          (click)="copyLink()"
        >
          <mat-icon>link</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Edit Onboarding Package"
          (click)="editOnboarding()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>

    <div class="forms-results">
      <h4>Form Submissions</h4>
      <div class="form-results-container">
        <mat-accordion [displayMode]="'default'" [multi]="true">
          <mat-expansion-panel
            *ngFor="let stage of package.stages"
            #expansionPanel
            (expandedChange)="markAsLoaded(stage.id)"
            [disabled]="stage.status !== 'completed'"
          >
            <mat-expansion-panel-header>
              <div
                class="stage-header flex flex-row items-center justify-start"
              >
                <div class="icon-status mr-5">
                  <mat-icon *ngIf="stage.status === 'completed'"
                    >check_circle</mat-icon
                  >
                </div>
                <div class="meta flex-1">
                  <div
                    class="form-name flex flex-row items-center justify-start"
                  >
                    <strong>{{ stage.form?.name || 'Form Deleted' }} </strong>
                    <span
                      *ngIf="stage?.form?.description"
                      class="text-sm text-gray-400"
                      >| {{ stage.form.description }}</span
                    >
                  </div>
                  <div class="form-status">
                    {{ stage.status | titlecase }}
                    {{ stage.updatedAt | amDateFormat: 'LLL' }}
                  </div>
                </div>
                <div class="action">
                  <button
                    (click)="openForm(stage)"
                    target="_blank"
                    mat-icon-button
                  >
                    <mat-icon>open_in_new</mat-icon>
                  </button>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div
              class="inside"
              *ngIf="expansionPanel.expanded || stageLoadedHash[stage.id]"
            >
              <ease-customer-onboarding-form-renderer
                [stageId]="stage.id"
              ></ease-customer-onboarding-form-renderer>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <div class="onboarding-notes">
      <h4>Onboarding Notes</h4>
      <div [easeMarkdown]="package.extraNotes"></div>
    </div>
  </ng-container>
</ng-container>

<ng-template #noPackage>
  <div class="not-found">
    Onboarding Package not found (Package ID: {{ packageId }})
    <span>Please contact tools team if you think this is an error.</span>
  </div>
</ng-template>
