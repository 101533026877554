import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs';

import { TypedFormGroup } from 'src/app/shared/reactive-forms';

@UntilDestroy()
@Component({
  selector: 'ease-email-shared-variables',
  templateUrl: './email-shared-variables.component.html',
  styleUrls: ['./email-shared-variables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailSharedVariablesComponent implements OnInit {
  @Input() hiddenVariableFields: string[] = [
    'recipientRegion',
    'relationshipManagerEmailSuffix',
    'senderEmailSuffix',
    'senderRegion'
  ];
  @Input() sharedEmailVariablesControl: TypedFormGroup<{
    [variableName: string]: string;
  }>;
  @Input() useSharedValuesControl: FormControl<boolean> = new FormControl(
    false
  );
  @Output() copySharedEmailVariablesToAllRecipients: EventEmitter<void> =
    new EventEmitter<void>();
  private requiredControls: Record<string, boolean> = {};

  constructor() {}

  ngOnInit() {
    /**
     * Enable required validators only when these fields are used for both forms
     */
    this.useSharedValuesControl.valueChanges
      .pipe(untilDestroyed(this), startWith(this.useSharedValuesControl.value))
      .subscribe(value => {
        const form = this.sharedEmailVariablesControl;
        if (!value) {
          Object.keys(form.controls).forEach(control => {
            if (form.controls[control].hasValidator(Validators.required)) {
              this.requiredControls[control] = true;
              form.controls[control].removeValidators(Validators.required);
              form.controls[control].updateValueAndValidity();
            }
          });
        } else {
          Object.keys(this.requiredControls).forEach(requiredControl => {
            form.controls[requiredControl].addValidators(Validators.required);
            form.controls[requiredControl].updateValueAndValidity();
          });
        }
      });
  }

  onCopySharedEmailVariablesToAllRecipients() {
    this.copySharedEmailVariablesToAllRecipients.emit();
  }
}
