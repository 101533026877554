import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { TypedFormGroup } from 'src/app/shared/reactive-forms';
import {
  TaskChecklistActionButton,
  TaskChecklistTemplateFieldOptions
} from '../task-checklist.interface';

@Component({
  selector: 'ease-task-checklist-actionbutton',
  templateUrl: './task-checklist-actionbutton.component.html',
  styleUrls: ['./task-checklist-actionbutton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskChecklistActionbuttonComponent implements OnInit {
  @Input()
  templateOptions: TaskChecklistTemplateFieldOptions;
  @Input()
  group: TypedFormGroup<TaskChecklistActionButton>;
  /**
   * `value` input exists solely to support OnPush change
   * detection being triggered when the field value updates
   */
  @Input()
  value: any;
  @Output()
  actionClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  buttonClicked(): void {
    this.actionClicked.emit();
  }
}
