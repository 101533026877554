import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';

import { SharedFormModule } from '../../shared/shared-form.module';
import { SharedModule } from '../../shared/shared.module';
import { AdwordsManagerLinksDialogComponent } from './adwords-manager-links-dialog.component';

@NgModule({
  imports: [AgGridModule, SharedModule, SharedFormModule],
  declarations: [AdwordsManagerLinksDialogComponent],
  exports: [AdwordsManagerLinksDialogComponent]
})
export class AdwordsManagerLinksDialogModule {}
