import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { CustomerAccountsService } from 'src/app/customers/customer-accounts/customer-accounts.service';
import { CustomersService } from 'src/app/customers/customers.service';
import { UserService } from 'src/app/users/user.service';
import { EntityUtilsService } from '../entity-utils.service';
import { EntityMetadata, EntityType } from '../shared.interface';
import { CustomData, VariableData } from './markdown.interface';

@Injectable({ providedIn: 'root' })
export class MarkdownVariableService {
  private VARIABLE_BY_ENTITY: Record<string, string[]> = {
    customer: ['customerId', 'customerName', 'relationshipManager'],
    account: [
      'accountId',
      'accountName',
      'accountType',
      'currency',
      'accountManager',
      'accountReviewer',
      'timezone'
    ]
  };

  constructor(
    private customerAccountsService: CustomerAccountsService,
    private customersService: CustomersService,
    private entityUtilsService: EntityUtilsService,
    private userService: UserService
  ) {}

  getVariables(entity: EntityType): string[] {
    switch (entity) {
      case 'prospect':
      case 'customer':
        return this.VARIABLE_BY_ENTITY.customer;
      case 'account':
        return [
          ...this.VARIABLE_BY_ENTITY.customer,
          ...this.VARIABLE_BY_ENTITY.account
        ];
    }
  }

  async getCustomerRoles(customerId: string): Promise<Record<string, string>> {
    const { roles: customerRoles } = await firstValueFrom(
      this.customersService.get(customerId)
    );
    const { name: relationshipManager } = await firstValueFrom(
      this.userService.get(customerRoles.relationshipManager)
    );
    return { relationshipManager };
  }

  async getAccountRoles(
    accountId: string,
    accountType: string
  ): Promise<Record<string, string>> {
    const accountMeta = await firstValueFrom(
      this.customerAccountsService.getAccount({ accountId, accountType })
    );
    const accountManager = (
      await firstValueFrom(
        this.userService.get(accountMeta['roles']['manager'])
      )
    ).name;
    const accountReviewer = (
      await firstValueFrom(
        this.userService.get(accountMeta['roles']['reviewer'])
      )
    ).name;
    return { accountManager, accountReviewer };
  }

  /**
   * Gets the value for each variable from the entity and custom data provided.
   *
   * @param variables
   * @param entity
   * @param customData a record of { variableName: variableValue }
   * @returns an array of a variable's name and value
   */
  async getVariableValues(
    variables: string[],
    entity: Pick<EntityMetadata, 'entityId' | 'entityType' | 'accountType'>,
    customData: CustomData = {}
  ): Promise<VariableData[]> {
    // get entity meta data
    let meta = await this.entityUtilsService.getEntityField(entity, '');

    // if the entity is an account, get and merge the customer meta
    if (entity.entityType === 'account') {
      const customerMeta = await this.entityUtilsService.getEntityField(
        {
          entityId: meta.customerId,
          entityType: 'customer'
        },
        ''
      );
      meta = { ...customerMeta, customerName: customerMeta.name, ...meta };
    }

    const entityType =
      entity.entityType === 'prospect' ? 'customer' : entity.entityType;
    let role: Record<string, string> = {};

    // only get the role metadata when requested to improve performance
    return await Promise.all([
      ...variables.map(async variable => {
        const variableName = variable.replace('{', '').replace('}', '');

        switch (variableName) {
          case `${entityType}Id`:
            return { variable, value: entity.entityId };
          case `${entityType}Name`:
            return { variable, value: meta.name };
          case 'relationshipManager':
            if (!role[variableName]) {
              role = {
                ...role,
                ...(await this.getCustomerRoles(
                  meta.customerId || entity.entityId
                ))
              };
            }
            return { variable, value: role[variableName] };
          case 'accountManager':
          case 'accountReviewer':
            if (!role[variableName]) {
              role = {
                ...role,
                ...(await this.getAccountRoles(
                  entity.entityId,
                  entity.accountType
                ))
              };
            }
            return { variable, value: role[variableName] };
          default:
            return {
              variable,
              value: meta[variableName] || customData[variableName] || variable
            };
        }
      })
    ]);
  }

  /**
   * Processes a string and replaces any variables with their respective values.
   * If a value can't be found, this falls back to the {variableName}.
   *
   * @param body the string contains variables
   * @param entity
   * @param customData a record of { variableName: variableValue }
   * @returns the body with all the variables replaced by it's value
   */
  async populateVariables(
    body: string,
    entity: Pick<EntityMetadata, 'entityId' | 'entityType' | 'accountType'>,
    customData?: CustomData
  ): Promise<string> {
    // exit early when there are no default & custom variables defined
    if ((!entity.entityId || !entity.entityType) && !customData.length) {
      return body;
    }

    // get all inserted variables from the body
    const variableTest = /\{\w*\}/gm;
    const variables = (body || '').match(variableTest);

    if (variables) {
      // build a hashmap for each variable and their value
      const values = await this.getVariableValues(
        variables,
        entity,
        customData
      );

      // replace all variables with their values
      values.forEach(value => {
        body = body.replace(value.variable, value.value);
      });
    }

    return body;
  }
}
