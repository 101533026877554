import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Announcement } from 'src/app/announcements/announcements.interface';
import { AnnouncementsService } from 'src/app/announcements/announcements.service';

@Component({
  selector: 'ease-announcements-popover',
  templateUrl: './announcements-popover.component.html',
  styleUrls: ['./announcements-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsPopoverComponent implements OnInit {
  public allActive$: Observable<Announcement[]>;
  public allCompanyLevel$: Observable<Announcement[]>;
  public allUserLevel$: Observable<Announcement[]>;
  @Output()
  closed: EventEmitter<void> = new EventEmitter<void>();

  constructor(private announcementsService: AnnouncementsService) {}

  ngOnInit(): void {
    this.allActive$ = this.announcementsService.getActive();
    this.allCompanyLevel$ = this.allActive$.pipe(
      map(announcements =>
        announcements.filter(
          announcement => announcement.targetLevel === 'company'
        )
      )
    );
    this.allUserLevel$ = this.allActive$.pipe(
      map(announcements =>
        announcements.filter(
          announcement => announcement.targetLevel === 'user'
        )
      )
    );
  }

  close() {
    this.closed.emit();
  }
}
