import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { firstValueFrom, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  TypedFormControl,
  TypedFormGroup
} from 'src/app/shared/reactive-forms';
import { environment } from '../../../../environments/environment';
import { ConfirmService } from '../../../shared/confirm/confirm.service';
import {
  ListDisplayItem,
  ListDisplayItemValue
} from '../../../shared/shared.interface';
import { transformCurrencyString } from '../../../shared/utils/functions';
import { SNACKBAR_DURATION_SUCCESS } from '../../../shared/constants';
import { UserService } from '../../../users/user.service';
import { CustomerPlanChange } from '../../customer-plan-selector/customer-plan-selector.component';
import { Customer } from '../../customer.interface';
import { CustomersService } from '../../customers.service';
import {
  OnboardingCreatePayload,
  OnboardingForm,
  OnboardingPackage,
  OnboardingPackageSuccess,
  OnboardingType
} from '../customer-onboarding.interface';
import { CustomerOnboardingService } from '../customer-onboarding.service';

export interface OnboardingOperation {
  action: 'new' | 'edit';
  data: {
    customerId: string;
    package?: OnboardingPackage;
  };
}

@UntilDestroy()
@Component({
  selector: 'ease-customer-onboarding-create-edit-dialog',
  templateUrl: './customer-onboarding-create-edit-dialog.component.html',
  styleUrls: ['./customer-onboarding-create-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerOnboardingCreateEditDialogComponent implements OnInit {
  get generalInfoFormGroup(): typeof this.onboardingForm.controls.generalInfo {
    return this.onboardingForm.controls.generalInfo;
  }

  get extraNotesControl(): FormControl<string> {
    return this.onboardingForm.controls.extraNotes;
  }

  @Input() operation: OnboardingOperation;

  public customer$: Observable<Customer>;
  public forms$: Observable<ListDisplayItem[]>;
  public onboardingTypes$: Observable<OnboardingType[]>;
  public packageSuccess: OnboardingPackageSuccess;
  public isLoading: boolean = false;
  private formsHash: { [formId: number]: OnboardingForm } = {};

  public onboardingForm: TypedFormGroup<{
    packageId: string;
    generalInfo: {
      customer: string;
      planId: string;
      fee: string;
      feeNotes: string;
      onboardingName: string;
    };
    stages: (OnboardingForm & {
      formId: number;
    })[];
    extraNotes: string;
  }>;
  public formSearchControl: FormControl<string> = new FormControl(null);

  constructor(
    private customerOnboardingService: CustomerOnboardingService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private matDialogRef: MatDialogRef<CustomerOnboardingCreateEditDialogComponent>,
    private customerService: CustomersService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {
    this.customer$ = this.customerService.get(this.operation.data.customerId);

    this.onboardingForm = this.formBuilder.group<
      typeof this.onboardingForm.controls
    >({
      packageId: this.formBuilder.control(null),
      generalInfo: this.formBuilder.group({
        customer: this.formBuilder.control(this.operation.data.customerId, {
          validators: Validators.required
        }),
        planId: this.formBuilder.control(null, {
          validators: Validators.required
        }),
        fee: this.formBuilder.control(null, {
          validators: Validators.required
        }),
        feeNotes: this.formBuilder.control(null, {
          validators: Validators.required
        }),
        onboardingName: this.formBuilder.control(null, {
          validators: Validators.required
        })
      }),
      stages: this.formBuilder.array<
        typeof this.onboardingForm.controls.stages.controls[0]
      >([], { validators: Validators.required }),
      extraNotes: this.formBuilder.control(null)
    });

    switch (this.operation.action) {
      case 'new':
        this.generalInfoFormGroup.controls.customer.patchValue(
          this.operation.data.customerId
        );
        break;

      case 'edit':
        this.injectPackage(this.operation.data.package);

        break;

      default:
        break;
    }

    this.forms$ = this.customerOnboardingService.getForms().pipe(
      map(forms =>
        forms.map(form => {
          this.formsHash[form.id] = form;

          return {
            value: form.id,
            viewValue: `${form.name} | ${form.description}`
          };
        })
      ),
      shareReplay(1)
    );

    this.onboardingTypes$ = this.customerOnboardingService
      .getOnboardingTypes()
      .pipe(shareReplay(1));
  }

  injectPackage(onboardingPackage: OnboardingPackage): void {
    this.onboardingForm.patchValue({
      packageId: onboardingPackage.id,
      generalInfo: {
        planId: onboardingPackage.planId,
        fee: onboardingPackage.fee,
        feeNotes: onboardingPackage.feeNotes,
        onboardingName: onboardingPackage.onboardingName
      },
      extraNotes: onboardingPackage.extraNotes
    });

    const stagesArray = this.onboardingForm.controls.stages;

    onboardingPackage.stages.forEach(stage => {
      stagesArray.push(
        this.formBuilder.group<
          TypedFormControl<OnboardingForm & { formId: number }>
        >({
          id: this.formBuilder.control(stage.id),
          formId: this.formBuilder.control(stage.form?.id || null),
          name: this.formBuilder.control(stage.form?.name || 'Form Deleted'),
          url: this.formBuilder.control(stage.form?.url || null),
          description: this.formBuilder.control(stage.form?.description || null)
        })
      );
    });
  }

  formSelected(formId: ListDisplayItemValue): void {
    this.formSearchControl.reset();
    const formSelected = this.formsHash[formId];
    const stagesArray = this.onboardingForm.controls.stages;

    stagesArray.push(
      this.formBuilder.group<
        TypedFormControl<OnboardingForm & { formId: number }>
      >({
        formId: this.formBuilder.control(formSelected.id),
        name: this.formBuilder.control(formSelected.name),
        description: this.formBuilder.control(formSelected.description),
        url: this.formBuilder.control(formSelected.url)
      })
    );

    this.cdr.detectChanges();
  }

  onStageSort(
    event: CdkDragDrop<typeof this.onboardingForm.controls.stages.controls>
  ): void {
    const stages = this.onboardingForm.controls.stages;
    const currentGroup = stages.at(event.previousIndex);
    stages.removeAt(event.previousIndex);
    stages.insert(event.currentIndex, currentGroup);
    this.cdr.detectChanges();
  }

  getIndex(index: number, el: any): number {
    return index;
  }

  deleteStage(index: number): void {
    const stages = this.onboardingForm.controls.stages;
    stages.removeAt(index);
    this.cdr.detectChanges();
  }

  async typeSelected(event: MatSelectChange): Promise<void> {
    const types = await firstValueFrom(
      this.onboardingTypes$.pipe(
        map(onbTypes =>
          onbTypes.reduce((acc, onbType) => {
            acc[onbType.id] = onbType;
            return acc;
          }, {})
        )
      )
    );

    const type = types[event.value];
    const injectdata = {
      generalInfo: {
        planId: type.planId,
        fee: type.fee,
        feeNotes: type.feeNotes,
        onboardingName: type.name
      },
      extraNotes: type.extraNotes
    };

    this.onboardingForm.patchValue(injectdata);
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  setCustomerPlan(planChange: CustomerPlanChange) {
    this.onboardingForm.controls.generalInfo.controls.planId.patchValue(
      planChange.newPlan
    );
  }

  get termsFormAdded(): boolean {
    const stagesArray = this.onboardingForm.controls.stages;

    for (const stage of stagesArray.controls) {
      const name = stage.controls.name.value;

      if (name.includes('Terms and Conditions')) {
        return true;
      }
    }

    return false;
  }

  async submit() {
    this.isLoading = true;

    if (this.onboardingForm.valid) {
      if (!this.termsFormAdded) {
        const confirmResult = await this.confirmService.confirm(
          {
            title: 'No terms and conditions form added',
            message: `No terms and conditions form added. Are you sure you want to continue?`,
            cancelText: 'Take me back',
            confirmText: 'Continue'
          },
          {
            disableClose: true
          }
        );

        if (!confirmResult.confirm) {
          this.isLoading = false;
          this.cdr.detectChanges();
          return;
        }
      }

      const onboardingPackage = this.generateOnboardingPayload(
        this.onboardingForm
      );

      if (this.operation.action === 'new') {
        firstValueFrom(
          this.customerOnboardingService.createPackage(onboardingPackage)
        )
          .then(success => {
            success.customerUrl = `${environment.ONBOARDING_HUB}/${success.packageId}`;
            this.packageSuccess = success;

            this.copyLink(success.packageId);
            this.isLoading = false;
            this.cdr.detectChanges();
          })
          .catch(err => (this.isLoading = false));
      } else {
        // Remove createdBy to avoid getting overwrited when updating a package.
        delete onboardingPackage.createdBy;
        firstValueFrom(
          this.customerOnboardingService.updatePackage(
            this.operation.data.package.id,
            onboardingPackage
          )
        )
          .then(success => {
            this.isLoading = false;
            this.matDialogRef.close();
            this.cdr.detectChanges();
          })
          .catch(err => (this.isLoading = false));
      }
    }
  }

  generateOnboardingPayload(
    onboardingForm: typeof this.onboardingForm
  ): OnboardingCreatePayload {
    const stages = onboardingForm.controls.stages.controls.map(
      (stageGroup, index) => ({
        order: index + 1,
        form: stageGroup.controls.formId.value,
        ...(stageGroup.controls.id ? { id: stageGroup.controls.id.value } : {})
      })
    );

    return {
      customer: onboardingForm.controls.generalInfo.controls.customer.value,
      createdBy: this.userService.currentUser.$key,
      fee: transformCurrencyString(
        onboardingForm.controls.generalInfo.controls.fee.value
      ),
      feeNotes: onboardingForm.controls.generalInfo.controls.feeNotes.value,
      planId: onboardingForm.controls.generalInfo.controls.planId.value,
      stages,
      onboardingName:
        onboardingForm.controls.generalInfo.controls.onboardingName.value,
      extraNotes: onboardingForm.controls.extraNotes.value
    };
  }

  copyLink(packageId: string) {
    this.clipboard.copy(`${environment.ONBOARDING_HUB}/${packageId}`);

    this.snackBar.open('Link copied to your clipboard', 'Close', {
      duration: SNACKBAR_DURATION_SUCCESS
    });
  }
}
