<ng-container *ngIf="controls">
  <div
    *ngFor="let variableControl of controls.controls | keyvalue"
    [ngClass]="{ hidden: isHidden(variableControl.key) }"
    class="mb-1"
  >
    <mat-form-field>
      <input
        matInput
        [placeholder]="variableControl.key | getVariableName | decamelize"
        [formControl]="variableControl.value"
      />
      <mat-error easeFormErrors [control]="variableControl.value"></mat-error>
    </mat-form-field>
  </div>
</ng-container>
