import { NgModule } from '@angular/core';

import { NotificationsPopoverModule } from '../shared/popover/notifications/notifications-popover.module';
import { SharedModule } from '../shared/shared.module';
import { NotificationsComponent } from './notifications/notifications.component';

@NgModule({
  imports: [NotificationsPopoverModule, SharedModule],
  declarations: [NotificationsComponent],
  exports: [NotificationsComponent]
})
export class NotificationsModule {}
