import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, map } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import * as moment from 'moment';

import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import { UserService } from '../../../users/user.service';
import { RoleDialogComponent } from '../../../shared/role-selector/role-dialog/role-dialog.component';
import { CustomerAccountsService } from '../../../customers/customer-accounts/customer-accounts.service';
import { TaskService } from '../../task.service';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from '../../../shared/constants';
import { CustomersService } from '../../../customers/customers.service';
import { WindowService } from '../../../shared/window/window.service';
import { RetireUserDialogComponent } from '../../../users/retire-user-dialog/retire-user-dialog.component';
import { UserModel } from '../../../users/user.model';
import { TypeformEmbedDialogComponent } from '../../../shared/typeform-embed/typeform-embed-dialog/typeform-embed-dialog.component';
import { SearchService } from '../../../shared/search/search.service';
import { TypesenseCustomerFeedFieldList } from '../../../shared/search/search.interface';
import { AccountMetadataService } from '../../../shared/account-metadata.service';
import { SearchUtils } from '../../../shared/search/search-utils';
import { TaskChecklistTemplateFieldType } from './task-checklist.interface';

export interface ChecklistActionButtonEvent {
  actionKey: string;
  formControl: AbstractControl;
}

type ChecklistActionHandler = (
  action: ChecklistActionButtonEvent,
  taskId: string,
  checklistKey: string
) => Promise<any>;

interface ChecklistActionMap {
  [actionKey: string]: {
    fieldTypes: TaskChecklistTemplateFieldType[];
    label: string;
    handler: ChecklistActionHandler;
  };
}

@Injectable({ providedIn: 'root' })
export class TaskChecklistActionsService {
  public checklistActions: ChecklistActionMap = {
    configureDomain: {
      fieldTypes: ['actionButton'],
      label: 'Configure Domain',
      handler: async actionEvent => {
        await this.configureDomain(actionEvent);
      }
    },
    accountBuilder: {
      fieldTypes: ['actionButton'],
      label: 'Account Builder',
      handler: async (actionEvent, taskId, checklistKey) => {
        await this.openAccountBuilder(taskId);
      }
    },
    retireUser: {
      fieldTypes: ['actionButton', 'checklistCheckbox'],
      label: 'Retire User',
      handler: async (actionEvent, taskId, checklistKey) => {
        await this.retireUser(actionEvent);
      }
    },
    assignUserRole: {
      fieldTypes: ['actionButton'],
      label: 'Assign Roles',
      handler: async (actionEvent, taskId, checklistKey) => {
        await this.assignUserRole(actionEvent, taskId, checklistKey);
      }
    },
    customerStatusOnline: {
      fieldTypes: [
        'checklistCheckbox',
        'checkboxInput',
        'checkboxRadio',
        'checkboxList',
        'toggle'
      ],
      label: 'Set Customer Status to Online',
      handler: async (actionEvent, taskId) => {
        await this.setCustomerStatus(actionEvent, taskId, 'ONLINE');
      }
    },
    customerStatusPaused: {
      fieldTypes: [
        'checklistCheckbox',
        'checkboxInput',
        'checkboxRadio',
        'checkboxList',
        'toggle'
      ],
      label: 'Set Customer Status to Paused',
      handler: async (actionEvent, taskId) => {
        await this.setCustomerStatus(actionEvent, taskId, 'PAUSED');
      }
    },
    customerStatusOffline: {
      fieldTypes: [
        'checklistCheckbox',
        'checkboxInput',
        'checkboxRadio',
        'checkboxList',
        'toggle'
      ],
      label: 'Set Customer Status to Offline',
      handler: async (actionEvent, taskId) => {
        await this.setCustomerStatus(actionEvent, taskId, 'OFFLINE');
      }
    },
    customerStatusOnboarding: {
      fieldTypes: [
        'checklistCheckbox',
        'checkboxInput',
        'checkboxRadio',
        'checkboxList',
        'toggle'
      ],
      label: 'Set Customer Status to Onboarding',
      handler: async (actionEvent, taskId) => {
        await this.setCustomerStatus(actionEvent, taskId, 'ONBOARDING');
      }
    },
    accountStatusOnline: {
      fieldTypes: [
        'checklistCheckbox',
        'checkboxInput',
        'checkboxRadio',
        'checkboxList',
        'toggle'
      ],
      label: 'Set Account Status to Online',
      handler: async (actionEvent, taskId) => {
        await this.setAccountStatus(actionEvent, taskId, 'ONLINE');
      }
    },
    accountStatusPaused: {
      fieldTypes: [
        'checklistCheckbox',
        'checkboxInput',
        'checkboxRadio',
        'checkboxList',
        'toggle'
      ],
      label: 'Set Account Status to Paused',
      handler: async (actionEvent, taskId) => {
        await this.setAccountStatus(actionEvent, taskId, 'PAUSED');
      }
    },
    accountStatusOffline: {
      fieldTypes: [
        'checklistCheckbox',
        'checkboxInput',
        'checkboxRadio',
        'checkboxList',
        'toggle'
      ],
      label: 'Set Account Status to Offline',
      handler: async (actionEvent, taskId) => {
        await this.setAccountStatus(actionEvent, taskId, 'OFFLINE');
      }
    },
    accountStatusOnboarding: {
      fieldTypes: [
        'checklistCheckbox',
        'checkboxInput',
        'checkboxRadio',
        'checkboxList',
        'toggle'
      ],
      label: 'Set Account Status to Onboarding',
      handler: async (actionEvent, taskId) => {
        await this.setAccountStatus(actionEvent, taskId, 'ONBOARDING');
      }
    },
    openBoomerangForm: {
      fieldTypes: ['actionButton'],
      label: 'Go To Boomerang Form',
      handler: async (actionEvent, taskId) => {
        await this.openBoomerangForm(actionEvent, taskId);
      }
    }
  };

  constructor(
    private accountMetadataService: AccountMetadataService,
    private customerAccountsService: CustomerAccountsService,
    private userService: UserService,
    private afFunctions: AngularFireFunctions,
    private entityUtilsService: EntityUtilsService,
    private matDialog: MatDialog,
    private taskService: TaskService,
    private customerService: CustomersService,
    private matSnackBar: MatSnackBar,
    private windowService: WindowService,
    private searchService: SearchService
  ) {}

  async checklistActionTrigger(
    actionEvent: ChecklistActionButtonEvent,
    taskId: string,
    checklistKey: string
  ) {
    if (this.checklistActions[actionEvent.actionKey]) {
      await this.checklistActions[actionEvent.actionKey].handler(
        actionEvent,
        taskId,
        checklistKey
      );
    }
  }

  async assignUserRole(
    actionEvent: ChecklistActionButtonEvent,
    taskId: string,
    checklistKey: string
  ) {
    const taskEntityMeta = await this.taskService.getEntityMeta(taskId);

    this.matDialog.open(RoleDialogComponent, {
      width: '520px',
      data: taskEntityMeta
    });
  }

  async configureDomain(
    actionEvent: ChecklistActionButtonEvent
  ): Promise<void> {
    actionEvent.formControl.patchValue({
      pending: true,
      message: null
    });

    if (actionEvent.formControl.root.get('domainPurchased').value.value) {
      try {
        await firstValueFrom(
          this.afFunctions.httpsCallable('nameServerConfig-configureDomain')({
            domain:
              actionEvent.formControl.root.get('domainPurchased').value.value
          })
        );

        actionEvent.formControl.patchValue({
          completedBy: this.userService.currentUser.name,
          completedAt: new Date().getTime(),
          success: true,
          pending: false,
          error: false,
          disabled: true,
          message: 'Domain configured'
        });
      } catch (error) {
        actionEvent.formControl.patchValue({
          pending: false,
          error: true,
          message: error.message
        });
      }
    } else {
      actionEvent.formControl.patchValue({
        pending: false,
        error: true,
        message: 'Populate the domain field above'
      });
    }
  }

  async setAccountStatus(
    actionEvent: ChecklistActionButtonEvent,
    taskId: string,
    newStatus: string
  ): Promise<void> {
    const taskEntityMeta = await this.taskService.getEntityMeta(taskId);

    if (taskEntityMeta.entityType === 'customer') {
      this.matSnackBar.open(
        'No action taken since task is applied to customer',
        'Close',
        {
          duration: SNACKBAR_DURATION_SUCCESS
        }
      );

      return actionEvent.formControl.patchValue({
        checked: false,
        completedAt: null,
        completedBy: null
      });
    }

    const accountMeta = {
      accountId: taskEntityMeta.entityId,
      accountType: taskEntityMeta.accountType
    };
    const customerId = await this.entityUtilsService.getCustomerIdForAccount(
      accountMeta
    );
    const oldStatus = await firstValueFrom(
      this.customerAccountsService.getAccountStatus(accountMeta)
    );

    if (oldStatus !== newStatus) {
      const didStatusChange =
        await this.customerAccountsService.setAccountStatus(
          customerId,
          accountMeta,
          {
            oldStatus,
            newStatus
          }
        );

      if (didStatusChange) {
        this.matSnackBar.open(`Account set to ${newStatus}`, 'Close', {
          duration: SNACKBAR_DURATION_SUCCESS
        });
      } else {
        return actionEvent.formControl.patchValue({
          checked: false,
          completedAt: null,
          completedBy: null
        });
      }
    } else {
      this.matSnackBar.open(`Account already ${newStatus}`, 'Close', {
        duration: SNACKBAR_DURATION_SUCCESS
      });
    }

    actionEvent.formControl.patchValue({ disabled: true });
  }

  async setCustomerStatus(
    actionEvent: ChecklistActionButtonEvent,
    taskId: string,
    newStatus: string
  ): Promise<void> {
    const taskEntityMeta = await this.taskService.getEntityMeta(taskId);
    let customerId = taskEntityMeta.entityId;

    if (taskEntityMeta.entityType === 'account') {
      customerId = await this.entityUtilsService.getCustomerIdForAccount({
        accountId: taskEntityMeta.entityId,
        accountType: taskEntityMeta.accountType
      });
    }

    const oldStatus = await firstValueFrom(
      this.customerService.getStatus(customerId)
    );

    if (oldStatus !== newStatus) {
      const didStatusChange = await this.customerService.setStatus(customerId, {
        oldStatus,
        newStatus
      });

      if (didStatusChange) {
        this.matSnackBar.open(`Customer set to ${newStatus}`, 'Close', {
          duration: SNACKBAR_DURATION_SUCCESS
        });
      } else {
        return actionEvent.formControl.patchValue({
          checked: false,
          completedAt: null,
          completedBy: null
        });
      }
    } else {
      this.matSnackBar.open(`Customer already ${newStatus}`, 'Close', {
        duration: SNACKBAR_DURATION_ERROR
      });
    }

    actionEvent.formControl.patchValue({ disabled: true });
  }

  async retireUser(actionEvent: ChecklistActionButtonEvent) {
    const retiredUser: UserModel = await firstValueFrom(
      this.matDialog
        .open(RetireUserDialogComponent, {
          maxHeight: '80vh'
        })
        .afterClosed()
    );
    if (retiredUser) {
      actionEvent.formControl.patchValue({
        completedBy: this.userService.currentUser.name,
        completedAt: new Date().getTime(),
        success: true,
        pending: false,
        error: false,
        disabled: true,
        message: `${retiredUser.name} successfully retired`
      });
    }
  }

  async openAccountBuilder(taskId: string) {
    const taskEntityMeta = await this.taskService.getEntityMeta(taskId);
    if (taskEntityMeta.entityType === 'customer') {
      this.matSnackBar.open(
        'This task should be applied to an account',
        'Close',
        {
          duration: SNACKBAR_DURATION_SUCCESS
        }
      );
    } else {
      this.windowService.create({
        data: {
          accountId: taskEntityMeta.entityId,
          accountName: taskEntityMeta.entityName
        },
        subtitle: taskEntityMeta.entityName,
        type: 'accountBuilder'
      });
    }
  }

  async openBoomerangForm(
    actionEvent: ChecklistActionButtonEvent,
    taskId: string
  ) {
    /**
     * Retreieve the customer based on whether the task is applied
     * to an account or customer
     */
    const taskEntityMeta = await this.taskService.getEntityMeta(taskId);
    let customerId = taskEntityMeta.entityId;

    if (taskEntityMeta.entityType === 'account') {
      customerId = await this.entityUtilsService.getCustomerIdForAccount({
        accountId: taskEntityMeta.entityId,
        accountType: taskEntityMeta.accountType
      });
    }

    const customer = await firstValueFrom(this.customerService.get(customerId));

    /**
     * Use the customer feed to retrieve the first time a customer
     * was switch to ONLINE status
     */
    const firstOnlineResponse =
      await this.searchService.create<TypesenseCustomerFeedFieldList>(
        'customerFeeds',
        {
          q: '*',
          limit_hits: 1,
          page: 1,
          per_page: 1,
          sort_by: SearchUtils.equalTo('createdAt', 'asc'),
          filter_by: `${SearchUtils.exactEqualTo(
            'entityId',
            customerId
          )} && ${SearchUtils.exactEqualTo(
            'itemType',
            'statusChange'
          )} && ${SearchUtils.exactEqualTo('newStatus', 'ONLINE')}`
        }
      );

    const [firstOnlineHit] = firstOnlineResponse.hits;
    const firstonline = firstOnlineHit?.document?.createdAt
      ? moment(firstOnlineHit?.document?.createdAt).format('YYYY-MM-DD')
      : 'Unknown';
    const lastcommunication = customer.lastCommunicationAt
      ? moment(customer.lastCommunicationAt).format('YYYY-MM-DD')
      : 'Unknown';
    const relationshipmanager = await firstValueFrom(
      this.userService.get(customer.roles.relationshipManager)
    );

    const verticalIds = Object.keys(customer.verticalIds || {});
    const verticals = await firstValueFrom(
      this.accountMetadataService.verticalsAsObject$.pipe(
        map(allVerticals =>
          verticalIds
            .map(verticalId => allVerticals[verticalId].name)
            .join(', ')
        )
      )
    );

    this.openTypeform(
      'XoFWYqhA',
      {
        customername: customer.name,
        customerid: customerId,
        task: `https://ease.searchkings.ca/tasks/${taskId}`,
        verticals,
        relationshipmanager: relationshipmanager?.name || 'Unknown',
        firstonline,
        lastcommunication,
        completedby: this.userService.currentUser?.name || 'Unknown'
      },
      actionEvent
    );
  }

  /**
   * Open a Typeform using the Typeform Embed SDK, and then handle
   * form submission to mark the triggering form control as complete
   *
   * @param formId The Typeform ID to open e.g. XoFWYqhA
   * @param hiddenData A key-value (string, string) map of hidden fields to pass to Typeform
   * @param actionEvent The event that triggered the modal to open, containing the control itself
   */
  private async openTypeform(
    formId: string,
    hiddenData: Record<string, string>,
    actionEvent: ChecklistActionButtonEvent
  ) {
    const reEnableForm = (event: Event) => {
      event.preventDefault();
      actionEvent.formControl.patchValue({
        pending: false,
        message: ''
      });
    };

    /**
     * Listen for the window being closed so we can reset the
     * form value before it does. This avoids the action button
     * being stuck in a "pending" state indefinitely
     */
    window.addEventListener('beforeunload', reEnableForm);

    actionEvent.formControl.patchValue({
      pending: true,
      message: `Form being filled by ${this.userService.currentUser.name}`
    });

    const ref = this.matDialog.open(TypeformEmbedDialogComponent, {
      width: '80vw',
      height: '80vh'
    });

    ref.componentInstance.formId = formId;
    ref.componentInstance.hidden = hiddenData || {};
    const responseId: string = await firstValueFrom(ref.afterClosed());

    if (responseId) {
      actionEvent.formControl.patchValue({
        completedBy: this.userService.currentUser.name,
        completedAt: new Date().getTime(),
        success: true,
        pending: false,
        error: false,
        disabled: true,
        message: `Form successfully completed`
      });
    } else {
      actionEvent.formControl.patchValue({
        pending: false,
        message: ''
      });
    }

    // We can now safely remove this listener as the form has been patched
    window.removeEventListener('beforeunload', reEnableForm);
  }
}
