import { Injectable } from '@angular/core';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import groupBy from 'lodash-es/groupBy';
import isEqual from 'lodash-es/isEqual';
import { MatSnackBar } from '@angular/material/snack-bar';

import { UserService } from '../../users/user.service';
import { FirebaseDbService } from '../../shared/firebase-db.service';
import {
  PERMISSIONS_PATH,
  PERMISSION_GROUPS_PATH,
  PERMISSION_ROLES_PATH,
  USERS_PATH
} from '../../shared/firebase-paths';
import { Permission, PermissionGroup, Role } from './permissions.interface';

export interface DisplayPermission {
  name: string;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdminPermissionsService {
  public permissionRoles$: Observable<Role[]>;
  public permissionRolesObject$: Observable<{
    [key: string]: Role;
  }>;
  public permissionGroups$: Observable<PermissionGroup[]>;
  public permissions$: Observable<Permission[]>;
  public roleNames$: Observable<{ [key: string]: string }>;
  public userPermissions$: Observable<{ [permissionId: string]: boolean }>;
  private userPermissions: { [permissionId: string]: boolean };

  constructor(
    private angularFire: FirebaseDbService,
    private userService: UserService,
    private matSnackBar: MatSnackBar
  ) {
    this.permissionRoles$ = this.angularFire
      .getList(`/${PERMISSION_ROLES_PATH}/`)
      .pipe(shareReplay(1));
    this.permissionRolesObject$ = this.angularFire
      .getObject(`/${PERMISSION_ROLES_PATH}/`)
      .pipe(shareReplay(1));
    this.permissionGroups$ = this.angularFire
      .getList(`/${PERMISSION_GROUPS_PATH}/`)
      .pipe(shareReplay(1));
    this.permissions$ = this.angularFire
      .getList(`/${PERMISSIONS_PATH}/`)
      .pipe(shareReplay(1));
    this.roleNames$ = this.permissionRoles$.pipe(
      map(roles =>
        roles.reduce(
          (acc, role) => ({
            ...acc,
            [role.$key]: role.name
          }),
          {}
        )
      ),
      shareReplay(1)
    );

    this.userPermissions$ = this.userService.currentUser$.pipe(
      map(user => user?.roles),
      distinctUntilChanged(isEqual),
      switchMap(currentUserRoles => {
        if (currentUserRoles) {
          // Get all roles assigned to an user
          const assignedRolesArray = Object.keys(currentUserRoles || {}).map(
            roleId =>
              this.angularFire.getObject(`/${PERMISSION_ROLES_PATH}/${roleId}`)
          );
          // Listen only to roles changes assigned to an user
          return combineLatest(assignedRolesArray);
        } else {
          return of([]);
        }
      }),
      map(roles =>
        roles.reduce((acc, role) => {
          if (role.permissions) {
            Object.keys(role.permissions).forEach(permissionId => {
              acc[permissionId] = true;
            });
          }
          return acc;
        }, {})
      ),
      tap(userPermissions => (this.userPermissions = userPermissions)),
      shareReplay(1)
    );

    this.userPermissions$
      .pipe(
        startWith(null),
        pairwise(),
        filter(([before, after]) => before && !isEqual(before, after))
      )
      .subscribe(() => {
        const snackbar = this.matSnackBar.open(
          'Your user permissions have changed. Please reload your browser.',
          'Reload'
        );

        firstValueFrom(snackbar.afterDismissed()).then(info => {
          if (info.dismissedByAction === true) {
            window.location.reload();
          }
        });
      });
  }

  getPermissionsByGroup(): Observable<PermissionGroup[]> {
    return combineLatest([this.permissionGroups$, this.permissions$]).pipe(
      map(([groupPermissions, permissions]) => {
        const permissionsByGroup = groupBy(permissions, 'group');
        const groupPermissionsCopy = [...groupPermissions];
        return groupPermissionsCopy.map(groupPermission => ({
          ...groupPermission,
          $key: groupPermission.$key,
          permissions: permissionsByGroup[groupPermission.$key] || []
        }));
      })
    );
  }

  createGroup(displayPermission: Partial<DisplayPermission>): Promise<void> {
    return this.angularFire
      .object(`/${PERMISSION_GROUPS_PATH}/${displayPermission.id}/name`)
      .set(displayPermission.name);
  }

  removeGroup(groupId: string): Promise<void> {
    return firstValueFrom(
      this.angularFire
        .getList(PERMISSIONS_PATH, ref =>
          ref.orderByChild('group').equalTo(groupId)
        )
        .pipe(
          take(1),
          map(permissions => {
            Promise.all([
              ...permissions.map(permission =>
                this.removePermission(permission.$key)
              ),
              this.angularFire
                .object(`/${PERMISSION_GROUPS_PATH}/${groupId}`)
                .remove()
            ]);
          })
        )
    );
  }

  createPermission(
    displayPermission: Partial<DisplayPermission>,
    groupId: string
  ): Promise<void> {
    return this.angularFire
      .object(`/${PERMISSIONS_PATH}/${displayPermission.id}`)
      .set({
        name: displayPermission.name,
        group: groupId
      });
  }

  removePermission(permissionId: string): Promise<void> {
    return firstValueFrom(
      this.angularFire.getList(PERMISSION_ROLES_PATH).pipe(
        take(1),
        map(roles => {
          Promise.all([
            roles.map(role =>
              this.angularFire
                .object(
                  `/${PERMISSION_ROLES_PATH}/${role.$key}/permissions/${permissionId}`
                )
                .remove()
            ),
            this.angularFire
              .object(`/${PERMISSIONS_PATH}/${permissionId}`)
              .remove()
          ]);
        })
      )
    );
  }

  createRole(role: Role): Promise<any> {
    return Promise.all([
      this.angularFire.object(`/${PERMISSION_ROLES_PATH}/${role.$key}`).set({
        name: role.name,
        permissions: role.permissions.reduce((acc, permissionId) => {
          acc[permissionId] = true;
          return acc;
        }, {})
      })
    ]);
  }

  deleteRole(roleId: string): Promise<any> {
    return firstValueFrom(
      this.angularFire
        .getList(USERS_PATH, ref =>
          ref.orderByChild(`roles/${roleId}`).equalTo(true)
        )
        .pipe(
          take(1),
          map(users => {
            const userRoleRemoveArrayPromises = users.map(user =>
              this.angularFire
                .object(`/${USERS_PATH}/${user.$key}/roles/${roleId}`)
                .remove()
            );

            return Promise.all([
              ...userRoleRemoveArrayPromises,
              this.angularFire
                .object(`/${PERMISSION_ROLES_PATH}/${roleId}`)
                .remove()
            ]);
          })
        )
    );
  }

  updateRole(role: Role): Promise<any> {
    return this.createRole(role);
  }

  hasPermission(permissionId): boolean {
    return this.userPermissions && this.userPermissions[permissionId];
  }

  hasPermission$(permissionId): Observable<boolean> {
    return this.userPermissions$.pipe(
      take(1),
      map(userPermissions => userPermissions[permissionId])
    );
  }
}
