<ng-container
  *ngIf="
    appService.isReady$ | async;
    then authorizedContent;
    else unAuthorizedContent
  "
></ng-container>

<ng-template #unAuthorizedContent>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #authorizedContent>
  <mat-sidenav-container
    autosize
    *ngIf="sharedLayoutService.isCompact$ | async as isCompact"
    class="!absolute !top-0 !right-0 !bottom-0 !left-0"
  >
    <mat-sidenav
      #sideNav
      fixedInViewport
      class="!border-r-blue-gray-700"
      [disableClose]="!isCompact.active"
      [opened]="isCompact.active ? false : true"
      [mode]="isCompact.active ? 'over' : 'side'"
    >
      <ease-app-nav
        #appNav
        [isMobile]="isCompact.active"
        (navigated)="isCompact.active && sideNav.close()"
      ></ease-app-nav>
    </mat-sidenav>

    <mat-sidenav-content>
      <div
        class="flex h-full w-full flex-col overflow-y-auto overflow-x-hidden"
      >
        <ease-announcements-bar></ease-announcements-bar>
        <div
          class="flex flex-1 flex-col overflow-hidden lg:flex-row"
          easeViewportOffsetReceiver="height"
          [restMobileOffset]="false"
        >
          <ease-main class="flex min-h-0 flex-1 flex-col">
            <mat-toolbar
              easeViewportOffsetPusher
              color="accent"
              class="relative z-50 !bg-red-600 !shadow-md"
            >
              <mat-toolbar-row>
                <button
                  *ngIf="isCompact.active"
                  mat-icon-button
                  [ngClass]="{ '!hidden': finder.panelOpen }"
                  class="!-ml-1.5"
                  (click)="sideNav.toggle()"
                >
                  <mat-icon>menu_open</mat-icon>
                </button>

                <ease-app-finder #finder class="flex-1"
                  >Search for a client</ease-app-finder
                >
                <div
                  [ngClass]="{ 'hidden lg:flex': finder.panelOpen }"
                  class="flex flex-row"
                >
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="helpMenu"
                    matTooltip="Help & Feedback"
                  >
                    <mat-icon matPrefix>help</mat-icon>
                  </button>
                  <mat-menu #helpMenu="matMenu">
                    <a target="_blank" href="https://searchkings.canny.io/">
                      <button mat-menu-item>
                        <mat-icon>map</mat-icon>
                        <span>Roadmap & Feedback</span>
                      </button>
                    </a>
                    <a
                      target="_blank"
                      href="https://drive.google.com/drive/folders/1SGvhdkjSvCJi3ZyyD6Z0UGWBc-9xSMxb"
                    >
                      <button mat-menu-item>
                        <mat-icon>description</mat-icon>
                        <span>Help Documentation</span>
                      </button>
                    </a>
                  </mat-menu>
                  <ease-announcements-panel></ease-announcements-panel>
                  <ease-notifications #notifications></ease-notifications>
                </div>
              </mat-toolbar-row>
            </mat-toolbar>
            <div
              class="relative z-1 flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
            >
              <router-outlet></router-outlet>
            </div>
            <router-outlet name="windows"></router-outlet>
            <ease-window-outlet></ease-window-outlet>
          </ease-main>
        </div>
        <ease-window-manager></ease-window-manager>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>
