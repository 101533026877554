import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TypedFormArray, TypedFormGroup } from 'src/app/shared/reactive-forms';
import {
  EmailMessage,
  EmailSendData,
  EmailSendResponse,
  EmailTemplateDetails
} from '../../emails/email.interface';
import { EmailTemplateService } from '../../emails/email-template.service';
import { ListDisplayItem, ListDisplayItemValue } from '../../shared.interface';

@Component({
  selector: 'ease-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss']
})
export class EmailSendComponent implements OnInit {
  @HostBinding('class') class = 'flex flex-col flex-1';

  get emailsFormArray(): TypedFormArray<EmailMessage> {
    return this.sendFrom.controls.emails;
  }

  @Input()
  data: EmailSendData = {};
  @Input() layoutStyle: 'horizontal' | 'vertical' = 'horizontal';
  @Output()
  send: EventEmitter<EmailSendResponse> = new EventEmitter<EmailSendResponse>();
  @Output()
  templateChanged: EventEmitter<EmailTemplateDetails> = new EventEmitter<EmailTemplateDetails>();

  public isWorking: boolean = true;
  public sendFrom: FormGroup<{
    emails: TypedFormArray<EmailMessage>;
  }> = this.formBuilder.group({
    emails: this.formBuilder.array<TypedFormGroup<EmailMessage>>([])
  });
  public emailTemplates$: Observable<ListDisplayItem[]>;
  private templateIdSubject = new ReplaySubject<string>(null);
  public templateId$ = this.templateIdSubject.asObservable();
  public templateDetails$: Subject<EmailTemplateDetails> =
    new Subject<EmailTemplateDetails>();
  public templateSelectorControl: FormControl<string> = new FormControl();

  constructor(
    private emailTemplateService: EmailTemplateService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.emailTemplates$ = this.emailTemplateService.getAllForDisplay().pipe(
      tap(() => {
        this.isWorking = false;
      }),
      catchError(err => {
        this.isWorking = false;
        throw err;
      })
    );

    if (this.data.notificationType) {
      const templateId =
        this.emailTemplateService.templateForNotificationType[
          this.data.notificationType
        ];
      if (templateId) {
        this.templateSelectorControl.setValue(templateId);
        this.templateIdSubject.next(templateId);
      }
    }
  }

  emailSent(emailResponse: EmailSendResponse): void {
    this.send.emit(emailResponse);
  }

  templateSelected(templateId: ListDisplayItemValue): void {
    this.templateIdSubject.next(templateId as string);
    this.isWorking = true;
  }

  onTemplateChanged(details: EmailTemplateDetails): void {
    this.templateChanged.emit(details);
    this.templateDetails$.next(details);
    this.isWorking = false;
  }
}
