<div [formGroup]="group" class="flex flex-row flex-wrap items-center gap-2">
  <button
    mat-raised-button
    class="!ml-6"
    [ngClass]="{ '!pl-2.5': templateOptions?.actionKey }"
    [disabled]="group?.value.pending || group?.value.disabled"
    (click)="buttonClicked()"
    [color]="templateOptions.color"
    matTooltip="Clicking this button will perform the action described automatically"
    [matTooltipDisabled]="!templateOptions?.actionKey && !group?.value.pending"
  >
    <mat-icon
      *ngIf="templateOptions?.actionKey && !group?.value.pending"
      class="mr-1"
      >offline_bolt</mat-icon
    >
    <mat-progress-spinner
      class="mr-2"
      *ngIf="group?.value.pending"
      [diameter]="20"
      mode="indeterminate"
    ></mat-progress-spinner>
    {{ templateOptions?.label }}
  </button>
  <div
    class="status !ml-6 md:ml-0"
    *ngIf="!(group?.value).error && !(group?.value).success"
  >
    {{ (group?.value).message }}
  </div>
  <mat-error
    class="!ml-6 md:ml-0"
    *ngIf="(group?.value).error && !(group?.value).success"
    >{{ (group?.value).message }}</mat-error
  >
  <div
    class="status success !ml-6 md:ml-0"
    *ngIf="(group?.value).success && !(group?.value).error"
  >
    {{ (group?.value).message }}
  </div>
  <div class="checklist-meta ml-auto">
    <div
      class="ml-6 flex items-center"
      [ngClass]="{ '!ml-[29px] md:ml-0': templateOptions?.actionKey }"
      *ngIf="group?.controls?.completedBy?.value"
    >
      <div>{{ group.controls.completedBy.value }}</div>
    </div>
    <div
      *ngIf="group?.controls?.completedAt?.value"
      class="flex items-center text-[11px] leading-snug text-gray-600"
    >
      <mat-icon class="mat-icon-12 mr-0.5 mb-px !hidden md:!block"
        >done</mat-icon
      >
      {{ group.controls.completedAt.value | amDateFormat: 'MMM D, h:mm a' }}
    </div>
  </div>
</div>
