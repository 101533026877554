import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getVariableName'
})
export class GetVariableNamePipe implements PipeTransform {
  transform(input: string) {
    return input.split('_')[0];
  }
}
