<mat-accordion multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <ng-container [ngSwitch]="targetLevel">
          <ng-container *ngSwitchCase="'company'">
            <img class="icon mat-icon-20" src="/assets/images/crown.png" />
            <span class="label">
              SearchKings {{ target[0].id | capitalize }}
            </span>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <mat-icon class="icon mat-icon-24">portrait</mat-icon>
            <span class="label">{{ targetLevel }}</span>
            <span class="total"> ({{ target?.length }}) </span>
          </ng-container>
        </ng-container>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="members">
      <ng-container [ngSwitch]="targetLevel">
        <div *ngSwitchCase="'company'" class="note">
          Everyone in SearchKings {{ target[0].id | capitalize }} will see this
          announcement
        </div>
        <div *ngSwitchCase="'user'" class="users">
          <ease-avatar
            *ngFor="let user of target"
            [userId]="user.id"
            [showTooltip]="true"
            [showInitials]="true"
            [size]="'medium'"
          ></ease-avatar>
        </div>
      </ng-container>
    </div>
  </mat-expansion-panel>
</mat-accordion>
