<input
  type="file"
  class="hidden"
  (change)="onFileSelected($event)"
  accept=".csv, .tsv"
  #fileUpload
/>

<div
  class="relative mt-5 flex min-h-[120px] flex-1 flex-col items-center justify-center gap-3 rounded border border-dashed border-gray-400 p-6 text-gray-600"
  ngFileDrop
  (uploadOutput)="onFileDrop($event)"
  [satPopoverAnchor]="help"
>
  <ng-container *ngIf="!file; else fileDetails">
    <ng-container
      *ngIf="invalidFileType"
      [ngTemplateOutlet]="invalid"
    ></ng-container>
    <div class="flex flex-row">
      <mat-icon class="mat-icon-20 mr-2">cloud_upload</mat-icon>
      Drag and drop a CSV / TSV file here or
      <span
        class="ml-1 cursor-pointer text-blue-500"
        (click)="fileUpload.click()"
      >
        browse
      </span>
    </div>
  </ng-container>
  <button
    (click)="help.open()"
    class="!absolute top-1 right-1 !text-[13px]"
    mat-button
    color="primary"
    type="button"
  >
    <mat-icon class="mat-icon-18 mr-1">help_outline</mat-icon>
    Required Format
  </button>
</div>

<ag-grid-angular
  #agGrid
  [gridOptions]="gridOptions"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [overlayLoadingTemplate]="overlayLoadingTemplate"
  class="ag-theme-material mt-3 h-64"
  [ngClass]="{ hidden: isPristine || !rowData.length }"
>
</ag-grid-angular>

<mat-error
  *ngIf="!isPristine && (hasInvalidEntity || !rowData?.length)"
  class="mt-5"
>
  *<span *ngIf="hasInvalidEntity">
    All invalid {{ entity }}s will be ignored and skipped. </span
  ><span *ngIf="!rowData.length"> No {{ entity }}s detected. </span>
  Check your column headers and see if it follows the
  <span (click)="help.open()" class="cursor-pointer font-semibold text-blue-500"
    >required format</span
  >.
</mat-error>

<ng-template #fileDetails>
  <div
    *ngIf="!invalidFileType; else invalid"
    class="flex flex-row text-gray-900"
  >
    <mat-icon class="mat-icon-20 mr-2">attach_file</mat-icon>
    {{ file.name }}
  </div>
  <div class="text-sm italic">
    Drop a CSV / TSV file or
    <span class="cursor-pointer text-blue-500" (click)="fileUpload.click()">
      browse
    </span>
    to update
  </div>
</ng-template>

<ng-template #invalid>
  <div class="flex flex-row text-red-500">
    <mat-icon class="mat-icon-20 mr-2"> block </mat-icon>
    Invalid file type
  </div>
</ng-template>

<sat-popover
  #help
  verticalAlign="below"
  horizontalAlign="center"
  [autoFocus]="false"
  [hasBackdrop]="true"
  [restoreFocus]="false"
  (opened)="cdr.detectChanges()"
  (closed)="cdr.detectChanges()"
  panelClass="bulk-help"
>
  <ease-bulk-targeting-help
    [entity]="entity"
    method="upload"
    [separator]="file?.type === 'text/tab-separated-values' ? 'tab' : 'comma'"
  ></ease-bulk-targeting-help>
</sat-popover>
