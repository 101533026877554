/**
 * Helper class to translate key/value(s) into
 * Typesense-compatible filter strings
 */
export class SearchUtils {
  static equalTo(
    key: string | number | symbol,
    val: string | number | boolean
  ): string {
    return `${String(key)}:${val}`;
  }
  static exactEqualTo(
    key: string | number | symbol,
    val: string | number | boolean
  ): string {
    return `${String(key)}:=${val}`;
  }
  static notEqualTo(
    key: string | number | symbol,
    val: string | number | boolean
  ): string {
    return `${String(key)}:!=${val}`;
  }
  static in(
    key: string | number | symbol,
    values: string[] | number[]
  ): string {
    return `${String(key)}:=[${values.join(',')}]`;
  }
  static notIn(
    key: string | number | symbol,
    values: string[] | number[]
  ): string {
    return `${String(key)}:!=[${values.join(',')}]`;
  }
  static lessThan(
    key: string | number | symbol,
    val: string | number | boolean
  ): string {
    return `${String(key)}:<${val}`;
  }
  static lessThanEqualTo(key: string | number | symbol, val: number): string {
    return `${String(key)}:<=${val}`;
  }
  static greaterThan(
    key: string | number | symbol,
    val: string | number | boolean
  ): string {
    return `${String(key)}:>${val}`;
  }
  static greaterThanEqualTo(
    key: string | number | symbol,
    val: number
  ): string {
    return `${String(key)}:>=${val}`;
  }
  static inRange(
    key: string | number | symbol,
    start: number,
    end: number
  ): string {
    return `${String(key)}:[${start}..${end}]`;
  }
  static joinAnd(queries: string[]): string {
    return queries.join(' && ');
  }
}
