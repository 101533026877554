import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { WindowPaneViewTaskService } from 'src/app/shared/window/window-pane-view-task/window-pane-view-task.service';
import { TaskOffsetValue } from 'src/app/shared/window/window-pane-view-task/window-pane-view-task.interface';
import { TaskModel } from '../task.model';
import { TaskService } from '../task.service';
import { UserModel } from '../../users/user.model';
import { ScrollspyService } from '../../shared/scrollspy/scrollspy.service';

@Component({
  selector: 'ease-task-body',
  templateUrl: './task-body.component.html',
  styleUrls: ['./task-body.component.scss']
})
export class TaskBodyComponent implements AfterViewInit, OnInit {
  @HostBinding('class') hostClass = 'bg-white';
  @Input() task: TaskModel;
  @Input()
  customerId: string;

  @Input()
  users: {
    [userId: string]: UserModel;
  };
  @Input() isSticky: boolean;
  @Input() compactHeaderHeight: number;
  @ViewChild('checklist', { static: true })
  taskChecklist: ElementRef;
  @ViewChild('description', { static: true })
  taskDescription: ElementRef;
  @ViewChild('tabNav') tabNav: ElementRef;
  public activeSection$: Observable<string>;
  private DEFAULT_SECTION = 'description';
  public offset$: Observable<TaskOffsetValue>;

  constructor(
    private taskService: TaskService,
    private windowPaneViewTaskService: WindowPaneViewTaskService,
    public scrollSpyService: ScrollspyService
  ) {}

  ngOnInit(): void {
    this.activeSection$ = this.scrollSpyService.sectionInView$.pipe(
      startWith(this.DEFAULT_SECTION),
      map(sectionInView => sectionInView || this.DEFAULT_SECTION)
    );

    this.offset$ = this.windowPaneViewTaskService
      .getOffsetValue()
      .pipe(map(offset => ({ value: -Math.abs(offset.value) })));
  }

  ngAfterViewInit(): void {
    this.windowPaneViewTaskService.setOffset({
      nav: this.tabNav?.nativeElement.offsetHeight || 0
    });
  }

  saveTask(taskData: Partial<TaskModel>): void {
    if (!this.task.state) {
      this.taskService.update(this.task.$key, taskData);
    }
  }
}
