import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { CUSTOMERS_PATH } from '../shared/firebase-paths';

@Injectable({
  providedIn: 'root'
})
export class CustomerRolesService {
  constructor(private angularFire: FirebaseDbService) {}

  getRoles(customerId: string): Observable<any[]> {
    return this.angularFire.getList(`/${CUSTOMERS_PATH}/${customerId}/roles`);
  }

  getRolesAsObject(customerId: string) {
    return this.angularFire
      .getObject(`/${CUSTOMERS_PATH}/${customerId}/roles`)
      .pipe(map(roles => (roles.$exists() ? roles : {})));
  }
}
