import {
  Observable,
  Observer,
  Subscription,
  timer as observableTimer
} from 'rxjs';

import { switchMap } from 'rxjs/operators';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormArray,
  ValidatorFn
} from '@angular/forms';
import { extractRootDomain } from './utils/domain-helpers';

// eslint-disable-line max-len
export const PROTOCOL_REGEXP = /^http(s)?:\/\//;
export const EMAIL_REGEXP =
  /(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const URL_NO_PROTOCOL_REGEXP =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const POSTALZIPCODE_REGEXP =
  /^[0-9]{5}$|^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$/;

export const PATH_REGEXP = new RegExp(/^(\/[a-z0-9.\-]+\/*){1,99}$/m);
export const SUBDOMAIN_REGEXP = new RegExp(/^[A-Z0-9]+\.*\-*[A-Z0-9\.\-]*$/im);
export const URL_REGEXP = new RegExp(
  '^' +
    // protocol identifier (optional)
    // short syntax // still required
    '(?:(?:(?:https?|ftp):)?\\/\\/)' +
    // user:pass BasicAuth (optional)
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:' +
    // IP address exclusion
    // private & local networks
    '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
    '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
    // host & domain names, may end with dot
    // can be replaced by a shortest alternative
    // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
    '(?:' +
    '(?:' +
    '[a-z0-9\\u00a1-\\uffff]' +
    '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
    ')?' +
    '[a-z0-9\\u00a1-\\uffff]\\.' +
    ')+' +
    // TLD identifier name, may end with dot
    '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
    ')' +
    // port number (optional)
    '(?::\\d{2,5})?' +
    // resource path (optional)
    '(?:[/?#]\\S*)?' +
    '$',
  'i'
);

export class SharedValidators {
  static noHttp: ValidatorFn = (control: AbstractControl) =>
    !control.value || !PROTOCOL_REGEXP.test(control.value)
      ? null
      : { noHttp: true };

  static requiredArray: ValidatorFn = (control: AbstractControl) =>
    control.value && control.value.length > 0
      ? null
      : {
          required: {
            valid: false
          }
        };

  static domain: ValidatorFn = (control: AbstractControl) =>
    !control.value || extractRootDomain(control.value)
      ? null
      : {
          domain: {
            message: `This doesn't look like a valid website address.`
          }
        };

  static url: ValidatorFn = (control: AbstractControl) =>
    !control.value || URL_REGEXP.test(control.value)
      ? null
      : {
          domain: {
            message: `Not a valid URL. Be sure to include http(s)`
          }
        };

  static postalzipcode: ValidatorFn = (control: AbstractControl) =>
    !control.value || POSTALZIPCODE_REGEXP.test(control.value)
      ? null
      : {
          postalzipcode: {
            message: `Not a valid postal/zip code`
          }
        };

  static subdomain: ValidatorFn = (control: AbstractControl) =>
    !control.value || SUBDOMAIN_REGEXP.test(control.value)
      ? null
      : {
          subdomain: {
            message: `Not a valid subdomain`
          }
        };

  static path: ValidatorFn = (control: AbstractControl) =>
    !control.value || PATH_REGEXP.test(control.value)
      ? null
      : {
          subdomain: {
            message: `Not a valid path. It need to start with a /. Not symbols allow`
          }
        };

  /**
   * Checks if a string value already exists in a string array
   *
   * @param source
   * @param isCaseSensitive set to false if casing DOES NOT matter
   * @returns
   */
  static noDuplicate =
    (source: string[], isCaseSensitive = true): ValidatorFn =>
    (control: AbstractControl) => {
      const value = isCaseSensitive
        ? control.value
        : control.value?.toLowerCase();
      const condition = isCaseSensitive
        ? source.includes(value)
        : source.map(item => item.toLowerCase()).includes(value);
      return condition
        ? {
            duplicate: true
          }
        : null;
    };

  static noDuplicateArrayFactory =
    (fields: string[]): ValidatorFn =>
    (formArray: UntypedFormArray) => {
      if (formArray.value && !formArray.value.length) {
        return null;
      }

      formArray.controls.forEach((control, currentIndex) => {
        formArray.value.forEach(value => {});
      });
    };

  static noDuplicateControl =
    (itemsArray: UntypedFormArray): ValidatorFn =>
    (control: AbstractControl) => {
      if (!control.value || !itemsArray) {
        return null;
      }

      const foundDuplicate = itemsArray.controls.filter(
        controlItem =>
          controlItem.get(getControlName(control)).value === control.value
      );

      if (foundDuplicate.length > 1) {
        return {
          duplicated: {
            message: 'This item is duplicated.'
          }
        };
      } else {
        return null;
      }
    };
}

/**
 * Debounced async validator from:
 * https://github.com/angular/angular/issues/6895#issuecomment-307112896
 */
type AsyncValidatorFactory = (
  service: (value: any) => Observable<any | null>
) => AsyncValidatorFn;

export const asyncValidatorFactory: AsyncValidatorFactory = (
  service: (value: any) => Observable<any | null>
): AsyncValidatorFn => {
  let subscription: Subscription = Subscription.EMPTY;
  return (input: AbstractControl) => {
    subscription.unsubscribe();
    return new Observable((observer: Observer<any | null>) => {
      subscription = observableTimer(400)
        .pipe(switchMap(() => service(input.value)))
        .subscribe(observer);
      return () => subscription.unsubscribe();
    });
  };
};

const getControlName = (control: AbstractControl): string | null => {
  const group = control.parent;

  if (!group) {
    return null;
  }

  let name: string;

  Object.keys(group.controls).forEach(key => {
    const childControl = group.get(key);

    if (childControl !== control) {
      return;
    }

    name = key;
  });

  return name;
};
