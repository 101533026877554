import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'ease-task-user-menu',
  templateUrl: './task-user-menu.component.html',
  styleUrls: ['./task-user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskUserMenuComponent implements OnInit {
  @Input() mode: 'assign' | 'subscribe' = 'assign';
  @Output() move: EventEmitter<void> = new EventEmitter<void>();
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
