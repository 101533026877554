import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectToIterable'
})
export class ObjectToIterablePipe implements PipeTransform {
  transform(inputObject: any): any[] {
    const results = [];
    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        let unwrapped = inputObject[key];
        if (/string|number|boolean/.test(typeof inputObject[key])) {
          unwrapped = {
            $value: inputObject[key]
          };
        }
        unwrapped['$key'] = key;
        results.push(unwrapped);
      }
    }

    return results;
  }
}
