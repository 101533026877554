<ease-sheet-content
  size="sm md"
  class="!flex flex-col items-start justify-start !py-4 lg:flex-row lg:flex-wrap"
>
  <div class="mb-1 flex w-full lg:mb-0 lg:w-auto">
    <div class="flex flex-grow">
      <button
        type="button"
        class="!-ml-2 lg:!mr-3"
        (click)="toggleStarred()"
        mat-icon-button
      >
        <mat-icon
          class="mat-icon-28"
          [ngClass]="{ 'text-amber-800': contact?.isStarred }"
          >{{ contact?.isStarred ? 'star' : 'star_outline' }}</mat-icon
        >
      </button>
    </div>

    <div class="flex-none lg:hidden">
      <ng-container *ngTemplateOutlet="contactActions"></ng-container>
    </div>
  </div>

  <div class="flex-1">
    <div class="user-status form-group-xs text-base">
      <div class="shared-inline name font-semibold">
        {{ contact?.title }} {{ contact?.firstName }} {{ contact?.middleName }}
        {{ contact?.lastName }}
      </div>
      <div class="shared-inline log !mb-1" *ngIf="contact?.reportingApp">
        <p
          class="shared-inline status-dot"
          [ngClass]="!contact.reportingApp.disabled ? 'active' : 'disabled'"
          matTooltip="{{
            !contact.reportingApp.disabled ? 'Active' : 'Disabled'
          }}"
        ></p>
        <p
          class="shared-inline date"
          matTooltip="Reports last login: {{
            contact.reportingApp.lastActive
              ? (contact.reportingApp.lastActive | amDateFormat: 'MMM D, YYYY')
              : 'never'
          }}"
        >
          <span class="hidden sm:inline-block">
            {{
              contact.reportingApp.lastActive
                ? (contact.reportingApp.lastActive | amTimeAgo)
                : 'never logged in'
            }}
          </span>

          <span class="inline-block sm:hidden">
            Reports last login:
            {{
              contact.reportingApp.lastActive
                ? (contact.reportingApp.lastActive | amTimeAgo)
                : 'never'
            }}
          </span>
        </p>
      </div>
    </div>

    <div class="mb-3 flex lg:hidden">
      <ng-container *ngTemplateOutlet="contactType"></ng-container>
    </div>

    <div
      class="form-group-xs flex flex-row items-center justify-start pb-1 text-xs"
      *ngIf="contact?.phone || contact?.phoneSecondary"
    >
      <div [matTooltip]="contact?.phoneType || 'phone'">
        <ease-contact-phone-icon
          class="mr-1 align-middle text-gray-700"
          [phoneType]="contact?.phoneType"
        ></ease-contact-phone-icon>
        {{ contact?.phone }}
      </div>
      <ng-container *ngIf="contact?.phoneSecondary">
        <div [matTooltip]="contact?.phoneSecondaryType || 'phone'">
          <ease-contact-phone-icon
            class="ml-2 mr-1 text-gray-700"
            [phoneType]="contact?.phoneSecondaryType"
          ></ease-contact-phone-icon>
          {{ contact?.phoneSecondary }}
        </div>
      </ng-container>
    </div>
    <div
      class="form-group-xs flex flex-row items-center justify-start pb-1 text-xs"
      *ngIf="contact?.email"
    >
      <div class="flex flex-row items-center justify-start">
        <mat-icon class="mat-icon-16 mr-1 text-gray-700">email</mat-icon>
        {{ contact?.email }}
      </div>
    </div>
    <div
      *ngIf="contact?.notes"
      class="flex flex-row items-center justify-start pb-1 text-xs"
    >
      <div class="flex flex-row items-start justify-start">
        <mat-icon class="mat-icon-16 mr-1 text-gray-700">note</mat-icon>
        {{ contact?.notes }}
      </div>
    </div>
  </div>

  <div class="actions flex flex-row">
    <div class="flex-1"></div>
    <div class="flex lg:-mt-2.5">
      <div class="hidden items-center lg:flex">
        <ng-container *ngTemplateOutlet="contactType"></ng-container>
        <ng-container *ngTemplateOutlet="contactActions"></ng-container>
      </div>
    </div>

    <mat-menu #menu="matMenu" class="contacts-action-menu">
      <ng-container *ngIf="contact?.reportingApp; else registerItem">
        <a mat-menu-item [href]="userSettingLink" target="blank">
          <mat-icon>settings</mat-icon>
          <span>Reports App User Settings</span>
        </a>
      </ng-container>
    </mat-menu>
  </div>
</ease-sheet-content>

<ng-template #registerItem>
  <a
    mat-menu-item
    *ngIf="registerLink$ | async as registerLink"
    [href]="registerLink"
    target="blank"
  >
    <mat-icon>mobile_screen_share</mat-icon>
    <span>Register in Reports App</span>
  </a>
</ng-template>

<ng-template #contactActions>
  <button
    type="button"
    mat-icon-button
    *ngIf="editButton"
    (click)="onEditClicked()"
  >
    <mat-icon>edit</mat-icon>
  </button>
  <button
    type="button"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    *ngIf="moreMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-template>

<ng-template #contactType>
  <ease-contact-type-view [type]="contact.type"></ease-contact-type-view>
</ng-template>
