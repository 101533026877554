import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import {
  CUSTOMER_MESSAGES_PATH,
  CUSTOMER_MESSAGE_GROUPS_PATH
} from '../../shared/firebase-paths';
import { WindowService } from '../../shared/window/window.service';
import { SkSyncHttpService } from '../../shared/sksync-http.service';
import {
  EmailMessageDetails,
  EmailSendData
} from '../../shared/emails/email.interface';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from '../../shared/constants';
import {
  CustomerMessage,
  CustomerMessageGroup
} from './customer-messages.interface';

@Injectable({ providedIn: 'root' })
export class CustomerMessagesService {
  constructor(
    private angularFire: FirebaseDbService,
    private matSnackBar: MatSnackBar,
    private sksyncHttp: SkSyncHttpService,
    private windowService: WindowService
  ) {}

  getAll(customerId: string): Observable<CustomerMessage[]> {
    return this.angularFire.getList(
      `/${CUSTOMER_MESSAGES_PATH}/${customerId}`,
      ref => ref.orderByChild('createdAtReversed')
    );
  }

  getAllGroups(customerId: string): Observable<CustomerMessageGroup[]> {
    return this.angularFire.getList(
      `/${CUSTOMER_MESSAGE_GROUPS_PATH}/${customerId}`,
      ref => ref.orderByChild('createdAtReversed')
    );
  }

  create(customerId: string) {
    this.windowService.create({
      type: 'sendEmail',
      data: {
        customerId
      } as EmailSendData
    });
  }

  getDetails(messageId: string): Promise<EmailMessageDetails> {
    return firstValueFrom(
      this.sksyncHttp.get(`/messages/${messageId}`, {}, { displayError: false })
    );
  }

  getMessageGroup(
    customerId: string,
    messageGroupId: string
  ): Observable<CustomerMessageGroup> {
    return this.angularFire.getObject(
      `/${CUSTOMER_MESSAGE_GROUPS_PATH}/${customerId}/${messageGroupId}`
    );
  }

  async resend(messageId: string): Promise<any> {
    try {
      await firstValueFrom(
        this.sksyncHttp.post(`/messages/${messageId}/resend`)
      );

      this.matSnackBar.open('Message resent', 'Close', {
        duration: SNACKBAR_DURATION_SUCCESS
      });
    } catch (e) {
      this.matSnackBar.open('Error resending message', 'Close', {
        duration: SNACKBAR_DURATION_ERROR
      });
    }
  }
}
