import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { firstValueFrom, map } from 'rxjs';
import { ConfirmData, ConfirmResult } from './confirm-data.interface';
import { ConfirmComponent } from './confirm.component';

const CONFIRM_DEFAULTS: ConfirmData = {
  title: 'Confirm Action',
  message: 'Are you sure?',
  confirmText: 'Yes',
  cancelText: 'No',
  confirmColor: 'warn',
  showDetails: false
};

@Injectable({ providedIn: 'root' })
export class ConfirmService {
  constructor(private mdDialog: MatDialog) {}

  confirm(
    data?: ConfirmData,
    config: MatDialogConfig = {}
  ): Promise<ConfirmResult> {
    return firstValueFrom(
      this.mdDialog
        .open(
          ConfirmComponent,
          Object.assign(
            {},
            {
              data: Object.assign({}, CONFIRM_DEFAULTS, data),
              minWidth: 320
            },
            config
          )
        )
        .afterClosed()
        .pipe(map(confirmResult => confirmResult || { confirmResult: false }))
    );
  }
}
