import { NgModule } from '@angular/core';

import { ColorBlobModule } from '../../shared/color-blob/color-blob.module';
import { SharedFormModule } from '../../shared/shared-form.module';
import { SharedModule } from '../../shared/shared.module';
import { SharedPipesModule } from '../../shared/shared-pipes.module';
import { CustomerPlanSelectorComponent } from './customer-plan-selector.component';

@NgModule({
  imports: [ColorBlobModule, SharedModule, SharedFormModule, SharedPipesModule],
  declarations: [CustomerPlanSelectorComponent],
  exports: [CustomerPlanSelectorComponent]
})
export class CustomerPlanSelectorModule {}
