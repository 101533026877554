<ease-sheet class="popover">
  <ease-popover-header>{{ title }}</ease-popover-header>
  <ease-user-selector
    #userSelector
    (selected)="selected.emit($event)"
    (deselected)="deselected.emit($event)"
    [selectedUsers]="selectedUsers"
    [selectIcon]="selectIcon"
    [onlyUsers]="onlyUsers"
  >
  </ease-user-selector>
</ease-sheet>
