<div class="container flex flex-row">
  <div class="type-icons">
    <div
      *ngIf="notification.hasMention"
      class="item mention"
      matTooltip="You were mentioned"
    >
      <mat-icon class="mat-icon-16">alternate_email</mat-icon>
    </div>
    <div
      *ngIf="notification.hasAssigned"
      class="item assigned"
      matTooltip="Assigned to you"
    >
      <mat-icon class="mat-icon-16">person_add</mat-icon>
    </div>
    <div
      *ngIf="notification.hasSubscribed"
      class="item assigned"
      matTooltip="You were added as watcher"
    >
      <mat-icon class="mat-icon-16">remove_red_eye</mat-icon>
    </div>
    <div
      *ngIf="notification.hasCompleted"
      class="item"
      matTooltip="Task completed"
    >
      <mat-icon class="mat-icon-16">done</mat-icon>
    </div>
    <div
      *ngIf="notification.hasNoteAdded"
      class="item"
      matTooltip="Note / comment added"
    >
      <mat-icon class="mat-icon-16">chat</mat-icon>
    </div>
  </div>
  <div class="content flex-1">
    <div (click)="onClick($event)">
      <div class="header">
        <div class="notification-panel__title truncate">
          {{ notification.title }}
        </div>
        <div class="notification-panel__subtitle truncate">
          <ease-entity-icon
            class="mr-2"
            [entity]="{
              entityType: notification.entityType,
              accountType: notification.accountType
            }"
            [size]="16"
          >
          </ease-entity-icon>
          <span class="label flex-1">{{ notification.entityName }}</span>
        </div>
      </div>
      <div class="updates">
        <div
          class="item"
          *ngFor="let update of notification.updates | limit: limitCount"
        >
          <div class="notification-panel__updates flex flex-row text-gray-700">
            <div
              class="notification-panel__action flex-1"
              [ngClass]="{ assigned: update.isAssigned || update.isSubscribed }"
            >
              {{ update.action }}
            </div>
            <div class="notification-panel__meta">
              {{ update.createdAt | amTimeAgo }}
            </div>
          </div>
          <div
            *ngIf="update.previewShort?.length"
            class="notification-panel__preview"
            [ngClass]="{ mention: update.isMention }"
          >
            {{ update.previewShort }}
          </div>
        </div>
      </div>
      <div
        class="actions flex flex-row items-center justify-center"
        *ngIf="notification.updates.length > limitCount"
      >
        <button
          *ngIf="!isExpanded"
          (click)="toggleExpanded(); $event.stopPropagation()"
          easeStopEvent
          mat-stroked-button
          class="flex-1"
        >
          Show {{ notification.updates.length - limitCount }} more updates
        </button>
        <button
          *ngIf="isExpanded"
          (click)="toggleExpanded(); $event.stopPropagation()"
          easeStopEvent
          mat-stroked-button
          class="flex-1"
        >
          Hide other updates
        </button>
      </div>
    </div>
    <div
      *ngIf="notification.status === 'unread'"
      class="notification-panel__dismiss-btn"
      matRipple
      (click)="onDismiss()"
      matTooltip="Mark as read"
    >
      <mat-icon class="mat-icon-14">close</mat-icon>
    </div>
  </div>
</div>
