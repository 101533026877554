<form [formGroup]="editForm" (ngSubmit)="save()">
  <h1 mat-dialog-title>Edit Scheduled Task</h1>
  <mat-dialog-content class="dialog-content">
    <ease-task-edit-form
      [isPreScheduled]="true"
      formControlName="fields"
    ></ease-task-edit-form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      type="button"
      title="Remove"
      (click)="remove()"
      mat-icon-button
      color="warn"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <div class="flex-1"></div>
    <button type="button" mat-dialog-close mat-button>Cancel</button>
    <button
      type="submit"
      [disabled]="editForm.invalid"
      color="primary"
      mat-button
    >
      Save
    </button>
  </mat-dialog-actions>
</form>
