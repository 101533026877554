<ease-sheet class="!bg-transparent">
  <ease-sheet-header class="bg-white shadow-md">
    <div class="flex flex-row items-center justify-start">
      <ease-account-icon
        class="mr-2"
        [accountType]="accountType"
        [size]="30"
      ></ease-account-icon>
      {{ accountTypeLabel }}
    </div>
    <div
      class="sheet-table-headers sheet-table-headers--expandable flex flex-row"
    >
      <div class="flex-1"></div>
      <div class="sheet-table-col col-budget">Budget</div>
      <div class="sheet-table-col col-enabled">Enabled</div>
    </div>
  </ease-sheet-header>
  <ease-sheet-group class="shadow-md" easeSheetExpander>
    <ease-sheet-expand
      *ngFor="let account of accounts"
      (opened)="getBudgetDetails(account.$key)"
      #sheetExpand
      [disableExpandIcon]="account.budgetByEase"
      [expandViaHost]="!account.budgetByEase"
    >
      <div sheet-expand-before>
        <ease-sheet-content
          class="item-content !flex flex-row items-center justify-center"
          size="sm md"
        >
          <div class="flex-1">
            {{ account.name }}
            <span class="text-sm text-gray-400">({{ account.$key }})</span>
            <div class="badge-funding ml-2" *ngIf="account.budgetByEase">
              Budget managed by ease
            </div>
          </div>
          <div class="sheet-table-col col-budget">
            {{ account.budgetFormatted }}
          </div>
          <div class="sheet-table-col col-enabled">
            <mat-icon *ngIf="account.enabled" class="text-emerald-500"
              >check_circle</mat-icon
            >
          </div>
        </ease-sheet-content>
      </div>
      <ng-template sheet-expand-after-lazy>
        <ease-sheet-header class="pb-0">{{ account.name }}</ease-sheet-header>
        <ease-customer-account-budget
          [allowCustomDateRange]="allowCustomDateRange"
          [showSummary]="showSummary"
          [summary]="budgetDetails[account.$key].summary | async"
          [settings]="budgetDetails[account.$key].settings | async"
          [accountType]="accountType"
          (save)="
            saveAccountSettings($event, account.$key); sheetExpand.close()
          "
          (cancel)="sheetExpand.close()"
        >
        </ease-customer-account-budget>
      </ng-template>
    </ease-sheet-expand>
  </ease-sheet-group>
</ease-sheet>
