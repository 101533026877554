import { AnimationEvent } from '@angular/animations';

import { EntityMetadata } from '../../shared.interface';

export interface WindowPaneOptions {
  closeResult?: any;
  emitEvent?: boolean;
  saveState: boolean;
}

export enum WindowPaneAnimationState {
  OPEN = 'open',
  CLOSE = 'close',
  VOID = 'void'
}

export interface WindowPaneAnimationEvent extends AnimationEvent {
  phaseName: 'start' | 'done';
  fromState: WindowPaneAnimationState;
  toState: WindowPaneAnimationState;
}

export interface TaskCreateWindowData extends Omit<EntityMetadata, 'phase'> {
  parent?: string;
}
