<!-- recurrence date info panel -->
<div
  *ngIf="task.rruleOptions?.count !== 1"
  data-id="{{ task?.$key }}"
  matTooltip="More scheduled dates"
  class="task-panel__meta"
  [ngClass]="{ active: activeState }"
  [satPopoverAnchor]="scheduledDatesPopover"
  (click)="scheduledDatesPopover.open()"
>
  <div>
    <div class="flex flex-row items-center justify-start">
      <mat-icon class="mat-icon-16">refresh</mat-icon>
      <div class="capitalize">{{ task.rruleOptions?.interval }} days</div>
    </div>

    <div class="flex flex-row items-center justify-start text-gray-500">
      <mat-icon class="mat-icon-16">date_range</mat-icon>
      <div>{{ task.scheduledNext | timezoneDateFormat: 'MMM DD' }}</div>
    </div>
    <div class="more flex flex-row items-end justify-center">
      <mat-icon class="mat-icon-20">more_horiz</mat-icon>
    </div>
  </div>
</div>

<!-- normal date info panel  -->
<div
  *ngIf="task.rruleOptions?.count === 1"
  class="task-panel__meta"
  data-id="{{ task?.$key }}"
  (click)="editTask(key)"
>
  <div>
    <div class="flex flex-row items-center justify-start text-gray-500">
      <mat-icon class="mat-icon-16">date_range</mat-icon>
      <div>{{ task.scheduledNext | timezoneDateFormat: 'MMM DD' }}</div>
    </div>
  </div>
</div>

<!-- task info panel -->
<div class="task-panel__body w-0 flex-1 truncate" (click)="editTask(key)">
  <div class="task-panel__names">
    <div class="task-panel__name">{{ task?.name }}</div>
    <div
      *ngIf="task?.entityId"
      class="task-panel__client flex flex-row items-center justify-start"
    >
      <ease-entity-icon
        class="mr-1"
        [entity]="{
          entityType: task.entityType,
          accountType: task.accountType
        }"
        [size]="16"
      >
      </ease-entity-icon>
      <span class="flex-1">{{ task.entityName }}</span>
    </div>
  </div>
</div>

<div class="task-panel__swap">
  <div class="task-panel__assigned flex flex-row items-center justify-end">
    <ease-avatar
      *ngFor="let userId of task?.$displayAssigned"
      [userId]="userId"
      [showTooltip]="true"
      [showInitials]="true"
    ></ease-avatar>
  </div>
</div>

<sat-popover
  verticalAlign="below"
  horizontalAlign="center"
  #scheduledDatesPopover
  [hasBackdrop]="true"
  (opened)="popoverState(true)"
  (closed)="popoverState(false)"
>
  <ease-task-scheduled-dates
    *ngIf="scheduledDatesPopover.isOpen()"
    [taskId]="task?.$key"
    [redHeader]="true"
  ></ease-task-scheduled-dates>
</sat-popover>
