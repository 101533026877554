import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  fromEvent,
  merge,
  Observable,
  ReplaySubject
} from 'rxjs';
import {
  filter,
  first,
  map,
  shareReplay,
  skip,
  startWith,
  tap
} from 'rxjs/operators';
import { isSupported } from 'firebase/messaging';
import { Workbox } from 'workbox-window';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar
} from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { FirebaseDbService } from './firebase-db.service';
import { APP_VERSION_PATH } from './firebase-paths';
import { AdblockDetectorDialogComponent } from './adblock-detector-dialog/adblock-detector-dialog.component';

export type PlatformType = 'MAC' | 'WINDOWS' | 'OTHER';

export interface BeforeInstallPromptEvent extends Event {
  prompt(): Promise<void>;
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  public newVersionAvailable$: Observable<boolean>;
  public applicationUpdateOngoing$: Observable<boolean>;
  public applicationOnline$: Observable<boolean> = merge(
    fromEvent(window, 'offline'),
    fromEvent(window, 'online')
  ).pipe(
    map(() => navigator.onLine),
    startWith(navigator.onLine)
  );
  public applicationInstallable$: Observable<boolean>;
  public runningStandAlone = false;
  public swRegistration: ServiceWorkerRegistration;
  public swRegistration$: ReplaySubject<ServiceWorkerRegistration> =
    new ReplaySubject<ServiceWorkerRegistration>(1);
  public platformType$: Observable<PlatformType>;

  private newVersionAvailable = new BehaviorSubject(false);
  private applicationUpdateOngoing = new BehaviorSubject(false);
  private applicationUpdateRequested = new BehaviorSubject(false);
  private serviceWorkerReady = new BehaviorSubject(false);
  private applicationInstallable = new BehaviorSubject(false);
  private platformTypeSource: ReplaySubject<PlatformType> =
    new ReplaySubject<PlatformType>();
  private versionListener$: Observable<string>;
  private versionSnackBarRef: MatSnackBarRef<SimpleSnackBar>;

  private sw = {
    file: '/notifications-sw.js',
    registerOptions: {},
    updateInterval: 180000
  };

  private promptEvent: BeforeInstallPromptEvent;
  // serviceWorker only available in production ( bundled assets )
  private serviceWorkerAvailable = false;
  private visible = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private angularFire: FirebaseDbService,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    private httpClient: HttpClient
  ) {
    this.newVersionAvailable$ = this.newVersionAvailable.asObservable();
    this.applicationUpdateOngoing$ =
      this.applicationUpdateOngoing.asObservable();
    this.applicationInstallable$ = this.applicationInstallable.asObservable();
    this.platformType$ = this.platformTypeSource.asObservable();

    this.platformTypeSource.next(
      navigator.appVersion.includes('Mac')
        ? 'MAC'
        : navigator.appVersion.includes('Windows')
        ? 'WINDOWS'
        : 'OTHER'
    );
  }

  public init() {
    this.checkInstallPrompt();
    this.registerServiceWorker();
    this.checkRunningStandAlone();
    this.registerVisibleChangeListener();

    /**
     * Listen for app version
     */
    this.versionListener$ = this.angularFire
      .getObject(`/${APP_VERSION_PATH}`)
      .pipe(
        map(version => version.$value),
        shareReplay({ refCount: true, bufferSize: 1 })
      );

    this.versionListener$.pipe(skip(1)).subscribe(() => this.checkForUpdate());

    this.newVersionAvailable$.subscribe(
      available => available && this.showRefreshUI()
    );
  }

  public isServiceWorkerReady(): Promise<boolean> {
    const swReady$: Observable<boolean> = new Observable(observer => {
      this.serviceWorkerReady
        .pipe(
          filter(serviceWorkerReady => serviceWorkerReady),
          first()
        )
        .subscribe(() => {
          observer.next(true);
          observer.complete();
        });
    });

    return firstValueFrom(swReady$);
  }

  public update(): void {
    this.applicationUpdateRequested.next(true);
  }

  public async checkForUpdate(): Promise<any> {
    if (this.serviceWorkerAvailable && this.swRegistration) {
      try {
        console.log('[EnvironmentService] Checking for update');
        return await this.swRegistration.update();
      } catch (err) {
        console.log('[EnvironmentService] Error checking for update', err);
      }
    } else {
      console.log('[EnvironmentService] SW functionality not available');
    }
  }

  public hasServiceWorker(): boolean {
    return 'serviceWorker' in navigator;
  }

  public hasMessagingSupport(): Promise<boolean> {
    // Some browsers don't support Push API(push notification),
    // this is a shared helper for checking.
    return isSupported();
  }

  public promptInstall(): Promise<void> {
    if (!this.promptEvent) {
      return;
    }
    return this.promptEvent
      .prompt()
      .then(() => this.applicationInstallable.next(false));
  }

  public async hasAdBlocker(): Promise<boolean> {
    try {
      await firstValueFrom(
        combineLatest([
          // Requesting internal asset to avoid initial precaching fail with Workbox
          this.httpClient.get('/assets/images/google-ads-logo.svg', {
            responseType: 'blob'
          }),
          // Requesting external `adsbygoogle.js` to ensure no adblocker after precaching
          this.httpClient.get(
            'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
            {
              responseType: 'blob'
            }
          )
        ])
      );

      console.log('[EnvironmentService] No ad blocker detected');

      return false;
    } catch (err) {
      console.log('[EnvironmentService] Ad blocker detected');

      this.matDialog.open(AdblockDetectorDialogComponent, {
        width: '25vw',
        disableClose: true
      });

      return true;
    }
  }

  async unregisterServiceWorkersAndClearCaches() {
    if (this.hasServiceWorker()) {
      try {
        console.log(
          '[EnvironmentService] Unregistering service worker and clearing caches'
        );
        const [registrations, allCaches] = await Promise.all([
          navigator.serviceWorker.getRegistrations(),
          caches.keys()
        ]);

        const unregistrations = registrations.map(registration =>
          registration.unregister()
        );

        const cacheClears = allCaches.map(cache => caches.delete(cache));

        await Promise.allSettled([...unregistrations, ...cacheClears]);

        console.log(
          '[EnvironmentService] Unregistered service workers and cleared caches'
        );
      } catch (err) {
        console.log(
          '[EnvironmentService] Error unregistering service worker and clearing caches',
          err
        );
      }
    }
  }

  private async registerServiceWorker(): Promise<void> {
    /**
     * Check we're in a browser before checking for service
     * worker support
     */
    if (isPlatformBrowser(this.platformId)) {
      this.serviceWorkerAvailable = this.hasServiceWorker();
    }

    /**
     * Check that service workers are available, if not,
     * mark as ready (app will run without them)
     */
    if (!this.serviceWorkerAvailable) {
      this.serviceWorkerReady.next(true);
      return;
    }

    const wb = new Workbox(this.sw.file, this.sw.registerOptions);

    wb.addEventListener('activated', event => {
      if (!event.isUpdate) {
        // If your service worker is configured to precache assets, those
        // assets should all be available now.

        // Send a message telling the service worker to claim the clients
        // This is the first install, so the functionality of the app
        // should meet the functionality of the service worker!
        wb.messageSW({ type: 'CLIENTS_CLAIM' });

        // The service worker is ready, so we can bootstrap the app
        this.serviceWorkerReady.next(true);
      }

      if (event.isExternal) {
        // If your service worker is configured to precache assets, those
        // assets should all be available now.
        // This activation was on request of the user, so let's finally reload the page
        window.location.reload();
      }
    });

    wb.addEventListener('controlling', () =>
      this.serviceWorkerReady.next(true)
    );

    // we use this waiting listener to show updates
    // when the user refreshes the page and a new service worker is going to waiting
    // this is specifically only valid when refreshed!
    wb.addEventListener('waiting', event => {
      // inform any functionality that is interested in this update
      console.log('[EnvironmentService] New version available');
      this.newVersionAvailable.next(true);

      // listen to application update requests
      this.applicationUpdateRequested
        .pipe(
          filter(applicationUpdateRequested => applicationUpdateRequested),
          first()
        )
        .subscribe(() => {
          wb.addEventListener('controlling', () => window.location.reload());
          // Send a message telling the service worker to skip waiting.
          // This will trigger the `controlling` event handler below.
          // Note: for this to work, you have to add a message
          // listener in your service worker. See below.
          console.log(
            '[EnvironmentService] Sending SKIP_WAITING to correct service worker'
          );
          wb.messageSkipWaiting();

          // let anybody interested know we are updating the application
          this.applicationUpdateOngoing.next(true);
        });
    });

    try {
      this.swRegistration = await wb.register();
      this.swRegistration$.next(this.swRegistration);

      setInterval(() => this.checkForUpdate(), this.sw.updateInterval);

      // The serviceWorker `controller` return null when hard refresh,
      // use ServiceWorkerRegistration `active` instead.
      // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/controller
      if (this.swRegistration.active) {
        this.serviceWorkerReady.next(true);
      }
    } catch (err) {
      console.log('[EnvironmentService] Error registering service worker', err);
    }
  }

  private checkRunningStandAlone(): void {
    // only do this in the browser
    if (isPlatformBrowser(this.platformId) && 'matchMedia' in window) {
      if ((navigator as any).standalone) {
        console.log('[EnvironmentService] Launched: Installed (iOS)');
        this.runningStandAlone = true;
      } else if (window.matchMedia('(display-mode: standalone)').matches) {
        console.log('[EnvironmentService] Launched: Installed');
        this.runningStandAlone = true;
      } else {
        console.log('[EnvironmentService] Launched: Browser Tab');
      }
    }
  }

  private registerVisibleChangeListener(): void {
    // only do this in the browser
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(document, 'visibilitychange')
        .pipe()
        .subscribe(() => {
          this.visible = document.visibilityState === 'visible';
          // only check for update if the page became visible
          if (this.visible) {
            this.checkForUpdate();
          }
        });
    }
  }

  private checkInstallPrompt(): void {
    fromEvent(window, 'beforeinstallprompt')
      .pipe(
        tap((event: BeforeInstallPromptEvent) => {
          event.preventDefault();
          this.promptEvent = event;
          this.applicationInstallable.next(true);
        })
      )
      .subscribe();
  }

  showRefreshUI(): void {
    if (!this.versionSnackBarRef) {
      this.versionSnackBarRef = this.matSnackBar.open(
        'An update for Ease is available',
        'Update Now',
        {
          panelClass: ['version-notification']
        }
      );

      this.versionSnackBarRef.onAction().subscribe(() => this.update());

      /**
       * Auto-update after 2 minutes if user hasn't
       * clicked the snackbar themselves
       */
      setTimeout(() => this.update(), 120000);
    }
  }
}
