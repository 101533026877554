import { Pipe, PipeTransform } from '@angular/core';

import { PlanFrequency } from './customer-billing.interface';

@Pipe({
  name: 'planFrequency'
})
export class PlanFrequencyPipe implements PipeTransform {
  transform(frequency: PlanFrequency): string {
    const interval = getFriendlyNameForInterval(frequency);

    return `Every ${frequency.numberOfIntervals} ${interval}`;
  }
}

const getFriendlyNameForInterval = ({
  interval,
  numberOfIntervals
}: PlanFrequency): string => {
  switch (interval) {
    case 'Monthly':
      return numberOfIntervals > 1 ? 'Months' : 'Month';

    case 'Yearly':
      return numberOfIntervals > 1 ? 'Years' : 'Year';

    default:
      return '';
  }
};
