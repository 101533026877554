import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  NgZone,
  OnDestroy
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ViewportOffsetService } from './viewport-offset.service';

@UntilDestroy()
@Directive({
  selector: '[easeViewportOffsetPusher]'
})
export class ViewportOffsetPusherDirective implements AfterViewInit, OnDestroy {
  private elementHeight: number = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private element: ElementRef,
    private ngZone: NgZone,
    private viewportOffsetService: ViewportOffsetService
  ) {}

  ngAfterViewInit() {
    this.setRoofHeight();

    this.ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(untilDestroyed(this), debounceTime(280))
        .subscribe(() => this.updateResizedRoofHeight());
    });
  }

  ngOnDestroy() {
    // Subtract HOST element's height from previous total height when element/component has gone
    this.viewportOffsetService.subtractRoofHeight(this.elementHeight);
  }

  private setRoofHeight(): void {
    // Element has delay on transforming, so timeout here to ensure receiving correct values
    setTimeout(() => {
      if (this.element) {
        // Use `offsetHeight` to calculate HOST element's height
        this.elementHeight = this.element.nativeElement.offsetHeight;
        this.viewportOffsetService.addRoofHeight(this.elementHeight);
      }
    });
  }

  public updateResizedRoofHeight(): void {
    // If element height changed on resize, re-calculate the difference
    if (this.element) {
      const currentHeight = this.element.nativeElement.offsetHeight;

      if (currentHeight !== this.elementHeight) {
        // 1. Get the difference
        const difference = currentHeight - this.elementHeight;

        // 2. Set to the new height for later usage
        this.elementHeight = currentHeight;

        // 3. Only add the difference to total - positive or negative heights
        this.viewportOffsetService.addRoofHeight(difference);

        // call detectChanges with ngZone
        this.cdr.detectChanges();
      }
    }
  }
}
