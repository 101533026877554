<form
  [formGroup]="taskForm"
  (ngSubmit)="createTasks()"
  class="flex min-h-0 flex-1 flex-col"
>
  <ease-sheet-content class="flex-1 overflow-y-auto overflow-x-hidden">
    <ng-container *ngIf="!submitted; else progressTracker">
      <ease-advanced-autocomplete
        class="mb-2"
        [formControl]="templateControl"
        [options]="taskTemplates"
        [placeholder]="'Select a task template'"
        (optionSelected)="setTemplate($event)"
      >
      </ease-advanced-autocomplete>

      <div class="flex flex-1 flex-col" cdkDropListGroup>
        <div class="form-group-sm">
          <mat-form-field>
            <input formControlName="name" placeholder="Task Name" matInput />
            <mat-error
              easeFormErrors
              [control]="taskForm?.controls.name"
            ></mat-error>
          </mat-form-field>
        </div>

        <div class="form-group-sm">
          <div class="mb-[7px] text-xs text-gray-600">Assigned Users</div>
          <ease-user-selector-input
            formControlName="assigned"
            label="Assign user"
            [onlyUsers]="true"
            (selected)="onAssign()"
          >
            <ng-template #menu let-userId>
              <ease-task-user-menu
                (move)="
                  taskUserMenuService.move(
                    userId,
                    taskForm.controls.assigned,
                    taskForm.controls.subscribed
                  )
                "
                (remove)="
                  taskUserMenuService.remove(userId, taskForm.controls.assigned)
                "
              ></ease-task-user-menu>
            </ng-template>
          </ease-user-selector-input>
          <mat-error
            *ngIf="taskForm.errors?.assignedWatchersRequired"
            class="mt-[-16px]"
          >
            {{ taskForm.errors.assignedWatchersRequired }}
          </mat-error>
        </div>

        <div class="form-group-sm">
          <div class="mb-[7px] text-xs text-gray-600">Watchers</div>
          <ease-user-selector-input
            formControlName="subscribed"
            label="Add a watcher"
            class="!pb-3"
          >
            <ng-template #menu let-userId>
              <ease-task-user-menu
                mode="subscribe"
                (move)="
                  taskUserMenuService.move(
                    userId,
                    taskForm.controls.subscribed,
                    taskForm.controls.assigned
                  );
                  onAssign()
                "
                (remove)="
                  taskUserMenuService.remove(
                    userId,
                    taskForm.controls.subscribed
                  )
                "
              ></ease-task-user-menu>
            </ng-template>
          </ease-user-selector-input>
          <mat-error
            *ngIf="taskForm.errors?.assignedWatchersRequired"
            class="mt-[-16px]"
          >
            {{ taskForm.errors.assignedWatchersRequired }}
          </mat-error>
        </div>

        <div class="mb-[6px]">
          <ease-task-role-selector
            formControlName="assignedRoles"
            #roleSelector
          ></ease-task-role-selector>
          <mat-error
            *ngIf="taskForm.errors?.assignedWatchersRequired"
            class="mt-[-16px]"
          >
            {{ taskForm.errors.assignedWatchersRequired }}
          </mat-error>
          <mat-error
            *ngIf="taskForm.errors?.entityError?.wrongType"
            class="mt-[-16px]"
          >
            The assigned role(s) only exists on the account level. For
            customers, you can assign the Relationship Manager role instead.
          </mat-error>
        </div>

        <div class="flex flex-col gap-4 md:flex-row">
          <div class="form-group flex-1">
            <mat-form-field>
              <mat-select placeholder="Board" formControlName="board">
                <mat-option
                  *ngFor="let board of boards$ | async"
                  [value]="board.$key"
                  >{{ board.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group flex-1" *ngIf="taskForm.controls.board.value">
            <mat-form-field>
              <mat-select placeholder="List" formControlName="list">
                <mat-option
                  *ngFor="let list of listsForCurrentBoard$ | async"
                  [value]="list.$key"
                  >{{ list.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group task-meta-group !mb-6 flex flex-1 flex-row">
            <ease-task-status
              [selectedStatus]="taskForm.controls.status.value"
              (statusChanged)="changeStatus($event)"
              class="inline-flex pr-6"
              matTooltip="Status"
            ></ease-task-status>
            <ease-task-phase
              [selectedPhase]="taskForm.controls.phase.value"
              (phaseChanged)="changePhase($event)"
              class="inline-flex pr-6"
              matTooltip="Phase"
            ></ease-task-phase>
          </div>
        </div>
        <div class="form-group !mb-11">
          <ease-bulk-targeting
            [formControl]="target"
            [targetLevels]="['customer', 'prospect', 'account']"
          ></ease-bulk-targeting>
          <mat-error *ngIf="taskForm.errors as errors" class="mt-1">
            <ng-container
              *ngIf="errors.entityError as entityError; else missingRoles"
            >
              {{ entityError.message }}
            </ng-container>
            <ng-template #missingRoles>
              <ng-container *ngIf="errors.missingRoles as missingRoles">
                <div>
                  The following {{ entityType }}s have unassigned roles:
                </div>
                <div *ngFor="let entity of missingRoles" class="flex gap-2">
                  <span
                    [cdkCopyToClipboard]="entity.entityId"
                    class="flex cursor-pointer gap-1"
                    matTooltip="Click to copy ID to clipboard"
                  >
                    <ease-entity-icon
                      [entity]="{
                        entityType: entity.entityType,
                        accountType: entity.accountType
                      }"
                      [size]="18"
                      class="flex items-center"
                    >
                    </ease-entity-icon>
                    {{ entity.entityName }}
                  </span>
                  <span>|</span>
                  <span>{{ entity.missingRoles }}</span>
                </div>
              </ng-container>
            </ng-template>
          </mat-error>
        </div>
        <div class="form-group">
          <ease-markdown-editor
            [easeMentions]="true"
            placeholder="Describe your task, @mention users"
            formControlName="description"
            [easeMarkdownVariable]="entityType"
            [easeMarkdownVariableCustom]="customVariables"
          ></ease-markdown-editor>
        </div>
        <div class="form-group-sm">
          <ease-advanced-autocomplete
            formControlName="checklist"
            [options]="checklists$ | async"
            [placeholder]="'Select a checklist'"
          >
          </ease-advanced-autocomplete>
        </div>
        <div class="flex flex-1">
          <ease-datepicker
            class="mr-3"
            [min]="now"
            [max]="taskForm.controls.dueDate.value"
            formControlName="startDate"
            placeholder="Task Start Date"
          ></ease-datepicker>
          <ease-datepicker
            [min]="taskForm.controls.startDate.value"
            formControlName="dueDate"
            placeholder="Task Due Date"
          ></ease-datepicker>
        </div>

        <div class="form-group-sm">
          <mat-slide-toggle formControlName="scheduled"
            >Enable Schedule</mat-slide-toggle
          >
        </div>
        <div
          [formGroup]="rruleForm"
          class="flex flex-col gap-4 md:flex-row"
          [ngClass]="{ hidden: !taskForm.controls.scheduled.value }"
        >
          <div class="form-group-xs flex-1">
            <ease-datepicker
              [min]="now"
              formControlName="dtstart"
              placeholder="First Creation Date"
            ></ease-datepicker>
          </div>
          <div class="form-group-xs flex-1">
            <mat-form-field>
              <mat-select formControlName="interval" placeholder="Repeat">
                <mat-option
                  *ngFor="let frequency of scheduledFrequencies"
                  [value]="frequency.value"
                  >{{ frequency.text }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #progressTracker>
      <div class="flex h-full flex-col">
        <div class="mb-4 flex flex-row items-center justify-center">
          <ng-container [ngSwitch]="inProgress">
            <ng-container *ngSwitchCase="true">
              <mat-spinner
                class="mr-2"
                mode="indeterminate"
                color="primary"
                diameter="24"
              ></mat-spinner>
              <h3>Creating Tasks</h3>
            </ng-container>
            <ng-container *ngSwitchDefault [ngSwitch]="cancelled">
              <ng-container *ngSwitchCase="true">
                <mat-icon class="mat-icon-24 mr-2 text-red-500">block</mat-icon>
                <h3>Cancelled</h3>
              </ng-container>
              <ng-container *ngSwitchDefault [ngSwitch]="hasFailedTasks">
                <ng-container *ngSwitchCase="true">
                  <mat-icon class="mat-icon-24 mr-2 text-red-500"
                    >report_problem</mat-icon
                  >
                  <h3>Completed with errors</h3>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <mat-icon class="mat-icon-24 mr-2">done</mat-icon>
                  <h3>Completed</h3>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <ease-create-task-bulk-queue
          #queueTracker
          [entity]="target.value.level"
          [rowData]="queue"
          (cellAction)="cellAction($event)"
          class="flex-grow"
        ></ease-create-task-bulk-queue>
      </div>
    </ng-template>
  </ease-sheet-content>

  <ease-sheet-footer [ngSwitch]="submitted" class="flex-none">
    <ng-container *ngSwitchCase="true" [ngSwitch]="hasFailedTasks">
      <ng-container *ngSwitchCase="true">
        <button
          (click)="inProgress ? cancel() : close()"
          type="button"
          mat-button
        >
          {{ inProgress ? 'Cancel' : 'Close' }}
        </button>
        <button
          (click)="retry()"
          [disabled]="inProgress"
          color="primary"
          type="button"
          mat-button
        >
          {{
            cancelled ? 'Resume and retry failed tasks' : 'Retry failed tasks'
          }}
        </button>
      </ng-container>
      <ng-container *ngSwitchDefault [ngSwitch]="cancelled">
        <ng-container *ngSwitchCase="true">
          <button
            (click)="inProgress ? cancel() : close()"
            type="button"
            mat-button
          >
            {{ inProgress ? 'Cancel' : 'Close' }}
          </button>
          <button
            (click)="retry()"
            [disabled]="inProgress"
            color="primary"
            type="button"
            mat-button
          >
            Resume
          </button>
        </ng-container>

        <button
          *ngSwitchDefault
          (click)="inProgress ? cancel() : close()"
          color="primary"
          type="button"
          mat-button
        >
          {{ inProgress ? 'Cancel' : 'Close' }}
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <button (click)="close()" type="button" mat-button>Cancel</button>
      <button
        [disabled]="
          taskForm.invalid || !target.value?.members?.length || submitted
        "
        color="primary"
        type="submit"
        mat-button
      >
        Create Tasks
      </button>
    </ng-container>
  </ease-sheet-footer>
</form>

<ng-template #windowIconTemplate>
  <ng-container [ngSwitch]="submitted">
    <ng-container *ngSwitchCase="true" [ngSwitch]="inProgress">
      <ng-container *ngSwitchCase="true">
        <mat-spinner
          class="my-1 mr-3"
          mode="indeterminate"
          color="primary"
          diameter="16"
        ></mat-spinner>
      </ng-container>
      <ng-container *ngSwitchDefault [ngSwitch]="cancelled">
        <ng-container *ngSwitchCase="true">
          <mat-icon class="mat-icon-16 my-1 mr-3 text-white">block</mat-icon>
        </ng-container>
        <ng-container *ngSwitchDefault [ngSwitch]="hasFailedTasks">
          <ng-container *ngSwitchCase="true">
            <mat-icon class="mat-icon-16 my-1 mr-3 text-white">
              report_problem
            </mat-icon>
          </ng-container>
          <mat-icon *ngSwitchDefault class="mat-icon-16 my-1 mr-3 text-white">
            done
          </mat-icon>
        </ng-container>
      </ng-container>
    </ng-container>
    <mat-icon *ngSwitchDefault class="mat-icon-16 my-1 mr-3">queue</mat-icon>
  </ng-container>
</ng-template>

<ng-template #windowTitleTemplate>
  <div class="flex flex-row items-center">
    <div class="truncate text-sm font-medium">
      <ng-container *ngIf="taskForm?.value?.name; else initialTitle">
        Bulk task: {{ taskForm?.value?.name }}
      </ng-container>
      <ng-template #initialTitle>Creating bulk task</ng-template>
    </div>
  </div>
</ng-template>

<ng-template #windowSubtitleTemplate>
  <div class="flex flex-row items-center">
    <ease-entity-icon
      class="mr-1"
      [size]="16"
      [entity]="{
        entityType: target?.value?.level
      }"
    ></ease-entity-icon>
    <div *ngIf="target?.value?.level" class="truncate text-xs">
      {{ target.value.members.length }}
      {{ target.value.level | capitalize }}s
    </div>
  </div>
</ng-template>
