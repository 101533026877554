<button
  (click)="notificationsPopover.open()"
  [satPopoverAnchor]="notificationsPopover"
  matTooltip="Notifications"
  class="relative"
  mat-icon-button
>
  <div
    class="h-6 w-6 rounded-full bg-white text-center leading-6 text-red-500"
    *ngIf="
      (notificationsService.unreadCount$ | async)?.total as totalUnread;
      else emptyIcon
    "
  >
    {{ totalUnread }}
  </div>
  <ng-template #emptyIcon><mat-icon>notifications</mat-icon></ng-template>
</button>
<sat-popover
  panelClass="toolbar-popover"
  verticalAlign="start"
  horizontalAlign="end"
  #notificationsPopover
  [hasBackdrop]="true"
>
  <ease-notifications-popover
    *ngIf="notificationsPopover.isOpen()"
    (clicked)="onNotificationClicked($event)"
    (dismiss)="onNotificationDismiss($event)"
    (markAllRead)="onMarkAllRead($event)"
    (closePanel)="notificationsPopover.close()"
    #notificationPopoverWindow
  >
  </ease-notifications-popover>
</sat-popover>
