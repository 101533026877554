import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_DURATION_SUCCESS } from 'src/app/shared/constants';
import { CustomerWebsitesService } from '../customer-websites.service';
import { CombinedCustomerWebsites } from '../customer-websites.interface';

@Component({
  selector: 'ease-customer-websites',
  templateUrl: './customer-websites.component.html',
  styleUrls: ['./customer-websites.component.scss']
})
export class CustomerWebsitesComponent implements OnInit {
  @HostBinding('class') class = 'flex flex-col flex-1';

  @Input()
  set customerId(customerId: string) {
    this._customerId = customerId;

    if (customerId) {
      this.isLoading = true;
      this.websites$ = this.customerWebsitesService
        .getAll(customerId)
        .pipe(tap(() => (this.isLoading = false)));
    }
  }

  get customerId(): string {
    return this._customerId;
  }

  public isLoading: boolean = false;
  public websites$: Observable<CombinedCustomerWebsites>;
  private _customerId: string;

  constructor(
    private customerWebsitesService: CustomerWebsitesService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  removeWebsite(websiteId: string): void {
    this.customerWebsitesService.remove(this.customerId, websiteId);
  }

  showMessage(message: string): void {
    this.matSnackBar.open(message, null, {
      duration: SNACKBAR_DURATION_SUCCESS
    });
  }
}
