<div (click)="onSortRequested($event)" class="flex flex-1 flex-row">
  <div class="flex flex-row items-center justify-center">
    <span
      class="column-header"
      [ngClass]="{ 'with-mobile-header': params.mobileHeaderName }"
    >
      {{ params.displayName }}
    </span>
    <span class="column-header mobile">{{ params.mobileHeaderName }}</span>
    <span class="tooltip-icon" *ngIf="params.tooltipDescription">
      <mat-icon
        class="mat-icon-14"
        *easeRichTooltip="params.tooltipDescription"
        aria-label="Button that displays a tooltip when focused or hovered over"
      >
        help
      </mat-icon>
    </span>
  </div>
  <div class="flex-1"></div>
  <div
    *ngIf="params.enableSorting && params.column.isSortAscending()"
    class="customSortDownLabel flex flex-col items-center justify-center"
  >
    <mat-icon class="mat-icon-18">arrow_upward</mat-icon>
  </div>

  <div
    *ngIf="params.enableSorting && params.column.isSortDescending()"
    class="customSortDownLabel flex flex-col items-center justify-center"
  >
    <mat-icon class="mat-icon-18">arrow_downward</mat-icon>
  </div>
</div>
<div
  *ngIf="params.enableMenu"
  #menuButton
  class="customHeaderMenuButton"
  (click)="onMenuClicked()"
>
  <mat-icon class="mat-icon-18">menu</mat-icon>
</div>
