import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { RankingListItem } from '../../accounts/account-dashboard/account-dashboard.interface';

@Component({
  selector: 'ease-vertical-ranking',
  templateUrl: './vertical-ranking.component.html',
  styleUrls: ['./vertical-ranking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerticalRankingComponent implements AfterViewInit {
  @Input() verticalRankings: RankingListItem[];
  @Input() colorSelected;
  @ViewChild('self') accountHighlight: ElementRef;

  ngAfterViewInit(): void {
    if (this.accountHighlight) {
      setTimeout(() => {
        this.accountHighlight.nativeElement.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth'
        });
      });
    }
  }
}
