import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import {
  CellClickedEvent,
  GridOptions,
  ICellRendererParams,
  ValueGetterParams
} from 'ag-grid-community';

import {
  Target,
  TargetMember
} from 'src/app/shared/bulk-targeting/bulk-targeting.interface';
import { LOGO_FILE_FOR_ACCOUNT_TYPE } from 'src/app/shared/constants';
import { TaskQueueItem, TaskQueueStatus } from '../create-task-bulk.interface';
import { CellAction } from './create-task-bulk-queue.interface';

@Component({
  selector: 'ease-create-task-bulk-queue',
  templateUrl: './create-task-bulk-queue.component.html',
  styleUrls: ['./create-task-bulk-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTaskBulkQueueComponent implements OnInit {
  @HostBinding('class') class = 'flex flex-col h-full';
  @Input() entity: Target['level'];
  @Input() rowData: TargetMember[];
  @Input() statusBarBg: Record<TaskQueueStatus, string> = {
    QUEUED: 'bg-gray-200',
    'IN PROGRESS': 'bg-gray-200',
    DONE: 'bg-blue-300',
    FAILED: 'bg-red-300'
  };
  @Output() cellAction: EventEmitter<CellAction> =
    new EventEmitter<CellAction>();

  public gridOptions: GridOptions;
  public summary: Record<string, number> = {};

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const entityName: string = this.entity
      ? this.entity.charAt(0).toUpperCase() + this.entity.slice(1)
      : 'Entity';

    const typeColumnDef =
      entityName === 'Account'
        ? [
            {
              headerName: `${entityName} Type`,
              field: 'type',
              width: 130,
              cellRenderer: (params: ICellRendererParams) => {
                const { type } = params.data as any;
                return `<div class="flex h-full items-center justify-center"><img class="mat-icon-18" src="/assets/images/${LOGO_FILE_FOR_ACCOUNT_TYPE[type]}" /></div>`;
              }
            }
          ]
        : [];

    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        suppressMenu: true
      },
      aggFuncs: {
        summary: queue => {
          this.summary = {};
          queue.values.forEach(status => {
            if (this.summary[status]) {
              this.summary[status]++;
            } else {
              this.summary[status] = 1;
            }
          });
        }
      },
      columnDefs: [
        {
          headerName: 'Status',
          field: 'status',
          pinned: 'left',
          width: 90,
          aggFunc: 'summary',
          cellRenderer: (params: ICellRendererParams) => {
            const status = params.value as TaskQueueStatus;

            switch (status) {
              case 'QUEUED':
                return '<div class="flex h-full items-center justify-center"><span class="mat-icon mat-icon-18 material-icons text-gray-400">more_horiz</span></div>';
              case 'IN PROGRESS':
                return `<div class="flex h-full items-center justify-center"><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500 !mx-auto" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg></div>`;
              case 'DONE':
                return '<div class="flex h-full items-center justify-center"><span class="mat-icon mat-icon-18 material-icons">done</span></div>';
              case 'FAILED':
                return '<div class="flex h-full items-center justify-center"><span class="mat-icon mat-icon-18 material-icons text-red-500">report_problem</span></div>';
            }
          }
        },
        {
          headerName: `${entityName} ID`,
          field: 'id'
        },
        ...typeColumnDef,
        {
          headerName: `${entityName} Name`,
          field: 'name',
          flex: 1,
          minWidth: 360
        },
        {
          headerName: 'Actions',
          pinned: 'right',
          width: 110,
          valueGetter: (params: ValueGetterParams) => {
            const { status, taskId } = params.data as TaskQueueItem;
            return { status, taskId };
          },
          cellRenderer: (params: ICellRendererParams) => {
            const { status } = params.value as Pick<
              TaskQueueItem,
              'status' | 'taskId'
            >;

            const cellClass = {
              div: 'flex h-full items-center justify-center',
              span: 'mat-icon mat-icon-18 material-icons'
            };

            switch (status) {
              case 'DONE':
                return `<div class="${cellClass.div} cursor-pointer"><span class="${cellClass.span}">launch</span></div>`;
              case 'FAILED':
                return `<div class="${cellClass.div} cursor-pointer"><span class="${cellClass.span}">refresh</span></div>`;
              default:
                return `<div class="${cellClass.div}"><span class="${cellClass.span} text-gray-400">more_horiz</span></div>`;
            }
          },
          onCellClicked: (event: CellClickedEvent) => {
            const item = event.data as TaskQueueItem;
            switch (item.status) {
              case 'DONE':
                return this.cellAction.emit({
                  name: 'view',
                  data: item
                });
              case 'FAILED':
                return this.cellAction.emit({
                  name: 'retry',
                  data: item
                });
            }
          }
        }
      ]
    };
  }

  updateQueue() {
    this.gridOptions.api.refreshCells();
    this.gridOptions.api.refreshClientSideRowModel('aggregate');
    this.cdr.detectChanges();
  }
}
