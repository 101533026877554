import { NgModule } from '@angular/core';
import { ScrollTrackerModule } from '@searchkings/ngx-scroll-tracker';

import { ActionScrollDirective } from './action-scroll.directive';
import { HorizontalScrollDirective } from './horizontal-scroll.directive';
import { InfiniteScrollDirective } from './infinite-scroll.directive';
import { ScrollToListContainerDirective } from './scroll-to-list/scroll-to-list-container.directive';
import { ScrollToListItemDirective } from './scroll-to-list/scroll-to-list-item.directive';

@NgModule({
  declarations: [
    ActionScrollDirective,
    HorizontalScrollDirective,
    InfiniteScrollDirective,
    ScrollToListContainerDirective,
    ScrollToListItemDirective
  ],
  exports: [
    ActionScrollDirective,
    HorizontalScrollDirective,
    InfiniteScrollDirective,
    ScrollToListContainerDirective,
    ScrollToListItemDirective,
    ScrollTrackerModule
  ]
})
export class ScrollModule {}
