<ng-container *ngIf="!isGroup && costPerLeadMetric">
  <div
    class="metric pointer flex flex-row items-center justify-center"
    (click)="metricInfo.toggle()"
    [satPopoverAnchor]="metricInfo"
    [ngClass]="costPerLeadMetric.ragStatusColor"
  >
    <div
      *ngIf="costPerLeadMetric.percentageSwingFromTarget"
      class="metric-color-special flex-1"
    >
      {{ costPerLeadMetric.percentageSwingFromTarget }}%
    </div>
    <div class="metric-details flex-1">
      <div
        class="metric-top"
        [matTooltip]="'Current CPL'"
        *ngIf="costPerLeadMetric.cpl"
      >
        <img
          src="/assets/icons/svg/coin-outline.svg"
          alt="target"
          class="micro-icon"
        />{{ costPerLeadMetric.cpl | currency: 'USD':'symbol':'1.0-2' }}
      </div>

      <div
        class="metric-bottom"
        [matTooltip]="
          account?.targetCpl !== targetCplNumber
            ? 'New Target CPL ' +
              (account?.targetCpl | currency: 'USD':'symbol':'1.0-2')
            : 'Target CPL'
        "
        *ngIf="costPerLeadMetric.cplTarget"
      >
        <img
          src="/assets/icons/svg/bullseye.svg"
          alt="target"
          class="micro-icon"
        />
        <span
          [ngClass]="{
            'line-through': account?.targetCpl !== targetCplNumber
          }"
          >{{
            costPerLeadMetric.cplTarget | currency: 'USD':'symbol':'1.0-2'
          }}</span
        >
      </div>
    </div>
  </div>
  <sat-popover [autoFocus]="false" [hasBackdrop]="true" #metricInfo>
    <ease-cpl-popover
      *ngIf="metricInfo.isOpen()"
      [account]="account"
      (cplChanged)="cellChanged($event)"
    ></ease-cpl-popover>
  </sat-popover>
</ng-container>
