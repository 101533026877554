import { ComponentRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import {
  BehaviorSubject,
  distinctUntilChanged,
  firstValueFrom,
  Observable,
  ReplaySubject
} from 'rxjs';

import { uuidv4 } from '../../utils/functions';
import {
  WindowConfig,
  WindowState,
  WINDOW_ROUTER_OUTLET,
  WINDOW_ROUTER_PATH
} from '../window-options';
import { WindowService } from '../window.service';
import { WindowPaneComponent } from './window-pane.component';
import {
  WindowPaneAnimationEvent,
  WindowPaneOptions
} from './window-pane.interface';

export class WindowPane<R = void> extends WindowConfig<R> {
  public readonly id: string;
  public icon: string;
  public state: WindowState;
  public componentRef: ComponentRef<WindowPaneComponent>;
  private dataChanged$ = new ReplaySubject<R>();
  private previousState: WindowState;
  private defaultConfig: Omit<WindowConfig<R>, 'component' | 'type'> = {
    state: 'MAXIMIZED',
    title: 'New Window',
    allowDuplicates: true,
    highlighted: false,
    templateContext: {}
  };
  private closed$ = new ReplaySubject<WindowPaneOptions>();
  private canClose$ = new BehaviorSubject<boolean>(true);
  private stateChanged$ = new ReplaySubject<WindowState>();
  private templateContextChanged$ = new ReplaySubject<any>();
  private animationEvents$ = new ReplaySubject<WindowPaneAnimationEvent>();
  private titleChanged$ = new ReplaySubject<string>();
  private subtitleChanged$ = new ReplaySubject<string>();
  private activatedRouteSource$ = new ReplaySubject<ActivatedRoute>();
  private initialTitle: string;
  private initialSubtitle: string;
  private router?: Router;

  constructor(config: WindowConfig<R>, private windowService: WindowService) {
    super(config);
    this.id = config.id || uuidv4();
    this.initialTitle = config.title;
    this.initialSubtitle = config.subtitle;
    Object.assign(this, this.defaultConfig, config);
    this.dataChanged$.next(this.data);
    this.stateChanged$.next(this.state);
    this.windowService.setSelected(this);
    this.templateContextChanged$.next(this.templateContext);
  }

  /**
   * Get an observable that emits when window is closed
   */
  get onClose(): Observable<Omit<WindowPaneOptions, 'emitEvent'>> {
    return this.closed$.asObservable();
  }

  /**
   * Get an observable that emits when window state changes
   */
  get onStateChanged(): Observable<WindowState> {
    return this.stateChanged$.asObservable();
  }

  /**
   * Get an observable that emits when window title changes
   */
  get onTitleChanged(): Observable<string> {
    return this.titleChanged$.asObservable();
  }

  /**
   * Get an observable that emits when window subtitle changes
   */
  get onSubtitleChanged(): Observable<string> {
    return this.subtitleChanged$.asObservable();
  }

  /**
   * Get an observable that emits when data is provided or changed
   */
  get onDataChanged(): Observable<R> {
    return this.dataChanged$.asObservable();
  }

  /**
   * Get an observable that emits when the activated route is provided or changed
   */
  get onActivatedRouteChanged(): Observable<ActivatedRoute> {
    return this.activatedRouteSource$.asObservable();
  }

  /**
   * Get an observable that emits when the template context is provided or changed
   */
  get onTemplateContextChanged(): Observable<any> {
    return this.templateContextChanged$
      .asObservable()
      .pipe(distinctUntilChanged(isEqual));
  }

  /**
   * Get an observable that emits when the animation state changes
   */
  get animationEvents(): Observable<WindowPaneAnimationEvent> {
    return this.animationEvents$.asObservable();
  }

  /**
   * Update the simple title for displaying
   *
   * @param subtitle New subtitle to display for the window
   */
  updateTitle(title: string) {
    this.title = title || this.initialTitle;
    this.titleChanged$.next(this.title);
  }

  /**
   * Update the simple subtitle for displaying
   *
   * @param subtitle New subtitle to display for the window
   */
  updateSubtitle(subtitle: string) {
    this.subtitle = subtitle || this.initialSubtitle;
    this.subtitleChanged$.next(this.subtitle);
  }

  /**
   * Update the icon template
   *
   * @param template TemplateRef to render the window icon with
   */
  updateIconTemplate(template: TemplateRef<any>) {
    this.iconTemplate = template;
  }

  /**
   * Update the title template
   *
   * @param template TemplateRef to render the window title with
   */
  updateTitleTemplate(template: TemplateRef<any>) {
    this.titleTemplate = template;
  }

  /**
   * Update the subtitle template
   *
   * @param template TemplateRef to render the window subtitle with
   */
  updateSubtitleTemplate(template: TemplateRef<any>) {
    this.subtitleTemplate = template;
  }

  /**
   * Update the subtitle template context
   *
   * @param context Any data to be passed to your ngTemplateOutletContext
   */
  updateTemplateContext(context: any) {
    this.templateContext = context;
    this.templateContextChanged$.next(this.templateContext);
  }

  /**
   * Send new data to be consumed by templates, components, etc.
   *
   * @param value Any data you wish to push to subscribers
   */
  updateData(value: any) {
    this.dataChanged$.next(value);
  }

  /**
   * Update the animation state for the window pane (start, done, open, close)
   *
   * @param event The animation even containing the current animation state
   */
  updateAnimationState(event: WindowPaneAnimationEvent) {
    this.animationEvents$.next(event);
  }

  /**
   * Toggle window state
   */
  async toggle() {
    let newState: WindowState;
    const oldState = this.state;

    if (this.state === 'MINIMIZED') {
      if (this.previousState) {
        newState = this.previousState;
      } else {
        newState = this.firstToggleState || 'OPEN';
      }
    } else {
      newState = 'MINIMIZED';
    }

    this.windowService.setSelected(this);
    await this.setState(newState, oldState);
    this.stateChanged$.next(this.state);
  }

  /**
   * Open window
   */
  async open() {
    this.windowService.setSelected(this);
    await this.setState('OPEN');
  }

  /**
   * Minimize window
   */
  async minimize() {
    await this.setState('MINIMIZED');
  }

  /**
   * Maximize window
   */
  async maximize() {
    this.windowService.setSelected(this);
    await this.setState('MAXIMIZED');
  }

  /**
   * Marks a window as highlighted (e.g. unread notification)
   */
  highlight() {
    this.highlighted = true;
  }

  /**
   * Marks a window as unhighlighted (e.g. unread notification marked as read)
   */
  unhighlight() {
    this.highlighted = false;
  }

  enableClose() {
    this.canClose$.next(true);
  }

  disableClose() {
    this.canClose$.next(false);
  }

  canClose(): Observable<boolean> {
    return this.canClose$.asObservable();
  }

  /**
   * Close the window pane
   *
   * @param options.closeResult An optional closeResult to be emitted to anything listening
   * @param options.emitEvent Whether to emit the close event at all
   * @param options.saveState Whether the closing of this window should be saved to localStorage
   * @returns Whether the window was closed or not
   */
  async close(options?: WindowPaneOptions): Promise<boolean> {
    const { emitEvent, saveState, closeResult } = {
      ...{
        emitEvent: true,
        saveState: true
      },
      ...options
    };

    const canClose = await firstValueFrom(this.canClose());
    if (canClose) {
      this.windowService.saveRecentlyClosed(this);
      this.componentRef.destroy();
      this.stateChanged$.complete();
      this.titleChanged$.complete();
      this.subtitleChanged$.complete();
      await this.populateWindowOutlet(null);
      emitEvent && this.closed$.next({ closeResult, saveState });
      this.closed$.complete();
      return true;
    } else {
      return false;
    }
  }

  setActivatedRoute(ar: ActivatedRoute) {
    this.activatedRouteSource$.next(ar);
  }

  async updateRoute() {
    if (!this.router) {
      this.router = this.componentRef.injector.get(Router);
    }

    if (
      this.routeable &&
      (this.state === 'OPEN' || this.state === 'MAXIMIZED')
    ) {
      await this.populateWindowOutlet([
        WINDOW_ROUTER_PATH,
        {
          data: this.data,
          type: this.type,
          ...(this.routeableParams ? this.routeableParams : {})
        }
      ]);

      /**
       * Clear any routeable params that were defined, if
       * specified we only need them once.
       *
       * E.g. navigating to a noteId inside a task
       */
      if (this.clearRouteableParams) {
        this.routeableParams = null;
      }
    } else {
      await this.populateWindowOutlet(null);
    }
  }

  private async populateWindowOutlet(commands: any[]) {
    const { path, queryParams } = this.windowService.parsePathAndParams(
      this.router
    );

    /**
     * Ensure we aren't already in the process of navigating to a
     * window-producing URL (e.g. /windows/create). If we are, don't navigate again to avoid
     * infinitely creating windows via WindowCreateGuard
     */
    if (!path?.startsWith(WINDOW_ROUTER_PATH)) {
      await this.router.navigate(
        [
          {
            outlets: {
              primary: path,
              [WINDOW_ROUTER_OUTLET]: commands
            }
          }
        ],
        {
          queryParams,
          queryParamsHandling: 'merge'
        }
      );
    }
  }

  /**
   * Sets the state of the window
   *
   * @param state New window state to apply
   * @param previousState Specific state to store in previous value, otherwise use current state
   */
  private async setState(state: WindowState, previousState?: WindowState) {
    this.storePreviousState(previousState);
    this.state = state;
    this.stateChanged$.next(this.state);
    await this.updateRoute();
  }

  /**
   *
   * @param state Specific state to store in previous value, otherwise use current state
   */
  private storePreviousState(state?: WindowState) {
    this.previousState = state || this.state;
  }
}

/**
 * How long the window takes to open/close
 */
export const WINDOW_PANE_ANIMATION_DURATION = 150;
