import { NgModule } from '@angular/core';

import { TaskWindowerDirective } from './task-windower.directive';

@NgModule({
  declarations: [TaskWindowerDirective],
  imports: [],
  exports: [TaskWindowerDirective]
})
export class TaskWindowerModule {}
