<ag-grid-angular
  #mainGrid
  style="width: 100%; height: 100%"
  class="ag-theme-material"
  [ngClass]="{ mobile: (sharedLayoutService.isCompact$ | async)?.active }"
  [masterDetail]="true"
  [detailCellRenderer]="'detailCellRenderer'"
  [detailCellRendererParams]="detailCellRendererParams"
  [detailRowHeight]="300"
  [rowData]="accounts"
  [gridOptions]="gridOptions"
  [groupDefaultExpanded]="groupDefaultExpanded"
  [quickFilterText]="quickFilter"
  (cellValueChanged)="onCellValueChanged($event)"
  [suppressScrollOnNewData]="true"
  [overlayLoadingTemplate]="overlayLoadingTemplate"
  [animateRows]="true"
>
</ag-grid-angular>

<ng-template #defaultCurrencyCell let-value>
  <div *ngIf="value; else emptyMetricCell">
    {{ value | currency: 'USD':'symbol':'1.0-2' }}
  </div>
</ng-template>

<ng-template #defaultMetricCell let-value>
  <div *ngIf="value; else emptyMetricCell">
    {{ value | currency: 'USD':'symbol':'1.0-2' }}
  </div>
</ng-template>

<ng-template #emptyMetricCell>
  <div class="metric empty flex flex-1 flex-row" matTooltip="No data">
    <mat-icon class="mat-icon-24"> error_outline </mat-icon>
  </div>
</ng-template>
