import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';

import { AdminPermissionsService } from '../../../admin/admin-roles/admin-permissions.service';
import { ConfirmService } from '../../../shared/confirm/confirm.service';
import { ListSelectorComponent } from '../../../shared/list-selector/list-selector.component';
import { TaskStatus } from '../task-status.interface';
import { TaskStatusService } from '../task-status.service';

@Component({
  selector: 'ease-task-status-selector',
  templateUrl: './task-status-selector.component.html',
  styleUrls: ['./task-status-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskStatusSelectorComponent implements OnInit {
  @Input()
  selectedStatus: string[] = [];
  @Output()
  selected: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  edit: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('listSelector', { static: true })
  listSelector: ListSelectorComponent;
  public isEmpty: boolean = false;
  public statuses$: Observable<TaskStatus[]>;
  public isAdmin: boolean = false;

  constructor(
    private confirmService: ConfirmService,
    private taskStatusService: TaskStatusService,
    public permissionsService: AdminPermissionsService
  ) {}

  ngOnInit() {
    this.statuses$ = this.taskStatusService.getAll();
  }

  focusInput() {
    this.listSelector && this.listSelector.focusInput();
  }

  onSelect(statusId: string) {
    this.selected.emit(statusId);
  }

  editStatus(statusId: string, event: Event) {
    this.edit.emit(statusId);
    event.stopPropagation();
  }

  removeStatus(statusId: string, event: Event) {
    this.confirmService
      .confirm({
        title: 'Delete Status',
        confirmText: 'Delete',
        cancelText: 'Cancel'
      })
      .then(
        confirmResult =>
          confirmResult.confirm &&
          this.taskStatusService.remove(statusId).subscribe()
      );

    event.stopPropagation();
  }

  getKey(index, currentValue) {
    return currentValue ? currentValue.$key : index;
  }
}
