import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import findLast from 'lodash-es/findLast';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

import { Breakpoints } from './breakpoints.module';
import { EntityMetadata } from './shared.interface';

@Injectable({ providedIn: 'root' })
export class SharedLayoutService {
  public currentBreakpoint: keyof typeof Breakpoints;
  public currentBreakpoint$: Observable<keyof typeof Breakpoints>;
  public currentBreakpoints: (keyof typeof Breakpoints)[];
  public currentBreakpoints$: Observable<(keyof typeof Breakpoints)[]>;
  public isCompact$: Observable<{ active: boolean }>;
  private observedBreakpoints: string[] = [
    Breakpoints.ltSm,
    Breakpoints.sm,
    Breakpoints.md,
    Breakpoints.lg,
    Breakpoints.xl,
    Breakpoints['2xl']
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    this.currentBreakpoint$ = this.breakpointObserver
      .observe(this.observedBreakpoints)
      .pipe(
        map(() => {
          const bpString = findLast(this.observedBreakpoints, bp =>
            this.breakpointObserver.isMatched(bp)
          );
          return Object.keys(Breakpoints).find(
            bpKey => Breakpoints[bpKey] === bpString
          ) as keyof typeof Breakpoints;
        }),
        distinctUntilChanged(),
        shareReplay({ refCount: true, bufferSize: 1 })
      );

    this.currentBreakpoints$ = this.breakpointObserver
      .observe(this.observedBreakpoints)
      .pipe(
        map(() => {
          const bpStrings = this.observedBreakpoints.filter(bp =>
            this.breakpointObserver.isMatched(bp)
          );
          return Object.keys(Breakpoints).filter(bpKey =>
            bpStrings.includes(Breakpoints[bpKey])
          ) as (keyof typeof Breakpoints)[];
        }),
        distinctUntilChanged(),
        shareReplay({ refCount: true, bufferSize: 1 })
      );

    this.isCompact$ = this.currentBreakpoint$.pipe(
      map(bpKey => ({
        active: bpKey === 'ltSm' || bpKey === 'sm' || bpKey === 'md'
      })),
      shareReplay({ refCount: true })
    );

    this.currentBreakpoint$.subscribe(
      bpKey => (this.currentBreakpoint = bpKey)
    );
    this.currentBreakpoints$.subscribe(
      bpKeys => (this.currentBreakpoints = bpKeys)
    );
  }

  navigateToEntity(meta: Partial<EntityMetadata>): void {
    switch (meta.entityType) {
      case 'customer':
        const basePath = meta.phase === 'prospect' ? 'prospects' : 'customers';
        this.router.navigate([`/${basePath}`, meta.entityId]);
        break;

      case 'prospect':
        this.router.navigate(['/prospects', meta.entityId]);
        break;

      case 'account':
        this.router.navigate(['/accounts', meta.accountType, meta.entityId]);
        break;

      case 'contacts':
        this.router.navigate(['/contacts', meta.entityId]);
        break;
    }
  }

  /**
   * Shortcuts will not work when the event
   * originates from within the markdown-editor or a Input, select, text area element.
   *
   * @param event
   *
   */
  isShortcutAllowed(event): boolean {
    const target = event.target as HTMLElement;
    return !(
      target?.className.includes('ProseMirror') ||
      target?.tagName === 'INPUT' ||
      target?.tagName === 'TEXTAREA' ||
      target?.tagName === 'SELECT'
    );
  }
}
