import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { flatten } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerAccountMeta } from '../customers/customer-accounts/customer-accounts.interface';

import { FirebaseDbService } from './firebase-db.service';
import {
  CUSTOMERS_PATH,
  CALL_TRACKING_ACCOUNTS_PATH,
  LSA_ACCOUNTS_PATH,
  ADWORDS_ACCOUNTS_PATH,
  BING_ACCOUNTS_PATH
} from './firebase-paths';
import { EntityMetadata, EntityType } from './shared.interface';
import { getFirebaseAccountPath } from './utils/functions';

@Injectable({
  providedIn: 'root'
})
export class EntityUtilsService {
  private ref: firebase.database.Reference;

  constructor(private angularFire: FirebaseDbService) {
    this.ref = this.angularFire.database.ref();
  }

  public async getEntityField<T = any>(
    meta: Partial<EntityMetadata>,
    field: string
  ): Promise<T> {
    let baseRef;

    if (meta.entityType === 'customer' || meta.entityType === 'prospect') {
      baseRef = this.ref.child(`/${CUSTOMERS_PATH}/${meta.entityId}/${field}`);
    }

    if (meta.entityType === 'account') {
      switch (meta.accountType) {
        case 'callTracking':
          baseRef = this.ref.child(
            `/${CALL_TRACKING_ACCOUNTS_PATH}/${meta.entityId}/${field}`
          );
          break;

        case 'lsa':
          baseRef = this.ref.child(
            `/${LSA_ACCOUNTS_PATH}/${meta.entityId}/${field}`
          );
          break;

        case 'adwords':
          baseRef = this.ref.child(
            `/${ADWORDS_ACCOUNTS_PATH}/${meta.entityId}/${field}`
          );
          break;

        case 'bing':
          baseRef = this.ref.child(
            `/${BING_ACCOUNTS_PATH}/${meta.entityId}/${field}`
          );
          break;
      }
    }

    if (baseRef) {
      const fieldSnap = await baseRef.once('value');
      return fieldSnap.val();
    } else {
      return Promise.reject(
        `baseRef type not found: ${meta.entityType} ${meta.accountType} ${meta.entityId}`
      );
    }
  }

  public getCustomerIdForAccount(
    accountMeta: CustomerAccountMeta
  ): Promise<string> {
    return this.getEntityField(
      {
        entityId: accountMeta.accountId,
        entityType: 'account',
        accountType: accountMeta.accountType
      },
      'customerId'
    );
  }

  public getEntitiesByUserRole(
    roleId: string,
    userId: string,
    entityType: EntityType
  ) {
    if (entityType === 'customer') {
      return this.angularFire.getList(`/${CUSTOMERS_PATH}`, ref =>
        ref.orderByChild(`roles/${roleId}`).equalTo(userId)
      );
    } else {
      return combineLatest(
        ['adwords', 'bing', 'lsa'].map(accountType =>
          this.angularFire
            .getList(`/${getFirebaseAccountPath(accountType)}`, ref =>
              ref.orderByChild(`roles/${roleId}`).equalTo(userId)
            )
            .pipe(
              map(accounts =>
                accounts.map(account => {
                  account.$accountType = accountType;
                  return account;
                })
              )
            )
        )
      ).pipe(map(accounts => flatten(accounts)));
    }
  }
}
