<div *ngIf="announcement" (click)="openDetails()" class="wrapper">
  <div class="container-level">
    <ng-container [ngSwitch]="announcement.targetLevel">
      <div *ngSwitchCase="'company'" class="level company">
        <img class="mat-icon-20" src="/assets/images/crown.png" />
      </div>
      <div *ngSwitchCase="'user'" class="level user">
        <mat-icon class="mat-icon-20">portrait</mat-icon>
      </div>
    </ng-container>
  </div>
  <div class="container-meta">
    <div class="summary">{{ announcement.summary }}</div>
    <div class="author">by {{ announcement.createdBy?.name }}</div>
  </div>
</div>
