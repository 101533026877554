import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';

import {
  EaseNotification,
  NotificationStatus
} from '../../../notifications/notification.interface';
import { NotificationsService } from '../../../notifications/notifications.service';
import { ListDisplayItem } from '../../shared.interface';
import { SearchPipe } from '../../search.pipe';

@Component({
  selector: 'ease-notifications-popover',
  templateUrl: './notifications-popover.component.html',
  styleUrls: ['./notifications-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsPopoverComponent implements OnInit {
  @ViewChild('searchInput', { read: MatInput }) searchInput: MatInput;
  @Output()
  clicked: EventEmitter<{
    event: MouseEvent;
    notification: EaseNotification;
  }> = new EventEmitter<{
    event: MouseEvent;
    notification: EaseNotification;
  }>();
  @Output()
  dismiss: EventEmitter<EaseNotification> = new EventEmitter<EaseNotification>();
  @Output()
  markAllRead: EventEmitter<EaseNotification[]> = new EventEmitter<
    EaseNotification[]
  >();
  @Output()
  closePanel: EventEmitter<boolean> = new EventEmitter<boolean>();

  public unreadNotifications$: Observable<EaseNotification[]>;
  public readNotifications$: Observable<EaseNotification[]>;
  public searchControl: FormControl<string> = new FormControl();
  public isSearching: boolean = false;
  public selectedItemIndex: number = -1;
  public tabs: (ListDisplayItem & {
    value: NotificationStatus;
    tooltip?: string;
  })[] = [
    { value: 'unread', viewValue: 'Unread' },
    {
      value: 'read',
      viewValue: 'Read',
      tooltip: 'Last 30 days of notifications'
    }
  ];
  public sortOptions: ListDisplayItem[] = [
    { value: 'chronological', viewValue: 'Newest' },
    { value: 'priority', viewValue: 'Priority' }
  ];
  public filterOptions: ListDisplayItem[] = [
    { value: 'hasMention', viewValue: 'Mentions' },
    { value: 'hasAssigned', viewValue: 'Assignments' },
    { value: 'hasSubscribed', viewValue: 'Subscriptions' },
    { value: 'hasCompleted', viewValue: 'Completions' },
    { value: 'hasNoteAdded', viewValue: 'Notes' }
  ];
  public activeStatus: NotificationStatus = 'unread';
  public activatedOnceStatus: { [type in NotificationStatus]?: boolean } = {
    ['unread']: true
  };

  constructor(
    private cdr: ChangeDetectorRef,
    public notificationsService: NotificationsService,
    private searchPipe: SearchPipe
  ) {}

  ngOnInit() {
    this.unreadNotifications$ = this.getNotification(
      this.notificationsService.unread$
    );

    this.readNotifications$ = this.getNotification(
      this.notificationsService.read$
    );
  }

  getNotification(
    notifications$: Observable<EaseNotification[]>
  ): Observable<EaseNotification[]> {
    return this.searchControl.valueChanges.pipe(
      startWith(null),
      distinctUntilChanged(),
      switchMap(query =>
        notifications$.pipe(
          map(notifications => this.searchPipe.transform(notifications, query))
        )
      )
    );
  }

  setActiveStatus(status: NotificationStatus) {
    this.activatedOnceStatus[status] = true;
    this.activeStatus = status;
  }

  setSort(sortType: string) {
    this.notificationsService.notificationSortControl.setValue(sortType);
  }

  onNotificationClick(event: MouseEvent, notification: EaseNotification) {
    this.clicked.emit({ event, notification });
  }

  onNotificationDismiss(notification: EaseNotification) {
    this.dismiss.emit(notification);
  }

  onMarkAllRead(unreadNotification: EaseNotification[]) {
    this.markAllRead.emit(unreadNotification);
  }

  getItemId(index: number, currentValue: EaseNotification) {
    return currentValue ? currentValue.itemId : index;
  }

  closeNotifications() {
    this.closePanel.emit();
  }

  startSearch() {
    this.isSearching = true;
    this.cdr.detectChanges();
    setTimeout(() => this.searchInput && this.searchInput.focus());
  }

  stopSearch() {
    this.searchControl.setValue(null);
    this.isSearching = false;
  }

  clearFilters() {
    this.notificationsService.notificationFilterControl.patchValue([]);
  }
}
