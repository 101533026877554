import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, from as observableFrom, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import { DrawerSidebarService } from 'src/app/shared/drawer-sidebar/drawer-sidebar.service';
import { SharedLayoutService } from 'src/app/shared/shared-layout.service';
import { VerticalTabGroupComponent } from 'src/app/shared/vertical-tabs/vertical-tab-group/vertical-tab-group.component';

import { CustomerFeedViewingEntity } from '../../customers/customer-feed/customer-feed.interface';
import { WindowPaneViewTaskService } from '../../shared/window/window-pane-view-task/window-pane-view-task.service';
import { TaskModel } from '../task.model';
import { SubtasksProgress } from '../task-project/subtasks.service';
import { TaskService } from '../task.service';

@UntilDestroy()
@Component({
  selector: 'ease-task-sidebar-renderer',
  templateUrl: './task-sidebar-renderer.component.html',
  styleUrls: ['./task-sidebar-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskSidebarRendererComponent implements AfterViewInit {
  @HostBinding('class') hostClass = 'h-full min-h-0 overflow-auto';
  @Input()
  set task(task: TaskModel) {
    this._task = task;
    this.taskSource.next(task);

    if (
      this.task &&
      this.task.entityId &&
      this.task.entityId !== this.previousEntityId
    ) {
      this.previousEntityId = this.task.entityId;

      switch (this.task.entityType) {
        case 'customer':
        case 'prospect':
          this.viewingEntity$ = of({
            customerId: this.task.entityId,
            account: null
          });
          break;

        case 'account':
          this.viewingEntity$ = observableFrom(
            this.entityUtilsService.getCustomerIdForAccount({
              accountId: this.task.entityId,
              accountType: this.task.accountType
            })
          ).pipe(
            map(customerId => ({
              customerId,
              account: {
                accountId: this.task.entityId,
                accountType: this.task.accountType
              }
            }))
          );

          break;
      }
    }
  }
  get task(): TaskModel {
    return this._task;
  }
  @ViewChild('tabGroup') tabGroup: VerticalTabGroupComponent;

  public viewingEntity$: Observable<CustomerFeedViewingEntity>;
  private _task: TaskModel;
  private previousEntityId: string;
  private taskSource: BehaviorSubject<TaskModel> =
    new BehaviorSubject<TaskModel>(this.task);
  private task$: Observable<TaskModel> = this.taskSource.asObservable();
  public projectProgress$: Observable<SubtasksProgress>;

  constructor(
    private drawerSidebarService: DrawerSidebarService,
    private entityUtilsService: EntityUtilsService,
    private windowPaneViewTaskService: WindowPaneViewTaskService,
    private sharedLayoutService: SharedLayoutService,
    private taskService: TaskService
  ) {}

  ngAfterViewInit(): void {
    this.drawerSidebarService
      .getResizeObserver('partial', this.tabGroup.tabNav)
      .pipe(untilDestroyed(this))
      .subscribe();

    this.sharedLayoutService.isCompact$
      .pipe(debounceTime(150), untilDestroyed(this))
      .subscribe(isMobile => {
        if (this.windowPaneViewTaskService.drawer) {
          isMobile.active
            ? this.tabGroup.deactivate()
            : this.tabGroup.activate();
        }
      });

    this.projectProgress$ = this.task$.pipe(
      switchMap(task =>
        task.children
          ? this.taskService.getProgress(task.$key)
          : task.parent
          ? this.taskService.getProgress(task.parent)
          : of(null)
      )
    );
  }

  onTabToggle(opened: boolean): void {
    this.windowPaneViewTaskService.drawer.toggle(opened);
  }
}
