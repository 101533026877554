<div class="flex flex-1 flex-col">
  <div class="label flex flex-row">
    <mat-icon *ngIf="matIcon" class="mat-icon-14 mr-2">
      {{ matIcon }}
    </mat-icon>
    {{ label }}
    <span class="tooltip" *ngIf="tooltip">
      <mat-icon class="mat-icon-14" *easeRichTooltip="tooltip">
        help_outline
      </mat-icon>
    </span>
  </div>
  <div class="value">
    {{ isNil(value) ? '--' : value }}
  </div>
  <div
    *ngIf="!isNil(differenceNumeric) || !isNil(differencePercentage)"
    class="difference flex flex-row"
  >
    <span
      class="difference-numeric"
      *ngIf="!isNil(differenceNumeric) && differenceNumeric !== 0"
    >
      {{ differenceNumeric }}
    </span>
    <span
      class="difference-percentage metric-background"
      *ngIf="!isNil(differencePercentage) && differencePercentage !== 0"
    >
      {{ differencePercentage }}%
    </span>
  </div>
  <div *ngIf="notes" class="notes">
    {{ notes }}
  </div>
</div>
