import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import 'firebase/database';
import { isInteger } from 'lodash-es';
import groupBy from 'lodash-es/groupBy';
import orderBy from 'lodash-es/orderBy';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  forkJoin,
  Observable,
  of
} from 'rxjs';
import { catchError, map, switchMap, shareReplay } from 'rxjs/operators';
import { EaseRequestOptions } from 'src/app/shared/base-http.service';

import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { UserSelected } from 'src/app/shared/user-selector/user-selector.interface';
import { AccountFlowJobData, JobType } from '../../jobs/job.interface';
import { ConfirmService } from '../../shared/confirm/confirm.service';
import { EntityStatusChange } from '../../shared/entity-status-selector/entity-status-selector.component';
import {
  ADWORDS_ACCOUNT_INVOICES_PATH,
  ANALYTICS_LINKS_PATH,
  BING_ACCOUNT_INVOICES_PATH,
  CUSTOMER_ACCOUNTS_PATH,
  CUSTOMER_ANALYTICS_LINKS_PATH,
  SCHEDULED_STATUSES_PATH
} from '../../shared/firebase-paths';
import { MappedCache } from '../../shared/mapped-cache';
import {
  AnalyticsPropertyResponse,
  DeepPartial,
  EntityMetadata
} from '../../shared/shared.interface';
import { SkSyncHttpService } from '../../shared/sksync-http.service';
import {
  firebaseJSON,
  getFirebaseAccountPath,
  getPathForAccountType,
  getSubscriptionsPathForAccountType
} from '../../shared/utils/functions';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from '../../shared/constants';
import { TaskService } from '../../tasks/task.service';
import { UserRolesService } from '../../users/user-roles/user-roles.service';
import { CustomerContactsService } from '../customer-contacts/customer-contacts.service';
import { CustomerRolesService } from '../customer-roles.service';
import {
  AccountBasicMeta,
  AccountInvoice,
  AccountScheduledStatus,
  AccountType,
  AdConversionActionsResponse,
  AdwordsAccessBase,
  AdwordsAccessDetails,
  AdwordsAccessUser,
  AdwordsAccount,
  AnalyticsEmailList,
  AnalyticsLink,
  AnalyticsLinkMeta,
  BingAccount,
  CallTrackingAccount,
  CallTrackingAccountInformation,
  CallTrackingAccountMeta,
  CustomerAccountAutoInvoice,
  CustomerAccountMeta,
  LsaAccount
} from './customer-accounts.interface';

@Injectable({ providedIn: 'root' })
export class CustomerAccountsService {
  public analyticsEmails: AnalyticsEmailList = {
    searchkingcanada: { suffix: '@gmail.com', visible: true },
    'searchkings.analytics': { suffix: '@gmail.com', visible: true },
    'searchkings.analytics2': { suffix: '@gmail.com', visible: true },
    manager1: { suffix: '@searchkings.ca', visible: true }
  };
  private viewingAccountSource = new BehaviorSubject<CustomerAccountMeta>(null);
  private accountsByCustomer = new MappedCache();
  private allAccounts = new MappedCache();
  public viewingAccount$ = this.viewingAccountSource.asObservable();
  public accountTypeOrder: string[] = ['adwords', 'lsa', 'bing'];

  constructor(
    private angularFire: FirebaseDbService,
    private confirmService: ConfirmService,
    private customerRolesService: CustomerRolesService,
    private customerContactsService: CustomerContactsService,
    private entityUtilsService: EntityUtilsService,
    private sksyncHttp: SkSyncHttpService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private taskService: TaskService,
    private userRolesService: UserRolesService
  ) {}

  set viewingAccount(meta: CustomerAccountMeta) {
    this.viewingAccountSource.next(meta);
  }

  /**
   * Create an account flow job
   *
   * @param formData Payload for accounts to be created/linked via account flow
   * @param flowType? Optional flow(job) type info for displaying
   * @returns Account flow job ID that can be monitored for progress
   */
  async create(
    formData: DeepPartial<AccountFlowJobData>,
    flowType?: JobType
  ): Promise<string> {
    const { jobId } = await firstValueFrom(
      this.sksyncHttp.post<DeepPartial<AccountFlowJobData>>(`/accounts/flow`, {
        ...formData,
        flowType
      })
    );

    return jobId;
  }

  update(
    { accountId, accountType }: CustomerAccountMeta,
    fields: any
  ): Promise<void> {
    return this.angularFire
      .object(`/${getPathForAccountType(accountType)}/${accountId}`)
      .update(firebaseJSON(fields));
  }

  getAll(customerId: string): Observable<any[]> {
    return this.getCustomerAccountMeta(customerId).pipe(
      switchMap((accountsMeta: CustomerAccountMeta[]) =>
        accountsMeta.length
          ? combineLatest(accountsMeta.map(meta => this.getAccount(meta)))
          : of([])
      )
    );
  }

  getAllGrouped(customerId: string): Observable<any> {
    return this.getCustomerAccountMeta(customerId).pipe(
      switchMap((accountsMeta: CustomerAccountMeta[]) =>
        accountsMeta.length
          ? combineLatest(accountsMeta.map(meta => this.getAccount(meta)))
          : of([])
      ),
      map(customerAccounts => groupBy(customerAccounts, '$accountType'))
    );
  }

  getAccountsOfTypes(
    customerId: string,
    accountTypes: string[]
  ): Observable<AccountType[]> {
    return this.getCustomerAccountMeta(customerId).pipe(
      map(accountsMeta =>
        accountsMeta.filter(meta => accountTypes.includes(meta.accountType))
      ),
      switchMap((accountsMeta: CustomerAccountMeta[]) =>
        accountsMeta.length
          ? combineLatest(accountsMeta.map(meta => this.getAccount(meta)))
          : of([])
      )
    );
  }

  getAdwordsAccounts(customerId: string): Observable<AdwordsAccount[]> {
    return this.accountsByCustomer.get(
      `${customerId}-adwords`,
      this.getAccountsOfTypes(customerId, ['adwords'])
    );
  }

  getInvoices(accountMeta: CustomerAccountMeta): Observable<AccountInvoice[]> {
    return this.angularFire
      .getList(
        `/${this.getPathForInvoice(accountMeta.accountType)}/${
          accountMeta.accountId
        }`
      )
      .pipe(
        map((invoices: AccountInvoice[]) =>
          orderBy(invoices, ['$key'], ['desc'])
        )
      );
  }

  private getPathForInvoice(accountType: string): string {
    switch (accountType) {
      case 'adwords':
        return ADWORDS_ACCOUNT_INVOICES_PATH;

      case 'bing':
        return BING_ACCOUNT_INVOICES_PATH;

      default:
        break;
    }
  }

  getAutoSendInvoice(accountMeta: CustomerAccountMeta): Observable<boolean> {
    return this.angularFire
      .getObject(
        `/${getFirebaseAccountPath(accountMeta.accountType)}/${
          accountMeta.accountId
        }/autoSendInvoice`
      )
      .pipe(map(value => (value.$exists() ? value.$value : null)));
  }

  async setAutoSendInvoice(
    account: CustomerAccountAutoInvoice,
    value: boolean
  ): Promise<boolean | void> {
    const { accountType, accountId, customerId, contactTypes } = account;

    if (value) {
      const allowToSet = await this.customerContactsService.hasContactTypes(
        customerId,
        contactTypes
      );

      if (!allowToSet) {
        this.matSnackBar.open(
          `There is no ${
            contactTypes.length
              ? contactTypes.join(' or ')
              : contactTypes.toString()
          } contact, please set one on the contacts page`,
          'Close',
          {
            duration: SNACKBAR_DURATION_ERROR
          }
        );

        return true;
      }
    }

    return this.angularFire
      .object(
        `/${getFirebaseAccountPath(accountType)}/${accountId}/autoSendInvoice`
      )
      .set(value);
  }

  getAccountUserRolesAsObject({ accountId, accountType }: CustomerAccountMeta) {
    return this.angularFire
      .getObject(`/${getPathForAccountType(accountType)}/${accountId}/roles`)
      .pipe(map(roles => (roles.$exists() ? roles : {})));
  }

  getAccountUserRoles({
    accountId,
    accountType
  }: CustomerAccountMeta): Observable<any[]> {
    return this.angularFire.getList(
      `/${getPathForAccountType(accountType)}/${accountId}/roles`
    );
  }

  setAccountUserRole(
    { accountId, accountType }: CustomerAccountMeta,
    roleKey: string,
    userId: string
  ): Promise<void> {
    return this.angularFire
      .object(
        `/${getPathForAccountType(accountType)}/${accountId}/roles/${roleKey}`
      )
      .set(userId);
  }

  assertRolesSet(accountMeta: CustomerAccountMeta): Promise<boolean> {
    return Promise.all([
      firstValueFrom(this.userRolesService.getForScope('account')),
      firstValueFrom(this.getAccountUserRoles(accountMeta))
    ]).then(([availableRoles, accountRoles]) =>
      availableRoles.every(availableRole =>
        accountRoles.some(
          accountRole => accountRole.$key === availableRole.$key
        )
      )
    );
  }

  getAccountStatus({
    accountId,
    accountType
  }: CustomerAccountMeta): Observable<string> {
    return this.angularFire
      .getObject(`/${getPathForAccountType(accountType)}/${accountId}/status`)
      .pipe(map(value => (value.$exists() ? value.$value : null)));
  }

  async setAccountStatus(
    customerId: string,
    { accountId, accountType }: CustomerAccountMeta,
    { oldStatus, newStatus }: EntityStatusChange
  ): Promise<boolean> {
    if (oldStatus === newStatus) {
      return false;
    }

    let allRolesSet = true;

    if (
      (oldStatus === 'ONLINE' || oldStatus === 'PAUSED') &&
      newStatus !== 'ONLINE' &&
      newStatus !== 'PAUSED'
    ) {
      const confirmResetResult = await this.confirmService.confirm(
        {
          title: 'Reset Account Checkup Tasks',
          message: `By updating the status to ${newStatus}, account checkup tasks will be reset the next time this account is set to ONLINE.`,
          confirmColor: 'primary',
          confirmText: 'Continue',
          cancelText: 'Cancel'
        },
        {
          disableClose: true
        }
      );

      if (!confirmResetResult?.confirm) {
        return false;
      }
    }

    if (newStatus === 'ONLINE') {
      allRolesSet = await this.assertRolesSet({
        accountId,
        accountType
      });
    }

    if (!allRolesSet) {
      const confirmResult = await this.confirmService.confirm(
        {
          title: 'User Roles Not Set',
          message:
            'All user roles must be set before you can turn this account online.',
          confirmColor: 'primary',
          confirmText: 'Go To Settings',
          cancelText: 'Cancel'
        },
        {
          disableClose: true
        }
      );

      confirmResult.confirm &&
        this.router.navigate([
          '/customers',
          customerId,
          'accounts',
          accountType,
          accountId,
          'settings'
        ]);

      return false;
    }

    /**
     * Set the new account status
     * Firebase function will take care of onlineAt field
     */
    await this.angularFire
      .object(`/${getPathForAccountType(accountType)}/${accountId}`)
      .update({
        status: newStatus
      });

    /**
     * Perform some post-status-update changes based on account type
     */
    switch (accountType) {
      case 'lsa':
        await this.setLsaStatus(customerId, accountId, {
          oldStatus,
          newStatus
        });
        break;
    }

    return true;
  }

  public async setLsaStatus(
    customerId: string,
    accountId: string,
    { oldStatus, newStatus }: EntityStatusChange
  ): Promise<void> {
    if (newStatus === 'ONLINE' && oldStatus !== 'PAUSED') {
      const confirmResult = await this.confirmService.confirm(
        {
          title: 'Create GLS Account Launch task?',
          message: 'Would you like to create a GLS account launch task?',
          confirmColor: 'primary',
          confirmText: 'Create Task',
          cancelText: 'Skip'
        },
        {
          disableClose: true
        }
      );

      if (confirmResult.confirm) {
        const customerRoles = await firstValueFrom(
          this.customerRolesService.getRolesAsObject(customerId)
        );
        const accountRoles = await firstValueFrom(
          this.getAccountUserRolesAsObject({
            accountId,
            accountType: 'lsa'
          })
        );

        /**
         * Assign both customer and account managers
         */
        const assigned: UserSelected[] = [];
        accountRoles['manager'] &&
          assigned.push({ userId: accountRoles['manager'], method: 'direct' });
        // avoid assigning duplicates
        customerRoles['relationshipManager'] &&
          customerRoles['relationshipManager'] !== accountRoles['manager'] &&
          assigned.push({
            userId: customerRoles['relationshipManager'],
            method: 'direct'
          });

        /**
         * TODO: Don't hardcode the board/list ids
         */
        const newTaskId = await this.taskService.create({
          accountType: 'lsa',
          entityId: accountId,
          entityName: await this.entityUtilsService.getEntityField(
            { entityId: accountId, entityType: 'account', accountType: 'lsa' },
            'name'
          ),
          checklists: {
            glsReview1: {
              name: 'GLS Kickoff'
            }
          },
          entityType: 'account',
          assigned,
          board: '-JP_v-ziIQwTAn5jcC9u',
          list: '-Kz-a5Smx1KzqbFdJ5ap',
          name: 'GLS Account Launch'
        });

        const snackBarRef = this.matSnackBar.open(
          'GLS Launch Task Created',
          'Go To Task',
          {
            duration: SNACKBAR_DURATION_SUCCESS
          }
        );

        firstValueFrom(snackBarRef.afterDismissed()).then(
          dismissResult =>
            dismissResult.dismissedByAction &&
            this.router.navigate(['/tasks', newTaskId])
        );
      }
    }
  }

  getScheduledStatus(scheduleId: string): Observable<AccountScheduledStatus> {
    return this.angularFire.getObject(
      `/${SCHEDULED_STATUSES_PATH}/${scheduleId}`
    );
  }

  getScheduledStatusesForStatus(
    meta: EntityMetadata,
    status: string
  ): Observable<AccountScheduledStatus[]> {
    return this.angularFire.getList(`/${SCHEDULED_STATUSES_PATH}`, ref =>
      ref
        .orderByChild('combinedFields')
        .equalTo(
          `${meta.entityType}_${meta.accountType}_${meta.entityId}_${status}`
        )
    );
  }

  getAllScheduledStatuses(
    meta: EntityMetadata
  ): Observable<AccountScheduledStatus[]> {
    return combineLatest([
      this.getScheduledStatusesForStatus(meta, 'PAUSED'),
      this.getScheduledStatusesForStatus(meta, 'SCHEDULED')
    ]).pipe(
      map(([pausedStatuses, scheduledStatuses]) =>
        orderBy([...pausedStatuses, ...scheduledStatuses], ['startAt', 'asc'])
      )
    );
  }

  createScheduledStatus(
    settings: AccountScheduledStatus
  ): Observable<{ scheduleId: string }> {
    return this.sksyncHttp.post(`/accounts/schedule-status`, settings);
  }

  /**
   * This checks if the potential new schedule is overlapping any of the current account
   * schedules. If a current schedule is actively edited, then that schedule is treated as the
   * potential new schedule and will be checked to the rest.
   *
   * @param meta
   * @param newSchedule
   * @param editingScheduleId Optional. Provide this when an existing schedule is actively edited.
   * @returns true if the potential new schedule overlaps with any of the current schedules. Otherwise, returns false.
   */
  async isScheduleOverlapping(
    meta: EntityMetadata,
    newSchedule: AccountScheduledStatus,
    editingScheduleId?: string
  ): Promise<boolean> {
    let currentSchedules = await firstValueFrom(
      this.getAllScheduledStatuses(meta)
    );

    if (editingScheduleId) {
      currentSchedules = currentSchedules.filter(
        schedule => schedule.$key !== editingScheduleId
      );
    }

    /**
     * 1. Combines current pause schedules with the potential new schedule into one array
     * 2. Sorts the array by startAt (ascending)
     * 3. Iterates through the array and checks if a schedule's endAt happens after or is equal to the next schedule's startAt.
     *    If it does, then there's a schedule overlap.
     */
    const allSchedules = [...currentSchedules, newSchedule];
    const sortedSchedules = allSchedules.sort((scheduleA, scheduleB) =>
      scheduleA.startAt < scheduleB.startAt
        ? -1
        : scheduleA.startAt > scheduleB.startAt
        ? 1
        : 0
    );
    return sortedSchedules.some((schedule, index, schedules) => {
      if (index === schedules.length - 1) {
        return false;
      }
      return schedule.endAt >= schedules[index + 1].startAt;
    });
  }

  updateScheduledStatus(scheduleId: string, settings: AccountScheduledStatus) {
    return this.sksyncHttp.put(
      `/accounts/schedule-status/${scheduleId}`,
      settings
    );
  }

  pauseScheduledStatus(scheduleId: string) {
    return this.sksyncHttp.put(
      `/accounts/schedule-status/${scheduleId}/pause`,
      {}
    );
  }

  unpauseScheduledStatus(scheduleId: string) {
    return this.sksyncHttp.put(
      `/accounts/schedule-status/${scheduleId}/unpause`,
      {}
    );
  }

  deleteScheduledStatus(scheduleId: string) {
    return this.sksyncHttp.delete(`/accounts/schedule-status/${scheduleId}`);
  }

  getAdwordsConversionActions(
    adwordsId: string,
    cacheBust: boolean = true
  ): Observable<AdConversionActionsResponse> {
    return this.sksyncHttp
      .get<AdConversionActionsResponse>(
        `/adwords/${adwordsId}/conversionActions/list`,
        null,
        {
          displayError: false,
          cacheBust
        }
      )
      .pipe(catchError(() => of({} as AdConversionActionsResponse)));
  }

  syncAdwordsConversionActions(
    adwordsId: string,
    actions: string[]
  ): Observable<void> {
    return this.sksyncHttp.post<void>(
      `/adwords/${adwordsId}/conversionActions/sync`,
      actions
    );
  }

  getLsaAccounts(customerId: string): Observable<LsaAccount[]> {
    return this.accountsByCustomer.get(
      `${customerId}-lsa`,
      this.getAccountsOfTypes(customerId, ['lsa'])
    );
  }

  getBingAccounts(customerId: string): Observable<BingAccount[]> {
    return this.accountsByCustomer.get(
      `${customerId}-bing`,
      this.getAccountsOfTypes(customerId, ['bing'])
    );
  }

  getCallTrackingAccounts(
    customerId: string
  ): Observable<CallTrackingAccountMeta[]> {
    return this.accountsByCustomer
      .get(
        `${customerId}-callTracking`,
        this.getAccountsOfTypes(customerId, ['callTracking'])
      )
      .pipe(
        switchMap((allAccounts: CallTrackingAccount[]) => {
          const observable$ = allAccounts?.length
            ? forkJoin(
                allAccounts.map(account =>
                  this.getCallTrackingAccountInformation(account.$key, {
                    displayError: false,
                    throwError: true
                  }).pipe(catchError(() => of(null)))
                )
              )
            : of([]);

          return observable$.pipe(
            map(accountsInformation => {
              accountsInformation?.forEach(accountInfo => {
                const accountIndex = allAccounts.findIndex(
                  account => account.$key === `${accountInfo?.id}`
                );

                /**
                 * In case if SKSync has no result(empty or error),
                 * always merge with Firebase basic result
                 */
                if (isInteger(accountIndex)) {
                  allAccounts[accountIndex] = {
                    ...allAccounts[accountIndex],
                    ...accountInfo
                  };
                }
              });

              return allAccounts;
            })
          );
        })
      );
  }

  getCallTrackingAccountInformation(
    accountId: number | string,
    requestOptions?: EaseRequestOptions
  ): Observable<CallTrackingAccountInformation> {
    return this.sksyncHttp
      .get(`/call-tracking-accounts/${accountId}`, {}, requestOptions)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  getAccount<T = AccountBasicMeta>(meta: CustomerAccountMeta): Observable<T> {
    return this.allAccounts.get(
      `${meta.accountType}-${meta.accountId}`,
      this.angularFire
        .getObject(
          `/${getPathForAccountType(meta.accountType)}/${meta.accountId}`
        )
        .pipe(map(account => this.applyAccountProps(account, meta)))
    );
  }

  getCustomerAccountMeta(
    customerId: string
  ): Observable<CustomerAccountMeta[]> {
    return this.angularFire.getList(`/${CUSTOMER_ACCOUNTS_PATH}/${customerId}`);
  }

  getCustomerAccountMetaForAccount(
    customerId: string,
    accountId: any
  ): Promise<CustomerAccountMeta> {
    return firstValueFrom(
      this.angularFire
        .getList(`/${CUSTOMER_ACCOUNTS_PATH}/${customerId}`, ref =>
          ref.orderByChild('accountId').equalTo(accountId)
        )
        .pipe(
          map((allMeta: CustomerAccountMeta[]) =>
            allMeta.find(meta => meta.accountId === meta.accountId)
          )
        )
    );
  }

  saveAnalyticsLink(customerId: string, newLink: AnalyticsLink): Promise<void> {
    /**
     * Check whether an AnalyticsLink with the combination of IDs is already
     * in firebase. If it is, simply associate it with this customer.
     *
     * If not, create the new link, then associate it with this customer.
     */
    return firstValueFrom(
      this.angularFire
        .getList(`/${ANALYTICS_LINKS_PATH}`, ref =>
          ref.orderByChild('combinedIds').equalTo(newLink.combinedIds)
        )
        .pipe(
          switchMap((existingLinks: AnalyticsLink[]) => {
            if (existingLinks.length) {
              return of(existingLinks[0].$key);
            } else {
              return this.angularFire
                .list(`/${ANALYTICS_LINKS_PATH}`)
                .push(newLink)
                .then(pushedLinkRef => pushedLinkRef.key);
            }
          }),
          switchMap(linkId =>
            this.angularFire
              .object(
                `/${CUSTOMER_ANALYTICS_LINKS_PATH}/${customerId}/${linkId}`
              )
              .set(true)
          )
        )
    );
  }

  getAnalyticsLinks(customerId: string): Observable<AnalyticsLinkMeta[]> {
    return this.accountsByCustomer.get(
      `${customerId}-analyticsLinks`,
      this.angularFire
        .getList(`/${CUSTOMER_ANALYTICS_LINKS_PATH}/${customerId}`)
        .pipe(
          switchMap((linkMeta: { $key: string }[]) =>
            linkMeta?.length
              ? combineLatest(
                  linkMeta.map(meta => this.getAnalyticsLink(meta.$key))
                )
              : of([])
          ),
          switchMap(links => {
            const observable$ = links?.length
              ? forkJoin(
                  links.map(link =>
                    this.getAnalyticsLinkInformation(
                      link.email,
                      link.propertyId,
                      {
                        displayError: false,
                        throwError: true
                      }
                    ).pipe(catchError(() => of(null)))
                  )
                )
              : of([]);

            return observable$.pipe(
              map(propertyInformation => {
                propertyInformation?.forEach(propertyInfo => {
                  const propertyIndex = links.findIndex(
                    account => account.propertyId === propertyInfo?.propertyId
                  );

                  /**
                   * In case if SKSync has no result(empty or error),
                   * always merge with Firebase basic result
                   */
                  if (isInteger(propertyIndex)) {
                    links[propertyIndex] = {
                      ...links[propertyIndex],
                      ...propertyInfo,
                      $key: links[propertyIndex]?.$key
                    };
                  }
                });

                return links;
              })
            );
          })
        )
    );
  }

  getAnalyticsLink(linkId: string): Observable<AnalyticsLink> {
    return this.angularFire.getObject(`/${ANALYTICS_LINKS_PATH}/${linkId}`);
  }

  getAnalyticsLinkInformation(
    email: string,
    propertyId: string,
    requestOptions?: EaseRequestOptions
  ): Observable<AnalyticsPropertyResponse> {
    return this.sksyncHttp
      .get(`/analytics/${email}/properties/${propertyId}`, {}, requestOptions)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  setCustomer(customerId: string, meta: CustomerAccountMeta): Promise<any> {
    return Promise.all([
      this.angularFire
        .list(`/${CUSTOMER_ACCOUNTS_PATH}/${customerId}`)
        .push(firebaseJSON(meta)),
      this.angularFire
        .object(`${getPathForAccountType(meta.accountType)}/${meta.accountId}`)
        .update({ customerId })
    ]);
  }

  moveToCustomer(
    sourceCustomerId: string,
    destinationCustomerId: string,
    meta: CustomerAccountMeta
  ): Promise<any> {
    return this.setCustomer(destinationCustomerId, meta).then(async () => {
      if (!meta.$key) {
        meta = await this.getCustomerAccountMetaForAccount(
          sourceCustomerId,
          meta.accountId
        );
      }

      this.angularFire
        .list(`/${CUSTOMER_ACCOUNTS_PATH}/${sourceCustomerId}/${meta.$key}`)
        .remove();
    });
  }

  mergeCustomers(
    sourceCustomerId: string,
    destinationCustomerId: string
  ): Promise<any> {
    return firstValueFrom(this.getCustomerAccountMeta(sourceCustomerId)).then(
      allMeta =>
        Promise.all(
          allMeta.map(meta =>
            this.moveToCustomer(sourceCustomerId, destinationCustomerId, meta)
          )
        )
    );
  }

  async removeAll(customerId: string): Promise<void> {
    if (customerId) {
      const metas = await firstValueFrom(
        this.getCustomerAccountMeta(customerId)
      );
      await Promise.all(
        metas.map(meta =>
          this.angularFire
            .object(
              `/${getPathForAccountType(meta.accountType)}/${meta.accountId}`
            )
            .remove()
        )
      );

      const analyticsLinks = await firstValueFrom(
        this.getAnalyticsLinks(customerId)
      );

      await Promise.all(
        analyticsLinks.map(link =>
          this.angularFire
            .object(`/${CUSTOMER_ANALYTICS_LINKS_PATH}/${link.$key}`)
            .remove()
        )
      );

      await this.angularFire
        .object(`/${CUSTOMER_ACCOUNTS_PATH}/${customerId}`)
        .remove();
    }
  }

  private applyAccountProps(account: any, meta: CustomerAccountMeta) {
    return Object.assign({}, account, {
      $key: account.$key,
      $accountType: meta.accountType
    });
  }

  async subscribeUser(subscriber: CustomerAccountMeta): Promise<void> {
    await this.angularFire
      .object(
        `/${getSubscriptionsPathForAccountType(subscriber.accountType)}/${
          subscriber.accountId
        }/${subscriber.$key}`
      )
      .set(true);
  }

  async unsubscribeUser(subscriber: CustomerAccountMeta): Promise<void> {
    await this.angularFire
      .object(
        `/${getSubscriptionsPathForAccountType(subscriber.accountType)}/${
          subscriber.accountId
        }/${subscriber.$key}`
      )
      .remove();
  }

  getAccountSubscriptions(account: CustomerAccountMeta) {
    return this.angularFire.getObject(
      `/${getSubscriptionsPathForAccountType(account.accountType)}/${
        account.accountId
      }`
    );
  }

  getAccountsByUserRole(
    roleId: string,
    userId: string,
    accountType: string
  ): Observable<AccountBasicMeta[]> {
    return this.angularFire
      .getList(`/${getFirebaseAccountPath(accountType)}`, ref =>
        ref.orderByChild(`roles/${roleId}`).equalTo(userId)
      )
      .pipe(
        map(accounts =>
          accounts.map(account => {
            account.$accountType = accountType;
            return account;
          })
        )
      );
  }

  /**
   * Retrieves user access / invitation details for Adwords / LSA accounts
   *
   * @param accountId Adwords (or LSA) account ID
   * @param cacheBust Whether to pull a fresh set of details from the server
   * @returns AdwordsAccessDetails containing invitations and current user details
   */
  getAdwordsAccess(
    accountId: string,
    cacheBust: boolean
  ): Observable<AdwordsAccessDetails> {
    return this.sksyncHttp.get(`/adwords/${accountId}/access`, null, {
      cacheBust
    });
  }

  /**
   * Updates access details for a given account + user combination
   *
   * @param accountId Adwords (or LSA) account ID
   * @param access A partial AdwordsAccessUser containing the new access_role and resource_name to modify
   * @returns void
   */
  updateAdwordsAccess(
    accountId: string,
    access: Pick<AdwordsAccessUser, 'access_role' | 'resource_name'>,
    displayError = false
  ): Observable<void> {
    return this.sksyncHttp.put(`/adwords/${accountId}/access`, access, {
      displayError
    });
  }

  /**
   * Removes a user from a given account
   *
   * @param accountId Adwords (or LSA) account ID
   * @param resourceName Resource name of the user to remove from the account
   * @returns void
   */
  removeAdwordsAccess(
    accountId: string,
    resourceName: string
  ): Observable<void> {
    return this.sksyncHttp.delete(`/adwords/${accountId}/access`, {
      body: { resource_name: resourceName }
    });
  }

  /**
   * Invites a new user to a given account
   *
   * @param accountId Adwords (or LSA) account ID
   * @param invitation A partial AdwordsAccessInvitation containing the new access_role and email_address to invite
   * @returns void
   */
  createAdwordsInvitation(
    accountId: string,
    invitation: Partial<AdwordsAccessBase>
  ): Observable<void> {
    return this.sksyncHttp.post(
      `/adwords/${accountId}/access/invitations`,
      invitation,
      {
        throwError: false
      }
    );
  }

  /**
   * Revokes an invitation for a given account + user combination
   *
   * @param accountId Adwords (or LSA) account ID
   * @param resourceName Resource name of the invite to remove from the account
   * @returns void
   */
  removeAdwordsInvitation(
    accountId: string,
    resourceName: string
  ): Observable<void> {
    return this.sksyncHttp.delete(`/adwords/${accountId}/access/invitations`, {
      body: { resource_name: resourceName }
    });
  }

  /**
   * Remove link for a Google Analytics account
   *
   * @param customerId Customer ID
   * @param fbAnalyticId Firebase Key linked to the Google Analytics account
   * @returns Promise<void>
   */
  async removeAnalyticsLink(
    customerId: string,
    fbAnalyticId: string
  ): Promise<void> {
    return this.angularFire
      .object(`/${CUSTOMER_ANALYTICS_LINKS_PATH}/${customerId}/${fbAnalyticId}`)
      .remove();
  }
}
