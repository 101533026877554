import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { FundingSummaryBreakdownItem } from '../../funding.interface';

@Component({
  selector: 'ease-funding-summary-breakdown',
  templateUrl: './funding-summary-breakdown.component.html',
  styleUrls: ['./funding-summary-breakdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundingSummaryBreakdownComponent implements OnInit {
  @Input() breakdownData: FundingSummaryBreakdownItem[];
  public displayedColumns: string[] = ['name', 'value'];

  constructor() {}

  ngOnInit(): void {}

  getTotalCost(): number {
    return this.breakdownData
      .map(t => t.value)
      .reduce((acc, value) => acc + value, 0);
  }
}
