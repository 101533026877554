import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { RRule } from 'rrule';
import * as moment from 'moment';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import {
  firebaseJSON,
  getBumpedRRuleForTask,
  getFormValueForTask,
  isEntityMetadataSame
} from '../../shared/utils/functions';
import { TASKS_SCHEDULED_PATH } from '../../shared/firebase-paths';
import { EntityMetadata } from '../../shared/shared.interface';
import {
  ScheduledTask,
  ScheduledTaskDetails
} from './scheduled-task.interface';

@Injectable({ providedIn: 'root' })
export class ScheduledTaskService {
  constructor(private angularFire: FirebaseDbService) {}

  get(id: string): Observable<ScheduledTask> {
    return this.angularFire
      .getObject(`/${TASKS_SCHEDULED_PATH}/${id}`)
      .pipe(map((task: ScheduledTask) => this.applyDisplayProps(task)));
  }

  getForUser(userId: string): Observable<ScheduledTask[]> {
    return this.angularFire
      .getList(`/${TASKS_SCHEDULED_PATH}`, ref =>
        ref.orderByChild(`assigned/${userId}`).equalTo(true)
      )
      .pipe(
        map((tasks: ScheduledTask[]) =>
          tasks.map(task => {
            task = this.applyDisplayProps(task);
            return Object.assign({}, task, { $key: task.$key });
          })
        )
      );
  }

  getForEntity(meta: Partial<EntityMetadata>): Observable<ScheduledTask[]> {
    return this.angularFire
      .getList(`/${TASKS_SCHEDULED_PATH}`, ref =>
        ref.orderByChild('entityId').equalTo(meta.entityId)
      )
      .pipe(
        map((tasks: ScheduledTask[]) =>
          tasks
            .filter(({ entityId, entityType, accountType }) =>
              isEntityMetadataSame(meta, { entityId, entityType, accountType })
            )
            .map(task => {
              task = this.applyDisplayProps(task);
              return Object.assign({}, task, { $key: task.$key });
            })
        )
      );
  }

  moveToEntity(
    oldEntityMeta: Partial<EntityMetadata>,
    newEntityMeta: Partial<EntityMetadata>
  ): Promise<any> {
    return firstValueFrom(this.getForEntity(oldEntityMeta)).then(
      scheduledTasks => {
        const scheduledTasksUpdatePromise = scheduledTasks.map(scheduledTask =>
          this.angularFire
            .object(`/${TASKS_SCHEDULED_PATH}/${scheduledTask.$key}`)
            .update(newEntityMeta)
        );

        return Promise.all([scheduledTasksUpdatePromise]);
      }
    );
  }

  update(taskId: string, task: ScheduledTask): Promise<void> {
    return this.angularFire
      .object(`/${TASKS_SCHEDULED_PATH}/${taskId}`)
      .update(firebaseJSON(task));
  }

  remove(taskId: string): Promise<void> {
    return this.angularFire
      .object(`/${TASKS_SCHEDULED_PATH}/${taskId}`)
      .remove();
  }

  unassign(taskId: string, userId: string): Promise<void> {
    return this.angularFire
      .object(`/${TASKS_SCHEDULED_PATH}/${taskId}/assigned/${userId}`)
      .remove();
  }

  assign(taskId: string, userId: string): Promise<void> {
    return this.angularFire
      .object(`/${TASKS_SCHEDULED_PATH}/${taskId}/assigned/${userId}`)
      .set(true);
  }

  applyDisplayProps(task: ScheduledTask): ScheduledTask {
    task.$displayStatus = task.status ? Object.keys(task.status) : [];
    task.$displayPhase = task.phase ? Object.keys(task.phase) : [];
    task.$displayAssigned = task.assigned ? Object.keys(task.assigned) : [];
    return task;
  }

  getFormValueForTask(id: string) {
    return this.get(id).pipe(map(task => getFormValueForTask(task)));
  }

  sortScheduledTasks(tasks: ScheduledTaskDetails[]) {
    return tasks.sort((taskA, taskB) => {
      const scheduledNextA = this.getScheduledNextDate(taskA);
      const scheduledNextB = this.getScheduledNextDate(taskB);

      return this.dateComparator(scheduledNextA, scheduledNextB);
    });
  }

  /**
   * gets the next scheduled date for a task
   *
   * @param task
   */
  getScheduledNextDate(task: ScheduledTaskDetails): moment.Moment {
    let scheduledNext: moment.Moment;

    if (!task.scheduledNext) {
      const bumpedRrule = getBumpedRRuleForTask(task.rrule);
      const rruleA = RRule.fromString(bumpedRrule);
      scheduledNext = moment(rruleA.options.dtstart);
      task.rruleOptions = rruleA.options;
      task.scheduledNext = scheduledNext;
    } else {
      scheduledNext = task.scheduledNext;
    }
    return scheduledNext;
  }

  /**
   * compares two dates from tasks in order to sort in ascending order
   *
   * @param scheduledNextA
   * @param scheduledNextB
   */
  dateComparator(
    scheduledNextA: moment.Moment,
    scheduledNextB: moment.Moment
  ): number {
    switch (true) {
      case scheduledNextA === scheduledNextB:
        return 0;
      default:
        return scheduledNextA.isAfter(scheduledNextB) ? 1 : -1;
    }
  }
}
