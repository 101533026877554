import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared.module';
import { SharedFormModule } from '../shared-form.module';
import { SharedPipesModule } from '../shared-pipes.module';
import { ListSelectorModule } from '../list-selector/list-selector.module';
import { MaterialChipsInputModule } from '../material-chips-input/material-chips-input.module';
import { UserSelectorComponent } from './user-selector.component';

@NgModule({
  imports: [
    CommonModule,
    ListSelectorModule,
    SharedModule,
    SharedFormModule,
    SharedPipesModule,
    MaterialChipsInputModule
  ],
  declarations: [UserSelectorComponent],
  exports: [UserSelectorComponent]
})
export class UserSelectorModule {}
