<div
  class="checklist-checkbox my-2 flex w-full flex-row md:my-0.5"
  [ngClass]="{
    'checklist-checkbox--checked': group?.controls?.checked?.value
  }"
  [formGroup]="group"
>
  <mat-icon
    matTooltip="Checking this box will perform the action described automatically"
    *ngIf="templateOptions?.actionKey"
    class="action-icon"
    >offline_bolt</mat-icon
  >

  <div
    class="flex w-full flex-1 flex-col flex-wrap items-start justify-between md:flex-row md:items-center md:gap-2"
  >
    <mat-checkbox
      #checkbox
      [checked]="group?.controls?.checked?.value"
      [disabled]="group?.controls?.checked?.disabled"
      (click)="clickCheckbox(checkbox)"
      color="primary"
      class="mr-3 md:max-w-[60%]"
    >
      <span
        [ngClass]="{
          'line-through decoration-gray-600': group?.controls?.checked?.value
        }"
        [easeMarkdown]="templateOptions?.label"
      ></span
    ></mat-checkbox>
    <div
      class="checklist-meta -mt-4 !pr-0 md:mt-0 md:!self-center"
      [ngClass]="{ '!hidden': !group?.controls?.checked?.value }"
    >
      <div
        class="ml-6 flex items-center"
        [ngClass]="{ '!ml-[29px] md:ml-0': templateOptions?.actionKey }"
        *ngIf="group?.controls?.completedBy?.value"
      >
        <div>{{ group.controls.completedBy.value }}</div>
      </div>
      <div
        *ngIf="group?.controls?.completedAt?.value"
        class="flex items-center text-[11px] leading-snug text-gray-600"
      >
        <mat-icon class="mat-icon-12 mr-0.5 mb-px !hidden md:!block"
          >done</mat-icon
        >
        {{ group.controls.completedAt.value | amDateFormat: 'MMM D, h:mm a' }}
      </div>
    </div>

    <mat-form-field
      class="!w-full flex-[1] flex-shrink pl-6 md:pl-0"
      [floatLabel]="'never'"
    >
      <input
        formControlName="value"
        [placeholder]="templateOptions?.placeholder"
        matInput
        class="text-sm md:text-base"
      />
    </mat-form-field>
  </div>
</div>
