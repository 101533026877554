import { NgModule } from '@angular/core';

import { PopoverModule } from '../popover.module';
import { SharedFormModule } from '../../shared-form.module';
import { SharedModule } from '../../shared.module';
import { TaskStatusSelectorModule } from '../../../tasks/task-status/task-status-selector/task-status-selector.module';
import { TaskStatusPopoverComponent } from './task-status-popover.component';

@NgModule({
  imports: [
    PopoverModule,
    SharedModule,
    SharedFormModule,
    TaskStatusSelectorModule
  ],
  declarations: [TaskStatusPopoverComponent],
  exports: [TaskStatusPopoverComponent, PopoverModule]
})
export class TaskStatusPopoverModule {}
