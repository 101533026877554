import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SkSyncHttpService } from '../../shared/sksync-http.service';
import { CustomerLsaSummaryItem } from './customer-lsa-summary/customer-lsa-summary.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomerLsaSummaryService {
  constructor(private http: SkSyncHttpService) {}

  get(customerId: string): Observable<CustomerLsaSummaryItem[]> {
    return this.http.get(`/lsa/${customerId}`);
  }
}
