import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
  PathReference,
  QueryFn
} from '@angular/fire/compat/database';

import { debounceTime, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  ChildEvent,
  DataSnapshot
} from '@angular/fire/compat/database/interfaces';

export interface UnwrappedSnapshot {
  $key?: string;
  $exists?: () => boolean;
  $value?: any;
}

export interface ListAsObject<T> {
  [key: string]: EaseUnwrappedSnapshot<T>;
}

export type EaseUnwrappedSnapshot<T> = T & UnwrappedSnapshot;

@Injectable({ providedIn: 'root' })
export class FirebaseDbService {
  public database: firebase.database.Database;

  constructor(private angularFire: AngularFireDatabase) {
    this.database = this.angularFire.database;
  }

  list<T>(pathOrRef: PathReference, queryFn?: QueryFn): AngularFireList<T> {
    return this.angularFire.list(pathOrRef, queryFn);
  }

  object<T>(pathOrRef: PathReference): AngularFireObject<T> {
    return this.angularFire.object(pathOrRef);
  }

  getList<T = any>(
    pathOrRef: PathReference,
    queryFn?: QueryFn,
    events?: ChildEvent[]
  ): Observable<EaseUnwrappedSnapshot<T>[]> {
    return this.angularFire
      .list(pathOrRef, queryFn)
      .snapshotChanges(events)
      .pipe(
        debounceTime(50),
        map(actions => actions.map(action => unwrapMapFn(action.payload)))
      );
  }

  getObject<T = any>(
    pathOrRef: PathReference
  ): Observable<EaseUnwrappedSnapshot<T>> {
    return this.angularFire
      .object(pathOrRef)
      .snapshotChanges()
      .pipe(map(action => unwrapMapFn(action.payload)));
  }

  createPushId(): string {
    return this.angularFire.createPushId();
  }

  async getServerTimestamp(): Promise<number> {
    const offsetSnap = await this.database
      .ref()
      .child('.info/serverTimeOffset')
      .once('value');

    return new Date().getTime() + offsetSnap.val();
  }
}

const unwrapMapFn = (snapshot: DataSnapshot) => {
  const val = snapshot.val();
  let unwrapped = !isNil(val) ? val : { $value: null };

  if (/string|number|boolean/.test(typeof unwrapped)) {
    unwrapped = {
      $value: unwrapped
    };
  }

  Object.defineProperty(unwrapped, '$key', {
    value: snapshot.ref.key,
    enumerable: false
  });

  Object.defineProperty(unwrapped, '$exists', {
    value: () => snapshot.exists(),
    enumerable: false
  });

  return unwrapped;
};

const isNil = (obj: any): boolean => obj === undefined || obj === null;
