import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { AgRendererComponent } from 'ag-grid-angular';

import { Account, ButMetric } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';

@Component({
  selector: 'ease-budget-utilization-cell',
  templateUrl: './budget-utilization-cell.component.html',
  styleUrls: ['./budget-utilization-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetUtilizationCellComponent implements AgRendererComponent {
  private account: Account;
  public budgetUtilization: ButMetric;
  public accountBudget: number;
  public isGroup: boolean;
  public params: ICellRendererParams;

  constructor(private accountDashboardService: AccountDashboardService) {}

  agInit(params: ICellRendererParams): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    this.account = params.data;
    this.isGroup = params.node.group;
    if (this.account) {
      this.budgetUtilization = this.account.butMetric;
      this.accountBudget = this.account.budget;
      return true;
    }
  }

  openBudgetManager() {
    this.accountDashboardService.openBudgetManagerDialog(
      this.account.accountId,
      this.account.accountType,
      this.account.accountName
    );
  }
}
