<ease-sheet class="popover">
  <ease-popover-header>Move Task</ease-popover-header>
  <ease-sheet-content>
    <div class="form-group">
      <mat-form-field>
        <mat-select
          placeholder="Board"
          [(ngModel)]="selectedBoardId"
          (ngModelChange)="changeBoard()"
        >
          <mat-option
            *ngFor="let board of boards$ | async"
            [value]="board.$key"
            >{{ board.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select placeholder="List" [(ngModel)]="selectedListId">
          <mat-option
            *ngFor="let list of selectedBoard?.$lists"
            [value]="list.$key"
            >{{ list.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </ease-sheet-content>
  <ease-sheet-footer>
    <button (click)="moveTask()" color="primary" mat-button>Move Task</button>
  </ease-sheet-footer>
</ease-sheet>
