import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[easeStopEvent]'
})
export class StopEventDirective implements OnInit, OnDestroy {
  @Input()
  easeStopEvent: string = 'click';

  constructor(private elementRef: ElementRef) {}

  eventHandler = (event: Event) => event.stopPropagation();

  ngOnInit() {
    this.elementRef.nativeElement.addEventListener(
      this.easeStopEvent || 'click',
      this.eventHandler
    );
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener(
      this.easeStopEvent,
      this.eventHandler
    );
  }
}
