<ease-add-funding
  class="flex h-full min-h-0 flex-1 flex-col"
  (addedFunding)="fundingCreated()"
  [data]="data$ | async"
></ease-add-funding>

<ng-template #windowSubtitleTemplate>
  <div class="flex flex-row items-center">
    <mat-icon class="mat-icon-16 mr-1 inline-flex flex-none"
      >person_pin</mat-icon
    >
    <div class="truncate text-xs">
      {{ this.customerName$ | async }}
    </div>
  </div>
</ng-template>
