<ng-container *ngIf="data; else empty">
  <div class="mt-5 mb-2 font-bold">Filters</div>
  <div class="flex flex-wrap gap-1">
    <ng-container
      *ngIf="data.quickFilter || (data.filter | json) !== '{}'; else noFilters"
    >
      <!-- User preset -->
      <div
        *ngIf="data.preset"
        class="flex rounded-full border border-solid border-gray-700 p-2 py-1"
      >
        <div class="mr-2 leading-3 text-gray-800">
          <mat-icon class="mat-icon mat-icon-20">visibility</mat-icon>
        </div>
        <div>{{ data.preset.name }}</div>
      </div>
      <!-- Quick Filter search string -->
      <div
        *ngIf="data.quickFilter"
        class="flex rounded-full border border-solid border-gray-700 p-2 py-1"
      >
        <div class="mr-2 leading-3 text-gray-800">
          <mat-icon class="mat-icon mat-icon-20">search</mat-icon>
        </div>
        <div>{{ data.quickFilter }}</div>
      </div>
      <!-- the rest of the filters -->
      <div
        *ngFor="let filter of data.filter | keyvalue"
        class="flex flex-wrap rounded-full border border-solid border-gray-300 p-2 py-1"
      >
        <div class="mr-2 text-gray-600">{{ filter.key | titlecase }}</div>
        <ng-container [ngSwitch]="filter.value.filterType">
          <mat-chip-list *ngSwitchCase="'set'" [selectable]="false">
            <ng-container
              *ngFor="let value of filter.value['values']; index as i"
            >
              <mat-chip
                *ngIf="i < MAX_FILTERS"
                class="!mx-[2px] !min-h-[21px] !bg-gray-300 !py-[2px] !px-2 !font-normal"
              >
                {{ value }}
              </mat-chip>
            </ng-container>
            <div
              *ngIf="filter.value['values'].length > MAX_FILTERS"
              class="py-[2px] px-2 italic text-gray-600"
            >
              and {{ filter.value['values'].length - MAX_FILTERS }} more...
            </div>
          </mat-chip-list>
          <mat-icon
            *ngSwitchDefault
            matTooltip="Preview unavailable"
            class="mat-icon mat-icon-20 text-gray-700"
          >
            error_outline
          </mat-icon>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <ag-grid-angular
    #agGrid
    [rowData]="rowData"
    [gridOptions]="gridOptions"
    [columnDefs]="columnDefs"
    class="ag-theme-material mt-5 h-40"
    [ngClass]="{ 'h-64': rowData.length }"
  >
  </ag-grid-angular>
</ng-container>

<ng-container *ngIf="state$ | async as state">
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="'MODIFYING'">
      <button
        *ngIf="
          gridTargetingService.getActiveWindow()?.id === windowPane.id;
          else notActive
        "
        (click)="cancel()"
        class="!mt-5 w-full !bg-red-600 !text-white"
        mat-flat-button
      >
        <mat-icon class="mat-icon-20 mr-2 w-full">close</mat-icon>
        Cancel modifying filters
      </button>
    </ng-container>
    <button
      *ngSwitchDefault
      (click)="modify()"
      class="!mt-5 w-full"
      color="primary"
      mat-flat-button
    >
      <mat-icon class="mat-icon-20 mr-2 w-full">edit</mat-icon>
      {{
        rowData?.length
          ? 'Modify Filters'
          : 'Add ' + entity + 's via the ' + entity + ' table'
      }}
    </button>
  </ng-container>
</ng-container>

<ng-template #noFilters>
  <ease-state-message
    [minHeight]="30"
    [message]="'No filters set'"
    class="w-[initial] flex-1 rounded border border-dashed border-gray-400"
  ></ease-state-message>
</ng-template>

<ng-template #notActive>
  <button class="!mt-5 w-full" mat-flat-button disabled>
    <mat-icon class="mat-icon-20 mr-2 w-full">error_outline</mat-icon>
    Another window is actively modifying the filters
  </button>
</ng-template>

<ng-template #empty>
  <ease-state-message
    [minHeight]="120"
    [message]="'No ' + entity + 's added yet'"
    class="mt-5 w-[initial] flex-1 rounded border border-dashed border-gray-400"
  ></ease-state-message>
</ng-template>
