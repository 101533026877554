<div *ngIf="selectedUsers$ | async as selectedUsers" class="group relative">
  <ng-container *ngIf="isDragging$ | async as isDragging">
    <div
      *ngIf="isDragging.active"
      class="pointer-events-none absolute top-0 bottom-0 left-0 right-0 z-5 flex items-center rounded border border-dashed border-gray-400 bg-white px-3 text-sm text-gray-600 transition-colors group-hover:border-blue-500 group-hover:bg-blue-50"
    >
      <div>
        <ng-container
          *ngIf="isDragging.containerId === containerId; else target"
        >
          Drop here to cancel. Drop elsewhere to remove.
        </ng-container>
        <ng-template #target>
          Drop here to <span class="lowercase">{{ label }}.</span>
        </ng-template>
      </div>
    </div>

    <div
      *ngIf="sharedLayoutService.isCompact$ | async as isCompact"
      cdkDropList
      [cdkDropListDisabled]="isCompact.active || !enableDragNDrop"
      #container="cdkDropList"
      (cdkDropListDropped)="dropped($event)"
      (mouseenter)="isDragging.active && updateDragPosition(isDragging, true)"
      (mouseleave)="isDragging.active && updateDragPosition(isDragging, false)"
      class="flex flex-row flex-wrap items-center gap-2 py-2"
    >
      <ng-container *ngIf="selectedUserModels$ | async as users">
        <ease-avatar
          *ngFor="let user of users; trackBy: getUserId"
          [userId]="user.$key"
          [showTooltip]="!isDragging.active"
          [showInitials]="!isDragging.active"
          [status]="{
            mode: user?.mode
          }"
          [matMenuTriggerFor]="userMenu"
          [matMenuTriggerData]="{ userId: user.$key }"
          (click)="$event.stopPropagation()"
          cdkDrag
          [cdkDragData]="user"
          (cdkDragStarted)="dragStarted($event)"
          (cdkDragDropped)="dragDropped($event)"
          class="cursor-pointer rounded-full"
          [ngClass]="{
            'pointer-events-none':
              isDragging.active && isDragging.user?.$key !== user.$key,
            'cursor-grab': !isCompact.active && enableDragNDrop
          }"
        >
          <ng-template cdkDragPreview [matchSize]="true">
            <ease-avatar
              [userId]="user.$key"
              [showTooltip]="false"
              [showInitials]="true"
              [status]="{
                mode: user?.mode
              }"
              class="rounded-full"
              [ngClass]="{
                grayscale: isDragging.active && !isDragging.isOverContainer
              }"
            ></ease-avatar>
          </ng-template>
        </ease-avatar>

        <mat-menu #userMenu="matMenu">
          <ng-template matMenuContent let-userId="userId">
            <ng-template
              *ngIf="menu; else default"
              [ngTemplateOutlet]="menu"
              [ngTemplateOutletContext]="{ $implicit: userId }"
            ></ng-template>
            <ng-template #default>
              <button (click)="onDeselect(userId)" mat-menu-item>
                <mat-icon>close</mat-icon>
                <span>Remove</span>
              </button>
            </ng-template>
          </ng-template>
        </mat-menu>
      </ng-container>

      <div class="inline-flex flex-row gap-2 py-2">
        <button
          *ngIf="isSelf$ | async as isSelf"
          (click)="
            isSelf.selected ? removeSelf() : addSelf(); $event.stopPropagation()
          "
          [matTooltip]="isSelf.selected ? 'Remove self' : 'Add self'"
          [matTooltipDisabled]="isDragging.active"
          class="!h-8 !w-8 rounded-full"
          type="button"
          mat-icon-button
          mat-stroked-button
        >
          <mat-icon class="mat-icon-18">
            {{ isSelf.selected ? 'person_remove' : 'person_add_alt_1' }}
          </mat-icon>
        </button>

        <button
          (click)="userPopover.open(); $event.stopPropagation()"
          [satPopoverAnchor]="userPopover"
          [matTooltip]="label"
          [matTooltipDisabled]="isDragging.active"
          class="!h-8 !w-8 rounded-full"
          type="button"
          mat-icon-button
          mat-stroked-button
        >
          <mat-icon class="mat-icon-18">add</mat-icon>
        </button>
      </div>

      <sat-popover
        #userPopover
        verticalAlign="below"
        horizontalAlign="start"
        [hasBackdrop]="true"
      >
        <ease-select-user-popover
          *ngIf="userPopover.isOpen()"
          (selected)="onSelect($event)"
          (deselected)="onDeselect($event)"
          [selectedUsers]="selectedUsers"
          [onlyUsers]="onlyUsers"
          [title]="label"
        >
        </ease-select-user-popover>
      </sat-popover>
    </div>
  </ng-container>
</div>
