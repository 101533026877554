import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'ease-customer-lsa-summary-dialog',
  templateUrl: './customer-lsa-summary-dialog.component.html',
  styleUrls: ['./customer-lsa-summary-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerLsaSummaryDialogComponent implements OnInit {
  @Input() customerId: string;

  constructor() {}

  ngOnInit(): void {}
}
