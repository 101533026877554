import { NgModule } from '@angular/core';

import { CustomerWebsitesModule } from '../../../customers/customer-websites/customer-websites.module';
import { PopoverModule } from '../popover.module';
import { SharedModule } from '../../shared.module';
import { CustomerWebsitesPopoverComponent } from './customer-websites-popover.component';

@NgModule({
  imports: [CustomerWebsitesModule, PopoverModule, SharedModule],
  declarations: [CustomerWebsitesPopoverComponent],
  exports: [CustomerWebsitesPopoverComponent, PopoverModule]
})
export class CustomerWebsitesPopoverModule {}
