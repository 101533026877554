<ease-list-selector
  #userSelector
  [items]="users$ | async"
  [clearInputOnSelect]="true"
  [selectedItems]="selectedUsersArray"
  (selected)="onSelectUser($event)"
  (deselected)="onDeselectUser($event)"
  class="users"
  [sortFields]="['type', 'name']"
  [selectIcon]="selectIcon"
>
  <ng-template let-user="item" let-selected="selected" let-icon="icon">
    <div
      class="flex flex-row items-center border-b border-solid border-gray-200 py-2 px-5"
    >
      <mat-icon
        class="mat-icon-20 mr-2 transition-all duration-300 ease-out"
        [ngClass]="{
          'opacity-1': selected,
          'opacity-0': !selected
        }"
        >{{ icon }}</mat-icon
      >

      <div class="avatar-container" *ngIf="user?.users">
        <ease-avatar [userId]="(user?.users)[0]"></ease-avatar>
        <div class="quantity">+{{ user?.users.length }}</div>
      </div>
      <ease-avatar
        *ngIf="!user?.users"
        [userId]="user?.$key"
        [status]="{
          mode: user?.mode
        }"
      ></ease-avatar>
      <div class="users-box__user-name flex-1">{{ user?.name }}</div>
      <div
        *ngIf="user?.tasks"
        class="users-box__user-count text-xs uppercase text-gray-400"
      >
        {{ user?.tasks | objectLength }} tasks
      </div>
    </div>
  </ng-template>
</ease-list-selector>
