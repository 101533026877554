<form novalidate *ngIf="form" [formGroup]="form">
  <h3 class="flex flex-row items-center justify-start">
    <div>{{ checklistTemplate?.name }}</div>
    <button
      type="button"
      *ngIf="!disabled"
      mat-icon-button
      title="Remove Checklist"
      (click)="removeChecklist()"
    >
      <mat-icon class="mat-icon-20">delete</mat-icon>
    </button>
  </h3>
  <ease-checklist-fields-renderer
    *ngIf="!!form.value"
    [fields]="checklistTemplateFieldsForRender"
    [template]="checklistTemplate"
    [form]="form"
    [taskId]="taskId"
    (actionTriggered)="actionTriggered.emit($event)"
    [taskState]="taskState"
    [previewMode]="previewMode"
  ></ease-checklist-fields-renderer>
</form>
