import { EMPTY, Observable } from 'rxjs';

import { catchError, shareReplay } from 'rxjs/operators';

interface MappedCacheOptions {
  size?: number;
  ttl?: number;
  trim?: number;
}

interface MappedCacheEntry<T> {
  expires: number;
  value: Observable<T>;
}

export class MappedCache<T = any> {
  private cache = new Map<string, MappedCacheEntry<T>>();
  private options: MappedCacheOptions = {
    /**
     * Maximum number of cache entries
     */
    size: 100,
    /**
     * Time-to-live (TTL) in seconds for cache entries
     */
    ttl: 300,
    /**
     * Frequency in seconds for how often the cache
     * should be checked for expired entries
     */
    trim: 60
  };

  constructor(options: MappedCacheOptions = {}) {
    this.options = { ...this.options, ...options };
    setInterval(() => this.trim(), this.options.trim * 1000);
  }

  /**
   * Check if the cache contains a given key
   *
   * @param key Key to check for existence
   * @returns Boolean indicating whether the cache contains a given key
   */
  has(key: string): boolean {
    return this.cache.has(key);
  }

  /**
   * Get an entry from the cache
   *
   * @param key Key to store the entry at
   * @param loader Observable to store at the entry key
   * @returns The observable containing the desired values
   */
  get(key: string, loader: Observable<T>): Observable<T> {
    if (this.has(key)) {
      return this.cache.get(key).value;
    } else {
      this.set(key, loader);
      return this.cache.get(key).value;
    }
  }

  /**
   * Delete an entry from the cache
   *
   * @param key Entry key to delete
   */
  delete(key: string) {
    this.cache.delete(key);
  }

  /**
   * Trim the cache of expired entries
   * Executed based on options.trim frequency,
   * or manually as needed
   */
  trim() {
    this.cache.forEach((val, key) => {
      if (this.isExpired(val.expires)) {
        this.cache.delete(key);
      }
    });
  }

  /**
   * Sets an entry into the cache for a given key
   *
   * @param key Key to store the value at
   * @param val Value to set
   * @returns A copy of the cache
   */
  private set(key: string, val: Observable<T>) {
    if (this.cache.size === this.options.size) {
      const firstKey = this.cache.keys().next().value;
      this.delete(firstKey);
    }

    /**
     * Add the fetcher to the cache.
     * Remove entries from cache that errored to prevent caching errors.
     */
    return this.cache.set(key, {
      expires: this.now() + this.options.ttl,
      value: val.pipe(
        catchError(() => {
          this.delete(key);
          return EMPTY;
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
      )
    });
  }

  private isExpired(entryTime: number): boolean {
    return entryTime < this.now();
  }

  private now(): number {
    return new Date().getTime() / 1000;
  }
}
