<ng-container [ngSwitch]="phoneType">
  <mat-icon [ngClass]="['mat-icon-' + size]" *ngSwitchCase="'office'"
    >business</mat-icon
  >
  <mat-icon [ngClass]="['mat-icon-' + size]" *ngSwitchCase="'home'"
    >home</mat-icon
  >
  <mat-icon [ngClass]="['mat-icon-' + size]" *ngSwitchCase="'mobile'"
    >smartphone</mat-icon
  >
  <mat-icon [ngClass]="['mat-icon-' + size]" *ngSwitchCase="'other'"
    >phone</mat-icon
  >
  <mat-icon [ngClass]="['mat-icon-' + size]" *ngSwitchDefault>phone</mat-icon>
</ng-container>
