import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PopoverModule } from '../popover.module';
import { SharedModule } from '../../shared.module';

import { AnnouncementsPopoverComponent } from './announcements-popover.component';
import { AnnouncementsPopoverItemComponent } from './announcements-popover-item/announcements-popover-item.component';

@NgModule({
  imports: [CommonModule, PopoverModule, SharedModule],
  declarations: [
    AnnouncementsPopoverComponent,
    AnnouncementsPopoverItemComponent
  ],
  exports: [AnnouncementsPopoverComponent]
})
export class AnnouncementsPopoverModule {}
