import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  SimpleChanges
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SatPopover } from '@ncstate/sat-popover';
import { firstValueFrom, Observable } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';

import { BoardModel } from 'src/app/boards/board.model';
import { BoardService } from 'src/app/boards/board.service';
import { TaskModel } from 'src/app/tasks/task.model';
import { TaskService } from 'src/app/tasks/task.service';
import { UserService } from 'src/app/users/user.service';
import { SNACKBAR_DURATION_SUCCESS } from '../../constants';

@Component({
  selector: 'ease-task-move-popover',
  templateUrl: './task-move-popover.component.html',
  styleUrls: ['./task-move-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskMovePopoverComponent implements OnChanges {
  @Input() set task(task: TaskModel) {
    this._task = task;
    this.selectedBoardId = task.board;
    this.selectedListId = task.list;
  }
  get task(): TaskModel {
    return this._task;
  }
  private _task: TaskModel;

  public selectedBoard: BoardModel;
  public selectedBoardId: string;
  public selectedListId: string;
  public boards$: Observable<BoardModel[]>;
  private originalTask: TaskModel;

  constructor(
    private boardService: BoardService,
    private matSnackBar: MatSnackBar,
    private parentPopover: SatPopover,
    public taskService: TaskService,
    public userService: UserService
  ) {
    this.boards$ = this.boardService.boardsWithLists$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.task.previousValue ||
      changes.task.previousValue.$key !== changes.task.currentValue.$key
    ) {
      this.originalTask = cloneDeep(changes.task.currentValue);
      this.selectedListId = this.originalTask.list;
      this.updateBoard(this.originalTask.board);
    }
  }

  async updateBoard(boardId: string) {
    this.selectedBoard = (await firstValueFrom(this.boards$)).find(
      item => item.$key === boardId
    );
    this.selectedBoardId = this.selectedBoardId || this.selectedBoard.$key;
  }

  async changeBoard() {
    await this.updateBoard(this.selectedBoardId);
    this.selectedListId = this.selectedBoard.$lists[0].$key;
  }

  async moveTask() {
    await this.taskService.move(
      this.task.$key,
      this.selectedBoardId,
      this.selectedListId
    );
    this.parentPopover.close();
    this.matSnackBar.open('Successfully moved task', 'Close', {
      duration: SNACKBAR_DURATION_SUCCESS
    });
  }
}
