import { map } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';

import { TaskPhaseService } from '../task-phase.service';
import { TaskPhase } from '../task-phase.interface';

@Component({
  selector: 'ease-task-phase-blob',
  templateUrl: './task-phase-blob.component.html',
  styleUrls: ['./task-phase-blob.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskPhaseBlobComponent implements OnInit {
  @Input() set phaseId(phaseId: string) {
    this.phase = this.taskPhaseService
      .getAllAsObject()
      .pipe(map(phases => phases[phaseId]));
  }
  @Input()
  showIcon: boolean = false;
  public phase: Observable<TaskPhase>;

  constructor(private taskPhaseService: TaskPhaseService) {}

  ngOnInit() {}
}
