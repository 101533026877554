import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { UserService } from 'src/app/users/user.service';
import { getFormValueForTask } from '../../shared/utils/functions';
import { TASK_TEMPLATES_PATH } from '../../shared/firebase-paths';
import { TaskTemplate } from './task-template.interface';

@Injectable({ providedIn: 'root' })
export class TaskTemplatesService {
  constructor(
    private angularFire: FirebaseDbService,
    private userService: UserService
  ) {}

  getAllPlus(): Observable<TaskTemplate[]> {
    return this.angularFire.getList(`/${TASK_TEMPLATES_PATH}`, ref =>
      ref.orderByChild('name')
    );
  }

  getAll(): Observable<TaskTemplate[]> {
    return combineLatest([
      this.getAllPlus(),
      this.userService.currentUserRegion
    ]).pipe(
      map(([templates, userRegion]) =>
        templates.filter(
          template => template.region === userRegion || !template.region
        )
      )
    );
  }

  get(id: string): Observable<TaskTemplate> {
    return this.angularFire.getObject(`/${TASK_TEMPLATES_PATH}/${id}`);
  }

  create(template: Partial<TaskTemplate>): Promise<any> {
    return this.angularFire
      .list(`/${TASK_TEMPLATES_PATH}`)
      .push(template) as unknown as Promise<any>;
  }

  update(id: string, updatedFields: Partial<TaskTemplate>): Promise<void> {
    return this.angularFire
      .object(`/${TASK_TEMPLATES_PATH}/${id}`)
      .update(updatedFields);
  }

  async remove(id: string): Promise<void> {
    // Remoe task template from other task templates
    const linkedTemplates = await firstValueFrom(this.getLinkTemplates(id));

    if (linkedTemplates.length) {
      for (const linkedTemplate of linkedTemplates) {
        await this.angularFire
          .object(
            `/${TASK_TEMPLATES_PATH}/${linkedTemplate.$key}/fields/childrenTemplates/${id}`
          )
          .remove();
      }
    }

    await this.angularFire.list(`/${TASK_TEMPLATES_PATH}/${id}`).remove();
  }

  getLinkTemplates(templateId: string): Observable<TaskTemplate[]> {
    return this.angularFire.getList<TaskTemplate>(
      `/${TASK_TEMPLATES_PATH}`,
      ref =>
        ref.orderByChild(`fields/childrenTemplates/${templateId}`).equalTo(true)
    );
  }

  getFormValueForTemplate(
    id: string,
    isEditingTemplate?: boolean
  ): Observable<TaskTemplate> {
    return this.get(id).pipe(
      map(({ name, description, fields, region }) => ({
        name,
        description: description || '',
        region: region || null,
        fields: getFormValueForTask(fields, isEditingTemplate)
      }))
    );
  }
}
