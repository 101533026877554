<ease-sheet-panel class="popover">
  <div class="content">
    <mat-tab-group [disablePagination]="true" mat-stretch-tabs>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon [matTooltip]="'All Announcements'">all_inclusive</mat-icon>
        </ng-template>
        <div class="tab-content">
          <ng-container *ngIf="allActive$ | async as allActive; else loading">
            <ng-container *ngIf="allActive.length; else empty">
              <ease-announcements-popover-item
                *ngFor="let announcement of allActive; last as isLast"
                [announcement]="announcement"
                [ngClass]="{ last: isLast }"
              ></ease-announcements-popover-item>
            </ng-container>
          </ng-container>
        </div>
      </mat-tab>
      <ng-container *ngIf="allCompanyLevel$ | async as allCompanyLevel">
        <mat-tab [disabled]="allCompanyLevel.length === 0">
          <ng-template mat-tab-label>
            <img
              [matTooltip]="
                allCompanyLevel.length
                  ? 'Company wide announcements'
                  : 'No company wide announcements'
              "
              class="mat-icon-20"
              src="/assets/images/crown.png"
            />
          </ng-template>
          <div class="tab-content">
            <ease-announcements-popover-item
              *ngFor="let announcement of allCompanyLevel; last as isLast"
              [announcement]="announcement"
              [ngClass]="{ last: isLast }"
            ></ease-announcements-popover-item>
          </div>
        </mat-tab>
      </ng-container>
      <ng-container *ngIf="allUserLevel$ | async as allUserLevel">
        <mat-tab [disabled]="allUserLevel.length === 0">
          <ng-template mat-tab-label>
            <mat-icon
              [matTooltip]="
                allUserLevel.length
                  ? 'User level announcements'
                  : 'No user level announcements'
              "
              >portrait</mat-icon
            >
          </ng-template>
          <div class="tab-content">
            <ease-announcements-popover-item
              *ngFor="let announcement of allUserLevel; last as isLast"
              [announcement]="announcement"
              [ngClass]="{ last: isLast }"
            ></ease-announcements-popover-item>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>

  <ease-sheet-footer class="!mb-2 md:!hidden">
    <span class="flex-1"></span>
    <button (click)="close()" mat-button>Close</button>
  </ease-sheet-footer>
</ease-sheet-panel>

<ng-template #loading>
  <ease-state-loading [minHeight]="160"></ease-state-loading>
</ng-template>

<ng-template #empty>
  <ease-state-message
    [minHeight]="160"
    [message]="'No active announcements'"
  ></ease-state-message>
</ng-template>
