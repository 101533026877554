import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FundingSummaryBreakdownItem } from '../funding.interface';
import { FundingService } from '../funding.service';

@Component({
  selector: 'ease-funding-summary',
  templateUrl: './funding-summary.component.html',
  styleUrls: ['./funding-summary.component.scss']
})
export class FundingSummaryComponent implements OnInit {
  @HostBinding('class.shadow-md') get hostClass() {
    return true;
  }
  @Input() isWidget: boolean;
  @Input() set customerId(customerId: string) {
    this._customerId = customerId;
    this.fundingService.setFundingCustomerId(this.customerId);
    this.fundingService.reloadFundingData();
  }
  get customerId() {
    return this._customerId;
  }
  public breakdownData$: Observable<FundingSummaryBreakdownItem[]>;
  private _customerId: string;

  constructor(private fundingService: FundingService) {}

  ngOnInit(): void {
    this.breakdownData$ = this.fundingService.fundingSummary$.pipe(
      map(fundingSummary => fundingSummary.breakdown)
    );
  }
}
