<ng-container *ngIf="competitiveMetric">
  <div
    class="metric pointer flex flex-row items-center justify-center"
    (click)="metricInfo.toggle()"
    [satPopoverAnchor]="metricInfo"
    [ngClass]="competitiveMetric.ragStatusColor"
  >
    <div class="metric-color-special flex-1">
      {{ competitiveMetric.percentageSwingFromMedian }}%
    </div>

    <div class="metric-details flex-1">
      <div class="metric-top" [matTooltip]="'Current CPL'">
        <img
          src="/assets/icons/svg/coin-outline.svg"
          alt="target"
          class="micro-icon"
        />{{ competitiveMetric.cpl | currency: 'USD':'symbol':'1.0-2' }}
      </div>
      <div class="metric-bottom" [matTooltip]="'Median CPL'">
        <img src="/assets/icons/svg/flag.svg" alt="target" class="micro-icon" />
        {{ competitiveMetric.medianCPL | currency: 'USD':'symbol':'1.0-2' }}
      </div>
    </div>
  </div>

  <sat-popover [autoFocus]="false" [hasBackdrop]="true" #metricInfo>
    <ease-competitive-metric-popover
      *ngIf="metricInfo.isOpen()"
      [competitiveMetric]="competitiveMetric"
    ></ease-competitive-metric-popover>
  </sat-popover>
</ng-container>
