import { EventEmitter, Injectable } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { RowNode } from 'ag-grid-community';
import { map, take } from 'rxjs/operators';
import * as moment from 'moment';

import { firstValueFrom } from 'rxjs';
import { UserGridSetting } from 'src/app/users/user.model';
import { UserService } from '../../users/user.service';
import { FirebaseDbService } from '../firebase-db.service';
import { USER_SETTINGS_PATH } from '../firebase-paths';
import { GridSearchComponent } from '../grid-toolbar/grid-search/grid-search.component';
import {
  CustomFilters,
  GridOptions,
  GridSettings,
  GridState,
  GridInstanceName
} from './grid.interface';

@Injectable({
  providedIn: 'root'
})
export class GridService {
  public presetChanged: EventEmitter<void> = new EventEmitter<void>();
  public presetReset: EventEmitter<void> = new EventEmitter<void>();
  public defaultComparator = agGridDefaultComparator;
  public dateComparator = agGridDateComparator;

  constructor(
    private angularFire: FirebaseDbService,
    private userService: UserService
  ) {}

  async saveGridState(
    grid: AgGridAngular,
    instanceName: GridInstanceName,
    presetName: string,
    uniqueId: string,
    importedSettings?: GridState,
    setView?: boolean,
    customFilters?: CustomFilters
  ) {
    const currentGridSettings = await this.getGridSettings(instanceName);

    let gridSets;

    if (currentGridSettings) {
      gridSets = currentGridSettings;
    } else {
      gridSets = {
        selectedGridState: null,
        gridSettingsList: {}
      };
    }

    const quickFilterState: string = grid.api['filterManager']?.quickFilter;

    if (grid && grid.api && grid.columnApi) {
      const settings: GridState = {
        colState: grid.columnApi.getColumnState(),
        groupState: grid.columnApi.getColumnGroupState(),
        filterState: grid.api.getFilterModel(),
        quickFilterState,
        ...(customFilters && {
          customFilters
        })
      };

      /**
       * Attempt to fix https://github.com/SearchKings/ease-ngx/issues/156
       * Unclear why sometimes undefined, but ensure it is defined before
       * trying to set a key inside of it.
       */
      if (!gridSets.gridSettingsList) {
        gridSets.gridSettingsList = {};
      }

      gridSets.gridSettingsList[uniqueId] = {
        name: presetName,
        settings: importedSettings ? importedSettings : settings
      };

      await this.setItem(`${instanceName}_gridSettings`, gridSets);

      if (setView !== false) {
        await this.setSessionSelectedView(instanceName, uniqueId);
      }
    }
  }

  async restoreGridState(
    grid: AgGridAngular,
    instanceName: GridInstanceName,
    quickFilter?: GridSearchComponent
  ) {
    const state = await this.getSavedGridState(instanceName);

    this.applySavedGridState(grid, state, quickFilter);
  }

  applySavedGridState(
    grid: AgGridAngular,
    state: GridState,
    gridSearch: GridSearchComponent,
    sizeColumnsToFit?: boolean
  ) {
    if (state && grid && grid.api && grid.columnApi) {
      state.colState &&
        grid.columnApi.applyColumnState({
          /**
           * Exclude `aggFunc` being included in the state applied to the grid.
           * We only define `aggFunc` in-code, so a user will not have a custom one defined.
           * Let the code ensure the proper function is applied instead
           */
          state: state.colState.map(({ aggFunc, ...otherState }) => otherState),
          applyOrder: true
        });
      state.groupState && grid.columnApi.setColumnGroupState(state.groupState);
      state.filterState && grid.api.setFilterModel(state.filterState);
      gridSearch && gridSearch.setSearchQuery(state.quickFilterState || '');
      sizeColumnsToFit && grid.api.sizeColumnsToFit();
    }
  }

  async removeGridState(instanceName: GridInstanceName, id: string) {
    const settings = await this.getGridSettings(instanceName);
    delete settings.gridSettingsList[id];

    return this.setItem(`${instanceName}_gridSettings`, settings);
  }

  async resetGridState(
    grid: AgGridAngular,
    instanceName: GridInstanceName,
    gridOptions: GridOptions,
    gridSearch: GridSearchComponent,
    sizeColumnsToFit?: boolean
  ) {
    if (grid && grid.api && grid.columnApi) {
      if (gridOptions.resetGroups) {
        grid.columnApi.setRowGroupColumns([]);
      }

      if (gridOptions.resetColumnGroupState) {
        grid.columnApi.setColumnGroupState([]);
      }

      grid.columnApi.resetColumnState();
      grid.api.setFilterModel(null);
      gridSearch && gridSearch.setSearchQuery('');

      grid.api.onFilterChanged();

      sizeColumnsToFit && grid.api.sizeColumnsToFit();

      await this.resetSessionSelectedView(instanceName);

      this.presetReset.emit();
    }
  }

  private async getSavedGridState(
    instanceName: GridInstanceName
  ): Promise<GridState> {
    const states = await this.getItem(`${instanceName}_gridSettings`);
    const activeState = states?.selectedGridState;
    return activeState ? states.gridSettingsList[activeState]?.settings : null;
  }

  getGridSettings(instanceName: GridInstanceName): Promise<GridSettings> {
    return this.getItem(`${instanceName}_gridSettings`);
  }

  async setSessionSelectedView(instanceName: GridInstanceName, id: string) {
    const currentSettings = await this.getGridSettings(instanceName);
    currentSettings.selectedGridState = id;
    return this.setItem(`${instanceName}_gridSettings`, currentSettings);
  }

  async resetSessionSelectedView(instanceName: GridInstanceName) {
    const settings =
      (await this.getGridSettings(instanceName)) || ({} as GridSettings);
    settings.selectedGridState = null;

    return this.setItem(`${instanceName}_gridSettings`, settings);
  }

  setItem(instanceName: string, data: any) {
    return this.angularFire
      .object(
        `/${USER_SETTINGS_PATH}/${this.userService.currentUser.$key}/gridSettings`
      )
      .update({ [instanceName]: JSON.stringify(data || {}) });
  }

  getItem(instanceName: string): Promise<any> {
    return firstValueFrom(
      this.angularFire
        .getObject<UserGridSetting>(
          `/${USER_SETTINGS_PATH}/${this.userService.currentUser.$key}/gridSettings/${instanceName}`
        )
        .pipe(
          take(1),
          map(value => {
            if (value && !value.$value && value.$value === null) {
              return null;
            } else {
              return JSON.parse(value.$value);
            }
          })
        )
    );
  }
  /**
   * @param date MomentInput
   * @param outputFormat default: 'YYYY-MM-DD'
   * @param inputFormat required date format for string type to avoid deprecation warning
   * @returns string
   */
  getFormattedDateString(
    date: moment.MomentInput,
    outputFormat = 'YYYY-MM-DD',
    inputFormat?: string
  ): string {
    if (!date) {
      return ' ';
    }

    if (inputFormat) {
      return moment(date, inputFormat).format(outputFormat);
    } else {
      return moment(date).format(outputFormat);
    }
  }

  getDateObjForFilter(date: moment.MomentInput): Date {
    if (!date) {
      return null;
    }

    if (typeof date === 'number') {
      return moment(date).startOf('day').toDate();
    } else {
      return moment(date, 'YYYY-MM-DD').toDate();
    }
  }

  /**
   * Primarily called when sorting groups to check if there is a
   * comparator defined on the grouped column.
   * - if there is, call that defined comparator
   * - else, fallback to the default
   *
   * @param valueA
   * @param valueB
   * @param nodeA
   * @param nodeB
   * @param isInverted
   */
  getDefinedColumnComparator(
    valueA: any,
    valueB: any,
    nodeA: RowNode,
    nodeB: RowNode,
    isInverted: boolean
  ) {
    const comparator =
      nodeA?.rowGroupColumn?.getColDef()?.comparator ||
      nodeB?.rowGroupColumn?.getColDef()?.comparator ||
      agGridDefaultComparator;
    return comparator(valueA, valueB, nodeA, nodeB, isInverted);
  }
}

/**
 * Comparator that can sort string and numeric values. Null or empty
 * values stay at the bottom.
 *
 * @param valueA
 * @param valueB
 * @param nodeA
 * @param nodeB
 * @param isInverted
 * @returns
 */
const agGridDefaultComparator = (
  valueA: any,
  valueB: any,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean
): number => {
  switch (true) {
    case valueA === valueB:
      return 0;
    case !valueA:
    case valueA === ' ':
      return isInverted ? -1 : 1;
    case !valueB:
    case valueB === ' ':
      return isInverted ? 1 : -1;
    /**
     * This checks if the values are strings to sort alphabetically. The additional
     * `!parseFloat(...)` condition makes sure that the values can't be translated
     * into numeric values.
     */
    case typeof valueA === 'string' &&
      typeof valueB === 'string' &&
      !parseFloat(valueA) &&
      !parseFloat(valueB):
      return valueA.localeCompare(valueB);
    default:
      return valueA - valueB;
  }
};

/**
 * Comparator that can sort date strings or timestamps. Null or empty values
 * stay at the bottom.
 *
 * @param dateA
 * @param dateB
 * @param nodeA
 * @param nodeB
 * @param isInverted
 * @returns
 */
const agGridDateComparator = (
  dateA: string,
  dateB: string,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean
): number => {
  switch (true) {
    case dateA === dateB:
      return 0;
    case !dateA:
    case dateA === ' ':
      return isInverted ? -1 : 1;
    case !dateB:
    case dateB === ' ':
      return isInverted ? 1 : -1;
    default:
      return moment(dateA).isAfter(dateB) ? 1 : -1;
  }
};
