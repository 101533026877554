<ng-container *ngIf="task$ | async as task">
  <ng-container *ngIf="sharedLayoutService.isCompact$ | async as isCompact"
    ><div class="h-full w-full overflow-auto">
      <button
        mat-menu-item
        (click)="handlePopoverOpen(assignPopover, $event)"
        [satPopoverAnchor]="assignPopover"
        class="!flex flex-row items-center justify-start"
      >
        <mat-icon>supervisor_account</mat-icon>
        <div class="flex flex-1 flex-row items-center justify-between">
          <div>Assign</div>
          <mat-icon class="!-mr-[10px]">arrow_right</mat-icon>
        </div>
      </button>
      <button
        mat-menu-item
        (click)="handlePopoverOpen(subscribePopover, $event)"
        [satPopoverAnchor]="subscribePopover"
        class="!flex flex-row items-center justify-start"
      >
        <mat-icon>remove_red_eye</mat-icon>
        <div class="flex flex-1 flex-row items-center justify-between">
          <div>Watcher</div>
          <mat-icon class="!-mr-[10px]">arrow_right</mat-icon>
        </div>
      </button>
      <mat-divider></mat-divider>

      <button
        mat-menu-item
        (click)="handlePopoverOpen(statusPopover, $event)"
        [satPopoverAnchor]="statusPopover"
        class="!flex flex-row items-center justify-start"
      >
        <mat-icon>widgets</mat-icon>
        <div class="flex flex-1 flex-row items-center justify-between">
          <div>Set Status</div>
          <mat-icon class="!-mr-[10px]">arrow_right</mat-icon>
        </div>
      </button>

      <button
        mat-menu-item
        (click)="handlePopoverOpen(phasePopover, $event)"
        [satPopoverAnchor]="phasePopover"
        class="!flex flex-row items-center justify-start"
      >
        <mat-icon>linear_scale</mat-icon>
        <div class="flex flex-1 flex-row items-center justify-between">
          <div>Set Phase</div>
          <mat-icon class="!-mr-[10px]">arrow_right</mat-icon>
        </div>
      </button>

      <div #startDate>
        <button
          mat-menu-item
          (click)="handlePopoverOpen(startDatePicker.calendar, $event)"
          class="!flex flex-row items-center justify-start"
        >
          <mat-icon>play_circle_outline</mat-icon>
          <div class="flex flex-1 flex-row items-center justify-between">
            <div>Set Start Date</div>
            <mat-icon class="!-mr-[10px]">arrow_right</mat-icon>
          </div>
        </button>
      </div>

      <div #dueDate>
        <button
          mat-menu-item
          (click)="handlePopoverOpen(dueDatePicker.calendar, $event)"
          class="!flex flex-row items-center justify-start"
        >
          <mat-icon>date_range</mat-icon>
          <div class="flex flex-1 flex-row items-center justify-between">
            <div>Set Due Date</div>
            <mat-icon class="!-mr-[10px]">arrow_right</mat-icon>
          </div>
        </button>
      </div>

      <button
        mat-menu-item
        (click)="handlePopoverOpen(priorityPopover, $event)"
        [satPopoverAnchor]="priorityPopover"
        class="!flex flex-row items-center justify-start"
      >
        <mat-icon>low_priority</mat-icon>
        <div class="flex flex-1 flex-row items-center justify-between">
          <div>Set Priority</div>
          <mat-icon class="!-mr-[10px]">arrow_right</mat-icon>
        </div>
      </button>

      <mat-divider></mat-divider>

      <ng-container *ngIf="backpackService.backpack$ | async as backpack">
        <button mat-menu-item (click)="toggleBackpack()">
          <mat-icon>{{
            backpack[taskId] ? 'no_backpack' : 'backpack'
          }}</mat-icon>
          <span>{{
            backpack[taskId] ? 'Remove from backpack' : 'Add to backpack'
          }}</span>
        </button>
      </ng-container>

      <mat-divider></mat-divider>

      <button
        mat-menu-item
        (click)="handlePopoverOpen(completeTaskPopover, $event)"
        [satPopoverAnchor]="completeTaskPopover"
      >
        <mat-icon>check</mat-icon>
        <span>Mark as complete</span>
      </button>

      <sat-popover
        [hasBackdrop]="isCompact.active"
        verticalAlign="start"
        horizontalAlign="after"
        #assignPopover
        (closed)="actionTypePopoverClosed()"
      >
        <ease-select-user-popover
          *ngIf="assignPopover.isOpen()"
          title="Assign User"
          (selected)="onUserAdd($event, 'assign')"
          (deselected)="onUserRemove($event, 'unassign')"
          [selectedUsers]="tempAssigned"
          [onlyUsers]="true"
        >
        </ease-select-user-popover>
      </sat-popover>

      <sat-popover
        [hasBackdrop]="isCompact.active"
        verticalAlign="start"
        horizontalAlign="after"
        #subscribePopover
        (closed)="actionTypePopoverClosed()"
      >
        <ease-select-user-popover
          *ngIf="subscribePopover.isOpen()"
          (selected)="onUserAdd($event, 'subscribe')"
          (deselected)="onUserRemove($event, 'unsubscribe')"
          title="Add Watcher"
          [selectedUsers]="tempSubscribed"
        >
        </ease-select-user-popover>
      </sat-popover>

      <sat-popover
        [hasBackdrop]="isCompact.active"
        verticalAlign="start"
        horizontalAlign="after"
        #statusPopover
        (closed)="popoverClosed()"
      >
        <ease-task-status-popover
          *ngIf="statusPopover.isOpen()"
          (selected)="onStatusChange($event)"
          [selectedStatus]="task.$displayStatus"
        >
        </ease-task-status-popover>
      </sat-popover>

      <ease-datepicker
        #startDatePicker
        [ngModel]="task.startDate"
        (ngModelChange)="updateTask({ startDate: $event })"
        [startDate]="task?.startDate"
        [customAnchor]="startDate"
        verticalAlign="start"
        horizontalAlign="after"
        [hasBackdrop]="isCompact.active"
        (closed)="popoverClosed()"
      ></ease-datepicker>

      <ease-datepicker
        #dueDatePicker
        [min]="task.startDate ? task.startDate : now"
        [ngModel]="task.dueDate"
        (ngModelChange)="updateTask({ dueDate: $event })"
        [startDate]="task?.dueDate"
        [customAnchor]="dueDate"
        verticalAlign="start"
        horizontalAlign="after"
        [hasBackdrop]="isCompact.active"
        (closed)="popoverClosed()"
      ></ease-datepicker>

      <sat-popover
        [hasBackdrop]="isCompact.active"
        verticalAlign="start"
        horizontalAlign="after"
        #phasePopover
        (closed)="popoverClosed()"
      >
        <ease-task-phase-popover
          *ngIf="phasePopover.isOpen()"
          (selected)="onPhaseChange($event)"
          [selectedPhase]="task.$displayPhase"
        >
        </ease-task-phase-popover>
      </sat-popover>

      <sat-popover
        [hasBackdrop]="isCompact.active"
        verticalAlign="start"
        horizontalAlign="after"
        #completeTaskPopover
        (closed)="popoverClosed(false)"
      >
        <ease-complete-task-popover
          *ngIf="completeTaskPopover.isOpen()"
          (completeStart)="onCompleteStart()"
          (completed)="onCompleted()"
          [taskId]="task.$key"
        ></ease-complete-task-popover>
      </sat-popover>

      <sat-popover
        #priorityPopover
        [hasBackdrop]="isCompact.active"
        verticalAlign="start"
        horizontalAlign="after"
        (closed)="popoverClosed()"
        [autoFocus]="false"
      >
        <ease-task-priority-popover
          [priority]="task.priority"
          (selected)="updateTask({ priority: $event })"
        ></ease-task-priority-popover>
      </sat-popover></div
  ></ng-container>
</ng-container>
