<h1 mat-dialog-title>Account Links</h1>
<mat-dialog-content
  *ngIf="managerLinkRequests$ | async as managerLinkRequests; else loading"
>
  <form
    class="my-4 rounded-md border border-solid border-gray-200 bg-gray-100 p-4"
    *ngIf="isLinking"
    (ngSubmit)="createLinkRequest()"
    [formGroup]="linkingForm"
  >
    <h4 class="mt-0 mb-2 flex items-center">
      <mat-icon class="mat-icon-18 mr-2">email</mat-icon>
      <span>New Link Request</span>
    </h4>
    <div class="flex space-x-4">
      <mat-form-field>
        <input
          type="number"
          formControlName="accountId"
          placeholder="Account ID (No Dashes)"
          matInput
        />
        <mat-error easeFormErrors [control]="linkingForm.controls.accountId">
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Google Ads Manager Account</mat-label>
        <mat-select
          placeholder="Select a manager account to link to"
          formControlName="managerId"
        >
          <mat-option
            *ngFor="let manager of managerAccounts$ | async"
            [value]="manager.value"
            >{{ manager.viewValue }}</mat-option
          >
        </mat-select>
        <mat-error
          easeFormErrors
          [control]="linkingForm.controls.managerId"
        ></mat-error>
      </mat-form-field>
    </div>
    <div class="my-1 flex space-x-4">
      <button (click)="stopLinking()" type="button" mat-button>Cancel</button>
      <button
        color="primary"
        type="submit"
        mat-raised-button
        [disabled]="linkRequestLoading || linkingForm.invalid"
      >
        {{
          linkRequestLoading ? 'Sending link request...' : 'Send Link Request'
        }}
      </button>
    </div>
  </form>

  <ng-container *ngIf="!isLinking">
    <h4 class="mb-0 flex items-center">
      <mat-icon class="mat-icon-18 mr-2">people</mat-icon>
      <span>Link Requests</span>
      <mat-icon
        class="mat-icon-14 ml-1"
        matTooltip="Link requests that have not been accepted by the account administrators yet"
        >help_outline</mat-icon
      >
      <button
        (click)="startLinking()"
        class="!ml-4"
        color="primary"
        mat-stroked-button
      >
        New Link Request
      </button>
    </h4>
    <ng-container *ngIf="managerLinkRequests?.length; else empty">
      <ag-grid-angular
        style="height: 220px"
        class="ag-theme-material"
        [gridOptions]="gridOptions"
        [rowData]="managerLinkRequests"
      >
      </ag-grid-angular>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-dialog-close mat-button>Close</button>
</mat-dialog-actions>

<ng-template #loading>
  <ease-state-loading
    [minHeight]="120"
    message="Loading details..."
  ></ease-state-loading>
</ng-template>

<ng-template #empty>
  <ease-state-message
    [minHeight]="80"
    message="Nothing to display"
  ></ease-state-message>
</ng-template>

<ng-template #actionsCell let-params="params">
  <a class="cursor-pointer text-blue-400" (click)="revokeLinkRequest(params)">
    Revoke
  </a>
</ng-template>
