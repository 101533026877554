<ease-sheet class="dialog-container flex-1">
  <div class="message__meta shadow-md" *ngIf="message">
    <div>
      <strong>{{ message.Message.Subject }}</strong>
    </div>
    <div><strong>From:</strong> {{ message.Message.From }}</div>
    <div><strong>To:</strong> {{ message.Recipient }}</div>
    <div *ngIf="message.Message.CC">
      <strong>Cc:</strong> {{ message.Message.CC }}
    </div>
    <div class="flex flex-row">
      <div class="flex flex-row items-center justify-start">
        <mat-icon class="mat-icon-16 mr-1">remove_red_eye</mat-icon>
        {{ message.TotalOpens }}
      </div>
      <div class="ml-2 flex flex-row items-center justify-start">
        <mat-icon class="mat-icon-16 mr-1">mouse</mat-icon>
        {{ message.TotalClicks }}
      </div>
    </div>
  </div>

  <div class="flex flex-1 flex-col">
    <div
      *ngIf="isLoading"
      class="flex flex-1 flex-row items-end justify-center"
    >
      <mat-progress-spinner
        [diameter]="48"
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </div>
    <div
      *ngIf="!message && !isLoading"
      class="flex flex-1 flex-row items-end justify-center"
    >
      <div class="text-center">
        <h2>Preview Unavailable</h2>
        <div class="notice">
          If this message was sent recently, it's still in the delivery queue.
        </div>
      </div>
    </div>
    <div
      *ngIf="message && !domPreview"
      class="flex flex-1 flex-row items-end justify-center"
    >
      <div class="text-center">
        <h2>Preview Unavailable</h2>
        <div class="notice">
          Campaign Monitor only stores the email message information for up to
          30 days. The content of older emails can not be viewed or resent.
        </div>
      </div>
    </div>
    <iframe class="message__preview flex-1" #previewTemplateEl></iframe>
  </div>
  <ease-sheet-footer class="dialog__actions">
    <button mat-dialog-close mat-button>Close</button>
    <button
      (click)="resend()"
      [disabled]="isWorking"
      *ngIf="message?.CanBeResent"
      color="primary"
      mat-button
    >
      {{ isWorking ? 'Resending' : 'Resend' }}
    </button>
  </ease-sheet-footer>
</ease-sheet>
