import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';

import { MatDialogModule } from '@angular/material/dialog';
import { MarkdownModule } from '../markdown/markdown.module';
import { SheetsModule } from '../sheet/sheets.module';
import { VersionDialogComponent } from './version-dialog.component';

@NgModule({
  imports: [MatButtonModule, MarkdownModule, SheetsModule, MatDialogModule],
  declarations: [VersionDialogComponent]
})
export class VersionDialogModule {}
