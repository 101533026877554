import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import {
  Target,
  TargetMember
} from 'src/app/shared/bulk-targeting/bulk-targeting.interface';

@Component({
  selector: 'ease-announcements-target',
  templateUrl: './announcements-target.component.html',
  styleUrls: ['./announcements-target.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsTargetComponent implements OnInit {
  @Input() targetLevel: Target['level'];
  @Input() target: TargetMember[];

  constructor() {}

  ngOnInit(): void {}
}
