import { AbstractControl } from '@angular/forms';
import { Directive, DoCheck, ElementRef, Input } from '@angular/core';

const defaultErrorMessages = {
  email: 'Please enter a valid email',
  maxlength: 'This field is too long',
  minlength: 'This field is too short',
  noHttp: 'Value must not start with http:// or http://',
  required: 'This field is required',
  date: 'Please enter a valid date',
  pattern: 'This field contains some invalid characters'
};

@Directive({
  selector: '[easeFormErrors]'
})
export class FormErrorsDirective implements DoCheck {
  @Input()
  control: AbstractControl;
  @Input()
  show: boolean;
  @Input()
  set errorMessages(messages: { [errorKey: string]: string }) {
    if (messages) {
      this._errorMessages = Object.assign({}, defaultErrorMessages, messages);
    } else {
      this._errorMessages = defaultErrorMessages;
    }
  }

  get errorMessages(): { [errorKey: string]: string } {
    return this._errorMessages;
  }

  private _errorMessages: { [errorKey: string]: string } = defaultErrorMessages;

  constructor(private elementRef: ElementRef) {}

  ngDoCheck() {
    if (this.control && this.control.errors) {
      let show = true;
      if (this.show !== null && this.show !== undefined) {
        show = this.show;
      }

      if (show) {
        Object.keys(this.control.errors).some(errorKey => {
          if (this.control.errors[errorKey].message) {
            this.elementRef.nativeElement.innerHTML =
              this.control.errors[errorKey].message;
            return true;
          }

          if (this.errorMessages[errorKey]) {
            this.elementRef.nativeElement.innerHTML =
              this.errorMessages[errorKey];
            return true;
          }

          this.elementRef.nativeElement.innerHTML =
            'There is a problem with this field';
          return true;
        });
      }
    } else {
      this.elementRef.nativeElement.innerHTML = '';
    }
  }
}
