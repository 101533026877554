<ng-container *ngIf="list">
  <div class="detail-list">
    <div *ngFor="let item of list" class="item flex flex-row">
      <div class="label flex-1">
        {{ item.label }}
      </div>
      <div class="value flex-1">
        {{ item.value }}
      </div>
    </div>
  </div>
</ng-container>
