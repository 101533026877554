import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';

import { CustomerContactsService } from '../customer-contacts.service';
import { CustomerContactTypeModel } from '../customer-contact.model';

@Component({
  selector: 'ease-contact-type-view',
  templateUrl: './contact-type-view.component.html',
  styleUrls: ['./contact-type-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactTypeViewComponent implements OnInit {
  @HostBinding('class') hostClass = 'flex';
  @Input() type: CustomerContactTypeModel;

  constructor(public customerContactsService: CustomerContactsService) {}

  ngOnInit() {}
}
