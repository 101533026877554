<ease-list-selector
  #listSelector
  [items]="phases$ | async"
  [selectedItems]="selectedPhase"
  [clearInputOnSelect]="true"
  [singleSelect]="true"
  (selected)="onSelect($event)"
>
  <ng-template let-phase="item" let-selected="selected" let-icon="icon">
    <div
      class="flex flex-row items-center border-b border-solid border-gray-200 py-2 px-5"
    >
      <mat-icon
        class="mat-icon-20 mr-2 transition-all duration-300 ease-out"
        [ngClass]="{
          'opacity-1': selected,
          'opacity-0': !selected
        }"
        >{{ icon }}</mat-icon
      >
      <ease-color-blob size="md" [color]="phase?.color"> </ease-color-blob>
      {{ phase.name }}
      <span class="flex-1"></span>
      <button
        class="phase-action-button"
        *ngIf="permissionsService.hasPermission('modifyTaskMetadata')"
        (click)="editPhase(phase.$key, $event)"
        mat-icon-button
      >
        <mat-icon class="mat-icon-18">edit</mat-icon>
      </button>
      <button
        class="phase-action-button"
        *ngIf="permissionsService.hasPermission('modifyTaskMetadata')"
        (click)="removePhase(phase.$key, $event)"
        mat-icon-button
      >
        <mat-icon class="mat-icon-18">delete</mat-icon>
      </button>
    </div>
  </ng-template>
</ease-list-selector>
