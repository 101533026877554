import { map, shareReplay, tap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { TaskModelState } from 'src/app/tasks/task.model';
import {
  TaskChecklist,
  TaskChecklistData,
  TaskChecklistTemplate
} from '../task-checklist.interface';
import { TaskService } from '../../../task.service';
import { TaskChecklistTemplateService } from '../task-checklist-template.service';
import { TaskChecklistActionsService } from '../task-checklist-actions.service';

@Component({
  selector: 'ease-task-checklist-group',
  templateUrl: './task-checklist-group.component.html',
  styleUrls: ['./task-checklist-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskChecklistGroupComponent {
  @Input()
  set taskId(taskId: string) {
    this._taskId = taskId;
    if (this.taskId) {
      this.taskChecklists$ = this.taskService.getChecklists(this.taskId).pipe(
        map(checklists =>
          checklists.map(checklist => {
            checklist.$trackByKey = `${this.taskId}_${checklist.$key}`;
            return checklist;
          })
        ),
        tap(taskChecklists =>
          taskChecklists.map(taskChecklist => {
            const templateId = taskChecklist.$key;

            if (!this.templates[templateId]) {
              this.templates[templateId] = this.taskChecklistTemplateService
                .get(templateId)
                .pipe(
                  map(template =>
                    /**
                     * Ensure still pass through `$key` for later reference,
                     * like, for deleting "deleted checklist template" in task
                     */
                    template?.$key
                      ? template
                      : {
                          ...template,
                          $key: templateId,
                          name: '(unknown checklist template)'
                        }
                  )
                );
            }
          })
        ),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
  }

  get taskId(): string {
    return this._taskId;
  }

  @Input() taskState: TaskModelState;

  @Input()
  disabled: boolean = false;
  public taskChecklists$: Observable<TaskChecklist[]>;
  public _taskId: string;
  public templates: {
    [templateId: string]: Observable<TaskChecklistTemplate>;
  } = {};

  constructor(
    private taskService: TaskService,
    private taskChecklistTemplateService: TaskChecklistTemplateService,
    public taskChecklistActionsService: TaskChecklistActionsService
  ) {}

  onChecklistChanged(
    checklistKey: string,
    checklistFieldToSave: TaskChecklistData
  ): void {
    if (!this.disabled) {
      this.taskService.saveChecklist(
        this.taskId,
        checklistKey,
        checklistFieldToSave
      );
    }
  }

  onChecklistRemove(checklistId: string): void {
    this.taskService.removeChecklist(this.taskId, checklistId);
  }

  getKey(index: number, currentValue: TaskChecklist): string | number {
    return currentValue ? currentValue.$trackByKey : index;
  }
}
