import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectLength'
})
export class ObjectLengthPipe implements PipeTransform {
  transform(inputObject: any): number {
    return inputObject ? Object.keys(inputObject).length : 0;
  }
}
