import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ease-nav-item',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavItemComponent {
  @HostBinding('class') class =
    'block h-8 text-gray-300 hover:bg-blue-gray-800';
}
