<form
  [formGroup]="createTaskForm"
  (ngSubmit)="createTask(createTaskForm.value, createTaskForm.valid)"
  class="flex min-h-0 flex-1 flex-col"
>
  <ease-sheet-content class="flex-1 overflow-y-auto overflow-x-hidden">
    <ease-advanced-autocomplete
      class="mb-2"
      [formControl]="templateControl"
      [options]="taskTemplates"
      [placeholder]="'Select a task template'"
      (optionSelected)="setTemplate($event)"
    >
    </ease-advanced-autocomplete>

    <ease-task-edit-form
      #taskEditForm
      formControlName="fields"
      [windowData]="data"
      [isSubtask]="!!data?.parent"
    ></ease-task-edit-form>
  </ease-sheet-content>
  <ease-sheet-footer class="flex-none">
    <button (click)="cancel()" mat-button>Cancel</button>
    <button
      type="submit"
      [disabled]="createTaskForm.invalid || isWorking"
      color="primary"
      mat-button
    >
      Create Task
    </button>
  </ease-sheet-footer>
</form>

<ng-template #windowSubtitleTemplate>
  <div class="flex flex-row items-center">
    <ease-entity-icon
      class="mr-1"
      [size]="16"
      [entity]="{
        entityType: createTaskForm?.value?.fields?.entityType,
        accountType: createTaskForm?.value?.fields?.accountType
      }"
    ></ease-entity-icon>
    <div class="truncate text-xs">
      {{ createTaskForm?.value?.fields?.entityName }}
    </div>
  </div>
</ng-template>
