import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { NotificationPanelModule } from '../../../notifications/notification-panel/notification-panel.module';
import { PopoverModule } from '../popover.module';
import { SharedModule } from '../../shared.module';
import { SharedFormModule } from '../../shared-form.module';
import { SharedPipesModule } from '../../shared-pipes.module';
import { SearchPipe } from '../../search.pipe';
import { NotificationsPopoverComponent } from './notifications-popover.component';

@NgModule({
  imports: [
    PopoverModule,
    NotificationPanelModule,
    ScrollingModule,
    SharedModule,
    SharedFormModule,
    SharedPipesModule,
    VirtualScrollerModule
  ],
  declarations: [NotificationsPopoverComponent],
  exports: [NotificationsPopoverComponent, PopoverModule],
  providers: [SearchPipe]
})
export class NotificationsPopoverModule {}
