import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding
} from '@angular/core';

@Component({
  selector: 'ease-nav-section-header',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./nav-section-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavSectionHeaderComponent implements OnInit {
  @HostBinding('class') class =
    'flex flex-row items-center text-gray-100 p-4 pt-0 text-base font-medium';
  constructor() {}

  ngOnInit(): void {}
}
