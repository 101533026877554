import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { EaseMaterialModule } from '../ease-material.module';
import { SheetsModule } from '../sheet/sheets.module';
import { ConfirmComponent } from './confirm.component';
import { ConfirmService } from './confirm.service';

@NgModule({
  imports: [
    CommonModule,
    SheetsModule,
    EaseMaterialModule,
    ReactiveFormsModule
  ],
  declarations: [ConfirmComponent],
  providers: [ConfirmService]
})
export class ConfirmModule {}
