import {
  Directive,
  OnInit,
  Input,
  Optional,
  HostListener,
  HostBinding
} from '@angular/core';
import { WindowPane } from '../window-pane/window-pane';

/**
 * Button that will close the Window that encloses this directive.
 */
@Directive({
  selector: '[easeWindowClose]',
  exportAs: 'easeWindowClose'
})
export class WindowCloseDirective implements OnInit {
  @HostBinding('attr.type') get buttonType() {
    return this.type;
  }
  @HostListener('click', ['$event'])
  handleHostClick(event: Event) {
    event.stopPropagation();
    this.windowPane.close(this.easeWindowClose);
  }
  @Input() easeWindowClose: any;

  /** Default to "button" to prevents accidental form submits. */
  @Input() type: 'submit' | 'button' | 'reset' = 'button';

  constructor(@Optional() public windowPane: WindowPane<any>) {}

  ngOnInit() {
    if (!this.windowPane) {
      throw new Error(
        'EaseWindowCloseDirective was unable to find a corresponding WindowPane to close'
      );
    }
  }
}
