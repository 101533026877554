<mat-toolbar
  [ngClass]="{ 'rounded-t-lg': windowRef.state === 'OPEN' }"
  class="z-5 h-auto max-h-16 min-h-0 flex-none cursor-pointer !bg-blue-gray-800 shadow-sm"
  color="primary"
  (click)="windowRef.minimize()"
  *ngIf="!isMinimized"
>
  <mat-toolbar-row
    class="flex h-auto max-h-16 min-h-0 flex-row px-4 py-2"
    *ngIf="windowRef.onTemplateContextChanged | async as templateContext"
  >
    <div class="flex-none">
      <ng-template
        [ngTemplateOutlet]="windowRef.iconTemplate || defaultIconTemplate"
        [ngTemplateOutletContext]="{
          $implicit: windowRef,
          templateContext: templateContext
        }"
      ></ng-template>
    </div>
    <div class="flex flex-1 flex-col truncate">
      <ng-template
        [ngTemplateOutlet]="windowRef.titleTemplate || defaultTitleTemplate"
        [ngTemplateOutletContext]="{
          $implicit: windowRef,
          templateContext: templateContext
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="
          windowRef.subtitleTemplate || defaultSubtitleTemplate
        "
        [ngTemplateOutletContext]="{
          $implicit: windowRef,
          templateContext: templateContext
        }"
      ></ng-template>
    </div>
    <button
      mat-icon-button
      matTooltip="Minimize"
      *ngIf="isOpen || isMaximized"
      (click)="windowRef.minimize(); $event.stopPropagation()"
    >
      <mat-icon class="mat-icon-20">minimize</mat-icon>
    </button>
    <button
      class="!hidden sm:!block"
      mat-icon-button
      matTooltip="Shrink"
      *ngIf="isMaximized"
      (click)="windowRef.open(); $event.stopPropagation()"
    >
      <mat-icon class="mat-icon-20">close_fullscreen</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="Grow"
      *ngIf="!isMaximized"
      (click)="windowRef.maximize(); $event.stopPropagation()"
    >
      <mat-icon class="mat-icon-20">open_in_full</mat-icon>
    </button>
    <button
      *ngIf="{ canClose: windowRef.canClose() | async } as context"
      (click)="windowRef.close(); $event.stopPropagation()"
      [ngClass]="{ '!text-blue-gray-500': !context.canClose }"
      [matTooltip]="
        context.canClose
          ? 'Close'
          : 'Something is preventing this window from closing'
      "
      mat-icon-button
    >
      <mat-icon class="mat-icon-20">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div
  [hidden]="!isOpen"
  class="flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white"
>
  <ng-template
    [cdkPortalOutlet]="componentPortal"
    (attached)="onComponentAttached($event)"
  ></ng-template>
</div>

<ng-template #defaultIconTemplate let-window>
  <mat-icon class="mat-icon-16 my-1 mr-2">{{ windowRef.icon }}</mat-icon>
</ng-template>

<ng-template #defaultTitleTemplate let-window>
  <div class="truncate text-sm font-medium">{{ window.title }}</div>
</ng-template>

<ng-template #defaultSubtitleTemplate let-window>
  <div class="truncate text-xs">{{ window.subtitle }}</div>
</ng-template>
