<ease-sheet *ngIf="customerId$ | async as customerId">
  <ease-account-dashboard-table
    #table
    [ngClass]="{ hidden: !dataReady }"
    [accounts]="accounts$ | async"
    [quickFilter]="''"
    [compact]="options?.compact ? options.compact : false"
    [overrides]="gridOptions"
    (rowDataUpdated)="onDataReady()"
    [style.height.px]="tableHeight"
  ></ease-account-dashboard-table>

  <ease-state-loading
    *ngIf="!dataReady"
    [minHeight]="260"
    [message]="'Loading Table...'"
  ></ease-state-loading
></ease-sheet>
