import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SatPopoverModule } from '@ncstate/sat-popover';

import { AccountIconModule } from '../../../shared/account-icon/account-icon.module';
import { FormErrorsModule } from '../../form-errors/form-errors.module';
import { MetricsModule } from '../../metrics/metrics.module';
import { SharedModule } from '../../shared.module';

import { FundingSummaryComponent } from './funding-summary.component';
import { FundingSummaryTransactionsComponent } from './funding-summary-transactions/funding-summary-transactions.component';
import { FundingSummarySettingsComponent } from './funding-summary-settings/funding-summary-settings.component';
import { FundingSummarySettingsDialogComponent } from './funding-summary-settings/funding-summary-settings-dialog/funding-summary-settings-dialog.component';
import { FundingSummaryBreakdownModule } from './funding-summary-breakdown/funding-summary-breakdown.module';

@NgModule({
  imports: [
    AccountIconModule,
    CommonModule,
    FormErrorsModule,
    FundingSummaryBreakdownModule,
    MetricsModule,
    ReactiveFormsModule,
    SatPopoverModule,
    SharedModule
  ],
  declarations: [
    FundingSummaryComponent,
    FundingSummaryTransactionsComponent,
    FundingSummarySettingsComponent,
    FundingSummarySettingsDialogComponent
  ],
  exports: [
    FundingSummaryComponent,
    FundingSummaryTransactionsComponent,
    FundingSummarySettingsComponent,
    FundingSummarySettingsDialogComponent
  ]
})
export class FundingSummaryModule {}
