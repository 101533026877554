<div class="flex flex-row items-center justify-start">
  <strong
    (click)="collapse = !collapse"
    [ngClass]="{ 'cursor-pointer': !formItem.answer }"
    >{{ formItem.title }}</strong
  >
  <div class="expand" (click)="collapse = !collapse" *ngIf="!formItem.answer">
    <mat-icon class="mat-icon-14">{{ collapse ? 'add' : 'remove' }}</mat-icon>
  </div>
</div>
<div>{{ formItem.answer }}</div>
<div class="indent" *ngIf="!collapse">
  <ease-customer-onboarding-form-renderer-item
    *ngFor="let formItem of formItem.fields"
    [formItem]="formItem"
    [collapse]="collapse"
  ></ease-customer-onboarding-form-renderer-item>
</div>
