import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FundingService } from '../../funding.service';
import { FundingSummarySettingsDialogComponent } from './funding-summary-settings-dialog/funding-summary-settings-dialog.component';

@Component({
  selector: 'ease-funding-summary-settings',
  templateUrl: './funding-summary-settings.component.html',
  styleUrls: ['./funding-summary-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundingSummarySettingsComponent implements OnInit {
  @Input() customerId: string;
  @Input() isWidget: boolean;
  public labelManagementFee = {
    funding: 'Funding',
    fundingPercentage: 'Funding %',
    customer: 'Out of Pocket'
  };

  constructor(
    public fundingService: FundingService,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.fundingService.setFundingCustomerId(this.customerId);
  }

  openFundingSettingsDialog() {
    const dialogRef = this.matDialog.open(
      FundingSummarySettingsDialogComponent,
      {
        width: '540px'
      }
    );
    dialogRef.componentInstance.customerId = this.customerId;
  }
}
