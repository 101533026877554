import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { Observable } from 'rxjs';

import { UserService } from 'src/app/users/user.service';
import { CustomerContactModel } from '../customer-contact.model';
import { CustomerContactsService } from '../customer-contacts.service';

@Component({
  selector: 'ease-customer-contact-view',
  templateUrl: './customer-contact-view.component.html',
  styleUrls: ['./customer-contact-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerContactViewComponent implements OnInit {
  @Input()
  contact: CustomerContactModel;
  @Input()
  editButton: boolean = false;
  @Input()
  moreMenu: boolean = false;
  @Input()
  disableStarring: boolean = false;
  @Output()
  save: EventEmitter<CustomerContactModel> = new EventEmitter<CustomerContactModel>();
  @Output()
  editClicked: EventEmitter<void> = new EventEmitter<void>();
  public registerLink$: Observable<string>;
  public userSettingLink: string;

  ngOnInit(): void {
    this.registerLink$ = this.customerContactsService.getReportsRegisterLink(
      this.contact.$key
    );
    this.userSettingLink =
      this.contact.reportingApp &&
      this.customerContactsService.getReportsUserSettingLink(
        this.contact.reportingApp.id
      );
  }

  constructor(
    private customerContactsService: CustomerContactsService,
    public userService: UserService
  ) {}

  toggleStarred() {
    if (!this.disableStarring) {
      this.contact.isStarred = !this.contact.isStarred;
      this.save.emit(this.contact);
    }
  }

  onEditClicked() {
    this.editClicked.emit();
  }
}
