import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
  Optional
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { WindowPane } from '../../shared/window/window-pane/window-pane';
import { TaskModel } from '../task.model';
import { TaskService } from '../task.service';

@Component({
  selector: 'ease-task-sidebar',
  templateUrl: './task-sidebar.component.html',
  styleUrls: ['./task-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskSidebarComponent implements OnInit {
  @HostBinding('class') class = 'overflow-auto h-full min-h-0';
  public task$: Observable<TaskModel>;
  private taskId$: Observable<string>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    @Optional() private windowPane: WindowPane<string>
  ) {
    if (this.windowPane) {
      this.taskId$ = this.windowPane.onDataChanged;
    }
  }

  ngOnInit() {
    if (!this.taskId$) {
      this.taskId$ = this.activatedRoute.params.pipe(
        map(params => params['id']),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }

    this.task$ = this.taskId$.pipe(switchMap(id => this.taskService.get(id)));
  }
}
