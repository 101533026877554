import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { SummaryModule } from '../../shared/summary/summary.module';
import { AccountIconModule } from '../../shared/account-icon/account-icon.module';
import { CustomerWebsitesComponent } from './customer-websites/customer-websites.component';
import { CustomerWebsitesRouteComponent } from './customer-websites-route/customer-websites-route.component';
import { CustomerWebsitesFormComponent } from './customer-websites-form/customer-websites-form.component';
import { CustomerWebsitesSummaryComponent } from './customer-websites-summary/customer-websites-summary.component';

@NgModule({
  imports: [AccountIconModule, ClipboardModule, SharedModule, SummaryModule],
  declarations: [
    CustomerWebsitesComponent,
    CustomerWebsitesFormComponent,
    CustomerWebsitesRouteComponent,
    CustomerWebsitesSummaryComponent
  ],
  exports: [CustomerWebsitesComponent, CustomerWebsitesRouteComponent]
})
export class CustomerWebsitesModule {}
