import { NgModule } from '@angular/core';

import { ListSelectorModule } from '../../../shared/list-selector/list-selector.module';
import { SharedModule } from '../../../shared/shared.module';
import { SharedPipesModule } from '../../../shared/shared-pipes.module';
import { TaskPhaseSelectorComponent } from './task-phase-selector.component';

@NgModule({
  imports: [ListSelectorModule, SharedModule, SharedPipesModule],
  declarations: [TaskPhaseSelectorComponent],
  exports: [TaskPhaseSelectorComponent]
})
export class TaskPhaseSelectorModule {}
