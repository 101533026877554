<div
  [ngClass]="{
    open: visible,
    disabled: disabled,
    hideicon: disableDefaultIcon
  }"
>
  <div
    (click)="toggle()"
    class="relative cursor-pointer after:absolute after:top-1/2 after:right-1 after:inline-block after:-translate-y-1/2 after:rotate-[45deg] after:cursor-pointer after:border-r-2 after:border-b-2 after:border-solid after:p-[3px] after:align-middle after:duration-200 after:ease-in-out"
    [ngClass]="{
      'cursor-default': disabled,
      'after:-translate-y-1/2 after:rotate-[225deg]': visible,
      'after:!hidden': disableDefaultIcon || disabled
    }"
  >
    <ng-content select=".collapse-title"></ng-content>
  </div>
  <div [@bodyExpansion]="getExpandedState()" class="overflow-hidden">
    <ng-content select=".collapse-content"></ng-content>
  </div>
</div>
