import { NgModule } from '@angular/core';

import { EntityIconModule } from 'src/app/shared/entity-icon/entity-icon.module';
import { SharedModule } from '../../shared/shared.module';
import { SharedPipesModule } from '../../shared/shared-pipes.module';
import { NotificationPanelComponent } from './notification-panel.component';

@NgModule({
  imports: [SharedModule, SharedPipesModule, EntityIconModule],
  declarations: [NotificationPanelComponent],
  exports: [NotificationPanelComponent]
})
export class NotificationPanelModule {}
