import { NgModule } from '@angular/core';

import { AccountIconModule } from '../../../shared/account-icon/account-icon.module';
import { ChartsModule } from '../../../shared/charts/charts.module';
import { CustomerAccountBudgetModule } from '../../../customers/customer-accounts/customer-account-budget/customer-account-budget.module';
import { CustomerBudgetsModule } from '../../../customers/customer-budgets/customer-budgets.module';
import { SharedModule } from '../../../shared/shared.module';
import { SharedPipesModule } from '../../../shared/shared-pipes.module';
import { TaskBudgetBasicComponent } from './task-budget-basic/task-budget-basic.component';
import { TaskBudgetComponent } from './task-budget.component';
import { TaskBudgetTrendingComponent } from './task-budget-trending/task-budget-trending.component';

@NgModule({
  imports: [
    AccountIconModule,
    ChartsModule,
    CustomerAccountBudgetModule,
    CustomerBudgetsModule,
    SharedModule,
    SharedPipesModule
  ],
  declarations: [
    TaskBudgetBasicComponent,
    TaskBudgetComponent,
    TaskBudgetTrendingComponent
  ],
  exports: [TaskBudgetComponent]
})
export class TaskBudgetModule {}
