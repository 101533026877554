import { NgModule } from '@angular/core';

import { AccountIconModule } from '../account-icon/account-icon.module';
import { SharedModule } from '../shared.module';
import { SharedFormModule } from '../shared-form.module';
import { SharedPipesModule } from '../shared-pipes.module';
import { StripTypesensePipeModule } from '../search/strip-typesense-pipe.module';
import { FinderService } from './finder.service';
import { FinderComponent } from './finder.component';

@NgModule({
  imports: [
    AccountIconModule,
    SharedModule,
    SharedFormModule,
    SharedPipesModule,
    StripTypesensePipeModule
  ],
  declarations: [FinderComponent],
  providers: [FinderService],
  exports: [FinderComponent]
})
export class FinderModule {}
