<h5>Modified Campaigns</h5>
<ng-container *ngIf="items && items.length; else noItems">
  <ng-container *ngFor="let campaignGroup of items">
    <ease-list-group size="sm">
      <ease-list-group-item>{{
        campaignGroup.campaignName
      }}</ease-list-group-item>
    </ease-list-group>
  </ng-container>
</ng-container>
<ng-template #noItems>
  No campaigns were updated as a result of this scheduled change.
</ng-template>
