import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

import { WindowService } from '../window.service';
import { TaskWindowerOpened } from './task-windower.interface';

@Directive({
  selector: '[easeTaskWindower]'
})
export class TaskWindowerDirective {
  @Input()
  easeTaskWindower: string;
  @Input()
  easeTaskWindowerDisabled: boolean;

  @Output() easeTaskWindowerOpened = new EventEmitter<TaskWindowerOpened>();

  @HostListener('click', ['$event'])
  async onClick(event: MouseEvent) {
    if (this.easeTaskWindower && !this.easeTaskWindowerDisabled) {
      const background = event.ctrlKey || event.metaKey;
      await this.windowService.create({
        type: 'viewTask',
        data: this.easeTaskWindower,
        state: background ? 'MINIMIZED' : 'MAXIMIZED'
      });

      this.easeTaskWindowerOpened.emit({ background, event });
    }
  }

  constructor(private windowService: WindowService) {}
}
