import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormControl } from '@angular/forms';
import { ConfirmData } from './confirm-data.interface';

@Component({
  selector: 'ease-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  public detailsControl: FormControl<string> = new FormControl();
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmData) {}

  ngOnInit() {}
}
