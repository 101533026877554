import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnInit
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { ScrollspyService } from './scrollspy.service';
import { ScrollSpySectionElement } from './scrollspy.interface';

@UntilDestroy()
@Directive({
  selector: '[easeScrollSpyScrollContainer]'
})
export class ScrollSpyScrollContainerDirective
  implements OnInit, AfterViewInit
{
  @Input() offset: number = 0;

  private prevSection: ScrollSpySectionElement;
  private currSection: ScrollSpySectionElement;

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private scrollSpyService: ScrollspyService
  ) {}

  ngOnInit() {
    this.scrollSpyService.scrollingEvent$
      .pipe(
        untilDestroyed(this),
        debounceTime(50),
        distinctUntilChanged(),
        filter(() => !!this.scrollSpyService.watchSections.length)
      )
      .subscribe(event => this.handleScroll(event));
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      const el = this.elementRef.nativeElement;
      fromEvent(el, 'scroll')
        .pipe(untilDestroyed(this), debounceTime(50), distinctUntilChanged())
        .subscribe(event =>
          // reenter the Angular zone and emit the scroll status immediately
          this.ngZone.run(() => this.propagateScrollStatus(event as Event))
        );
    });
  }

  propagateScrollStatus(event: Event): void {
    this.scrollSpyService.setScrollEvent(event);
  }

  findCurrentSection(scrollEvent: Event): ScrollSpySectionElement {
    let section: ScrollSpySectionElement;
    const scrollTop = scrollEvent.target['scrollTop'];
    const parentOffset = scrollEvent.target['offsetTop'];

    // Loop through all sections and determine which section is showing on the browser
    for (const watchSection of this.scrollSpyService.watchSections) {
      // Determine watched elements if shows on the current scroll position,
      // but only return the latest one
      const isElementPositionDetermined =
        watchSection.element.offsetTop - parentOffset <=
        scrollTop + this.offset;

      if (isElementPositionDetermined) {
        section = watchSection;
      }
    }

    return section;
  }

  handleScroll(scrollEvent: Event): void {
    this.currSection = this.findCurrentSection(scrollEvent);

    // If the dom has no spied-on sections are available(undefined),
    // we return right away
    if (!this.prevSection && !this.currSection) {
      return;
    }

    // If the dom has no spied-on sections are in view and had the previous section
    // we still emit to make sure to reset the shared scroll state
    if (!this.currSection && this.prevSection) {
      this.prevSection = null;
      return this.scrollSpyService.setSectionInView(null);
    }

    // If the dom has spied-on section and section changed,
    // we emit the current section id
    if (this.currSection?.id !== this.prevSection?.id) {
      this.prevSection = this.currSection;
      return this.scrollSpyService.setSectionInView(this.currSection.id);
    }
  }
}
