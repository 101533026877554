<div class="banner theme-info mb-5 flex flex-row items-center justify-center">
  <mat-icon class="mr-2">info</mat-icon>
  <span
    >These display campaigns contain ads that are using feeds
    (<strong>skSharedValues</strong>) which are prohibited.</span
  >
</div>
<ease-list-group size="sm">
  <ease-list-group-item *ngFor="let campaign of items">{{
    campaign.campaignName
  }}</ease-list-group-item>
</ease-list-group>
