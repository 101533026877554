import { NgModule } from '@angular/core';

import { ColorBlobModule } from '../../shared/color-blob/color-blob.module';
import { SharedModule } from '../../shared/shared.module';
import { TaskStatusComponent } from '../task-modules/task-status/task-status.component';
import { TaskStatusPopoverModule } from '../../shared/popover/task-status/task-status-popover.module';
import { TaskStatusSelectorModule } from './task-status-selector/task-status-selector.module';
import { TaskStatusBlobComponent } from './task-status-blob/task-status-blob.component';

@NgModule({
  imports: [
    ColorBlobModule,
    TaskStatusPopoverModule,
    TaskStatusSelectorModule,
    SharedModule
  ],
  declarations: [TaskStatusComponent, TaskStatusBlobComponent],
  exports: [
    TaskStatusBlobComponent,
    TaskStatusComponent,
    TaskStatusSelectorModule,
    TaskStatusPopoverModule
  ]
})
export class TaskStatusModule {}
