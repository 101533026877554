<p>
  Fusebill Customer: {{ details.fusebillCustomerName }} ({{
    details.fusebillCustomerId
  }})
</p>
<p>
  Attempted to charge
  <strong>{{
    details.paymentAttemptedAmount | currency: details.paymentCurrency:'code'
  }}</strong>
  to {{ details.paymentMethod }}.
</p>
<p>
  Response from payment gateway was
  <strong>{{ details.gatewayAuthResponse || 'unknown' }}</strong
  >.
</p>
<a target="_blank" [href]="details.detailsUrl" mat-raised-button
  ><mat-icon>launch</mat-icon> <span>View in Fusebill</span></a
>
