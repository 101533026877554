import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ease-announcements-panel',
  templateUrl: './announcements-panel.component.html',
  styleUrls: ['./announcements-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsPanelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
