import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ListDisplayItemValue } from '../shared.interface';
import { AdvancedAutocompleteOption } from './advanced-autocomplete.interface';
import {
  optionApplyMatcher,
  optionDisplayMatcher,
  optionFilter
} from './advanced-autocomplete.utils';

@Pipe({
  name: 'advancedAutocompleteFilter'
})
export class AdvancedAutocompleteFilterPipe implements PipeTransform {
  transform(
    query: string,
    options: AdvancedAutocompleteOption<true | false>[] = [],
    grouped: boolean
  ): AdvancedAutocompleteOption<true | false>[] {
    if (options?.length && query && query !== '') {
      if (grouped) {
        return (options as AdvancedAutocompleteOption<true>[])
          .map(optionGroup => ({
            name: optionGroup.name,
            items: optionFilter(query, optionGroup.items)
          }))
          .filter(group => group.items.length);
      } else {
        return optionFilter(
          query,
          options as AdvancedAutocompleteOption<false>[]
        );
      }
    } else {
      return options;
    }
  }
}

@Pipe({
  name: 'advancedAutocompleteApplyValue'
})
export class AdvancedAutocompleteApplyValuePipe implements PipeTransform {
  transform(
    control: FormControl<ListDisplayItemValue>,
    options: AdvancedAutocompleteOption<true | false>[] = [],
    grouped: boolean
  ): void {
    let matchValue: ListDisplayItemValue;
    const currentValue: ListDisplayItemValue = control.value;

    if (currentValue) {
      if (grouped) {
        for (const optionGroup of options as AdvancedAutocompleteOption<true>[]) {
          matchValue = optionApplyMatcher(currentValue, optionGroup.items);

          if (matchValue) {
            break;
          }
        }
      } else {
        matchValue = optionApplyMatcher(
          currentValue,
          options as AdvancedAutocompleteOption<false>[]
        );
      }

      if (matchValue && matchValue !== currentValue) {
        control.setValue(matchValue);
      }
    } else {
      return null;
    }
  }
}

@Pipe({
  name: 'advancedAutocompleteDisplayFn'
})
export class AdvancedAutocompleteDisplayFnPipe implements PipeTransform {
  transform(
    options: AdvancedAutocompleteOption<true | false>[] = [],
    grouped: boolean
  ): (selected: ListDisplayItemValue) => string {
    return selectedValue => {
      let matchViewValue: string;

      if (selectedValue && options?.length) {
        if (grouped) {
          for (const optionGroup of options as AdvancedAutocompleteOption<true>[]) {
            matchViewValue = optionDisplayMatcher(
              selectedValue,
              optionGroup.items
            );

            if (matchViewValue) {
              break;
            }
          }
        } else {
          matchViewValue = optionDisplayMatcher(
            selectedValue,
            options as AdvancedAutocompleteOption<false>[]
          );
        }
      }

      if (matchViewValue) {
        return matchViewValue;
      } else if (selectedValue && !matchViewValue) {
        return '(Preselected or selected option is no longer available)';
      } else {
        return null;
      }
    };
  }
}
