<div class="flex flex-1 flex-row items-center justify-center">
  <div class="numeric" *ngIf="!onlyBar">
    <span class="value metric-color-special"> {{ value }}% </span>
  </div>
  <div class="graph flex flex-1 flex-row">
    <div class="bar flex flex-row" [ngStyle]="{ 'height.px': height }">
      <div
        class="value metric-background"
        [ngStyle]="{ 'width.%': value }"
        [ngClass]="color"
      ></div>
    </div>
  </div>
</div>
