import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'ease-feed-item-role-change',
  templateUrl: './feed-item-role-change.component.html',
  styleUrls: ['../feed-item/feed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemRoleChangeComponent implements OnInit {
  @Input()
  item;
  @Input()
  users;
  @Input()
  dateFormat: string;

  constructor() {}

  ngOnInit(): void {}
}
