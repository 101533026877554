import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import { ListDisplayItem } from 'src/app/shared/shared.interface';
import { FeedItem } from '../../../shared/feed-items/feed-item.interface';
import { FeedItemChange } from '../../../shared/feed-items/feed-item/feed-item.component';
import { ActionScrollDirective } from '../../../shared/scroll/action-scroll.directive';
import { TypesenseBaseFieldList } from '../../../shared/search/search.interface';
import { getNotesPathForAccountType } from '../../../shared/utils/functions';
import {
  ACCOUNT_FEED_TYPES,
  SNACKBAR_DURATION_SUCCESS
} from '../../../shared/constants';
import { UserService } from '../../../users/user.service';
import { CustomerAccountMeta } from '../../customer-accounts/customer-accounts.interface';
import { CustomerAccountsService } from '../../customer-accounts/customer-accounts.service';
import { CreateCustomerCommunicationComponent } from '../../customer-communication/create-customer-communication/create-customer-communication.component';
import {
  CustomerFeedViewingEntity,
  CustomerStickyNote
} from '../customer-feed.interface';
import { CustomerFeedService } from '../customer-feed.service';

@UntilDestroy()
@Component({
  selector: 'ease-customer-feed',
  templateUrl: './customer-feed.component.html',
  styleUrls: ['./customer-feed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerFeedComponent implements OnInit {
  @HostBinding('class') class = 'flex flex-col flex-1';
  @Input()
  get viewingEntity(): CustomerFeedViewingEntity {
    return this._viewingEntity;
  }

  set viewingEntity(viewingEntity: CustomerFeedViewingEntity) {
    if (viewingEntity) {
      this._viewingEntity = viewingEntity;
      if (viewingEntity.customerId) {
        this.stickyNotes$ = this.customerFeedService.getStickyNotes(
          viewingEntity.customerId
        );
        this.hidingStickyNotes = !!JSON.parse(
          localStorage.getItem(`hidingStickyNotes-${viewingEntity.customerId}`)
        );
        this.getCustomerFeedAccounts();

        if (viewingEntity.account) {
          /**
           * Get the accounts for the customer,
           * if there is only one, get the full customer feed,
           * otherwise get the feed for the defined viewingEntity account.
           */
          this.getCustomerFeedAccounts().subscribe(accounts => {
            if (accounts && accounts.length > 1) {
              /**
               * This customer has more than 1 account, and we've requested
               * to only view a single account, so load the account feed.
               */
              this.getAccountFeed(viewingEntity.account);
            } else {
              /**
               * This customer only has 1 account, so load the customer feed
               * even though the viewingEntity has an account defined.
               */
              this.getCustomerFeed();
            }
          });
        } else {
          this.getCustomerFeed();
        }
      }
      this.updateActivityFilters();
    }
  }

  @ViewChild('actionScroll', { read: ActionScrollDirective, static: true })
  actionScroll: ActionScrollDirective;
  @ViewChild('markdownForm')
  markdownForm;
  @ViewChild('searchInput', { read: MatInput })
  set searchInput(input: MatInput) {
    input && setTimeout(() => input.focus());
  }
  private _viewingEntity: CustomerFeedViewingEntity;
  private createDialogRef: MatDialogRef<CreateCustomerCommunicationComponent>;
  private viewMode: 'customer' | 'account';
  public _selectedAccount: CustomerAccountMeta | string = 'all';
  public customerFeedAccounts$: Observable<any[]>;
  public feed$: Observable<FeedItem[]>;
  public liveFeed$: Observable<FeedItem[]>;
  public hidingStickyNotes: boolean = false;
  public showingNoteForm: boolean = false;
  public isAddingNote: boolean = false;
  public isSearching: boolean = false;
  public noteForm: FormGroup<{
    body: FormControl<string>;
    sticky: FormControl<boolean>;
  }> = this.formBuilder.group({
    body: this.formBuilder.control(null, { validators: Validators.required }),
    sticky: this.formBuilder.control(false)
  });
  public searchControl: FormControl<string> = new FormControl();
  public activityFilterControl: FormControl<string[]> = new FormControl();
  public stickyNotes$: Observable<CustomerStickyNote[]>;
  public users: any = {};

  public filterOptions: ListDisplayItem[] = [
    { value: 'budgetChange', viewValue: 'Budget Changes' },
    {
      value: 'customerCommunication',
      viewValue: 'Customer Communications'
    },
    {
      value: 'planChange',
      viewValue: 'Plan Changes'
    },
    { value: 'statusChange', viewValue: 'Status Changes' },
    { value: 'roleChange', viewValue: 'Role Changes' }
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private customerAccountsService: CustomerAccountsService,
    public customerFeedService: CustomerFeedService,
    private dialog: MatDialog,
    private entityUtilsService: EntityUtilsService,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.usersAsObject
      .pipe(take(1))
      .subscribe(users => (this.users = users));

    this.searchControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(query => this.updateFeedQuery(query));

    this.activityFilterControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(
        currentFilter => (this.customerFeedService.itemType = currentFilter)
      );
  }

  get selectedAccount() {
    return this._selectedAccount as any;
  }

  updateActivityFilters(): void {
    this.filterOptions.forEach(option => {
      option.hidden =
        option.value === 'planChange' && !!this._viewingEntity?.account;
    });

    this.activityFilterControl.reset();
    this.cdr.detectChanges();
  }

  getCustomerFeed() {
    this._selectedAccount = 'all';
    this.viewMode = 'customer';
    this.customerFeedService.viewingEntity = {
      customerId: this.viewingEntity.customerId
    };
    this._viewingEntity = {
      customerId: this.viewingEntity.customerId
    };
    this.scrollToTop();
  }

  resetFilters() {
    this.activityFilterControl.patchValue([]);
  }

  getAccountFeed(accountMeta: CustomerAccountMeta) {
    this._selectedAccount = accountMeta;
    this.viewMode = 'account';
    this.customerFeedService.viewingEntity = {
      customerId: this.viewingEntity.customerId,
      account: {
        accountId: accountMeta.accountId,
        accountType: accountMeta.accountType
      }
    };
    this._viewingEntity = {
      customerId: this.viewingEntity.customerId,
      account: {
        accountId: accountMeta.accountId,
        accountType: accountMeta.accountType
      }
    };

    this.scrollToTop();
  }

  updateFeedQuery(query: string) {
    this.customerFeedService.currentQuery = query;
  }

  getCustomerFeedAccounts(): Observable<any[]> {
    this.customerFeedAccounts$ = this.customerAccountsService
      .getAccountsOfTypes(this.viewingEntity.customerId, ACCOUNT_FEED_TYPES)
      .pipe(take(1));

    return this.customerFeedAccounts$;
  }

  onAccountSelect(event: MatSelectChange) {
    if (event.value === 'all') {
      this.getCustomerFeed();
    } else {
      this.getAccountFeed({
        accountId: event.value.$key,
        accountType: event.value.$accountType
      });
    }
    this.updateActivityFilters();
  }

  compareSelectedAccount(option, selection): boolean {
    if (!selection) {
      return true;
    }

    if (typeof selection === 'string') {
      return selection === option;
    } else {
      return +selection.accountId === +option.$key;
    }
  }

  loadMoreItems() {
    this.customerFeedService.loadMore();
  }

  showNoteForm() {
    this.showingNoteForm = true;
    this.cdr.detectChanges();
  }

  hideNoteForm() {
    this.showingNoteForm = false;
    this.cdr.detectChanges();
  }

  toggleHidingStickyNotes() {
    if (this.hidingStickyNotes) {
      this.hidingStickyNotes = false;
      localStorage.removeItem(
        `hidingStickyNotes-${this.viewingEntity.customerId}`
      );
    } else {
      this.hidingStickyNotes = true;
      localStorage.setItem(
        `hidingStickyNotes-${this.viewingEntity.customerId}`,
        JSON.stringify(true)
      );
    }
  }

  toggleSearch() {
    if (this.isSearching) {
      this.searchControl.setValue('');
    }

    this.isSearching = !this.isSearching;
  }

  onItemSave(change: FeedItemChange) {
    this.customerFeedService.updateItem(change);
  }

  async addNote() {
    if (this.noteForm.valid) {
      this.isAddingNote = true;
      this.cdr.detectChanges();

      const { body, sticky } = this.noteForm.value;

      if (sticky) {
        await this.addStickyNote(body);
      } else {
        if (this._selectedAccount === 'all') {
          await this.addCustomerNote(body);
        }

        if (this._selectedAccount && this.selectedAccount !== 'all') {
          await this.addAccountNote(body);
        }
      }
    }

    this.hideNoteForm();
    this.scrollToTop();

    this.isAddingNote = false;
    this.cdr.detectChanges();
  }

  async addStickyNote(body: string) {
    return this.customerFeedService
      .addStickyNote(this.viewingEntity.customerId, body)
      .then(() => this.noteForm.reset());
  }

  async addCustomerNote(body: string) {
    return this.customerFeedService
      .addNote(this.viewingEntity.customerId, body)
      .then(() => this.showCustomerFeedPrompt());
  }

  async addAccountNote(body: string) {
    return this.customerFeedService.createItem(
      { body },
      {
        accountType: this.viewingEntity.account.accountType,
        endpoint: getNotesPathForAccountType(
          this.viewingEntity.account.accountType
        ),
        customerId: this.viewingEntity.customerId,
        entityId: this.viewingEntity.account.accountId,
        entityName: await this.entityUtilsService.getEntityField(
          {
            entityType: 'account',
            entityId: this.viewingEntity.account.accountId,
            accountType: this.viewingEntity.account.accountType
          },
          'name'
        ),
        entityType: 'account'
      }
    );
  }

  removeStickyNote(note: CustomerStickyNote) {
    this.customerFeedService.removeStickyNote(
      this.viewingEntity.customerId,
      note.$key
    );
  }

  scrollToTop() {
    this.actionScroll && this.actionScroll.scroll();
  }

  showCustomerFeedPrompt() {
    if (this.viewMode === 'account') {
      this.matSnackBar
        .open('Item added to customer-level feed', 'Jump to feed', {
          duration: SNACKBAR_DURATION_SUCCESS
        })
        .afterDismissed()
        .pipe(untilDestroyed(this))
        .subscribe(
          dismissResult =>
            dismissResult.dismissedByAction && this.getCustomerFeed()
        );
    }
  }

  openCommunicationDialog() {
    this.createDialogRef = this.dialog.open(
      CreateCustomerCommunicationComponent
    );
    this.createDialogRef.componentInstance.customerId =
      this.viewingEntity.customerId;
    this.createDialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(newCommunicationDates => {
        if (newCommunicationDates) {
          this.scrollToTop();
          this.showCustomerFeedPrompt();
        }
      });
  }

  handleSearchBlur() {
    if (!this.searchControl.value) {
      this.toggleSearch();
    }
  }

  getKey(
    index: number,
    currentValue: FeedItem | TypesenseBaseFieldList | CustomerStickyNote
  ): string {
    return currentValue.$key;
  }
}
