import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';

import { SharedLayoutService } from '../../shared-layout.service';
import { WindowPane } from '../window-pane/window-pane';
import { WindowService } from '../window.service';

@UntilDestroy()
@Component({
  selector: 'ease-window-manager',
  templateUrl: './window-manager.component.html',
  styleUrls: ['./window-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('barTrigger', [
      transition(':enter', [
        style({ height: '0px', opacity: 0 }),
        animate('100ms 0ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('100ms 0ms ease-out', style({ height: '0px', opacity: 0 }))
      ])
    ]),
    trigger('tabTrigger', [
      transition(':enter', [
        style({ transform: 'translateX(25%) scaleX(25%)', opacity: 0 }),
        animate(
          '100ms 0ms ease-out',
          style({ transform: 'translateX(0) scaleX(100%)', opacity: 1 })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0) scaleX(100%)', opacity: 1 }),
        animate(
          '100ms 0ms ease-out',
          style({ transform: 'translateX(-25%) scaleX(25%)', opacity: 0 })
        )
      ])
    ])
  ]
})
export class WindowManagerComponent implements OnInit {
  @ContentChild('iconTemplate') iconTemplate: TemplateRef<any>;
  @ContentChild('titleTemplate') titleTemplate: TemplateRef<any>;
  @ContentChild('subtitleTemplate') subtitleTemplate: TemplateRef<any>;
  @ViewChild('mobileMangerUi') mobileMangerUi: TemplateRef<any>;
  @ViewChild('hasMobileWindow') set hasMobileWindow(hasWindow: WindowPane) {
    // Close mobile manager UI once no selected pane(empty panes)
    if (!hasWindow) {
      this.closeMobileManager();
    }
  }
  public selected$: Observable<WindowPane>;

  constructor(
    private bottomSheet: MatBottomSheet,
    public sharedLayoutService: SharedLayoutService,
    public windowService: WindowService
  ) {}

  ngOnInit() {
    this.selected$ = this.windowService.getSelected();

    combineLatest([
      this.sharedLayoutService.isCompact$,
      this.windowService.reachedMaxWindows$
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([{ active }, reached]) => {
        if (active && reached) {
          this.openMobileManager();
        }
      });
  }

  getId(index: number, window: WindowPane<any>): string {
    return window.id;
  }

  drop({ previousIndex, currentIndex }: CdkDragDrop<string[]>) {
    this.windowService.move(previousIndex, currentIndex);
  }

  openMobileManager(): void {
    if (this.mobileMangerUi) {
      this.bottomSheet.open(this.mobileMangerUi, {
        panelClass: 'window-manager-mobile-panel',
        closeOnNavigation: true
      });
    }
  }

  closeMobileManager(): void {
    this.bottomSheet.dismiss();
  }
}
