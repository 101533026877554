import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';
import { SNACKBAR_DURATION_ERROR } from '../../shared/constants';
import { AdminPermissionsService } from './admin-permissions.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(
    private permissionService: AdminPermissionsService,
    private matSnackBar: MatSnackBar
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const permissionId: string =
      route.hasOwnProperty('data') && route.data.permissionId
        ? route.data.permissionId
        : null;

    return permissionId
      ? this.permissionService.hasPermission$(permissionId).pipe(
          tap(hasPermission => {
            if (!hasPermission) {
              this.matSnackBar.open('Access denied', 'Close', {
                duration: SNACKBAR_DURATION_ERROR
              });
            }
          })
        )
      : of(false).pipe(
          tap(() => {
            console.error(
              'Permission guard present. Not permission ID assigned'
            );
          })
        );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(route, state);
  }
}
