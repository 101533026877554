import { take } from 'rxjs/operators';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, Observable } from 'rxjs';

import { TaskModel } from '../task.model';
import { TaskService } from '../task.service';
import { UserModel } from '../../users/user.model';
import { UserService } from '../../users/user.service';
import { ScheduledTaskService } from '../scheduled-tasks/scheduled-task.service';
import { ScheduledTaskEditDialogComponent } from '../scheduled-tasks/scheduled-task-edit-dialog/scheduled-task-edit-dialog.component';
import { SNACKBAR_DURATION_ERROR } from '../../shared/constants';

@Component({
  selector: 'ease-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss']
})
export class TaskViewComponent implements OnInit {
  @HostBinding('class') hostClass = 'relative';
  @Input() task: TaskModel;
  public currentUser$: Observable<UserModel>;
  public task$: Observable<TaskModel>;
  public users$: Observable<{ [userId: string]: UserModel }>;

  constructor(
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private scheduledTaskService: ScheduledTaskService,
    private taskService: TaskService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.users$ = this.userService.usersAsObject.pipe(take(1));
  }

  saveTask(task: TaskModel) {
    this.taskService.update(task.$key, task);
  }

  async onEditScheduledTask(taskId: string) {
    const exists: boolean = await firstValueFrom(
      this.scheduledTaskService.get(taskId)
    ).then((task: any) => task.$exists());

    if (exists) {
      const editRef = this.matDialog.open(ScheduledTaskEditDialogComponent);
      editRef.componentInstance.taskId = taskId;
    } else {
      this.matSnackBar.open(`This scheduled task has already ended`, 'Close', {
        duration: SNACKBAR_DURATION_ERROR
      });
    }
  }
}
