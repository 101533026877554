import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { delay, shareReplay, switchMap } from 'rxjs/operators';

import { CustomerLsaSummaryItem } from '../customer-lsa-summary.interface';
import { CustomerLsaSummaryService } from '../../customer-lsa-summary.service';

@Component({
  selector: 'ease-customer-lsa-summary',
  templateUrl: './customer-lsa-summary.component.html',
  styleUrls: ['./customer-lsa-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerLsaSummaryComponent implements OnInit {
  @Input() set customerId(customerId: string) {
    this.customerIdSource$.next(customerId);
  }

  private customerIdSource$: ReplaySubject<string> = new ReplaySubject<string>(
    1
  );
  private customerId$: Observable<string> = this.customerIdSource$
    .asObservable()
    .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  public summary$: Observable<CustomerLsaSummaryItem[]>;

  constructor(private customerLsaSummaryService: CustomerLsaSummaryService) {}

  ngOnInit(): void {
    this.summary$ = this.customerId$.pipe(
      switchMap(customerId => this.customerLsaSummaryService.get(customerId)),
      delay(1500)
    );
  }
}
