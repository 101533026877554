<ul
  class="detail detail-position"
  #itemsContainer
  *ngIf="verticalRankings"
  [ngClass]="colorSelected ? colorSelected : ''"
>
  <ng-container *ngFor="let account of verticalRankings">
    <li
      class="item metric-border flex flex-row"
      [ngClass]="{
        self: account.self
      }"
      #self
      *ngIf="account.self"
    >
      <span class="account-rank">{{ account.position }}</span>
      <span class="account-name flex-1">{{ account.accountName }}</span>
      <span class="account-cpl">
        {{ account.CPL | currency: 'USD':'symbol':'1.0-2' }}
      </span>
    </li>

    <li
      class="item flex flex-row"
      [ngClass]="{
        self: account.self
      }"
      *ngIf="!account.self"
    >
      <span class="account-rank">{{ account.position }}</span>
      <span class="account-name flex-1">{{ account.accountName }}</span>
      <span class="account-cpl">
        {{ account.CPL | currency: 'USD':'symbol':'1.0-2' }}
      </span>
    </li>
  </ng-container>
</ul>
