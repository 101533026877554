<div class="wrapper flex flex-col">
  <div class="header">
    <div class="content">
      <div class="icon">
        <mat-icon
          [matBadge]="users.length"
          [matBadgeHidden]="!users.length"
          matBadgeColor="warn"
          matBadgePosition="above before"
          >library_add_check</mat-icon
        >
      </div>
      <div class="label">Marked read by</div>
    </div>
  </div>

  <div class="body flex-1 overflow-y-auto overflow-x-hidden">
    <ng-container *ngIf="users.length; else empty">
      <ng-container *ngIf="allUsers$ | async as allUsers">
        <div *ngFor="let userId of users" class="content">
          <div class="icon">
            <ease-avatar
              [userId]="userId"
              [showTooltip]="true"
              [showInitials]="true"
            ></ease-avatar>
          </div>
          <div class="label">{{ allUsers[userId].name }}</div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="footer">
    <div (click)="toggle()" class="content toggle">
      <div class="icon">
        <mat-icon>chevron_left</mat-icon>
      </div>
      <div class="label">Collapse</div>
    </div>
  </div>
</div>

<ng-template #empty>
  <ease-state-message
    class="empty"
    icon="person_off"
    message="Be the first to mark this as read"
  ></ease-state-message>
</ng-template>
