import { map } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';

import { TaskStatusService } from '../task-status.service';
import { TaskStatus } from '../task-status.interface';

@Component({
  selector: 'ease-task-status-blob',
  templateUrl: './task-status-blob.component.html',
  styleUrls: ['./task-status-blob.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskStatusBlobComponent implements OnInit {
  @Input() set statusId(statusId: string) {
    this.status = this.taskStatusService
      .getAllAsObject()
      .pipe(map(statuses => statuses[statusId]));
  }
  @Input()
  showIcon: boolean = false;
  public status: Observable<TaskStatus>;

  constructor(private taskStatusService: TaskStatusService) {}

  ngOnInit() {}
}
