import {
  Component,
  Inject,
  TemplateRef,
  HostBinding,
  Input
} from '@angular/core';
import { CONTEXT_MENU_CONTENT } from './context-menu.service';

@Component({
  selector: 'ease-context-menu',
  template: `<ng-container
    [ngTemplateOutlet]="content"
    [ngTemplateOutletContext]="{ $implicit: data }"
  ></ng-container>`
})
export class ContextMenuComponent {
  @Input() data: any;
  constructor(
    @Inject(CONTEXT_MENU_CONTENT)
    public content: TemplateRef<any>
  ) {}

  @HostBinding('class')
  class = 'bg-white rounded-xl mat-elevation-z4 py-2';
}
