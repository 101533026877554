import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';

import { FundingSummaryBreakdownDialogComponent } from './funding-summary-breakdown-dialog/funding-summary-breakdown-dialog.component';
import { FundingSummaryBreakdownComponent } from './funding-summary-breakdown.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    FundingSummaryBreakdownComponent,
    FundingSummaryBreakdownDialogComponent
  ],
  exports: [
    FundingSummaryBreakdownComponent,
    FundingSummaryBreakdownDialogComponent
  ]
})
export class FundingSummaryBreakdownModule {}
