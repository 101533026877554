import { Pipe, PipeTransform } from '@angular/core';

import { CustomerContactTypeModel } from '../customer-contact.model';

@Pipe({
  name: 'emptyContactType'
})
export class EmptyContactTypePipe implements PipeTransform {
  transform(contactType: CustomerContactTypeModel): boolean {
    return !!contactType && Object.values(contactType).every(type => !type);
  }
}
