import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'ease-state-message',
  templateUrl: './state-message.component.html',
  styleUrls: ['../states-shared.scss', './state-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateMessageComponent implements OnInit {
  @Input() minHeight: number = 80;
  @Input() message: string;
  @Input() icon: string;

  constructor() {}

  ngOnInit(): void {}
}
