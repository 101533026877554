import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';
import { BaseHttpService } from './base-http.service';

@Injectable({ providedIn: 'root' })
export class SkSyncHttpService extends BaseHttpService {
  constructor(
    public afAuth: AngularFireAuth,
    public http: HttpClient,
    public mdSnackBar: MatSnackBar
  ) {
    super(environment.SKSYNC_URI, afAuth, http, mdSnackBar);
  }

  protected extractData(response) {
    return response ? response.payload : {};
  }
}
