import { combineLatest, Observable } from 'rxjs';

import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { orderBy } from 'lodash-es';

import {
  FirebaseDbService,
  ListAsObject
} from 'src/app/shared/firebase-db.service';
import { BOARDS_PATH } from '../shared/firebase-paths';
import { UserService } from '../users/user.service';
import { BoardLists, BoardModel } from './board.model';
import { ListService } from './list.service';

@Injectable({ providedIn: 'root' })
export class BoardService {
  public boardsWithLists$: Observable<BoardModel[]>;
  public boardsAsObject$: Observable<ListAsObject<BoardModel>>;

  constructor(
    private angularFire: FirebaseDbService,
    private listService: ListService,
    private userService: UserService
  ) {
    this.boardsAsObject$ = this.angularFire
      .getObject(`/${BOARDS_PATH}`)
      .pipe(shareReplay(1));

    this.boardsWithLists$ = this.getAllWithLists('current');
  }

  create(board: Pick<BoardModel, 'name' | 'group'>) {
    return this.angularFire.list(`/${BOARDS_PATH}`).push(board);
  }

  addList(boardId: string, listId: string) {
    return this.angularFire
      .object(`/${BOARDS_PATH}/${boardId}/lists/${listId}`)
      .set(true);
  }

  updateLists(boardId: string, lists: BoardLists) {
    return this.angularFire
      .object(`/${BOARDS_PATH}/${boardId}/lists`)
      .update(lists);
  }

  getAll(region: string = 'current'): Observable<BoardModel[]> {
    switch (region) {
      case 'canada':
      case 'southAfrica':
        return this.angularFire.getList(`/${BOARDS_PATH}`, ref =>
          ref.orderByChild('group').equalTo(region)
        );

      case 'all':
        return this.angularFire.getList(`/${BOARDS_PATH}`);

      case 'current':
        return this.userService.currentUserRegion.asObservable().pipe(
          take(1),
          switchMap(userRegion =>
            this.angularFire.getList(`/${BOARDS_PATH}`, ref =>
              ref.orderByChild('group').equalTo(userRegion)
            )
          )
        );
    }
  }

  getAllWithLists(region: 'canada' | 'southAfrica' | 'all' | 'current') {
    return combineLatest([this.getAll(region), this.listService.getAll()]).pipe(
      map(([boards, lists]) =>
        boards.map(board => {
          const listsWithOrder = board?.lists;
          const boardLists = lists.filter(list => list.board === board.$key);
          const rawLists = boardLists.map(list => ({
            ...list,
            $key: list?.$key,
            order: listsWithOrder?.[list?.$key]?.order
          }));

          board.$lists = orderBy(rawLists, 'order');

          return board;
        })
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  get(id) {
    return this.angularFire.getObject(`/${BOARDS_PATH}/${id}`);
  }
}
