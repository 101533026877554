import { NgModule } from '@angular/core';
import { RichTooltipModule } from '../rich-tooltip/rich-tooltip.module';
import { SharedModule } from './../../shared/shared.module';

import { MetricGraphBarComponent } from './metric-graph-bar/metric-graph-bar.component';
import { MetricListComponent } from './metric-list/metric-list.component';
import { MetricSimpleComponent } from './metric-simple/metric-simple.component';

@NgModule({
  imports: [SharedModule, RichTooltipModule],
  exports: [
    MetricGraphBarComponent,
    MetricListComponent,
    MetricSimpleComponent
  ],
  declarations: [
    MetricGraphBarComponent,
    MetricListComponent,
    MetricSimpleComponent
  ]
})
export class MetricsModule {}
