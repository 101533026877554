import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollSpyScrollContainerDirective } from './scrollspy-scroll-container.directive';
import { ScrollspySectionDirective } from './scrollspy-section.directive';

@NgModule({
  declarations: [ScrollSpyScrollContainerDirective, ScrollspySectionDirective],
  imports: [CommonModule],
  exports: [ScrollSpyScrollContainerDirective, ScrollspySectionDirective]
})
export class ScrollspyModule {}
