<button
  (click)="announcementsPopover.open()"
  [satPopoverAnchor]="announcementsPopover"
  matTooltip="Announcements"
  mat-icon-button
>
  <mat-icon>campaign</mat-icon>
</button>
<sat-popover
  panelClass="toolbar-popover"
  verticalAlign="start"
  horizontalAlign="end"
  #announcementsPopover
  [hasBackdrop]="true"
>
  <ease-announcements-popover
    *ngIf="announcementsPopover.isOpen()"
    (closed)="announcementsPopover.close()"
  ></ease-announcements-popover>
</sat-popover>
