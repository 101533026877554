import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import {
  ChainedCommands,
  Editor,
  markPasteRule,
  textblockTypeInputRule
} from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Typography from '@tiptap/extension-typography';
import CodeBlock from '@tiptap/extension-code-block';
import Highlight from '@tiptap/extension-highlight';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Mention from '@tiptap/extension-mention';
import Link from '@tiptap/extension-link';
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion';
import tippy from 'tippy.js';
import { Instance, Props } from 'tippy.js';

import { EnvironmentService } from '../../environment.service';
import { PROTOCOL_REGEXP } from '../../shared-validators';
import { Key } from '../../key-codes';
import { SNACKBAR_DURATION_ERROR } from '../../constants';
import { MarkdownService } from '../markdown.service';
import {
  MentionSuggestion,
  ProseSize,
  SuggestionEntity
} from '../markdown.interface';

const MARKDOWN_EDITOR_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MarkdownEditorComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'ease-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss'],
  providers: [MARKDOWN_EDITOR_CONTROL_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None
})
export class MarkdownEditorComponent
  implements AfterViewInit, ControlValueAccessor, OnDestroy
{
  @HostBinding('class') hostClass =
    'group flex flex-col bg-white border border-solid border-gray-300';
  @Input()
  placeholder: string = 'Start typing here...';
  @Input()
  persistenceKey: string;
  @Input() easeMentions = false;
  @Input() set size(size: ProseSize) {
    this.proseSizeClass = this.markdownService.getProseClass(size);
  }
  @Input() autoFocus: boolean = false;
  /**
   * When enabled, this will shrink the editor everytime it loses focus
   * -- reducing the height and hiding the menu bar. This utilizes the
   * `focus-within` pseudoclass.
   */
  @Input() autoCollapse = false;
  @ViewChild('tiptap') tiptapRef: ElementRef;
  @ViewChild('mentions') mentionsRef: ElementRef;
  @ViewChild('linkField') linkFieldRef: ElementRef;
  private onChange$: Subject<string> = new Subject<string>();
  private proseSizeClass = 'prose';
  private suggestions: MentionSuggestion[] = [];
  public editor: Editor;
  public suggestionProps: SuggestionProps;
  set selectedIndex(index: number) {
    this._selectedIndex = index;
    this.cdr.detectChanges();
  }
  get selectedIndex(): number {
    return this._selectedIndex;
  }
  private _selectedIndex: number;
  public linkUrl: FormControl<string>;
  public showLinkMenu = false;
  public showMarkMenu = false;
  public showTableMenu = false;
  public platformModifier: string = 'Ctrl/⌘';
  private MAX_SUGGESTIONS = 10;
  private DEFAULT_TIPPY_OPTIONS: Pick<
    Props,
    'showOnCreate' | 'interactive' | 'trigger' | 'placement'
  > = {
    showOnCreate: true,
    interactive: true,
    trigger: 'manual',
    placement: 'bottom-start'
  };

  /**
   * Managed by the markdown-variable directive
   */
  set variables(variables: string[]) {
    this._variables = variables;
    this.cdr.detectChanges();
  }
  get variables(): string[] {
    return this._variables;
  }
  private _variables: string[] = [];
  public variableProps: SuggestionProps;
  @ViewChild('variablesTemplate') variablesRef: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private environmentService: EnvironmentService,
    private formBuilder: FormBuilder,
    private markdownService: MarkdownService,
    private matSnackBar: MatSnackBar
  ) {}

  ngAfterViewInit() {
    this.onChange$
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => this.emitChange(this.getValue()));

    this.markdownService
      .getSuggestions()
      .pipe(untilDestroyed(this))
      .subscribe(suggestions => (this.suggestions = suggestions));

    this.environmentService.platformType$.pipe(take(1)).subscribe(platform => {
      switch (platform) {
        case 'MAC':
          this.platformModifier = '⌘';
          break;
        case 'WINDOWS':
          this.platformModifier = 'Ctrl';
      }
      this.cdr.detectChanges();
    });

    /**
     * Extends the `CodeBlock` Tiptap extension and adds an input rule that will automatically
     * toggle codeblocks by typing 3 backticks consecutively
     */
    const customCodeBlock = CodeBlock.extend({
      name: 'customCodeBlock',
      addInputRules() {
        return [
          textblockTypeInputRule({
            find: /^```/,
            type: this.type
          })
        ];
      }
    });

    /**
     * Extends the `Link` Tiptap extension and overrides the default paste rule to also include
     * characters such as parentheses
     */
    const customLink = Link.extend({
      addPasteRules() {
        return [
          markPasteRule({
            find: /https?:\S*/gi,
            type: this.type,
            getAttributes: url => ({ href: url })
          })
        ];
      }
    }).configure({
      openOnClick: false
    });

    /**
     * Create a new node for variables based on the existing "Mention" tiptap node.
     * Key differences are:
     * - different trigger char `{`
     * - custom HTML attribute `data-variable`
     * - different rendering `<span data-variable="...">...</span>
     *
     * Includes workaround for .destroy() issue seen here:
     * https://github.com/ueberdosis/tiptap/issues/2592
     */
    const VariableMention = Mention.extend({
      name: 'variable',
      addOptions: () => ({
        renderLabel: () => '',
        HTMLAttributes: {},
        suggestion: {
          char: '{',
          command: ({ editor, range, props }) =>
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'variable',
                  attrs: props
                },
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .run()
        }
      }),
      addAttributes: () => ({
        class: {
          default: 'font-bold text-blue-500'
        },
        id: {
          default: null,
          parseHTML: element => ({
            id: element.getAttribute('data-variable')
          }),
          renderHTML: attributes => {
            if (!attributes.id) {
              return {};
            }
            return {
              'data-variable': attributes.id
            };
          }
        }
      }),
      parseHTML: () => [
        {
          tag: 'span[data-variable]'
        }
      ],
      renderHTML: ({ node, HTMLAttributes }) => [
        'span',
        HTMLAttributes,
        `{${node.attrs.id}}`
      ]
    }).configure({
      suggestion: {
        items: ({ query }) =>
          this.variables
            .filter(variable =>
              variable.toLocaleLowerCase().includes(query.toLowerCase())
            )
            .slice(0, this.MAX_SUGGESTIONS),
        render: () => {
          let isDropdownInitialized = false;
          let wasDropdownDestroyed = false;
          let variablesMenu: Instance<Props>;

          return {
            onStart: (props: SuggestionProps) => {
              if (wasDropdownDestroyed) {
                wasDropdownDestroyed = false;
                return;
              }

              this.variableProps = props;
              this.selectedIndex = 0;

              [variablesMenu] = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: this.variablesRef.nativeElement,
                ...this.DEFAULT_TIPPY_OPTIONS
              });

              isDropdownInitialized = true;
            },
            onUpdate: (props: SuggestionProps) => {
              if (!isDropdownInitialized) {
                return;
              }

              this.variableProps = props;

              variablesMenu.setProps({
                getReferenceClientRect: props.clientRect
              });
              this.cdr.detectChanges();
            },
            onKeyDown: (props: SuggestionKeyDownProps) => {
              if (!isDropdownInitialized) {
                return;
              }

              /**
               * If there were no suggestions, exit early and let the event through.
               * This allows users to navigate on the editor whilst being on a mention
               * without any suggestions.
               */
              return this.variableProps.items.length > 0
                ? this.onKeyDown(props, 'variable')
                : false;
            },
            onExit: () => {
              if (!isDropdownInitialized) {
                wasDropdownDestroyed = true;
                return;
              }

              isDropdownInitialized = false;
              variablesMenu.destroy();
            }
          };
        }
      } as any
    });

    /**
     * Includes workaround for .destroy() issue seen here:
     * https://github.com/ueberdosis/tiptap/issues/2592
     */
    const UserMention = Mention.extend({ name: 'mention' }).configure({
      HTMLAttributes: {
        class: 'font-bold text-blue-500'
      },
      suggestion: {
        decorationClass: 'font-bold text-blue-500',
        items: ({ query }) =>
          this.suggestions
            .filter(
              item =>
                item.name.toLowerCase().includes(query.toLowerCase()) ||
                item.email.toLowerCase().includes(query.toLowerCase())
            )
            .slice(0, this.MAX_SUGGESTIONS),
        render: () => {
          let isDropdownInitialized = false;
          let wasDropdownDestroyed = false;
          let suggestionsMenu: Instance<Props>;

          return {
            onStart: (props: SuggestionProps) => {
              if (wasDropdownDestroyed) {
                wasDropdownDestroyed = false;
                return;
              }
              this.suggestionProps = props;
              this.selectedIndex = 0;

              [suggestionsMenu] = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: this.mentionsRef.nativeElement,
                ...this.DEFAULT_TIPPY_OPTIONS
              });

              isDropdownInitialized = true;
            },
            onUpdate: (props: SuggestionProps) => {
              if (!isDropdownInitialized) {
                return;
              }

              this.suggestionProps = props;

              suggestionsMenu.setProps({
                getReferenceClientRect: props.clientRect
              });
              this.cdr.detectChanges();
            },
            onKeyDown: (props: SuggestionKeyDownProps) => {
              if (!isDropdownInitialized) {
                return;
              }

              /**
               * If there were no suggestions, exit early and let the event through.
               * This allows users to navigate on the editor whilst being on a mention
               * without any suggestions.
               */
              return this.suggestionProps.items.length > 0
                ? this.onKeyDown(props, 'mention')
                : false;
            },
            onExit: () => {
              if (!isDropdownInitialized) {
                wasDropdownDestroyed = true;
                return;
              }

              isDropdownInitialized = false;
              suggestionsMenu.destroy();
            }
          };
        }
      }
    });

    this.editor = new Editor({
      element: this.tiptapRef.nativeElement,
      editorProps: {
        attributes: {
          class: `${this.proseSizeClass} p-2 overflow-auto max-w-full w-full`
        },
        transformPastedText: text => this.markdownService.sanitizeUrl(text)
      },
      extensions: [
        StarterKit.configure({
          // See: customCodeBlock
          codeBlock: false
        }),
        customCodeBlock,
        Typography.configure({
          oneHalf: false,
          oneQuarter: false,
          threeQuarters: false,
          openSingleQuote: false,
          closeSingleQuote: false,
          openDoubleQuote: false,
          closeDoubleQuote: false
        }),
        Highlight,
        Underline,
        Table.configure({
          resizable: false
        }),
        TableRow,
        TableHeader,
        TableCell,
        customLink,
        Placeholder.configure({
          placeholder: this.placeholder
        }),
        UserMention,
        VariableMention
      ],
      injectCSS: false,
      onCreate: ({ editor }: { editor: Editor }) => {
        this.autoFocus && editor.commands.focus();
      },
      onUpdate: this.onTiptapUpdate.bind(this),
      onBlur: this.onTiptapBlur.bind(this),
      onSelectionUpdate: ({ editor }: { editor: Editor }) => {
        this.showLinkMenu = editor.isActive('link');
        if (this.showLinkMenu) {
          const { href }: Record<string, string> = editor.getAttributes('link');
          this.linkUrl.setValue(href, { emitEvent: false });
        }

        this.showMarkMenu = !editor.state.selection.empty && !this.showLinkMenu;
        this.showTableMenu =
          editor.can().deleteTable() &&
          !this.showMarkMenu &&
          !this.showLinkMenu;

        this.cdr.detectChanges();
      }
    });

    this.restoreValue();

    this.linkUrl = this.formBuilder.control('');
    // force Angular to detect the changes after tiptap is set up
    this.cdr.detectChanges();
  }

  /**
   * This moves the caret at the end of the selection; if there was a selection.
   */
  endSelection() {
    if (!this.editor.state.selection.empty) {
      const end = this.editor.state.selection.to;
      this.editor.chain().focus().setTextSelection(end).run();
    }
  }

  toggleLink() {
    if (this.editor.isActive('link')) {
      this.unsetLink();
    } else {
      this.setLink('', true);
      this.linkFieldRef?.nativeElement.focus();
    }
  }

  /**
   * Links the current selection with the provided URL -- if the anchored
   * text is not a URL. This limitation was set to avoid a current issue with
   * markdown-it-flowdock: https://github.com/flowdock/markdown-it-flowdock/issues/7
   *
   * @param url
   * @param init when true, initializes the link with an empty string `''`
   */
  setLink(url: string, init = false) {
    /**
     * Get the anchored text by joining the strings from both left and right
     * of the cursor's position. `nodeBefore` and `nodeAfter` does not go
     * beyond the `<a>` tags so it's safe to assume that we're only getting
     * the anchored text and not anything else adjacent to it.
     *
     * This approach is a lot more reliable than just getting the selection which
     * could be a partial or even, none.
     */
    const anchoredText = `${this.editor.state.selection.$anchor.nodeBefore?.text}${this.editor.state.selection.$anchor.nodeAfter?.text}`;

    if (this.markdownService.urlTest.test(anchoredText)) {
      this.matSnackBar.open(
        `Modifying / Adding another link to a URL is not allowed`,
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );
    } else {
      /**
       * Prepend a default protocol only when all of these are true:
       * - `url` has value -- this avoids populating the field with the default protocol when the field is empty
       * - when the `url` doesn't start with `/` -- these links are internal Ease links
       * - when the protocol (`http://` or `https://`) is missing from the link
       */
      if (url && !/^\//.test(url) && !PROTOCOL_REGEXP.test(url)) {
        url = `http://${url}`;
      }

      url = this.markdownService.sanitizeUrl(url);

      init && this.linkUrl.setValue(url, { emitEvent: false });
      this.editor.chain().focus().setLink({ href: url }).run();
      !init && this.endSelection();
      this.showLinkMenu = init;
      this.showMarkMenu = !this.showLinkMenu;
    }
    this.cdr.detectChanges();
  }

  /**
   * Reverts the selection from a link node to a normal text.
   * Since a selection is still active, hide the link menu and show
   * the mark menu in it's place.
   */
  unsetLink() {
    this.editor.chain().focus().unsetLink().run();
    this.showLinkMenu = false;
    this.showMarkMenu = true;
    this.cdr.detectChanges();
  }

  /**
   * Handles the key events while the link field is focused.
   * Returns false when the Enter key is pressed to stop the editor
   * from inserting a new line after form submission.
   *
   * @param event
   * @returns
   */
  onLinkKeyDown(event: KeyboardEvent): boolean {
    switch (event.key) {
      case Key.ENTER:
        this.setLink(this.linkUrl.value);
        return false;
    }
  }

  getValue(): string {
    /**
     * This needs to be called again just in case the user copies and pastes a
     * "formatted" link from another rich text editor. Since tiptap has its own
     * paste rules and paste these links as is, this will lead to anchored URLS
     * which causes issues as mentioned on `transformAnchoredUrls()`
     */
    const transformedHtml = this.markdownService.prepareContent(
      this.editor.getHTML()
    );
    return this.markdownService.toMarkdown(transformedHtml);
  }

  /**
   * Sets the rendered HTML from `markdown-it` as content for `tiptap`. This intentionally
   * disables tiptap history for this action to stop users from _"undoing"_ this step
   *
   * @param markdown
   */
  setValue(markdown: string) {
    if (markdown) {
      const html = this.markdownService.render(markdown);
      this.editor
        .chain()
        .setContent(this.markdownService.prepareContent(html))
        .command(({ tr }) => {
          tr.setMeta('addToHistory', false);
          return true;
        })
        ?.run();
    }
  }

  menuCommand(chain: ChainedCommands) {
    chain.focus().run();
  }

  /**
   * Handles data transformation once an item from the suggestions is selected.
   * Sets the user's username from their email address as the selected value.
   *
   * @param index
   */
  selectItem(index: number, entity: SuggestionEntity = 'mention') {
    switch (entity) {
      case 'mention':
        const user = this.suggestionProps.items[index];
        user && this.suggestionProps.command({ id: user.email.split('@')[0] });
        break;
      case 'variable':
        const variable = this.variableProps.items[index];
        variable && this.variableProps.command({ id: variable });
    }
  }

  /**
   * Handles keyboard navigation for Tippy menus
   *
   * @param object a property that gets emitted by Tiptap
   * @param entity 'mention' (default) or 'variable'
   * @returns
   */
  onKeyDown(
    { event }: { event: KeyboardEvent },
    entity: SuggestionEntity = 'mention'
  ): boolean {
    let props: SuggestionProps;
    switch (entity) {
      case 'mention':
        props = this.suggestionProps;
        break;
      case 'variable':
        props = this.variableProps;
    }

    switch (event.key) {
      case Key.UP_ARROW:
        this.selectedIndex =
          (this.selectedIndex + props.items.length - 1) % props.items.length;
        return true;

      case Key.DOWN_ARROW:
        this.selectedIndex = (this.selectedIndex + 1) % props.items.length;
        return true;

      case Key.ENTER:
        this.selectItem(this.selectedIndex, entity);
        return true;

      default:
        this.selectedIndex = 0;
        return false;
    }
  }

  onTiptapUpdate() {
    this.emitChange(this.getValue());
  }

  onTiptapBlur() {
    this.emitChange(this.getValue());
  }

  onEditorBlur() {
    this.editor && this.emitChange(this.getValue());
  }

  onPaste() {
    setTimeout(() => this.emitChange(this.getValue()));
  }

  clear() {
    this.editor.commands.clearContent();
    this.emitChange('');
  }

  update(value: string) {
    setTimeout(() => {
      this.setValue(value);
    });
  }

  emitChange(value: string) {
    this.persistValue(value);
    this.onChange(value);
    this.cdr.markForCheck();
  }

  /**
   * Saves the value to sessionStorage for restoration later as needed
   *
   * @param value Value to persist to sessionStorage
   */
  persistValue(value: string) {
    if (this.persistenceKey) {
      value && value.trim()
        ? sessionStorage.setItem(this.persistenceKey, value)
        : sessionStorage.removeItem(this.persistenceKey);
    }
  }

  /**
   * Restores the value from sessionStorage if it's present and
   * the editor was configured with a persistenceKey
   */
  restoreValue() {
    if (this.persistenceKey) {
      const toRestore = sessionStorage.getItem(this.persistenceKey);
      toRestore && this.update(toRestore);
      setTimeout(() => {
        this.emitChange(this.getValue());
        this.cdr.detectChanges();
      });
    }
  }

  /** Implemented as part of ControlValueAccessor. */
  onChange: (value) => any = () => {
    /* */
  };

  onTouched: () => any = () => {
    /* */
  };

  writeValue(value: any) {
    this.update(value || '');
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.editor.destroy();
  }
}
