import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { SatPopoverModule } from '@ncstate/sat-popover';
import { MarkdownModule } from '../../markdown/markdown.module';
import { SharedModule } from '../../shared.module';
import { SharedPipesModule } from '../../shared-pipes.module';
import { AccountIconModule } from '../../account-icon/account-icon.module';
import { FeedItemNoteComponent } from './feed-item-note.component';

@NgModule({
  imports: [
    AccountIconModule,
    FormsModule,
    MarkdownModule,
    SatPopoverModule,
    PickerModule,
    EmojiModule,
    SharedModule,
    SharedPipesModule,
    ClipboardModule
  ],
  declarations: [FeedItemNoteComponent],
  exports: [FeedItemNoteComponent]
})
export class FeedItemNoteModule {}
