import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

import { CustomerMessageDetailsDialogComponent } from '../customer-message-details-dialog/customer-message-details-dialog.component';

@Directive({
  selector: '[easeCustomerMessagePreview]'
})
export class CustomerMessagePreviewDirective {
  @Input()
  easeCustomerMessagePreview: string;

  @Output()
  opened: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  expanded: EventEmitter<void> = new EventEmitter<void>();

  private dialogRef: MatDialogRef<CustomerMessageDetailsDialogComponent>;

  @HostListener('click')
  onClick() {
    this.dialogRef = this.dialog.open(CustomerMessageDetailsDialogComponent, {
      disableClose: false,
      width: '100vw',
      height: '100vh',
      panelClass: 'trim-dialog'
    });

    this.dialogRef.componentInstance.messageId =
      this.easeCustomerMessagePreview;

    firstValueFrom(this.dialogRef.afterOpened()).then(() => this.opened.emit());
  }

  constructor(private dialog: MatDialog) {}
}
