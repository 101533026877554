<ease-sheet class="popover">
  <ease-popover-header>Task Status</ease-popover-header>
  <div [ngSwitch]="mode">
    <div *ngSwitchCase="'init'">
      <ease-task-status-selector
        #statusSelector
        (selected)="onSelect($event)"
        (edit)="editStatus($event)"
        [selectedStatus]="selectedStatus"
      >
      </ease-task-status-selector>
      <ease-sheet-footer
        *ngIf="permissionsService.hasPermission('modifyTaskMetadata')"
      >
        <button
          type="button"
          (click)="switchMode('create')"
          color="primary"
          mat-button
        >
          Create Status
        </button>
      </ease-sheet-footer>
    </div>
    <div *ngSwitchCase="'create'">
      <form [formGroup]="statusForm" (ngSubmit)="createStatus()">
        <ease-sheet-content>
          <div class="form-group-sm">
            <mat-form-field>
              <input
                formControlName="name"
                placeholder="Status name"
                matInput
              />
              <mat-error *ngIf="statusForm.controls['name']?.errors?.required"
                >This field is required</mat-error
              >
              <mat-error
                *ngIf="statusForm.controls['name']?.errors?.nameExists"
                >{{
                  statusForm.controls['name']?.errors?.nameExists
                }}</mat-error
              >
            </mat-form-field>
          </div>
          <div class="flex flex-row flex-wrap items-center justify-center">
            <ease-color-blob
              *ngFor="let color of colorChoices"
              (click)="setStatusColor(color)"
              [color]="color"
              [size]="'lg'"
              [selected]="statusForm.controls.color.value === color"
              class="cursor-pointer"
            >
            </ease-color-blob>
          </div>
        </ease-sheet-content>
        <ease-sheet-footer>
          <button type="button" (click)="resetView()" mat-button>Cancel</button>
          <button
            type="submit"
            color="primary"
            mat-button
            [disabled]="statusForm.invalid"
          >
            Save
          </button>
        </ease-sheet-footer>
      </form>
    </div>
  </div>
</ease-sheet>
