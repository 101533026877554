export const availableColors = {
  grey: '#858484',
  green: '#99c83c',
  orange: '#faac4e',
  purple: '#994b81',
  pink: '#ca5985',
  blue: '#2196f3',
  red: '#e53d2e',
  yellow: '#fcf0ad',
  gold: '#d6af36',
  silver: '#a7a7ad',
  bronze: '#a77044',
  black: '#1d1f21'
};
