import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BindQueryParamsFactory } from '@ngneat/bind-query-params';
import { firstValueFrom, Observable } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TypedFormGroup } from 'src/app/shared/reactive-forms';
import { ChartOptions } from 'chart.js';
import { CHART_DEFAULT_OPTIONS } from '../../../shared/charts/charts';
import { Change } from '../../../shared/shared.interface';
import { CustomerBudget } from '../../customer-accounts/customer-account-budget/customer-account-budget.interface';
import { CustomerAccountRulesService } from '../../customer-accounts/customer-account-rules.service';
import { CustomerRulesService } from '../../customer-rules/customer-rules.service';
import { CustomerAccountsBudgetSummary } from '../customer-budget.interface';
import { CustomerRuleMetricsParams } from '../../customer-rules/customer-rules.interface';
import { ChartMetric } from '../../../shared/charts/charts.interface';

@UntilDestroy()
@Component({
  selector: 'ease-customer-budgets',
  templateUrl: './customer-budgets.component.html',
  styleUrls: ['./customer-budgets.component.scss']
})
export class CustomerBudgetsComponent implements OnDestroy, OnInit {
  @Input()
  set customerId(customerId: string) {
    this._customerId = customerId;

    this.customerBudgetSettings$ = this.customerRulesService.get(
      customerId,
      'budget'
    );

    this.getBudgetSummary();
    this.getMetrics();
  }

  get customerId(): string {
    return this._customerId;
  }

  public customerBudgetSettings$: Observable<CustomerBudget>;
  public customerAccountBudgetSettings: CustomerAccountsBudgetSummary;
  private _customerId: string;

  public chartOptions: ChartOptions = CHART_DEFAULT_OPTIONS;
  public metrics$: Observable<ChartMetric[]>;

  public dateForm: TypedFormGroup<CustomerRuleMetricsParams> =
    this.formBuilder.group({
      grouping: this.formBuilder.control('platforms'),
      date_range: this.formBuilder.control('last_30_days')
    });

  private bindQueryParamsManager = this.bindQueryParams
    .create<CustomerRuleMetricsParams>([
      { queryKey: 'date_range', type: 'string' },
      { queryKey: 'grouping', type: 'string' }
    ])
    .connect(this.dateForm);

  constructor(
    private bindQueryParams: BindQueryParamsFactory,
    private customerRulesService: CustomerRulesService,
    private customerAccountRulesService: CustomerAccountRulesService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.dateForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.getMetrics());
  }

  getMetrics() {
    this.metrics$ = this.customerRulesService.getMetrics(
      this.customerId,
      this.dateForm.value
    );
  }

  saveCustomerSettings(budgetChange: Change<Partial<CustomerBudget>>) {
    this.customerRulesService.updateBudget(this.customerId, budgetChange);
  }

  async getBudgetSummary() {
    this.customerAccountBudgetSettings = await firstValueFrom(
      this.customerAccountRulesService.getAllAccountBudgetSettings(
        this.customerId
      )
    );
  }

  ngOnDestroy() {
    this.bindQueryParamsManager.destroy();
  }
}
