import { NgModule } from '@angular/core';

import { PopoverModule } from '../popover.module';
import { SharedModule } from '../../shared.module';
import { SharedFormModule } from '../../shared-form.module';

import { TaskMovePopoverComponent } from './task-move-popover.component';

@NgModule({
  imports: [PopoverModule, SharedFormModule, SharedModule],
  declarations: [TaskMovePopoverComponent],
  exports: [TaskMovePopoverComponent]
})
export class TaskMovePopoverModule {}
