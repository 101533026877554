import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { compact, isEqual } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  from as observableFrom,
  Observable,
  of,
  ReplaySubject
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  share,
  startWith,
  switchMap
} from 'rxjs/operators';

import { ConfirmService } from 'src/app/shared/confirm/confirm.service';
import { EmailSendData } from 'src/app/shared/emails/email.interface';
import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import { ScrollspyService } from 'src/app/shared/scrollspy/scrollspy.service';
import { ToggleComponent } from 'src/app/shared/toggle/toggle.component';
import { SNACKBAR_DURATION_SUCCESS } from 'src/app/shared/constants';
import { WindowPaneViewTaskService } from 'src/app/shared/window/window-pane-view-task/window-pane-view-task.service';
import { WindowService } from 'src/app/shared/window/window.service';
import { AdminPermissionsService } from '../../admin/admin-roles/admin-permissions.service';
import { SharedLayoutService } from '../../shared/shared-layout.service';
import { EntityMetadata } from '../../shared/shared.interface';
import { WindowPane } from '../../shared/window/window-pane/window-pane';
import { UserModel } from '../../users/user.model';
import { ScheduledTaskService } from '../scheduled-tasks/scheduled-task.service';
import { SubtasksService } from '../task-project/subtasks.service';
import { TaskModel } from '../task.model';
import { TaskService } from '../task.service';

@UntilDestroy()
@Component({
  selector: 'ease-task-view-renderer',
  templateUrl: './task-view-renderer.component.html',
  styleUrls: ['./task-view-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskViewRendererComponent implements AfterViewInit, OnInit {
  @HostBinding('class') hostClass = 'flex flex-row flex-1 bg-white';
  @Input()
  set task(task: TaskModel) {
    this._task = task;

    this.setHasEditableScheduleTemplate();
    this.getCustomerIdForEntity();
    this.taskSource.next(task);
  }
  get task(): TaskModel {
    return this._task;
  }
  @Input()
  users: { [userId: string]: UserModel };
  @Output()
  save: EventEmitter<TaskModel> = new EventEmitter<TaskModel>();
  @Output()
  editScheduledTask: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('editEntityToggle', { read: ToggleComponent })
  editEntityToggle: ToggleComponent;
  @ViewChild('header') header: ElementRef;
  @ViewChild('compactHeader') compactHeader: ElementRef;
  get isCompleted(): boolean {
    return this.task && !!this.task.completedAt;
  }
  public customerId$: Observable<string>;
  public customerName$: Observable<string>;
  public hasEditableScheduleTemplate: boolean = false;
  private previousEntityId: string;
  private _task: TaskModel;
  private taskSource: ReplaySubject<TaskModel> = new ReplaySubject<TaskModel>();

  private headerOffset: BehaviorSubject<number> = new BehaviorSubject<number>(
    264
  );
  public showCompact$: Observable<boolean>;
  public parentTask$: Observable<TaskModel>;

  constructor(
    private confirmService: ConfirmService,
    private entityUtilsService: EntityUtilsService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private scheduledTaskService: ScheduledTaskService,
    public sharedLayoutService: SharedLayoutService,
    private scrollSpyService: ScrollspyService,
    public taskService: TaskService,
    public permissionsService: AdminPermissionsService,
    private windowPane: WindowPane,
    private windowPaneViewTaskService: WindowPaneViewTaskService,
    private windowService: WindowService,
    private subtasksService: SubtasksService
  ) {}

  ngOnInit(): void {
    this.showCompact$ = combineLatest([
      this.scrollSpyService.scrollingEvent$.pipe(startWith(null)),
      this.headerOffset.asObservable()
    ]).pipe(
      map(([event, offset]) => {
        this.windowPaneViewTaskService.setOffset({
          header: this.compactHeader?.nativeElement.offsetHeight || 0
        });

        return event?.target['scrollTop'] > offset;
      }),
      distinctUntilChanged(),
      share()
    );

    this.parentTask$ = this.taskSource.asObservable().pipe(
      switchMap(task =>
        task.parent ? this.taskService.get(task.parent) : of(null)
      ),
      distinctUntilChanged(),
      share()
    );
  }

  ngAfterViewInit(): void {
    const offset = this.header?.nativeElement.offsetHeight;
    offset && this.headerOffset.next(offset);
  }

  getCustomerIdForEntity(): void {
    if (
      this.task &&
      this.task.entityId &&
      this.task.entityId !== this.previousEntityId
    ) {
      this.previousEntityId = this.task.entityId;

      switch (this.task.entityType) {
        case 'customer':
        case 'prospect':
          this.customerId$ = of(this.task.entityId);
          this.customerName$ = of(this.task.entityName);
          break;

        case 'account':
          this.customerId$ = observableFrom(
            this.entityUtilsService.getCustomerIdForAccount({
              accountId: this.task.entityId,
              accountType: this.task.accountType
            })
          );
          this.customerName$ = observableFrom(
            this.entityUtilsService.getEntityField<string>(
              {
                entityId: this.task.entityId,
                entityType: this.task.entityType,
                accountType: this.task.accountType
              },
              'name'
            )
          );
          break;
      }
    }
  }

  async setHasEditableScheduleTemplate(): Promise<void> {
    if (this.task?.scheduledTaskTemplateId) {
      this.hasEditableScheduleTemplate = await firstValueFrom(
        this.scheduledTaskService.get(this.task.scheduledTaskTemplateId)
      ).then((task: any) => task.$exists());
    } else {
      this.hasEditableScheduleTemplate = false;
    }
  }

  saveTask(taskData): void {
    if (!this.isCompleted) {
      this.save.emit(Object.assign(this.task, taskData));
    }
  }

  onEntitySelected({
    entityId,
    entityType,
    entityName,
    accountType
  }: EntityMetadata): void {
    if (
      !this.isCompleted &&
      !isEqual(
        compact(
          Object.values({
            entityId: this.task.entityId,
            entityType: this.task.entityType,
            entityName: this.task.entityName,
            accountType: this.task.accountType
          })
        ),
        compact(
          Object.values({
            entityId,
            entityType,
            entityName,
            accountType
          })
        )
      )
    ) {
      this.editEntityToggle.hide();
      this.save.emit(
        Object.assign(this.task, {
          entityId,
          entityType,
          entityName,
          accountType
        })
      );
    }
  }

  onEntityDelete(): void {
    if (!this.isCompleted) {
      this.save.emit(
        Object.assign(this.task, {
          entityType: null,
          entityId: null,
          accountType: null,
          entityName: null
        })
      );
    }
  }

  // TODO on a separate PR: review and test this
  onScheduledTaskEdit(templateId: string): void {
    this.editScheduledTask.emit(templateId);
  }

  goToEntity(entityType: any, entityId: string, accountType?: string): void {
    this.windowPane.minimize();

    // if account, redirect to the customer's root route (customer dashboard) instead
    if (entityType === 'account') {
      this.entityUtilsService
        .getCustomerIdForAccount({
          accountId: entityId,
          accountType
        })
        .then(customerId => {
          this.router.navigate(['/customers', customerId]);
        });
    }
    // else, redirect to the usual page instead
    else {
      this.sharedLayoutService.navigateToEntity({
        entityType,
        entityId,
        accountType
      });
    }
  }

  async createEmailWindow(): Promise<void> {
    const data: EmailSendData = {
      customerId:
        this.task.entityType === 'customer' ||
        this.task.entityType === 'prospect'
          ? this.task.entityId
          : this.task.entityType === 'account'
          ? await this.entityUtilsService.getCustomerIdForAccount({
              accountId: this.task.entityId,
              accountType: this.task.accountType
            })
          : null,
      taskId: this.task.$key,
      taskType: this.task.taskType,
      notificationType: this.task.notificationType,
      variables: {
        declineDigits: this.task.declineDigits
      }
    };

    const emailWindowPane = await this.windowService.create({
      type: 'sendEmail',
      data
    });

    /**
     * When the email window is closed (either due to successful send, or cancel/close),
     * we re-open this task that originally opened it.
     */
    emailWindowPane.onClose.pipe(untilDestroyed(this)).subscribe(() => {
      this.windowPane.state === 'MINIMIZED' && this.windowPane.toggle();
    });
  }

  async destroy(): Promise<void> {
    const confirmResult = await this.confirmService.confirm({
      title: 'Destroy Task',
      message: 'This will destroy the task and all its subtasks. Are you sure?',
      confirmText: 'Destroy Task',
      cancelText: 'Cancel'
    });

    confirmResult.confirm && this.taskService.destroy(this.task, true);
  }

  scrollToTop(): void {
    this.scrollSpyService.scrollToTop();
  }

  showMessage(): void {
    this.matSnackBar.open('Copied to clipboard', null, {
      duration: SNACKBAR_DURATION_SUCCESS
    });
  }

  async detachSubtask(task: TaskModel): Promise<void> {
    const confirmDialog = await this.confirmService.confirm({
      title: 'Detach Subtask',
      message: 'Are you sure you want to detach this subtask from the project?',
      confirmText: 'Detach Subtask',
      cancelText: 'Cancel'
    });

    if (confirmDialog?.confirm) {
      await this.subtasksService.detach(task.parent, task.$key);
    }
  }
}
