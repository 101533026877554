<mat-form-field
  [floatLabel]="floatLabel || selectedItems.length ? 'always' : 'auto'"
  [ngClass]="{ 'disabled-field': disabled }"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-chip-list #chipList>
    <ng-container *ngFor="let item of selectedItems; let i = index">
      <ng-container
        [ngTemplateOutlet]="chipTemplate || defaultChipTemplate"
        [ngTemplateOutletContext]="{
          item: item,
          itemIndex: i,
          selectedCount: selectedItems.length
        }"
      >
      </ng-container>
    </ng-container>

    <input
      #autocompleteInput
      (paste)="onPaste($event)"
      [placeholder]="placeholder"
      [formControl]="autocompleteControl"
      [matChipInputAddOnBlur]="addOnBlur"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="userAddedChip($event)"
    />
  </mat-chip-list>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
    (optionSelected)="selected($event)"
    [ngSwitch]="grouped"
  >
    <ng-container *ngSwitchCase="true">
      <mat-optgroup
        *ngFor="let group of $any(filteredItems$ | async)"
        [label]="group.name"
      >
        <ng-container *ngFor="let item of group.items">
          <mat-option [value]="item.value" *ngIf="!item.hidden">
            {{ item.viewValue }}
          </mat-option>
        </ng-container>
      </mat-optgroup>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngFor="let item of $any(filteredItems$ | async)">
        <mat-option [value]="item.value" *ngIf="!item.hidden">
          {{ item.viewValue }}
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>

<ng-template #defaultChipTemplate let-item="item">
  <mat-chip
    [selectable]="selectable"
    [removable]="removable"
    (removed)="remove(item)"
  >
    {{ item.viewValue }}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
  </mat-chip>
</ng-template>
