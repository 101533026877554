<ease-sheet class="flex min-h-0 flex-1 flex-col">
  <ng-container *ngIf="parentTask$ | async as parentTask">
    <div
      class="bg-white p-3 px-5 shadow-sm"
      [easeTaskWindower]="parentTask.$key"
    >
      <div class="mb-2 text-xs text-gray-600">Creating sub-task for:</div>
      <div
        class="flex cursor-pointer flex-row items-center justify-start gap-2 rounded-md border border-gray-300 p-3 px-5"
      >
        <mat-icon>folder</mat-icon>
        <div>
          <div class="text-sm font-semibold">
            {{ parentTask.name }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <mat-button-toggle-group
    *ngIf="
      permissionService.hasPermission('createTasksInBulk') && enableBulkCreation
    "
    [formControl]="quantity"
    class="m-5 mb-0"
  >
    <mat-button-toggle value="single" class="flex-1">
      <div class="flex items-center text-[14px] font-medium">
        <mat-icon class="mat-icon-20 mr-2">playlist_add</mat-icon> Single
      </div>
    </mat-button-toggle>
    <mat-button-toggle value="bulk" class="flex-1">
      <div class="flex items-center text-[14px] font-medium">
        <mat-icon class="mat-icon-20 mr-2">queue</mat-icon> Bulk
      </div>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <ng-container [ngSwitch]="currentQuantity">
    <ease-create-task-single
      *ngSwitchCase="'single'"
      [data]="data$ | async"
      [taskTemplates]="taskTemplates$ | async"
      [templateControl]="templateControl"
      (create)="create.emit($event)"
      class="flex min-h-0 flex-1 flex-col"
    ></ease-create-task-single>
    <ease-create-task-bulk
      *ngSwitchCase="'bulk'"
      [data]="data$ | async"
      [taskTemplates]="taskTemplates$ | async"
      [templateControl]="templateControl"
      (queued)="queued = true"
      (create)="create.emit($event)"
      (creating)="$event ? quantity.disable() : quantity.enable()"
      class="flex min-h-0 flex-1 flex-col"
    ></ease-create-task-bulk>
  </ng-container>
</ease-sheet>
