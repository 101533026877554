<mat-card class="ad-set-container mb-2">
  <ease-collapse [collapsed]="collapsed">
    <div class="collapse-title flex flex-row items-center justify-start">
      <div
        class="number mr-5 flex h-8 w-8 flex-row items-center justify-center rounded-full bg-blue-400 font-bold text-white"
      >
        {{ index + 1 }}
      </div>
      <div class="text-container flex-1">
        <h4 class="mb-0 mt-0 font-bold">
          {{
            adSetControl.controls.name.enabled &&
            adSetControl.controls.name.value
              ? adSetControl.controls.name.value
              : 'Ad Set ' + (index + 1)
          }}
        </h4>
        <div class="mb-0 mt-0 flex flex-row items-center justify-start text-sm">
          <div class="mr-5 flex flex-row items-center justify-center">
            <mat-icon class="mr-2">link</mat-icon>
            <span class="url mr-1 max-w-sm truncate">{{
              adSetControl.controls.displayUrl.value || 'No URL Assigned'
            }}</span>
          </div>
          <div class="mr-5 flex flex-row items-center justify-center">
            <mat-icon class="mr-2">burst_mode</mat-icon>
            <span
              >{{ adSetControl.controls.files['controls'].length }} Remarketing
              Ads</span
            >
          </div>
          <div
            class="mr-5 flex flex-row items-center justify-center"
            *ngIf="adSetControl.controls.hiring.value"
          >
            <mat-icon class="mr-2">work</mat-icon>
            <span>Hiring Campaign</span>
          </div>
        </div>
      </div>
      <div class="mr-14 flex flex-row items-center space-x-2">
        <button mat-icon-button (click)="onDelete()">
          <mat-icon>delete</mat-icon>
        </button>
        <div class="valid-item mt-1 text-green-500" *ngIf="adSetControl.valid">
          <mat-icon>check_circle</mat-icon>
        </div>
      </div>
    </div>
    <div
      class="collapse-content mt-5 border-t border-solid border-gray-200 p-4"
      [formGroup]="adSetControl"
    >
      <div class="flex flex-row" *ngIf="adSetControl.controls.name.enabled">
        <mat-form-field class="mb-2 mr-5" *ngIf="!dropdownName">
          <input matInput placeholder="Name of Ad Set" formControlName="name" />
          <mat-hint>Eg. Finance, Hiring, Branding, etc...</mat-hint>
          <mat-error
            easeFormErrors
            [control]="adSetControl.controls.name"
          ></mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="dropdownName">
          <mat-select placeholder="Name of Ad Set" formControlName="name">
            <mat-option
              *ngFor="let name of names$ | async"
              [value]="name.text"
              [disabled]="name.disabled"
            >
              {{ name.text }}
            </mat-option>
          </mat-select>
          <mat-error
            easeFormErrors
            [control]="adSetControl.controls.name"
          ></mat-error>
        </mat-form-field>
        <mat-slide-toggle
          color="primary"
          formControlName="hiring"
          *ngIf="adSetControl.controls.hiring.enabled"
          >Hiring Campaign</mat-slide-toggle
        >
      </div>
      <mat-form-field class="mb-2">
        <input
          matInput
          placeholder="Landing Page URL"
          formControlName="displayUrl"
        />
        <mat-error
          easeFormErrors
          [control]="adSetControl.controls.displayUrl"
        ></mat-error>

        <a
          *ngIf="adSetControl.controls.displayUrl.valid"
          [href]="adSetControl.controls.displayUrl.value"
          target="_blank"
          class="mr-5 flex flex-row items-center justify-center"
          matSuffix
        >
          <mat-icon>launch</mat-icon>
        </a>
      </mat-form-field>
      <ease-file-uploader
        [fileFormArray]="filesArray"
        [maxFileSize]="153600"
        [validDimensions]="validAdsDimensions"
        #fileUploader
      ></ease-file-uploader>
    </div>
  </ease-collapse>
</mat-card>
