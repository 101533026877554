import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { GridOptions } from 'ag-grid-community';

import { AccountDashboardService } from 'src/app/accounts/account-dashboard/account-dashboard.service';
import { Account } from 'src/app/accounts/account-dashboard/account-dashboard.interface';
import { AccountDashboardTableComponent } from 'src/app/accounts/account-dashboard/account-dashboard-table/account-dashboard-table.component';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { WidgetOptions } from '../../customer-dashboard-widget.interface';

@Component({
  selector: 'ease-customer-dashboard-widget-accounts-dashboard-shared-table',
  templateUrl:
    './customer-dashboard-widget-accounts-dashboard-shared-table.component.html',
  styleUrls: [
    './customer-dashboard-widget-accounts-dashboard-shared-table.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerDashboardWidgetAccountsDashboardSharedTableComponent
  implements OnInit
{
  @Input() customerId$: Observable<string>;
  @Input() options: WidgetOptions;
  @ViewChild('table')
  table: AccountDashboardTableComponent;
  public accounts$: Observable<Account[]>;
  public gridOptions: GridOptions;
  private hideColumns: string[] = ['id', 'budget'];
  public dataReady: boolean;
  public tableHeight: number;

  constructor(private accountDashboardService: AccountDashboardService) {}

  ngOnInit(): void {
    this.accounts$ = combineLatest([
      this.customerId$,
      this.accountDashboardService.reload$.pipe(startWith(true))
    ]).pipe(
      switchMap(([customerId]) =>
        this.accountDashboardService.getAccountsByCustomer(customerId)
      ),
      tap(accounts => {
        // hide the table until all overrides are done
        this.dataReady = false;

        // set table height
        const tableHeight: {
          rowHeight: number;
          headerHeight: number;
        } = this.table.mainGrid.api.getSizesForCurrentTheme();
        const statusBarHeight = 52;
        const rowCount = !accounts || accounts.length < 3 ? 3 : accounts.length;

        // add 1 for header border to remove overflow/scrollbar
        this.tableHeight =
          tableHeight.headerHeight +
          1 +
          tableHeight.rowHeight * rowCount +
          statusBarHeight;
      })
    );

    this.gridOptions = {
      sideBar: false
    };
  }

  // AMD table overrides
  onDataReady() {
    // hide extra columns
    this.table.mainGrid.columnApi.setColumnsVisible(this.hideColumns, false);

    this.dataReady = true;
  }
}
