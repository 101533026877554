import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';

import { CustomerAccountsService } from 'src/app/customers/customer-accounts/customer-accounts.service';
import { BillingCustomer } from 'src/app/customers/customer-billing/customer-billing.interface';
import { CustomerBillingService } from 'src/app/customers/customer-billing/customer-billing.service';
import { MatRadioChange } from '@angular/material/radio';

import {
  TypedFormControl,
  TypedFormGroup
} from 'src/app/shared/reactive-forms';
import { buildFormControls } from '../../../../utils/functions';
import { SNACKBAR_DURATION_SUCCESS } from '../../../../constants';
import { FundingService } from '../../../funding.service';
import {
  AdvertisingAccount,
  FundingEventLineType,
  FundingSettings,
  FundingSettingsAccount,
  FundingSettingsAccountGroup
} from '../../../funding.interface';
import {
  AdwordsAccount,
  BingAccount,
  LsaAccount
} from '../../../../../customers/customer-accounts/customer-accounts.interface';

const accountValidator: ValidatorFn = (control: AbstractControl) => {
  const controlValue = control.value;
  const paymentType = controlValue.paymentType;
  const advertisingAccount = controlValue.advertisingAccount;

  if (paymentType === 'fundingPercentage') {
    if (advertisingAccount && advertisingAccount.length > 1) {
      return {
        tooManyAccounts: {
          message: 'You can only choose one account for this payment type'
        }
      };
    }
  }
  return null;
};

@Component({
  selector: 'ease-funding-summary-settings-dialog',
  templateUrl: './funding-summary-settings-dialog.component.html',
  styleUrls: ['./funding-summary-settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundingSummarySettingsDialogComponent implements OnInit {
  @Input() customerId: string;
  @Output() whenSaved: EventEmitter<void> = new EventEmitter<void>();

  private accountTypeNamingMapping = {
    adwords: 'Google Ads',
    lsa: 'Google Local Services',
    bing: 'Bing'
  };
  public viewFundingPaymentOptions: boolean;
  public viewFundingPercentagePaymentOptions: boolean;
  private allAccounts$: Observable<
    [AdwordsAccount[], LsaAccount[], BingAccount[]]
  >;
  public customerAccountsList$: Observable<FundingSettingsAccountGroup[]>;
  public fundingTypes: FundingEventLineType[];
  public billingCustomer$: Observable<BillingCustomer>;
  public fundingSettingsForm: TypedFormGroup<{
    paymentType: string;
    fundingPaymentTypeSettings: {
      managementFeeType: number;
      managementFeeAmount: number;
      billingDay: number;
    };
    fundingPercentagePaymentTypeSettings: {
      managementFeeType: number;
    };
    advertisingAccount: FundingSettingsAccount[];
  }> = this.formBuilder.group(
    {
      paymentType: this.formBuilder.control('', {
        validators: Validators.required
      }),
      fundingPaymentTypeSettings: this.formBuilder.group({
        managementFeeType: this.formBuilder.control(null, {
          validators: Validators.required
        }),
        managementFeeAmount: this.formBuilder.control(null, {
          validators: [Validators.required, Validators.min(0)]
        }),
        billingDay: this.formBuilder.control(null, {
          validators: [
            Validators.required,
            Validators.min(1),
            Validators.max(28)
          ]
        })
      }),
      fundingPercentagePaymentTypeSettings: this.formBuilder.group({
        managementFeeType: this.formBuilder.control(null, {
          validators: Validators.required
        })
      }),
      advertisingAccount: this.formBuilder.array<
        TypedFormGroup<FundingSettingsAccount>
      >([], {
        validators: [Validators.required, accountValidator]
      })
    },
    { validators: [accountValidator] }
  );

  get fundingPaymentTypeSettingsGroup(): typeof this.fundingSettingsForm.controls.fundingPaymentTypeSettings {
    return this.fundingSettingsForm.controls.fundingPaymentTypeSettings;
  }

  get fundingPercentagePaymentTypeSettingsGroup(): typeof this.fundingSettingsForm.controls.fundingPercentagePaymentTypeSettings {
    return this.fundingSettingsForm.controls
      .fundingPercentagePaymentTypeSettings;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private customerAccountService: CustomerAccountsService,
    private customerBillingService: CustomerBillingService,
    public dialogRef: MatDialogRef<FundingSummarySettingsDialogComponent>,
    private formBuilder: FormBuilder,
    public fundingService: FundingService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    // check if customer has fusebill set up, if it doesn't disable `Funding` as a paymenth method for management Fee
    this.billingCustomer$ = this.customerBillingService
      .getBillingCustomer(this.customerId)
      .pipe(
        catchError(err => of({} as BillingCustomer)),
        take(1)
      );

    // get customer funding settings
    this.fundingService.setFundingCustomerId(this.customerId);

    this.fundingService.fundingSettings$.pipe(take(1)).subscribe(settings => {
      if (settings.exist) {
        this.setFormData(settings);

        switch (settings.paymentSource) {
          case 'funding':
            this.viewFundingPaymentOptions = true;
            this.viewFundingPercentagePaymentOptions = false;
            this.fundingSettingsForm.controls.fundingPercentagePaymentTypeSettings.disable();
            break;
          case 'fundingPercentage':
            this.viewFundingPercentagePaymentOptions = true;
            this.viewFundingPaymentOptions = false;
            this.fundingSettingsForm.controls.fundingPaymentTypeSettings.disable();
            break;
          case 'customer':
            this.viewFundingPaymentOptions = false;
            this.fundingSettingsForm.controls.fundingPaymentTypeSettings.disable();
            this.viewFundingPercentagePaymentOptions = false;
            this.fundingSettingsForm.controls.fundingPercentagePaymentTypeSettings.disable();
            break;
        }
      } else {
        this.viewFundingPaymentOptions = false;
        this.viewFundingPercentagePaymentOptions = false;
      }
    });

    // get all management funding types
    this.fundingService
      .getFundingTypes()
      .subscribe(data => this.getFundingTypes(data));

    // initialize customer account list
    this.allAccounts$ = combineLatest([
      this.customerAccountService.getAdwordsAccounts(this.customerId),
      this.customerAccountService.getLsaAccounts(this.customerId),
      this.customerAccountService.getBingAccounts(this.customerId)
    ]).pipe(shareReplay({ refCount: true }));

    this.customerAccountsList$ = this.allAccounts$.pipe(
      map(allAccounts => {
        const accountSelectArray: FundingSettingsAccountGroup[] = [];
        allAccounts.forEach(accountList => {
          if (accountList.length) {
            const accountType = accountList[0].$accountType;
            const nestedItems: FundingSettingsAccount[] = [];

            accountList.forEach(account =>
              nestedItems.push({
                id: account.$key,
                type: accountType,
                name: account.name
              })
            );

            accountSelectArray.push({
              name: this.accountTypeNamingMapping[accountType],
              type: accountType,
              accounts: nestedItems
            });
          }
        });

        return accountSelectArray;
      })
    );
  }

  getFundingTypes(data: FundingEventLineType[]) {
    this.fundingTypes = data.filter(
      type =>
        type.billingAction === 'subscription' ||
        type.billingPlanProduct?.code === 'googlepurpleplanpurchase'
    );

    this.cdr.detectChanges();
  }

  setFormData(fundingSettings: FundingSettings): void {
    this.fundingSettingsForm.controls.paymentType.setValue(
      fundingSettings.paymentSource
    );

    switch (fundingSettings.paymentSource) {
      case 'funding':
        this.fundingPaymentTypeSettingsGroup.controls.managementFeeType.setValue(
          fundingSettings.fundingEventLineType.id
        );
        this.fundingPaymentTypeSettingsGroup.controls.managementFeeAmount.setValue(
          fundingSettings.managementFee
        );
        this.fundingPaymentTypeSettingsGroup.controls.billingDay.setValue(
          fundingSettings.billingDay
        );
        break;
      case 'fundingPercentage':
        this.fundingPercentagePaymentTypeSettingsGroup.controls.managementFeeType.setValue(
          fundingSettings.fundingEventLineType.id
        );
        break;
    }

    fundingSettings.advertisingAccount.forEach(account => {
      this.addAccount(account);
    });
  }

  getPostPayload() {
    const advertisingAccounts =
      this.fundingSettingsForm.controls.advertisingAccount.value;
    const paymentSource = this.fundingSettingsForm.controls.paymentType.value;

    // only provide values for these when mngmt fee payment type is set to 'funding'
    const managementFee =
      paymentSource === 'funding'
        ? this.fundingPaymentTypeSettingsGroup.controls.managementFeeAmount
            .value / advertisingAccounts.length
        : null;

    const billingDay =
      paymentSource === 'funding'
        ? this.fundingPaymentTypeSettingsGroup.controls.billingDay.value
        : null;

    const fundingEventLineTypeId = type => {
      switch (type) {
        case 'funding':
          return this.fundingPaymentTypeSettingsGroup.controls.managementFeeType
            .value;
        case 'fundingPercentage':
          return this.fundingPercentagePaymentTypeSettingsGroup.controls
            .managementFeeType.value;
        default:
          return null;
      }
    };

    return advertisingAccounts.map(account => ({
      paymentSource,
      customer: this.customerId,
      managementFee,
      billingDay,
      advertisingAccount: {
        id: account.id,
        type: account.type
      },
      fundingEventLineType: {
        id: fundingEventLineTypeId(paymentSource)
      }
    }));
  }

  // show payment type advanced settings and enable appropriate formgroup
  paymentTypeChange(event: MatRadioChange): void {
    this.viewFundingPaymentOptions = false;
    this.fundingPaymentTypeSettingsGroup.disable();
    this.viewFundingPercentagePaymentOptions = false;
    this.fundingPercentagePaymentTypeSettingsGroup.disable();
    switch (event.value) {
      case 'funding':
        this.viewFundingPaymentOptions = true;
        this.fundingPaymentTypeSettingsGroup.enable();
        break;
      case 'fundingPercentage':
        this.viewFundingPercentagePaymentOptions = true;
        this.fundingPercentagePaymentTypeSettingsGroup.enable();
        break;
    }

    // re-check accounts control validity
    this.fundingSettingsForm.controls.advertisingAccount.updateValueAndValidity();
  }

  checkAdvertisingAccounts(accountId: string): FundingSettingsAccount {
    const accounts = this.fundingSettingsForm.controls.advertisingAccount.value;
    return accounts.find(
      account => account.id === accountId
    ) as FundingSettingsAccount;
  }

  addAccount(account: AdvertisingAccount): void {
    const accountArray = this.fundingSettingsForm.controls.advertisingAccount;
    const accountFormGroup = this.formBuilder.group<
      TypedFormControl<FundingSettingsAccount>
    >({} as any);
    buildFormControls(account, accountFormGroup);
    accountArray.push(accountFormGroup);
  }

  removeAccount(index: number): void {
    const accountArray = this.fundingSettingsForm.controls.advertisingAccount;
    accountArray.removeAt(index);
  }

  onSubmit() {
    // prepare payload and send post request to server
    firstValueFrom(
      this.fundingService.saveCustomerFundingSettings(
        this.customerId,
        this.getPostPayload()
      )
    ).then(response => {
      this.matSnackBar.open('Funding settings successfully updated.', 'Close', {
        duration: SNACKBAR_DURATION_SUCCESS
      });
      this.dialogRef.close();
      this.fundingService.reloadFundingData();
      this.whenSaved.emit();
    });
  }
}
