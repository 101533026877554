import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';

import { AnalyticsLinkPipe } from './analytics-link.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { ClientIdToAdwordsIdPipe } from './client-id-to-adwords-id.pipe';
import { DecamelizePipe } from './decamelize.pipe';
import { FilesizePipe } from './filesize.pipe';
import { LimitPipe } from './limit.pipe';
import { ObjectLengthPipe } from './object-length.pipe';
import { ObjectToIterablePipe } from './object-to-iterable.pipe';
import { OrderByPipe } from './order-by.pipe';
import { SearchPipe } from './search.pipe';
import { HighlightPipe } from './highlight.pipe';
import { TimezoneDateFormatPipe } from './timezone-date-format.pipe';
import { MinuteSecondsPipe } from './minutes-seconds.pipe';

@NgModule({
  declarations: [
    AnalyticsLinkPipe,
    CapitalizePipe,
    ClientIdToAdwordsIdPipe,
    DecamelizePipe,
    FilesizePipe,
    LimitPipe,
    MinuteSecondsPipe,
    TimezoneDateFormatPipe,
    ObjectLengthPipe,
    ObjectToIterablePipe,
    OrderByPipe,
    SearchPipe,
    HighlightPipe
  ],
  exports: [
    AnalyticsLinkPipe,
    CapitalizePipe,
    ClientIdToAdwordsIdPipe,
    DecamelizePipe,
    FilesizePipe,
    LimitPipe,
    TimezoneDateFormatPipe,
    MomentModule,
    MinuteSecondsPipe,
    ObjectLengthPipe,
    ObjectToIterablePipe,
    OrderByPipe,
    SearchPipe,
    HighlightPipe
  ],
  providers: [CapitalizePipe]
})
export class SharedPipesModule {}
