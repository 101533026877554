import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

import { ScheduledTaskDetails } from '../../scheduled-tasks/scheduled-task.interface';
import { ScheduledTaskEditDialogComponent } from '../../scheduled-tasks/scheduled-task-edit-dialog/scheduled-task-edit-dialog.component';

@Component({
  selector: 'ease-task-panel-scheduled',
  templateUrl: './task-panel-scheduled.component.html',
  styleUrls: [
    '../task-panel/task-panel.component.scss',
    './task-panel-scheduled.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskPanelScheduledComponent {
  @Input() task: ScheduledTaskDetails;
  @Input() key: string;
  @Output()
  opened: EventEmitter<void> = new EventEmitter<void>();
  public activeState: boolean = false;

  constructor(private matDialog: MatDialog) {}

  editTask(taskId: string) {
    const editRef = this.matDialog.open(ScheduledTaskEditDialogComponent);
    editRef.componentInstance.taskId = taskId;
    firstValueFrom(editRef.afterOpened()).then(() => this.opened.emit());
  }

  popoverState(state) {
    if (!state) {
      return (this.activeState = false);
    }

    this.activeState = true;
  }
}
