<h2>Ad Extension Disapproval</h2>
<div class="disaproval-container">
  <div
    class="disaproval-item flex flex-row shadow-md"
    *ngFor="let item of items"
  >
    <div class="icon-warning">
      <mat-icon>warning</mat-icon>
    </div>
    <div class="left max-w-[30%] flex-full">
      <div class="info-block">
        <label>Type</label>
        <p class="info-text">{{ item?.placeholderType || 'No Type Given' }}</p>
      </div>
      <div class="info-block" *ngIf="item?.disapprovalReasons">
        <label>Reason</label>
        <p class="info-text" *ngFor="let reason of item?.disapprovalReasons">
          {{ reason }}
        </p>
      </div>
    </div>
    <div class="right max-w-[70%] flex-full">
      <div class="info-block">
        <label>Fields</label>
        <ul class="fields-list">
          <li *ngFor="let field of item?.extensionFields">
            <strong>{{ field.label }}: </strong>
            <span>{{ field.value }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
