import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedFormModule } from 'src/app/shared/shared-form.module';
import { FormErrorsModule } from 'src/app/shared/form-errors/form-errors.module';
import { NgxMaskModule } from 'ngx-mask';

import { AdvancedAutocompleteModule } from '../../shared/advanced-autocomplete/advanced-autocomplete.module';
import { FileUploaderModule } from '../../shared/file-uploader/file-uploader.module';
import { AccountIconModule } from '../../shared/account-icon/account-icon.module';
import { MaterialChipsInputModule } from '../../shared/material-chips-input/material-chips-input.module';
import { RichTooltipModule } from '../../shared/rich-tooltip/rich-tooltip.module';
import { AdvancedMultiSelectorModule } from '../../shared/advanced-multi-selector/advanced-multi-selector.module';
import { AdSetItemComponent } from './ad-set-item/ad-set-item.component';
import { AccountBuilderComponent } from './account-builder.component';
import { SearchCampaignBuilderComponent } from './search-campaign-builder/search-campaign-builder.component';
import { SearchCampaignPreviewComponent } from './search-campaign-preview/search-campaign-preview.component';
import { NegativeKeywordsSelectorComponent } from './negative-keywords-selector/negative-keywords-selector.component';

@NgModule({
  imports: [
    AdvancedAutocompleteModule,
    AdvancedMultiSelectorModule,
    CommonModule,
    SharedModule,
    SharedFormModule,
    FormErrorsModule,
    FileUploaderModule,
    NgxMaskModule,
    AccountIconModule,
    MaterialChipsInputModule,
    RichTooltipModule
  ],
  declarations: [
    AccountBuilderComponent,
    AdSetItemComponent,
    SearchCampaignBuilderComponent,
    SearchCampaignPreviewComponent,
    NegativeKeywordsSelectorComponent
  ],
  exports: [AccountBuilderComponent]
})
export class AccountBuilderModule {}
