import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SatPopoverModule } from '@ncstate/sat-popover';

import { AvatarModule } from '../avatar/avatar.module';
import { SelectUserPopoverModule } from '../popover/select-user/select-user-popover.module';

import { UserSelectorInputComponent } from './user-selector-input.component';
import { UserSelectorInputService } from './user-selector-input.service';

@NgModule({
  imports: [
    AvatarModule,
    CommonModule,
    DragDropModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    SatPopoverModule,
    SelectUserPopoverModule
  ],
  declarations: [UserSelectorInputComponent],
  exports: [UserSelectorInputComponent],
  providers: [UserSelectorInputService]
})
export class UserSelectorInputModule {}
