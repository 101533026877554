<div class="p-1">
  <mat-icon matTooltip="Properties">label_important_outline</mat-icon>
</div>

<div class="flex flex-row flex-wrap items-center gap-2">
  <ease-task-status
    [selectedStatus]="task.status"
    (statusChanged)="changeStatus($event)"
    matTooltip="Status"
    class="rounded-full bg-white p-2 py-1 drop-shadow"
  ></ease-task-status>

  <ease-task-phase
    [selectedPhase]="task.phase"
    (phaseChanged)="changePhase($event)"
    matTooltip="Phase"
    class="rounded-full bg-white p-2 py-1 drop-shadow"
  ></ease-task-phase>

  <div
    matTooltip="Start Date"
    class="rounded-full bg-white p-2 py-1 text-[13px] font-medium leading-6 text-gray-800 drop-shadow"
  >
    <div
      #startDate
      class="relative flex cursor-pointer flex-row items-center gap-2"
    >
      <mat-icon class="mat-icon-18 text-gray-600">play_circle_outline</mat-icon>
      <span
        (click)="startDatePicker.showPicker()"
        [ngClass]="{ 'mr-2': !task.startDate }"
      >
        {{
          task?.startDate
            ? (task?.startDate | amDateFormat: 'MMM D, YYYY')
            : 'Set Start Date'
        }}
      </span>
      <mat-icon
        *ngIf="task.startDate"
        (click)="updateTask({ startDate: null })"
        class="mat-icon-18 text-gray-600"
      >
        close
      </mat-icon>
    </div>
    <ease-datepicker
      #startDatePicker
      [ngModel]="task.startDate"
      (ngModelChange)="updateTask({ startDate: $event })"
      [startDate]="task?.startDate"
      [customAnchor]="startDate"
    ></ease-datepicker>
  </div>

  <div matTooltip="Due Date" class="rounded-full bg-white p-2 py-1 drop-shadow">
    <div
      #dueDate
      class="relative flex cursor-pointer flex-row items-center gap-2"
    >
      <div
        class="flex flex-row items-center gap-2 text-gray-800"
        [ngClass]="{
          'font-bold !text-red-500':
            task?.dueDate && task?.dueDate < nowTimeStamp,
          'text-orange-600': task?.dueDate && task?.dueDate === nowTimeStamp
        }"
      >
        <mat-icon
          class="mat-icon-18 text-gray-600"
          [ngClass]="{
            '!text-red-500': task?.dueDate && task?.dueDate < nowTimeStamp
          }"
        >
          {{ task?.dueDate < nowTimeStamp ? 'warning' : 'date_range' }}
        </mat-icon>
        <span
          (click)="dueDatePicker.showPicker()"
          class="text-[13px] font-medium leading-6"
          [ngClass]="{ 'mr-2': !task.startDate }"
        >
          {{
            task?.dueDate
              ? (task?.dueDate | amDateFormat: 'MMM D, YYYY')
              : 'Set Due Date'
          }}
        </span>
      </div>
      <mat-icon
        *ngIf="task?.dueDate"
        (click)="updateTask({ dueDate: null })"
        class="mat-icon-18 cursor-pointer text-gray-600"
      >
        close
      </mat-icon>
    </div>
    <ease-datepicker
      #dueDatePicker
      [min]="now"
      [ngModel]="task.dueDate"
      (ngModelChange)="updateTask({ dueDate: $event })"
      [startDate]="task?.dueDate"
      [customAnchor]="dueDate"
    ></ease-datepicker>
  </div>

  <div class="rounded-full bg-white p-2 py-1 drop-shadow" matTooltip="Priority">
    <div
      (click)="priorityPopover.open()"
      [satPopoverAnchor]="priorityPopover"
      class="flex cursor-pointer flex-row items-center gap-2 text-[13px] font-medium capitalize leading-6 text-gray-800"
    >
      <mat-icon class="mat-icon-18 text-gray-600">low_priority</mat-icon>

      <ng-container
        *ngIf="!!taskService.priorityNames[task.priority]; else expandMore"
      >
        <span>
          {{ taskService.priorityNames[task.priority] }}
        </span>
        <mat-icon
          (click)="setPriority(); $event.stopPropagation()"
          class="mat-icon-18 text-gray-600"
        >
          close
        </mat-icon>
      </ng-container>
      <ng-template #expandMore>
        <span>Set Priority</span>
        <mat-icon class="mat-icon-18 text-gray-600">expand_more</mat-icon>
      </ng-template>
    </div>
    <sat-popover
      #priorityPopover
      verticalAlign="below"
      horizontalAlign="start"
      [hasBackdrop]="true"
      [autoFocus]="false"
    >
      <ease-task-priority-popover
        [priority]="task.priority"
        (selected)="setPriority($event)"
      ></ease-task-priority-popover>
    </sat-popover>
  </div>

  <ng-container *ngIf="inBackpack$ | async; else noTaskInBackpack">
    <div
      class="rounded-full bg-blue-500 p-2 py-1 drop-shadow"
      matTooltip="Click to remove from backpack"
      (click)="backpackService.remove(task.$key)"
    >
      <div
        class="flex cursor-pointer flex-row items-center gap-2 text-[13px] font-medium leading-6 text-white"
      >
        <mat-icon class="mat-icon-18 text-blue-50">backpack</mat-icon>
        <span>In your backpack</span>
      </div>
    </div>
  </ng-container>

  <ng-template #noTaskInBackpack>
    <div
      class="rounded-full bg-white p-2 py-1 drop-shadow"
      (click)="backpackService.add(task.$key)"
    >
      <div
        class="flex cursor-pointer flex-row items-center gap-2 text-[13px] font-medium leading-6 text-gray-800"
      >
        <mat-icon class="mat-icon-18 text-gray-600">backpack</mat-icon>
        <span>Add to backpack</span>
      </div>
    </div>
  </ng-template>
</div>
