import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';

import {
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup
} from 'src/app/shared/reactive-forms';
import { buildFormControls } from '../utils/functions';
import { FilesizePipe } from '../filesize.pipe';
import { FileUploaderFile } from './file-uploader.interface';

@Component({
  selector: 'ease-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  constructor(
    private cdr: ChangeDetectorRef,

    private filesizePipe: FilesizePipe
  ) {
    this.uploadInput = new EventEmitter<UploadInput>();
  }

  @ViewChild('fileInput', { static: true }) fileInput;
  @Input() fileFormArray: TypedFormArray<FileUploaderFile>;
  @Input() maxFileSize = 3000000;
  @Input() accepts = '.pdf, .png, .jpg, .bmp';
  @Input() set validDimensions(dimensions: string[]) {
    this.validDimensionsObject = dimensions.reduce((acc, dimensionString) => {
      const width = dimensionString.split('x')[0];
      const height = dimensionString.split('x')[1];
      if (!acc[width]) {
        acc[width] = {};
      }

      acc[width][height] = true;
      return acc;
    }, {});
    this._validDimensions = dimensions;
  }

  get validDimensions(): string[] {
    return this._validDimensions;
  }
  private _validDimensions: string[];
  private validDimensionsObject: {
    [width: number]: { [height: number]: boolean };
  };
  public filesQueue: FileUploaderFile[] = [];
  public uploadInput: EventEmitter<UploadInput>;
  public dragOver: boolean = false;

  private _files: FileUploaderFile[] = [];
  public set files(files: FileUploaderFile[]) {
    while (this.fileFormArray.length !== 0) {
      this.fileFormArray.removeAt(0);
    }
    files.forEach((file, index) => {
      this.fileFormArray.push(this.generateFileControl(file));
    });
    this._files = files;
  }

  public get files(): FileUploaderFile[] {
    return this._files;
  }

  ngOnInit() {}

  async onAllAddedToQueue() {
    if (this.filesQueue.length) {
      const filesResults = await Promise.all(
        this.filesQueue.map(file => this.attachMetadata(file))
      );
      this.files = [...filesResults];
      this.cdr.detectChanges();
    }
  }

  attachMetadata(file: FileUploaderFile): Promise<FileUploaderFile> {
    return new Promise((resolve, reject) => {
      if (!file.metadata) {
        const reader = new FileReader();
        reader.readAsDataURL(file.nativeFile);
        reader.onload = () => {
          const img = new Image();
          img.src = window.URL.createObjectURL(file.nativeFile);
          img.onload = () => {
            file.metadata = {
              width: img.naturalWidth,
              height: img.naturalHeight,
              url: reader.result
            };

            // Validating filesize
            if (file.size > this.maxFileSize) {
              file.metadata.error = `File too big, must be less than ${this.filesizePipe.transform(
                this.maxFileSize
              )}`;
            }

            // Check Dimensions
            if (
              this.validDimensionsObject &&
              !(
                this.validDimensionsObject[file.metadata.width] &&
                this.validDimensionsObject[file.metadata.width][
                  file.metadata.height
                ]
              )
            ) {
              file.metadata.error = `Incorrect ad dimensions`;
            }

            resolve(file);
          };
        };
      } else {
        resolve(file);
      }
    });
  }

  async onReplaceFile(
    output: UploadOutput,
    previousFile: FileUploaderFile,
    fileIndex: number
  ) {
    if (output.file) {
      output.file.id = previousFile.id;
      output.file.fileIndex = fileIndex;
      const newFile = await this.attachMetadata(output.file);

      this.files[fileIndex] = newFile;
      this.filesQueue[fileIndex] = output.file;
      this.files = [...this.files];
      this.filesQueue = [...this.filesQueue];
      this.cdr.detectChanges();
    }
  }

  deleteFile(fileId: string) {
    this.uploadInput.emit({ type: 'remove', id: fileId });
  }

  generateFileControl(file: FileUploaderFile) {
    const control: TypedFormGroup<FileUploaderFile> = new FormGroup(
      {} as TypedFormControl<FileUploaderFile>
    );

    buildFormControls(file, control);

    if (file.metadata.error) {
      control.setErrors({
        fileError: {
          message: 'File has an error'
        }
      });
      control.markAsDirty();
    }
    return control;
  }

  /**
   * File Upload Handling
   */
  onUploadOutput(output: UploadOutput) {
    switch (output.type) {
      case 'addedToQueue':
        if (output.file) {
          this.filesQueue.push(output.file);
        }
        break;
      case 'allAddedToQueue':
        this.onAllAddedToQueue();
        break;
      case 'removed':
        this.filesQueue = this.filesQueue.filter(
          (file: UploadFile) => file.id !== output.file.id
        );

        this.files = this.files.filter(
          (file: FileUploaderFile) => file.id !== output.file.id
        );

        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
    }
  }
}
