import { NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';

/**
 * These are the javascript definitions for breakpoints that match the default Tailwind breakpoints
 */
const BP_SM = 640;
const BP_MD = 768;
const BP_LG = 1024;
const BP_XL = 1280;
const BP_2XL = 1536;

export const Breakpoints = {
  ltSm: `screen and (max-width: ${BP_SM - 1}px)`,
  sm: `screen and (min-width: ${BP_SM}px)`,
  md: `screen and (min-width: ${BP_MD}px)`,
  lg: `screen and (min-width: ${BP_LG}px)`,
  xl: `screen and (min-width: ${BP_XL}px)`,
  '2xl': `screen and (min-width: ${BP_2XL}px)`
};

@NgModule({
  imports: [LayoutModule],
  exports: [LayoutModule]
})
export class BreakpointsModule {}
