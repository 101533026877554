<ng-container *ngIf="!packageSuccess">
  <ng-container *ngIf="customer$ | async as customer; else loading">
    <h1 mat-dialog-title class="!mb-0">
      {{ operation.action | titlecase }} Onboarding Package
    </h1>
    <p>Let's prepare an onboarding package.</p>

    <mat-dialog-content class="!px-0">
      <mat-vertical-stepper #stepper>
        <mat-step [stepControl]="onboardingForm.controls.generalInfo">
          <ng-template matStepLabel>Onboarding Information</ng-template>

          <p class="step-desc">
            This information will be included in the onboarding task upon
            package completion
          </p>
          <div class="onboarding-type">
            <mat-form-field>
              <mat-select
                placeholder="Onboarding Type"
                (selectionChange)="typeSelected($event)"
              >
                <mat-option
                  *ngFor="let type of onboardingTypes$ | async"
                  [value]="type.id"
                  >{{ type.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div [formGroup]="generalInfoFormGroup">
            <div class="form-group">
              <ease-customer-plan-selector
                displayStyle="form"
                [displayPlanText]="true"
                class="-mb-5"
                [plan]="
                  onboardingForm.controls.generalInfo.controls.planId.value
                "
                (planChanged)="setCustomerPlan($event)"
              >
              </ease-customer-plan-selector>
            </div>
            <mat-form-field>
              <input
                matInput
                placeholder="Fee"
                formControlName="fee"
                type="text"
                prefix="$"
                mask="separator.2"
                thousandSeparator=","
                [dropSpecialCharacters]="false"
              />
              <mat-error
                easeFormErrors
                [control]="onboardingForm.controls.generalInfo.controls.fee"
              ></mat-error>
            </mat-form-field>
            <mat-form-field class="mb-5">
              <input
                matInput
                placeholder="Fee Notes"
                formControlName="feeNotes"
              />
              <mat-hint>Ex.: Monthly. to be paid out of benefits</mat-hint>
              <mat-error
                easeFormErrors
                [control]="
                  onboardingForm.controls.generalInfo.controls.feeNotes
                "
              ></mat-error>
            </mat-form-field>
            <ease-markdown-editor [formControl]="extraNotesControl">
            </ease-markdown-editor>
            <div class="mt-5">
              <button mat-raised-button color="primary" matStepperNext>
                Next
              </button>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="onboardingForm.controls.stages">
          <ng-template matStepLabel>Forms</ng-template>
          <p class="step-desc">
            Select the forms that must be completed. You can change the order
            the forms will be presented to the customer.
          </p>
          <div class="form-finder mb-5">
            <div class="form-group-sm">
              <ease-advanced-autocomplete
                [formControl]="formSearchControl"
                [options]="forms$ | async"
                [placeholder]="'Search Form'"
                [prefixIcon]="'search'"
                (optionSelected)="formSelected($event)"
              ></ease-advanced-autocomplete>
            </div>
            <ng-container
              *ngIf="onboardingForm.controls.stages.controls.length"
            >
              <div
                class="stages-wrapper sorter-group"
                cdkDropList
                [cdkDropListData]="onboardingForm.controls.stages.controls"
                (cdkDropListDropped)="onStageSort($event)"
              >
                <div
                  class="stage flex flex-row items-center justify-center"
                  *ngFor="
                    let stage of onboardingForm.controls.stages.controls;
                    trackBy: getIndex;
                    index as i
                  "
                  cdkDrag
                >
                  <div class="drag-drop-placeholder" *cdkDragPlaceholder></div>
                  <div class="drag-handle"></div>
                  <div class="order">{{ i + 1 }}</div>
                  <div class="name-info flex-1">
                    <div class="name-description">
                      <span class="name">{{ stage.controls.name.value }} </span>
                    </div>
                    <div
                      class="url truncate"
                      *ngIf="stage.controls.description"
                      [attr.data-url]="stage.controls.url.value"
                    >
                      {{ stage.controls.description.value }}
                    </div>
                  </div>
                  <div class="actions">
                    <button mat-icon-button (click)="deleteStage(i)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="onboardingForm.invalid || isLoading"
        (click)="submit()"
      >
        {{ operation.action === 'new' ? 'Create' : 'Update' }}
        Onboarding Package
      </button>
    </mat-dialog-actions>
  </ng-container>

  <ng-template #loading>
    <div
      class="loading-wrapper my-5 mx-5 flex flex-row items-center justify-center"
    >
      <mat-progress-spinner
        [diameter]="24"
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="packageSuccess">
  <ease-sheet class="dialog">
    <div class="dialog-header flex flex-row items-start justify-start">
      <div class="title-wrapper flex-1">
        <h1>Share Onboarding Link</h1>
        <p>This link will guide the customer through the onboarding process.</p>
      </div>
      <div class="action">
        <button mat-icon-button (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div class="content-link">
      <div
        class="link-wrapper cursor-pointer"
        (click)="copyLink(packageSuccess.packageId)"
      >
        <span class="mr-2">{{ packageSuccess.customerUrl }}</span>
        <button mat-icon-button><mat-icon>file_copy</mat-icon></button>
      </div>

      <div class="actions my-5 flex flex-row items-end justify-end">
        <button mat-raised-button color="primary" (click)="closeDialog()">
          Go to dashboard
        </button>
      </div>
    </div>
  </ease-sheet>
</ng-container>
