import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatepickerModule } from 'src/app/shared/datepicker/datepicker.module';
import { EaseMaterialModule } from 'src/app/shared/ease-material.module';
import { TaskPriorityPopoverModule } from 'src/app/shared/popover/task-priority/task-priority-popover.module';
import { SharedFormModule } from 'src/app/shared/shared-form.module';
import { SharedPipesModule } from 'src/app/shared/shared-pipes.module';
import { TaskPhaseModule } from '../task-phase/task-phase.module';
import { TaskStatusModule } from '../task-status/task-status.module';
import { TaskSummaryComponent } from './task-summary.component';

@NgModule({
  declarations: [TaskSummaryComponent],
  imports: [
    CommonModule,
    EaseMaterialModule,
    TaskStatusModule,
    TaskPhaseModule,
    SharedPipesModule,
    DatepickerModule,
    TaskPriorityPopoverModule,
    SharedFormModule
  ],
  exports: [TaskSummaryComponent]
})
export class TaskSummaryModule {}
