import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { ButMetric } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';

@Component({
  selector: 'ease-budget-utilization-popover',
  templateUrl: './budget-utilization-popover.component.html',
  styleUrls: ['./budget-utilization-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetUtilizationPopoverComponent implements OnInit {
  @Input() budgetUtilization: ButMetric;
  @Input() accountBudget: number;
  @Output() openBudget: EventEmitter<void> = new EventEmitter<void>();

  constructor(public accountDashboardService: AccountDashboardService) {}

  ngOnInit() {}

  openBudgetManager() {
    this.openBudget.emit();
  }
}
