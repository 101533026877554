import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding
} from '@angular/core';

@Component({
  selector: 'ease-nav-section-items',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./nav-section-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavSectionItemsComponent implements OnInit {
  @HostBinding('class') class = 'block';
  constructor() {}

  ngOnInit(): void {}
}
