import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { AgGridModule } from 'ag-grid-angular';
import { NgxUploaderModule } from 'ngx-uploader';

import { EntityIconModule } from '../entity-icon/entity-icon.module';
import { MarkdownModule } from '../markdown/markdown.module';
import { SelectUserPopoverModule } from '../popover/select-user/select-user-popover.module';
import { SharedModule } from '../shared.module';
import { SharedFormModule } from '../shared-form.module';
import { SharedPipesModule } from '../shared-pipes.module';

import { BulkTargetingComponent } from './bulk-targeting.component';
import { BulkTargetingEntityComponent } from './bulk-targeting-entity/bulk-targeting-entity.component';
import { BulkTargetingHelpComponent } from './bulk-targeting-help/bulk-targeting-help.component';
import { BulkTargetingMethodPasteComponent } from './bulk-targeting-method-paste/bulk-targeting-method-paste.component';
import { BulkTargetingMethodTableComponent } from './bulk-targeting-method-table/bulk-targeting-method-table.component';
import { BulkTargetingMethodUploadComponent } from './bulk-targeting-method-upload/bulk-targeting-method-upload.component';
import { BulkTargetingUsersComponent } from './bulk-targeting-users/bulk-targeting-users.component';

@NgModule({
  imports: [
    AgGridModule,
    CommonModule,
    EntityIconModule,
    MarkdownModule,
    NgxUploaderModule,
    SatPopoverModule,
    SelectUserPopoverModule,
    SharedFormModule,
    SharedModule,
    SharedPipesModule
  ],
  declarations: [
    BulkTargetingComponent,
    BulkTargetingEntityComponent,
    BulkTargetingHelpComponent,
    BulkTargetingMethodPasteComponent,
    BulkTargetingMethodTableComponent,
    BulkTargetingMethodUploadComponent,
    BulkTargetingUsersComponent
  ],
  exports: [BulkTargetingComponent]
})
export class BulkTargetingModule {}
