import {
  debounceTime,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  of
} from 'rxjs';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CurrencyPipe } from '@angular/common';

import {
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup
} from 'src/app/shared/reactive-forms';
import { transformCurrencyString } from '../../utils/functions';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from '../../constants';
import { EmailComposerComponent } from '../../emails/email-composer/email-composer.component';
import { EmailTemplateService } from '../../emails/email-template.service';
import {
  EmailMessage,
  EmailTemplateDetails
} from '../../emails/email.interface';
import { FundingService } from '../funding.service';
import { UserService } from '../../../users/user.service';
import {
  CreateFundingPayload,
  FundingEventLineItem,
  FundingLine,
  FundingSpendLineTypeMap,
  FundingSummary,
  FundingTotals,
  FundingEventLineType,
  AddFundingFormValue,
  FundingSettings,
  FundingForm,
  FundingFormItem,
  FundingDetails
} from '../funding.interface';
import { FundingManagerComponent } from '../funding-manager/funding-manager.component';

export const atLeastOne =
  () =>
  (group: FormGroup): ValidationErrors | null => {
    const groupValue = group.value;
    let moreThanOne = false;
    Object.keys(groupValue).forEach(subcontrolsKeys => {
      if (Object.keys(groupValue[subcontrolsKeys]).length) {
        moreThanOne = true;
      }
    });
    return moreThanOne ? null : { atLeastOne: true };
  };

@UntilDestroy()
@Component({
  selector: 'ease-add-funding',
  templateUrl: './add-funding.component.html',
  styleUrls: ['./add-funding.component.scss']
})
export class AddFundingComponent implements AfterViewInit, OnInit {
  get emailsDetailsFormArray(): TypedFormArray<EmailMessage> {
    return this.fundingForm.controls.emailsDetails;
  }

  get fundingDetailsFormGroup(): TypedFormGroup<FundingDetails> {
    return this.fundingForm.controls.fundingDetails;
  }

  @Input() data: { customerId: string };
  @Output() addedFunding: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('emailComposer')
  emailComposer: EmailComposerComponent;
  @ViewChild('fundingManager')
  fundingManager: FundingManagerComponent;
  public isWorking: boolean = false;
  public fundingForm: TypedFormGroup<FundingForm>;
  public fundingTypes$: Observable<FundingEventLineType[]>;
  public usedAmount$: Observable<number>;
  public usedAmount: number;
  public totalAmount$: Observable<number>;
  public totalAmount: number;
  public templateId$: Observable<string>;
  public templateDetails$: Observable<EmailTemplateDetails>;
  public fundingTotals$: Observable<FundingTotals>;
  public fundingTotals: FundingTotals;
  private fundingSpendLineTypeMap$: Observable<FundingSpendLineTypeMap>;
  private platformSpendMap = {
    adsSpend: 'adwords',
    glsSpend: 'lsa',
    bingSpend: 'bing'
  };
  private customerFundingSummary$: Observable<FundingSummary>;
  private recalculateVariables$: BehaviorSubject<void> =
    new BehaviorSubject<void>(null);

  constructor(
    private currencyPipe: CurrencyPipe,
    private formBuilder: FormBuilder,
    private userService: UserService,
    public fundingService: FundingService,
    private matSnackBar: MatSnackBar,
    private emailTemplateService: EmailTemplateService
  ) {}

  ngOnInit() {
    this.fundingForm = this.formBuilder.group({
      fundingDetails: this.formBuilder.group({
        availableBalance: this.formBuilder.control(null, {
          validators: Validators.required
        }),
        fundingTotal: this.formBuilder.control(null),
        createdBy: this.formBuilder.control(this.userService.currentUser.$key, {
          validators: Validators.required
        }),
        notes: this.formBuilder.control(null)
      }),
      fundingSpend: this.formBuilder.group(
        {
          platformSpend: this.formBuilder.group<
            TypedFormControl<FundingFormItem>
          >({}),
          managementSpend: this.formBuilder.group<
            TypedFormControl<FundingFormItem>
          >({})
        },
        { validators: atLeastOne() }
      ),
      emailsDetails: this.formBuilder.array<TypedFormGroup<EmailMessage>>([])
    });

    this.customerFundingSummary$ = this.fundingService.fundingSummary$.pipe(
      take(1),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.fundingSpendLineTypeMap$ = this.fundingService.getFundingTypes().pipe(
      map(types =>
        types.reduce(
          (acc, type) => {
            if (
              type.emailVariable === 'managementFee' ||
              type.emailVariable === 'otherDigitalFees'
            ) {
              acc.managementSpend[type.billingPlanProduct.code] = type;
            } else if (type.emailVariable && !type.billingPlanProduct) {
              acc.platformSpend[this.platformSpendMap[type.emailVariable]] =
                type;
            }

            return acc;
          },
          { platformSpend: {}, managementSpend: {} } as FundingSpendLineTypeMap
        )
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.fundingTotals$ = combineLatest([
      this.fundingForm.controls.fundingSpend.valueChanges.pipe(startWith(null)),
      this.customerFundingSummary$,
      this.fundingService.fundingSettings$,
      this.fundingSpendLineTypeMap$
    ]).pipe(
      map(
        ([
          formValue,
          fundingSummary,
          fundingSettings,
          fundingSpendLineTypeMap
        ]: [
          AddFundingFormValue,
          FundingSummary,
          FundingSettings,
          FundingSpendLineTypeMap
        ]) => {
          const fundingTotals: FundingTotals = {
            balanceAvailable:
              fundingSummary.summary.currentBalance -
              (fundingSettings.managementFee || 0),
            totalFunding: 0,
            platformSpend: [],
            managementSpend: []
          };

          this.fundingDetailsFormGroup.controls.availableBalance.patchValue(
            fundingTotals.balanceAvailable
          );

          if (formValue) {
            // Iterate between types of spend | platFormSpend and ManagementSpend
            Object.keys(formValue).forEach(spendType => {
              // Iterate over platformSpendTypes
              fundingTotals[spendType] = Object.keys(formValue[spendType]).map(
                productCodeOrPlatformName => {
                  // Initialze a total to be used for all FundingFormItems of this spendType
                  let total = 0;
                  // Iterate through each FundingFormItem to obtain total and add it to accountSpend variable.
                  Object.keys(
                    formValue[spendType][productCodeOrPlatformName]
                  ).forEach(entityId => {
                    const amount = transformCurrencyString(
                      formValue[spendType][productCodeOrPlatformName][entityId]
                        .amount
                    );
                    // Making sure that the line has an amount defined
                    if (amount) {
                      // Add each line item to the total for this spend type
                      total = amount + total;

                      // Add this line item's amount to the overall total
                      fundingTotals.totalFunding = +(
                        amount + fundingTotals.totalFunding
                      ).toFixed(2);

                      this.fundingDetailsFormGroup.controls.fundingTotal.patchValue(
                        +fundingTotals.totalFunding.toFixed(2)
                      );
                    }
                  });

                  return {
                    fundingEventLineType:
                      fundingSpendLineTypeMap[spendType][
                        productCodeOrPlatformName
                      ],
                    spendType: productCodeOrPlatformName,
                    total
                  };
                }
              );
            });
          }
          this.fundingTotals = fundingTotals;
          return fundingTotals;
        }
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.fundingService.setFundingCustomerId(this.data.customerId);
    this.templateId$ = of('b7794f16-ff9e-41cd-b341-0f1a19070a7c');

    this.templateDetails$ = this.templateId$.pipe(
      switchMap(templateId => this.emailTemplateService.getDetails(templateId))
    );

    this.fundingDetailsFormGroup.controls.notes.valueChanges
      .pipe(untilDestroyed(this), debounceTime(180))
      .subscribe(notes => {
        this.emailComposer.sharedEmailVariables.patchValue({ notes });
        this.emailComposer.copySharedEmailVariablesToAllRecipients();
      });

    combineLatest([
      this.fundingTotals$,
      this.recalculateVariables$.asObservable()
    ])
      .pipe(
        debounceTime(180),
        withLatestFrom(this.fundingSpendLineTypeMap$),
        map(([triggers, fundingSpendLineTypeMap]) => {
          const [fundingTotals] = triggers;
          const sharedVariables = {
            glsSpend: null,
            glsManagementFee: null,
            adsSpend: null,
            adsManagementFee: null,
            bingSpend: null,
            otherDigitalFees: null,
            totalFunding: null,
            managementFee: null,
            notes: this.fundingForm.controls.fundingDetails.controls.notes.value
          };

          // Mapping platform spend to email variables
          fundingTotals.platformSpend.forEach(platformSpendItem => {
            sharedVariables[
              fundingSpendLineTypeMap['platformSpend'][
                platformSpendItem.spendType
              ].emailVariable
            ] = platformSpendItem.total
              ? this.currencyPipe.transform(
                  platformSpendItem.total,
                  undefined,
                  'symbol-narrow',
                  '1.2-2'
                )
              : null;
          });

          // Mapping management spend to email variables
          let totalManagementFee = 0;
          let otherDigitalFees = 0;
          fundingTotals.managementSpend.forEach(managementSpendItem => {
            if (
              managementSpendItem.fundingEventLineType.emailVariable ===
              'otherDigitalFees'
            ) {
              otherDigitalFees = managementSpendItem.total + otherDigitalFees;
            } else {
              totalManagementFee =
                managementSpendItem.total + totalManagementFee;
            }
          });

          sharedVariables['managementFee'] = totalManagementFee
            ? this.currencyPipe.transform(
                totalManagementFee,
                undefined,
                'symbol-narrow',
                '1.2-2'
              )
            : null;

          sharedVariables['otherDigitalFees'] = otherDigitalFees
            ? this.currencyPipe.transform(
                otherDigitalFees,
                undefined,
                'symbol-narrow',
                '1.2-2'
              )
            : null;

          // Mapping totalFunding to email variables
          sharedVariables.totalFunding = fundingTotals.totalFunding
            ? this.currencyPipe.transform(
                fundingTotals.totalFunding,
                undefined,
                'symbol-narrow',
                '1.2-2'
              )
            : null;

          return sharedVariables;
        }),
        untilDestroyed(this)
      )
      .subscribe(sharedVariables => {
        this.emailComposer.sharedEmailVariables.patchValue(sharedVariables);
        this.emailComposer.copySharedEmailVariablesToAllRecipients();
      });
  }

  ngAfterViewInit() {
    this.fundingService.fundingSettings$
      .pipe(take(1))
      .subscribe(fundingSettings => {
        if (fundingSettings && fundingSettings.advertisingAccount) {
          fundingSettings.advertisingAccount.forEach(account => {
            this.fundingManager.addSpend(
              {
                accountType: account.type,
                displayValue: account.name,
                value: account.id
              },
              'platform'
            );
          });
        }
      });
  }

  recalculateVariables(): void {
    this.recalculateVariables$.next();
  }

  async transformPayload(): Promise<CreateFundingPayload> {
    const formValue = this.fundingForm.controls.fundingSpend.value;
    const fundingEventLines: FundingEventLineItem[] = [];
    const fundingTotals = await firstValueFrom(this.fundingTotals$);
    const fundingSpendLineTypeMap = await firstValueFrom(
      this.fundingSpendLineTypeMap$
    );

    Object.keys(formValue).forEach(spendType => {
      // platformSpend OR managementSpend
      Object.keys(formValue[spendType]).forEach(productCodeOrPlatformName => {
        Object.keys(formValue[spendType][productCodeOrPlatformName]).forEach(
          entityId => {
            const eventLine: FundingLine =
              formValue[spendType][productCodeOrPlatformName][entityId];

            fundingEventLines.push({
              amount: transformCurrencyString(eventLine.amount),
              customer: this.data.customerId,
              createdBy: this.userService.currentUser.$key,
              fundingEventLineType:
                fundingSpendLineTypeMap[spendType][productCodeOrPlatformName],
              // If we're adding a line that needs to create a Fusebill subscription, include the frequencyId
              ...(eventLine.frequencyId && {
                frequencyId: eventLine.frequencyId
              }),
              // If we're adding a line for platform spend, include details about the account
              ...(spendType === 'platformSpend'
                ? {
                    advertisingAccount: {
                      id: entityId,
                      type: productCodeOrPlatformName
                    }
                  }
                : {})
            });
          }
        );
      });
    });

    return {
      fundingDetails: {
        type: 'debit',
        customer: this.data.customerId,
        totalAmount: fundingTotals.totalFunding,
        createdBy: this.userService.currentUser.$key,
        fundingEventLines
      },
      emailDetails: this.fundingForm.controls.emailsDetails
        .value as EmailMessage[]
    };
  }

  async createFunding() {
    if (this.fundingForm.valid && this.totalAmount === this.usedAmount) {
      this.working(true);
      const success = await this.emailComposer.prepareEmail();
      const payload = await this.transformPayload();
      if (success) {
        firstValueFrom(this.fundingService.createFunding(payload))
          .then(response => {
            this.working(false);
            this.addedFunding.emit(response);
            this.fundingService.reloadSource.next();
            this.matSnackBar.open('Funding Created', 'Close', {
              duration: SNACKBAR_DURATION_SUCCESS
            });
          })
          .catch(err => this.working(false));
      }
    } else {
      this.matSnackBar.open(
        'Some fields are invalid. If you believe your entry is correct, contact the tools team.',
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );
    }
  }

  working(status: boolean) {
    this.isWorking = status;
  }
}
