import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { availableColors } from './colors';

@Component({
  selector: 'ease-color-blob',
  templateUrl: './color-blob.component.html',
  styleUrls: ['./color-blob.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorBlobComponent {
  @HostBinding('class') hostClass = 'inline-block last:mr-0';
  @Input()
  color: string = 'grey';
  @Input()
  size: string = 'sm';
  @Input()
  hollow: boolean = false;
  @Input()
  selected: boolean = false;
  public colorList = availableColors;

  inlineStyleBlob() {
    return {
      backgroundColor: availableColors[this.color],
      borderColor: availableColors[this.color]
    };
  }
}
