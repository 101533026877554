<ease-sheet class="shadow-md">
  <ease-sheet-header class="flex flex-row items-center justify-start">
    <mat-icon class="mat-icon-30 mr-2">folder_shared</mat-icon>
    <div class="flex-1">User Roles</div>
    <span class="text-xs" *ngIf="required">Required</span>
  </ease-sheet-header>
  <ease-sheet-content>
    <mat-form-field *ngFor="let role of availableUserRoles$ | async">
      <mat-select
        [value]="currentRoles ? currentRoles[role.$key] : null"
        (selectionChange)="onRoleChange(role.$key, $event)"
        [placeholder]="role.label"
        [disabled]="disabled"
      >
        <mat-option [value]="user.$key" *ngFor="let user of users$ | async">{{
          user.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </ease-sheet-content>
</ease-sheet>
