import { Component, HostBinding, Input } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const EXPANSION_PANEL_ANIMATION_TIMING =
  '225ms cubic-bezier(0.4,0.0,0.2,1)';

@Component({
  selector: 'ease-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  animations: [
    trigger('bodyExpansion', [
      state('collapsed', style({ height: '0px' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate(EXPANSION_PANEL_ANIMATION_TIMING)
      )
    ])
  ]
})
export class CollapseComponent {
  @HostBinding('class') class = 'block';
  @Input()
  collapsed: boolean;
  @Input()
  disableDefaultIcon: boolean;
  @Input()
  disabled: boolean;

  get visible() {
    return !this.collapsed;
  }

  set visible(collapsedState) {
    this.collapsed = !collapsedState;
  }

  collapse() {
    if (!this.disabled) {
      this.visible = false;
    }
  }

  expand() {
    if (!this.disabled) {
      this.visible = true;
    }
  }

  toggle() {
    if (!this.disabled) {
      this.visible = !this.visible;
    }
  }

  getExpandedState(): string {
    return this.visible ? 'expanded' : 'collapsed';
  }
}
