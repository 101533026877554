import { Component, Input } from '@angular/core';

@Component({
  selector: 'ease-task-ad-extension-disapprovals',
  templateUrl: './task-ad-extension-disapprovals.component.html',
  styleUrls: ['./task-ad-extension-disapprovals.component.scss']
})
export class TaskAdExtensionDisapprovalsComponent {
  @Input()
  items;
}
