import { take } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { SatPopover } from '@ncstate/sat-popover';

import { EaseNotification } from '../notification.interface';
import { NotificationsService } from '../notifications.service';
import { WindowService } from '../../shared/window/window.service';

@Component({
  selector: 'ease-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit {
  @ViewChild('notificationsPopover')
  notificationsPopover: SatPopover;

  constructor(
    public notificationsService: NotificationsService,
    private windowService: WindowService,
    private router: Router
  ) {}

  ngOnInit() {}

  panelToggle() {
    this.notificationsPopover.toggle();
  }

  onNotificationClicked({
    event,
    notification
  }: {
    event?: MouseEvent;
    notification: EaseNotification;
  }) {
    /**
     * A bit hacky, but we check whether we are clicking on a
     * task notification. If so, we check whether it should be
     * backgrounded via ctrlKey and open the window as needed
     *
     * If ctrlKey was not pressed, we close the notifications
     * popover as expected.
     */
    if (notification.clickUrl.startsWith('/tasks/')) {
      const background = event?.ctrlKey || event?.metaKey;
      this.windowService.create({
        data: notification.itemId,
        type: 'viewTask',
        state: background ? 'MINIMIZED' : 'MAXIMIZED'
      });
    } else {
      this.router.navigateByUrl(notification.clickUrl);
    }

    this.notificationsService.markRead(notification.itemId);

    if (!event?.ctrlKey && !event?.metaKey) {
      this.notificationsPopover.close();
    }
  }

  onNotificationDismiss(notification: EaseNotification) {
    this.notificationsService.markRead(notification.itemId);
  }

  openLastNotification() {
    this.notificationsService.unread$.pipe(take(1)).subscribe(notifications => {
      if (notifications && notifications.length) {
        this.onNotificationClicked({
          notification: notifications[notifications.length - 1]
        });
      }
    });
  }

  onMarkAllRead(unreadNotifications: EaseNotification[]) {
    this.notificationsService.markAllRead(unreadNotifications);
  }
}
