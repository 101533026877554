import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Injectable,
  InjectionToken,
  Injector,
  StaticProvider,
  TemplateRef,
  Type,
  ViewContainerRef
} from '@angular/core';
import { filter, firstValueFrom, fromEvent, take, tap } from 'rxjs';
import { ContextMenuComponent } from './context-menu.component';

// This Service is needed to have one overlay over the app.

export const CONTEXT_MENU_CONTENT = new InjectionToken<
  TemplateRef<any> | Type<any>
>('Context Menu Content');

@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {
  public overlayRef: OverlayRef;
  public componentRef: ComponentRef<ContextMenuComponent>;
  constructor(public overlay: Overlay) {}

  /**
   * Update the data for the currently-open context menu componentRef
   *
   * @param data Data that will be passed to the context menu
   */
  updateData(data: any) {
    if (this.componentRef) {
      this.componentRef.instance.data = data;
      this.componentRef.changeDetectorRef.detectChanges();
    }
  }

  public open(
    { x, y }: MouseEvent,
    data: any,
    template: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
    disabled?
  ) {
    if (!disabled) {
      this.close();
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo({ x, y })
        .withPositions([
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top'
          }
        ]);

      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'bg-transparent',
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.close()
      });

      const providers: StaticProvider[] = [
        {
          provide: CONTEXT_MENU_CONTENT,
          useValue: template
        }
      ];

      this.componentRef = this.overlayRef.attach(
        new ComponentPortal(
          ContextMenuComponent,
          viewContainerRef,
          Injector.create({
            parent: viewContainerRef.injector,
            providers
          })
        )
      );

      // After attaching, update the data and mark the context menu as open
      this.updateData(data);

      /**
       * Listen for clicks *outside* the context menu and *outside*
       * any popovers that might be nested inside the context menu
       *
       * Close the context menu if those conditions are true
       */
      const afterCloseEvent$ = firstValueFrom(
        fromEvent<MouseEvent>(document, 'click').pipe(
          filter(event => {
            const clickTarget = event.target as HTMLElement;
            const targetPath = event.composedPath();

            const clickInsidePopover = !!targetPath.find(element =>
              (element as HTMLElement)?.className
                ?.toLowerCase()
                .includes('popover')
            );

            return (
              !clickInsidePopover &&
              !!this.overlayRef &&
              !this.overlayRef.overlayElement.contains(clickTarget)
            );
          }),
          take(1),
          tap(() => this.close())
        )
      );
      return {
        afterClosed: () => afterCloseEvent$
      };
    }
  }

  /**
   * Cleans up any service-level values and mark the menu as closed
   */
  public close() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
