<ng-container *ngIf="!isGroup && callQualityMetric">
  <div
    class="metric pointer flex flex-row items-center justify-start"
    (click)="metricInfo.toggle()"
    [satPopoverAnchor]="metricInfo"
    [ngClass]="callQualityMetric.ragStatusColor"
  >
    <div class="metric-details metric-color flex-1">
      <strong>{{ callQualityMetric.callQuality }}%</strong>
    </div>
  </div>
  <sat-popover [autoFocus]="false" [hasBackdrop]="true" #metricInfo>
    <ease-cq-popover
      *ngIf="metricInfo.isOpen()"
      [account]="account"
    ></ease-cq-popover>
  </sat-popover>
</ng-container>
