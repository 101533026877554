<div class="banner theme-info mb-5 flex flex-row items-center justify-center">
  <mat-icon class="mr-2">info</mat-icon>
  <span
    >If this is intentional, add a label: <strong>All Hours</strong> to avoid
    this alert reappearing.</span
  >
</div>
<ng-container *ngFor="let campaignGroup of items">
  <ease-list-group size="sm">
    <ease-list-group-header>
      {{ campaignGroup.campaignName }}</ease-list-group-header
    >
    <ease-list-group-item *ngFor="let adGroup of campaignGroup?.adGroups">{{
      adGroup.adGroupName
    }}</ease-list-group-item>
  </ease-list-group>
</ng-container>
