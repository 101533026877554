import { filter, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import {
  ADWORDS_ACCOUNT_FEEDS_PATH,
  ADWORDS_ACCOUNT_NOTES_PATH,
  BING_ACCOUNT_FEEDS_PATH,
  BING_ACCOUNT_NOTES_PATH,
  CUSTOMER_COMBINED_FEEDS_PATH,
  CUSTOMER_COMMUNICATIONS_PATH,
  CUSTOMER_NOTES_PATH,
  LSA_ACCOUNT_FEEDS_PATH,
  LSA_ACCOUNT_NOTES_PATH
} from '../../shared/firebase-paths';
import { UserService } from '../../users/user.service';
import { EntityUtilsService } from '../entity-utils.service';
import { TypesenseCustomerFeedFieldList } from '../search/search.interface';
import {
  FeedItem,
  FeedItemCreationMeta,
  FeedItemMeta
} from './feed-item.interface';

@Injectable({ providedIn: 'root' })
export class FeedItemService {
  constructor(
    private angularFire: FirebaseDbService,
    private entityUtilsService: EntityUtilsService,
    private userService: UserService
  ) {}

  async create(
    item: any,
    creationMeta: FeedItemCreationMeta
  ): Promise<Partial<FeedItem>> {
    const timestamp = new Date().getTime();
    const feedItem = {
      entityId: creationMeta.entityId,
      entityName: creationMeta.entityName,
      entityType: creationMeta.entityType,
      accountType: creationMeta.accountType || null,
      itemId: item.$key,
      itemType: this.getItemType(item, creationMeta.endpoint),
      endpoint: creationMeta.endpoint,
      createdAt: timestamp,
      createdAtReversed: timestamp * -1
    };

    /**
     * If this feed item is being applied to a customer,
     * add it to the customer feed and return early.
     */
    if (
      creationMeta.entityType === 'customer' ||
      creationMeta.entityType === 'prospect'
    ) {
      await this.angularFire
        .list(`/${CUSTOMER_COMBINED_FEEDS_PATH}/${creationMeta.entityId}`)
        .push(feedItem);
      return feedItem;
    }

    /**
     * If this feed item is being applied to an account,
     * and there is also a customerId defined, add it to the
     * correct account feed path, and also add it to the customer feed.
     */
    if (creationMeta.entityType === 'account') {
      const customerId =
        creationMeta.customerId ||
        (await this.entityUtilsService.getCustomerIdForAccount({
          accountType: creationMeta.accountType,
          accountId: creationMeta.entityId
        }));

      await this.angularFire
        .list(
          `/${this.getFeedPathForAccountType(creationMeta.accountType)}/${
            creationMeta.entityId
          }`
        )
        .push(feedItem);

      if (customerId) {
        await this.angularFire
          .list(`/${CUSTOMER_COMBINED_FEEDS_PATH}/${customerId}`)
          .push(feedItem);
      }
    }

    return feedItem;
  }

  getAll(itemsMeta: FeedItemMeta[]): Observable<any>[] {
    return itemsMeta.map(itemMeta => this.get(itemMeta));
  }

  get(itemMeta: FeedItemMeta): Observable<FeedItem> {
    const item$ =
      itemMeta.entityId && itemMeta.endpoint !== 'tasksCompleted'
        ? this.angularFire.getObject(
            `/${itemMeta.endpoint}/${itemMeta.entityId}/${itemMeta.itemId}`
          )
        : this.angularFire.getObject(
            `/${itemMeta.endpoint}/${itemMeta.itemId}`
          );

    return item$.pipe(
      filter(item => !!item),
      map((item: FeedItem) => {
        item.endpoint = itemMeta.endpoint;
        item.itemType = itemMeta.itemType;

        if (itemMeta.entityType && !item.entityType) {
          item.entityType = itemMeta.entityType;
        }

        if (itemMeta.entityName && !item.entityName) {
          item.entityName = itemMeta.entityName;
        }

        if (itemMeta.entityId && !item.entityId) {
          item.entityId = itemMeta.entityId;
        }

        if (itemMeta.accountType && !item.accountType) {
          item.accountType = itemMeta.accountType;
        }

        return item;
      }),
      take(1)
    );
  }

  getFromItemId(basePath: string, itemId: string): Observable<FeedItem[]> {
    return this.angularFire.getList(basePath, ref =>
      ref.orderByChild('itemId').equalTo(itemId)
    );
  }

  /**
   * Inspects an item about to be added to the feed,
   * and returns the appropriate item type.
   */
  getItemType(item: any, endpoint: string): string {
    switch (endpoint) {
      case CUSTOMER_NOTES_PATH:
        return 'customerNote';
      case ADWORDS_ACCOUNT_NOTES_PATH:
      case BING_ACCOUNT_NOTES_PATH:
      case LSA_ACCOUNT_NOTES_PATH:
        return 'accountNote';
      case CUSTOMER_COMMUNICATIONS_PATH:
        return 'customerCommunication';
      default:
        break;
    }

    return null;
  }

  editableByType(
    item: FeedItem | TypesenseCustomerFeedFieldList,
    alternateEndpoint?: string
  ): boolean {
    switch (alternateEndpoint || item.endpoint) {
      case 'tasksCompleted':
        return !!(item.completedBy === this.userService.currentUser.$key);
      default:
        return !!(item.createdBy === this.userService.currentUser.$key);
    }
  }

  getFeedPathForAccountType(accountType: string): string {
    switch (accountType) {
      case 'adwords':
        return ADWORDS_ACCOUNT_FEEDS_PATH;

      case 'bing':
        return BING_ACCOUNT_FEEDS_PATH;

      case 'lsa':
        return LSA_ACCOUNT_FEEDS_PATH;
    }
  }
}
