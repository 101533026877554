import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { FeedItemNoteModule } from '../../shared/feed-items/feed-item-note/feed-item-note.module';
import { MarkdownModule } from '../../shared/markdown/markdown.module';
import { SharedModule } from '../../shared/shared.module';
import { SharedPipesModule } from '../../shared/shared-pipes.module';
import { TaskNotesComponent } from './task-notes.component';

@NgModule({
  imports: [
    FeedItemNoteModule,
    FormsModule,
    MarkdownModule,
    SharedModule,
    SharedPipesModule,
    VirtualScrollerModule
  ],
  declarations: [TaskNotesComponent],
  exports: [TaskNotesComponent]
})
export class TaskNotesModule {}
