import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { SharedModule } from '../../../shared/shared.module';
import { SharedPipesModule } from '../../../shared/shared-pipes.module';
import { CustomerMessageDetailsDialogComponent } from './customer-message-details-dialog.component';

@NgModule({
  imports: [ScrollingModule, SharedModule, SharedPipesModule],
  declarations: [CustomerMessageDetailsDialogComponent],
  exports: [CustomerMessageDetailsDialogComponent]
})
export class CustomerMessageDetailsDialogModule {}
