import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDashboardTableModule } from 'src/app/accounts/account-dashboard/account-dashboard-table/account-dashboard-table.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomerDashboardWidgetAccountsDashboardSharedTableComponent } from './customer-dashboard-widget-accounts-dashboard-shared-table.component';

@NgModule({
  declarations: [CustomerDashboardWidgetAccountsDashboardSharedTableComponent],
  imports: [
    CommonModule,
    CommonModule,
    AccountDashboardTableModule,
    SharedModule
  ],
  exports: [CustomerDashboardWidgetAccountsDashboardSharedTableComponent]
})
export class CustomerDashboardWidgetAccountsDashboardSharedTableModule {}
