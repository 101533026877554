import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { TaskTemplateFields } from 'src/app/admin/task-templates/task-template.interface';
import {
  EntityMetadata,
  ListDisplayItem,
  ListDisplayItemValue
} from 'src/app/shared/shared.interface';
import { TaskEditFormComponent } from 'src/app/tasks/task-edit-form/task-edit-form.component';
import { TaskFormService } from 'src/app/tasks/task-form.service';
import { SubtasksService } from '../../../../tasks/task-project/subtasks.service';
import { SNACKBAR_DURATION_ERROR } from '../../../constants';
import { WindowPane } from '../../window-pane/window-pane';
import { TaskCreateWindowData } from '../../window-pane/window-pane.interface';
import { CreateTaskService } from '../create-task.service';

@Component({
  selector: 'ease-create-task-single',
  templateUrl: './create-task-single.component.html',
  styleUrls: ['./create-task-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTaskSingleComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @Input() data: TaskCreateWindowData;
  @Input() taskTemplates: ListDisplayItem[];
  @Input() templateControl: FormControl<string>;
  @Output()
  create: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('taskEditForm', { static: true })
  taskEditForm: TaskEditFormComponent;
  @ViewChild('windowSubtitleTemplate', { read: TemplateRef })
  windowSubtitleTemplate: TemplateRef<any>;
  public isWorking: boolean = false;
  public createTaskForm: FormGroup<{
    fields: FormControl<TaskTemplateFields>;
  }> = this.formBuilder.group({
    fields: this.formBuilder.control(null)
  });

  constructor(
    private formBuilder: FormBuilder,
    private createTaskService: CreateTaskService,
    private taskFormService: TaskFormService,
    private windowPane: WindowPane<EntityMetadata>,
    private subtaskService: SubtasksService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.createTaskService.setWindowPane(this.windowPane);
  }

  ngAfterViewInit() {
    this.windowPane.updateSubtitleTemplate(this.windowSubtitleTemplate);
    /**
     * Revert window header templates to default. Just in case user toggles
     * from bulk to single.
     */
    this.windowPane.updateIconTemplate(null);
    this.windowPane.updateTitleTemplate(null);

    this.createTaskService.startNameSubscription(
      this.taskEditForm.taskEditForm.controls.name
    );
  }

  async setTemplate(templateId: ListDisplayItemValue) {
    await firstValueFrom(
      this.createTaskService.getTemplate(templateId as string)
    ).then(fields => {
      // Check if you are creating a sub task and limit scheduled templates
      if (this.data?.parent && fields?.scheduled) {
        this.matSnackBar.open(
          'You cannot create a sub task based on a scheduled template',
          'Ok',
          {
            duration: SNACKBAR_DURATION_ERROR
          }
        );
        this.templateControl.setValue(null);
        return;
      }

      this.createTaskForm.controls.fields.patchValue(fields);
    });
  }

  async createTask(formValue: any, isFormValid: boolean): Promise<void> {
    if (isFormValid) {
      this.isWorking = true;
      const { fields } = formValue;
      const taskId = await this.taskFormService.createTask({
        fields
      });

      if (fields?.parent) {
        // if the task has a parent, we need to create a relationship
        await this.subtaskService.attach(fields.parent, taskId);
      }
      this.isWorking = false;
      if (taskId) {
        this.create.emit(taskId);
        this.windowPane.close({
          // Return true if task was created
          closeResult: true,
          saveState: true
        });
      }
    }
  }

  cancel() {
    this.windowPane.close();
  }

  ngOnDestroy() {
    this.createTaskService.stopNameSubscription();
  }
}
