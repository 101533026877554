<div
  [easeTaskWindower]="task?.itemId || task?.$key"
  class="group relative my-2 flex cursor-pointer flex-col gap-2 py-2 text-gray-600"
>
  <div class="flex flex-row items-center gap-2">
    <ease-avatar class="self-start" [userId]="task?.completedBy"></ease-avatar>

    <div class="flex flex-1 flex-col gap-1 overflow-hidden">
      <div class="flex flex-row items-center gap-1">
        <div class="flex-1 text-[13px] leading-5">
          <span class="font-bold">
            {{ users[task?.completedBy]?.name }}
          </span>
          {{ task?.snoozeUntil ? 'snoozed' : 'completed' }} a task
        </div>

        <div
          *ngIf="editable && task?.completionMessage && messageToggle?.isHidden"
          class="top-0 right-0 z-1 flex h-[28px] flex-row rounded border border-solid border-gray-300 bg-gray-100 px-1 text-gray-700 group-hover:visible md:invisible md:absolute"
        >
          <div
            (click)="messageToggle.show(); $event.stopPropagation()"
            matTooltip="Edit"
            class="cursor-pointer py-1 px-1"
          >
            <mat-icon class="mat-icon-18"> edit </mat-icon>
          </div>
        </div>
      </div>

      <div class="flex flex-row items-center gap-1">
        <mat-icon class="mat-icon-16">list_alt</mat-icon>
        <span class="truncate text-[13px] font-medium leading-5">
          {{ task?._highlightResult?.name?.value || task?.name }}
        </span>
      </div>

      <div
        *ngIf="(task?.entityName && task?.accountType) || showEntity"
        class="flex flex-row items-center gap-2"
      >
        <ease-entity-icon
          [entity]="{
            entityType: task.entityType,
            accountType: task.accountType
          }"
          [size]="14"
        >
        </ease-entity-icon>
        <span class="truncate text-xs font-medium leading-5 text-gray-600">
          {{ task?.entityName }}
        </span>
      </div>

      <ease-toggle
        #messageToggle="toggle"
        class="cursor-text rounded bg-white p-2 shadow-md"
        [ngClass]="{ hidden: !task?.completionMessage }"
        (click)="$event.stopPropagation()"
      >
        <ng-container *ngIf="messageToggle.isHidden">
          <div
            *ngIf="
              task?._highlightResult?.completionMessage?.matchedWords?.length;
              else defaultMessage
            "
            [innerHTML]="task?._highlightResult?.completionMessage?.value"
          ></div>
          <ng-template #defaultMessage>
            <div [easeMarkdown]="task?.completionMessage" size="sm"></div>
          </ng-template>
        </ng-container>
        <ease-markdown-form
          [allowMentions]="true"
          *ngIf="!messageToggle.isHidden"
          (save)="saveCompletionMessage($event); messageToggle.hide()"
          (cancel)="messageToggle.hide()"
          [(ngModel)]="task.completionMessage"
          size="sm"
        >
        </ease-markdown-form>
      </ease-toggle>

      <div class="flex flex-row gap-3">
        <div
          class="flex flex-row items-center gap-1 text-xs leading-5 text-gray-600"
        >
          <mat-icon class="mat-icon-16">check</mat-icon>
          <span>
            {{ task?.completedAt | amDateFormat: dateFormat }}
          </span>
        </div>
        <div
          *ngIf="task?.snoozeUntil"
          class="flex flex-row items-center gap-1 text-xs leading-5 text-gray-600"
        >
          <mat-icon class="mat-icon-16">snooze</mat-icon>
          <span>
            {{ task?.snoozeUntil | amDateFormat: dateFormat }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
