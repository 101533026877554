<div class="form-group">
  <ease-email-variables
    [hiddenVariables]="hiddenVariableFields"
    [controls]="sharedEmailVariablesControl"
  ></ease-email-variables>
  <div>
    <mat-checkbox color="primary" [formControl]="useSharedValuesControl"
      >Use for all recipients</mat-checkbox
    >
  </div>
  <div class="-ml-5" *ngIf="!useSharedValuesControl.value">
    <button
      type="button"
      (click)="onCopySharedEmailVariablesToAllRecipients()"
      mat-button
    >
      <mat-icon>file_copy</mat-icon>
      <span>Copy to all recipients</span>
    </button>
  </div>
</div>
