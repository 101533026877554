import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';
import { MatSelectChange } from '@angular/material/select';

import {
  UserRole,
  UserRolesService
} from '../../../users/user-roles/user-roles.service';
import { UserModel } from '../../../users/user.model';
import { UserService } from '../../../users/user.service';

export interface RoleSelectorChange {
  roleId: string;
  userId: string;
}

@Component({
  selector: 'ease-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleSelectorComponent implements OnInit {
  @Input()
  currentRoles: { [role: string]: string };
  @Input()
  required: boolean = false;
  @Input()
  set scope(scope: string) {
    this.availableUserRoles$ = this.userRolesService.getForScope(scope);
  }
  @Input() disabled: boolean = false;
  @Output()
  roleChange: EventEmitter<RoleSelectorChange> = new EventEmitter<RoleSelectorChange>();
  public availableUserRoles$: Observable<UserRole[]>;
  public users$: Observable<UserModel[]>;

  constructor(
    private userRolesService: UserRolesService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.users$ = this.userService.users.pipe(
      take(1),
      map(users => orderBy(users, 'name'))
    );
  }

  onRoleChange(roleId: string, changeEvent: MatSelectChange) {
    this.roleChange.emit({ roleId, userId: changeEvent.value });
  }
}
