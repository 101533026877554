import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  ContentChild,
  TemplateRef,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { FloatLabelType } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { orderBy, reject } from 'lodash-es';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import {
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { ListDisplayItem, ListDisplayItemValue } from '../shared.interface';

@UntilDestroy()
@Component({
  selector: 'ease-advanced-multi-selector',
  templateUrl: './advanced-multi-selector.component.html',
  styleUrls: ['./advanced-multi-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdvancedMultiSelectorComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AdvancedMultiSelectorComponent
  implements OnInit, ControlValueAccessor
{
  @Input() set options(options: ListDisplayItem[]) {
    this._options = options;
    this.optionsSource.next(options);
  }

  get options() {
    return this._options;
  }

  private _options: ListDisplayItem[];
  private optionsSource: BehaviorSubject<ListDisplayItem[]> =
    new BehaviorSubject<ListDisplayItem[]>([]);
  private options$: Observable<ListDisplayItem[]> =
    this.optionsSource.asObservable();
  private valueFromWriteSource$: ReplaySubject<ListDisplayItemValue[]> =
    new ReplaySubject<ListDisplayItemValue[]>();

  @Input() floatLabel: FloatLabelType = 'never';
  @Input() label: string;
  @Input() placeholder: string = 'Search';
  @Input() hint: string;
  @Input() keepSelectedOrder = false;
  @ContentChild('selectTrigger') selectTrigger: TemplateRef<any>;
  @ContentChild('viewValueTemp') viewValueTemp: TemplateRef<any>;
  @ContentChild('customBottomElements') customBottomElements: TemplateRef<any>;
  @ContentChild('customBottomActions') customBottomActions: TemplateRef<any>;
  @Output() selectOnlyUpdated: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatInput) matInput: MatInput;
  @ViewChild('selectAllCheckbox') selectAllCheckbox: MatCheckbox;
  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;

  private latestSelectedValues: ListDisplayItemValue[];
  public searchControl: FormControl<string> = new FormControl();
  public selectAllControl: FormControl<boolean> = new FormControl(false);
  public firstViewValue: string;
  public isOpened: boolean = false;
  public someSelected: boolean = false;
  public selectedControl: FormControl<ListDisplayItemValue[]>;
  public sortedOptions$: Observable<ListDisplayItem[]>;

  constructor(private formBuilder: FormBuilder) {
    /**
     * This control is ONLY used for communicating parent,
     * other unnecessary controls should NOT be included.
     * Also, see `registerOnChange()` implementation for
     * outputting changes.
     */
    this.selectedControl = this.formBuilder.control([]);
  }

  ngOnInit(): void {
    /**
     * Listen for value changes from outside (via writeValue)
     * and update the selected value(s) with the latest options
     */
    this.valueFromWriteSource$
      .asObservable()
      .pipe(
        withLatestFrom(this.options$),
        tap(([value, options]: [ListDisplayItemValue[], ListDisplayItem[]]) => {
          this.setWriteValue(options, value);
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.sortedOptions$ = this.options$.pipe(
      /**
       * Listen for options changes and use the current value
       * to match available values with the new options
       */
      tap(options => this.setWriteValue(options, this.selectedControl.value)),
      switchMap(options =>
        this.selectedControl.valueChanges.pipe(
          startWith(this.selectedControl.value),
          tap(selectedValues => {
            this.setFirstViewValue(options, selectedValues);
            this.updateSelectAllStatus(options, selectedValues);
          }),
          map(selectedValues => this.getSortedOptions(options, selectedValues))
        )
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.selectAllControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(status => this.selectAll(status));

    this.searchControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (value && value !== '') {
          this.selectAllControl.disable({ emitEvent: false });
        } else {
          this.selectAllControl.enable({ emitEvent: false });
        }
      });
  }

  /**
   * Set write value conditionally when receiving from parent
   *
   * Note: if any selected is no longer available in options,
   * omit them and patch the "filtered" values only
   *
   * @param options
   * @param valueFromWrite
   */
  setWriteValue(
    options: ListDisplayItem[],
    valueFromWrite: ListDisplayItemValue[]
  ): void {
    let filteredValues = valueFromWrite || null;

    if (valueFromWrite?.length && options?.length) {
      filteredValues = valueFromWrite.filter(selected =>
        options?.some(option => option.value === selected)
      );
    }

    this.dispatchLatestSelected(filteredValues);
  }

  getSortedOptions(
    options: ListDisplayItem[],
    selectedValues: ListDisplayItemValue[]
  ): ListDisplayItem[] {
    const sel: ListDisplayItem[] = [];
    const unsel: ListDisplayItem[] = [];

    if (!options?.length) {
      return [];
    }

    /**
     * - Any selected hidden options, render only for unselect
     * - Any non-selected hidden options, without rendering
     */
    if (!selectedValues?.length || options?.length === selectedValues?.length) {
      return orderBy(reject(options, { hidden: true }), 'viewValue');
    } else {
      for (const option of options) {
        const isInSelected = selectedValues?.includes(option.value);
        if (isInSelected || (isInSelected && option?.hidden)) {
          sel.push(option);
        } else {
          !option?.hidden && unsel.push(option);
        }
      }

      return [
        ...(this.keepSelectedOrder ? sel : orderBy(sel, 'viewValue')),
        ...orderBy(unsel, 'viewValue')
      ];
    }
  }

  updateSelectAllStatus(
    options: ListDisplayItem[],
    selectedValues: ListDisplayItemValue[]
  ): void {
    const status = options?.length === selectedValues?.length;

    if (!status && selectedValues?.length > 0) {
      this.someSelected = true;
    } else {
      this.someSelected = false;
    }

    this.selectAllControl.patchValue(status, {
      emitEvent: false
    });
  }

  setFirstViewValue(
    options: ListDisplayItem[],
    selectedValues: ListDisplayItemValue[]
  ): void {
    let matchedOption: ListDisplayItem;

    if (selectedValues?.length && options?.length) {
      matchedOption = options?.find(
        option => option?.value === selectedValues[0]
      );
    }

    this.firstViewValue = matchedOption?.viewValue || '';
  }

  /**
   * Always use this dispatcher for updating `latestSelectedValues` &
   * emit latest values because NOT all selected values are available when
   * filtering or with virtual scroll
   *
   * @param values
   * @returns void
   */
  dispatchLatestSelected(values: ListDisplayItemValue[]): void {
    this.latestSelectedValues = values;
    this.selectedControl.patchValue(values);
  }

  matSelectOpenedChange(isOpen: boolean): void {
    if (isOpen) {
      // Open mat-select with latest selected values for other transforming
      // because options are destroyed while filtering or virtual scroll
      this.latestSelectedValues = this.selectedControl.value;

      this.isOpened = true;
      this.matInput && this.matInput.focus();
    } else {
      this.isOpened = false;
      this.searchControl.reset();
    }

    // To avoid blank mat-select-trigger/mat-option, refresh current items in viewport
    if (this.virtualScroller) {
      this.virtualScroller.refresh();
    }
  }

  selectOnly(option: ListDisplayItem): void {
    if (!option?.disabled) {
      this.dispatchLatestSelected([option.value]);
      this.selectOnlyUpdated.next();
    }
  }

  selectAll(status: boolean): void {
    let newValues: ListDisplayItemValue[];

    if (
      status &&
      !this.selectAllCheckbox.indeterminate &&
      this.options?.length
    ) {
      newValues = this.options
        .filter(option => !option?.disabled)
        .map(option => option.value);
    } else {
      newValues = [];
    }

    this.dispatchLatestSelected(newValues);
  }

  /**
   * Depends on status, either merge/deduplicate or remove
   *
   * @param filteredOptions
   * @param selectStatus
   * @returns void
   */
  selectAllFiltered(
    filteredOptions: ListDisplayItem[],
    selectStatus: 'select' | 'unselect'
  ): void {
    if (this.searchControl.value && filteredOptions?.length) {
      let newValues: ListDisplayItemValue[];

      const filteredValues = filteredOptions
        .filter(item => !item?.disabled)
        .map(item => item.value);

      if (selectStatus === 'select') {
        newValues = [
          ...new Set([...(this.latestSelectedValues || []), ...filteredValues])
        ];
      }

      if (selectStatus === 'unselect') {
        newValues = this.latestSelectedValues?.filter(
          value => !filteredValues.includes(value)
        );
      }

      this.dispatchLatestSelected(newValues);
    }
  }

  /**
   * Check current value if existed on `latestSelectedValues` list
   * Remove if existed else merge/deduplicate from the selected list
   *
   * @param currentValue
   * @returns void
   */
  selectIndividual(option: ListDisplayItem): void {
    if (!option?.disabled) {
      let newValues: ListDisplayItemValue[];

      if (
        this.latestSelectedValues &&
        this.latestSelectedValues?.indexOf(option.value) !== -1
      ) {
        newValues = this.latestSelectedValues?.filter(
          value => value !== option.value
        );
      } else {
        newValues = [
          ...new Set([...(this.latestSelectedValues || []), option.value])
        ];
      }

      this.dispatchLatestSelected(newValues);
    }
  }

  /**
   * Known race condition `writeValue()` with formControl, as workaround by
   * using ReplaySubject() to receive the last value from parent.
   * https://github.com/angular/angular/issues/29218
   *
   * @see {@link AdvancedMultiSelectorComponent.setWriteValue()}
   *
   * @param values
   * @returns void
   */
  writeValue(values: ListDisplayItemValue[]): void {
    this.valueFromWriteSource$.next(values);
  }

  /**
   * Subscribe `selectedControl` and push latest values to parent
   *
   * @param fn
   * @returns void
   */
  registerOnChange(fn: any): void {
    this.selectedControl.valueChanges.pipe(untilDestroyed(this)).subscribe(fn);
  }

  registerOnTouched(): void {}

  /**
   * Pausing value & status update(emitEvent: false) if controls are disabled
   *
   * @param isDisabled
   * @returns void
   */
  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectedControl.disable({ emitEvent: false });
      this.selectAllControl.disable({ emitEvent: false });
    } else {
      this.selectedControl.enable({ emitEvent: false });
      this.selectAllControl.enable({ emitEvent: false });
    }
  }
}
