import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';

import { TaskSidebarModule } from '../../../tasks/task-sidebar/task-sidebar.module';
import { TaskViewModule } from '../../../tasks/task-view/task-view.module';
import { ScrollspyModule } from '../../scrollspy/scrollspy.module';
import { EntityIconModule } from '../../entity-icon/entity-icon.module';
import { DrawerSidebarModule } from '../../drawer-sidebar/drawer-sidebar.module';
import { StatesModule } from '../../states/states.module';
import { WindowPaneViewTaskComponent } from './window-pane-view-task.component';

@NgModule({
  declarations: [WindowPaneViewTaskComponent],
  imports: [
    EntityIconModule,
    CommonModule,
    DrawerSidebarModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatSidenavModule,
    TaskSidebarModule,
    TaskViewModule,
    ScrollspyModule,
    StatesModule
  ]
})
export class WindowPaneViewTaskModule {}
