import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ListAsObject } from 'src/app/shared/firebase-db.service';

@Component({
  selector: 'ease-task-phase',
  templateUrl: './task-phase.component.html',
  styleUrls: ['./task-phase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskPhaseComponent {
  private _selectedPhaseId: string;

  @Input() set selectedPhase(selectedPhase: ListAsObject<boolean>) {
    this._selectedPhaseId = selectedPhase
      ? Object.keys(selectedPhase)[0]
      : null;
  }

  get selectedPhaseId() {
    return this._selectedPhaseId;
  }

  @Output()
  phaseChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  onPhaseChange(phaseId: string): void {
    this._selectedPhaseId = phaseId;
    this.phaseChanged.emit(this._selectedPhaseId);
  }
}
