import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  HostBinding,
  ContentChild,
  TemplateRef,
  Renderer2
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { isEqual, orderBy } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  startWith,
  switchMap
} from 'rxjs';

import { UserModel } from 'src/app/users/user.model';
import { UserService } from 'src/app/users/user.service';
import { UserSelected } from '../user-selector/user-selector.interface';
import { SharedLayoutService } from '../shared-layout.service';
import {
  UserDragMeta,
  UserSelectorInputService
} from './user-selector-input.service';

const CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UserSelectorInputComponent),
  multi: true
};

@Component({
  selector: 'ease-user-selector-input',
  templateUrl: './user-selector-input.component.html',
  styleUrls: ['./user-selector-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CONTROL_VALUE_ACCESSOR]
})
export class UserSelectorInputComponent
  implements ControlValueAccessor, OnInit
{
  @HostBinding('class') class = 'block pb-5';
  @ContentChild('menu') menu: TemplateRef<any>;
  @Input() label: string = 'Select user';
  @Input() onlyUsers: boolean = false;
  /**
   * Make sure to enable drag and drop for all applicable instances and
   * add the `cdkDropListGroup` directive to the nearest ancestor.
   */
  @Input() enableDragNDrop: boolean = true;
  @Output() selected: EventEmitter<UserSelected> =
    new EventEmitter<UserSelected>();
  @Output() deselected: EventEmitter<string> = new EventEmitter<string>();
  public users: FormControl<UserSelected[]> = new FormControl([]);
  public selectedUsers$: Observable<string[]>;
  public selectedUserModels$: Observable<UserModel[]>;
  private isSelfSource: BehaviorSubject<{ selected: boolean }> =
    new BehaviorSubject({
      selected: false
    });
  public isSelf$: Observable<{ selected: boolean }> =
    this.isSelfSource.asObservable();
  public isDragging$: Observable<UserDragMeta>;
  public containerId: string;

  constructor(
    public sharedLayoutService: SharedLayoutService,
    renderer: Renderer2,
    public userService: UserService,
    public userSelectorInputService: UserSelectorInputService
  ) {
    this.userSelectorInputService.setRenderer(renderer);
  }

  ngOnInit(): void {
    this.selectedUsers$ = this.users.valueChanges.pipe(
      startWith(null),
      map(() => {
        const users = this.users.value;
        const selected = users.some(
          user => user.userId === this.userService.currentUser.$key
        );
        this.isSelfSource.next({ selected });
        return users.map(user => user.userId);
      })
    );

    this.selectedUserModels$ = this.selectedUsers$.pipe(
      distinctUntilChanged(isEqual),
      switchMap(userIds =>
        userIds.length
          ? combineLatest(userIds.map(userId => this.userService.get(userId)))
          : of([])
      ),
      map((users: UserModel[]) => orderBy(users, ['name']))
    );

    this.isDragging$ = this.userSelectorInputService.getIsDragging();
  }

  addSelf(): void {
    this.onSelect({
      userId: this.userService.currentUser.$key,
      method: 'direct'
    });
  }

  removeSelf(): void {
    this.onDeselect(this.userService.currentUser.$key);
  }

  onSelect(selectedUser: UserSelected): void {
    /**
     * Don't add the user if they already exist on the list -- only happens
     * when a group is selected. This way, a "direct" assign take precedence.
     */
    const isExisting = this.users.value.some(
      user => user.userId === selectedUser.userId
    );
    if (isExisting) {
      return;
    }

    const users = [...this.users.value, selectedUser];
    this.users.setValue(users);
    this.onChange(users);
    this.selected.emit(selectedUser);
  }

  onDeselect(userId: string): void {
    const users = this.users.value.filter(user => user.userId !== userId);
    this.users.setValue(users);
    this.deselected.emit(userId);
    this.onChange(users);
  }

  dropped(event: CdkDragDrop<UserModel>): void {
    const user: UserModel = event.item.data;
    // Add the user if the drop was done over a valid container
    if (event.isPointerOverContainer) {
      this.onSelect({
        userId: user.$key,
        method: 'direct'
      });
    }
  }

  dragStarted(event: CdkDragStart): void {
    this.containerId = event.source.dropContainer.id;
    this.userSelectorInputService.setIsDragging({
      active: true,
      containerId: this.containerId,
      user: event.source.data,
      isOverContainer: true
    });
  }

  dragDropped(event: CdkDragDrop<UserModel>): void {
    const user: UserModel = event.item.data;
    // Remove the user if they were not dropped on a valid container
    if (!event.isPointerOverContainer) {
      this.onDeselect(user.$key);
    }
    this.userSelectorInputService.setIsDragging({ active: false });
  }

  /**
   * Toggles the flag that states whether the dragged item is over
   * a container or not.
   *
   * @param dragMeta
   * @param isOverContainer
   */
  updateDragPosition(dragMeta: UserDragMeta, isOverContainer: boolean): void {
    this.userSelectorInputService.setIsDragging({
      ...dragMeta,
      isOverContainer
    });
  }

  getUserId(index: number, user: UserModel): string {
    return user?.$key;
  }

  /* Implemented as part of ControlValueAccessor. */
  onChange: (value: UserSelected[]) => any = () => {};

  onTouched: () => any = () => {};

  writeValue(users: string[] | UserSelected[]): void {
    const usersSelected =
      users?.map(user =>
        typeof user === 'string' ? { userId: user, method: 'direct ' } : user
      ) || [];
    this.users.setValue(usersSelected);
    this.onChange(usersSelected);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
