import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EntityIconModule } from 'src/app/shared/entity-icon/entity-icon.module';
import { CompleteTaskPopoverModule } from '../../shared/popover/complete-task/complete-task-popover.module';
import { SelectUserPopoverModule } from '../../shared/popover/select-user/select-user-popover.module';
import { SharedModule } from '../../shared/shared.module';
import { SharedPipesModule } from '../../shared/shared-pipes.module';
import { TaskPhaseModule } from '../task-phase/task-phase.module';
import { TaskStatusModule } from '../task-status/task-status.module';
import { TaskScheduledDatesPopoverModule } from '../../shared/popover/task-scheduled-dates/task-scheduled-dates-popover.module';
import { TaskPanelScheduledComponent } from './task-panel-scheduled/task-panel-scheduled.component';
import { TaskPanelComponent } from './task-panel/task-panel.component';
import { TaskPanelCompactComponent } from './task-panel-compact/task-panel-compact.component';

@NgModule({
  imports: [
    CompleteTaskPopoverModule,
    RouterModule,
    SelectUserPopoverModule,
    SharedModule,
    SharedPipesModule,
    TaskPhaseModule,
    TaskStatusModule,
    TaskScheduledDatesPopoverModule,
    EntityIconModule
  ],
  declarations: [
    TaskPanelComponent,
    TaskPanelCompactComponent,
    TaskPanelScheduledComponent
  ],
  exports: [
    TaskPanelComponent,
    TaskPanelCompactComponent,
    TaskPanelScheduledComponent
  ]
})
export class TaskPanelsModule {}
