import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { PopoverModule } from '../../../shared/popover/popover.module';
import { MarkdownModule } from '../../../shared/markdown/markdown.module';
import { DatepickerModule } from '../../../shared/datepicker/datepicker.module';
import { ChartsModule } from '../../../shared/charts/charts.module';
import { ButSuggestedBudgetPipeModule } from '../pipes/but-suggested-budget.module';
import { MetricsModule } from './../../../shared/metrics/metrics.module';

import { AlertCellPopoverComponent } from './alert-cell-popover/alert-cell-popover.component';
import { BudgetUtilizationPopoverComponent } from './budget-utilization-popover/budget-utilization-popover.component';
import { CompetitiveMetricPopoverComponent } from './competitive-metric-popover/competitive-metric-popover.component';
import { CplPopoverComponent } from './cpl-popover/cpl-popover.component';
import { CqPopoverComponent } from './cq-popover/cq-popover.component';
import { ResPopoverComponent } from './res-popover/res-popover.component';
import { SnoozePopoverComponent } from './snooze-popover/snooze-popover.component';
import { OsPopoverComponent } from './os-popover/os-popover.component';

@NgModule({
  declarations: [
    AlertCellPopoverComponent,
    BudgetUtilizationPopoverComponent,
    CompetitiveMetricPopoverComponent,
    CplPopoverComponent,
    CqPopoverComponent,
    ResPopoverComponent,
    OsPopoverComponent,
    SnoozePopoverComponent
  ],
  imports: [
    SharedModule,
    PopoverModule,
    MarkdownModule,
    ReactiveFormsModule,
    MetricsModule,
    ChartsModule,
    ReactiveFormsModule,
    DatepickerModule,
    ButSuggestedBudgetPipeModule
  ],
  exports: [
    AlertCellPopoverComponent,
    BudgetUtilizationPopoverComponent,
    CompetitiveMetricPopoverComponent,
    CplPopoverComponent,
    CqPopoverComponent,
    ResPopoverComponent,
    OsPopoverComponent,
    SnoozePopoverComponent
  ]
})
export class DashboardPopoversModule {}
