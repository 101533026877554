import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AsyncValidator,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import {
  combineLatest,
  filter,
  first,
  firstValueFrom,
  map,
  Observable,
  of,
  startWith
} from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { BoardModel } from 'src/app/boards/board.model';
import { ListModel } from 'src/app/boards/list.model';
import { Options } from 'rrule';
import { isEqual } from 'lodash-es';
import {
  TaskTemplate,
  TaskTemplateFields
} from '../../admin/task-templates/task-template.interface';
import { TaskTemplatesService } from '../../admin/task-templates/task-templates.service';
import { BoardService } from '../../boards/board.service';
import {
  EntityMetadata,
  EntityType,
  ListDisplayItem,
  ListDisplayItemValue
} from '../../shared/shared.interface';
import { emptyFormArray } from '../../shared/utils/functions';
import { CreateTaskService } from '../../shared/window/window-pane-create-task/create-task.service';
import { TaskCreateWindowData } from '../../shared/window/window-pane/window-pane.interface';
import { TaskFormService } from '../task-form.service';
import { TaskChecklistTemplateService } from '../task-modules/task-checklist/task-checklist-template.service';
import { TaskUserMenuService } from '../task-user-menu/task-user-menu.service';
import { ScheduledFrequency } from '../task.interface';
import { TaskRuleForm, TaskTemplateFieldsGroup } from '../task-form.interface';
import { TaskRoleSelectorComponent } from './task-role-selector/task-role-selector.component';

const TASK_FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TaskEditFormComponent),
  multi: true
};

const TASK_FORM_VALIDATOR = {
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => TaskEditFormComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'ease-task-edit-form',
  templateUrl: './task-edit-form.component.html',
  styleUrls: ['./task-edit-form.component.scss'],
  providers: [TASK_FORM_CONTROL_VALUE_ACCESSOR, TASK_FORM_VALIDATOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskEditFormComponent
  implements ControlValueAccessor, OnInit, AsyncValidator
{
  @Input() isTemplate = false;
  @Input() templateId: string;
  @Input() isPreScheduled = false;
  @Input() isSubtask = false;
  @Input() set windowData(windowData: TaskCreateWindowData) {
    this._windowData = windowData;
    this.setFinderEntity(windowData);
  }
  get windowData() {
    return this._windowData;
  }
  @ViewChild('roleSelector') roleSelector: TaskRoleSelectorComponent;
  @ViewChild('subtaskContainer') subtaskContainer: ElementRef;
  private _windowData: TaskCreateWindowData;
  public boards$: Observable<BoardModel[]>;
  public checklists$: Observable<ListDisplayItem[]>;
  public taskEditForm: TaskTemplateFieldsGroup;
  public subtaskEditForms: TaskTemplateFieldsGroup['controls']['childrenOverrides'];
  public rruleForm: TaskRuleForm;
  public finderEntityControl: FormControl<Omit<EntityMetadata, 'phase'>> =
    new FormControl();
  public isWorking: boolean = false;
  public listsForCurrentBoard$: Observable<ListModel[]>;
  public scheduledFrequencies: ScheduledFrequency[];
  public now = moment().startOf('day').toDate();
  public entityType: EntityType;
  public templateControl: FormControl<string> = new FormControl();
  public taskTemplates$: Observable<ListDisplayItem[]>;
  public subtaskTemplates$: Observable<TaskTemplate[]>;
  public subtaskTemplates: TaskTemplate[] = [];
  public enableScheduleToggle$: Observable<boolean>;

  constructor(
    private boardService: BoardService,
    private cdr: ChangeDetectorRef,
    private taskFormService: TaskFormService,
    private taskChecklistTemplateService: TaskChecklistTemplateService,
    public taskUserMenuService: TaskUserMenuService,
    private taskTemplatesService: TaskTemplatesService,
    private matSnackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private createTaskService: CreateTaskService
  ) {}

  ngOnInit() {
    this.taskTemplates$ = // Get All Task Templates if we are editing a template
      (
        this.isTemplate
          ? this.taskTemplatesService.getAllPlus()
          : this.taskTemplatesService.getAll()
      ).pipe(
        map(templates =>
          templates.reduce((acc, template) => {
            // filter out scheduled templates and templates with subtasks
            if (
              this.templateId !== template.$key &&
              !template.fields?.scheduled &&
              !template.fields?.childrenTemplates
            ) {
              acc.push({
                value: template.$key,
                viewValue: template.name,
                viewSubvalue: template.description
              });
            }
            return acc;
          }, [])
        )
      );

    this.taskEditForm = this.taskFormService.getTaskForm(
      this.isTemplate,
      this.isPreScheduled
    );

    // Prepare the subtask form array overrider
    this.subtaskEditForms = this.taskEditForm.controls.childrenOverrides;

    this.subtaskTemplates$ =
      this.taskEditForm.controls.childrenTemplates.valueChanges.pipe(
        startWith(this.taskEditForm.controls.childrenTemplates.value),
        tap(templates => {
          // Prepare form Array if we are creating tasks
          if (!this.isTemplate) {
            emptyFormArray(this.subtaskEditForms);
            for (const templateId of templates) {
              this.addSubTaskTemplate(templateId);
            }
          }
        }),
        switchMap(templates =>
          templates.length
            ? combineLatest(
                templates.map(templateId =>
                  this.taskTemplatesService.get(templateId)
                )
              )
            : of([])
        )
      );

    // Get all subtask templates for the selected template
    this.subtaskTemplates$
      .pipe(
        tap(subtaskTemplates => (this.subtaskTemplates = subtaskTemplates)),
        tap(() => this.cdr.markForCheck()),
        untilDestroyed(this)
      )
      .subscribe();

    if (this.isSubtask) {
      // If subtask inherit all window data into the form from the parent (eg. EntityId, EntityName, etc)
      this.taskEditForm.patchValue(this.windowData, { emitEvent: false });
    }

    this.boards$ = this.boardService.getAll();

    this.checklists$ = this.taskChecklistTemplateService.getAllForSelect();

    this.listsForCurrentBoard$ =
      this.taskEditForm.controls.board.valueChanges.pipe(
        untilDestroyed(this),
        startWith(null),
        switchMap(() =>
          this.taskFormService.getWritableBoardList(
            this.taskEditForm.controls.board.value
          )
        ),
        tap(lists => {
          if (lists && lists.length) {
            const listControl = this.taskEditForm.controls.list;
            listControl.setValue(
              this.taskFormService.getInitialList(lists, listControl.value)
            );
          }
        })
      );

    this.enableScheduleToggle$ = of(
      !this.isSubtask && !this.windowData?.parent
    ).pipe(
      switchMap(defaultEnabling => {
        const observable = (
          this.isTemplate
            ? this.taskEditForm.controls.childrenTemplates.valueChanges
            : this.subtaskEditForms.valueChanges
        ) as Observable<string[] | TaskTemplateFields[]>;

        // If we are a simple task enable schedule toggle by default
        return defaultEnabling
          ? // Listen for subtasks changes
            observable.pipe(
              startWith(defaultEnabling),
              map((subtasks: TaskTemplateFields[]) => !!!subtasks.length)
            )
          : of(defaultEnabling);
      }),
      tap(enableScheduleToggle => {
        // Set up Schedule formcontrols if there wasn't setted before.
        if (enableScheduleToggle && !this.rruleForm) {
          this.setUpScheduleToggle();
        } else if (!enableScheduleToggle) {
          // Toggle off schedule Toggle if we disable the toggle
          this.disableSchedule();
        }
      })
    );

    this.taskEditForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(formVal => this.emitChange(formVal));

    this.finderEntityControl.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged(isEqual))
      .subscribe(finderEntity => this.setSelectedEntity(finderEntity));

    this.taskFormService
      .dedupeUserControls(
        this.taskEditForm.controls.assigned,
        this.taskEditForm.controls.subscribed
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onAssign() {
    this.taskFormService.subscribeAssigner(
      this.taskEditForm.controls.assigned,
      this.taskEditForm.controls.subscribed
    );
  }

  setUpScheduleToggle() {
    this.rruleForm = this.taskFormService.getRruleForm();
    this.scheduledFrequencies = this.taskFormService.getScheduledFrequencies();

    this.rruleForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(val =>
        this.taskEditForm.controls.rrule.setValue(
          this.taskFormService.getTaskRule(val as Options)
        )
      );

    this.rruleForm.controls.interval.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(interval =>
        this.rruleForm.controls.count.setValue(
          this.taskFormService.getCountFromInterval(interval)
        )
      );

    this.taskEditForm.controls.scheduled.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(scheduled =>
        this.taskFormService.toggleScheduled(
          this.taskEditForm,
          this.rruleForm,
          scheduled,
          this.isTemplate
        )
      );
  }

  disableSchedule() {
    this.taskEditForm.controls.scheduled.setValue(false);
  }

  changeStatus(statusId: string): void {
    this.taskEditForm.controls.status.setValue(
      statusId ? { [statusId]: true } : null
    );
  }

  changePhase(phaseId: string): void {
    this.taskEditForm.controls.phase.setValue(
      phaseId ? { [phaseId]: true } : null
    );
  }

  emitChange(value: TaskTemplateFieldsGroup['value']) {
    this.onChange(value);
    this.cdr.markForCheck();
  }

  onChange: (value) => any = (): any => {};

  onTouched: () => any = (): any => {};

  onValidationChange: () => any = () => {};

  writeValue(formVal: TaskTemplateFieldsGroup['value']): void {
    if (this.taskEditForm && formVal) {
      this.taskEditForm.patchValue(this.getFormValueWithProperEntity(formVal));

      if (formVal?.rrule) {
        this.rruleForm.patchValue(
          this.taskFormService.getIncomingRule(formVal.rrule)
        );
      }

      this.cdr.markForCheck();
    }
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: any): void {
    this.onValidationChange = fn;
  }

  validate(): Observable<ValidationErrors | null> {
    return this.taskEditForm.statusChanges.pipe(
      startWith(this.taskEditForm.status),
      filter(status => status !== 'PENDING'),
      map(status =>
        status === 'VALID'
          ? null
          : this.taskEditForm.errors || { required: true }
      ),
      first(),
      tap(() => this.cdr.markForCheck())
    );
  }

  async addSubTaskTemplate(
    templateSelected: ListDisplayItemValue,
    scrollAfterAddition = false
  ): Promise<void> {
    const templateId = templateSelected as string;

    if (!this.isTemplate) {
      const template = await firstValueFrom(
        this.createTaskService.getTemplate(templateId)
      );
      // Check if is a scheduled task template
      if (template.scheduled) {
        this.matSnackBar.open(
          'Subtasks with scheduled tasks are not supported',
          'OK',
          {
            duration: 5000
          }
        );
        this.templateControl.reset();
        return;
      }

      const subtaskForm: FormControl<TaskTemplateFields> =
        this.formBuilder.control(template);
      this.subtaskEditForms.push(subtaskForm);
    } else {
      this.taskEditForm.controls.childrenTemplates.setValue([
        ...this.taskEditForm.controls.childrenTemplates.value,
        templateId
      ]);
    }

    this.templateControl.reset();
    if (scrollAfterAddition) {
      // Wait 100 ms to scroll to the bottom
      setTimeout(() => {
        this.subtaskContainer.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
      }, 100);
    }
  }

  removeSubtaskTemplate(index: number): void {
    if (!this.isTemplate) {
      this.subtaskEditForms.removeAt(index);
    } else {
      const childrenTemplates =
        this.taskEditForm.controls.childrenTemplates.value.filter(
          (_, i) => i !== index
        );

      this.taskEditForm.controls.childrenTemplates.setValue(childrenTemplates);
    }
    this.cdr.detectChanges();
  }

  private hasValidEntityMeta(
    taskEntity: TaskTemplateFieldsGroup['value'] | TaskCreateWindowData
  ): boolean {
    if (taskEntity?.entityType === 'account') {
      return (
        !!taskEntity?.entityId &&
        !!taskEntity?.entityType &&
        !!taskEntity?.entityName &&
        !!taskEntity?.accountType
      );
    } else {
      return (
        !!taskEntity?.entityId &&
        !!taskEntity?.entityType &&
        !!taskEntity?.entityName
      );
    }
  }

  private getFormValueWithProperEntity(
    formVal: TaskTemplateFieldsGroup['value']
  ): TaskTemplateFieldsGroup['value'] {
    /**
     * When has a valid entity, sync finder immediately, e.g. edit scheduled task
     */
    if (this.hasValidEntityMeta(formVal)) {
      this.setFinderEntity({
        entityId: formVal.entityId,
        entityType: formVal.entityType,
        entityName: formVal.entityName,
        accountType: formVal.accountType
      });
    }

    /**
     * Important: ensure merge to the finder entity since
     * that is the latest what user see or change
     *
     * e.g. AMD or normal task creation
     *
     * @see this.windowData setter for initial value or updated Input for finder control
     */
    return {
      ...formVal,
      ...this.finderEntityControl.value
    };
  }

  private setSelectedEntity(entityMeta: EntityMetadata): void {
    this.taskEditForm.patchValue(
      this.taskFormService.getEntityMeta(entityMeta)
    );
    this.entityType = entityMeta?.entityType;
    this.cdr.detectChanges();
  }

  /**
   * Set entity to the finder control without emitting to avoid
   * unnecessary valueChange detection
   */
  private setFinderEntity(taskEntity: EntityMetadata) {
    this.finderEntityControl.patchValue(
      this.taskFormService.getEntityMeta(taskEntity),
      {
        emitEvent: false
      }
    );
  }
}
