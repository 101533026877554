<div [formGroup]="taskEditForm" class="flex flex-1 flex-col" cdkDropListGroup>
  <div class="form-group-sm">
    <mat-form-field>
      <input formControlName="name" placeholder="Task Name" matInput />
      <mat-error
        easeFormErrors
        [control]="taskEditForm?.controls?.name"
      ></mat-error>
    </mat-form-field>
  </div>

  <div>
    <div class="text-xs text-gray-600">Assigned Users</div>
    <ease-user-selector-input
      formControlName="assigned"
      label="Assign user"
      [onlyUsers]="true"
      (selected)="onAssign()"
    >
      <ng-template #menu let-userId>
        <ease-task-user-menu
          (move)="
            taskUserMenuService.move(
              userId,
              taskEditForm.controls.assigned,
              taskEditForm.controls.subscribed
            )
          "
          (remove)="
            taskUserMenuService.remove(userId, taskEditForm.controls.assigned)
          "
        ></ease-task-user-menu>
      </ng-template>
    </ease-user-selector-input>
    <mat-error
      *ngIf="taskEditForm.errors?.assignedWatchersRequired"
      class="mt-[-16px]"
    >
      {{ taskEditForm.errors.assignedWatchersRequired }}
    </mat-error>
  </div>

  <div>
    <div class="text-xs text-gray-600">Watchers</div>
    <ease-user-selector-input
      formControlName="subscribed"
      label="Add a watcher"
      [ngClass]="{
        '!pb-1': isTemplate,
        '!pb-3': !isTemplate
      }"
    >
      <ng-template #menu let-userId>
        <ease-task-user-menu
          mode="subscribe"
          (move)="
            taskUserMenuService.move(
              userId,
              taskEditForm.controls.subscribed,
              taskEditForm.controls.assigned
            );
            onAssign()
          "
          (remove)="
            taskUserMenuService.remove(userId, taskEditForm.controls.subscribed)
          "
        ></ease-task-user-menu>
      </ng-template>
    </ease-user-selector-input>
    <mat-error *ngIf="taskEditForm.errors?.assignedWatchersRequired">
      {{ taskEditForm.errors.assignedWatchersRequired }}
    </mat-error>
  </div>

  <div class="form-group-sm" *ngIf="isTemplate">
    <mat-slide-toggle formControlName="assignCurrentUser">
      Assign Current User
    </mat-slide-toggle>
  </div>

  <div class="mb-[6px]">
    <ease-task-role-selector
      formControlName="assignedRoles"
      #roleSelector
    ></ease-task-role-selector>
    <mat-error
      *ngIf="taskEditForm.errors?.assignedWatchersRequired"
      class="mt-[-16px]"
    >
      {{ taskEditForm.errors.assignedWatchersRequired }}
    </mat-error>
    <ng-container *ngIf="taskEditForm.errors?.entityError as error">
      <mat-error
        *ngIf="taskEditForm.errors?.entityError?.wrongType"
        class="mt-[-16px]"
      >
        The assigned role(s) does not exist on the linked customer
      </mat-error>
    </ng-container>
  </div>

  <div class="flex flex-col gap-4 md:flex-row">
    <div class="form-group flex-1">
      <mat-form-field>
        <mat-select placeholder="Board" formControlName="board">
          <mat-option
            *ngFor="let board of boards$ | async"
            [value]="board.$key"
            >{{ board.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group flex-1" *ngIf="taskEditForm.controls.board.value">
      <mat-form-field>
        <mat-select placeholder="List" formControlName="list">
          <mat-option
            *ngFor="let list of listsForCurrentBoard$ | async"
            [value]="list.$key"
            >{{ list.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group !mb-6 flex flex-1 flex-row">
      <ease-task-status
        [selectedStatus]="taskEditForm.controls.status.value"
        (statusChanged)="changeStatus($event)"
        class="inline-flex pr-6"
        matTooltip="Status"
      ></ease-task-status>
      <ease-task-phase
        [selectedPhase]="taskEditForm.controls.phase.value"
        (phaseChanged)="changePhase($event)"
        class="inline-flex pr-6"
        matTooltip="Phase"
      ></ease-task-phase>
    </div>
  </div>

  <div *ngIf="!isTemplate && !isSubtask" class="form-group mb-11">
    <ease-finder
      label="Customer / Account *"
      [formControl]="finderEntityControl"
      [searchTypes]="['customers', 'accounts', 'prospects']"
      [excludeFields]="['phase']"
      [clearOnSelect]="false"
    >
    </ease-finder>
    <mat-error *ngIf="taskEditForm.errors?.entityError as error" class="mt-1">
      {{ error.message }}
    </mat-error>
  </div>

  <div class="form-group">
    <ease-markdown-editor
      [easeMentions]="true"
      placeholder="Describe your task, @mention users"
      formControlName="description"
      [easeMarkdownVariable]="entityType"
    ></ease-markdown-editor>
  </div>
  <div class="form-group-sm">
    <ease-advanced-autocomplete
      formControlName="checklist"
      [options]="checklists$ | async"
      [placeholder]="'Select a checklist'"
    >
    </ease-advanced-autocomplete>
  </div>
  <div class="flex flex-1">
    <ease-datepicker
      class="mr-3"
      [min]="now"
      [max]="taskEditForm.controls.dueDate.value"
      formControlName="startDate"
      placeholder="Task Start Date"
    ></ease-datepicker>
    <ease-datepicker
      [min]="taskEditForm.controls.startDate.value"
      formControlName="dueDate"
      placeholder="Task Due Date"
    ></ease-datepicker>
  </div>

  <!-- Sub Tasks Container -->

  <div *ngIf="!isSubtask && !this.taskEditForm.controls.scheduled.value">
    <ease-sheet-divider></ease-sheet-divider>
    <div class="flex flex-row items-center justify-start">
      <div class="text-lg font-bold">Subtasks</div>
      <mat-icon
        class="mat-icon-16 ml-2 cursor-pointer"
        [satPopoverAnchor]="subtaskPopover"
        (click)="subtaskPopover.toggle()"
        >help_outline</mat-icon
      >
    </div>

    <sat-popover
      verticalAlign="below"
      horizontalAlign="start"
      [autoFocus]="false"
      [hasBackdrop]="true"
      #subtaskPopover
    >
      <ease-subtask-help-popover></ease-subtask-help-popover>
    </sat-popover>

    <ease-advanced-autocomplete
      class="mb-2"
      [formControl]="templateControl"
      [options]="taskTemplates$ | async"
      [placeholder]="'Select a sub-task template'"
      (optionSelected)="addSubTaskTemplate($event, true)"
    >
    </ease-advanced-autocomplete>

    <div class="flex flex-col gap-1 p-2" #subtaskContainer>
      <ng-container *ngIf="isTemplate; else taskOverider">
        <div
          class="flex flex-row items-center justify-between border border-gray-100 bg-gray-50 p-3 shadow-md"
          *ngFor="let subtaskTemplate of subtaskTemplates; let i = index"
        >
          <span> {{ subtaskTemplate.name }}</span>
          <button
            mat-icon-button
            type="button"
            (click)="removeSubtaskTemplate(i)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </ng-container>

      <ng-template #taskOverider>
        <ease-sheet-group
          class="shadow-md"
          easeSheetExpander
          *ngIf="subtaskEditForms?.length"
        >
          <ease-sheet-expand
            *ngFor="let subtaskForm of subtaskEditForms.controls; let i = index"
            [disableExpandIcon]="true"
            #sheetExpand
          >
            <div sheet-expand-before>
              <div
                class="flex flex-row items-center justify-start border border-gray-100 p-3"
              >
                <button
                  mat-icon-button
                  type="button"
                  (click)="sheetExpand.open()"
                >
                  <mat-icon>chevron_right</mat-icon>
                </button>
                <span>{{ subtaskForm?.value?.name }}</span>
              </div>
            </div>
            <ng-template sheet-expand-after-lazy>
              <div
                class="flex flex-row items-start justify-start border border-gray-100 p-2"
              >
                <div
                  class="mr-2 flex flex-col items-center justify-between self-stretch border-r border-gray-200 px-2"
                >
                  <button
                    mat-icon-button
                    type="button"
                    (click)="sheetExpand.close()"
                  >
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  <div class="flex-1"></div>
                  <button
                    mat-icon-button
                    type="button"
                    (click)="removeSubtaskTemplate(i)"
                    matTooltip="Delete Subtask"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                <div class="flex-1 flex-row py-4 px-5">
                  <ease-task-edit-form
                    class="w-full"
                    [formControl]="subtaskForm"
                    [isSubtask]="true"
                  ></ease-task-edit-form>
                </div>
              </div>
            </ng-template>
          </ease-sheet-expand>
        </ease-sheet-group>
      </ng-template>
    </div>
  </div>

  <ng-container *ngIf="enableScheduleToggle$ | async">
    <ease-sheet-divider></ease-sheet-divider>
    <div [formGroup]="taskEditForm">
      <div class="form-group-sm" *ngIf="!isPreScheduled">
        <mat-slide-toggle formControlName="scheduled"
          >Enable Schedule</mat-slide-toggle
        >
      </div>
      <div
        [formGroup]="rruleForm"
        class="flex flex-col gap-4 md:flex-row"
        [ngClass]="{ hidden: !taskEditForm.controls.scheduled.value }"
      >
        <div *ngIf="!isTemplate" class="form-group-xs flex-1">
          <ease-datepicker
            [min]="now"
            formControlName="dtstart"
            [placeholder]="
              isPreScheduled ? 'Next Creation Date' : 'First Creation Date'
            "
          ></ease-datepicker>
        </div>
        <div class="form-group-xs flex-1">
          <mat-form-field>
            <mat-select formControlName="interval" placeholder="Repeat">
              <mat-option
                *ngFor="let frequency of scheduledFrequencies"
                [value]="frequency.value"
                >{{ frequency.text }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </ng-container>
</div>
