import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  selector: 'ease-header-custom-info-cell',
  templateUrl: './header-custom-info-cell.component.html',
  styleUrls: ['./header-custom-info-cell.component.scss']
})
export class HeaderCustomInfoCellComponent {
  public params: IHeaderParams & {
    mobileHeaderName?: string;
    tooltipDescription?: string;
  };

  @ViewChild('menuButton', { read: ElementRef })
  public menuButton: ElementRef;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onSortRequested(event: MouseEvent) {
    this.params.progressSort();
  }
}
