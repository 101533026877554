import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ReactiveFormsModule } from '@angular/forms';
import { JobPanelComponent } from '../job-panel/job-panel.component';
import { SharedModule } from '../../shared/shared.module';
import { SharedPipesModule } from '../../shared/shared-pipes.module';
import { JobStatusIconComponent } from '../job-status-icon/job-status-icon.component';
import { JobPanelStepComponent } from '../job-panel-step/job-panel-step.component';
import { JobsDialogComponent } from './jobs-dialog.component';

@NgModule({
  imports: [RouterModule, SharedModule, ReactiveFormsModule, SharedPipesModule],
  declarations: [
    JobsDialogComponent,
    JobPanelComponent,
    JobStatusIconComponent,
    JobPanelStepComponent
  ],
  exports: [JobPanelComponent]
})
export class JobsDialogModule {}
