import { NgModule } from '@angular/core';

import { ChecklistTemplatesPopoverModule } from 'src/app/shared/popover/checklist-templates/checklist-templates-popover.module';
import { CompleteTaskPopoverModule } from 'src/app/shared/popover/complete-task/complete-task-popover.module';
import { SelectUserPopoverModule } from 'src/app/shared/popover/select-user/select-user-popover.module';
import { TaskMovePopoverModule } from 'src/app/shared/popover/task-move/task-move-popover.module';
import { SharedPipesModule } from 'src/app/shared/shared-pipes.module';
import { TaskWindowerModule } from 'src/app/shared/window/window-pane-view-task/task-windower.module';
import { CustomerDashboardWidgetAccountsDashboardSharedTableModule } from '../../customers/customer-dashboard/customer-dashboard-widget/customer-dashboard-widget-accounts-dashboard/customer-dashboard-widget-accounts-dashboard-shared-table/customer-dashboard-widget-accounts-dashboard-shared-table.module';
import { EntityIconModule } from '../../shared/entity-icon/entity-icon.module';
import { FinderModule } from '../../shared/finder/finder.module';
import { CustomerTasksPopoverModule } from '../../shared/popover/customer-tasks-popover/customer-tasks-popover.module';
import { CustomerWebsitesPopoverModule } from '../../shared/popover/customer-websites-popover/customer-websites-popover.module';
import { TaskScheduledDatesPopoverModule } from '../../shared/popover/task-scheduled-dates/task-scheduled-dates-popover.module';
import { SharedFormModule } from '../../shared/shared-form.module';
import { SharedModule } from '../../shared/shared.module';
import { TaskAssignedUsersModule } from '../task-assigned-users/task-assigned-users.module';
import { TaskBodyModule } from '../task-body/task-body.module';
import { TaskModulesModule } from '../task-modules/task-modules.module';
import { TaskPhaseModule } from '../task-phase/task-phase.module';
import { TaskStatusModule } from '../task-status/task-status.module';

import { TaskPriorityPopoverModule } from '../../shared/popover/task-priority/task-priority-popover.module';
import { TaskProjectModule } from '../task-project/task-project.module';
import { TaskSummaryModule } from '../task-summary/task-summary.module';
import { TaskBoardListModule } from '../task-board-list/task-board-list.module';
import { TaskViewRendererComponent } from './task-view-renderer.component';
import { TaskViewComponent } from './task-view.component';

@NgModule({
  imports: [
    ChecklistTemplatesPopoverModule,
    CompleteTaskPopoverModule,
    CustomerDashboardWidgetAccountsDashboardSharedTableModule,
    CustomerTasksPopoverModule,
    CustomerWebsitesPopoverModule,
    EntityIconModule,
    FinderModule,
    SelectUserPopoverModule,
    SharedFormModule,
    SharedModule,
    SharedPipesModule,
    TaskAssignedUsersModule,
    TaskBodyModule,
    TaskModulesModule,
    TaskMovePopoverModule,
    TaskPriorityPopoverModule,
    TaskPhaseModule,
    TaskScheduledDatesPopoverModule,
    TaskStatusModule,
    TaskProjectModule,
    TaskSummaryModule,
    TaskWindowerModule,
    TaskBoardListModule,
    TaskProjectModule
  ],
  declarations: [TaskViewComponent, TaskViewRendererComponent],
  exports: [TaskViewComponent]
})
export class TaskViewModule {}
