import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';

import { ReactiveFormsModule } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { SharedModule } from '../shared.module';
import { MetricsModule } from '../metrics/metrics.module';
import { FormErrorsModule } from '../form-errors/form-errors.module';
import { EmailsModule } from '../emails/emails.module';
import { AccountIconModule } from '../account-icon/account-icon.module';
import { WindowCloseModule } from '../window/window-close/window-close.module';
import { PlanFrequencyPipeModule } from '../../customers/customer-billing/plan-frequency-pipe.module';
import { AddFundingComponent } from './add-funding/add-funding.component';
import { FundingManagerComponent } from './funding-manager/funding-manager.component';
import { FundingTotalsComponent } from './funding-totals/funding-totals.component';

@NgModule({
  declarations: [
    AddFundingComponent,
    FundingManagerComponent,
    FundingTotalsComponent
  ],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    MetricsModule,
    FormErrorsModule,
    NgxMaskModule.forRoot(),
    EmailsModule,
    AccountIconModule,
    WindowCloseModule,
    PlanFrequencyPipeModule
  ],
  exports: [AddFundingComponent],
  providers: [CurrencyPipe]
})
export class FundingModule {}
