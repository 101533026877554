import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';

import { CustomerMessageGroup } from '../../customer-messages.interface';

@Component({
  selector: 'ease-customer-message-groups-list',
  templateUrl: './customer-message-groups-list.component.html',
  styleUrls: ['./customer-message-groups-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerMessageGroupsListComponent implements OnInit {
  @Input() groups: CustomerMessageGroup[];
  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;

  constructor() {}

  ngOnInit() {}

  scrollToExpanded(group: CustomerMessageGroup): void {
    setTimeout(
      () => this.virtualScroller && this.virtualScroller.scrollInto(group)
    );
  }

  refreshVirtualScrollerView(): void {
    setTimeout(() => this.virtualScroller && this.virtualScroller.refresh());
  }
}
