import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ListGroupModule } from '../list-group/list-group.module';
import { SheetsModule } from '../sheet/sheets.module';
import { SkSelectComponent } from './sk-select.component';

@NgModule({
  imports: [CommonModule, ListGroupModule, ReactiveFormsModule, SheetsModule],
  declarations: [SkSelectComponent],
  exports: [SkSelectComponent]
})
export class SkSelectModule {}
