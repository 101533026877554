<div class="flex flex-row">
  <div class="flex max-w-[40%] flex-full flex-row items-center justify-start">
    <mat-icon class="mat-icon-18 mr-3">block</mat-icon>
    <strong class="w-20 md:w-auto">Negative Keyword</strong>
  </div>
  <div class="flex max-w-[60%] flex-full flex-row items-center justify-start">
    <mat-icon class="mat-icon-20 mr-2">error</mat-icon>
    <strong>Conflicts</strong>
  </div>
</div>
<ng-container *ngIf="pendingOperations$ | async as pendingOperations">
  <div *ngFor="let item of items" class="mt-5 flex flex-row">
    <div class="max-w-[40%] flex-full">
      <div class="-ml-2 flex flex-row items-center justify-start">
        <div
          class="flex-none"
          [matTooltip]="
            item.value.removed ? 'Removed' : 'Remove Negative Keyword'
          "
        >
          <ng-container
            *ngIf="!pendingOperations[item.value.negativeId]; else pauseLoading"
          >
            <button
              mat-icon-button
              *ngIf="!task.completedAt"
              [disabled]="
                item.value.removed || pendingOperations[item.value.negativeId]
              "
              (click)="removeKeyword(item.value)"
            >
              <mat-icon class="mat-icon-20">{{
                item.value.removed ? 'done' : 'delete'
              }}</mat-icon>
            </button>
          </ng-container>
        </div>
        <div>
          <div
            class="font-bold"
            [ngClass]="
              pendingOperations[item.value.negativeId] || item.value.removed
                ? 'text-gray-400'
                : 'text-red-600'
            "
          >
            {{ item.value.negative }}
          </div>
          <ng-container [ngSwitch]="item.value.level">
            <div class="text-sm text-gray-400" *ngSwitchCase="'SHARED_LIST'">
              {{ item.value.listName }}
              <mat-icon
                class="mat-icon-14"
                matTooltip="Shared Negative Keyword List"
                >help</mat-icon
              >
            </div>
            <div class="text-sm text-gray-400" *ngSwitchCase="'CAMPAIGN'">
              {{ item.value.campaignName }}
              <mat-icon
                class="mat-icon-14"
                matTooltip="Campaign Negative Keyword"
                >help</mat-icon
              >
            </div>
            <div class="text-sm text-gray-400" *ngSwitchCase="'ADGROUP'">
              {{ item.value.adGroupName }}
              <mat-icon
                class="mat-icon-14"
                matTooltip="Ad Group Negative Keyword"
                >help</mat-icon
              >
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="max-w-[60%] flex-full">
      <div
        class="mb-2"
        *ngFor="
          let conflict of item.value.conflicts
            | limit: getKeywordLimit(item.value.negativeId)
        "
      >
        <div class="flex flex-col text-sm md:flex-row md:items-center">
          <strong class="mr-1.5 text-xs uppercase text-gray-400"
            >Campaign</strong
          >
          {{ conflict.campaignName }}
        </div>
        <div
          class="mt-1 flex flex-col text-sm md:mt-0 md:flex-row md:items-center"
        >
          <strong class="mr-2.5 text-xs uppercase text-gray-400"
            >Ad Group</strong
          >
          <div class="-mt-2.5 flex items-center md:mt-0">
            {{ conflict.adGroupName }}
            <ng-container
              *ngIf="
                !pendingOperations[
                  conflict.negativeId + ':' + conflict.adGroupId
                ];
                else pauseLoading
              "
            >
              <button mat-icon-button *ngIf="!conflict.paused">
                <mat-icon
                  class="mat-icon-18"
                  matTooltip="Pause conflicting keywords"
                  (click)="
                    pauseKeyword(
                      conflict.negativeId,
                      conflict.positives,
                      conflict.adGroupId
                    )
                  "
                  >pause</mat-icon
                >
              </button>
            </ng-container>
          </div>
        </div>
        <div class="mt-1 mb-5">
          <div
            class="mb-1.5 mr-1.5 inline-block rounded-full bg-gray-200 py-1 px-1.5 text-xs"
            [ngClass]="!conflict.paused ? 'text-gray-700' : 'text-gray-400'"
            *ngFor="
              let positive of conflict.positives
                | limit: getKeywordLimit(item.value.negativeId)
            "
          >
            {{ positive.display }}
          </div>
          <button
            (click)="setKeywordLimit(item.value.negativeId)"
            *ngIf="conflict.positives.length > 5"
            class="ml-2 text-xs font-bold text-blue-400"
          >
            <div *ngIf="!keywordShowing[item.value.negativeId]">
              Show {{ conflict.positives.length - 5 }} more
            </div>

            <div *ngIf="keywordShowing[item.value.negativeId]">Show Less</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #pauseLoading>
  <mat-progress-spinner
    class="m-2.5"
    [diameter]="20"
    color="primary"
    mode="indeterminate"
  >
  </mat-progress-spinner>
</ng-template>
