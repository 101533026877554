import firebase from 'firebase/compat/app';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListDisplayItem } from 'src/app/shared/shared.interface';
import {
  FirebaseDbService,
  ListAsObject
} from '../../../shared/firebase-db.service';
import { CHECKLIST_TEMPLATES_PATH } from '../../../shared/firebase-paths';
import { UserService } from '../../../users/user.service';
import { MappedCache } from '../../../shared/mapped-cache';
import { firebaseJSON } from '../../../shared/utils/functions';
import {
  TaskChecklistTemplate,
  TaskChecklistTemplateField,
  TaskChecklistTemplateFieldType
} from './task-checklist.interface';

interface ChecklistGetterOptions {
  includeHidden?: boolean;
  includeAllRegions?: boolean;
  hiddenOnly?: boolean;
}

@Injectable({ providedIn: 'root' })
export class TaskChecklistTemplateService {
  public taskChecklistTemplateObjectGetter$: Observable<
    ListAsObject<TaskChecklistTemplate>
  > = this.angularFire.getObject(`/${CHECKLIST_TEMPLATES_PATH}`).pipe(
    map(checklists => (checklists.$exists() ? checklists : {})),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  private checklistsById = new MappedCache<TaskChecklistTemplate>();
  public checklistTypeNames: Record<TaskChecklistTemplateFieldType, string> = {
    actionButton: 'Action Button',
    checklistCheckbox: 'Checkbox',
    checkboxList: 'Checkbox List',
    checkboxInput: 'Checkbox with input',
    checkboxRadio: 'Checkbox with Radio buttons',
    inputHorizontal: 'Text Field',
    sectionHeading: 'Text Header',
    sectionHeadingDivider: 'Text Header Divider',
    toggle: 'Toggle'
  };

  constructor(
    private angularFire: FirebaseDbService,
    private userService: UserService
  ) {}

  getAll(
    options?: ChecklistGetterOptions
  ): Observable<TaskChecklistTemplate[]> {
    return this.angularFire.getList(`/${CHECKLIST_TEMPLATES_PATH}`).pipe(
      switchMap((templates: TaskChecklistTemplate[]) =>
        this.userService.currentUserRegion.pipe(
          map(userRegion =>
            templates.filter(template => {
              const includeRegion = !options?.includeAllRegions
                ? template.group === userRegion || !template.group
                : true;
              const includeHidden = !options?.includeHidden
                ? !template.hidden
                : true;
              const hiddenOnly = options?.hiddenOnly && template.hidden;

              if (hiddenOnly) {
                return includeRegion;
              } else {
                return includeRegion && includeHidden;
              }
            })
          )
        )
      ),
      map(templates => orderBy(templates, ['name'])),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  getAllForSelect(
    options?: ChecklistGetterOptions
  ): Observable<ListDisplayItem[]> {
    return this.getAll(options).pipe(
      map(checklists =>
        checklists.map(checklist => ({
          viewValue: checklist.name,
          viewSubvalue: checklist?.description,
          value: checklist.$key
        }))
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  get(id: string): Observable<TaskChecklistTemplate> {
    return this.checklistsById.get(
      id,
      this.angularFire.getObject(`/${CHECKLIST_TEMPLATES_PATH}/${id}`).pipe(
        map(template => {
          const fields = template.fields
            ? Object.keys(template.fields).map(fieldId => {
                const field = template.fields[fieldId];
                field.$dbKey = fieldId;

                return field;
              })
            : [];

          return {
            $key: template.$exists() ? id : null,
            name: template.name,
            description: template.description || null,
            createdAt: template.createdAt || null,
            createdBy: template.createdBy || null,
            updatedAt: template.updatedAt || null,
            updatedBy: template.updatedBy || null,
            fields: orderBy(fields, 'order'),
            group: template.group || null,
            hidden: template.hidden || null
          };
        })
      )
    );
  }

  async createNewChecklist(
    checklistTemplate: TaskChecklistTemplate
  ): Promise<firebase.database.ThenableReference> {
    const createdAt = await this.angularFire.getServerTimestamp();

    return this.angularFire.list(`/${CHECKLIST_TEMPLATES_PATH}`).push(
      Object.assign(checklistTemplate, {
        createdAt,
        createdBy: this.userService.currentUser.name
      })
    );
  }

  async saveChecklist(checklistTemplate: TaskChecklistTemplate): Promise<void> {
    const updatedAt = await this.angularFire.getServerTimestamp();
    const fields: {
      [key: string]: TaskChecklistTemplateField;
    } = checklistTemplate?.fields?.reduce((acc, field) => {
      const key = field?.$dbKey || firebase.database().ref().push().key;
      return {
        ...acc,
        [key]: firebaseJSON(field)
      };
    }, {});

    await this.angularFire
      .object(`/${CHECKLIST_TEMPLATES_PATH}/${checklistTemplate.$key}`)
      .set({
        name: checklistTemplate.name,
        description: checklistTemplate.description || null,
        group: checklistTemplate.group || null,
        hidden: checklistTemplate.hidden || null,
        fields,
        updatedAt,
        updatedBy: this.userService.currentUser.name
      });
  }

  deleteChecklistTemplate(checklistTemplateId: string) {
    return this.angularFire
      .object(`/${CHECKLIST_TEMPLATES_PATH}/${checklistTemplateId}`)
      .remove();
  }
}
