import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  ContentChild,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  SkipSelf
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SharedLayoutService } from '../shared-layout.service';
import { DrawerSidebarService } from './drawer-sidebar.service';

@UntilDestroy()
@Component({
  selector: 'ease-drawer-sidebar',
  templateUrl: './drawer-sidebar.component.html',
  styleUrls: ['./drawer-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerSidebarComponent implements AfterViewInit {
  @HostBinding('style.--expanded-drawer-width') expandedWidth;
  @HostBinding('style.--collapsed-drawer-width') collapsedWidth;
  @ContentChild(MatDrawer) drawer: MatDrawer;
  @ContentChild(MatDrawer, { read: ElementRef }) drawerElement: ElementRef<any>;

  constructor(
    @SkipSelf() private cdr: ChangeDetectorRef,
    private drawerSidebarService: DrawerSidebarService,
    private sharedLayoutService: SharedLayoutService
  ) {}

  ngAfterViewInit(): void {
    this.drawer.mode = 'side';
    this.drawer.disableClose = true;
    this.drawer.opened = true;
    this.drawerElement.nativeElement.className += ' !block';

    // set the drawer's expanded width dynamically
    this.drawerSidebarService
      .getResizeObserver('drawer', this.drawerElement)
      .pipe(untilDestroyed(this))
      .subscribe();

    // subscribe and apply the changes on the drawer widths
    this.drawerSidebarService
      .getWidth()
      .pipe(untilDestroyed(this))
      .subscribe(widths => {
        const { expanded, collapsed } = widths;
        this.expandedWidth = expanded;
        this.collapsedWidth = collapsed;
        this.cdr.detectChanges();
      });

    // set the expanded width to 100% on mobile
    this.sharedLayoutService.isCompact$
      .pipe(untilDestroyed(this))
      .subscribe(isMobile => {
        if (isMobile.active) {
          this.drawerElement.nativeElement.className += ' w-full';
          this.drawerSidebarService.setWidth({ expanded: '100%' });
          this.drawer.close();
        } else {
          const currentClasses = this.drawerElement.nativeElement.className;
          this.drawerElement.nativeElement.className = currentClasses.replace(
            ' w-full',
            ''
          );
        }
      });
  }
}
