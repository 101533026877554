<ng-container *ngIf="breakdownData; else loading">
  <mat-table [dataSource]="breakdownData">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let item">{{ item.name }}</mat-cell>
      <mat-footer-cell *matFooterCellDef>Grand Total</mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="value">
      <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
      <mat-cell
        *matCellDef="let item"
        [ngClass]="{ positive: item.value > 0 }"
        >{{ item.value | currency }}</mat-cell
      >
      <mat-footer-cell *matFooterCellDef>{{
        getTotalCost() | currency
      }}</mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
  </mat-table>
</ng-container>

<ng-template #loading>
  <ease-state-loading></ease-state-loading>
</ng-template>
