<ng-container *ngIf="filteredOptions$ | async as filteredOptions; else loading">
  <mat-form-field>
    <mat-icon *ngIf="prefixIcon" matPrefix class="mat-icon-18">{{
      prefixIcon
    }}</mat-icon>

    <input
      type="text"
      matInput
      #searchInput
      [placeholder]="placeholder"
      [formControl]="control"
      [matAutocomplete]="auto"
      (blur)="onBlur(filteredOptions)"
    />
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error
      easeFormErrors
      [errorMessages]="!control?.errors?.required ? control.errors : {}"
      [control]="control"
    ></mat-error>

    <mat-autocomplete
      #auto="matAutocomplete"
      [autoActiveFirstOption]="true"
      [displayWith]="options | advancedAutocompleteDisplayFn: grouped"
      (optionSelected)="setSelected($event.option.value)"
      (opened)="panelOpened()"
      [ngSwitch]="grouped"
    >
      <div class="flex h-full flex-col">
        <div class="h-full overflow-y-auto overflow-x-hidden">
          <virtual-scroller
            #scroll
            [items]="filteredOptions"
            [enableUnequalChildrenSizes]="true"
          >
            <mat-option disabled *ngIf="!options.length">
              <div class="text-sm">No option available</div>
            </mat-option>

            <ng-container *ngSwitchCase="true">
              <mat-optgroup
                *ngFor="let group of scroll.viewPortItems"
                [label]="group.name"
              >
                <mat-option
                  *ngFor="let option of group.items"
                  [value]="option.value"
                >
                  <ng-container
                    [ngTemplateOutlet]="optionView"
                    [ngTemplateOutletContext]="{
                      $implicit: option
                    }"
                  ></ng-container>
                </mat-option>
              </mat-optgroup>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <mat-option
                *ngFor="let option of scroll.viewPortItems"
                [value]="option.value"
              >
                <ng-container
                  [ngTemplateOutlet]="optionView"
                  [ngTemplateOutletContext]="{
                    $implicit: option
                  }"
                ></ng-container>
              </mat-option>
            </ng-container>
          </virtual-scroller>
        </div>

        <div
          *ngIf="panelFooter"
          class="border-t border-solid border-gray-300 bg-white px-4 py-2"
        >
          <ng-template [ngTemplateOutlet]="panelFooter"></ng-template>
        </div>
      </div>
    </mat-autocomplete>

    <button
      *ngIf="control.value"
      type="button"
      matTooltip="Clear"
      mat-icon-button
      matSuffix
      (click)="clearSelected()"
    >
      <mat-icon class="mat-icon-18 !top-0 !m-auto">close</mat-icon>
    </button>
  </mat-form-field>
</ng-container>

<ng-template #loading>
  <div class="flex h-[70px] flex-1 items-center justify-center">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>

<ng-template #optionView let-option>
  <div class="text-sm leading-6">{{ option.viewValue }}</div>
  <div *ngIf="option?.viewSubvalue" class="text-xs text-gray-600">
    {{ option.viewSubvalue }}
  </div>
</ng-template>
