<div
  *ngIf="editor"
  class="tiptap-menu justify-left flex min-h-[44px] flex-1 flex-row items-center overflow-hidden overflow-x-auto border-b border-solid border-gray-300 leading-none text-gray-600"
  [ngClass]="{
    '!hidden group-focus-within:!flex': autoCollapse
  }"
>
  <!-- LINK MENU -->
  <ng-container *ngIf="showLinkMenu">
    <mat-form-field appearance="outline" color="primary" class="flex-1 text-sm">
      <input
        [formControl]="linkUrl"
        placeholder="Enter valid URL"
        (keydown)="onLinkKeyDown($event)"
        class="placeholder-gray-500 placeholder-opacity-80"
        matInput
        #linkField
      />
      <mat-icon color="primary" matPrefix>insert_link</mat-icon>
      <button
        *ngIf="linkUrl.value"
        (click)="setLink(linkUrl.value)"
        matSuffix
        mat-icon-button
      >
        <mat-icon>done</mat-icon>
      </button>
      <button (click)="toggleLink()" matSuffix mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <!-- MARK MENU -->
  <ng-container *ngIf="showMarkMenu">
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleHeading({ level: 3 }),
          tooltip: 'Toggle title',
          icon: 'text_fields',
          ngClass: {
            '!text-blue-500': editor.isActive('heading', { level: 3 })
          }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().focus().toggleBulletList(),
          tooltip: 'Toggle bulleted list',
          icon: 'format_list_bulleted',
          ngClass: { '!text-blue-500': editor.isActive('bulletList') }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().focus().toggleOrderedList(),
          tooltip: 'Toggle ordered list',
          icon: 'format_list_numbered',
          ngClass: { '!text-blue-500': editor.isActive('orderedList') }
        }
      }"
    ></ng-container>
    <mat-divider
      [vertical]="true"
      class="ml-2 mr-2 h-4 border-gray-300"
    ></mat-divider>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleBold(),
          tooltip: 'Bold ( ' + platformModifier + ' B )',
          icon: 'format_bold',
          ngClass: { '!text-blue-500': editor.isActive('bold') }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleItalic(),
          tooltip: 'Italic ( ' + platformModifier + ' I )',
          icon: 'format_italic',
          ngClass: { '!text-blue-500': editor.isActive('italic') }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleUnderline(),
          tooltip: 'Underline ( ' + platformModifier + ' U )',
          icon: 'format_underlined',
          ngClass: { '!text-blue-500': editor.isActive('underline') }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleStrike(),
          tooltip: 'Strikethrough ( ' + platformModifier + ' ⇧ X )',
          icon: 'strikethrough_s',
          ngClass: { '!text-blue-500': editor.isActive('strike') }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleHighlight(),
          tooltip: 'Highlight ( ' + platformModifier + ' ⇧ H )',
          icon: 'highlight',
          ngClass: { '!text-blue-500': editor.isActive('highlight') }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleCode(),
          tooltip: 'Inline code',
          icon: 'code',
          ngClass: { '!text-blue-500': editor.isActive('code') }
        }
      }"
    ></ng-container>
    <div (click)="toggleLink()">
      <ng-container
        [ngTemplateOutlet]="menuButton"
        [ngTemplateOutletContext]="{
          $implicit: {
            tooltip: 'Toggle link',
            icon: 'insert_link',
            ngClass: { '!text-blue-500': editor.isActive('link') }
          }
        }"
      ></ng-container>
    </div>
  </ng-container>

  <!-- TABLE MENU -->
  <ng-container *ngIf="showTableMenu">
    <mat-icon class="mat-icon-20 ml-3 mr-2">add</mat-icon>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().addRowAfter(),
          tooltip: 'Add row below',
          label: 'Row'
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().addColumnAfter(),
          tooltip: 'Add column to the right',
          label: 'Col'
        }
      }"
    ></ng-container>
    <mat-divider
      [vertical]="true"
      class="ml-2 mr-2 h-4 border-gray-300"
    ></mat-divider>
    <mat-icon class="mat-icon-20 ml-2 mr-2">delete_outline</mat-icon>
    <!-- 
      We intentionally hide this action for table headers since removing such will cause issues with
      the HTML-markdown conversions. The following condition checks the currently selected node at the
      given depth. Since we don't allow nested tables, then `tableHeader` would only appear on the
      "depth 3". With the earlier depths as, 0 - doc, 1 - table, 2 - tableRow.
    -->
    <ng-container
      *ngIf="
        editor.state.selection.$anchor.node(3)?.type.name !== 'tableHeader'
      "
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().deleteRow(),
          tooltip: 'Delete row',
          label: 'Row'
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().deleteColumn(),
          tooltip: 'Delete column',
          label: 'Col'
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().deleteTable(),
          tooltip: 'Delete Table',
          label: 'Table',
          ngClass: { '!w-11': true }
        }
      }"
    ></ng-container>
  </ng-container>

  <!-- BLOCK MENU -->
  <ng-container *ngIf="!showTableMenu && !showMarkMenu && !showLinkMenu">
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleHeading({ level: 3 }),
          tooltip: 'Insert title',
          icon: 'text_fields',
          ngClass: {
            '!text-blue-500': editor.isActive('heading', { level: 3 })
          }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleBulletList(),
          tooltip: 'Insert bulleted list',
          icon: 'format_list_bulleted',
          ngClass: { '!text-blue-500': editor.isActive('bulletList') }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleOrderedList(),
          tooltip: 'Insert ordered list',
          icon: 'format_list_numbered',
          ngClass: { '!text-blue-500': editor.isActive('orderedList') }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleBlockquote(),
          tooltip: 'Insert quote',
          icon: 'format_quote',
          ngClass: { '!text-blue-500': editor.isActive('blockquote') }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().toggleCodeBlock(),
          tooltip: 'Insert code block',
          icon: 'code',
          ngClass: { '!text-blue-500': editor.isActive('codeBlock') }
        }
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="menuButton"
      [ngTemplateOutletContext]="{
        $implicit: {
          command: editor.chain().setHorizontalRule(),
          tooltip: 'Insert horizontal line',
          icon: 'horizontal_rule'
        }
      }"
    ></ng-container>
    <!-- Avoid nesting tables inside another node. This complicates the check for `tableHeader`s -->
    <div
      *ngIf="!editor.isActive('blockquote')"
      (click)="editor.chain().focus().insertTable().run()"
    >
      <ng-container
        [ngTemplateOutlet]="menuButton"
        [ngTemplateOutletContext]="{
          $implicit: {
            tooltip: 'Insert table',
            icon: 'grid_on'
          }
        }"
      ></ng-container>
    </div>
    <div *ngIf="variables.length">
      <ng-container
        [ngTemplateOutlet]="menuButton"
        [ngTemplateOutletContext]="{
          $implicit: {
            tooltip:
              'Variables are enabled on this editor. You can start adding variables by typing { and picking one from the suggested list.',
            icon: 'bolt',
            ngClass: { '!text-blue-500': true }
          }
        }"
      ></ng-container>
    </div>
  </ng-container>
</div>
<div
  #tiptap
  class="tiptap flex max-h-screen-half"
  [ngClass]="{
    'min-h-10 h-10 group-focus-within:h-auto group-focus-within:min-h-24':
      autoCollapse,
    'min-h-24': !autoCollapse
  }"
  cdkFocusRegionStart
></div>

<div #mentions>
  <div
    class="min-w-max rounded-md !bg-blue-gray-900"
    *ngIf="suggestionProps?.items"
    layout="column"
  >
    <button
      *ngFor="let item of suggestionProps.items; index as i"
      (click)="selectItem(i)"
      mat-button
      class="item !flex w-full flex-row items-center justify-start !p-2 !py-1.5 !pr-4 !text-gray-300"
      (mouseenter)="selectedIndex = i"
    >
      <ng-container *ngIf="!item?.users">
        <ease-avatar
          class="mr-2"
          [userId]="item.$key"
          [status]="{
            mode: item?.mode,
            borderColor: 'border-blue-gray-900'
          }"
        ></ease-avatar>
        <span [ngClass]="{ '!text-blue-500': selectedIndex === i }">
          {{ item.name }}
        </span>
      </ng-container>

      <ng-container *ngIf="item?.users">
        <div
          class="relative mr-2 flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border border-gray-700"
        >
          <div
            class="absolute z-1 flex h-full w-full items-center justify-center bg-black bg-opacity-70"
          >
            +{{ item.users.length }}
          </div>

          <ease-avatar [userId]="item.users[0]"></ease-avatar>
        </div>
        <span [ngClass]="{ '!text-blue-500': selectedIndex === i }">
          {{ item.name }}
        </span>
      </ng-container>
    </button>
  </div>
</div>

<div #variablesTemplate>
  <div
    class="min-w-max rounded-md bg-blue-gray-900"
    *ngIf="variableProps?.items"
    layout="column"
  >
    <button
      *ngFor="let item of variableProps.items; index as i"
      (click)="selectItem(i, 'variable')"
      mat-button
      class="item !flex w-full flex-row items-center justify-start !p-2 !py-1.5 !pr-4 !text-gray-300"
      (mouseenter)="selectedIndex = i"
    >
      <span [ngClass]="{ '!text-blue-500': selectedIndex === i }">
        {{ item }}
      </span>
    </button>
  </div>
</div>

<ng-template #menuButton let-item>
  <button
    (click)="item.command ? menuCommand(item.command) : null"
    [matTooltip]="item.tooltip"
    matTooltipPosition="above"
    mat-button
    [class.mat-icon-button]="item.icon"
    class="!h-9 !w-9 !min-w-0 !rounded-none !p-0 hover:bg-gray-200"
    [ngClass]="item.ngClass ? item.ngClass : {}"
    type="button"
  >
    <ng-container *ngIf="item.icon">
      <mat-icon class="mat-icon-20">{{ item.icon }}</mat-icon>
    </ng-container>
    <ng-container *ngIf="item.label">{{ item.label }}</ng-container>
  </button>
</ng-template>
