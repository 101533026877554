import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';

import { SummaryComponent } from './summary.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SummaryComponent],
  exports: [SummaryComponent]
})
export class SummaryModule {}
