import { of } from 'rxjs';

import { map, subscribeOn, take } from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { asyncScheduler } from 'rxjs';
import { SatPopover } from '@ncstate/sat-popover';
import { asyncValidatorFactory } from '../../shared-validators';

import { COLOR_CHOICES } from '../../constants';
import { TaskStatus } from '../../../tasks/task-status/task-status.interface';
import { TaskStatusSelectorComponent } from '../../../tasks/task-status/task-status-selector/task-status-selector.component';
import { TaskStatusService } from '../../../tasks/task-status/task-status.service';
import { AdminPermissionsService } from '../../../admin/admin-roles/admin-permissions.service';
import { TypedFormControl, TypedFormGroup } from '../../reactive-forms';

@Component({
  selector: 'ease-task-status-popover',
  templateUrl: './task-status-popover.component.html',
  styleUrls: ['./task-status-popover.component.scss']
})
export class TaskStatusPopoverComponent implements OnInit {
  @Input()
  selectedStatus: string[] = [];
  @Output()
  selected: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('statusSelector')
  statusSelector: TaskStatusSelectorComponent;
  public mode: string = 'init';
  public colorChoices: string[] = COLOR_CHOICES;
  public statusForm: TypedFormGroup<TaskStatus>;
  private cachedStatus: TaskStatus;

  constructor(
    private formBuilder: FormBuilder,
    @Optional() private parentPopover: SatPopover,
    private taskStatusService: TaskStatusService,
    public permissionsService: AdminPermissionsService
  ) {}

  ngOnInit() {
    this.statusForm = this.formBuilder.group<TypedFormControl<TaskStatus>>({
      $key: this.formBuilder.control(null),
      name: this.formBuilder.control(null, {
        validators: Validators.required,
        asyncValidators: [
          asyncValidatorFactory(newName => {
            /**
             * If we are editing an existing status, check whether the new name is different than the existing one.
             * Only run the async name validator if the name has changed, otherwise return null (no errors for this field).
             */
            const key = this.statusForm.controls.$key.value;

            if (!key || (key && newName !== this.cachedStatus.name)) {
              return this.taskStatusService.validateName(newName).pipe(
                map((valid: boolean) =>
                  valid
                    ? null
                    : {
                        nameExists: 'A status with this name already exists'
                      }
                )
              );
            } else {
              return of(null);
            }
          }),
          asyncValidatorFactory(() =>
            of(null).pipe(subscribeOn(asyncScheduler))
          )
        ]
      }),
      color: this.formBuilder.control(null, { validators: Validators.required })
    });
  }

  switchMode(mode: string) {
    this.mode = mode;
  }

  createStatus() {
    if (this.statusForm.valid) {
      /**
       * If editing an existing status, call update instead of create.
       */
      const key = this.statusForm.controls.$key.value;

      if (key) {
        this.taskStatusService.update(key, this.statusForm.value).then(() => {
          this.resetView();
        });
      } else {
        this.taskStatusService.create(this.statusForm.value).then(() => {
          this.resetView();
        });
      }
    }
  }

  editStatus(statusId: string) {
    this.switchMode('create');
    this.taskStatusService
      .get(statusId)
      .pipe(take(1))
      .subscribe(status => {
        this.cachedStatus = Object.assign({}, status, { $key: status.$key });
        this.statusForm.patchValue(this.cachedStatus);
      });
  }

  setStatusColor(color: string) {
    this.statusForm.controls.color.setValue(color);
  }

  onSelect(statusId: string) {
    this.selected.emit(statusId);
    this.parentPopover && this.parentPopover.close();
  }

  resetView() {
    this.switchMode('init');
    this.statusForm.reset();
    setTimeout(() => this.statusSelector.focusInput());
  }
}
