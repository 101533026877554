<ease-sheet class="popover">
  <ease-popover-header
    [ngClass]="{
      red__header: redHeader
    }"
    >Scheduled Dates
  </ease-popover-header>

  <ease-sheet-content>
    <ease-list-group>
      <ng-container *ngIf="hasEmptyRRule">
        <ease-list-group-item class="text-sm text-gray-400"
          >This scheduled task has already ended.</ease-list-group-item
        >
      </ng-container>

      <ease-list-group-item
        *ngFor="let scheduledDate of scheduledDates"
        class="flex flex-row items-center justify-start"
        [ngClass]="{
          closest: scheduledDate.isNext,
          'text-gray-400': !scheduledDate.isScheduled
        }"
      >
        <div class="max-w-[70%] flex-full">
          <mat-icon class="mat-icon-16">
            {{ scheduledDate.isScheduled ? 'date_range' : 'check' }}
          </mat-icon>
          {{ scheduledDate.date | amDateFormat: 'MMM D, YYYY' }}
        </div>
        <div class="max-w-[30%] flex-full text-sm">
          {{ scheduledDate.isScheduled ? 'Upcoming' : 'Triggered' }}
        </div>
      </ease-list-group-item>
    </ease-list-group>
  </ease-sheet-content>
</ease-sheet>
