import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs';
import { BoardModel } from 'src/app/boards/board.model';
import { BoardService } from '../../../boards/board.service';
import { ListModel } from '../../../boards/list.model';
import { ListService } from '../../../boards/list.service';
import { WindowPane } from '../../../shared/window/window-pane/window-pane';

@Component({
  selector: 'ease-task-board-list-blob',
  templateUrl: './task-board-list-blob.component.html',
  styleUrls: ['./task-board-list-blob.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBoardListBlobComponent implements OnInit {
  @Input() set boardId(id: string) {
    this.boardSource.next(id);
  }
  @Input() set listId(id: string) {
    this.listSource.next(id);
  }

  @Input() enableActions: boolean = false;

  private boardSource: ReplaySubject<string> = new ReplaySubject<string>();
  public board$: Observable<BoardModel> = this.boardSource
    .asObservable()
    .pipe(switchMap(boardId => this.boardService.get(boardId)));

  private listSource: ReplaySubject<string> = new ReplaySubject<string>();
  public list$: Observable<ListModel> = this.listSource
    .asObservable()
    .pipe(switchMap(listId => this.listService.get(listId)));

  constructor(
    private boardService: BoardService,
    private listService: ListService,
    private windowPane: WindowPane,
    private router: Router
  ) {}

  ngOnInit(): void {}
  /**
   * Redirects a user to the board's page
   * - if a list was provided, redirect user to the specific list page
   * - otherwise, redirect to the first list on the board provided
   *
   * @param board
   * @param list
   */
  goToBoard(board: BoardModel, list?: ListModel): void {
    this.windowPane.minimize();
    const listId: string = list ? list.$key : this.getFirstList(board);
    this.router.navigate(['/boards', board.$key, 'lists', listId]);
  }

  /**
   * Returns the ID of the first list on a board
   *
   * @param board
   * @returns
   */
  getFirstList(board: BoardModel): string {
    const [firstListId] = Object.keys(board.lists);
    return firstListId;
  }
}
