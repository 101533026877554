import { Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  CustomerAccountBudget,
  CustomerAccountCost,
  CustomerBudget
} from '../customer-account-budget.interface';
import { CustomerAccountRulesService } from '../../customer-account-rules.service';
import { Change } from '../../../../shared/shared.interface';

@Component({
  selector: 'ease-customer-account-budget-group',
  templateUrl: './customer-account-budget-group.component.html',
  styleUrls: ['./customer-account-budget-group.component.scss']
})
export class CustomerAccountBudgetGroupComponent implements OnInit {
  @Input()
  allowCustomDateRange: boolean = true;
  @Input()
  accountType: string;
  @Input()
  accountTypeLabel: string;
  @Input()
  accounts: CustomerAccountBudget[];
  @Input()
  customerId: string;
  @Input()
  showSummary: boolean = true;
  @Output()
  saved: EventEmitter<void> = new EventEmitter<void>();
  public budgetDetails: {
    [index: string]: {
      settings: Observable<CustomerBudget>;
      summary: Promise<CustomerAccountCost>;
    };
  } = {};

  constructor(
    private customerAccountRulesService: CustomerAccountRulesService
  ) {}

  ngOnInit() {}

  saveAccountSettings(
    budgetChange: Change<Partial<CustomerBudget>>,
    accountId: string
  ) {
    this.customerAccountRulesService
      .updateBudget({ accountType: this.accountType, accountId }, budgetChange)
      .then(() => this.saved.emit());
  }

  getBudgetDetails(accountId: string) {
    this.budgetDetails[accountId] = {
      settings: this.customerAccountRulesService.get(
        { accountId, accountType: this.accountType },
        'budget'
      ),
      summary: this.showSummary
        ? this.customerAccountRulesService.getCostSummary({
            accountId,
            accountType: this.accountType
          })
        : Promise.resolve(null)
    };
  }
}
