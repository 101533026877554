import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';
import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { FormControl } from '@angular/forms';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import {
  NOTIFICATIONS_PATH,
  NOTIFICATION_TOKENS_PATH
} from '../shared/firebase-paths';
import { PageTitleService } from '../shared/page-title/page-title.service';
import { UserService } from '../users/user.service';
import { EnvironmentService } from '../shared/environment.service';
import {
  EaseNotification,
  NotificationMarkReadWriteItem,
  NotificationMarkAsReadWrites,
  NotificationStatus,
  NotificationUpdate,
  PushNotificationOutgoingOptions,
  PushNotificationPayload,
  ServiceWorkerMessage,
  NotificationCount
} from './notification.interface';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  public unread$: Observable<EaseNotification[]>;
  public unreadCount$: Observable<NotificationCount>;
  public read$: Observable<EaseNotification[]>;
  public unreadById$: Observable<{ [itemId: string]: boolean }>;
  public notificationSortControl: FormControl<string> = new FormControl(
    localStorage.getItem('notificationsSort') || 'priority'
  );
  public notificationFilterControl: FormControl<string[]> = new FormControl(
    JSON.parse(localStorage.getItem('notificationsFilter')) || []
  );
  public notificationsSort$: Observable<string> =
    this.notificationSortControl.valueChanges.pipe(
      startWith(this.notificationSortControl.value),
      tap(val => localStorage.setItem('notificationsSort', val)),
      shareReplay(1)
    );
  public notificationsFilter$: Observable<string[]> =
    this.notificationFilterControl.valueChanges.pipe(
      startWith(this.notificationFilterControl.value),
      tap(val =>
        localStorage.setItem('notificationsFilter', JSON.stringify(val || []))
      ),
      shareReplay(1)
    );
  private currentUrl$: Observable<string>;
  private notificationUrls$: Observable<string[]>;
  private notificationUrlMatcher$: Observable<string>;
  private baseUnread$: Observable<EaseNotification[]>;
  private baseRead$: Observable<EaseNotification[]>;

  constructor(
    private angularFire: FirebaseDbService,
    private afMessaging: AngularFireMessaging,
    private router: Router,
    private userService: UserService,
    private environmentService: EnvironmentService,
    private pageTitleService: PageTitleService
  ) {
    this.environmentService.isServiceWorkerReady().then(() => {
      console.log(
        '[NotificationsService] Found SW registration, setting up notifications'
      );
      this.setupMessagingServiceWorker();
    });

    this.baseUnread$ = this.userService.currentUser$.pipe(
      switchMap(user =>
        this.applyStreamTransformers(
          this.angularFire.getList(`/${NOTIFICATIONS_PATH}/${user.$key}`, ref =>
            ref.orderByChild('status').equalTo('unread')
          ),
          'unread'
        )
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.baseRead$ = this.userService.currentUser$.pipe(
      switchMap(user =>
        this.applyStreamTransformers(
          this.angularFire.getList(`/${NOTIFICATIONS_PATH}/${user.$key}`, ref =>
            ref.orderByChild('updatedAtReversed')
          ),
          'read'
        )
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.read$ = this.getSortedNotifications(this.baseRead$);
    this.unread$ = this.getSortedNotifications(this.baseUnread$);
    this.unreadById$ = this.getUserNotificationsByStatus('unread');

    this.unreadCount$ = combineLatest([this.baseUnread$, this.unread$]).pipe(
      map(([baseUnread, filteredUnread]) => ({
        filtered: filteredUnread.length,
        total: baseUnread.length
      })),
      tap(({ total }) => this.pageTitleService.updateNotificationTitle(total)),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.currentUrl$ = this.router.events.pipe(
      filter(ev => ev instanceof NavigationEnd),
      map((ev: NavigationEnd) => ev.urlAfterRedirects),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.notificationUrls$ = this.unread$.pipe(
      debounceTime(1000),
      map(notifications =>
        notifications.map(notification => notification.clickUrl)
      )
    );

    this.notificationUrlMatcher$ = combineLatest([
      this.currentUrl$,
      this.notificationUrls$
    ]).pipe(
      map((combined: any[]) => {
        const currentUrl: string = combined[0];
        const allUrls: string[] = combined[1];
        return allUrls.indexOf(currentUrl) > -1 ? currentUrl : '';
      }),
      filter(matchedUrl => !!matchedUrl.length)
    );

    this.notificationUrlMatcher$
      .pipe(filter(() => document.hasFocus()))
      .subscribe(matchedUrl => this.markReadFromUrl(matchedUrl));

    window.onfocus = ev => {
      this.markReadFromUrl(this.router.url);
    };
  }

  getUserNotificationsByStatus(
    status: NotificationStatus
  ): Observable<{ [itemId: string]: boolean }> {
    return this.userService.currentUser$.pipe(
      switchMap(user =>
        this.angularFire.getList(`/${NOTIFICATIONS_PATH}/${user.$key}`, ref =>
          ref.orderByChild('status').equalTo(status)
        )
      ),
      map(notifications =>
        notifications.reduce(
          (acc, notification) => ({
            ...acc,
            [notification.$key]: !!notification
          }),
          {}
        )
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  async markRead(itemId: string): Promise<void> {
    if (this.userService.currentUser.$key) {
      const item = await this.angularFire.database
        .ref(
          `/${NOTIFICATIONS_PATH}/${this.userService.currentUser.$key}/${itemId}`
        )
        .once('value')
        .then(snap => snap.val());

      if (item) {
        const updates = this.getMarkReadWrites(itemId, item);

        await this.angularFire.database.ref().update(updates);
      }
    }
  }

  async markAllRead(notifications: EaseNotification[]): Promise<void> {
    if (this.userService.currentUser.$key) {
      let allUpdates: NotificationMarkAsReadWrites = {};

      const allItems = await this.angularFire.database
        .ref(`/${NOTIFICATIONS_PATH}/${this.userService.currentUser.$key}`)
        .once('value')
        .then(snap => snap.val());

      for (const notification of notifications) {
        if (notification?.itemId) {
          const item = allItems[notification.itemId];

          allUpdates = {
            ...allUpdates,
            ...this.getMarkReadWrites(notification.itemId, item)
          };
        }
      }

      await this.angularFire.database.ref().update(allUpdates);
    }
  }

  getStatusForItem(itemId: string): Observable<NotificationStatus> {
    return this.userService.currentUser$.pipe(
      switchMap(user =>
        this.angularFire.getObject(
          `/${NOTIFICATIONS_PATH}/${user.$key}/${itemId}/status`
        )
      ),
      map(status => status?.$value),
      distinctUntilChanged()
    );
  }

  async deleteForItem(itemIdToDelete: string): Promise<void> {
    await firstValueFrom(
      this.userService.usersAll.pipe(
        take(1),
        switchMap(users =>
          Promise.all(
            users.map(user =>
              this.angularFire
                .object(`/${NOTIFICATIONS_PATH}/${user.$key}/${itemIdToDelete}`)
                .remove()
            )
          )
        )
      )
    );
  }

  private applyStreamTransformers(
    notifications$: Observable<EaseNotification[]>,
    status: NotificationStatus
  ) {
    return notifications$.pipe(
      debounceTime(100),
      map((items: EaseNotification[]) =>
        items
          .map((item: EaseNotification) =>
            this.getUpdatesForStatus(item, status)
          )
          .filter(item => item?.updates?.length)
      )
    );
  }

  private filterByType(notifications: EaseNotification[], types: string[]) {
    return notifications.filter(notification =>
      types.some(type => notification[type])
    );
  }

  private getSortedNotifications(
    notifications$: Observable<EaseNotification[]>
  ) {
    return combineLatest([
      notifications$,
      this.notificationsSort$,
      this.notificationsFilter$
    ]).pipe(
      map(([notifications, sortType, filters]) => {
        if (filters && filters.length) {
          notifications = [...this.filterByType(notifications, filters)];
        }

        switch (sortType) {
          case 'priority':
            return this.sortByPriority(notifications);

          case 'chronological':
          default:
            return orderBy(notifications, ['updatedAtReversed']);
        }
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  // get updates per item and flag update types
  private getUpdatesForStatus(
    item: EaseNotification,
    filterByStatus: NotificationStatus
  ): EaseNotification {
    const mentionRegex = /mention/i;
    const assignedRegex = /assigned/i;
    const subscribedRegex = /subscribed/i;
    const completedRegex = /completed/i;
    const noteAddedRegex = /note/i;

    let updates = [];
    // set initial values for update types flags
    item.hasMention = false;
    item.hasAssigned = false;
    item.hasSubscribed = false;
    item.hasCompleted = false;
    item.hasNoteAdded = false;
    if (item.updates) {
      updates = Object.keys(item.updates)
        .reverse()
        .reduce((acc, updateId) => {
          const update: NotificationUpdate = item.updates[updateId];
          if (update.status === filterByStatus) {
            switch (true) {
              case mentionRegex.test(update.type):
                item.hasMention = true;
                update.isMention = true;
                break;
              case assignedRegex.test(update.type):
                item.hasAssigned = true;
                update.isAssigned = true;
                break;
              case subscribedRegex.test(update.type):
                item.hasSubscribed = true;
                update.isSubscribed = true;
                break;
              case completedRegex.test(update.type):
                item.hasCompleted = true;
                break;
              case noteAddedRegex.test(update.type):
                item.hasNoteAdded = true;
                break;
            }

            acc.push(update);
          }

          return acc;
        }, []);
    }
    item.updates = [...updates];

    return item;
  }

  private async setupMessagingServiceWorker(): Promise<void> {
    /**
     * Known issue:
     * When using HMR with Angular dev server, this will log an error
     * warning about calling useServiceWorker prior to getToken.
     *
     * The warning itself is harmless and doesn't appear on production
     */
    const hasSupport = await this.environmentService.hasMessagingSupport();

    if (hasSupport) {
      await this.environmentService.isServiceWorkerReady();
      this.initializeToken().then(() => this.addNotificationsListeners());
    }
  }

  private sortByPriority(
    notifications: EaseNotification[]
  ): EaseNotification[] {
    return orderBy(
      notifications,
      [
        (item: EaseNotification) =>
          item.updates.some(update =>
            update.type.toLowerCase().includes('mention')
          ),
        (item: EaseNotification) =>
          item.updates.some(update =>
            update.type.toLowerCase().includes('note')
          ),
        (item: EaseNotification) =>
          item.updates.some(
            update =>
              update.type.toLowerCase().includes('assigned') &&
              !update.action.toLowerCase().includes('ease')
          ),
        (item: EaseNotification) =>
          item.updates.some(update =>
            update.type.toLowerCase().includes('completed')
          )
      ],
      ['desc', 'desc', 'desc', 'desc']
    );
  }

  private async markReadFromUrl(url: string): Promise<void> {
    if (this.userService.currentUser.$key) {
      await firstValueFrom(
        this.angularFire
          .getList(
            `/${NOTIFICATIONS_PATH}/${this.userService.currentUser.$key}`,
            ref => ref.orderByChild('clickUrl').equalTo(url)
          )
          .pipe(
            take(1),
            switchMap((notifications: EaseNotification[]) =>
              Promise.all(
                notifications.map(notification =>
                  this.markRead(notification.$key)
                )
              )
            )
          )
      );
    }
  }

  private getMarkReadWrites(
    itemId: string,
    item: NotificationMarkReadWriteItem
  ): NotificationMarkAsReadWrites {
    const updates = {};

    updates[
      `/${NOTIFICATIONS_PATH}/${this.userService.currentUser.$key}/${itemId}/status`
    ] = 'read';

    if (item.updates) {
      for (const updateId in item.updates) {
        if (updateId) {
          updates[
            `/${NOTIFICATIONS_PATH}/${this.userService.currentUser.$key}/${itemId}/updates/${updateId}/status`
          ] = 'read';
        }
      }
    }

    return updates;
  }

  private async requestPermission(): Promise<void | NotificationPermission> {
    const hasSupport = await this.environmentService.hasMessagingSupport();

    if (hasSupport) {
      return firstValueFrom(this.afMessaging.requestPermission);
    }
  }

  private async getToken(): Promise<string | void> {
    try {
      const permission = await this.requestPermission();

      if (permission === 'granted') {
        return firstValueFrom(this.afMessaging.getToken);
      } else {
        throw new Error(
          `Didn't attempt to get a notification token because permission resolved to: ${permission}`
        );
      }
    } catch (err) {
      console.log(
        '[NotificationsService] Failed to get token or permission denied!',
        err
      );
    }
  }

  private updateToken(token: string): void {
    this.userService.currentUser$.pipe(take(1)).subscribe(currentUser => {
      if (token) {
        this.angularFire
          .object(`/${NOTIFICATION_TOKENS_PATH}/${currentUser.$key}/${token}`)
          .set(true);
      } else {
        this.angularFire
          .object(`/${NOTIFICATION_TOKENS_PATH}/${currentUser.$key}/`)
          .remove();
      }
    });
  }

  private handleServiceWorkerMessage(event: ServiceWorkerMessage): void {
    if (event.data && event.data.action) {
      switch (event.data.action) {
        case 'NAVIGATE':
          this.router.navigateByUrl(event.data.url);
          break;

        default:
          break;
      }
    }
  }

  private initializeToken(): Promise<void> {
    return this.getToken().then(token => token && this.updateToken(token));
  }

  private handleFirebaseMessage(
    payload: PushNotificationPayload
  ): Promise<void> {
    return this.environmentService.swRegistration
      .getNotifications({ tag: payload.data.tag })
      .then(notis => {
        let title: string = payload.data.title;
        const options: PushNotificationOutgoingOptions = {
          body: 'Click to open Ease',
          tag: payload.data.tag,
          icon: payload.data.icon,
          badge: payload.data.badge,
          data: {
            clickUrl: '/'
          }
        };
        if (notis.length) {
          title = 'Multiple Unread Notifications';
          options.data.clickUrl = '/';
        }
        return this.showNotification(title, options);
      });
  }

  private showNotification(
    title: string,
    options: PushNotificationOutgoingOptions
  ): void {
    if (this.environmentService.swRegistration.active) {
      this.environmentService.swRegistration.showNotification(title, options);
    }
  }

  private addNotificationsListeners(): void {
    this.afMessaging.tokenChanges.subscribe(() => this.initializeToken());

    if (this.environmentService.hasServiceWorker()) {
      navigator.serviceWorker.onmessage = ev =>
        this.handleServiceWorkerMessage(ev);

      navigator.serviceWorker.ready.then(() =>
        this.afMessaging.onMessage(payload =>
          this.handleFirebaseMessage(payload as PushNotificationPayload)
        )
      );
    }
  }
}
