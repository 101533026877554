import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  endWith,
  filter,
  fromEvent,
  map,
  mapTo,
  Observable,
  scan,
  shareReplay,
  startWith,
  switchMap,
  timer,
  withLatestFrom
} from 'rxjs';
import { FirebaseDbService } from '../../../shared/firebase-db.service';
import { TYPESENSE_UPDATES_PATH } from '../../../shared/firebase-paths';
import { TypesenseCollection } from '../../../shared/search/search.interface';

import { WindowService } from '../../../shared/window/window.service';
import { TaskUpdateOperation } from '../task-dashboards.interface';

@Injectable({
  providedIn: 'root'
})
export class TaskDashboardsViewService {
  public autoRefresh$: Observable<void>;
  private defaultAutoRefreshInterval: number = 6000;
  private fasterAutoRefreshInterval: number = 500;
  private refreshModifiedTimeoutId: number;
  private autoRefreshInterval$: BehaviorSubject<number> =
    new BehaviorSubject<number>(this.defaultAutoRefreshInterval);
  private autoRefreshRollbackDelay: number = 4000;
  private windowAutoRefreshStatus$: Observable<boolean>;
  private autoRefreshToggle$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  private refreshSource$: BehaviorSubject<void> = new BehaviorSubject<void>(
    null
  );
  public refreshedSource$: BehaviorSubject<void> = new BehaviorSubject<void>(
    null
  );
  public refresh$: Observable<void> = this.refreshSource$
    .asObservable()
    .pipe(debounceTime(50));
  public refreshed$: Observable<void> = this.refreshedSource$.asObservable();
  private taskUpdatesSource$: BehaviorSubject<TaskUpdateOperation> =
    new BehaviorSubject<TaskUpdateOperation>(null);
  /**
   * Dashboard-wide update-loading indicator for each task
   * If an operation is starting, mark it as loading.
   * If an operation finished, remove it from the accumulator.
   */
  public taskUpdates$: Observable<Record<string, boolean>> =
    this.taskUpdatesSource$.asObservable().pipe(
      scan((acc, change) => {
        if (change?.operation === 'start') {
          acc[change.taskId] = true;
        }

        if (change?.operation === 'finish') {
          delete acc[change.taskId];
        }

        return acc;
      }, {})
    );

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private windowService: WindowService,
    private angularFire: FirebaseDbService
  ) {
    this.configureAutoRefresh();
  }

  /**
   * Set whether we should auto-refresh when viewing the dashboard
   *
   * @param status Whether the auto-refresh behaviour should be enabled or disabled
   */
  setAutoRefresh(status: boolean): void {
    this.autoRefreshToggle$.next(status);
  }

  /**
   * When a task changes from inside a widget (due to a user action in this session),
   * Increase the auto-refresh frequency for some time until we revert it back to a
   * slower frequency after the timeout ends
   *
   * Ensure we enable auto-refresh as part of speeding up the refresh frequency
   */
  increaseRefreshFrequency() {
    this.refreshModifiedTimeoutId &&
      clearTimeout(this.refreshModifiedTimeoutId);
    this.autoRefreshInterval$.next(this.fasterAutoRefreshInterval);
    this.setAutoRefresh(true);
    this.refreshModifiedTimeoutId = setTimeout(() => {
      this.autoRefreshInterval$.next(this.defaultAutoRefreshInterval);
    }, this.autoRefreshRollbackDelay) as unknown as number;
  }

  /**
   * Used to explicitly refresh the dashboard on-demand
   */
  refresh() {
    this.refreshSource$.next();
  }

  /**
   * Configures listeners required to handle pause/resume of auto-refresh
   * Also creates the autoRefresh$ observable itself
   */
  private configureAutoRefresh(): void {
    /**
     * Listen for windows being opened on top of the dashboard
     * Pause auto-refresh when windows obscure the dashboard
     */
    this.windowAutoRefreshStatus$ = this.windowService.selected$.pipe(
      startWith(null),
      filter(window => !!window),
      switchMap(window =>
        window.onStateChanged.pipe(startWith(null), endWith(null as void))
      ),
      map(
        windowState => !(windowState === 'MAXIMIZED' || windowState === 'OPEN')
      ),
      distinctUntilChanged(),
      debounceTime(100),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.windowAutoRefreshStatus$.subscribe(status => {
      this.setAutoRefresh(status);
      status && this.refresh();
    });

    /**
     * Add a visibility listener to the browser tab to disable
     * auto-refresh when out of focus. When regaining focus,
     * only re-enable auto-refresh if there is not an Ease
     * window obscuring the dashboard.
     */
    combineLatest([
      fromEvent(this.document, 'visibilitychange'),
      this.windowAutoRefreshStatus$
    ])
      .pipe(filter(([_, windowRefreshStatus]) => windowRefreshStatus === true))
      .subscribe(() => {
        const isVisible = document.visibilityState === 'visible';

        this.setAutoRefresh(document.visibilityState === 'visible');
        isVisible && this.refresh();
      });

    this.autoRefresh$ = this.autoRefreshInterval$.pipe(
      switchMap(intervalDuration => timer(0, intervalDuration)),
      withLatestFrom(
        this.autoRefreshToggle$.pipe(startWith(true), distinctUntilChanged())
      ),
      filter(([_, enabled]) => enabled),
      mapTo(null)
    );
  }

  /**
   * Get an observable that tracks Typesense indexing for a given task ID
   *
   * @param taskId Task ID to watch for changes being indexed to Typesense
   * @returns An observable that contains the timestamp and eventId (or null)
   * of the last time this task was updated
   */
  listenForTaskUpdate(
    taskId: string
  ): Observable<{ timestamp: number; eventId: string }> {
    const collection: TypesenseCollection = 'tasks';

    return this.angularFire
      .object<{ timestamp: number; eventId: string }>(
        `${TYPESENSE_UPDATES_PATH}/${collection}/${taskId}`
      )
      .valueChanges();
  }

  /**
   * Add an operation to the dashboard-wide loading indicator for each task ID
   * Operations with 'start' will mark a task as loading, operations with 'finish'
   * will mark a task as finished loading
   *
   * @param operation The operation containing task ID and start/finish state
   */
  doTaskOperation(operation: TaskUpdateOperation) {
    this.taskUpdatesSource$.next(operation);
  }
}
