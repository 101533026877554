import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, firstValueFrom, Observable, Subject } from 'rxjs';
import { catchError, startWith } from 'rxjs/operators';
import { has, merge } from 'lodash-es';
import * as moment from 'moment';

import { TypedFormGroup } from 'src/app/shared/reactive-forms';
import { EntityMetadata } from 'src/app/shared/shared.interface';
import { ChartOptions } from 'chart.js';
import { SkSyncHttpService } from '../../shared/sksync-http.service';
import { UserService } from '../../users/user.service';
import {
  CHART_CURRENCY_OPTIONS,
  CHART_PERCENTAGE_OPTIONS
} from '../../shared/charts/charts';
import { AccountBudgetDialogComponent } from './account-budget-dialog/account-budget-dialog.component';
import { AccountFeedDialogComponent } from './account-feed-dialog/account-feed-dialog.component';
import {
  Account,
  AggregateTotals,
  AccountDataFilters,
  COMMetric,
  SnoozeData,
  AccountSummary,
  AccountDataRequest
} from './account-dashboard.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountDashboardService {
  private reloadSource: Subject<boolean> = new Subject<boolean>();
  public reload$ = this.reloadSource.asObservable().pipe(startWith(true));
  public initialAccountDataFilters: AccountDataFilters;
  public filtersForm: TypedFormGroup<AccountDataFilters>;
  public highImpactOptiscoreWeight: number = 1500;
  public currencyChartOptions: ChartOptions = this.getChartOptions(
    CHART_CURRENCY_OPTIONS
  );
  public percentageChartOptions: ChartOptions = this.getChartOptions(
    CHART_PERCENTAGE_OPTIONS
  );

  constructor(
    private matDialog: MatDialog,
    private skSyncHttp: SkSyncHttpService,
    private userService: UserService,
    private formBuilder: FormBuilder
  ) {
    this.filtersForm = this.formBuilder.group<typeof this.filtersForm.controls>(
      {
        search: this.formBuilder.control(null),
        role: this.formBuilder.control('manager'),
        onlineAt: this.formBuilder.control(''),
        users: this.formBuilder.control([this.userService.currentUser.$key])
      }
    );

    this.initialAccountDataFilters = this.filtersForm.value;
  }

  applyDataFilters(filters: AccountDataFilters): void {
    if (filters) {
      this.filtersForm.patchValue(filters);
    } else {
      this.filtersForm.patchValue(this.initialAccountDataFilters);
    }
  }

  getAccounts(requestFilters: AccountDataRequest): Observable<Account[]> {
    return this.skSyncHttp
      .get(`/amd`, {
        params: {
          ...requestFilters,
          ...{ region: this.userService.currentUser.region }
        }
      })
      .pipe(catchError(() => EMPTY));
  }

  getAccountsByCustomer(customerId: string): Observable<Account[]> {
    return this.skSyncHttp
      .get(`/amd`, {
        params: {
          ...{ customerId }
        }
      })
      .pipe(catchError(() => EMPTY));
  }

  getAccountsSummary(
    requestFilters: AccountDataRequest
  ): Observable<AccountSummary> {
    return this.skSyncHttp.get(`/amd/summary`, {
      params: {
        ...requestFilters,
        ...{ region: this.userService.currentUser.region }
      }
    });
  }

  getAggregateMetric(
    requestFilters: AccountDataRequest,
    cacheBust?: boolean
  ): Observable<AggregateTotals> {
    return this.skSyncHttp.get(
      `/amd/aggregate`,
      {
        params: {
          ...requestFilters,
          ...{ region: this.userService.currentUser.region }
        }
      },
      { cacheBust }
    );
  }

  getLowestVerticalCPL(competitiveMetric: COMMetric): number {
    if (has(competitiveMetric, 'metadata.rankingList')) {
      return competitiveMetric.metadata.rankingList[0].CPL;
    }
  }

  getHighestVerticalCPL(competitiveMetric: COMMetric): number {
    if (has(competitiveMetric, 'metadata.rankingList')) {
      return competitiveMetric.metadata.rankingList[
        competitiveMetric.metadata.rankingList.length - 1
      ].CPL;
    }
  }

  getAccountVerticalRank(competitiveMetric: COMMetric): string {
    if (competitiveMetric.position) {
      switch (competitiveMetric.position) {
        case 1:
          return competitiveMetric.position + 'st';
        case 2:
          return competitiveMetric.position + 'nd';
        case 3:
          return competitiveMetric.position + 'rd';
        default:
          return competitiveMetric.position + 'th';
      }
    }
  }

  getSnoozeDuration(duration: number | 'custom', snoozeDate: number): number {
    let snoozeDuration: number;
    const isCustomDate = duration === 'custom' && typeof duration === 'string';

    if (isCustomDate) {
      const todayDate = moment();
      snoozeDuration = moment(snoozeDate).diff(todayDate, 'days') + 1;
    } else {
      snoozeDuration = duration;
    }

    return snoozeDuration;
  }

  async snoozeAccount(
    snoozeMeta: EntityMetadata,
    snoozeData: SnoozeData
  ): Promise<Account> {
    const path =
      snoozeMeta.entityType === 'customer'
        ? `/amd/${snoozeMeta.entityId}/snoozeAll`
        : `/amd/${snoozeMeta.accountType}/${snoozeMeta.entityId}/snooze`;

    const accountResponse = await firstValueFrom(
      this.skSyncHttp.patch(path, {
        createdBy: this.userService.currentUser.$key,
        ...snoozeData
      })
    );

    // After successfully snoozing, trigger a dashboard refresh (with cache busting)
    this.reloadDashboard(0, true);

    return accountResponse;
  }

  openBudgetManagerDialog(
    accountId: string,
    accountType: string,
    accountName: string
  ) {
    const dialogRef = this.matDialog.open(AccountBudgetDialogComponent, {
      width: '70vw'
    });
    dialogRef.componentInstance.accountId = accountId;
    dialogRef.componentInstance.accountType = accountType;
    dialogRef.componentInstance.accountName = accountName;

    firstValueFrom(dialogRef.afterClosed()).then(budgetChange => {
      if (budgetChange) {
        // TODO: Update row instance budget instead of reloading the full list.
        this.reloadDashboard(2000);
      }
    });
  }

  openAccountFeed() {
    return this.matDialog.open(AccountFeedDialogComponent, {
      width: '70vw',
      height: '80vh'
    });
  }

  reloadDashboard(msDelay: number = 0, cacheBust?: boolean) {
    setTimeout(() => {
      this.reloadSource.next(cacheBust);
    }, msDelay);
  }

  /**
   * Get chart options to be used in AMD popovers/tab content
   *
   * @param chartTypeOptions Chart-specific options to merge with base options (e.g. currency/percentage-specific)
   * @returns Chart Options to be passed to ChartJS
   */
  getChartOptions(chartTypeOptions: ChartOptions): ChartOptions {
    const pluginOptions: ChartOptions<'line'> = {
      plugins: {
        legend: {
          display: false
        }
      }
    };
    const baseOptions: ChartOptions<'line'> = {
      animation: false,
      layout: {
        padding: {
          left: 5,
          right: 20,
          top: 20,
          bottom: 20
        }
      },
      scales: {
        x: {
          title: {
            text: 'Month'
          },
          stacked: false,
          beginAtZero: true,
          min: 0
        }
      }
    };

    return merge({}, chartTypeOptions, pluginOptions, baseOptions);
  }
}
