import { Injectable } from '@angular/core';
import { DataSnapshot } from '@angular/fire/compat/database/interfaces';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import firebase from 'firebase/compat/app';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import {
  ANNOUNCEMENTS_COMPANY_PATH,
  ANNOUNCEMENTS_PATH,
  ANNOUNCEMENTS_USER_PATH
} from 'src/app/shared/firebase-paths';
import { UserService } from '../users/user.service';
import { Announcement } from './announcements.interface';

@Injectable({ providedIn: 'root' })
export class AnnouncementsService {
  private ref: firebase.database.Reference;
  private allActive$: Observable<Announcement[]>;

  constructor(
    private angularFire: FirebaseDbService,
    private userService: UserService
  ) {
    this.ref = this.angularFire.database.ref();

    this.allActive$ = this.getActiveIds().pipe(
      map(ids =>
        ids.map(id =>
          this.angularFire.getObject(`/${ANNOUNCEMENTS_PATH}/${id}`)
        )
      ),
      switchMap(data => (data.length === 0 ? of([]) : combineLatest(data))),
      map((announcements: Announcement[]) =>
        // sort by create date - descending
        announcements.sort((a, b) => (a.createdAt >= b.createdAt ? -1 : 1))
      ),
      shareReplay(1)
    );
  }

  /**
   * Get's all active announcements that targets the current logged in user.
   * If there's no logged in user, returns an empty array.
   *
   * @returns an observable of an array of announcementIDs
   */
  getActiveIds(): Observable<string[]> {
    if (!this.userService.currentUser) {
      return of([]);
    }

    const { $key, region } = this.userService.currentUser;
    return combineLatest([
      this.angularFire.getList(`/${ANNOUNCEMENTS_COMPANY_PATH}/${region}`),
      this.angularFire.getList(`/${ANNOUNCEMENTS_USER_PATH}/${$key}`)
    ]).pipe(
      map(([allCompany, allUser]) => {
        const allActive = [...allCompany, ...allUser];
        return allActive.map(active => active.$key);
      })
    );
  }

  /**
   * gets all the active announcements that should be visible to the current user
   * - all active company level announcements
   * - user level announcements that are targetting current user
   */
  getActive(): Observable<Announcement[]> {
    return this.allActive$;
  }

  get(id: string): Observable<Announcement> {
    return this.angularFire
      .getObject(`/${ANNOUNCEMENTS_PATH}/${id}`)
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }

  /**
   * marks the announcement as read for the current user logged in
   *
   * @param id announcement id
   */
  async markAsRead(id: string): Promise<firebase.database.ThenableReference> {
    return await this.ref
      .child(`/${ANNOUNCEMENTS_PATH}/${id}/markedBy`)
      .push(this.userService.currentUser.$key);
  }

  async markAsUnread(id: string): Promise<DataSnapshot> {
    const markedByRef = this.ref.child(`/${ANNOUNCEMENTS_PATH}/${id}/markedBy`);
    return await markedByRef
      .orderByValue()
      .equalTo(this.userService.currentUser.$key)
      .once('value', snapshot =>
        snapshot.forEach(child => {
          child.ref.remove();
        })
      );
  }

  /**
   * checks if the announcement is already read by the current user by
   * finding the user's id on the markedBy object
   *
   * @param markedBy an object of $key: userId
   */
  isMarkedRead(markedBy: { [key: string]: string }): boolean {
    return markedBy
      ? Object.values(markedBy).includes(this.userService.currentUser.$key)
      : false;
  }
}
