import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';
import { isNil } from 'lodash-es';
import * as moment from 'moment';
import { combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import { ConfirmService } from '../../shared/confirm/confirm.service';

import { SharedLayoutService } from '../../shared/shared-layout.service';
import { BackpackService } from '../backpack.service';
import { TaskModel } from '../task.model';
import { TaskService } from '../task.service';

@Component({
  selector: 'ease-task-summary',
  templateUrl: './task-summary.component.html',
  styleUrls: ['./task-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskSummaryComponent implements OnInit {
  @HostBinding('class') class = 'flex flex-row gap-2 items-start';
  @Input() set task(task: TaskModel) {
    this._task = task;
    this.taskId.next(task.$key);
  }
  get task(): TaskModel {
    return this._task;
  }
  private _task: TaskModel;
  private taskId = new ReplaySubject<string>();
  public isNil = isNil;

  public now: Date = moment().startOf('day').toDate();
  public nowTimeStamp: number = this.now.getTime();
  public inBackpack$: Observable<boolean>;

  constructor(
    public sharedLayoutService: SharedLayoutService,
    public taskService: TaskService,
    public confirmService: ConfirmService,
    public backpackService: BackpackService
  ) {}

  ngOnInit(): void {
    this.inBackpack$ = combineLatest([
      this.taskId,
      this.backpackService.backpack$
    ]).pipe(
      map(([taskId, currentUserBackpack]) => currentUserBackpack[taskId])
    );
  }

  get isCompleted(): boolean {
    return this.task && !!this.task.completedAt;
  }

  async updateTask(taskData?: Partial<TaskModel>): Promise<void> {
    if (
      await this.taskService.allowDueDateChange(this.task, taskData.dueDate)
    ) {
      await this.saveTask({ ...this.task, ...taskData });
    }
  }

  setPriority(priority?: number): void {
    this.saveTask({
      ...this.task,
      priority: typeof priority === 'number' ? priority : null
    });
  }

  changePhase(phaseId: string): void {
    this.taskService.setPhase(this.task.$key, phaseId);
  }

  changeStatus(statusId: string): void {
    this.taskService.setStatus(this.task.$key, statusId);
  }

  saveTask(task: Partial<TaskModel>): Promise<void> {
    return this.taskService.update(this.task.$key, task);
  }
}
