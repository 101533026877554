<ease-sheet
  class="flex h-full w-full min-w-[740px] max-w-screen-lg flex-1 flex-col"
>
  <div class="dialog-header flex flex-row items-center justify-center p-5">
    <div class="title-wrapper flex-1">
      <h1 class="my-0">Retire a user</h1>
    </div>
    <div class="action">
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <!-- Content -->
  <div class="flex-1 overflow-y-auto">
    <mat-stepper #stepper orientation="vertical" linear>
      <mat-step [stepControl]="retireForm.controls.sourceUser">
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Retire a user',
                openStateSublabel: 'Select a user to retire',
                closedStateSublabel:
                  '👋' + retireForm.controls.sourceUser?.value?.name,
                isSelected: stepper.selectedIndex === 0,
                closedStateStatement: retireForm.controls.sourceUser.value
              }
            }"
          ></ng-container>
        </ng-template>
        <div class="mt-3">
          <mat-form-field appearance="outline">
            <mat-label>User to retire</mat-label>
            <mat-select
              placeholder="User to retire"
              [formControl]="retireForm.controls.sourceUser"
              (selectionChange)="stepper.next()"
            >
              <mat-option *ngFor="let user of users$ | async" [value]="user">
                {{ user.name }}
              </mat-option>
            </mat-select>
            <mat-error
              easeFormErrors
              [control]="retireForm.controls.sourceUser"
            ></mat-error>
          </mat-form-field>
        </div>
      </mat-step>
      <mat-step [stepControl]="retireForm.controls.customers">
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Assign new relationship manager',
                openStateSublabel:
                  'Assign a new relationship manager to replace ' +
                  retireForm.controls.sourceUser?.value?.name,
                closedStateSublabel:
                  retireForm.controls.customers?.controls.length + ' customers',
                isSelected: stepper.selectedIndex === 1,
                closedStateStatement:
                  retireForm.controls.customers?.controls.length &&
                  retireForm.controls.customers.valid
              }
            }"
          ></ng-container>
        </ng-template>
        <div class="mt-3">
          <ease-entity-assign
            *ngIf="retireForm.controls.sourceUser.value"
            [user]="retireForm.controls.sourceUser.value"
            [entity]="{
              entityType: 'customer'
            }"
            [role]="{
              id: 'relationshipManager',
              label: 'Relationship Manager'
            }"
            [entityListControl]="retireForm.controls.customers"
          >
          </ease-entity-assign>

          <div class="flex flex-row items-center justify-start">
            <button
              mat-raised-button
              matStepperNext
              [disabled]="retireForm.controls.customers.invalid"
            >
              Next
            </button>
            <span
              class="ml-3 flex-1 text-sm italic text-red-500"
              *ngIf="retireForm.controls.customers.invalid"
              >You need to assign a new relationship manager to all
              customers</span
            >
          </div>
        </div>
      </mat-step>
      <mat-step [stepControl]="retireForm.controls.accountsByManager">
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Assign a new manager to all accounts',
                openStateSublabel:
                  'Assign a new account manager to replace ' +
                  retireForm.controls.sourceUser.value?.name,
                closedStateSublabel:
                  retireForm.controls.accountsByManager?.controls.length +
                  ' accounts',
                isSelected: stepper.selectedIndex === 2,
                closedStateStatement:
                  retireForm.controls.accountsByManager?.controls.length &&
                  retireForm.controls.accountsByManager.valid
              }
            }"
          ></ng-container>
        </ng-template>

        <div class="mt-3">
          <ease-entity-assign
            *ngIf="retireForm.controls.sourceUser.value"
            [user]="retireForm.controls.sourceUser.value"
            [entity]="{
              entityType: 'account'
            }"
            [role]="{
              id: 'manager',
              label: 'Account Manager'
            }"
            [entityListControl]="retireForm.controls.accountsByManager"
          >
          </ease-entity-assign>
          <div class="flex flex-row items-center justify-start">
            <button
              mat-raised-button
              matStepperNext
              [disabled]="retireForm.controls.accountsByManager.invalid"
            >
              Next
            </button>
            <span
              class="ml-3 flex-1 text-sm italic text-red-500"
              *ngIf="retireForm.controls.accountsByManager.invalid"
              >You need to assign a new manager to all accounts</span
            >
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="retireForm.controls.accountsByReviewer">
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Assign a new reviewer to accounts',
                openStateSublabel:
                  'Assign a new account reviewer to replace ' +
                  retireForm.controls.sourceUser.value?.name,
                closedStateSublabel:
                  retireForm.controls.accountsByReviewer?.controls.length +
                  ' accounts',
                isSelected: stepper.selectedIndex === 3,
                closedStateStatement:
                  retireForm.controls.accountsByReviewer?.controls.length &&
                  retireForm.controls.accountsByReviewer.valid
              }
            }"
          ></ng-container>
        </ng-template>

        <div class="mt-3">
          <ease-entity-assign
            *ngIf="retireForm.controls.sourceUser.value"
            [user]="retireForm.controls.sourceUser.value"
            [entity]="{
              entityType: 'account'
            }"
            [role]="{
              id: 'reviewer',
              label: 'Account Reviewer'
            }"
            [entityListControl]="retireForm.controls.accountsByReviewer"
          >
          </ease-entity-assign>

          <div class="flex flex-row items-center justify-start">
            <button
              mat-raised-button
              matStepperNext
              [disabled]="retireForm.controls.accountsByReviewer.invalid"
            >
              Next
            </button>
            <span
              class="ml-3 flex-1 text-sm italic text-red-500"
              *ngIf="retireForm.controls.accountsByReviewer.invalid"
              >You need to assign a new reviewer to all accounts</span
            >
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="retireForm.controls.destinationUser">
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Tasks',
                openStateSublabel:
                  'Re-assign ' +
                  retireForm.controls.sourceUser.value?.name +
                  '\'s tasks',
                closedStateSublabel:
                  totalNumberOfTasks + scheduledTasks + ' tasks',
                isSelected: stepper.selectedIndex === 4,
                closedStateStatement: retireForm.controls.destinationUser.valid
              }
            }"
          ></ng-container>
        </ng-template>
        <div class="mt-3">
          <div class="mb-2">
            Assign {{ retireForm.controls.sourceUser?.value?.name }}'s ({{
              totalNumberOfTasks
            }}) tasks and ({{ scheduledTasks }}) scheduled tasks
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Assign tasks to</mat-label>
            <mat-select [formControl]="retireForm.controls.destinationUser">
              <mat-option
                *ngFor="let user of filteredUsers$ | async"
                [value]="user"
              >
                {{ user.name }}
              </mat-option>
            </mat-select>
            <mat-error
              easeFormErrors
              [control]="retireForm.controls.destinationUser"
            ></mat-error>
          </mat-form-field>

          <button
            mat-raised-button
            matStepperNext
            [disabled]="retireForm.controls.destinationUser.invalid"
          >
            Next
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="retireForm">
        <ng-template matStepLabel>
          <ng-container
            [ngTemplateOutlet]="dynamicStepLabel"
            [ngTemplateOutletContext]="{
              data: {
                mainLabel: 'Summary',
                openStateSublabel:
                  'You are about to retire ' +
                  retireForm.controls.sourceUser.value?.name +
                  '. The following will happen:',
                isSelected: stepper.selectedIndex === 5
              }
            }"
          ></ng-container>
        </ng-template>

        <div class="mt-3">
          <ul class="my-3 space-y-3">
            <li>
              <div class="m-0 font-bold text-gray-900">Customers</div>
              <p class="m-0 text-sm italic">
                {{
                  retireForm.controls.customers?.controls.length
                    ? retireForm.controls.customers?.controls.length
                    : 'No'
                }}
                Customers will be reassigned
              </p>
            </li>
            <li>
              <div class="m-0 font-bold text-gray-900">Accounts</div>
              <p class="m-0 text-sm italic">
                {{
                  retireForm.controls.accountsByManager?.controls.length ||
                  retireForm.controls.accountsByReviewer?.controls.length
                    ? retireForm.controls.accountsByManager?.controls.length +
                      retireForm.controls.accountsByReviewer?.controls.length
                    : 'No'
                }}
                accounts will be reassigned
              </p>
            </li>
            <li>
              <div class="m-0 font-bold text-gray-900">Tasks</div>
              <p class="m-0 text-sm italic">
                {{
                  totalNumberOfTasks ? totalNumberOfTasks + ' tasks' : 'No task'
                }}
                {{
                  scheduledTasks
                    ? 'and ' + scheduledTasks + ' scheduled tasks '
                    : ''
                }}
                will be reassigned
              </p>
            </li>
            <li>
              <div class="m-0 font-bold text-gray-900">
                Task Templates & User Groups
              </div>
              <p class="m-0 text-sm italic">
                User will be removed from task templates and user groups
              </p>
            </li>
            <li>
              <div class="m-0 font-bold text-gray-900">Disable login</div>
              <p class="m-0 text-sm italic">Ease credentials will be revoked</p>
            </li>
          </ul>

          <button
            mat-raised-button
            color="primary"
            (click)="submit()"
            [disabled]="retireForm.invalid || isWorking"
          >
            <mat-progress-spinner
              [diameter]="16"
              color="primary"
              mode="indeterminate"
              class="mr-2"
              *ngIf="isWorking"
            >
            </mat-progress-spinner>
            <span>
              {{ isWorking ? 'Retiring ' : 'Retire ' }}
              {{ retireForm.controls.sourceUser.value?.name }}</span
            >
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</ease-sheet>

<ng-template #dynamicStepLabel let-data="data">
  <div class="flex flex-row">
    <div class="flex flex-1 flex-col font-normal">
      <div class="text-base font-bold">
        {{ data.mainLabel }}
      </div>
      <!-- Open State Step Text -->
      <p class="m-0 text-sm" *ngIf="data.isSelected">
        {{ data.openStateSublabel }}
      </p>
    </div>
    <div
      class="pr-2 font-semibold italic text-gray-700"
      *ngIf="!data.isSelected && data.closedStateStatement"
    >
      {{ data.closedStateSublabel }}
    </div>
  </div>
</ng-template>
