import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';

import { DrawerSidebarComponent } from './drawer-sidebar.component';
import { DrawerSidebarService } from './drawer-sidebar.service';

@NgModule({
  imports: [CommonModule, MatSidenavModule],
  declarations: [DrawerSidebarComponent],
  exports: [DrawerSidebarComponent],
  providers: [DrawerSidebarService]
})
export class DrawerSidebarModule {}
