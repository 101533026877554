<ease-sheet class="popover popover-alert shadow-md">
  <ease-popover-header
    ><mat-icon class="mat-icon-18 mr-2"> warning</mat-icon>
    Account State Detail
  </ease-popover-header>

  <ul class="alert-messages" *ngFor="let alertItem of alert | keyvalue">
    <li
      class="alert-message flex flex-row items-center justify-start"
      *ngFor="let item of alertItem.value.values"
    >
      <div class="dot-status" [ngClass]="item.nowRagStatus"></div>
      <span>{{ item.message }}</span>
    </li>
  </ul>
</ease-sheet>
