import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';

import { FinderModule } from '../finder/finder.module';
import { AppFinderComponent } from './app-finder/app-finder.component';
import { AppNavModule } from './app-nav/app-nav.module';
import { MainComponent } from './main/main.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  imports: [FinderModule, CommonModule, MatSidenavModule],
  declarations: [AppFinderComponent, MainComponent, NotFoundComponent],
  exports: [AppFinderComponent, AppNavModule, MainComponent]
})
export class LayoutModule {}
