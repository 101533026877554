import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom, Observable, of } from 'rxjs';
import get from 'lodash-es/get';
import pickBy from 'lodash-es/pickBy';
import orderBy from 'lodash-es/orderBy';
import identity from 'lodash-es/identity';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take
} from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';

import {
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup
} from 'src/app/shared/reactive-forms';
import { EmailTemplateSettingsService } from 'src/app/admin/email-templates/email-templates-settings/email-templates-settings.service';
import {
  emptyFormArray,
  getFileBase64,
  getUserNameParts,
  setFormGroupTouched
} from 'src/app/shared/utils/functions';
import { SNACKBAR_DURATION_ERROR } from 'src/app/shared/constants';
import {
  EmailMessage,
  EmailMessageAttachment,
  EmailSendData,
  EmailSendResponse,
  EmailTemplateDetails,
  EmailVariableAttributes,
  EmailVariableMeta,
  Recipient,
  RecipientSettings
} from '../../emails/email.interface';
import { CustomerContactsService } from '../../../customers/customer-contacts/customer-contacts.service';
import { EMAIL_REGEXP } from '../../shared-validators';
import { CustomerContactModel } from '../../../customers/customer-contacts/customer-contact.model';
import { EmailTemplateService } from '../../emails/email-template.service';
import { UserService } from '../../../users/user.service';
import { TaskService } from '../../../tasks/task.service';
import { CustomersService } from '../../../customers/customers.service';
import { Customer } from '../../../customers/customer.interface';
import { ListDisplayItem } from '../../shared.interface';

@UntilDestroy()
@Component({
  selector: 'ease-email-composer',
  templateUrl: './email-composer.component.html',
  styleUrls: ['./email-composer.component.scss']
})
export class EmailComposerComponent implements OnInit {
  get emailControlForms(): TypedFormGroup<EmailMessage>[] {
    return this.emails?.controls;
  }

  @Input() templateId$: Observable<string>;
  @Input() layoutStyle: 'horizontal' | 'vertical' = 'horizontal';
  @Input() emailsFormArray: TypedFormArray<EmailMessage>;
  @Input()
  data: EmailSendData = {};
  @Input() disableVariablesEdit: boolean = false;
  @Output() workingOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  variablesLoaded: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  send: EventEmitter<EmailSendResponse> = new EventEmitter<EmailSendResponse>();
  @Output()
  subjectChange: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  templateChange: EventEmitter<EmailTemplateDetails> = new EventEmitter<EmailTemplateDetails>();
  @Output()
  toRecipientChange: EventEmitter<void> = new EventEmitter<void>();
  @ViewChildren(MatExpansionPanel)
  emailPanels: QueryList<MatExpansionPanel>;
  private _templateId: string;
  private _isWorking: boolean;
  set isWorking(status: boolean) {
    this.workingOutput.emit(status);
    this._isWorking = status;
  }
  get isWorking() {
    return this._isWorking;
  }
  private hasOneContact: boolean = false;
  private easeUserSuggestions: ListDisplayItem[] = [];
  public attachments: UploadFile[] = [];
  public customerContacts: CustomerContactModel[] = [];
  public customerContactsSuggestions: ListDisplayItem[] = [];
  public dragOver: boolean = false;
  public emails: TypedFormArray<EmailMessage>;
  public emailsWithOtherRecipients = new Set<number>();
  public emailVariables: string[] = [];
  public hasSharedVariables: boolean = false;
  public listId: string;
  public hiddenVariableFields = [
    'recipientRegion',
    'relationshipManagerEmailSuffix',
    'senderEmailSuffix',
    'senderRegion'
  ];
  public sharedEmailVariables: TypedFormGroup<Record<string, string>>;
  public templateDetails$: Observable<EmailTemplateDetails>;
  public templateVariables$: Observable<string[]>;
  public uploadInput = new EventEmitter<UploadInput>();
  public useSharedValuesControl = new FormControl(false);
  public chipsFilters = [
    (item: ListDisplayItem) => EMAIL_REGEXP.test(item.value as string)
  ];
  private SUFFIX_REQUIRED = 'required';

  constructor(
    private customersService: CustomersService,
    private customerContactsService: CustomerContactsService,
    private emailTemplateService: EmailTemplateService,
    private emailTemplateSettingsService: EmailTemplateSettingsService,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private taskService: TaskService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.templateDetails$ = this.templateId$.pipe(
      switchMap(templateId => {
        if (templateId) {
          this._templateId = templateId;
          return this.emailTemplateService
            .getDetails(templateId)
            .catch(() => null);
        } else {
          return of(null);
        }
      }),
      shareReplay(1)
    );

    this.templateDetails$
      .pipe(
        filter(template => !!template),
        untilDestroyed(this)
      )
      .subscribe(async template => {
        this.templateChange.emit(template);
        this.listId = template.ListId;

        await this.getContactsForCustomer();

        if (template.RecipientSettings) {
          this.populateRecipients(template.RecipientSettings);
        }

        this.hasOneContact = this.customerContacts.length === 1;
      });

    if (!this.emailsFormArray) {
      this.emails = this.formBuilder.array<TypedFormGroup<EmailMessage>>([]);
    } else {
      this.emails = this.emailsFormArray;
    }

    this.sharedEmailVariables = this.formBuilder.group({});

    this.getContactsForCustomer();

    this.templateVariables$ = this.templateDetails$.pipe(
      map(details => get(details, 'Properties.Content.EmailVariables') || []),
      shareReplay(1)
    );

    this.templateVariables$
      .pipe(untilDestroyed(this))
      .subscribe(vars => this.handleEmailVariablesChange(vars));

    this.useSharedValuesControl.valueChanges
      .pipe(startWith(true), untilDestroyed(this))
      .subscribe(
        value =>
          value && this.updateDataFormGroups(this.sharedEmailVariables.value)
      );

    this.sharedEmailVariables.valueChanges
      .pipe(
        debounceTime(300),
        filter(() => this.useSharedValuesControl.value),
        untilDestroyed(this)
      )
      .subscribe(values => this.updateDataFormGroups(values));
  }

  async populateRecipients(
    recipientSettings: RecipientSettings
  ): Promise<void> {
    emptyFormArray(this.emails);

    const recipients = await this.emailTemplateSettingsService.getRecipients(
      this.data.customerId,
      recipientSettings
    );

    // Process "To" recipients first and create multiple emails when necessary
    const { To, ...otherRecipients } = recipients;
    if (To.length) {
      To.forEach(toRecipient => this.addRecipient(toRecipient));
    } else {
      // if there were no recipients from the settings, add one empty group
      this.addRecipient();
    }

    Object.keys(otherRecipients).forEach(field => {
      this.emails.controls.forEach(email =>
        email.controls[field].setValue(
          otherRecipients[field].map(recipient => recipient.email)
        )
      );
    });
  }

  /**
   * Patches each field on the form using its "root" name. Used to prepopulate
   * Ease variables which can have the `_required` suffix.
   *
   * @param form
   * @param values
   */
  patchFields(
    form: TypedFormGroup<EmailMessage>,
    values: Record<string, string>
  ): void {
    const fields = Object.keys(form.controls.Data.controls);
    for (const field of fields) {
      const rootVariableName = field.replace(`_${this.SUFFIX_REQUIRED}`, '');
      form.controls.Data.controls[field].patchValue(
        values[rootVariableName] || null
      );
    }
  }

  getEmailFormGroup(): TypedFormGroup<EmailMessage> {
    const formGroup: TypedFormGroup<EmailMessage> = this.formBuilder.group<
      TypedFormControl<EmailMessage>
    >({
      smartEmailID: this.formBuilder.control(null),
      smartEmailName: this.formBuilder.control(null),
      AddRecipientsToList: this.formBuilder.control(this.listId ? true : false),
      ListId: this.formBuilder.control(this.listId),
      Attachments: this.formBuilder.array<
        TypedFormGroup<EmailMessageAttachment>
      >([]),
      To: this.formBuilder.control(null, {
        validators: [Validators.email, Validators.required]
      }),
      CC: this.formBuilder.control([]),
      BCC: this.formBuilder.control([]),
      Data: this.formBuilder.group({}),
      CustomerID: this.formBuilder.control(this.data.customerId),
      ContactID: this.formBuilder.control(null),
      CreatedBy: this.formBuilder.control(this.userService.currentUser.$key)
    });

    formGroup.controls.To.valueChanges
      .pipe(
        filter(email => !!email),
        untilDestroyed(this)
      )
      .subscribe(async email => {
        const contacts = await this.getContactsForCustomer();

        const matchingContact = contacts.find(
          contact => contact.email === email
        );

        if (matchingContact) {
          formGroup.controls.ContactID.setValue(matchingContact.$key);
        }

        const values = await this.getDataValues(matchingContact);
        this.patchFields(formGroup, values);
        this.toRecipientChange.emit();
      });

    return formGroup;
  }

  async handleEmailVariablesChange(vars: string[]): Promise<void> {
    this.emailVariables = vars || [];
    this.hasSharedVariables = vars && vars.length > 0;
    this.clearFormGroup(this.sharedEmailVariables);
    const values = await this.getDataValues();
    this.addVarsToFormGroup(this.sharedEmailVariables, values);

    this.dataFormGroups.forEach(dataGroup => {
      this.clearFormGroup(dataGroup);
      this.addVarsToFormGroup(dataGroup, this.sharedEmailVariables.value);
    });
    this.variablesLoaded.emit();
  }

  get dataFormGroups(): TypedFormGroup<Record<string, string>>[] {
    return this.emails.controls.map(emailGroup => emailGroup.controls.Data);
  }

  get smartEmailIdControls(): FormControl<string>[] {
    return this.emails.controls.map(
      emailGroup => emailGroup.controls.smartEmailID
    );
  }

  get attachmentsControls(): TypedFormArray<EmailMessageAttachment>[] {
    return this.emails.controls.map(
      emailGroup => emailGroup.controls.Attachments
    );
  }

  updateDataFormGroups(values: Record<string, string>): void {
    const filteredValues = pickBy(values, identity);

    if (Object.keys(filteredValues).length) {
      this.dataFormGroups.forEach(dataGroup => {
        dataGroup.patchValue(values);
      });
    }
  }

  copySharedEmailVariablesToAllRecipients(): void {
    this.updateDataFormGroups(this.sharedEmailVariables.value);
  }

  copySharedEmailVariablesToRecipient(recipientIndex: number): void {
    const recipientGroup = this.emails.at(recipientIndex);
    const dataGroup = recipientGroup.controls.Data;
    const filteredValues = pickBy(this.sharedEmailVariables.value, identity);
    dataGroup.patchValue(filteredValues);
  }

  /**
   * Builds form controls for each email variable. Applies appropriate
   * validators based on the variable's attributes.
   *
   * @param formGroup
   * @param values
   */
  addVarsToFormGroup(
    formGroup: TypedFormGroup<Record<string, string>>,
    values: Record<string, string> = {}
  ): void {
    this.emailVariables.forEach(varName => {
      const variableMeta = this.getVariableMeta(varName);
      formGroup.setControl(
        varName,
        this.formBuilder.control(
          values[variableMeta.name] || null,
          variableMeta.attributes.required ? Validators.required : null
        )
      );
    });

    setFormGroupTouched(formGroup);
  }

  /**
   * Parses a variable name and separates the name from the attributes.
   * Assumes the variable follows this convention:
   *
   * `variableName_attr1_attr2_...`
   *
   * @param name the whole name of the variable from CM's response
   * @returns an object with the variable's `name` and `attributes`
   */
  getVariableMeta(name: string): EmailVariableMeta {
    const segments: string[] = name.split('_');
    const attributes: EmailVariableAttributes = {};

    segments.forEach((attribute, index) => {
      // skip variable name
      if (index === 0) {
        return;
      }

      switch (attribute) {
        case this.SUFFIX_REQUIRED:
          attributes.required = true;
      }
    });

    return {
      name: segments[0],
      attributes
    };
  }

  addRecipient(recipient?: Recipient): void {
    const newEmailGroup = this.getEmailFormGroup();
    this.addVarsToFormGroup(newEmailGroup.controls.Data);
    newEmailGroup.controls.Data.patchValue(this.sharedEmailVariables.value);

    if (recipient) {
      newEmailGroup.patchValue({
        To: recipient.email || null,
        ContactID: recipient.contactId || null
      });

      // if recipient is not a customer contact, set it as "Other" on the To field
      !recipient.contactId &&
        this.emailsWithOtherRecipients.add(this.emails.controls.length);
    }

    this.emailPanels.forEach(panel => panel.close());
    this.emails.push(newEmailGroup);
  }

  removeRecipient(index: number): void {
    this.emails.removeAt(index);

    if (this.emails.length === 1) {
      this.useSharedValuesControl.setValue(false);
    }
  }

  onRecipientSelectChange(
    change: MatSelectChange,
    index: number,
    customRecipient: HTMLInputElement
  ): void {
    if (change.value === 'other') {
      const control = this.emails.at(index).controls.To;

      this.emailsWithOtherRecipients.add(index);
      setTimeout(() => {
        control.setValue(null);
        customRecipient.focus();
      });
    } else {
      this.emailsWithOtherRecipients.delete(index);
    }
  }

  selectFromContacts(index: number, contactSelector: MatSelect): void {
    const control = this.emails.at(index).controls.To;
    control.setValue(null);
    control.setErrors(null);
    this.emailsWithOtherRecipients.delete(index);
    setTimeout(() => contactSelector.open());
  }

  clearFormGroup(formGroup: TypedFormGroup<Record<string, string>>): void {
    Object.keys(formGroup.controls).forEach(controlKey => {
      formGroup.removeControl(controlKey);
    });
  }

  async getContactsForCustomer(): Promise<CustomerContactModel[]> {
    /**
     * Populate easeUserSuggestions first, if needed.
     */
    this.easeUserSuggestions = await firstValueFrom(this.userService.emails);

    if (this.data.customerId) {
      if (!this.customerContacts.length) {
        this.customerContacts = await firstValueFrom(
          this.customerContactsService.get(this.data.customerId).pipe(
            map(contacts => {
              const validContacts = contacts.filter(
                contact => contact && EMAIL_REGEXP.test(contact.email)
              );

              return orderBy(
                validContacts,
                [
                  contact => contact.isStarred || false,
                  contact => !!contact.firstName,
                  contact => contact.firstName || ''
                ],
                ['desc', 'desc', 'asc']
              );
            })
          )
        );

        this.customerContactsSuggestions = this.customerContacts.map(
          contact => ({
            value: contact.email,
            viewValue: `${contact.firstName || ''} ${
              contact.middleName || ''
            } ${contact.lastName || ''} (${contact.email})`
          })
        );

        this.customerContactsSuggestions =
          this.customerContactsSuggestions.concat(this.easeUserSuggestions);
      }
      return this.customerContacts;
    } else {
      return Promise.resolve([]);
    }
  }

  async getDataValues(primaryContact?: CustomerContactModel) {
    const relationshipManager =
      await this.emailTemplateSettingsService.getUserForRole(
        this.data.customerId,
        'relationshipManager'
      );

    if (this.hasOneContact && !primaryContact) {
      const contacts = await this.getContactsForCustomer();
      primaryContact = contacts[0];
    }

    const recipientCustomer = this.data.customerId
      ? await firstValueFrom(this.customersService.get(this.data.customerId))
      : ({} as Customer);

    const recipientCustomerId = this.data.customerId;

    const relationshipManagerAvatar = await this.getUserAvatar(
      relationshipManager.$key
    );

    const senderAvatar = await this.getUserAvatar(
      this.userService.currentUser.$key
    );

    return {
      recipientCompany: recipientCustomer.name
        ? recipientCustomer.name.split(' / ')[0]
        : null,
      recipientCustomerId,
      recipientRegion: recipientCustomer.region || null,
      recipientFirstName: primaryContact ? primaryContact.firstName : null,
      recipientLastName: primaryContact ? primaryContact.lastName : null,
      relationshipManagerEmail: relationshipManager.email || null,
      relationshipManagerFirstName:
        getUserNameParts(relationshipManager.name).firstName || null,
      relationshipManagerLastName:
        getUserNameParts(relationshipManager.name).lastName || null,
      relationshipManagerExtension: relationshipManager.extension || null,
      relationshipManagerBookingUrl: relationshipManager.bookingUrl || null,
      relationshipManagerEmailSuffix: relationshipManager.email
        ? relationshipManager.email.split('@')[1]
        : null,
      relationshipManagerAvatar,
      senderFirstName:
        getUserNameParts(this.userService.currentUser.name).firstName || null,
      senderLastName:
        getUserNameParts(this.userService.currentUser.name).lastName || null,
      senderEmail: this.userService.currentUser.email || null,
      senderExtension: this.userService.currentUser.extension || null,
      senderBookingUrl: this.userService.currentUser.bookingUrl || null,
      senderEmailSuffix: this.userService.currentUser.email
        ? this.userService.currentUser.email.split('@')[1]
        : null,
      senderAvatar,
      ...this.data.variables
    };
  }

  getUserAvatar(userId: string): Promise<string> {
    return firstValueFrom(
      this.userService.getAvatar(userId).pipe(
        take(1),
        map(userAvatar => (userAvatar?.avatar ? userAvatar.avatar : null))
      )
    );
  }

  async getAttachmentFormGroup({
    nativeFile,
    name,
    type
  }: UploadFile): Promise<TypedFormGroup<EmailMessageAttachment>> {
    const base64 = await getFileBase64(nativeFile).then(
      (file: string) => file.split('base64,')[1]
    );
    return this.formBuilder.group({
      Content: base64,
      Name: name,
      Type: type
    });
  }

  async applyAttachments(): Promise<boolean> {
    if (this.attachments.length) {
      const totalBytes = this.attachments.reduce(
        (acc, file) => (acc += file.size),
        0
      );

      if (totalBytes > 25000000) {
        this.matSnackBar.open(
          'Total attachment size must not exceed 25MB',
          'Close',
          {
            duration: SNACKBAR_DURATION_ERROR
          }
        );
        return false;
      }

      this.attachmentsControls.forEach(control => emptyFormArray(control));

      await Promise.all(
        this.attachments.map(async file => {
          await Promise.all(
            this.attachmentsControls.map(async control => {
              control.push(await this.getAttachmentFormGroup(file));
            })
          );
        })
      );
    } else {
      this.attachmentsControls.forEach(control => emptyFormArray(control));
    }

    return true;
  }

  async applySmartEmailFields(): Promise<void> {
    if (this._templateId) {
      const template = await this.emailTemplateService.getDetails(
        this._templateId
      );
      this.emails.controls.forEach(emailControl => {
        const AddRecipientsToList =
          emailControl.controls.AddRecipientsToList.value;
        emailControl.patchValue({
          smartEmailID: template.SmartEmailID,
          smartEmailName: template.Name,
          AddRecipientsToList
        });
      });

      this.smartEmailIdControls.forEach(control =>
        control.setValue(this._templateId)
      );
    }
  }

  async prepareEmail(): Promise<boolean> {
    await this.applySmartEmailFields();
    return await this.applyAttachments();
  }

  async sendEmail(): Promise<void> {
    if (this.emails.valid) {
      this.isWorking = true;
      const success = await this.prepareEmail();
      if (success) {
        const response = await this.emailTemplateService.send(
          this.emails.value
        );

        // Add message to task if non-undefined response returns back,
        // else service "send" handler should show an snackbar error.
        if (response) {
          await this.handleSentEmail(response);
        }

        this.isWorking = false;
      }
    }
  }

  async handleSentEmail(response: EmailSendResponse): Promise<void> {
    /**
     * If there is a task id and the message was sent, attach the message group id
     * to the task so it can be viewed from inside the task.
     */
    if (this.data.taskId && response.messageGroupId) {
      await this.taskService.applyMessageGroupToTask(
        this.data.taskId,
        response.messageGroupId
      );
    }

    this.send.emit(response);
  }

  /**
   * File Upload Handling
   */
  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'addedToQueue':
        if (output.file) {
          this.attachments.push(output.file);
        }
        break;
      case 'removed':
        this.attachments = this.attachments.filter(
          (file: UploadFile) => file !== output.file
        );
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
    }
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id });
  }
}
