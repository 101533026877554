import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserModel } from 'src/app/users/user.model';
import { ListAsObject } from '../../firebase-db.service';
import { FeedItem } from '../feed-item.interface';

@Component({
  selector: 'ease-feed-item-snoozed-state',
  templateUrl: './feed-item-snoozed-state.component.html',
  styleUrls: [
    '../feed-item/feed-item.component.scss',
    './feed-item-snoozed-state.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemSnoozedStateComponent {
  @Input() item: FeedItem;
  @Input() users: ListAsObject<UserModel>;
  @Input() dateFormat: string;
}
