import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeepPartial } from 'src/app/shared/shared.interface';
import { SkSyncHttpService } from 'src/app/shared/sksync-http.service';
import {
  BillingCustomer,
  FusebillCustomer,
  Invoice,
  PaymentDetails,
  PlanResponse,
  SalesMetaOption,
  Subscription
} from './customer-billing.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomerBillingService {
  constructor(private skSyncHttp: SkSyncHttpService) {}

  getBillingCustomer(customerId: string): Observable<BillingCustomer> {
    return this.skSyncHttp.get(
      `/billing/${customerId}`,
      {},
      {
        displayError: false
      }
    );
  }

  getSubscriptions(customerId: string): Observable<Subscription[]> {
    return this.skSyncHttp.get(`/billing/${customerId}/subscriptions`);
  }

  getInvoices(customerId: string): Observable<Invoice[]> {
    return this.skSyncHttp.get(`/billing/${customerId}/invoices`);
  }

  getInvoiceHTML(customerId: string, invoiceId: number) {
    return this.skSyncHttp.get(
      `/billing/${customerId}/invoices/${invoiceId}/html`
    );
  }

  getSalesTrackingSource(): Observable<SalesMetaOption[]> {
    return this.skSyncHttp.get(`/billing/salesTrackingSources`);
  }

  getPlans(code?: string): Observable<PlanResponse[]> {
    return this.skSyncHttp.get(`/billing/plans`, {
      params: {
        code
      }
    });
  }

  createFusebillShell(
    customerId: string,
    createFormData: DeepPartial<FusebillCustomer>
  ) {
    return this.skSyncHttp.post(`/billing/${customerId}`, createFormData);
  }

  getPaymentDetails(fusebillId: number): Observable<PaymentDetails> {
    return this.skSyncHttp.get(`/billing/${fusebillId}/paymentDetails`);
  }
}
