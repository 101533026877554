import { NgModule } from '@angular/core';

import { PopoverModule } from '../popover.module';
import { SharedFormModule } from '../../shared-form.module';
import { SharedModule } from '../../shared.module';
import { TaskPhaseSelectorModule } from '../../../tasks/task-phase/task-phase-selector/task-phase-selector.module';
import { TaskPhasePopoverComponent } from './task-phase-popover.component';

@NgModule({
  imports: [
    PopoverModule,
    SharedModule,
    SharedFormModule,
    TaskPhaseSelectorModule
  ],
  declarations: [TaskPhasePopoverComponent],
  exports: [TaskPhasePopoverComponent, PopoverModule]
})
export class TaskPhasePopoverModule {}
