import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { CustomerStickyNote } from '../../../customers/customer-feed/customer-feed.interface';
import { ConfirmService } from '../../confirm/confirm.service';

@Component({
  selector: 'ease-feed-item-sticky-note',
  templateUrl: './feed-item-sticky-note.component.html',
  styleUrls: [
    '../feed-item/feed-item.component.scss',
    './feed-item-sticky-note.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemStickyNoteComponent {
  @Input()
  note: CustomerStickyNote;
  @Input()
  users;
  @Input()
  dateFormat: string;
  @Output()
  remove: EventEmitter<any> = new EventEmitter<any>();

  constructor(private confirmService: ConfirmService) {}

  removeNote() {
    this.confirmService
      .confirm({
        message: 'Delete sticky note?',
        confirmText: 'Delete',
        cancelText: 'Cancel'
      })
      .then(confirmResult => confirmResult.confirm && this.remove.emit());
  }
}
