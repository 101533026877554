import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

import { IndexerViewComponent } from './indexer-view/indexer-view.component';
import { IndexerDirective } from './indexer.directive';

@NgModule({
  declarations: [IndexerViewComponent, IndexerDirective],
  imports: [CommonModule, MatTooltipModule],
  exports: [IndexerDirective]
})
export class IndexerModule {}
