import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

import { TypedFormGroup } from 'src/app/shared/reactive-forms';
import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { UserService } from '../../../../users/user.service';
import {
  TaskChecklistCheckbox,
  TaskChecklistTemplateFieldOptions
} from '../task-checklist.interface';

@Component({
  selector: 'ease-task-checklist-checkbox',
  templateUrl: './task-checklist-checkbox.component.html',
  styleUrls: ['./task-checklist-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskChecklistCheckboxComponent {
  @Input()
  templateOptions: TaskChecklistTemplateFieldOptions;
  @Input()
  group: TypedFormGroup<TaskChecklistCheckbox>;

  @Input()
  value: any;
  @Output()
  actionClicked: EventEmitter<void> = new EventEmitter<void>();
  public addingNote: boolean = false;

  constructor(
    private angularFire: FirebaseDbService,
    private userService: UserService
  ) {}

  addNote(): void {
    this.addingNote = true;
  }

  cancelNote(): void {
    this.addingNote = false;
  }

  async saveNote(noteValue: string): Promise<void> {
    const createdAt = await this.angularFire.getServerTimestamp();

    this.group?.controls?.note?.patchValue({
      body: noteValue,
      createdBy: this.userService.currentUser.name,
      createdAt
    });
    this.addingNote = false;
  }

  async clickCheckbox(checkbox: MatCheckbox): Promise<void> {
    const completedAt = await this.angularFire.getServerTimestamp();

    setTimeout(() => {
      if (!this.group.disabled) {
        const checked = checkbox.checked;

        if (checked) {
          this.group.patchValue({
            checked,
            completedBy: this.userService.currentUser.name,
            completedAt
          });
        } else {
          this.group.patchValue({
            checked,
            completedBy: '',
            completedAt: null
          });
        }

        this.actionClicked.emit();
      }
    });
  }

  toggleDisabled(): void {
    const disabledValue = this.group?.controls?.disabled?.value;

    this.group.patchValue({
      disabled: !disabledValue,
      checked: false,
      completedBy: !disabledValue
        ? ''
        : this.group?.controls?.completedBy?.value,
      completedAt: !disabledValue
        ? null
        : this.group?.controls?.completedAt?.value
    });
  }
}
