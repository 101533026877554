import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';
import flatten from 'lodash-es/flatten';
import {
  combineLatest,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject
} from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { CustomerAccountsService } from '../../../customers/customer-accounts/customer-accounts.service';
import { ConfirmService } from '../../../shared/confirm/confirm.service';
import { EntityUtilsService } from '../../../shared/entity-utils.service';
import { EntityMetadata } from '../../../shared/shared.interface';
import { ScheduledTask } from '../../scheduled-tasks/scheduled-task.interface';
import { TaskModel } from '../../task.model';
import { TaskService } from '../../task.service';
import { SubtasksService } from '../subtasks.service';

@Component({
  selector: 'ease-subtask-selector',
  templateUrl: './subtask-selector.component.html',
  styleUrls: ['./subtask-selector.component.scss']
})
export class SubtaskSelectorComponent implements OnInit {
  @HostBinding('class') hostClass = 'block  lg:w-[600px]';

  @Input()
  set task(task: TaskModel) {
    this.taskSource.next(task);
    this.currentTaskId = task.$key;
  }

  private allCustomerMeta$: Observable<EntityMetadata[]>;
  private allCustomerAccountMeta$: Observable<EntityMetadata[]>;

  public openTasks$: Observable<TaskModel[]>;
  public scheduledTasks$: Observable<ScheduledTask[]>;
  private taskSource: ReplaySubject<TaskModel> = new ReplaySubject(1);
  private task$: Observable<TaskModel> = this.taskSource.asObservable();
  private customerId$: Observable<string>;
  private currentTaskId: string;
  constructor(
    private customerAccountService: CustomerAccountsService,
    private popover: SatPopover,
    private taskService: TaskService,
    private entityUtilsService: EntityUtilsService,
    private confirmService: ConfirmService,
    private subtasksService: SubtasksService
  ) {}

  ngOnInit(): void {
    this.customerId$ = this.task$.pipe(
      switchMap(task => {
        if (task.entityType === 'account') {
          return this.entityUtilsService.getCustomerIdForAccount({
            accountId: task.entityId,
            accountType: task.accountType
          });
        } else {
          return of(task.entityId);
        }
      })
    );

    this.allCustomerAccountMeta$ = this.customerId$.pipe(
      switchMap(customerId =>
        this.customerAccountService.getCustomerAccountMeta(customerId)
      ),
      map(accountMetas =>
        accountMetas.map(meta => ({
          accountType: meta.accountType,
          entityId: meta.accountId,
          entityType: 'account'
        }))
      )
    );

    this.allCustomerMeta$ = combineLatest([
      this.customerId$,
      this.allCustomerAccountMeta$
    ]).pipe(
      map(([currentCustomerId, customerAccountIds]) => [
        { entityId: currentCustomerId } as EntityMetadata,
        ...customerAccountIds
      ]),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.openTasks$ = this.allCustomerMeta$
      .pipe(
        switchMap(allIds =>
          combineLatest(allIds.map(meta => this.taskService.getForEntity(meta)))
        )
      )
      .pipe(
        map(taskArrs =>
          flatten(taskArrs).filter(
            task => !task.parent && this.currentTaskId !== task.$key
          )
        )
      );
  }

  async makeParent(task: TaskModel): Promise<void> {
    const originalTask = await firstValueFrom(this.task$);

    const confirmResult = await this.confirmService.confirm({
      title: 'Attach to a task',
      message: `Are you sure you want to attach <strong>${originalTask.name}</strong> to <strong>${task.name}</strong>?`,
      confirmText: 'Attach',
      confirmColor: 'primary',
      cancelText: 'Cancel'
    });

    if (confirmResult.confirm) {
      await this.subtasksService.attach(task.$key, originalTask.$key);
    }

    this.popover.close();
  }
}
