<ng-container *ngIf="customerId">
  <ease-list-group no-border-last>
    <ng-container *ngIf="contacts$ | async as contacts; else noContacts">
      <ease-list-group-item *ngFor="let contact of contacts">
        <ease-customer-contact-view flex [contact]="contact">
        </ease-customer-contact-view>
      </ease-list-group-item>
    </ng-container>
  </ease-list-group>
</ng-container>

<ng-template #noContacts>
  <h4 class="m-0 text-center text-gray-400">
    Customer has no contacts defined
  </h4>
</ng-template>
