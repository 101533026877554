import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {
  switchMap,
  Observable,
  of,
  from,
  map,
  combineLatest,
  first,
  BehaviorSubject,
  tap,
  catchError
} from 'rxjs';
import { isEqual } from 'lodash-es';

import { EntityUtilsService } from '../shared/entity-utils.service';
import { EntityMetadata } from '../shared/shared.interface';
import { AssignedRoles, CreatingTaskModel } from './task.model';

interface EntityMetaRoles extends EntityMetadata {
  assignedRoles: AssignedRoles;
}

@Injectable({
  providedIn: 'root'
})
export class TaskFormValidators {
  /**
   * Checks if there were any users assigned or watching, from all methods,
   * specific user or role assigns.
   *
   * @param control
   */
  static assignedWatchersRequired: ValidatorFn = (control: AbstractControl) => {
    const value = control.value;
    const assignedRoles = value?.assignedRoles
      ? Object.keys(value?.assignedRoles)
      : null;
    const hasUsersAssigned = !!(
      value?.assigned?.length ||
      assignedRoles.length ||
      value?.assignCurrentUser
    );
    const hasUsersWatching = !!value?.subscribed?.length;
    return !hasUsersAssigned && !hasUsersWatching
      ? {
          assignedWatchersRequired:
            'At least one user or role must be assigned or watching'
        }
      : null;
  };

  /**
   * Checks if the linked entity(ies) satisfies the assigned roles. Where a customer role
   * can be provided by linking a customer or an account entity. While an account role will
   * require a linked account.
   *
   * @param entityType
   * @param assignedRoles
   * @returns ValidationErrors | null
   */
  private static entityAssignedRoleValidation({
    entityType,
    assignedRoles
  }: EntityMetaRoles): ValidationErrors | null {
    /**
     * Get required linked entity type. If has any "account" roles,
     * the required entity is 'account'. Otherwise, it's 'customer'.
     */
    const hasAccountRoles =
      assignedRoles.accountManager || assignedRoles.accountReviewer;

    switch (entityType) {
      case null:
        return {
          entityError: {
            message: `The selected role(s) require at least one ${
              hasAccountRoles ? 'account' : 'account / customer'
            } to be associated to this task`
          }
        };
      case 'prospect':
      case 'customer':
        if (hasAccountRoles) {
          return {
            entityError: {
              wrongType: true,
              message:
                'The selected role(s) require at least one account to be associated to this task'
            }
          };
        }
        break;
      case 'account':
        return null;
    }
  }

  /**
   * Checks if every assigned role exists on a given entity
   *
   * @param entityMetaRoles
   * @param entityUtilsService
   * @returns returns a string that describes what's missing, if there are any. Otherwise, returns null.
   */
  public static checkMissingRoles(
    { entityId, entityType, accountType, assignedRoles }: EntityMetaRoles,
    entityUtilsService: EntityUtilsService
  ): Observable<string> {
    if (!entityId || !entityType) {
      return of(null);
    }

    const entityRoles$ = combineLatest([
      entityUtilsService.getEntityField(
        { entityType, entityId, accountType },
        'roles'
      ),
      ...(entityType === 'account'
        ? [
            from(
              entityUtilsService
                .getCustomerIdForAccount({
                  accountId: entityId,
                  accountType
                })
                .then(customerId =>
                  entityUtilsService.getEntityField(
                    { entityId: customerId, entityType: 'customer' },
                    'roles'
                  )
                )
            )
          ]
        : [])
    ]);

    return entityRoles$.pipe(
      map(([entityRoles, customerRoles]) => {
        const missingRoles: string[] = [];
        const roles = { ...(entityRoles || {}), ...(customerRoles || {}) };

        /**
         * Check each assigned role is any is missing from the entity. Note
         * that the keys are different for the account roles.
         */
        if (Object.keys(assignedRoles).length) {
          assignedRoles.relationshipManager &&
            !roles?.relationshipManager &&
            missingRoles.push('Relationship Manager');
          assignedRoles.accountManager &&
            !roles?.manager &&
            missingRoles.push('Account Manager');
          assignedRoles.accountReviewer &&
            !roles?.reviewer &&
            missingRoles.push('Account Reviewer');
        }

        if (missingRoles.length) {
          return missingRoles.join(', ');
        } else {
          return null;
        }
      }),
      catchError(
        error =>
          `Unable to fetch selected ${entityType} meta: ${JSON.stringify(
            error
          )}`
      )
    );
  }

  /**
   * Compare entity meta(roles) with assigned roles to get missing for validation
   *
   * @param entityMetaRoles
   * @param entityUtilsService
   * @returns Observable<ValidationErrors | null>
   */
  private static entityMissingRoleValidation(
    { entityId, entityType, accountType, assignedRoles }: EntityMetaRoles,
    entityUtilsService: EntityUtilsService
  ): Observable<ValidationErrors | null> {
    return this.checkMissingRoles(
      { entityId, entityType, accountType, assignedRoles },
      entityUtilsService
    ).pipe(
      map(missingRoles =>
        missingRoles
          ? {
              entityError: {
                message: `The selected ${entityType} has unassigned role(s): ${missingRoles}`
              }
            }
          : null
      )
    );
  }

  static entityRoleValidations(
    entityUtilsService: EntityUtilsService
  ): AsyncValidatorFn {
    /**
     * Static factory
     */
    let lastCheckErrors: ValidationErrors = null;
    let prevRequestMeta: EntityMetaRoles = null;
    const checkSource = new BehaviorSubject<EntityMetaRoles>(null);
    const checkErrors$ = checkSource.asObservable().pipe(
      switchMap(currentMeta => {
        /**
         * If no selected assignedRoles, start/reset by returning valid(null)
         */
        if (Object.keys(currentMeta.assignedRoles).length) {
          /**
           * First, check selected entity satisfies the assigned roles
           * Then if the check has no error(null), pull entity roles
           * for missing checks.
           */
          const entityForAssignedRoleCheck =
            this.entityAssignedRoleValidation(currentMeta);

          if (entityForAssignedRoleCheck) {
            return of(entityForAssignedRoleCheck);
          } else {
            return this.entityMissingRoleValidation(
              currentMeta,
              entityUtilsService
            );
          }
        } else {
          return of(null);
        }
      })
    );

    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      /**
       * AsyncValidator factory
       */
      const {
        entityId,
        entityType,
        accountType,
        assignedRoles
      }: CreatingTaskModel = control?.value || {};

      const currRequestMeta: EntityMetaRoles = {
        entityId,
        entityType,
        accountType,
        assignedRoles
      };

      /**
       * Angular automatically unsubscribes from the previous Observable
       * returned by the AsyncValidator if the form value changes. This is
       * a manual way to emulate `distinctUntilChanged` to block unnecessary
       * requests if this attaches to the entire formGroup.
       *
       * Only run check for the related field updates, else only send back
       * the last errors. e.g., task name update shouldn't do any validations
       */
      if (!isEqual(currRequestMeta, prevRequestMeta)) {
        checkSource.next(currRequestMeta);

        return checkErrors$.pipe(
          first(),
          tap(checkErrors => {
            prevRequestMeta = currRequestMeta;
            lastCheckErrors = checkErrors;
          })
        );
      } else {
        return of(lastCheckErrors);
      }
    };
  }
}
