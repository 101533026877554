import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';

import { A11yModule } from '@angular/cdk/a11y';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { IframeInjecterDirective } from './iframe-injecter/iframe-injecter.directive';
import { AvatarModule } from './avatar/avatar.module';
import { ColorBlobModule } from './color-blob/color-blob.module';
import { ConfirmModule } from './confirm/confirm.module';
import { EaseMaterialModule } from './ease-material.module';
import { ListGroupModule } from './list-group/list-group.module';
import { SheetsModule } from './sheet/sheets.module';
import { StopEventModule } from './stop-event/stop-event.module';
import { ScrollModule } from './scroll/scroll.module';
import { CollapseComponent } from './collapse/collapse.component';
import { ToggleComponent } from './toggle/toggle.component';
import { VerticalRankingComponent } from './vertical-ranking/vertical-ranking.component';
import { StatesModule } from './states/states.module';

@NgModule({
  imports: [
    AvatarModule,
    CommonModule,
    ConfirmModule,
    EaseMaterialModule,
    RouterModule,
    StopEventModule,
    MomentModule,
    ScrollModule,
    StatesModule,
    A11yModule
  ],
  declarations: [
    AutofocusDirective,
    CollapseComponent,
    ToggleComponent,
    IframeInjecterDirective,
    VerticalRankingComponent
  ],
  exports: [
    AutofocusDirective,
    AvatarModule,
    CollapseComponent,
    ColorBlobModule,
    CommonModule,
    EaseMaterialModule,
    IframeInjecterDirective,
    ListGroupModule,
    MomentModule,
    SheetsModule,
    StatesModule,
    StopEventModule,
    A11yModule,
    ToggleComponent,
    VerticalRankingComponent
  ]
})
export class SharedModule {}
