<div #tabNav class="border-r border-solid border-gray-300">
  <mat-selection-list #nav [multiple]="false" class="!p-0">
    <mat-list-option
      *ngFor="let tab of tabs; index as i"
      [selected]="i === 0"
      [value]="i"
      (click)="onSelect(i)"
      [matTooltip]="tab.label"
      [matTooltipPosition]="tab.position"
      class="!h-20 border-l-2 border-r-2 border-solid border-transparent !bg-transparent text-center"
      [ngClass]="{ 'border-r-blue-500': activeTab === i }"
    >
      <ng-container
        *ngTemplateOutlet="
          tab.tabCustomTemplate ? customTemplate : defaultTemplate;
          context: { $implicit: tab }
        "
      >
      </ng-container>
    </mat-list-option>
  </mat-selection-list>
</div>

<mat-tab-group #tabGroup animationDuration="0" class="flex-1 overflow-hidden">
  <mat-tab *ngFor="let tab of tabs" [label]="tab.label">
    <ng-template [ngTemplateOutlet]="tab.content"></ng-template>
  </mat-tab>
</mat-tab-group>

<ng-template #customTemplate let-tab>
  <ng-container [ngTemplateOutlet]="tab.tabCustomTemplate"></ng-container>
</ng-template>

<ng-template #defaultTemplate let-tab>
  <div class="flex flex-row items-center justify-center">
    <mat-icon class="mat-icon mat-icon-24 text-gray-600">
      {{ tab?.icon || 'info' }}
    </mat-icon>
  </div>
</ng-template>
