import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';
import { CustomerFeedViewingEntity } from 'src/app/customers/customer-feed/customer-feed.interface';

@Component({
  selector: 'ease-account-feed-dialog',
  templateUrl: './account-feed-dialog.component.html',
  styleUrls: ['./account-feed-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountFeedDialogComponent implements OnInit {
  @HostBinding('class') hostClass = 'flex flex-col h-full';
  @Input() viewingEntity: CustomerFeedViewingEntity;
  constructor() {}

  ngOnInit() {}
}
