import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxUploaderModule } from 'ngx-uploader';

import { EaseMaterialModule } from '../ease-material.module';
import { SharedModule } from '../shared.module';
import { SharedPipesModule } from '../shared-pipes.module';
import { FilesizePipe } from '../filesize.pipe';
import { FileUploaderComponent } from './file-uploader.component';

@NgModule({
  imports: [
    CommonModule,
    NgxUploaderModule,
    EaseMaterialModule,
    SharedModule,
    SharedPipesModule
  ],
  declarations: [FileUploaderComponent],
  exports: [FileUploaderComponent],
  providers: [FilesizePipe]
})
export class FileUploaderModule {}
