<ng-container *ngIf="sharedLayoutService.isCompact$ | async as isMobile">
  <ng-container
    *ngIf="isMobile.active; then mobile; else desktop"
  ></ng-container>
</ng-container>

<ng-template #desktop>
  <div
    @barTrigger
    *ngIf="selected$ | async as selected"
    easeViewportOffsetPusher
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="drop($event)"
    class="flex flex-row items-center gap-x-2 !bg-blue-gray-900 p-2 shadow-md"
  >
    <div
      *ngFor="let window of windowService.windows; index as i; trackBy: getId"
      cdkDrag
      @tabTrigger
      (click)="window.toggle()"
      class="!hover:bg-blue-gray-700 group min-w-0 max-w-sm flex-1 cursor-pointer self-stretch rounded-md border-blue-gray-800 !bg-blue-gray-800 text-sm text-gray-200"
      [ngClass]="{
        'border border-solid': true,
        '!bg-opacity-50': window.state === 'MINIMIZED',
        'border-blue-gray-600 !bg-blue-gray-700 bg-opacity-100':
          window.state === 'OPEN' || window.state === 'MAXIMIZED',
        'border-2 border-blue-gray-600 !bg-blue-gray-800':
          window.id === selected.id,
        '!bg-blue-gray-900': window.id !== selected.id
      }"
    >
      <ng-container
        *ngIf="window.onTemplateContextChanged | async as templateContext"
      >
        <div
          class="relative h-full"
          *easeRichTooltip="
            windowTooltip;
            showDelay: 500;
            verticalAlign: 'above';
            horizontalAlign: 'center';
            offsetClass: 'm-5';
            anchorClass: '!block w-full h-full';
            autoWidth: true
          "
        >
          <span
            *ngIf="window.highlighted"
            class="absolute top-0 right-0 flex h-3 w-3 translate-x-1/3 -translate-y-1/3 transform"
          >
            <span
              class="absolute inline-flex h-full w-full animate-ping rounded-full bg-blue-500 opacity-75"
            ></span>
            <span
              class="relative inline-flex h-3 w-3 rounded-full bg-blue-600"
            ></span>
          </span>
          <div
            matRipple
            class="flex h-full flex-1 flex-row items-center px-3 py-1"
          >
            <div class="flex-none">
              <ng-template
                [ngTemplateOutlet]="window.iconTemplate || defaultIconTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: window,
                  templateContext: templateContext
                }"
              ></ng-template>
            </div>
            <div class="flex min-w-0 flex-1 flex-col">
              <ng-template
                [ngTemplateOutlet]="
                  window.titleTemplate || defaultTitleTemplate
                "
                [ngTemplateOutletContext]="{
                  $implicit: window,
                  templateContext: templateContext
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="
                  window.subtitleTemplate || defaultSubtitleTemplate
                "
                [ngTemplateOutletContext]="{
                  $implicit: window,
                  templateContext: templateContext
                }"
              ></ng-template>
            </div>

            <button
              *ngIf="{ canClose: window.canClose() | async } as context"
              (click)="window.close(); $event.stopPropagation()"
              class="!hidden !h-6 !w-6 group-hover:!block"
              [ngClass]="{ '!text-blue-gray-500': !context.canClose }"
              [matTooltip]="
                context.canClose
                  ? 'Close'
                  : 'Something is preventing this window from closing'
              "
              [matTooltipShowDelay]="800"
              [matTooltipPosition]="'after'"
              mat-icon-button
            >
              <mat-icon class="mat-icon-16">close</mat-icon>
            </button>
          </div>
        </div>

        <ng-template #windowTooltip>
          <div class="is-dark group">
            <ng-template
              [ngTemplateOutlet]="window.titleTemplate || defaultTitleTemplate"
              [ngTemplateOutletContext]="{
                $implicit: window,
                templateContext: templateContext
              }"
            ></ng-template>
          </div>

          <ng-template
            [ngTemplateOutlet]="
              window.subtitleTemplate || defaultSubtitleTemplate
            "
            [ngTemplateOutletContext]="{
              $implicit: window,
              templateContext: templateContext
            }"
          ></ng-template>
        </ng-template>
      </ng-container>
    </div>
    <button
      matTooltip="Close all"
      class="!ml-auto !text-white"
      (click)="windowService.closeAll()"
      mat-icon-button
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #mobile>
  <ng-container #hasMobileWindow *ngIf="selected$ | async as selected">
    <div
      *ngIf="windowService.windows.length"
      class="relative z-50 h-2 w-full bg-blue-gray-900"
    >
      <div
        class="curved-button bottom-0 bg-blue-gray-900"
        (click)="openMobileManager(); $event.stopPropagation()"
      >
        <mat-icon>keyboard_arrow_up</mat-icon>
      </div>
    </div>

    <ng-template #mobileMangerUi let-bottomSheetRef="bottomSheetRef">
      <div
        *ngIf="selected"
        class="relative bg-blue-gray-900 px-2 pt-2 pb-3 text-sm text-gray-200"
      >
        <div
          class="curved-button -top-[24px] bg-blue-gray-900"
          (click)="closeMobileManager(); $event.stopPropagation()"
        >
          <mat-icon>keyboard_arrow_down</mat-icon>
        </div>

        <div class="mb-2 flex items-center justify-between gap-2 px-2">
          <span class="flex-1 font-bold"
            >Current Windows
            <span
              class="inline-block rounded-full bg-blue-gray-700 px-2 text-xs font-bold text-zinc-300"
              >{{ windowService.windows.length }}</span
            >
          </span>
          <div class="flex-none">
            <button
              mat-button
              class="!px-0 !text-xs !leading-9"
              (click)="windowService.closeAll(); $event.stopPropagation()"
            >
              Close All
            </button>
          </div>
        </div>

        <div
          class="flex max-h-[35vh] flex-col gap-2 overflow-y-auto overflow-x-hidden"
        >
          <div
            *ngFor="
              let window of windowService.windows;
              index as i;
              trackBy: getId
            "
            (click)="
              window.state === 'MINIMIZED' && window.toggle();
              window.state !== 'MINIMIZED' && closeMobileManager()
            "
            class="mx-2 flex flex-1 cursor-pointer flex-row items-center rounded-md border-blue-gray-800 p-1 pl-2"
            [ngClass]="{
              'border border-solid': true,
              '!bg-opacity-50': window.state === 'MINIMIZED',
              'border-blue-gray-600 !bg-blue-gray-700 bg-opacity-100':
                window.state === 'OPEN' || window.state === 'MAXIMIZED',
              'border-2 border-blue-gray-600 !bg-blue-gray-800':
                window.id === selected.id,
              '!bg-blue-gray-900': window.id !== selected.id
            }"
          >
            <ng-container
              *ngIf="window.onTemplateContextChanged | async as templateContext"
            >
              <div class="flex-none">
                <ng-template
                  [ngTemplateOutlet]="
                    window.iconTemplate || defaultIconTemplate
                  "
                  [ngTemplateOutletContext]="{
                    $implicit: window,
                    templateContext: templateContext
                  }"
                ></ng-template>
              </div>
              <div class="flex min-w-0 flex-1 flex-col">
                <ng-template
                  [ngTemplateOutlet]="
                    window.titleTemplate || defaultTitleTemplate
                  "
                  [ngTemplateOutletContext]="{
                    $implicit: window,
                    templateContext: templateContext
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="
                    window.subtitleTemplate || defaultSubtitleTemplate
                  "
                  [ngTemplateOutletContext]="{
                    $implicit: window,
                    templateContext: templateContext
                  }"
                ></ng-template>
              </div>
              <button
                *ngIf="{ canClose: window.canClose() | async } as context"
                mat-icon-button
                class="!h-6 !w-6"
                [ngClass]="{ '!text-blue-gray-500': !context.canClose }"
                (click)="window.close(); $event.stopPropagation()"
              >
                <mat-icon class="mat-icon-16">close</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #defaultIconTemplate let-window>
  <mat-icon class="mat-icon-16 mr-3">{{ window.icon }}</mat-icon>
</ng-template>

<ng-template #defaultTitleTemplate let-window>
  <div
    class="truncate text-sm font-medium text-gray-200 group-[.is-dark]:!text-gray-700"
  >
    {{ window.title }}
  </div>
</ng-template>

<ng-template #defaultSubtitleTemplate let-window>
  <div class="truncate text-xs text-gray-400">
    {{ window.subtitle }}
  </div>
</ng-template>
