import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { map, take } from 'rxjs/operators';
import { RRuleToDateRange } from '../../utils/functions';
import { ScheduledTaskService } from '../../../tasks/scheduled-tasks/scheduled-task.service';
import { RRuleDateRange } from '../../shared.interface';

@Component({
  selector: 'ease-task-scheduled-dates',
  templateUrl: './task-scheduled-dates-popover.component.html',
  styleUrls: ['./task-scheduled-dates-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskScheduledDatesPopoverComponent implements OnInit {
  @Input() taskId: string;
  @Input() redHeader: boolean;

  public scheduledDates: RRuleDateRange[];
  public hasEmptyRRule: boolean = false;

  constructor(
    private scheduledTaskService: ScheduledTaskService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.viewDates();
  }

  async viewDates() {
    const rrule = await this.getRRule(this.taskId);

    if (rrule) {
      this.scheduledDates = await RRuleToDateRange(rrule);
    } else {
      this.hasEmptyRRule = true;
    }

    this.cdr.detectChanges();
  }

  getRRule(id: string): Promise<string> {
    return firstValueFrom(
      this.scheduledTaskService.get(id).pipe(
        take(1),
        map(task => task.rrule)
      )
    );
  }
}
