<mat-card
  class="metrics-tab-container flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
  *ngIf="
    account.butMetric ||
      account.cplMetric ||
      account.comMetric ||
      account.cqMetric;
    else noAvailableMetrics
  "
>
  <mat-tab-group>
    <mat-tab *ngIf="account.butMetric">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <span class="desktop">Budget Utilization</span>
          <span class="mobile">BUT</span>
        </div>
      </ng-template>
      <ease-but-tab-content [account]="account"></ease-but-tab-content>
    </mat-tab>
    <mat-tab *ngIf="account.cplMetric">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <span class="desktop">Cost Per Lead</span>
          <span class="mobile">CPL</span>
        </div>
      </ng-template>
      <ease-cpl-tab-content
        [account]="account"
        (cplChanged)="cellChanged($event)"
      ></ease-cpl-tab-content>
    </mat-tab>
    <mat-tab *ngIf="account.comMetric">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <span class="desktop">Competitive Metric</span>
          <span class="mobile">COM</span>
        </div>
      </ng-template>
      <ease-com-tab-content [account]="account"></ease-com-tab-content>
    </mat-tab>
    <mat-tab *ngIf="account.cqMetric">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <span class="desktop">Call Quality</span>
          <span class="mobile">CQ</span>
        </div>
      </ng-template>
      <ease-cq-tab-content [account]="account"></ease-cq-tab-content>
    </mat-tab>
    <mat-tab *ngIf="account.resMetric">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <span class="desktop">Responsiveness</span>
          <span class="mobile">Res</span>
        </div>
      </ng-template>
      <ease-res-tab-content [account]="account"></ease-res-tab-content>
    </mat-tab>
    <mat-tab *ngIf="account.osMetric">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <span class="desktop">Optiscore</span>
          <span class="mobile">OS</span>
        </div>
      </ng-template>
      <ease-os-tab-content [account]="account"></ease-os-tab-content>
    </mat-tab>
  </mat-tab-group>
</mat-card>

<ng-template #noAvailableMetrics>
  <mat-card
    class="metrics-tab-container flex flex-1 flex-col items-center justify-center overflow-y-auto overflow-x-hidden"
  >
    <div class="metric-note">No metrics available</div></mat-card
  >
</ng-template>
