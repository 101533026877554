import { Injectable } from '@angular/core';
import { Event, NavigationStart, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { WindowPane } from '../../window/window-pane/window-pane';
import { WindowService } from '../../window/window.service';
import {
  GridEntity,
  GridTarget,
  GridTargetingState
} from './grid-targeting.interface';

@Injectable({ providedIn: 'root' })
export class GridTargetingService {
  private activeWindow: WindowPane;
  private _data: GridTarget;
  private state$ = new BehaviorSubject<GridTargetingState>('READY');
  private routerEventsSub$: Subscription;
  private ENTITY_TABLE_ROUTES: Record<
    GridEntity,
    { fragments: string[]; queryParams?: Params }
  > = {
    prospect: {
      fragments: ['customers'],
      queryParams: { phase: 'prospect' }
    },
    customer: {
      fragments: ['customers'],
      queryParams: { phase: 'customer' }
    },
    account: {
      fragments: ['accounts']
    }
  };

  constructor(private router: Router, private windowService: WindowService) {}

  setData(data: GridTarget) {
    this._data = data;
  }

  getData(): GridTarget {
    return this._data;
  }

  setActiveWindow(windowPane: WindowPane) {
    this.activeWindow = windowPane;
  }

  getActiveWindow(): WindowPane {
    return this.activeWindow;
  }

  async create(data: GridTarget) {
    this.activeWindow = null;
    this._data = data;

    const newWindow = await this.windowService.create({ type: 'createTask' });
    this.setActiveWindow(newWindow as WindowPane<void>);

    this.setState('SAVED');
  }

  setState(state: GridTargetingState) {
    this.state$.next(state);
  }

  getState(): Observable<GridTargetingState> {
    return this.state$.asObservable();
  }

  modify(entity: GridEntity, data: GridTarget, windowPane: WindowPane) {
    this.setActiveWindow(windowPane);

    /**
     * Always force the active window to mimimize. The window manager
     * won't detect a route change in the case where the user is already
     * on the target's entity table prior to the modify action
     */
    this.activeWindow.minimize();

    this.setData(data);
    this.setState('MODIFYING');

    // navigate user to the correct entity table
    this.router.navigate(this.ENTITY_TABLE_ROUTES[entity].fragments, {
      queryParams: { ...this.ENTITY_TABLE_ROUTES[entity].queryParams }
    });

    this.startRouteGuard(entity);
  }

  /**
   * This actively checks the route to see if the user has navigated away
   * from the target entity tables
   *
   * @param entity
   */
  startRouteGuard(entity: GridEntity) {
    const route = this.ENTITY_TABLE_ROUTES[entity];
    const fragments = route.fragments;
    /**
     * This builds an array of URL parameter strings. Example:
     * ["phase=customer"]
     */
    const parameters = route.queryParams
      ? Object.keys(route.queryParams).map(
          key => `${key}=${route.queryParams[key]}`
        )
      : null;

    /**
     * This checks if all the URL fragments and parameters on a given
     * entity table are present on the destination URL. The fragments and
     * parameters are intentionally checked separately since it's possible
     * to have a window outlet in between the URL path and params.
     */
    this.routerEventsSub$ = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        const url = event.url;

        if (
          fragments.every(fragment => url.includes(fragment)) &&
          (!parameters ||
            parameters.every(parameter => url.includes(parameter)))
        ) {
          return;
        } else {
          this.cancel(true);
        }
      }
    });

    this.activeWindow.onClose.subscribe(() => {
      this.cancel(true);
    });
  }

  stopRouteGuard() {
    !this.routerEventsSub$.closed && this.routerEventsSub$.unsubscribe();
  }

  save(data: GridTarget) {
    this.setData(data);

    this.activeWindow.maximize();

    this.stopRouteGuard();

    this.setState('SAVED');
  }

  cancel(isLost = false) {
    this.setState(isLost ? 'LOST' : 'CANCELLED');

    this.stopRouteGuard();

    this.setData(null);

    /**
     * Re-open the active window when the modifying state was cancelled
     */
    !isLost && this.activeWindow.maximize();

    this.setActiveWindow(null);

    this.setState('READY');
  }
}
