import { NgModule } from '@angular/core';
import { SatPopoverModule } from '@ncstate/sat-popover';

import { SharedModule } from 'src/app/shared/shared.module';
import { CustomerTasksPopoverModule } from 'src/app/shared/popover/customer-tasks-popover/customer-tasks-popover.module';
import { DashboardPopoversModule } from '../dashboard-popovers/dashboard-popovers.module';
import { CustomerLsaSummaryModule } from '../../../customers/customer-accounts/customer-lsa-summary/customer-lsa-summary.module';
import { BudgetUtilizationCellComponent } from './budget-utilization-cell/budget-utilization-cell.component';
import { CompetitiveMetricCellComponent } from './competitive-metric-cell/competitive-metric-cell.component';
import { AlertCellComponent } from './alert-cell/alert-cell.component';
import { MoreActionsCellComponent } from './more-actions-cell/more-actions-cell.component';

import { MetricsModule } from './../../../shared/metrics/metrics.module';
import { CplMetricCellComponent } from './cpl-metric-cell/cpl-metric-cell.component';
import { CqMetricCellComponent } from './cq-metric-cell/cq-metric-cell.component';
import { ResMetricCellComponent } from './res-metric-cell/res-metric-cell.component';
import { OsMetricCellComponent } from './os-metric-cell/os-metric-cell.component';

@NgModule({
  declarations: [
    AlertCellComponent,
    BudgetUtilizationCellComponent,
    CompetitiveMetricCellComponent,
    CplMetricCellComponent,
    CqMetricCellComponent,
    MoreActionsCellComponent,
    ResMetricCellComponent,
    OsMetricCellComponent
  ],
  imports: [
    CustomerTasksPopoverModule,
    SharedModule,
    SatPopoverModule,
    DashboardPopoversModule,
    MetricsModule,
    CustomerLsaSummaryModule
  ],
  exports: [
    BudgetUtilizationCellComponent,
    CompetitiveMetricCellComponent,
    MoreActionsCellComponent
  ]
})
export class GridCellsModule {}
