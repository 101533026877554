import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import {
  AngularFireDatabaseModule,
  USE_EMULATOR as USE_DATABASE_EMULATOR
} from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import {
  AngularFireMessagingModule,
  SERVICE_WORKER
} from '@angular/fire/compat/messaging';
import {
  AngularFireFunctionsModule,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR
} from '@angular/fire/compat/functions';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { AvatarModule } from './shared/avatar/avatar.module';
import { EaseMaterialModule } from './shared/ease-material.module';
import { EaseErrorHandler } from './shared/error-handler';
import { BreakpointsModule } from './shared/breakpoints.module';
import { appRoutes } from './app.routes';
import { AppComponent } from './app.component';
import { LayoutModule } from './shared/layout/layout.module';
import { NotificationsModule } from './notifications/notifications.module';
import { VersionDialogModule } from './shared/version-dialog/version-dialog.module';
import { WindowModule } from './shared/window/window.module';
import { GridCellsModule } from './shared/grid-cells/grid-cells.module';
import { AdblockDetectorDialogModule } from './shared/adblock-detector-dialog/adblock-detector-dialog.module';
import { EnvironmentService } from './shared/environment.service';
import { ViewportOffsetModule } from './shared/viewport-offset/viewport-offset.module';
import { AnnouncementsModule } from './announcements/announcements.module';

export const initApp = (environmentService: EnvironmentService) => async () => {
  try {
    await environmentService.hasAdBlocker();
  } catch (err) {
    console.log('[Bootstrap] Could not initialize application', err);
    throw err;
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    AgGridModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireModule,
    AngularFireMessagingModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AnnouncementsModule,
    AvatarModule,
    appRoutes,
    BrowserModule,
    BrowserAnimationsModule,
    EaseMaterialModule,
    GridCellsModule,
    BreakpointsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    LayoutModule,
    NotificationsModule,
    VersionDialogModule,
    WindowModule,
    AdblockDetectorDialogModule,
    ViewportOffsetModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [EnvironmentService],
      multi: true
    },
    {
      provide: SERVICE_WORKER,
      useFactory: (environmentService: EnvironmentService) =>
        firstValueFrom(environmentService.swRegistration$),
      deps: [EnvironmentService]
    },
    {
      provide: ErrorHandler,
      useClass: EaseErrorHandler
    },
    ...(environment.useEmulators
      ? [
          { provide: USE_DATABASE_EMULATOR, useValue: ['localhost', 9000] },
          { provide: USE_FUNCTIONS_EMULATOR, useValue: ['localhost', 5001] }
        ]
      : []),
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 250,
        disableTooltipInteractivity: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
