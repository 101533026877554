import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SkSyncHttpService } from '../shared/sksync-http.service';
import {
  AccountsTableRow,
  AdwordsManagerLinkAccount,
  AdwordsManagerLinkRequest
} from './accounts.interface';

@Injectable({ providedIn: 'root' })
export class AccountsService {
  constructor(private http: SkSyncHttpService) {}

  searchAll(filters: any = {}): Observable<AccountsTableRow[]> {
    return this.http.post(`/accounts/search`, filters);
  }

  /**
   * Get a list of manager accounts that can be linked-to
   *
   * @returns A list of link-able manager accounts
   */
  getAdwordsManagerLinkAccounts(): Observable<AdwordsManagerLinkAccount[]> {
    return this.http.get(`/adwords/managerLinks/managers`);
  }

  /**
   * Retrieve a list of pending manager link requests
   *
   * @param cacheBust Whether to pull a fresh resultset from the server
   * @returns A list of pending manager link requests across all manager accounts
   */
  getAdwordsManagerLinkRequests(
    cacheBust: boolean = false
  ): Observable<AdwordsManagerLinkRequest[]> {
    return this.http.get(`/adwords/managerLinks`, null, {
      cacheBust
    });
  }

  /**
   * Create a link request between a manager and a child account
   *
   * @param accountId Account ID to request access to
   * @param managerId Manager account to link the child account to
   */
  createAdwordsManagerLinkRequest(
    accountId: string,
    managerId: string
  ): Observable<void> {
    return this.http.post(`/adwords/managerLinks`, {
      accountId,
      managerId
    });
  }

  /**
   * Cancels a pending manager link request
   *
   * @param managerId Manager account ID that owns the link request
   * @param resourceName Resource name of the link request to remove
   * @param displayError Whether to display an error snackbar if the request fails
   */
  removeAdwordsManagerLinkRequest(
    managerId: string,
    resourceName: string,
    displayError = false
  ): Observable<void> {
    return this.http.delete(
      `/adwords/managerLinks`,
      {
        body: {
          manager_id: managerId,
          resource_name: resourceName
        }
      },
      {
        displayError
      }
    );
  }
}
