<ease-sheet class="flex flex-1 flex-col">
  <ease-sheet-content
    size="md"
    class="flex-1 overflow-y-auto overflow-x-hidden"
  >
    <ease-create-announcement-meta
      [form]="metaFormGroup"
    ></ease-create-announcement-meta>
  </ease-sheet-content>
  <ease-sheet-content class="bg-gray-100">
    <ease-bulk-targeting
      [formControl]="targetControl"
      title="Target Audience"
      emptyMessage="No target audience yet"
      [targetLevels]="['company', 'user']"
    ></ease-bulk-targeting>
  </ease-sheet-content>
  <ease-sheet-footer class="flex-none">
    <div class="flex-1"></div>
    <button easeWindowClose mat-button>Cancel</button>
    <button (click)="create()" [disabled]="announcementForm.invalid" mat-button>
      Create Announcement
    </button>
  </ease-sheet-footer>
</ease-sheet>
