import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { firstValueFrom, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { TypedFormGroup } from 'src/app/shared/reactive-forms';
import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import { ListDisplayItem } from '../../../shared/shared.interface';
import { CustomerFeedService } from '../../customer-feed/customer-feed.service';
import { CustomersService } from '../../customers.service';
import { CUSTOMER_COMMUNICATIONS_PATH } from '../../../shared/firebase-paths';
import { CustomerContactsService } from '../../customer-contacts/customer-contacts.service';

@UntilDestroy()
@Component({
  selector: 'ease-create-customer-communication',
  templateUrl: './create-customer-communication.component.html',
  styleUrls: ['./create-customer-communication.component.scss']
})
export class CreateCustomerCommunicationComponent implements OnInit {
  @HostBinding('class') hostClass = 'block max-w-[540px]';
  @Input()
  customerId: string;
  public contacts$: Observable<ListDisplayItem[]>;
  public communicationForm: TypedFormGroup<{
    customer: string;
    contactId: string;
    contactName: string;
    type: 'phone' | 'email';
    body: string;
    nextCommunicationAt: number;
  }>;
  public hasDateBeenBumped: boolean = false;
  public now = moment().startOf('day');
  public isWorking: boolean = false;

  constructor(
    private customerContactsService: CustomerContactsService,
    private customerFeedService: CustomerFeedService,
    private customerService: CustomersService,
    public dialogRef: MatDialogRef<CreateCustomerCommunicationComponent>,
    private entityUtilsService: EntityUtilsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.communicationForm = this.formBuilder.group({
      customer: this.formBuilder.control(this.customerId, {
        validators: Validators.required
      }),
      contactId: this.formBuilder.control(null, {
        validators: Validators.required
      }),
      contactName: this.formBuilder.control(null, {
        validators: Validators.required
      }),
      type: this.formBuilder.control<'phone' | 'email'>('phone', {
        validators: Validators.required
      }),
      body: this.formBuilder.control(null, { validators: Validators.required }),
      nextCommunicationAt: this.formBuilder.control(null, {
        validators: [Validators.required, this.isBeforeNow()]
      })
    });

    this.customerService
      .getNextCommunicationAt(this.customerId)
      .pipe(untilDestroyed(this))
      .subscribe(timestamp => this.setNextCommunicationAtForView(timestamp));

    this.contacts$ = this.customerContactsService.get(this.customerId).pipe(
      map(contacts =>
        contacts.map(contact => ({
          value: contact.$key,
          viewValue: `${contact.firstName} ${contact.lastName}`
        }))
      ),
      take(1)
    );

    this.communicationForm.controls.contactId.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(async contactId => {
        const contacts = await firstValueFrom(this.contacts$);
        const contact = contacts.find(
          currContact => currContact.value === contactId
        );
        this.communicationForm.controls.contactName.setValue(
          contact ? contact.viewValue : 'Other'
        );
      });
  }

  isBeforeNow(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      moment(control.value).isBefore(this.now)
        ? { invalidDate: { message: 'Please choose any date after today' } }
        : null;
  }

  setNextCommunicationAtForView(timestamp: number) {
    let viewValue = null;
    // If the current communication date is already past, then auto-bump it to 30 days from today.
    if (timestamp && timestamp > this.now.valueOf()) {
      this.hasDateBeenBumped = false;
      viewValue = moment(timestamp).valueOf();
    } else {
      this.hasDateBeenBumped = true;
      viewValue = moment(this.now).add(30, 'days').valueOf();
    }
    this.communicationForm.controls.nextCommunicationAt.patchValue(viewValue);
  }

  async createCommunication(formValue, isValid: boolean) {
    if (isValid) {
      this.isWorking = true;
      const promises = [];
      promises.push(
        this.customerFeedService.createItem(formValue, {
          entityType: 'customer',
          entityName: await this.entityUtilsService.getEntityField(
            { entityType: 'customer', entityId: this.customerId },
            'name'
          ),
          entityId: this.customerId,
          endpoint: CUSTOMER_COMMUNICATIONS_PATH
        }),
        this.customerService.setCommunicationAt(this.customerId, {
          lastCommunicationAt: this.now.valueOf(),
          nextCommunicationAt: formValue.nextCommunicationAt
            ? formValue.nextCommunicationAt
            : null
        })
      );

      Promise.all(promises)
        .then(() =>
          this.dialogRef.close({
            lastCommunicationAt: this.now.valueOf(),
            nextCommunicationAt: formValue.nextCommunicationAt
          })
        )
        .catch(e => (this.isWorking = false));
    }
  }
}
