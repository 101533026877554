import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';

import { SharedModule } from 'src/app/shared/shared.module';
import { MarkdownModule } from '../shared/markdown/markdown.module';
import { AnnouncementsPopoverModule } from '../shared/popover/announcements/announcements-popover.module';
import { PopoverModule } from '../shared/popover/popover.module';
import { SharedPipesModule } from '../shared/shared-pipes.module';
import { ViewportOffsetModule } from '../shared/viewport-offset/viewport-offset.module';

import { AnnouncementsBarComponent } from './announcements-bar/announcements-bar.component';
import { AnnouncementsDetailComponent } from './announcements-detail/announcements-detail.component';
import { AnnouncementsPanelComponent } from './announcements-panel/announcements-panel.component';
import { AnnouncementsTargetComponent } from './announcements-target/announcements-target.component';
import { AnnouncementsMarkedByComponent } from './announcements-marked-by/announcements-marked-by.component';

@NgModule({
  imports: [
    AnnouncementsPopoverModule,
    CommonModule,
    MarkdownModule,
    MatBadgeModule,
    PopoverModule,
    SharedModule,
    SharedPipesModule,
    ViewportOffsetModule
  ],
  declarations: [
    AnnouncementsBarComponent,
    AnnouncementsDetailComponent,
    AnnouncementsMarkedByComponent,
    AnnouncementsPanelComponent,
    AnnouncementsTargetComponent
  ],
  exports: [
    AnnouncementsBarComponent,
    AnnouncementsDetailComponent,
    AnnouncementsMarkedByComponent,
    AnnouncementsPanelComponent,
    AnnouncementsTargetComponent
  ]
})
export class AnnouncementsModule {}
