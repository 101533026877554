import {
  combineLatest as observableCombineLatest,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject,
  Subject
} from 'rxjs';

import { Injectable } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { map, switchMap } from 'rxjs/operators';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';

import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import {
  FeedItem,
  FeedItemCreationMeta,
  FeedItemMeta
} from '../../shared/feed-items/feed-item.interface';
import { FeedItemService } from '../../shared/feed-items/feed-item.service';
import { FeedItemChange } from '../../shared/feed-items/feed-item/feed-item.component';
import {
  CUSTOMER_COMBINED_FEEDS_PATH,
  CUSTOMER_NOTES_PATH,
  CUSTOMER_STICKY_NOTES_PATH
} from '../../shared/firebase-paths';
import { TypesenseCustomerFeedFieldList } from '../../shared/search/search.interface';
import { SearchService } from '../../shared/search/search.service';
import { firebaseJSON } from '../../shared/utils/functions';
import { UserService } from '../../users/user.service';
import { SearchUtils } from '../../shared/search/search-utils';
import {
  CustomerFeedViewingEntity,
  CustomerStickyNote
} from './customer-feed.interface';

@Injectable({ providedIn: 'root' })
export class CustomerFeedService {
  private feedSource$: Subject<TypesenseCustomerFeedFieldList[]> = new Subject<
    TypesenseCustomerFeedFieldList[]
  >();
  private loadingSource$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private currentPage = 1;
  private totalPages = 1;
  private _currentQuery: string;
  private _currentViewingEntity: CustomerFeedViewingEntity;
  private _currentFilters: string;
  private _currentItems: TypesenseCustomerFeedFieldList[] = [];
  private _lastTypesenseDocumentId: string;
  public drawerToggled$: Subject<void> = new Subject<void>();
  private currentActivityFilters: string[];
  public set currentQuery(query: string) {
    this._currentQuery = query;
    this.currentPage = 1;
    this.updateTypesenseFeed();
  }

  /**
   * Updates Typesense with the selected activity filters
   * for account/customer feeds.
   *
   * @param currentActivityFilters: The current selected activity filters
   */
  public set itemType(currentActivityFilters: string[]) {
    this.currentActivityFilters = currentActivityFilters;
    if (this._currentFilters) {
      this.updateCurrentFilters();
      this.updateTypesenseFeed();
    }
  }

  /**
   * Creates the search query for Typesense with the active
   * filters and the current customer/account
   *
   */
  updateCurrentFilters() {
    const currentViewingEntity: CustomerFeedViewingEntity =
      this._currentViewingEntity;

    this.resetFeed();
    const hasActivityFilters = !!this.currentActivityFilters?.length;

    if (currentViewingEntity.account) {
      this._currentFilters = `${SearchUtils.exactEqualTo(
        'entityId',
        currentViewingEntity.account.accountId
      )} && ${SearchUtils.exactEqualTo(
        'accountType',
        currentViewingEntity.account.accountType
      )}`;

      if (hasActivityFilters) {
        this._currentFilters += ` && ${SearchUtils.in(
          'itemType',
          this.currentActivityFilters
        )}`;
      }
    } else {
      this._currentFilters = SearchUtils.exactEqualTo(
        'customerId',
        currentViewingEntity.customerId
      );

      if (hasActivityFilters) {
        this._currentFilters += ` && ${SearchUtils.in(
          'itemType',
          this.currentActivityFilters
        )}`;
      }
    }
  }

  public set viewingEntity(viewingEntity: CustomerFeedViewingEntity) {
    this.resetFeed();
    this._currentViewingEntity = viewingEntity;

    this.updateCurrentFilters();
    this.updateTypesenseFeed();
  }
  public feed$ = this.feedSource$.asObservable();
  public liveFeed$: Observable<FeedItem[]> = of([]);
  public loading$ = this.loadingSource$.asObservable();
  public hasMoreItems: boolean = true;

  constructor(
    private angularFire: FirebaseDbService,
    private entityUtilsService: EntityUtilsService,
    private feedItemService: FeedItemService,
    private searchService: SearchService,
    private userService: UserService
  ) {}

  getLiveFeed(basePath: string) {
    const baseList = this._lastTypesenseDocumentId
      ? this.angularFire.getList(basePath, ref =>
          ref.orderByKey().startAfter(this._lastTypesenseDocumentId)
        )
      : this.angularFire.getList(basePath);

    return baseList.pipe(
      map((itemsMeta: FeedItemMeta[]) => {
        const excludingFirstItem = itemsMeta.filter(
          meta => meta.$key !== this._lastTypesenseDocumentId
        );
        return orderBy(excludingFirstItem, ['createdAt'], ['desc']);
      }),
      map((itemsMeta: FeedItemMeta[]) =>
        this.feedItemService.getAll(itemsMeta)
      ),
      switchMap(data =>
        data && data.length ? observableCombineLatest(data) : of([])
      )
    );
  }

  async updateTypesenseFeed(append = false): Promise<void> {
    this.loadingSource$.next(true);
    return this.searchService
      .create<TypesenseCustomerFeedFieldList>('customerFeeds', {
        q: this._currentQuery,
        filter_by: this._currentFilters,
        sort_by: SearchUtils.equalTo('createdAt', 'desc'),
        page: this.currentPage,
        per_page: 50,
        highlight_fields: ['name', 'completionMessage', 'entityName'].join(','),
        highlight_start_tag: '<span class="highlight">',
        highlight_end_tag: '</span>'
      })
      .then(response => {
        this.currentPage = response.page;
        this.totalPages = this.searchService.getPageCount(response);
        this.hasMoreItems = this.checkHasMoreItems(response);
        return response.hits;
      })
      .then(hits => {
        this._currentItems = append
          ? [...this._currentItems, ...hits.map(hit => hit.document)]
          : [...hits.map(hit => hit.document)];

        this.feedSource$.next(this._currentItems);
        this._lastTypesenseDocumentId = this._currentItems[0]
          ? this._currentItems[0].$key
          : null;

        if (this._currentViewingEntity.account) {
          this.liveFeed$ = this.getLiveFeed(
            `/${this.feedItemService.getFeedPathForAccountType(
              this._currentViewingEntity.account.accountType
            )}/${this._currentViewingEntity.account.accountId}`
          );
        } else {
          this.liveFeed$ = this.getLiveFeed(
            `/${CUSTOMER_COMBINED_FEEDS_PATH}/${this._currentViewingEntity.customerId}`
          );
        }

        this.loadingSource$.next(false);
      });
  }

  loadMore() {
    if (this.currentPage + 1 <= this.totalPages) {
      this.currentPage++;
      this.updateTypesenseFeed(true);
    }
  }

  resetFeed() {
    this._currentItems = [];
    this._currentQuery = '';
    this.currentPage = 1;
  }

  checkHasMoreItems(
    response: SearchResponse<TypesenseCustomerFeedFieldList>
  ): boolean {
    const pageCount = this.searchService.getPageCount(response);

    if (pageCount === 0) {
      return false;
    }
    if (response.page <= pageCount - 1) {
      return true;
    }

    return false;
  }

  async createItem(
    item: any,
    creationMeta: FeedItemCreationMeta
  ): Promise<Partial<FeedItem>> {
    if (creationMeta.endpoint && item) {
      const createdAt = await this.angularFire.getServerTimestamp();

      item.createdBy = this.userService.currentUser.$key;
      item.createdAt = createdAt;

      const pushedMetaRef = await this.angularFire
        .list(`/${creationMeta.endpoint}/${creationMeta.entityId}`)
        .push(firebaseJSON(item));
      item.$key = pushedMetaRef.key;

      return this.feedItemService.create(item, creationMeta);
    }
  }

  updateItem(feedChange: FeedItemChange) {
    const itemId = feedChange.item.itemId || feedChange.item.$key;

    feedChange.item.endpoint === 'tasksCompleted'
      ? this.angularFire
          .object(`/${feedChange.item.endpoint}/${itemId}`)
          .update(feedChange.change)
      : this.angularFire
          .object(
            `/${feedChange.item.endpoint}/${feedChange.item.entityId}/${itemId}`
          )
          .update(feedChange.change);
  }

  async addNote(customerId: string, body: string): Promise<Partial<FeedItem>> {
    return this.createItem(
      { body },
      {
        endpoint: CUSTOMER_NOTES_PATH,
        customerId,
        entityId: customerId,
        entityName: await this.entityUtilsService.getEntityField(
          {
            entityType: 'customer',
            entityId: customerId
          },
          'name'
        ),
        entityType: 'customer'
      }
    );
  }

  getStickyNotes(customerId: string): Observable<CustomerStickyNote[]> {
    return this.angularFire.getList(
      `/${CUSTOMER_STICKY_NOTES_PATH}/${customerId}`,
      ref => ref.orderByChild('createdAtReversed')
    );
  }

  async addStickyNote(customerId: string, body: string): Promise<any> {
    const timestamp = await this.angularFire.getServerTimestamp();

    return this.angularFire
      .list(`/${CUSTOMER_STICKY_NOTES_PATH}/${customerId}`)
      .push({
        body,
        createdAt: timestamp,
        createdAtReversed: timestamp * -1,
        createdBy: this.userService.currentUser.$key,
        createdByName: this.userService.currentUser.name
      });
  }

  removeStickyNote(customerId: string, noteId: string): Promise<any> {
    return this.angularFire
      .object(`/${CUSTOMER_STICKY_NOTES_PATH}/${customerId}/${noteId}`)
      .remove();
  }

  moveAccount(
    sourceCustomerId: string,
    destinationCustomerId: string,
    accountId: string
  ): Promise<any> {
    return firstValueFrom(
      this.angularFire.getList(
        `/${CUSTOMER_COMBINED_FEEDS_PATH}/${sourceCustomerId}`,
        ref => ref.orderByChild('entityId').equalTo(accountId)
      )
    ).then((feed: FeedItemMeta[]) =>
      Promise.all(
        feed.map(feedItem =>
          Promise.all([
            this.angularFire
              .object(
                `/${CUSTOMER_COMBINED_FEEDS_PATH}/${sourceCustomerId}/${feedItem.$key}`
              )
              .remove(),
            this.angularFire
              .object(
                `/${CUSTOMER_COMBINED_FEEDS_PATH}/${destinationCustomerId}/${feedItem.$key}`
              )
              .set(firebaseJSON(feedItem))
          ])
        )
      )
    );
  }

  mergeCustomers(
    sourceCustomerId: string,
    destinationCustomerId: string
  ): Promise<any> {
    return firstValueFrom(
      this.angularFire.getObject(
        `/${CUSTOMER_COMBINED_FEEDS_PATH}/${sourceCustomerId}`
      )
    )
      .then(feed => {
        if (feed.$exists()) {
          return Object.keys(feed).reduce((acc, feedItemKey) => {
            const feedItem = feed[feedItemKey];

            if (
              feedItem.entityType === 'customer' ||
              feedItem.entityType === 'prospect'
            ) {
              feedItem.entityId = destinationCustomerId;
            }

            acc[feedItemKey] = feedItem;
            return acc;
          }, {});
        } else {
          return {};
        }
      })
      .then(newFeedItems =>
        this.angularFire
          .object(`/${CUSTOMER_COMBINED_FEEDS_PATH}/${destinationCustomerId}`)
          .update(firebaseJSON(newFeedItems))
      )
      .then(() =>
        this.angularFire
          .object(`/${CUSTOMER_COMBINED_FEEDS_PATH}/${sourceCustomerId}`)
          .remove()
      );
  }
}
