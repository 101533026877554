import { fromEvent as observableFromEvent, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';
import compact from 'lodash-es/compact';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';

import { filterObjectsList } from '../utils/functions';
import { Key } from '../key-codes';

@Component({
  selector: 'ease-list-selector',
  templateUrl: './list-selector.component.html',
  styleUrls: ['./list-selector.component.scss']
})
export class ListSelectorComponent
  implements AfterViewInit, OnChanges, OnDestroy, OnInit
{
  @HostBinding('class') hostClass = 'block';
  @ContentChild(TemplateRef)
  template: any;
  @Input()
  clearInputOnSelect: boolean = false;
  @Input()
  compareKey: string = '$key';
  @Input()
  showFilter: boolean = true;
  @Input()
  set items(items: any[]) {
    this._items = items || [];
    this.cachedItems = items || [];

    if (this.initialized) {
      this.filterAndSortItems();
    }
  }

  get items(): any[] {
    return this._items;
  }

  @Input()
  maxHeight: number = 300;
  @Input()
  placeholder: string = 'Search...';
  @Input()
  set selectIcon(icon: string) {
    this._selectIcon = icon;
  }
  get selectIcon() {
    return this._selectIcon || 'check';
  }
  @Input()
  singleSelect: boolean = false;
  @Input()
  sortSelectedFirst: boolean = true;
  @Input()
  sortFields: any[] = ['name'];
  @Input()
  sortFieldsOrders: string[] = ['asc'];
  @Input()
  set selectedItems(items: any[]) {
    this._selectedItems = items ? compact(items) : [];
    this._selectedItemsMap = this._selectedItems.reduce((acc, itemId) => {
      acc[itemId] = true;
      return acc;
    }, {});

    if (this.initialized) {
      this.filterAndSortItems();
    }
  }

  get selectedItems(): any[] {
    return this._selectedItems;
  }

  @Input()
  searchFields: string[] = ['name'];
  @Input()
  set externalFilter(filterValue: string) {
    this.itemFilterControl.patchValue(filterValue);
  }
  @Output()
  selected: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  deselected: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('itemFilter', { read: MatInput })
  itemFilter: MatInput;
  public selectedItemIndex: number;
  public itemFilterControl: FormControl<string> = new FormControl();
  public _selectedItemsMap: { [index: string]: boolean };
  public _selectIcon: string;
  private cachedItems: string[] = [];
  private inputSubscription: Subscription;
  private keySubscription: Subscription;
  private selectedSortAdded: boolean = false;
  private _items: any[] = [];
  private _selectedItems: any[] = [];
  private initialized: boolean = false;

  constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef) {}

  ngOnInit() {
    this.keySubscription = observableFromEvent(
      this.elementRef.nativeElement,
      'keydown'
    )
      .pipe(filter((event: KeyboardEvent) => this.isHandledInput(event)))
      .subscribe((event: KeyboardEvent) => this.handleValidInput(event));

    this.inputSubscription = this.itemFilterControl.valueChanges
      .pipe(
        tap(filterValue =>
          filterValue && filterValue.length
            ? this.setInitialSelection()
            : this.resetSelection()
        ),
        tap(query => this.filterAndSortItems(query))
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.initialized = true;
    this.filterAndSortItems();
    setTimeout(() => this.focusInput());
  }

  focusInput() {
    this.itemFilter && this.itemFilter.focus();
  }

  clearInput() {
    this.itemFilterControl.setValue('');
  }

  isSelected(itemId: string): boolean {
    if (!itemId) {
      return false;
    }

    return this.selectedItems.indexOf(itemId) > -1;
  }

  sortSelected = (item: any) => this.isSelected(item[this.compareKey]);

  filterAndSortItems(query?: string) {
    if (query) {
      this._items = this.applySorts(
        filterObjectsList(query, this.cachedItems, this.searchFields)
      );
    } else {
      this._items = this.applySorts(this.cachedItems);
    }
  }

  selectItem(itemId: string) {
    if (this.singleSelect) {
      this.selectedItems.forEach(currSelectedItemId =>
        this.deselectItem(currSelectedItemId)
      );
      this.selectedItems = [itemId];
    } else {
      this.selectedItems.push(itemId);
    }

    this.selected.emit(itemId);
  }

  deselectItem(itemId: string, emitSingleSelect?: boolean) {
    const itemIndex = this.selectedItems.indexOf(itemId);
    if (itemIndex > -1) {
      this.selectedItems.splice(itemIndex, 1);
      this.deselected.emit(itemId);

      /**
       * If "single select" mode is enabled and
       * we deselect an item, we should emit the
       * empty selection to be handled accordingly
       */
      if (this.singleSelect && emitSingleSelect) {
        this.selected.emit(null);
      }
    }
  }

  toggleSelected(itemId: string) {
    if (this.isSelected(itemId)) {
      this.deselectItem(itemId, true);
    } else {
      this.selectItem(itemId);
    }

    if (this.clearInputOnSelect) {
      this.clearInput();
    }
  }

  private resetSelection() {
    this.selectedItemIndex = null;
  }

  private setInitialSelection() {
    this.selectedItemIndex = 0;
  }

  private isHandledInput(event: KeyboardEvent): boolean {
    return (
      event.key === Key.UP_ARROW ||
      event.key === Key.DOWN_ARROW ||
      event.key === Key.ENTER
    );
  }

  private handleValidInput(event: KeyboardEvent) {
    switch (event.key) {
      case Key.DOWN_ARROW:
        this.handleNextArrow();
        break;
      case Key.UP_ARROW:
        this.handlePreviousArrow();
        break;
      case Key.ENTER:
        if (
          this.items &&
          this.items[this.selectedItemIndex] &&
          this.items[this.selectedItemIndex][this.compareKey]
        ) {
          const highlightedItem =
            this.items[this.selectedItemIndex][this.compareKey];
          this.toggleSelected(highlightedItem);
        }
        break;
      default:
        break;
    }

    event.stopPropagation();
    this.cdr.markForCheck();
  }

  private handlePreviousArrow() {
    if (this.selectedItemIndex === null) {
      this.goToBottom();
      return false;
    }
    this.goToPrevious();
  }

  private handleNextArrow() {
    // Initialize to zero if first time results are shown
    if (this.selectedItemIndex === null) {
      this.goToTop();
      return false;
    }
    this.goToNext();
  }

  private goToTop() {
    this.selectedItemIndex = 0;
    this.ensureHighlightVisible();
  }

  private goToBottom() {
    this.selectedItemIndex = this.itemsCount - 1;
    this.ensureHighlightVisible();
  }

  private goToNext() {
    if (this.selectedItemIndex + 1 < this.itemsCount) {
      this.selectedItemIndex = this.selectedItemIndex + 1;
    } else {
      this.goToTop();
    }
    this.ensureHighlightVisible();
  }

  private goToPrevious() {
    if (this.selectedItemIndex - 1 >= 0) {
      this.selectedItemIndex = this.selectedItemIndex - 1;
    } else {
      this.goToBottom();
    }
    this.ensureHighlightVisible();
  }

  private get itemsCount(): number {
    return this.items ? this.items.length : 0;
  }

  private ensureHighlightVisible() {
    const container = this.elementRef.nativeElement.querySelector(
      '.list-selector__items'
    );
    if (!container) {
      return;
    }
    const choices = container.querySelectorAll('.list-selector__item');
    if (choices.length < 1) {
      return;
    }
    if (this.selectedItemIndex < 0) {
      return;
    }
    const highlighted: any = choices[this.selectedItemIndex];
    if (!highlighted) {
      return;
    }
    const posY: number =
      highlighted.offsetTop + highlighted.clientHeight - container.scrollTop;
    const height: number = container.offsetHeight;

    if (posY >= height) {
      container.scrollTop += posY - height;
    } else if (posY <= highlighted.clientHeight) {
      container.scrollTop -= highlighted.clientHeight - posY;
    }
  }

  applySorts(itemsList: any[]) {
    return orderBy(itemsList, this.sortFields, this.sortFieldsOrders);
  }

  getKey = (index, currentValue) =>
    currentValue ? currentValue[this.compareKey] : index;

  ngOnChanges(changes: SimpleChanges) {
    if (this.sortSelectedFirst && !this.selectedSortAdded) {
      this.sortFields.unshift(this.sortSelected);
      this.sortFieldsOrders.unshift('desc');
      this.selectedSortAdded = true;
    }
  }

  ngOnDestroy() {
    this.keySubscription && this.keySubscription.unsubscribe();
    this.inputSubscription && this.inputSubscription.unsubscribe();
  }
}
