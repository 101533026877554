import { of } from 'rxjs';

import { map, subscribeOn, take } from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { asyncScheduler } from 'rxjs';
import { SatPopover } from '@ncstate/sat-popover';
import { asyncValidatorFactory } from '../../shared-validators';

import { COLOR_CHOICES } from '../../constants';
import { TaskPhase } from '../../../tasks/task-phase/task-phase.interface';
import { TaskPhaseSelectorComponent } from '../../../tasks/task-phase/task-phase-selector/task-phase-selector.component';
import { TaskPhaseService } from '../../../tasks/task-phase/task-phase.service';
import { AdminPermissionsService } from '../../../admin/admin-roles/admin-permissions.service';
import { TypedFormControl, TypedFormGroup } from '../../reactive-forms';

@Component({
  selector: 'ease-task-phase-popover',
  templateUrl: './task-phase-popover.component.html',
  styleUrls: ['./task-phase-popover.component.scss']
})
export class TaskPhasePopoverComponent implements OnInit {
  @Input()
  selectedPhase: string[] = [];
  @Output()
  selected: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('phaseSelector')
  phaseSelector: TaskPhaseSelectorComponent;
  public mode: string = 'init';
  public colorChoices: string[] = COLOR_CHOICES;
  public phaseForm: TypedFormGroup<TaskPhase>;
  private cachedPhase: TaskPhase;

  constructor(
    private formBuilder: FormBuilder,
    @Optional() private parentPopover: SatPopover,
    private taskPhaseService: TaskPhaseService,
    public permissionsService: AdminPermissionsService
  ) {}

  ngOnInit() {
    this.phaseForm = this.formBuilder.group<TypedFormControl<TaskPhase>>({
      $key: this.formBuilder.control(null),
      name: this.formBuilder.control(null, {
        validators: Validators.required,
        asyncValidators: [
          asyncValidatorFactory(newName => {
            /**
             * If we are editing an existing phase, check whether the new name is different than the existing one.
             * Only run the async name validator if the name has changed, otherwise return null (no errors for this field).
             */
            const key = this.phaseForm.controls.$key.value;

            if (!key || (key && newName !== this.cachedPhase.name)) {
              return this.taskPhaseService.validateName(newName).pipe(
                map((valid: boolean) =>
                  valid
                    ? null
                    : {
                        nameExists: 'A phase with this name already exists'
                      }
                )
              );
            } else {
              return of(null);
            }
          }),
          asyncValidatorFactory(() =>
            of(null).pipe(subscribeOn(asyncScheduler))
          )
        ]
      }),
      color: this.formBuilder.control(null, { validators: Validators.required })
    });
  }

  switchMode(mode: string) {
    this.mode = mode;
  }

  createPhase() {
    if (this.phaseForm.valid) {
      /**
       * If editing an existing phase, call update instead of create.
       */
      const key = this.phaseForm.controls.$key.value;

      if (key) {
        this.taskPhaseService.update(key, this.phaseForm.value).then(() => {
          this.resetView();
        });
      } else {
        this.taskPhaseService.create(this.phaseForm.value).then(() => {
          this.resetView();
        });
      }
    }
  }

  editPhase(phaseId: string) {
    this.switchMode('create');
    this.taskPhaseService
      .get(phaseId)
      .pipe(take(1))
      .subscribe(phase => {
        this.cachedPhase = Object.assign({}, phase, { $key: phase.$key });
        this.phaseForm.patchValue(this.cachedPhase);
      });
  }

  setPhaseColor(color: string) {
    this.phaseForm.controls.color.setValue(color);
  }

  onSelect(userId: string) {
    this.selected.emit(userId);
    this.parentPopover && this.parentPopover.close();
  }

  resetView() {
    this.switchMode('init');
    this.phaseForm.reset();
    setTimeout(() => this.phaseSelector.focusInput());
  }
}
