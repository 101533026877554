<ease-sheet
  class="popover popover-metric shadow-md"
  [ngClass]="budgetUtilization.ragStatusColor"
>
  <div class="header metric-background">
    <span class="description">
      {{ budgetUtilization.ragStatusDescription }}
    </span>
  </div>
  <div class="details">
    <div class="row detail flex flex-row">
      <div class="column detail flex-1">
        <ease-metric-simple
          [label]="'BU to Date'"
          [notes]="
            budgetUtilization.budgetUpdatedAt
              ? 'Since ' +
                (budgetUtilization.budgetUpdatedAt | amDateFormat: 'MMM D')
              : null
          "
          [tooltip]="
            'The amount of spend accrued in the account for the budget period, so far.'
          "
          [value]="
            budgetUtilization.butCostToDate | currency: 'USD':'symbol':'1.0-0'
          "
          [differenceNumeric]="
            budgetUtilization.butCostToDate - budgetUtilization.butTargetToDate
              | currency: 'USD':'symbol':'1.0-0'
          "
          [differencePercentage]="budgetUtilization.butPercentageToDate"
        ></ease-metric-simple>
      </div>

      <div class="column detail flex-1">
        <ease-metric-simple
          [label]="'Target BU to Date'"
          [tooltip]="
            'The amount of spend the account should have accrued for the budget period, so far. Computed based on the account\'s currently set monthly budget and the remaining days for the month.'
          "
          [value]="
            budgetUtilization.butTargetToDate | currency: 'USD':'symbol':'1.0-0'
          "
          [notes]="
            budgetUtilization.numBudgetDays &&
            budgetUtilization.numBudgetDays + ' day(s) included'
          "
        ></ease-metric-simple>
      </div>
    </div>

    <div
      class="bg-orange-500 text-center text-white"
      *ngIf="budgetUtilization.budgetUpdatedAt"
    >
      <span class="p-1 text-xs">
        Budget change occurred on
        <strong>{{
          budgetUtilization.budgetUpdatedAt | amDateFormat: 'MMM D'
        }}</strong
        >.
      </span>
    </div>

    <div class="row detail flex flex-row">
      <div class="column detail flex-1">
        <ease-metric-simple
          [label]="'Sugg. Budget'"
          [tooltip]="
            'Suggested daily budget to spend 100% of your budget by the end of the period'
          "
          [value]="
            budgetUtilization
              | butSuggestedBudget
              | currency: 'USD':'symbol':'1.0-0'
          "
        ></ease-metric-simple>
      </div>
      <div class="column detail flex-1">
        <ease-metric-simple
          [label]="'Projected Cost'"
          [tooltip]="'Projected cost at the end of the budget period'"
          [value]="
            budgetUtilization.projectedCost | currency: 'USD':'symbol':'1.0-0'
          "
        ></ease-metric-simple>
      </div>
      <div class="column detail flex-1">
        <ease-metric-simple
          [label]="'Monthly Budget'"
          [value]="budgetUtilization.budget | currency: 'USD':'symbol':'1.0-2'"
        ></ease-metric-simple>
        <div
          class="metric-note"
          *ngIf="
            accountBudget &&
            (accountBudget | number) !== (budgetUtilization.budget | number)
          "
        >
          New Budget (<strong>{{
            accountBudget | currency: 'USD':'symbol':'1.0-2'
          }}</strong
          >) will take 24h to be reflected in this calculation.
        </div>
      </div>
    </div>

    <div class="actions row flex flex-row">
      <button class="flex-1" mat-raised-button (click)="openBudgetManager()">
        <mat-icon class="mr-2">monetization_on</mat-icon>
        Open Budget Manager
      </button>
    </div>

    <div
      class="row detail"
      *ngIf="budgetUtilization?.metadata?.chartData.labels.length"
    >
      <ease-chart
        [height]="200"
        [data]="budgetUtilization?.metadata?.chartData"
        [type]="'line'"
        [options]="accountDashboardService.percentageChartOptions"
      >
      </ease-chart>
    </div>
  </div>
</ease-sheet>
