<ease-sheet class="shadow-md">
  <ng-container *ngIf="task?.chartData">
    <ease-chart
      [height]="200"
      [data]="task?.chartData"
      [type]="'line'"
      [options]="chartOptions"
    >
    </ease-chart>
  </ng-container>
  <ease-sheet-content
    class="flex flex-row flex-wrap items-center justify-center"
  >
    <ease-task-budget-trending
      [task]="task"
      *ngIf="task?.expectedSpend"
    ></ease-task-budget-trending>
    <ease-task-budget-basic
      [task]="task"
      *ngIf="!task?.expectedSpend"
    ></ease-task-budget-basic>
  </ease-sheet-content>
  <ng-container *ngIf="isAdjustingBudget">
    <ease-sheet-divider></ease-sheet-divider>
    <ease-customer-account-budget
      [summary]="costSummary"
      [settings]="budgetSettings$ | async"
      (cancel)="isAdjustingBudget = false"
      (save)="updateBudget($event); isAdjustingBudget = false"
    >
    </ease-customer-account-budget>
  </ng-container>
  <ease-sheet-footer *ngIf="!isAdjustingBudget">
    <button
      *ngIf="task.entityType === 'account' && !isAdjustingBudget"
      (click)="isAdjustingBudget = true"
      mat-button
    >
      Change Account Budget
    </button>
    <button (click)="openBudgetManager()" color="primary" mat-button>
      Open Budget Manager
    </button>
  </ease-sheet-footer>
</ease-sheet>
