<div
  class="color-blob relative mr-2 flex items-center whitespace-nowrap rounded-3xl px-2 font-medium text-white"
  [attr.style]="
    hollow
      ? null
      : 'background-color:' +
        (colorList[color] ? colorList[color] : color) +
        ' !important; border-color:' +
        (colorList[color] ? colorList[color] : color) +
        ' !important'
  "
  [ngClass]="{
    'hollow border border-solid border-gray-900 bg-transparent text-gray-900':
      hollow,
    'text-xs': size === 'xs' || size === 'xxs',
    'text-sm': size === 'sm',
    'py-2': size === 'md',
    'min-h-[2rem] px-3': size === 'lg',
    'min-w-[2rem] text-base ': size === 'md' || size === 'lg',
    'min-h-[0.8rem] min-w-[0.8rem] !p-[0]': size === 'sm',
    'min-h-[1.25rem] min-w-[1.25rem] ': size === 'xs' || size === 'md',
    '!m-0 min-h-[0.5rem] min-w-[0.5rem] !p-0': size === 'xxs',
    'ring-4 ring-blue-500 ring-opacity-30': selected,
    'text-black': color === 'yellow'
  }"
>
  <ng-content></ng-content>
</div>
