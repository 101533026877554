import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

import { TypedFormGroup } from 'src/app/shared/reactive-forms';
import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { UserService } from '../../../../users/user.service';
import {
  TaskChecklistCheckboxList,
  TaskChecklistTemplateFieldOptions
} from '../task-checklist.interface';

@Component({
  selector: 'ease-task-checklist-checkbox-list',
  templateUrl: './task-checklist-checkbox-list.component.html',
  styleUrls: ['./task-checklist-checkbox-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskChecklistCheckboxListComponent {
  @Input()
  templateOptions: TaskChecklistTemplateFieldOptions;
  @Input()
  group: TypedFormGroup<TaskChecklistCheckboxList>;
  /**
   * `value` input exists solely to support OnPush change
   * detection being triggered when the field value updates
   */
  @Input()
  value: any;
  @Output()
  actionClicked: EventEmitter<void> = new EventEmitter<void>();
  /**
   * When adding checkbox item from checklist manager, the
   * nested formGroup is not available yet before saving checklist.
   *
   * Use this as fallback(empty) group to avoid FormGroup instance error.
   */
  public emptyNestedCheckboxGroup: FormGroup<{
    checked: FormControl<boolean>;
  }> = this.formBuilder.group({
    checked: this.formBuilder.control(null)
  });

  constructor(
    private angularFire: FirebaseDbService,
    private userService: UserService,
    private formBuilder: FormBuilder
  ) {}

  async clickCheckbox(checkbox: MatCheckbox): Promise<void> {
    const completedAt = await this.angularFire.getServerTimestamp();

    setTimeout(() => {
      const checked = checkbox.checked;
      if (checked) {
        this.group.patchValue({
          checked,
          completedBy: this.userService.currentUser.name,
          completedAt
        });
      } else {
        this.group.patchValue({
          checked,
          completedBy: '',
          completedAt: null
        });
      }

      this.actionClicked.emit();
    });
  }
}
