<h3 class="capitalize">Matched {{ matched.numItems }} {{ matched.type }}:</h3>
<div *ngIf="matched.numItems > 5">
  <h4>The first 5 campaigns that met your requirements:</h4>
</div>
<ease-list-group size="sm">
  <ease-list-group-item
    *ngFor="let item of matched.items | objectToIterable"
    class="flex flex-row items-center justify-start"
  >
    {{ item.$value }}
  </ease-list-group-item>
</ease-list-group>
