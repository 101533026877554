<div class="wrapper" [style]="'min-height:' + minHeight + 'px;'">
  <ng-container *ngIf="message; else content">
    <mat-icon *ngIf="icon" class="mat-icon-18 mr-2">{{ icon }}</mat-icon>
    <p class="message">{{ message }}</p>
  </ng-container>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
