import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { WindowType } from '../window-types';
import { WindowService } from '../window.service';

@Component({
  selector: 'ease-window-route',
  template: '',
  styleUrls: ['./window-route.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowRouteComponent implements OnInit {
  constructor(
    private windowService: WindowService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      const type = paramMap.get('type') as WindowType;
      const data = paramMap.get('data');

      const pane = await this.windowService.create({
        type,
        data
      });
      this.windowService.setSelected(pane);
      pane.setActivatedRoute(this.activatedRoute);
    });
  }
}
