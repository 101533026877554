<div class="flex flex-row">
  <div class="vertical-selector flex-1">
    <mat-accordion class="w-full">
      <mat-expansion-panel *ngFor="let set of searchSets.controls">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="font-bold">
              {{ set.controls.name.value }}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="relative flex flex-row space-x-5">
          <div class="flex-1">
            <div>
              <div
                class="relative z-5 flex flex-row items-center space-x-2 font-bold"
              >
                <span>Final URL</span>
                <mat-icon
                  class="mat-icon-14 cursor-pointer text-gray-600"
                  *easeRichTooltip="finalUrlTooltip"
                  >help_outline</mat-icon
                >
              </div>
              <mat-form-field appearance="standard" class="-mt-4">
                <input
                  matInput
                  placeholder="https://"
                  [formControl]="set.controls.finalUrl"
                />
                <mat-error
                  easeFormErrors
                  [control]="set.controls.finalUrl"
                ></mat-error>
              </mat-form-field>
            </div>

            <div>
              <div
                class="relative z-5 flex flex-row items-center space-x-2 font-bold"
              >
                <span>Display path</span>
                <mat-icon
                  class="mat-icon-14 cursor-pointer text-gray-600"
                  *easeRichTooltip="displayUrlTooltip"
                  >help_outline</mat-icon
                >
              </div>

              <div class="flex flex-row items-center space-x-2">
                <span class="text-gray-600">
                  {{ set.controls.finalUrl.value || 'https://www.example.com' }}
                </span>
                <span>/</span>
                <mat-form-field appearance="standard" class="-mt-4">
                  <input matInput [formControl]="set.controls.path1" />
                  <mat-error
                    easeFormErrors
                    [control]="set.controls.path1"
                  ></mat-error>
                  <mat-hint align="end"
                    >{{ set.controls.path1.value?.length }} /
                    {{ pathMaxLength }}</mat-hint
                  >
                </mat-form-field>

                <span>/</span>

                <mat-form-field appearance="standard" class="-mt-4">
                  <input matInput [formControl]="set.controls.path2" />
                  <mat-error
                    easeFormErrors
                    [control]="set.controls.path2"
                  ></mat-error>
                  <mat-hint align="end"
                    >{{ set.controls.path2.value?.length }} /
                    {{ pathMaxLength }}</mat-hint
                  >
                </mat-form-field>
              </div>
            </div>
            <div
              class="relative z-5 flex flex-row items-center space-x-2 font-bold"
            >
              <span>Headlines</span>
              <mat-icon
                class="mat-icon-14 cursor-pointer text-gray-600"
                *easeRichTooltip="headlinesTooltip"
                >help_outline</mat-icon
              >
            </div>

            <mat-form-field
              appearance="standard"
              *ngFor="
                let headlineControl of set.controls.headlines.controls;
                let i = index
              "
              [ngClass]="{
                'pointer-events-none opacity-50':
                  headlineControl.controls.predefined.value
              }"
            >
              <mat-label>Headline {{ i + 1 }}</mat-label>
              <input
                matInput
                [placeholder]="
                  i + 1 === 8 ? 'Eg. Company Name' : 'Eg. Benefits'
                "
                [formControl]="headlineControl.controls.text"
                [readonly]="headlineControl.controls.predefined.value"
                [maxlength]="
                  headlineControl.controls.predefined.value ? null : 25
                "
              />
              <button
                mat-icon-button
                matSuffix
                (click)="onRemoveHeadline(set.controls.headlines, i)"
                *ngIf="!headlineControl.controls.predefined.value"
              >
                <mat-icon class="mat-icon-14">close</mat-icon>
              </button>
              <mat-hint
                align="end"
                *ngIf="!headlineControl.controls.predefined.value"
                >{{ headlineControl.controls.text.value.length }} / 25</mat-hint
              >
              <mat-error
                easeFormErrors
                [control]="headlineControl.controls.text"
              ></mat-error>
            </mat-form-field>

            <div class="flex flex-row items-center justify-between">
              <div
                easeFormErrors
                class="text-red-500"
                [control]="set.controls.headlines"
              ></div>

              <button
                mat-raised-button
                [disabled]="
                  set.controls.headlines.controls.length >=
                  searchHeadlinesMaxLength
                "
                (click)="onAddHeadline(set.controls.headlines)"
              >
                <mat-icon>add</mat-icon>
                Add a headline
              </button>
            </div>
          </div>

          <div class="w-5/12">
            <div class="sticky top-0">
              <div class="py-2 text-sm font-bold uppercase text-gray-500">
                {{ set.controls.name.value }} Campaign Preview
              </div>

              <ease-search-campaign-preview
                [set]="set"
              ></ease-search-campaign-preview>

              <div class="p-2 text-sm text-gray-600">
                This preview shows potential ads assembled using your assets.
                Not all combinations are shown. Assets can be shown in any
                order, so make sure that they make sense individually or in
                combination, and don't violate Google policies or local law.
                Some shortening may also occur in some formats.

                <br /><br />

                The headlines used for responsive ads will be used to create
                call out extensions. Make sure that are 25 characters maximum.
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<ng-template #finalUrlTooltip>
  <p>
    The final URL is the URL that people reach after clicking your ad. It should
    match what your ad promotes
  </p>
</ng-template>

<ng-template #displayUrlTooltip>
  <p>
    The "Path" fields are part of your display URL (the green text under your
    headline text) and can be up to {{ pathMaxLength }} characters each.
  </p>
  <p>
    To create your display URL, Google Ads will combine the domain (for example,
    "www.google.com" in www.google.com/nonprofits) from your final URL and the
    path text (for example, "nonprofits" in www.google.com/nonprofits). The
    display URL gives potential customers a clear idea of what webpage they'll
    reach once they click your ad, so your path text should describe your ad's
    landing page.
  </p>
</ng-template>

<ng-template #headlinesTooltip>
  <p>
    Enter 3 to 5 headlines. Headlines appear at the top of your ad and can be up
    to 30 characters.
  </p>
  <p>
    Your headlines and other parts of your ad (including extensions) may show in
    different configurations based on the device that a potential customer is
    using, or when Google Ads predicts that it may improve your performance.
  </p>
</ng-template>
