import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { TaskChecklistTemplateField } from '../task-checklist.interface';

@Pipe({
  name: 'getHideExpressionClass'
})
export class GetHideExpressionClassPipe implements PipeTransform {
  transform(
    hideExpression: TaskChecklistTemplateField['hideExpression'],
    formValue: any,
    previewMode: boolean
  ): string {
    if (formValue && hideExpression) {
      let finalCondition: boolean;

      Object.entries(hideExpression).forEach(([key, { state, condition }]) => {
        const result = !!formValue[key];
        const toReturn = !state ? !result : result;

        /**
         * Produce with each operator for final condition,
         * but excluding the initial `undefined` value
         */
        switch (condition) {
          case 'and':
            finalCondition = !isNil(finalCondition)
              ? finalCondition && toReturn
              : toReturn;
            break;
          case 'or':
            finalCondition = !isNil(finalCondition)
              ? finalCondition || toReturn
              : toReturn;
            break;
          default:
            throw new Error(`Unknown hide expression condition ${condition}`);
        }
      });

      if (!finalCondition) {
        return previewMode
          ? `relative opacity-50 before:absolute before:z-10 before:block before:h-full before:w-full before:content-['']`
          : 'hidden';
      }
    }

    return '';
  }
}
