import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ListDisplayItem, ListDisplayItemValue } from '../shared.interface';
import { AdvancedAutocompleteOption } from './advanced-autocomplete.interface';

export const optionFilter = (
  query: ListDisplayItemValue,
  options: ListDisplayItem[]
): ListDisplayItem[] => {
  if (typeof query === 'number') {
    query = query.toString();
  }

  const term = query.toLowerCase();

  return options.filter(option => {
    const viewValue = option.viewValue.toLowerCase();
    const viewSubvalue = option?.viewSubvalue?.toLowerCase();

    return (
      option.value === query ||
      viewValue.includes(term) ||
      viewSubvalue?.includes(term)
    );
  });
};

export const optionApplyMatcher = (
  currentValue: ListDisplayItemValue,
  options: ListDisplayItem[]
): ListDisplayItemValue => {
  let valueForViewMatch = currentValue;

  if (typeof currentValue === 'number') {
    valueForViewMatch = currentValue.toString();
  }

  return options.find(
    option =>
      option.value === currentValue || option.viewValue === valueForViewMatch
  )?.value;
};

export const optionDisplayMatcher = (
  selected: ListDisplayItemValue,
  options: ListDisplayItem[]
): string => options.find(option => option.value === selected)?.viewValue;

export const hasMatchedOption = (
  currentValue: ListDisplayItemValue,
  options: ListDisplayItem[]
): boolean => {
  let valueForViewMatch = currentValue;

  if (typeof currentValue === 'number') {
    valueForViewMatch = currentValue.toString();
  }

  return options.some(
    option =>
      option.value === currentValue || option.viewValue === valueForViewMatch
  );
};

export const suggestedItemValidator =
  (
    options: AdvancedAutocompleteOption<true | false>[],
    grouped: boolean
  ): ValidatorFn =>
  (control: AbstractControl) => {
    let match: boolean;
    const currentValue: ListDisplayItemValue = control.value;

    if (!currentValue || !options?.length) {
      return null;
    }

    // If the option list is empty, return not match message
    if (options.length === 0) {
      match = false;
    } else if (grouped) {
      for (const optionGroup of options as AdvancedAutocompleteOption<true>[]) {
        match = hasMatchedOption(currentValue, optionGroup.items);

        if (match) {
          break;
        }
      }
    } else {
      match = hasMatchedOption(
        currentValue,
        options as AdvancedAutocompleteOption<false>[]
      );
    }

    if (match) {
      return null;
    } else {
      return {
        autocompleteItem: {
          message: 'Value must be one of the suggested items.'
        }
      };
    }
  };
