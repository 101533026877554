import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  ChangeDetectorRef,
  ElementRef
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { combineLatest } from 'rxjs';
import scrollIntoView from 'scroll-into-view-if-needed';

import { Indexer, IndexerMeta, IndexerRelation } from '../indexer.interface';
import { IndexerService } from '../indexer.service';

@UntilDestroy()
@Component({
  selector: 'ease-indexer-view',
  templateUrl: './indexer-view.component.html',
  styleUrls: ['./indexer-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexerViewComponent implements OnInit {
  @HostBinding('class') hostClass =
    'flex items-center justify-center h-full mr-2';
  @Input() set indexer(indexer: Indexer) {
    this._indexer = indexer;

    if (indexer?.active) {
      this.indexerService.setActiveIndexer(indexer);
    }
  }

  get indexer() {
    return this._indexer;
  }

  @Input() set meta(meta: IndexerMeta) {
    if (meta?.relation) {
      this.indexerService.setRelation(meta.relation);
    }
  }

  private _indexer: Indexer;

  public activeIndex: number = null;
  public isParent: boolean;
  public isChild: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private element: ElementRef,
    public indexerService: IndexerService
  ) {}

  ngOnInit(): void {
    this.indexerService.activeIndexer$
      .pipe(untilDestroyed(this))
      .subscribe(indexer => {
        this.activeIndex = indexer.index;

        if (indexer?.scrollTo) {
          this.scrollToElement();
        }

        this.cdr.detectChanges();
      });

    combineLatest([
      this.indexerService.relation$,
      this.indexerService.activeIndexer$
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([relation]) => {
        this.setRelationHighlight(relation);
        this.cdr.detectChanges();
      });
  }

  setRelationHighlight(relation: IndexerRelation): void {
    if (relation) {
      this.isParent =
        relation[this.indexer.index] &&
        relation[this.indexer.index][this.activeIndex];

      this.isChild =
        relation[this.activeIndex] &&
        relation[this.activeIndex][this.indexer.index];
    }
  }

  scrollToElement(): void {
    if (this.indexer.index === this.activeIndex) {
      // Buffer a bit before indexer added/changed since it may not in the view yet
      setTimeout(() =>
        scrollIntoView(this.element.nativeElement, {
          behavior: 'smooth',
          block: 'center',
          // Limit scrolling within "body" to avoid extra white space created
          // when an item scrolled to center from a big overflow list
          boundary: document.body
        })
      );
    }
  }
}
