import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ease-task-ad-disapprovals',
  templateUrl: './task-ad-disapprovals.component.html',
  styleUrls: ['./task-ad-disapprovals.component.scss']
})
export class TaskAdDisapprovalsComponent implements OnInit {
  @Input()
  items: any[];

  constructor() {}

  ngOnInit() {}
}
