import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FundingService } from '../../funding.service';

@Component({
  selector: 'ease-funding-summary-transactions',
  templateUrl: './funding-summary-transactions.component.html',
  styleUrls: ['./funding-summary-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundingSummaryTransactionsComponent implements OnInit {
  public fundingManagementFee$: Observable<string>;

  constructor(
    public fundingService: FundingService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    this.fundingManagementFee$ = this.fundingService.fundingSettings$.pipe(
      map(settings =>
        settings && settings.managementFee
          ? `-${this.currencyPipe.transform(
              settings.managementFee,
              'USD',
              'symbol-narrow',
              '1.2-2'
            )} reserved for management fees`
          : null
      )
    );
  }
}
