<div class="flex flex-row items-center justify-end">
  <button mat-button (click)="collapseAll = !collapseAll">
    {{ collapseAll ? 'Expand' : 'Collapse' }} All
  </button>
</div>

<ng-container *ngIf="stage$ | async as stage; else loading">
  <ng-container *ngIf="stage.formData; else noData">
    <ng-container *ngFor="let formItem of stage.formData">
      <ease-customer-onboarding-form-renderer-item
        [formItem]="formItem"
        [collapse]="collapseAll"
      ></ease-customer-onboarding-form-renderer-item>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="flex flex-row items-center justify-center p-5 text-center">
    <mat-progress-spinner [diameter]="24" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<ng-template #noData>
  <p>No form submitted</p>
</ng-template>
