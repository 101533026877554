import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { map, Observable, ReplaySubject, shareReplay } from 'rxjs';

@Component({
  selector: 'ease-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit {
  @ViewChild('chart', { static: true }) chart: ElementRef;
  @Input() set data(data: ChartData) {
    if (data) {
      this.dataSource$.next(data);
    }
  }

  @Input()
  height: number = 300;
  @Input() options: ChartOptions<typeof this.type>;
  @Input() type: ChartType = 'line';
  @Input() width = '100%';

  private datasetColorMap: string[] = [
    '#FF6363',
    '#E7C54A',
    '#0086FF',
    '#16A34A',
    '#7E1FAB',
    '#c51162'
  ];
  private dataSource$: ReplaySubject<ChartData> =
    new ReplaySubject<ChartData>();
  public data$: Observable<ChartData<ChartType>> = this.dataSource$
    .asObservable()
    .pipe(
      map(data => this.generateBackgroundForData(data)),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  constructor() {}

  ngOnInit() {}

  private generateBackgroundForData(chartData: ChartData): ChartData {
    chartData.datasets = chartData.datasets.map((data, index) => ({
      ...data,
      backgroundColor: this.generateBgColor(index),
      borderColor: this.datasetColorMap[index],
      pointBorderColor: this.datasetColorMap[index],
      pointBackgroundColor: this.datasetColorMap[index]
    }));

    return chartData;
  }

  private generateBgColor(dataIndex: number): CanvasGradient {
    /**
     * If the dataset index is within the maximum index of colors
     * we have defined, use the index, otherwise we have more
     * data than we have colors defined, so randomly pick an index/color
     */
    const color =
      dataIndex <= this.datasetColorMap.length - 1
        ? this.datasetColorMap[dataIndex]
        : this.datasetColorMap[
            Math.floor(Math.random() * this.datasetColorMap.length)
          ];
    const canvas = this.chart.nativeElement as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(1, `${color}20`);
    gradient.addColorStop(0, `${color}`);

    return gradient;
  }
}
