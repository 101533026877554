<h1 mat-dialog-title>User Roles</h1>

<mat-dialog-content>
  <ease-list-group>
    <ease-list-group-header class="capitalize">
      <mat-icon class="mat-icon-18 mr-2">person_pin</mat-icon>
      Customer
    </ease-list-group-header>

    <ease-role-selector
      scope="customer"
      [currentRoles]="customerRoles$ | async"
      (roleChange)="setCustomerRole($event)"
    ></ease-role-selector>
  </ease-list-group>

  <ease-list-group *ngIf="accountRoles$ | async as accountRoles">
    <ease-list-group-header class="capitalize">
      <mat-icon class="mat-icon-18 mr-2">device_hub</mat-icon>
      Account
    </ease-list-group-header>
    <ease-role-selector
      scope="account"
      [currentRoles]="accountRoles"
      (roleChange)="setAccountRole($event)"
    >
    </ease-role-selector>
  </ease-list-group>
</mat-dialog-content>
