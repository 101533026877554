import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ease-subtask-help-popover',
  templateUrl: './subtask-help-popover.component.html',
  styleUrls: ['./subtask-help-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubtaskHelpPopoverComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
