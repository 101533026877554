import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ease-task-campaign-location-targeting',
  templateUrl: './task-campaign-location-targeting.component.html',
  styleUrls: ['./task-campaign-location-targeting.component.scss']
})
export class TaskCampaignLocationTargetingComponent implements OnInit {
  @Input()
  campaigns: any[];

  @Input()
  pausedCampaigns: any[];

  constructor() {}

  ngOnInit() {}
}
