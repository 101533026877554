import {
  Component,
  ChangeDetectionStrategy,
  AfterViewInit,
  QueryList,
  ContentChildren,
  HostBinding,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  ElementRef
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { isNil } from 'lodash-es';

import { VerticalTabComponent } from '../vertical-tab/vertical-tab.component';

@Component({
  selector: 'ease-vertical-tab-group',
  templateUrl: './vertical-tab-group.component.html',
  styleUrls: ['./vertical-tab-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerticalTabGroupComponent implements AfterViewInit {
  @HostBinding('class') class = 'flex flex-row';
  @ContentChildren(VerticalTabComponent)
  children: QueryList<VerticalTabComponent>;
  @ViewChild('tabNav') tabNav: ElementRef<any>;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  /**
   * Enables the toggling of a tabs active state
   */
  @Input() canToggleState: boolean = false;
  /**
   * Emits true if a tab where activated from a deactivated state.
   * Emits false when tab was deactivated.
   */
  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  public tabs: VerticalTabComponent[];
  /**
   * An extra flag so we can toggle which tabs are active without solely relying
   * to mat-tab-group
   */
  public activeTab: number;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.tabs = this.children.map(tab => tab);
  }

  onSelect(index: number): void {
    if (
      this.canToggleState &&
      !isNil(this.activeTab) &&
      this.activeTab === index
    ) {
      this.deactivate();
      return;
    }

    isNil(this.activeTab) && this.toggled.emit(true);
    this.activeTab = index;
    this.tabGroup.selectedIndex = index;
    this.cdr.detectChanges();
  }

  activate(): void {
    this.activeTab = 0;
    this.tabGroup.selectedIndex = 0;
    this.toggled.emit(true);
    this.cdr.detectChanges();
  }

  deactivate(): void {
    this.activeTab = null;
    this.toggled.emit(false);
    this.cdr.detectChanges();
  }
}
