import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { Account, OsMetric } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';

@Component({
  selector: 'ease-os-popover',
  templateUrl: './os-popover.component.html',
  styleUrls: ['./os-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OsPopoverComponent implements OnInit {
  @Input() account: Account;
  public optiscoreMetric: OsMetric;

  constructor(public accountDashboardService: AccountDashboardService) {}

  ngOnInit(): void {
    if (this.account && this.account.osMetric) {
      this.optiscoreMetric = this.account.osMetric;
    }
  }
}
