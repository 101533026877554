import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { omit } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  startWith,
  switchMap,
  tap
} from 'rxjs';
import {
  SearchParams,
  SearchResponse
} from 'typesense/lib/Typesense/Documents';

import { ContactModel } from '../../contacts/contact.model';
import { ContactService } from '../../contacts/contact.service';
import { AccountBasicMeta } from '../../customers/customer-accounts/customer-accounts.interface';
import { CustomerAccountsService } from '../../customers/customer-accounts/customer-accounts.service';
import { Customer } from '../../customers/customer.interface';
import { CustomersService } from '../../customers/customers.service';
import { TypedFormArray, TypedFormGroup } from '../reactive-forms';
import { SearchService } from '../search/search.service';
import { SharedLayoutService } from '../shared-layout.service';
import { DeepPartial, EntityMetadata } from '../shared.interface';
import { patchFormArray } from '../utils/functions';
import { FinderService } from './finder.service';

export interface FinderSettings {
  query: string;
  searchTypes: { name: string; value: boolean }[];
  includeOffline: boolean;
}

interface ResultsTypesenseSearchParams extends SearchParams {
  collection_name?: string;
}

interface ResultsTypesense<T> extends SearchResponse<T> {
  request_params: ResultsTypesenseSearchParams;
}

@UntilDestroy()
@Component({
  selector: 'ease-finder',
  templateUrl: './finder.component.html',
  styleUrls: ['./finder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FinderComponent,
      multi: true
    }
  ]
})
export class FinderComponent implements OnInit, ControlValueAccessor {
  @ViewChild('finder') finderInput: ElementRef;
  @ViewChild('autocomplete') autocomplete: MatAutocomplete;
  @Input()
  clearOnSelect: boolean = false;
  @Input()
  clearOnBlur: boolean = true;
  @Input() appearance: MatFormFieldAppearance = 'standard';
  @Input() placeholder: string = 'Search...';
  @Input() label: string;
  @Input()
  disabled: boolean = false;
  @Input()
  showSearchTypeFilter: boolean = false;
  @Input()
  set searchTypes(types: string[]) {
    this.searchTypesSource.next(types);
  }
  @Input()
  includeOffline: boolean = true;
  @Input()
  selectedResults: Record<string, boolean> = {};
  @Input()
  excludeFields: (keyof EntityMetadata)[] = [];
  @Input() showClearButton: boolean = true;
  @Input() set finderSettings(finderSettings: Partial<FinderSettings>) {
    if (this.finderForm) {
      this.setFinderSettings(finderSettings);
    }
    this._finderSettings = finderSettings;
  }

  get finderSettings(): Partial<FinderSettings> {
    return this._finderSettings;
  }

  private _finderSettings: Partial<FinderSettings>;

  @Output()
  selected: EventEmitter<EntityMetadata> = new EventEmitter<EntityMetadata>();
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() filterChanged: EventEmitter<DeepPartial<FinderSettings>> =
    new EventEmitter<DeepPartial<FinderSettings>>();
  public entityTypes: string[] = [
    'prospects',
    'customers',
    'accounts',
    'contacts'
  ];

  private toggleMobileFilters$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private toggleMobileFilters: boolean = false;
  public showMobileFilters$: Observable<boolean>;
  private searchTypesSource: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >(this.entityTypes);
  private incomingEntityMeta$: ReplaySubject<EntityMetadata> =
    new ReplaySubject<EntityMetadata>();

  public finderForm: TypedFormGroup<FinderSettings>;
  public results$: Observable<ResultsTypesense<any>[]>;
  public resultsExists: boolean;
  public searchTypes$: Observable<string[]> =
    this.searchTypesSource.asObservable();
  public showFinder = true;
  public selectedOption$: Observable<EntityMetadata>;
  private selectedOption: EntityMetadata;
  get searchTypesControls(): TypedFormArray<FinderSettings['searchTypes'][0]> {
    return this.finderForm.controls.searchTypes;
  }

  constructor(
    private formBuilder: FormBuilder,
    private finderService: FinderService,
    private customersService: CustomersService,
    private customerAccountsService: CustomerAccountsService,
    private contactsService: ContactService,
    private searchService: SearchService,
    private cdr: ChangeDetectorRef,
    private sharedLayoutService: SharedLayoutService
  ) {}

  ngOnInit(): void {
    this.showMobileFilters$ = combineLatest([
      this.sharedLayoutService.currentBreakpoint$,
      this.toggleMobileFilters$.asObservable()
    ]).pipe(
      map(([currentBreakpoint, toggle]) => {
        if (currentBreakpoint === 'ltSm') {
          this.toggleMobileFilters = toggle;
          return this.toggleMobileFilters;
        }
        return true;
      })
    );
    // Set up finder form
    this.finderForm = this.formBuilder.group<typeof this.finderForm.controls>({
      query: this.formBuilder.control({
        value: '',
        disabled: this.disabled
      }),
      searchTypes: this.formBuilder.array<
        typeof this.finderForm.controls.searchTypes.controls[0]
      >([]),
      includeOffline: this.formBuilder.control(this.includeOffline)
    });

    //  Translate searchTypes to FormGroups
    this.searchTypes$.pipe(untilDestroyed(this)).subscribe(inputTypes => {
      this.entityTypes.forEach(type => {
        if (inputTypes.includes(type)) {
          this.finderForm.controls.searchTypes.push(
            this.formBuilder.group({
              name: [type],
              value: [true]
            })
          );
        }
      });
    });

    // Apply user finder settings if available
    if (this.finderSettings) {
      this.setFinderSettings(this.finderSettings);
    }

    this.results$ = this.finderForm.valueChanges.pipe(
      // Only emit when a valid query is available
      filter(
        finderSettings =>
          !finderSettings.query || typeof finderSettings.query === 'string'
      ),
      // Emit the latest filterSettings
      tap(filters => this.filterChanged.emit(filters)),
      switchMap(finderSettings => {
        // Transform formGroup finderSettings to searchable params
        const finalTypes = finderSettings.searchTypes
          .filter(type => type.value)
          .map(type => type.name);

        // Search
        return this.finderService
          .search(
            finderSettings.query,
            finalTypes,
            finderSettings.includeOffline
          )
          .then(response => response.results);
      }),
      // Set property if nested groups has results
      tap(results => (this.resultsExists = results.some(group => group.found))),
      startWith([])
    );

    this.selectedOption$ = this.incomingEntityMeta$.asObservable().pipe(
      switchMap(incomingMeta =>
        // Get the full information for the selected entity to display it properly
        this.getSelectedDisplayValue(incomingMeta)
      ),
      map(selected => {
        // For some cases, exclude the metadata properties, e.g. task also has phase meta
        if (this.excludeFields.length) {
          selected = omit(selected, this.excludeFields) as EntityMetadata;
        }

        this.emitChange(selected);

        // Hide the search input if there is a selected item
        this.blurSearch();
        this.selectedOption =
          selected?.entityId && !this.clearOnSelect ? selected : null;
        this.showFinder = this.clearOnSelect || !!!this.selectedOption;

        this.cdr.detectChanges();
        return this.selectedOption;
      }),
      shareReplay(1)
    );
  }

  emitChange(entityMeta: EntityMetadata): void {
    this.selected.emit(entityMeta);
    this.onChange(entityMeta);
  }

  /**
   * Set predefined filter settings if available
   *
   * @param finderSettings settings to overwrite
   */
  setFinderSettings(finderSettings: Partial<FinderSettings>): void {
    if (this.finderForm) {
      this.finderForm.patchValue(finderSettings);
      const searchTypes = this.finderForm.controls.searchTypes;
      patchFormArray(searchTypes, finderSettings.searchTypes);
    }
  }

  onItemSelect({
    option: {
      value: { item, section }
    }
  }: MatAutocompleteSelectedEvent) {
    this.finderForm.controls.query.reset();

    const entityMeta: EntityMetadata = {
      accountType: item.accountType || null,
      entityId: item.accountId
        ? `${item.accountId}`
        : item.$key
        ? `${item.$key}`
        : null,
      entityName: item.name || null,
      entityType:
        item.entityType ||
        this.searchService.getCollectionNameWithoutEnvironment(
          (section.request_params as any).collection_name
        ) ||
        null,
      phase: item.phase || null
    };

    this.incomingEntityMeta$.next(entityMeta);
  }

  /**
   * Get all selected entity information so if available to show on the template
   *
   * @param entityMeta Entity information
   */
  async getSelectedDisplayValue(
    entityMeta: EntityMetadata
  ): Promise<EntityMetadata> {
    const entity = await this.getEntityData(entityMeta);

    return entity;
  }

  enableFinder(): void {
    this.showFinder = true;
    this.focusSearch();
    this.cdr.detectChanges();
  }

  focusSearch(): void {
    setTimeout(() => {
      this.finderInput.nativeElement.focus();
    });
  }

  blurSearch(): void {
    setTimeout(() => {
      this.finderInput.nativeElement.blur();
    });
  }

  async onPanelClose(): Promise<void> {
    this.showFinder = !this.selectedOption;
    this.blurSearch();

    if (this.clearOnBlur) {
      // Wait until selectedValue is triggered by mat-autocomplete, otherwise will not register selection
      setTimeout(() => {
        this.finderForm.controls.query.reset();
      }, 100);
    }
    this.closed.emit();
  }

  clearSelected() {
    const entityMeta = {
      accountType: null,
      entityId: null,
      entityName: null,
      entityType: null,
      phase: null
    } as EntityMetadata;

    this.incomingEntityMeta$.next(entityMeta);
  }

  /**
   * Get entity information depending on entityType
   *
   * @param meta entityMeta
   * @returns Entity information
   */
  async getEntityData(meta: EntityMetadata): Promise<EntityMetadata> {
    let entity: Observable<Customer | ContactModel | AccountBasicMeta | void>;

    switch (meta.entityType) {
      case 'customer':
      case 'prospect':
        entity = this.customersService.get(meta.entityId);
        break;

      case 'account':
        entity = this.customerAccountsService.getAccount({
          accountId: meta.entityId,
          accountType: meta.accountType
        });
        break;

      case 'contacts':
        entity = this.contactsService.get(meta.entityId).pipe(
          map(contact =>
            contact.$exists()
              ? {
                  ...contact,
                  name: `${contact.firstName || ''} ${
                    contact.middleName || ''
                  } ${contact.lastName || ''}`
                }
              : null
          )
        );
        break;

      default:
        entity = of(null);
        break;
    }

    return firstValueFrom(
      entity.pipe(
        map(data => ({
          accountType: meta?.accountType || null,
          entityId: meta?.entityId || null,
          entityName: (data as any)?.name || null,
          entityType: meta?.entityType || null,
          phase: (data as Customer)?.phase || null
        }))
      )
    );
  }

  /**
   * ControlValueAccessor
   */
  writeValue(entityMeta: EntityMetadata) {
    if (entityMeta) {
      this.incomingEntityMeta$.next(entityMeta);
    }
  }

  onChange: (value) => any = () => {
    /* */
  };

  onTouched = () => {};

  registerOnChange(onChangeFn) {
    this.onChange = onChangeFn;
  }

  registerOnTouched(onTouchedFn) {
    this.onTouched = onTouchedFn;
  }

  markAsTouched() {
    this.onTouched();
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    disabled ? this.finderForm.disable() : this.finderForm.enable();
  }

  toggleMobileFilter() {
    this.toggleMobileFilters$.next(!this.toggleMobileFilters);
  }
}
