import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  AfterViewInit,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, timer } from 'rxjs';
import {
  delayWhen,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap
} from 'rxjs/operators';

import { CustomerFeedService } from '../../../customers/customer-feed/customer-feed.service';
import { NotificationsService } from '../../../notifications/notifications.service';
import { TaskModel } from '../../../tasks/task.model';
import { TaskService } from '../../../tasks/task.service';
import { FundingService } from '../../funding/funding.service';
import { ScrollspyService } from '../../scrollspy/scrollspy.service';
import { SNACKBAR_DURATION_ERROR } from '../../constants';
import { WINDOW_DATA } from '../window-options';
import {
  WindowPane,
  WINDOW_PANE_ANIMATION_DURATION
} from '../window-pane/window-pane';
import { WindowPaneAnimationState } from '../window-pane/window-pane.interface';
import { TaskOffsetValue } from './window-pane-view-task.interface';
import { WindowPaneViewTaskService } from './window-pane-view-task.service';

@UntilDestroy()
@Component({
  selector: 'ease-window-pane-view-task',
  templateUrl: './window-pane-view-task.component.html',
  styleUrls: ['./window-pane-view-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    WindowPaneViewTaskService,
    ScrollspyService,
    CustomerFeedService,
    FundingService
  ]
})
export class WindowPaneViewTaskComponent implements AfterViewInit, OnInit {
  @ViewChild('windowSubtitleTemplate', { read: TemplateRef })
  windowSubtitleTemplate: TemplateRef<any>;
  @ViewChild('drawer', { read: MatDrawer }) set drawer(drawer: MatDrawer) {
    if (drawer) {
      // Configure the drawer after it becomes available from ngIf inside template
      this.windowPaneViewTaskService.setDrawer(drawer);
    }
  }
  public offset$: Observable<TaskOffsetValue>;
  public task$: Observable<TaskModel>;
  public showTask$: Observable<boolean>;

  constructor(
    public windowPane: WindowPane<string>,
    @Inject(WINDOW_DATA) private taskId$: ReplaySubject<string>,
    private notificationsService: NotificationsService,
    private windowPaneViewTaskService: WindowPaneViewTaskService,
    private matSnackBar: MatSnackBar,
    private taskService: TaskService
  ) {}

  ngOnInit(): void {
    this.task$ = this.taskId$.pipe(
      switchMap(taskId => this.taskService.get(taskId)),
      tap(task => {
        if (!task || !task.$exists()) {
          this.handleMissingTask();
          return;
        }

        this.applyTaskMetadata(task);
      })
    );

    /**
     * Highlights the window if it is minimized and
     * a notification comes in, otherwise we unhighlight
     * the window and mark it as read
     */
    this.taskId$
      .pipe(
        switchMap(taskId =>
          this.notificationsService.getStatusForItem(taskId).pipe(
            map(status => ({
              taskId,
              status
            }))
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(({ taskId, status }) => {
        status === 'unread' && this.windowPane.state === 'MINIMIZED'
          ? this.windowPane.highlight()
          : this.notificationsService.markRead(taskId) &&
            this.windowPane.unhighlight();
      });

    /**
     * Listen for window state changes and mark as read once
     * the window opens or is maximized
     */
    this.windowPane.onStateChanged
      .pipe(
        filter(state => state === 'OPEN' || state === 'MAXIMIZED'),
        switchMap(() => this.taskId$),
        switchMap(taskId => this.notificationsService.markRead(taskId)),
        untilDestroyed(this)
      )
      .subscribe();

    /**
     * Listen to animation events and when opening finishes,
     * inform the view that we're safe to load the task
     * without causing animation jank
     */
    this.showTask$ = this.windowPane.animationEvents.pipe(
      map(
        event =>
          (event.toState === WindowPaneAnimationState.OPEN &&
            event.phaseName === 'done') ||
          (event.toState === WindowPaneAnimationState.CLOSE &&
            event.fromState !== WindowPaneAnimationState.VOID &&
            event.phaseName === 'start')
      ),
      distinctUntilChanged(),
      delayWhen(val => (val ? timer(WINDOW_PANE_ANIMATION_DURATION) : timer(0)))
    );

    this.offset$ = this.windowPaneViewTaskService.getOffsetValue();
  }

  ngAfterViewInit() {
    if (this.windowPane) {
      this.windowPane.updateSubtitleTemplate(this.windowSubtitleTemplate);
    }
  }

  applyTaskMetadata(task: TaskModel) {
    this.windowPane.updateTitle(task.name);
    this.windowPane.updateTemplateContext({
      entityName: task.entityName,
      entityType: task.entityType,
      accountType: task.accountType
    });
  }

  handleMissingTask() {
    setTimeout(() => {
      this.matSnackBar.open(`Sorry, that task couldn't be found.`, 'Close', {
        duration: SNACKBAR_DURATION_ERROR
      });

      this.windowPane.close();
    });
  }
}
