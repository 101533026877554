import { ICON_FOR_STATUS, LOGO_FILE_FOR_ACCOUNT_TYPE } from '../constants';

export const statusCellRenderer = params => {
  const icon = params.value ? ICON_FOR_STATUS[params.value] : '';
  return `<div title="${params.value}" class="flex flex-row justify-start items-center h-full"><div class="material-icons mat-icon">${icon}</div></div>`;
};

export const accountTypeRenderer = params => {
  const logo = LOGO_FILE_FOR_ACCOUNT_TYPE[params.value];
  return logo
    ? `<div title="${params.value}" class="flex flex-row justify-start items-center h-full"><img class="mat-icon-20" src="/assets/images/${logo}"></div>`
    : '';
};
