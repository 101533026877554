<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <ng-container [ngSwitch]="entity">
      <ng-container
        *ngSwitchCase="'customer'"
        [ngTemplateOutlet]="header"
        [ngTemplateOutletContext]="{
          $implicit: {
            label: 'Customers',
            icon: 'person_pin'
          }
        }"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'prospect'"
        [ngTemplateOutlet]="header"
        [ngTemplateOutletContext]="{
          $implicit: {
            label: 'Prospects',
            icon: 'ring_volume'
          }
        }"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'account'"
        [ngTemplateOutlet]="header"
        [ngTemplateOutletContext]="{
          $implicit: {
            label: 'Accounts',
            icon: 'device_hub'
          }
        }"
      ></ng-container>
    </ng-container>
    <span class="text-sm italic text-gray-600">({{ total }})</span>
  </mat-expansion-panel-header>

  <mat-button-toggle-group [formControl]="method" class="w-full">
    <mat-button-toggle value="table" class="flex-1">
      <ng-container
        [ngTemplateOutlet]="methodToggle"
        [ngTemplateOutletContext]="{
          $implicit: {
            label: 'Add via Table',
            icon: 'table_chart'
          }
        }"
      ></ng-container>
    </mat-button-toggle>
    <mat-button-toggle value="paste" class="flex-1">
      <ng-container
        [ngTemplateOutlet]="methodToggle"
        [ngTemplateOutletContext]="{
          $implicit: {
            label: 'Paste Data',
            icon: 'content_paste'
          }
        }"
      ></ng-container>
    </mat-button-toggle>
    <mat-button-toggle value="upload" class="flex-1">
      <ng-container
        [ngTemplateOutlet]="methodToggle"
        [ngTemplateOutletContext]="{
          $implicit: {
            label: 'Upload CSV',
            icon: 'cloud_upload'
          }
        }"
      ></ng-container>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <ng-container [ngSwitch]="currentMethod">
    <ease-bulk-targeting-method-table
      *ngSwitchCase="'table'"
      [entity]="entity"
      (valueChanged)="memberChanged($event)"
    ></ease-bulk-targeting-method-table>
    <ease-bulk-targeting-method-paste
      *ngSwitchCase="'paste'"
      [entity]="entity"
      (valueChanged)="memberChanged($event)"
    ></ease-bulk-targeting-method-paste>
    <ease-bulk-targeting-method-upload
      *ngSwitchCase="'upload'"
      [entity]="entity"
      (valueChanged)="memberChanged($event)"
    ></ease-bulk-targeting-method-upload>
  </ng-container>
</mat-expansion-panel>

<ng-template #header let-item>
  <mat-icon class="mat-icon-24 mr-2">{{ item.icon }}</mat-icon>
  <span class="pr-1 text-base font-medium text-gray-900">{{ item.label }}</span>
</ng-template>

<ng-template #methodToggle let-item>
  <div class="flex items-center text-[14px] font-medium">
    <mat-icon class="mat-icon-20 mr-2">{{ item.icon }}</mat-icon>
    {{ item.label }}
  </div>
</ng-template>
