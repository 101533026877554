import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

export interface FormItem {
  id: string;
  title: string;
  answer?: string;
  fields: FormItem[];
}
@Component({
  selector: 'ease-customer-onboarding-form-renderer-item',
  templateUrl: './customer-onboarding-form-renderer-item.component.html',
  styleUrls: ['./customer-onboarding-form-renderer-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerOnboardingFormRendererItemComponent implements OnInit {
  @Input() formItem: FormItem;
  @Input() collapse: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
