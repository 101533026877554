<div
  class="metric row details flex flex-col"
  [ngClass]="account.butMetric.ragStatusColor"
>
  <!--header-->
  <div class="row header flex flex-1 flex-col">
    <div class="column cell flex flex-row items-center justify-center">
      <div class="graph flex flex-col">
        <div class="dot flex flex-row">
          <div class="indicator metric-background"></div>
        </div>
      </div>
      <div class="status-summary flex-1">
        {{ account.butMetric.ragStatusDescription }}
      </div>
    </div>
  </div>
  <!--details-->
  <div class="row cell flex flex-row">
    <ease-metric-graph-bar
      [value]="account.butMetric.butPercentageToDate"
      class="flex-1"
    ></ease-metric-graph-bar>
  </div>
  <div class="detail-summary row flex flex-row">
    <div
      class="detail-summary-group column detail flex max-w-[70%] flex-full flex-row"
    >
      <div
        class="column cell detail flex flex-1 flex-col items-center justify-center"
      >
        <ease-metric-simple
          [label]="'BU to Date'"
          [notes]="
            account.butMetric.budgetUpdatedAt
              ? 'Since ' +
                (account.butMetric.budgetUpdatedAt | amDateFormat: 'MMM D')
              : null
          "
          [tooltip]="
            'The amount of spend accrued in the account for the budget period, so far.'
          "
          [value]="
            account.butMetric.butCostToDate | currency: 'USD':'symbol':'1.0-0'
          "
          [differenceNumeric]="
            account.butMetric.butCostToDate - account.butMetric.butTargetToDate
              | currency: 'USD':'symbol':'1.0-0'
          "
          [differencePercentage]="account.butMetric.butPercentageToDate"
        ></ease-metric-simple>
      </div>
      <div class="column cell detail flex flex-1 flex-col justify-center">
        <ease-metric-simple
          [label]="'Target BU to Date'"
          [tooltip]="
            'The amount of spend the account should have accrued for the budget period, so far. Computed based on the account\'s currently set monthly budget and the remaining days for the month.'
          "
          [value]="
            account.butMetric.butTargetToDate | currency: 'USD':'symbol':'1.0-0'
          "
          [notes]="
            account.butMetric.numBudgetDays &&
            account.butMetric.numBudgetDays + ' day(s) included'
          "
        ></ease-metric-simple>
      </div>
      <div class="column cell detail flex flex-1 flex-col justify-center">
        <ease-metric-simple
          [label]="'Monthly Budget'"
          [value]="account.butMetric.budget | currency: 'USD':'symbol':'1.0-2'"
        ></ease-metric-simple>
        <div
          class="metric-note pt-1"
          *ngIf="account?.butMetric.budgetUpdatedAt"
        >
          Budget change occurred on
          <strong>{{
            account?.butMetric.budgetUpdatedAt | amDateFormat: 'MMM D'
          }}</strong
          >.
        </div>
        <div
          class="metric-note pt-1"
          *ngIf="account.budget && account.budget !== account.butMetric.budget"
        >
          New Budget (<strong>{{
            account.budget | currency: 'USD':'symbol':'1.0-2'
          }}</strong
          >) will take 24h to reflect.
        </div>
      </div>
      <div class="column cell detail flex flex-1 flex-col justify-center">
        <ease-metric-simple
          [label]="'Sugg. Budget'"
          [tooltip]="
            'Suggested daily budget to spend 100% of your budget by the end of the period'
          "
          [value]="
            account.butMetric
              | butSuggestedBudget
              | currency: 'USD':'symbol':'1.0-0'
          "
        ></ease-metric-simple>
      </div>
      <div class="column cell detail flex flex-1 flex-col justify-center">
        <ease-metric-simple
          [label]="'Projected Cost'"
          [tooltip]="'Projected cost at the end of the budget period'"
          [value]="
            account.butMetric.projectedCost | currency: 'USD':'symbol':'1.0-0'
          "
        ></ease-metric-simple>
      </div>
    </div>

    <div
      class="column detail max-w-[30%] flex-full"
      *ngIf="account.butMetric?.metadata?.chartData.labels.length"
    >
      <ease-chart
        [height]="130"
        [data]="account.butMetric?.metadata?.chartData"
        [type]="'line'"
        [options]="accountDashboardService.percentageChartOptions"
      >
      </ease-chart>
    </div>
  </div>
</div>
