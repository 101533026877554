<div
  *ngIf="account?.metadata && !isGroup"
  (click)="alertInfo.toggle()"
  [satPopoverAnchor]="alertInfo"
  class="metric empty alerts flex flex-1 flex-row"
>
  <mat-icon class="mat-icon-24">{{
    account?.metadata?.status === 'NORMAL_ALERT' ? 'restore' : 'warning'
  }}</mat-icon>
  <sat-popover [autoFocus]="false" [hasBackdrop]="true" #alertInfo>
    <ease-alert-cell-popover
      [alert]="account.metadata"
    ></ease-alert-cell-popover>
  </sat-popover>
</div>

<div
  *ngIf="isGroup && hasError"
  class="metric empty alerts flex flex-1 flex-row"
>
  <div class="blob-error"></div>
</div>
