import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserModel } from 'src/app/users/user.model';
import { ListAsObject } from '../../firebase-db.service';
import { FeedItem } from '../feed-item.interface';

@Component({
  selector: 'ease-feed-item-message-group',
  templateUrl: './feed-item-message-group.component.html',
  styleUrls: ['../feed-item/feed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemMessageGroupComponent {
  @Input() item: FeedItem;
  @Input() users: ListAsObject<UserModel>;
  @Input() dateFormat: string;

  public messagePluralMapping: { [T: string]: string } = {
    '=0': 'no recipients',
    '=1': '1 recipient',
    other: '# recipients'
  };
}
