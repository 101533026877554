import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { EntityUtilsService } from 'src/app/shared/entity-utils.service';
import { ChartOptions } from 'chart.js';
import { CHART_CURRENCY_OPTIONS } from '../../../shared/charts/charts';
import {
  CustomerAccountCost,
  CustomerBudget
} from '../../../customers/customer-accounts/customer-account-budget/customer-account-budget.interface';
import { CustomerAccountMeta } from '../../../customers/customer-accounts/customer-accounts.interface';
import { CustomerAccountRulesService } from '../../../customers/customer-accounts/customer-account-rules.service';
import { CustomerBudgetsDialogComponent } from '../../../customers/customer-budgets/customer-budgets-dialog/customer-budgets-dialog.component';
import { TaskModel } from '../../task.model';
import { Change } from '../../../shared/shared.interface';

@Component({
  selector: 'ease-task-budget',
  templateUrl: './task-budget.component.html'
})
export class TaskBudgetComponent {
  @Input()
  set task(task: TaskModel) {
    this._task = task;

    if (task && task.entityType === 'account') {
      this.entityUtilsService
        .getCustomerIdForAccount({
          accountId: this.task.entityId,
          accountType: this.task.accountType
        })
        .then(customerId => (this.customerId = customerId));

      this.budgetSettings$ = this.customerAccountRulesService.get(
        { accountId: this.task.entityId, accountType: this.task.accountType },
        'budget'
      );
      this.getCostSummary({
        accountId: this.task.entityId,
        accountType: this.task.accountType
      });
    }

    if (task && task.entityType === 'customer') {
      this.customerId = task.entityId;
    }
  }

  get task(): TaskModel {
    return this._task;
  }

  public budgetSettings$: Observable<CustomerBudget>;
  public costSummary: CustomerAccountCost;
  public isAdjustingBudget = false;
  public customerId: string;
  public chartOptions: ChartOptions = Object.assign(
    {},
    CHART_CURRENCY_OPTIONS,
    {
      legend: {
        display: true,
        labels: {
          boxWidth: 14
        }
      }
    }
  );
  private _task: TaskModel;

  constructor(
    private customerAccountRulesService: CustomerAccountRulesService,
    private entityUtilsService: EntityUtilsService,
    private matDialog: MatDialog
  ) {}

  updateBudget(budgetChange: Change<Partial<CustomerBudget>>) {
    this.customerAccountRulesService.updateBudget(
      { accountId: this.task.entityId, accountType: this.task.accountType },
      budgetChange
    );
    this.getCostSummary({
      accountId: this.task.entityId,
      accountType: this.task.accountType
    });
  }

  getCostSummary({ accountId, accountType }: CustomerAccountMeta) {
    this.customerAccountRulesService
      .getCostSummary({ accountId, accountType })
      .then(summary => (this.costSummary = summary));
  }

  openBudgetManager() {
    const dialogRef = this.matDialog.open(CustomerBudgetsDialogComponent, {
      maxWidth: 'none'
    });

    dialogRef.componentInstance.customerId = this.customerId;
  }
}
