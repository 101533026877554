<div class="py-0 px-5" *ngIf="showFilter">
  <mat-form-field floatLabel="never">
    <input
      [formControl]="itemFilterControl"
      #itemFilter
      [placeholder]="placeholder"
      matInput
    />
  </mat-form-field>
</div>
<div>
  <virtual-scroller
    #scroll
    [items]="items"
    [enableUnequalChildrenSizes]="true"
    [ngStyle]="{ height: maxHeight + 'px' }"
  >
    <div
      class="cursor-pointer"
      (click)="toggleSelected(item[compareKey])"
      *ngFor="
        let item of scroll.viewPortItems;
        trackBy: getKey;
        let itemIndex = index
      "
    >
      <ng-template
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{
          item: item,
          selected: _selectedItemsMap[item[compareKey]],
          icon: selectIcon
        }"
      ></ng-template>
    </div>
  </virtual-scroller>
</div>
