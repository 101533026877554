import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';

import { SharedModule } from '../shared.module';
import { SharedPipesModule } from '../shared-pipes.module';

import { VerticalTabGroupComponent } from './vertical-tab-group/vertical-tab-group.component';
import { VerticalTabComponent } from './vertical-tab/vertical-tab.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    SharedModule,
    SharedPipesModule
  ],
  declarations: [VerticalTabComponent, VerticalTabGroupComponent],
  exports: [VerticalTabComponent, VerticalTabGroupComponent]
})
export class VerticalTabsModule {}
