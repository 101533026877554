import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'ease-task-scheduled-status',
  templateUrl: './task-scheduled-status.component.html',
  styleUrls: ['./task-scheduled-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskScheduledStatusComponent implements OnInit {
  @Input()
  items: any[];

  constructor() {}

  ngOnInit() {}
}
