import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContextMenuModule } from 'src/app/shared/context-menu/context-menu.module';
import { DatepickerModule } from 'src/app/shared/datepicker/datepicker.module';
import { CompleteTaskPopoverModule } from 'src/app/shared/popover/complete-task/complete-task-popover.module';
import { SelectUserPopoverModule } from 'src/app/shared/popover/select-user/select-user-popover.module';
import { TaskStatusPopoverModule } from 'src/app/shared/popover/task-status/task-status-popover.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TaskPhaseModule } from 'src/app/tasks/task-phase/task-phase.module';
import { TaskStatusModule } from 'src/app/tasks/task-status/task-status.module';
import { TaskPriorityPopoverModule } from '../../../../shared/popover/task-priority/task-priority-popover.module';
import { TaskItemActionMenuComponent } from './task-item-action-menu.component';

@NgModule({
  declarations: [TaskItemActionMenuComponent],
  imports: [
    CommonModule,
    ContextMenuModule,
    SelectUserPopoverModule,
    TaskStatusPopoverModule,
    TaskPriorityPopoverModule,
    CompleteTaskPopoverModule,
    TaskStatusModule,
    TaskPhaseModule,
    SharedModule,
    DatepickerModule,
    FormsModule
  ],
  exports: [TaskItemActionMenuComponent]
})
export class TaskItemActionMenuModule {}
