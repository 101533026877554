<div
  class="wrapper"
  [ngClass]="wrapperState"
  [@wrapper]="wrapperState"
  (@wrapper.done)="enableCardTransitions($event)"
  easeViewportOffsetPusher
>
  <ng-container *ngIf="allUnread$ | async as allUnread">
    <ng-container *ngIf="allUnread.length">
      <div
        (click)="navigate('prev')"
        class="nav left"
        [ngClass]="{ disabled: allUnread.length === 1 }"
      >
        <mat-icon class="mat-icon-0"> chevron_left </mat-icon>
      </div>

      <div class="container-wrapper flex-1">
        <div
          class="list"
          [matBadge]="allUnread.length"
          matBadgeColor="warn"
          matBadgePosition="above after"
        >
          <div
            *ngFor="let unread of allUnread; index as index; trackBy: getKey"
            (click)="openDetails(unread.$key)"
            [@.disabled]="!isWrapperReady"
            [@card]="cardState[index]"
            [ngClass]="cardState[index]"
            class="item"
          >
            <div class="content flex flex-1 flex-row">
              <div class="meta flex-1">
                <div class="target-level">
                  <ng-container [ngSwitch]="unread.targetLevel">
                    <div *ngSwitchCase="'company'" class="level company">
                      <img class="mat-icon-20" src="/assets/images/crown.png" />
                    </div>
                    <div *ngSwitchCase="'user'" class="level user">
                      <mat-icon class="mat-icon-20">portrait</mat-icon>
                    </div>
                  </ng-container>
                </div>
                <div class="summary">{{ unread.summary }}</div>
                <div class="author">by {{ unread.createdBy?.name }}</div>
              </div>
              <div class="action">Read More</div>
            </div>
          </div>
        </div>
      </div>

      <div
        (click)="navigate('next')"
        class="nav right"
        [ngClass]="{ disabled: allUnread.length === 1 }"
      >
        <mat-icon class="mat-icon-0"> chevron_right </mat-icon>
      </div>
    </ng-container>
  </ng-container>
</div>
