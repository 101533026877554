import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ListAsObject } from 'src/app/shared/firebase-db.service';

@Component({
  selector: 'ease-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskStatusComponent {
  private _selectedStatusId: string;

  @Input() set selectedStatus(selectedStatus: ListAsObject<boolean>) {
    this._selectedStatusId = selectedStatus
      ? Object.keys(selectedStatus)[0]
      : null;
  }

  get selectedStatusId() {
    return this._selectedStatusId;
  }

  @Output()
  statusChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  onStatusChange(statusId: string): void {
    this._selectedStatusId = statusId;
    this.statusChanged.emit(this._selectedStatusId);
  }
}
