<div
  class="ad-wrapper rounded-xl border border-gray-200 p-5"
  *ngIf="ad$ | async as ad"
>
  <div class="flex flex-row items-center justify-start space-x-2 text-sm">
    <div class="font-bold text-black">Ad •</div>
    <div class="flex-1 truncate text-gray-500">{{ ad.finalUrl }}</div>
  </div>

  <div class="text-lg text-blue-700">
    {{ ad.headline }}
  </div>
  <div>
    {{ ad.description }}
  </div>
</div>
