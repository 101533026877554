<div
  [easeContextMenu]="subtaskContextMenu"
  [easeContextData]="task"
  [easeContextMenuDisabled]="
    previewMode || task.state === 'completed' || task.state === 'snoozed'
  "
  [easeTaskWindower]="task.$key"
  #mySubtaskContextMenu="easeContextMenu"
  class="relative flex cursor-pointer flex-row justify-between rounded-lg bg-white py-2 pl-5 pr-2 shadow-sm"
  [ngClass]="{ 'ring-2 ring-blue-500': currentlyViewing }"
>
  <div
    class="absolute bottom-0 right-0 ml-2 inline-flex flex-row items-center gap-1 rounded-tl-lg bg-blue-500 px-2 py-1 text-xs font-bold text-white"
    *ngIf="currentlyViewing"
  >
    <mat-icon class="mat-icon-16">visibility</mat-icon>
    <span *ngIf="!task.state">Viewing</span>
  </div>
  <div
    class="flex flex-col"
    [ngClass]="
      task.state === 'completed' || task.state === 'snoozed'
        ? 'opacity-60'
        : 'opacity-100'
    "
  >
    <div
      class="mt-1 flex flex-row items-center gap-1 text-xs font-bold uppercase text-gray-500"
      *ngIf="isParent"
    >
      <mat-icon class="mat-icon-16">folder</mat-icon> <span>Project</span>
    </div>

    <div class="flex flex-row items-center gap-1">
      <div
        *ngIf="task.name"
        class="mt-1 flex flex-row items-start gap-1 text-base font-bold"
      >
        <mat-icon class="mat-icon-16 mt-0.5" *ngIf="!isParent"
          >subdirectory_arrow_right</mat-icon
        >
        <div>{{ task.name }}</div>
      </div>
    </div>
    <ng-container
      *ngIf="
        task.state === 'completed' || task.state === 'snoozed';
        else taskInfo
      "
    >
      <span class="mt-0.5 text-sm">{{ task.completionMessage }}</span>
    </ng-container>
  </div>

  <ng-container
    *ngIf="
      task.state === 'completed' || task.state === 'snoozed';
      else moreActionsTemplate
    "
  >
    <div
      class="flex py-1.5 px-2 text-sm font-bold text-light-green-400"
      [ngClass]="{
        'items-center': !task.completionMessage,
        'text-light-green-400': task.state === 'completed',
        'text-gray-500': task.state === 'snoozed'
      }"
    >
      <mat-icon class="mat-icon-20 mr-1">{{
        task.state === 'completed' ? 'check_circle' : 'notifications_paused'
      }}</mat-icon>
      <span class="uppercase">{{ task.state }}</span>
    </div>
  </ng-container>
</div>

<ng-template #taskInfo>
  <div
    *ngIf="task.status || task.phase || task.board"
    class="flex flex-row flex-wrap gap-x-2"
  >
    <ng-container *ngFor="let status of task.status | keyvalue">
      <ease-task-status-blob
        class="mr-1"
        [statusId]="status.key"
        *ngIf="task.status"
      ></ease-task-status-blob>
    </ng-container>
    <ng-container *ngFor="let phase of task.phase | keyvalue">
      <ease-task-phase-blob
        class="mr-1"
        [phaseId]="phase.key"
        *ngIf="task.phase"
      ></ease-task-phase-blob>
    </ng-container>
    <div *ngIf="task.board" class="flex flex-row items-center gap-1">
      <mat-icon class="mat-icon-16">web</mat-icon>
      <ease-task-board-list-blob [boardId]="task.board" [listId]="task.list">
      </ease-task-board-list-blob>
    </div>
  </div>
  <div class="mt-px flex flex-row">
    <div
      class="flex flex-row items-center justify-center py-1 pl-0 pr-2 text-gray-500"
      *ngIf="task.startDate"
    >
      <div class="flex flex-row items-center justify-center space-x-1 text-xs">
        <mat-icon class="mat-icon-14">play_circle</mat-icon>
        <span>{{ task.startDate | amDateFormat: 'MMM D' }}</span>
      </div>
    </div>
    <div
      class="flex flex-row items-center justify-center py-1 pl-0 pr-2 text-gray-500"
      *ngIf="task.dueDate"
      [ngClass]="{
        'text-red-600': task.dueDate < todayTimestamp,
        'text-orange-600': task.dueDate === todayTimestamp
      }"
    >
      <div class="flex flex-row items-center justify-center space-x-1 text-xs">
        <mat-icon class="mat-icon-14">calendar_today</mat-icon>
        <span>{{ task.dueDate | amDateFormat: 'MMM D' }}</span>
      </div>
    </div>
    <div
      class="flex flex-row items-center justify-center py-1 pl-0 pr-2 text-gray-500"
      *ngIf="taskService.priorityNames[task.priority]"
    >
      <div class="flex flex-row items-center justify-center space-x-1 text-xs">
        <mat-icon class="mat-icon-14">low_priority</mat-icon>
        <span>{{ taskService.priorityNames[task.priority] }}</span>
      </div>
    </div>
  </div>
  <ease-user-selector-input
    [formControl]="assigned"
    [onlyUsers]="true"
    (selected)="assign(task.$key, $event)"
    (deselected)="taskService.unassign(task.$key, $event)"
    [enableDragNDrop]="false"
    label="Assign User"
    class="flex-1 !pb-0"
  >
    <ng-template #menu let-userId>
      <ease-task-user-menu
        (move)="subscribe(task.$key, { userId, method: 'direct' })"
        (remove)="taskService.unassign(task.$key, userId)"
      ></ease-task-user-menu>
    </ng-template>
  </ease-user-selector-input>
</ng-template>

<ng-template #moreActionsTemplate>
  <button
    mat-icon-button
    [matMenuTriggerFor]="moreMenu"
    (click)="$event.stopPropagation()"
  >
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #moreMenu="matMenu">
    <ng-template matMenuContent>
      <ease-task-item-action-menu
        [taskId]="task.$key"
      ></ease-task-item-action-menu>
    </ng-template>
  </mat-menu>
</ng-template>

<ng-template #subtaskContextMenu let-task>
  <ease-task-item-action-menu
    [taskId]="task.$key"
    (popoverClose)="mySubtaskContextMenu.close()"
  ></ease-task-item-action-menu>
</ng-template>
