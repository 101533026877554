import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { UserSelected } from 'src/app/shared/user-selector/user-selector.interface';
import { TaskModel } from '../task.model';
import { TaskService } from '../task.service';

@Component({
  selector: 'ease-task-assigned-users',
  templateUrl: './task-assigned-users.component.html',
  styleUrls: ['./task-assigned-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskAssignedUsersComponent {
  @HostBinding('class') hostClass = 'pt-1';
  @Input() set task(task: TaskModel) {
    this._task = task;

    this.assigned.setValue(task.$displayAssigned || []);
    this.subscribed.setValue(task.$displaySubscribed || []);
  }
  get task(): TaskModel {
    return this._task;
  }
  private _task: TaskModel;
  public assigned: FormControl<string[]> = new FormControl([]);
  public subscribed: FormControl<string[]> = new FormControl([]);

  constructor(public taskService: TaskService) {}

  assign(taskId, selected: UserSelected): void {
    this.taskService.assign({ taskId, ...selected });
  }

  subscribe(taskId, selected: UserSelected): void {
    this.taskService.subscribe({ taskId, ...selected });
  }
}
