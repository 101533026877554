import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';

import { AdminPermissionsService } from '../../../admin/admin-roles/admin-permissions.service';
import { ConfirmService } from '../../../shared/confirm/confirm.service';
import { ListSelectorComponent } from '../../../shared/list-selector/list-selector.component';
import { TaskPhase } from '../task-phase.interface';
import { TaskPhaseService } from '../task-phase.service';

@Component({
  selector: 'ease-task-phase-selector',
  templateUrl: './task-phase-selector.component.html',
  styleUrls: ['./task-phase-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskPhaseSelectorComponent implements OnInit {
  @Input()
  selectedPhase: string[] = [];
  @Output()
  selected: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  edit: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('listSelector', { static: true })
  listSelector: ListSelectorComponent;
  public isEmpty: boolean = false;
  public phases$: Observable<TaskPhase[]>;
  public isAdmin: boolean = false;

  constructor(
    private confirmService: ConfirmService,
    private taskPhaseService: TaskPhaseService,
    public permissionsService: AdminPermissionsService
  ) {}

  ngOnInit() {
    this.phases$ = this.taskPhaseService.getAll();
  }

  focusInput() {
    this.listSelector && this.listSelector.focusInput();
  }

  onSelect(phaseId: string) {
    this.selected.emit(phaseId);
  }

  editPhase(phaseId: string, event: Event) {
    this.edit.emit(phaseId);
    event.stopPropagation();
  }

  removePhase(phaseId: string, event: Event) {
    this.confirmService
      .confirm({
        title: 'Delete Phase',
        confirmText: 'Delete',
        cancelText: 'Cancel'
      })
      .then(
        confirmResult =>
          confirmResult.confirm &&
          this.taskPhaseService.remove(phaseId).subscribe()
      );

    event.stopPropagation();
  }

  getKey(index, currentValue) {
    return currentValue ? currentValue.$key : index;
  }
}
