<div
  class="wrapper mat-card !flex flex-1 flex-row flex-wrap"
  [ngClass]="{ widget: isWidget }"
>
  <ease-funding-summary-transactions
    class="flex-1"
  ></ease-funding-summary-transactions>
  <div class="w-0"></div>
  <ng-container *ngIf="customerId">
    <ease-funding-summary-settings
      [customerId]="customerId"
      [isWidget]="isWidget"
      class="flex-1"
    >
    </ease-funding-summary-settings>
  </ng-container>
</div>

<mat-expansion-panel class="panel">
  <mat-expansion-panel-header
    [collapsedHeight]="'30px'"
    [expandedHeight]="'40px'"
    class="header"
  >
    <mat-panel-title class="title">
      Toggle Breakdown for Current Funding Year
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div>
    <ease-funding-summary-breakdown
      [breakdownData]="breakdownData$ | async"
    ></ease-funding-summary-breakdown>
  </div>
</mat-expansion-panel>
