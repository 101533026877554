import { getTypesenseConfiguration } from '../app/shared/search/typesense.configuration';
import { VERSION } from './version';

export const environment = {
  name: 'production',
  prodMode: true,
  useEmulators: false,
  VERSION,
  userId: null,
  firebase: {
    apiKey: 'AIzaSyBSOWIGncI2m3OXGeRnjWQYJh_vBGnil8Y',
    authDomain: 'ease-2014.firebaseapp.com',
    databaseURL: 'https://ease-2014.firebaseio.com',
    projectId: 'ease-2014',
    storageBucket: 'ease-2014.appspot.com',
    messagingSenderId: '850849047742',
    appId: '1:850849047742:web:a9b48c3899239ab0',
    measurementId: 'G-SXNMHYS5GJ'
  },
  REST_URI: 'https://resteasy.searchkings.ca',
  SKSYNC_URI: 'https://sksync.searchkings.ca/api/v1',
  TYPESENSE_PREFIX: 'prod_',
  TYPESENSE: getTypesenseConfiguration('production'),
  AGGRID_LICENSE:
    'CompanyName=SearchKings,LicensedApplication=EASE,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026455,ExpiryDate=1_May_2023_[v2]_MTY4Mjg5NTYwMDAwMA==2a601b563d881671d926c7cc19b3e5bd',
  REPORTS_APP_URL: 'https://reports.searchkings.ca',
  LMG_BUCKET: 'lmg-funding-production',
  GOOGLE_MAPS_API_KEY: 'AIzaSyAUzMEPBjVNqZgU6kr0dSAtKovG9rFQl0A',
  GOOGLE_MAPS_URI: `https://maps.googleapis.com/maps/api`,
  ONBOARDING_HUB: 'https://hub.searchkings.ca/onboarding',
  ABSTRACT_HOLIDAYS_KEY: '8fe21394b79b4456b1f290100ac7d6bc'
};
