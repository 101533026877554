<ng-container *ngIf="task">
  <div
    #tabNav
    class="relative z-10 bg-gray-50"
    [ngClass]="{ '!sticky': isSticky }"
    [style.top.px]="
      isSticky ? (compactHeaderHeight ? compactHeaderHeight : 72) : null
    "
  >
    <nav
      *ngIf="
        (task?.description && task?.state) || !task?.state || task?.checklists
      "
      class="flex flex-row items-center !border-gray-300"
      mat-tab-nav-bar
      [disablePagination]="true"
    >
      <ng-container *ngIf="offset$ | async as offset">
        <ng-container *ngIf="activeSection$ | async as activeSection">
          <a
            [active]="activeSection === 'description'"
            [ngxScrollTo]="description"
            [ngxScrollToDuration]="250"
            [ngxScrollToOffset]="offset.value"
            mat-tab-link
          >
            Description
          </a>
          <a
            *ngIf="task.checklists"
            [active]="activeSection === 'checklist'"
            [ngxScrollTo]="checklist"
            [ngxScrollToDuration]="250"
            [ngxScrollToOffset]="offset.value"
            mat-tab-link
          >
            Checklists
          </a>
        </ng-container>
      </ng-container>
    </nav>

    <div
      class="absolute right-2 top-1.5 bottom-0 z-10 lg:right-9"
      [ngClass]="{ '!right-6': !!task.state }"
      *ngIf="isSticky"
    >
      <button
        (click)="scrollSpyService.scrollToTop()"
        [matTooltip]="'Scroll to Top'"
        mat-icon-button
        class="!text-gray-600 !shadow-none hover:!bg-blue-500 hover:!text-white"
      >
        <div class="-mt-1.5 flex flex-col leading-none">
          <mat-icon class="!scale-x-150">keyboard_arrow_up</mat-icon>
          <span class="-mt-1.5 block text-[10px] font-bold">TOP</span>
        </div>
      </button>
    </div>
  </div>

  <ease-task-body-section *ngIf="task.state && users">
    <ease-task-completion-message
      [task]="task"
      [users]="users"
      #content
    ></ease-task-completion-message>
  </ease-task-body-section>

  <ease-task-body-section
    *ngIf="task.customerTransactionalMessageGroups && customerId"
  >
    <ease-task-customer-message-groups
      [messageGroupIds]="task.customerTransactionalMessageGroups | keyvalue"
      [customerId]="customerId"
      #content
    ></ease-task-customer-message-groups>
  </ease-task-body-section>

  <!-- Matched Items from Email-based Rules -->
  <ease-task-body-section *ngIf="task.matchedItems as matchedItems">
    <ease-task-matched-items
      [matched]="matchedItems"
      #content
    ></ease-task-matched-items>
  </ease-task-body-section>

  <ease-task-body-section [ngSwitch]="task?.taskType" class="task-body-items">
    <!-- Keyword Conflicts -->
    <ease-task-keyword-conflicts
      *ngSwitchCase="'keywordConflict'"
      [task]="task"
      [adwordsId]="task?.entityId"
      [items]="task?.keywordConflicts | keyvalue"
      #content
    ></ease-task-keyword-conflicts>

    <!-- Ad Disapprovals -->
    <ease-task-ad-disapprovals
      *ngSwitchCase="'adDisapproval'"
      [items]="task?.adDisapprovals"
      #content
    ></ease-task-ad-disapprovals>

    <!-- Keyword Disapprovals -->
    <ease-task-keyword-disapprovals
      *ngSwitchCase="'keywordDisapproval'"
      [items]="task?.keywordDisapprovals"
      #content
    ></ease-task-keyword-disapprovals>

    <!-- Ad Extension Disapprovals -->
    <ease-task-ad-extension-disapprovals
      *ngSwitchCase="'adExtensionDisapproval'"
      [items]="task?.disapprovals | objectToIterable"
      #content
    ></ease-task-ad-extension-disapprovals>

    <!-- Display Targeting Issue -->
    <ease-task-display-targeting
      *ngSwitchCase="'displayTargeting'"
      [items]="task?.displayTargetingIssues | objectToIterable"
      #content
    ></ease-task-display-targeting>

    <!-- Shopping Ads Issue -->
    <ease-task-shopping-ad-issue
      *ngSwitchCase="'shoppingAdIssue'"
      [items]="task?.shoppingAdIssues"
      #content
    ></ease-task-shopping-ad-issue>

    <!-- Display Location Targeting Issue -->
    <ease-task-display-location-targeting
      *ngSwitchCase="'displayLocationTargeting'"
      [items]="task?.campaigns | objectToIterable"
      #content
    ></ease-task-display-location-targeting>

    <!-- Display Campaign Ad Schedule Issue -->
    <ease-task-display-campaign-ad-schedule
      *ngSwitchCase="'displayCampaignAdSchedule'"
      [items]="task?.campaigns | objectToIterable"
      #content
    ></ease-task-display-campaign-ad-schedule>

    <!-- Display Feed Values Issue -->
    <ease-task-display-text-ad-feed
      *ngSwitchCase="'displayTextAdFeed'"
      [items]="task?.campaigns | objectToIterable"
      #content
    ></ease-task-display-text-ad-feed>

    <!-- Search Campaign w/o Negative Keywords -->
    <ease-task-campaign-negative-keywords
      *ngSwitchCase="'campaignNegativeKeywords'"
      [items]="task?.campaigns | objectToIterable"
      #content
    ></ease-task-campaign-negative-keywords>

    <!-- Campaigns without any location targeting -->
    <ease-task-campaign-location-targeting
      *ngSwitchCase="'noGeoTargeting'"
      [campaigns]="task?.campaigns | objectToIterable"
      [pausedCampaigns]="task?.pausedCampaigns | objectToIterable"
      #content
    >
    </ease-task-campaign-location-targeting>

    <!-- Task Budget -->
    <ease-task-budget
      *ngSwitchCase="'budgetAlert'"
      [task]="task"
      #content
    ></ease-task-budget>

    <!-- Task Budget -->
    <ease-task-budget
      *ngSwitchCase="'overBudgetAlert'"
      [task]="task"
      #content
    ></ease-task-budget>

    <!-- Customer Communication -->
    <ng-container *ngSwitchCase="'customerCommunication'">
      <ease-customer-contacts-list
        *ngIf="customerId"
        [customerId]="customerId"
        #content
      ></ease-customer-contacts-list>
    </ng-container>

    <ease-task-consulting-payment-declined
      *ngSwitchCase="'consultingPaymentDeclined'"
      [details]="task?.declineDetails"
      #content
    ></ease-task-consulting-payment-declined>

    <ease-task-scheduled-status
      *ngSwitchCase="'scheduledStatus'"
      [items]="task?.campaigns | objectToIterable"
      #content
    ></ease-task-scheduled-status>

    <ng-container *ngSwitchCase="'prospectFunding'">
      <ease-task-funding
        *ngIf="customerId"
        [customerId]="customerId"
        [disableFunding]="task?.entityType === 'prospect'"
        #content
      ></ease-task-funding>
    </ng-container>

    <ng-container *ngSwitchCase="'customerFunding'">
      <ease-task-funding
        *ngIf="customerId"
        [customerId]="customerId"
        [disableFunding]="task?.entityType === 'prospect'"
        #content
      ></ease-task-funding>
    </ng-container>

    <ease-customer-onboarding-task-package
      *ngSwitchCase="'onboardingTask'"
      [packageId]="task?.data?.packageId"
      #content
    ></ease-customer-onboarding-task-package>
  </ease-task-body-section>

  <div #description easeScrollspySection="description">
    <ease-task-body-section>
      <ease-task-description
        *ngIf="(task.description && task.state) || !task.state"
        (save)="saveTask($event)"
        [isCompleted]="!!task.state"
        [description]="task.description"
        #content
      ></ease-task-description>
    </ease-task-body-section>
  </div>

  <div #checklist easeScrollspySection="checklist" class="pr-4 pl-6 md:p-6">
    <ng-container *ngIf="task.checklists">
      <ease-task-checklist-group
        [disabled]="!!task.state"
        [taskId]="task.$key"
        [taskState]="task.state"
      ></ease-task-checklist-group>
    </ng-container>
  </div>
</ng-container>
