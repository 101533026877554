import { NgModule } from '@angular/core';
import { NgxUploaderModule } from 'ngx-uploader';

import { CustomerContactsModule } from '../../customers/customer-contacts/customer-contacts.module';
import { MaterialChipsInputModule } from '../material-chips-input/material-chips-input.module';
import { SharedFormModule } from '../shared-form.module';
import { SharedModule } from '../shared.module';
import { SharedPipesModule } from '../shared-pipes.module';
import { WindowCloseModule } from '../window/window-close/window-close.module';
import { AdvancedAutocompleteModule } from '../advanced-autocomplete/advanced-autocomplete.module';
import { EmailVariablesComponent } from './email-variables/email-variables.component';
import { EmailSendComponent } from './email-send/email-send.component';
import { EmailSharedVariablesComponent } from './email-shared-variables/email-shared-variables.component';
import { EmailComposerComponent } from './email-composer/email-composer.component';
import { GetVariableNamePipe } from './get-variable-name.pipe';

@NgModule({
  imports: [
    AdvancedAutocompleteModule,
    CustomerContactsModule,
    MaterialChipsInputModule,
    NgxUploaderModule,
    SharedModule,
    SharedFormModule,
    SharedPipesModule,
    WindowCloseModule
  ],
  declarations: [
    EmailSendComponent,
    EmailVariablesComponent,
    EmailSharedVariablesComponent,
    EmailComposerComponent,
    GetVariableNamePipe
  ],
  exports: [EmailSendComponent, EmailComposerComponent, GetVariableNamePipe]
})
export class EmailsModule {}
