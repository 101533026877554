import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserSelected } from 'src/app/shared/user-selector/user-selector.interface';
import { TaskModel } from '../../task.model';
import { TaskService } from '../../task.service';

@UntilDestroy()
@Component({
  selector: 'ease-task-panel-compact',
  templateUrl: './task-panel-compact.component.html',
  styleUrls: [
    '../task-panel/task-panel.component.scss',
    './task-panel-compact.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskPanelCompactComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'cursor-pointer';
  @HostBinding('class.task-panel--highlighted')
  get isHighlighted(): boolean {
    return this.task && !!this.task.customerTransactionalMessageGroups;
  }
  @HostBinding('class.task-panel--unread')
  get hasUnreadNotifications(): boolean {
    return this.task && this.task.$unreadNotifications;
  }
  @Input()
  task: TaskModel;

  @Input() actionsDisabled: boolean = false;
  private managerDialogSubscription: Subscription;
  private popoverOpen: boolean = false;
  public isHovered: boolean = false;
  public statusForDisplay: string[] = [];
  public todayTimestamp = new Date().setHours(0, 0, 0, 0);

  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    public taskService: TaskService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      const el = this.elementRef.nativeElement;

      fromEvent(el, 'mouseenter')
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.isHovered = true;
          this.cdr.detectChanges();
        });

      fromEvent(el, 'mouseleave')
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (!this.popoverOpen) {
            this.isHovered = false;
            this.cdr.detectChanges();
          }
        });
    });
  }

  popoverToggled(status) {
    this.popoverOpen = status;
    if (!status) {
      this.isHovered = false;
    }
  }

  onAssign(userSelected: UserSelected): void {
    this.taskService.assign({ taskId: this.task.$key, ...userSelected });
  }

  onUnassign(userId: string) {
    this.taskService.unassign(this.task.$key, userId);
  }

  onStatusChange(statusId: string) {
    this.taskService.setStatus(this.task.$key, statusId);
  }

  onRemoveStatus(statusId: string) {
    this.taskService.setStatus(this.task.$key);
  }

  onPhaseChange(phaseId: string) {
    this.taskService.setPhase(this.task.$key, phaseId);
  }

  ngOnDestroy() {
    this.managerDialogSubscription &&
      this.managerDialogSubscription.unsubscribe();
  }
}
