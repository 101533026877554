<div class="banner theme-info mb-5 flex flex-row items-center justify-center">
  <mat-icon class="mr-2">info</mat-icon>
  <span
    >If this is intentional, add a label:
    <strong>Broad Location Targeting</strong> to avoid this alert
    reappearing.</span
  >
</div>
<ease-list-group size="sm">
  <ease-list-group-header
    >The following campaigns have been paused:</ease-list-group-header
  >
  <ease-list-group-item *ngFor="let campaign of pausedCampaigns">{{
    campaign.campaignName
  }}</ease-list-group-item>
</ease-list-group>
<ease-list-group size="sm">
  <ease-list-group-header
    >The following campaigns are affected, but were unable to be
    paused:</ease-list-group-header
  >
  <ease-list-group-item *ngFor="let campaign of campaigns">{{
    campaign.campaignName
  }}</ease-list-group-item>
</ease-list-group>
