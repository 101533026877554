<ease-sheet class="popover">
  <form (ngSubmit)="snoozeAccount()" [formGroup]="snoozeForm">
    <ease-popover-header
      >Snooze {{ account ? 'Account' : 'Accounts' }}</ease-popover-header
    >
    <ease-sheet-content>
      <div
        class="mb-2 rounded border border-solid border-blue-300 bg-blue-100 p-2 text-center text-sm"
        *ngIf="!account"
      >
        This will snooze
        <span
          class="cursor-pointer font-bold text-blue-600"
          (click)="onShowRelatedAccounts()"
          ><strong>{{ relatedAccounts }} related </strong></span
        >
        to <strong>{{ snoozeMetadata.entityName }}</strong
        >.
      </div>
      <div class="form-group">
        <ease-markdown-editor
          placeholder="Describe the changes you made"
          formControlName="body"
          size="sm"
          [autoFocus]="true"
          class="max-h-[25vh]"
        ></ease-markdown-editor>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-select
            formControlName="duration"
            placeholder="Snooze for"
            [value]="this.snoozeForm.controls.duration.value"
          >
            <mat-option
              *ngFor="let snoozeTime of snoozeTimes"
              [value]="snoozeTime.value"
              >{{ snoozeTime.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <ease-datepicker
          #snoozePicker
          [min]="now"
          [max]="maxSnoozeDate"
          *ngIf="this.snoozeForm.controls.snoozeDate.enabled"
          formControlName="snoozeDate"
          placeholder="Snooze until(max 90 days)"
        ></ease-datepicker>
      </div>
    </ease-sheet-content>
    <ease-sheet-footer>
      <button
        type="submit"
        color="primary"
        [disabled]="snoozeForm.invalid"
        mat-button
      >
        Snooze Account
      </button>
    </ease-sheet-footer>
  </form>
</ease-sheet>
