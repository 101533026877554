import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { Account } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';

@Component({
  selector: 'ease-but-tab-content',
  templateUrl: './but-tab-content.component.html',
  styleUrls: ['./but-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButTabContentComponent implements OnInit {
  @Input() account: Account;

  constructor(public accountDashboardService: AccountDashboardService) {}

  ngOnInit() {}
}
