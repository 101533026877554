import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { TaskOffset, TaskOffsetValue } from './window-pane-view-task.interface';

@Injectable()
export class WindowPaneViewTaskService {
  private _drawer: MatDrawer;
  private scrollOffsetSource = new BehaviorSubject<TaskOffset>({
    header: 0,
    nav: 0
  });

  constructor() {}

  setDrawer(drawer: MatDrawer) {
    this._drawer = drawer;
  }

  get drawer(): MatDrawer {
    return this._drawer;
  }

  setOffset(offset: Partial<TaskOffset>): void {
    const current = this.scrollOffsetSource.getValue();
    // merge with current and only update specified offset properties
    this.scrollOffsetSource.next({
      ...current,
      ...offset
    });
  }

  getOffsetValue(): Observable<TaskOffsetValue> {
    return this.scrollOffsetSource
      .asObservable()
      .pipe(map(offset => ({ value: offset.header + offset.nav })));
  }
}
