import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ease-task-matched-items',
  templateUrl: './task-matched-items.component.html',
  styleUrls: ['./task-matched-items.component.scss']
})
export class TaskMatchedItemsComponent implements OnInit {
  @Input()
  matched: {
    type: string;
    numItems: number;
    items: {
      [id: string]: string;
    };
  };

  constructor() {}

  ngOnInit() {}
}
