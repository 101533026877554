<div
  class="upload-file-container my-5 flex min-h-[180px] flex-col items-center justify-center rounded-3xl border border-solid border-gray-200 bg-white p-5 text-center"
  *ngIf="fileFormArray"
  ngFileDrop
  (uploadOutput)="onUploadOutput($event)"
  [uploadInput]="uploadInput"
  [ngClass]="{ 'border-2 border-solid border-blue-500': dragOver }"
>
  <div
    class="upload-trigger !flex h-full w-full flex-1 cursor-pointer flex-col items-center justify-center text-gray-400"
    *ngIf="!files.length"
    (click)="fileInput.click()"
  >
    <div class="flex flex-row items-center justify-center">
      <mat-icon class="mr-2">add_circle_outline</mat-icon>Drag and drop, or
      click to select files.
    </div>
    <small
      >Accepted file formats: {{ accepts }}. File size limit of
      {{ maxFileSize | filesize }}.</small
    >
  </div>

  <ng-container *ngIf="files.length">
    <table mat-table [dataSource]="files" matSort class="w-full">
      <!-- Position Column -->
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let file; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="preview">
        <th mat-header-cell *matHeaderCellDef>Preview</th>
        <td mat-cell *matCellDef="let file">
          <div
            class="image-preview group relative h-[30px] w-[30px] bg-contain bg-center bg-no-repeat"
            [ngStyle]="{ 'background-image': 'url(' + file.metadata.url + ')' }"
          >
            <img
              [src]="file.metadata.url"
              class="full-img mat-elevation-z8 absolute left-12 z-50 hidden max-h-[200px] group-hover:block"
            />
          </div>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let file">
          <div>{{ file.name }}</div>
          <div
            *ngIf="file.metadata.error"
            class="error-text text-xs text-red-900"
          >
            {{ file.metadata.error }}
          </div>
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="dimensions">
        <th mat-header-cell *matHeaderCellDef>Dimensions</th>
        <td mat-cell *matCellDef="let file">
          {{ file.metadata.width }}x{{ file.metadata.height }}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let file">
          {{ file.size | filesize }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let file; let i = index">
          <button
            mat-icon-button
            matTooltip="Replace file"
            (click)="fileReplace.click()"
          >
            <mat-icon>find_replace</mat-icon>
          </button>
          <input
            [accept]="accepts"
            class="hidden"
            type="file"
            (uploadOutput)="onReplaceFile($event, file, i)"
            [uploadInput]="uploadInput"
            ngFileSelect
            #fileReplace
          />
          <button
            mat-icon-button
            (click)="deleteFile(file.id)"
            matTooltip="Delete file"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="
          ['index', 'preview', 'name', 'dimensions', 'size', 'actions'];
          sticky: true
        "
      ></tr>
      <tr
        mat-row
        *matRowDef="
          let file;
          columns: ['index', 'preview', 'name', 'dimensions', 'size', 'actions']
        "
        [ngClass]="{ '!bg-red-100': file.metadata.error }"
        class="even:bg-gray-100"
      ></tr>
    </table>
  </ng-container>
</div>

<input
  [accept]="accepts"
  class="hidden"
  type="file"
  #fileInput
  (uploadOutput)="onUploadOutput($event)"
  [uploadInput]="uploadInput"
  ngFileSelect
  multiple
/>

<div class="footer-summary flex flex-row" *ngIf="files.length">
  <button mat-button (click)="fileInput.click()" color="primary">
    <mat-icon>add</mat-icon>Add more
  </button>

  <div class="flex-1"></div>
  <div>{{ files.length }} files will be uploaded</div>
</div>
