import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { ConfirmService } from '../../confirm/confirm.service';
import { GridEntity } from '../../grid-toolbar/grid-targeting/grid-targeting.interface';

import { GridTargetingService } from '../../grid-toolbar/grid-targeting/grid-targeting.service';
import { WindowPane } from '../../window/window-pane/window-pane';
import { TargetMember } from '../bulk-targeting.interface';
import { BulkTargetingService } from '../bulk-targeting.service';

type Method = 'table' | 'paste' | 'upload';

@UntilDestroy()
@Component({
  selector: 'ease-bulk-targeting-entity',
  templateUrl: './bulk-targeting-entity.component.html',
  styleUrls: ['./bulk-targeting-entity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => BulkTargetingEntityComponent)
    },
    BulkTargetingService
  ]
})
export class BulkTargetingEntityComponent implements OnInit {
  @Input() entity: GridEntity;
  @Input() disabled = false;
  private touched = false;
  public method: FormControl<Method> = new FormControl('table');
  public currentMethod: Method = 'table';
  public total = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private confirmService: ConfirmService,
    private gridTargetingService: GridTargetingService,
    public windowPane: WindowPane<void>
  ) {}

  ngOnInit(): void {
    this.method.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((method: Method) => {
        if (this.currentMethod !== method) {
          if (this.total) {
            this.confirmService
              .confirm({
                message: `Are you sure you want to switch targeting methods? All currently targeted ${this.entity}s will be lost.`,
                confirmText: `Switch`,
                cancelText: 'Cancel'
              })
              .then(confirmResult => {
                if (confirmResult.confirm) {
                  this.currentMethod = method;
                  this.cdr.detectChanges();
                } else {
                  this.method.setValue(this.currentMethod);
                }
              });
          } else {
            this.currentMethod = method;
          }
        }
      });

    // disable toggling between different target methods when modifying ag-grid filters
    this.gridTargetingService
      .getState()
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        switch (state) {
          case 'MODIFYING':
            this.gridTargetingService.getActiveWindow().id ===
            this.windowPane.id
              ? this.method.disable()
              : this.method.enable();
            break;
          default:
            this.method.disabled && this.method.enable();
        }
      });
  }

  memberChanged(members: TargetMember[]) {
    this.total = members.length;
    this.onChange(members);
  }

  /**
   * ControlValueAccessor
   */
  writeValue() {
    this.markAsTouched();
  }

  onChange = value => {};

  onTouched = () => {};

  registerOnChange(onChangeFn) {
    this.onChange = onChangeFn;
  }

  registerOnTouched(onTouchedFn) {
    this.onTouched = onTouchedFn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
