import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BulkTargetingModule } from '../../bulk-targeting/bulk-targeting.module';
import { MarkdownModule } from '../../markdown/markdown.module';
import { SharedFormModule } from '../../shared-form.module';
import { SharedModule } from '../../shared.module';

import { WindowCloseModule } from '../window-close/window-close.module';
import { WindowPaneCreateAnnouncementComponent } from './window-pane-create-announcement.component';
import { CreateAnnouncementMetaComponent } from './create-announcement-meta/create-announcement-meta.component';

@NgModule({
  imports: [
    BulkTargetingModule,
    CommonModule,
    MarkdownModule,
    SharedFormModule,
    SharedModule,
    WindowCloseModule
  ],
  declarations: [
    CreateAnnouncementMetaComponent,
    WindowPaneCreateAnnouncementComponent
  ],
  exports: [
    CreateAnnouncementMetaComponent,
    WindowPaneCreateAnnouncementComponent
  ]
})
export class WindowPaneCreateAnnouncementModule {}
