import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { TaskUserMenuComponent } from './task-user-menu.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatMenuModule],
  exports: [TaskUserMenuComponent],
  declarations: [TaskUserMenuComponent]
})
export class TaskUserMenuModule {}
