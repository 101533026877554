import { NgModule } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { CustomerContactsModule } from '../../customers/customer-contacts/customer-contacts.module';
import { SharedModule } from '../../shared/shared.module';
import { SharedPipesModule } from '../../shared/shared-pipes.module';
import { TaskModulesModule } from '../task-modules/task-modules.module';
import { ScrollspyModule } from '../../shared/scrollspy/scrollspy.module';
import { CustomerOnboardingModule } from '../../customers/customer-onboarding/customer-onboarding.module';

import { TaskBodyComponent } from './task-body.component';
import { TaskBodySectionComponent } from './task-body-section/task-body-section.component';

@NgModule({
  imports: [
    CustomerContactsModule,
    CustomerOnboardingModule,
    SharedModule,
    SharedPipesModule,
    ScrollToModule.forRoot(),
    ScrollspyModule,
    TaskModulesModule
  ],
  declarations: [TaskBodyComponent, TaskBodySectionComponent],
  exports: [TaskBodyComponent]
})
export class TaskBodyModule {}
