import { NgModule } from '@angular/core';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { SharedModule } from '../../shared.module';
import { PopoverModule } from '../popover.module';
import { ScheduledTasksModule } from '../../../tasks/scheduled-tasks/scheduled-tasks.module';
import { TaskPanelsModule } from '../../../tasks/task-panels/task-panels.module';
import { TaskWindowerModule } from '../../window/window-pane-view-task/task-windower.module';
import { ListSelectorModule } from '../../list-selector/list-selector.module';
import { CustomerTasksPopoverComponent } from './customer-tasks-popover.component';

@NgModule({
  declarations: [CustomerTasksPopoverComponent],
  exports: [CustomerTasksPopoverComponent, PopoverModule],
  imports: [
    PopoverModule,
    ScheduledTasksModule,
    SharedModule,
    TaskPanelsModule,
    TaskWindowerModule,
    VirtualScrollerModule,
    ListSelectorModule
  ]
})
export class CustomerTasksPopoverModule {}
