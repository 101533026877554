import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'ease-feed-item-completed-task',
  templateUrl: './feed-item-completed-task.component.html',
  styleUrls: [
    '../feed-item/feed-item.component.scss',
    './feed-item-completed-task.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemCompletedTaskComponent {
  @Input()
  task;
  @Input()
  users;
  @Input()
  dateFormat: string;
  @Input()
  editable: boolean = false;
  @Input()
  showEntity: boolean = false;
  @Output()
  save: EventEmitter<any> = new EventEmitter<any>();

  saveCompletionMessage(completionMessage: string) {
    this.save.emit({ completionMessage });
  }
}
