import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ease-sheet-group',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./sheet-group.component.scss']
})
export class SheetGroupComponent {
  @HostBinding('class') hostClass = 'block rounded-md';
}
