<h2>Keyword Disapprovals</h2>
<ease-list-group size="sm">
  <ease-list-group-item
    *ngFor="let item of items"
    class="flex flex-row items-center justify-start"
  >
    <div class="flex-1">
      <div>{{ item.campaign }}</div>
      <div class="text-sm text-gray-400">{{ item.adGroup }}</div>
    </div>
    <div class="text-sm text-gray-400">
      {{ item.keyword }}
    </div>
  </ease-list-group-item>
</ease-list-group>
