<ease-sheet
  [ngClass]="{ 'push-surrounding': expander.pushSurrounding }"
  class="flex !flex-row"
>
  <div
    [ngClass]="{ 'cursor-pointer': expandViaHost && !isExpanded }"
    (click)="handleHostClick()"
    class="flex flex-1 flex-row items-center justify-start"
  >
    <div class="flex-1" *ngIf="!isExpanded">
      <ng-content select="[sheet-expand-before]"></ng-content>
    </div>
    <div class="flex-1" easeStopEvent *ngIf="isExpanded">
      <ng-template [cdkPortalOutlet]="portal"></ng-template>
      <ng-content select="[sheet-expand-after]"></ng-content>
    </div>
  </div>
  <div
    (click)="toggle()"
    class="sheet-expand-toggle cursor-pointer"
    *ngIf="!disableExpandIcon && !isExpanded"
  >
    <button mat-icon-button>
      <mat-icon>{{ expandIcon }}</mat-icon>
    </button>
  </div>
</ease-sheet>
