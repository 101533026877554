import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, UrlSegment } from '@angular/router';
import { AddressType } from '@googlemaps/google-maps-services-js';
import * as moment from 'moment';
import { firstValueFrom, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { AdminPermissionsService } from '../admin/admin-roles/admin-permissions.service';
import { AccountMetadataService } from '../shared/account-metadata.service';
import { ConfirmService } from '../shared/confirm/confirm.service';
import { EntityStatusChange } from '../shared/entity-status-selector/entity-status-selector.component';
import { EntityUtilsService } from '../shared/entity-utils.service';
import { bugsnagClient } from '../shared/error-handler';
import {
  CUSTOMERS_PATH,
  CUSTOMER_ANALYTICS_LINKS_PATH,
  CUSTOMER_COMMUNICATIONS_PATH,
  CUSTOMER_MESSAGES_PATH,
  CUSTOMER_MESSAGE_GROUPS_PATH,
  CUSTOMER_PLANS_PATH,
  CUSTOMER_STATUS_CHANGES_PATH,
  CUSTOMER_STICKY_NOTES_PATH
} from '../shared/firebase-paths';
import { GoogleMapsService } from '../shared/google-maps.service';
import { FirebasePathMerge, ListDisplayItem } from '../shared/shared.interface';
import { SkSyncHttpService } from '../shared/sksync-http.service';
import { firebaseJSON } from '../shared/utils/functions';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from '../shared/constants';
import { TaskService } from '../tasks/task.service';
import { UserRolesService } from '../users/user-roles/user-roles.service';
import { UserService } from '../users/user.service';
import { CustomerAccountsService } from './customer-accounts/customer-accounts.service';
import { CustomerContactsService } from './customer-contacts/customer-contacts.service';
import { CustomerRolesService } from './customer-roles.service';
import { CustomerRulesService } from './customer-rules/customer-rules.service';
import {
  CreatingCustomer,
  Customer,
  CustomerPlan,
  CustomerPlanDetail,
  CustomerSharedFields,
  CustomersTableRow
} from './customer.interface';

@Injectable({ providedIn: 'root' })
export class CustomersService {
  private CUSTOMER_MERGEABLE_PATHS: FirebasePathMerge[] = [
    { path: CUSTOMER_COMMUNICATIONS_PATH, action: 'merge' },
    { path: CUSTOMER_STICKY_NOTES_PATH, action: 'merge' },
    { path: CUSTOMER_PLANS_PATH, action: 'merge' },
    { path: CUSTOMER_STATUS_CHANGES_PATH, action: 'delete' },
    { path: CUSTOMER_STATUS_CHANGES_PATH, action: 'delete' },
    { path: CUSTOMER_MESSAGES_PATH, action: 'merge' },
    { path: CUSTOMER_MESSAGE_GROUPS_PATH, action: 'merge' },
    { path: CUSTOMER_MESSAGES_PATH, action: 'merge' },
    { path: CUSTOMER_ANALYTICS_LINKS_PATH, action: 'merge' }
  ];

  constructor(
    private accountMetadataService: AccountMetadataService,
    private angularFire: FirebaseDbService,
    private confirmService: ConfirmService,
    private customerAccountsService: CustomerAccountsService,
    private customerRolesService: CustomerRolesService,
    private customerRulesService: CustomerRulesService,
    private customerContactsService: CustomerContactsService,
    private entityUtilsService: EntityUtilsService,
    private googleMapsService: GoogleMapsService,
    private sksyncHttp: SkSyncHttpService,
    private http: HttpClient,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private taskService: TaskService,
    private userRolesService: UserRolesService,
    private permissionService: AdminPermissionsService,
    private userService: UserService
  ) {}

  get(customerId: string): Observable<Customer> {
    return this.angularFire.getObject(`/${CUSTOMERS_PATH}/${customerId}`);
  }

  getAll(status?: string): Observable<Customer[]> {
    return this.angularFire.getList(`/${CUSTOMERS_PATH}`, ref => {
      if (status) {
        return ref.orderByChild('status').equalTo(status);
      } else {
        return ref;
      }
    });
  }

  searchAll(filters: any = {}): Observable<CustomersTableRow[]> {
    return this.sksyncHttp.post(`/customers/search`, filters);
  }

  async update(
    customerId: string,
    customerData: Partial<Customer>
  ): Promise<void> {
    const currentDmaId = await this.entityUtilsService.getEntityField(
      { entityId: customerId, entityType: 'customer' },
      'dmaId'
    );

    /**
     * Update the defaultAccountFields if the customer's dmaId changes
     */
    if (customerData.dmaId && customerData.dmaId !== currentDmaId) {
      const currentFields = await this.entityUtilsService.getEntityField(
        { entityId: customerId, entityType: 'customer' },
        'defaultAccountFields'
      );
      const newFields = await this.getDefaultAccountFieldsFromDmaId(
        customerData.dmaId
      );

      if (newFields) {
        customerData.defaultAccountFields = Object.assign(
          {},
          currentFields,
          newFields
        );
      }
    }

    return this.angularFire
      .object(`/${CUSTOMERS_PATH}/${customerId}`)
      .update(firebaseJSON(customerData));
  }

  create(customerData: CreatingCustomer): Promise<string> {
    customerData.createdBy = this.userService.currentUser.$key;

    return firstValueFrom(
      this.sksyncHttp.post('/customers/create', customerData)
    ).then(async newCustomerId => {
      await this.customerRulesService.update(
        newCustomerId,
        'communication',
        {
          enabled: true
        },
        false
      );
      return newCustomerId;
    });
  }

  async convertToCustomer(
    customerId: string,
    mergeFields: Partial<Customer> = {}
  ): Promise<string> {
    if (!this.permissionService.hasPermission('convertProspectToCustomer')) {
      this.matSnackBar.open(
        `You don't have the right permission to convert prospect to customers`,
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );
      return;
    }

    const oldStatus = await this.entityUtilsService.getEntityField(
      { entityType: 'customer', entityId: customerId },
      'status'
    );

    await this.update(
      customerId,
      Object.assign(
        {},
        {
          phase: 'customer',
          status: 'OFFLINE'
        },
        mergeFields
      )
    );

    await this.setStatus(customerId, {
      oldStatus,
      newStatus: 'OFFLINE'
    });

    return customerId;
  }

  async merge(
    sourceCustomerId: string,
    destinationCustomerId: string
  ): Promise<any> {
    /**
     * Loop over all mergeable paths (defined above). If we encounter
     * a path that should be merged, first copy it to the destination
     * customer before deleting matching the source customer endpoint
     *
     * Whether a path is mergeable or not, the end result is the
     * source customer endpoint will be deleted
     */
    await Promise.all(
      this.CUSTOMER_MERGEABLE_PATHS.map(async ({ path, action }) => {
        if (action === 'merge') {
          const itemsToMove = await firstValueFrom(
            this.angularFire.getObject(`/${path}/${sourceCustomerId}`)
          );

          await this.angularFire
            .object(`/${path}/${destinationCustomerId}`)
            .update(firebaseJSON(itemsToMove.$exists() ? itemsToMove : {}));
        }

        await this.angularFire.object(`/${path}/${sourceCustomerId}`).remove();
      })
    );

    await this.delete(sourceCustomerId, false);
  }

  async delete(customerId: string, confirm: boolean = true): Promise<boolean> {
    if (!this.permissionService.hasPermission('deleteCustomerProspect')) {
      this.matSnackBar.open(
        `You don't have the right permission to delete customers/prospects`,
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );
      return false;
    }
    if (confirm) {
      const confirmResult = await this.confirmService.confirm({
        title: 'Delete Customer',
        message:
          'The customer profile will be permanently deleted. Are you sure?',
        confirmText: 'Yes, Delete',
        cancelText: 'Cancel'
      });

      if (!confirmResult.confirm) {
        return false;
      }
    }

    const customerTasks = await firstValueFrom(
      this.taskService.getForEntity({ entityId: customerId })
    );

    await Promise.all(
      customerTasks.map(task =>
        this.taskService.update(task.$key, {
          entityId: null,
          entityName: null,
          entityType: null,
          accountType: null
        })
      )
    );

    await this.customerAccountsService.removeAll(customerId);
    await this.customerRulesService.remove(customerId);
    await this.customerContactsService.removeAll(customerId);
    await this.angularFire.object(`/${CUSTOMERS_PATH}/${customerId}`).remove();

    confirm &&
      this.matSnackBar.open('Customer deleted', 'Close', {
        duration: SNACKBAR_DURATION_SUCCESS
      });

    return true;
  }

  getStatus(customerId: string): Observable<string> {
    return this.angularFire
      .getObject(`/${CUSTOMERS_PATH}/${customerId}/status`)
      .pipe(map(value => (value.$exists() ? value.$value : null)));
  }

  async setStatus(
    customerId: string,
    { newStatus, oldStatus }: EntityStatusChange
  ): Promise<boolean> {
    let allRolesSet = true;
    let statusDetails;

    if (newStatus === 'ONLINE') {
      allRolesSet = await this.assertRolesSet(customerId);
    }

    if (!allRolesSet) {
      const confirmResult = await this.confirmService.confirm({
        title: 'User Roles Not Set',
        message:
          'All user roles must be set before you can turn this customer online.',
        confirmColor: 'primary',
        confirmText: 'Go To Settings',
        cancelText: 'Cancel'
      });

      confirmResult.confirm &&
        this.router.navigate(['/customers', customerId, 'settings']);

      return false;
    }

    if (newStatus === 'LOST') {
      const confirmResult = await this.confirmService.confirm(
        {
          title: 'Specify Reason',
          message: 'Why was this lead lost?',
          detailsPlaceholder: 'Reason for marking as lost',
          showDetails: true,
          cancelText: 'Cancel',
          confirmText: 'Mark as lost'
        },
        { disableClose: true, minWidth: 320, width: '500px' }
      );

      if (confirmResult.confirm) {
        statusDetails = confirmResult.details;
      } else {
        return false;
      }
    }

    /**
     * Set the new customer status
     *
     * If temporary statusDetails field existed, Firebase function will use
     * the value from here to store the status and details change into the feed,
     * and then delete the value afterwards.
     */
    this.angularFire.object(`/${CUSTOMERS_PATH}/${customerId}`).update({
      status: newStatus,
      statusDetails: statusDetails || null
    });

    return true;
  }

  setRole(customerId: string, roleKey: string, userId: string): Promise<void> {
    return this.angularFire
      .object(`/${CUSTOMERS_PATH}/${customerId}/roles/${roleKey}`)
      .set(userId);
  }

  assertRolesSet(customerId: string): Promise<boolean> {
    return Promise.all([
      firstValueFrom(this.userRolesService.getForScope('customer')),
      firstValueFrom(this.customerRolesService.getRoles(customerId))
    ]).then(([availableRoles, customerRoles]) =>
      availableRoles.every(availableRole =>
        customerRoles.some(
          customerRole => customerRole.$key === availableRole.$key
        )
      )
    );
  }

  getPlans(): Observable<ListDisplayItem[]> {
    return this.angularFire
      .getList(`${CUSTOMER_PLANS_PATH}`, ref => ref.orderByChild('order'))
      .pipe(
        map((plans: CustomerPlanDetail[]) =>
          plans.map(plan => ({
            value: `${plan.$key}`,
            viewValue: plan.name,
            color: plan.color,
            order: plan.order
          }))
        )
      );
  }

  getPlansAsObject(): Observable<{
    [key: string]: { name: string; color: string };
  }> {
    return this.angularFire.getObject(`${CUSTOMER_PLANS_PATH}`);
  }

  createPlan(plan: CustomerPlan): Promise<void> {
    // get the largest existing planID and use it to create the new planId(+1)
    return firstValueFrom(
      this.angularFire
        .getList(`${CUSTOMER_PLANS_PATH}`, ref =>
          ref.orderByKey().limitToLast(1)
        )
        .pipe(
          take(1),
          map(prevPlans => prevPlans[0])
        )
    ).then(prevPlan =>
      this.angularFire
        .object(`${CUSTOMER_PLANS_PATH}/${Number(prevPlan.$key) + 1}`)
        .set(plan)
    );
  }

  updatePlans(plans: CustomerPlan) {
    return this.angularFire.object(`${CUSTOMER_PLANS_PATH}`).update(plans);
  }

  getNextCommunicationAt(customerId: string): Observable<number> {
    return this.angularFire
      .getObject(`/${CUSTOMERS_PATH}/${customerId}/nextCommunicationAt`)
      .pipe(map(timestamp => (timestamp.$exists() ? timestamp.$value : null)));
  }

  getLastCommunicationAt(customerId: string): Observable<number> {
    return this.angularFire
      .getObject(`/${CUSTOMERS_PATH}/${customerId}/lastCommunicationAt`)
      .pipe(map(timestamp => (timestamp.$exists() ? timestamp.$value : null)));
  }

  /**
   * Set the last/next communication dates for a customer
   *
   * @param customerId Customer to set the new communication dates for
   * @param dates.lastCommunicationAt When the customer was last spoken to
   * @param dates.nextCommunicationAt When the customer should be spoken to next
   */
  async setCommunicationAt(
    customerId: string,
    {
      lastCommunicationAt,
      nextCommunicationAt
    }: {
      lastCommunicationAt?: number | null;
      nextCommunicationAt?: number | null;
    }
  ): Promise<void> {
    /**
     * Spread any defined properties into a `toSave` payload
     * If next communication date is defined, ensure we set
     * it to 8am of the chosen day
     */
    const toSave = {
      ...(nextCommunicationAt
        ? {
            nextCommunicationAt: moment(nextCommunicationAt)
              .set('hours', 8)
              .valueOf()
          }
        : {}),
      ...(lastCommunicationAt ? { lastCommunicationAt } : {})
    };

    return this.angularFire
      .object(`/${CUSTOMERS_PATH}/${customerId}`)
      .update(toSave);
  }

  removeNextCommunicationAt(customerId: string): Promise<void> {
    return this.angularFire
      .object(`/${CUSTOMERS_PATH}/${customerId}/nextCommunicationAt`)
      .remove();
  }

  async getDefaultAccountFieldsFromDmaId(
    dmaId: string
  ): Promise<Partial<CustomerSharedFields>> {
    if (!dmaId) {
      return;
    }
    let currency: string;
    let country: string;
    let timezone: string;

    try {
      const dma = await firstValueFrom(
        this.accountMetadataService.getDma(dmaId)
      );

      const geo = await firstValueFrom(
        this.googleMapsService.getGeoCode({
          address: `${dma?.name}, ${dma?.country?.toUpperCase()}`
        })
      );

      if (geo && geo.status === 'OK' && geo.results && geo.results.length) {
        const result = geo.results[0];
        const location = result.geometry.location;
        const countryComponent = result.address_components.find(component =>
          component.types.includes(AddressType.country)
        );

        if (countryComponent) {
          country = countryComponent.long_name;
          const countryShort = countryComponent.short_name;
          const countryData = await firstValueFrom(
            this.http.get<any>(
              `https://restcountries.com/v3.1/alpha/${countryShort}`
            )
          );

          if (
            countryData &&
            countryData.currencies &&
            countryData.currencies.length
          ) {
            currency = countryData.currencies[0].code;
          }
        }
        const timezoneData = await firstValueFrom(
          this.googleMapsService.getTimezone({
            location: `${location.lat},${location.lng}`,
            timestamp: new Date().getTime() / 1000
          })
        );

        if (
          timezoneData &&
          timezoneData.status === 'OK' &&
          timezoneData.timeZoneId
        ) {
          timezone = timezoneData.timeZoneId;
        }
      }
    } catch (err) {
      console.error('Error while getting default account fields from DMA', err);
      bugsnagClient.notify(err);
    }

    return {
      currency: currency || null,
      country: country || null,
      timezone: timezone || null
    };
  }

  getDefaultAccountFields(
    customerId: string
  ): Observable<CustomerSharedFields> {
    return this.angularFire
      .getObject(`/${CUSTOMERS_PATH}/${customerId}/defaultAccountFields`)
      .pipe(map(values => (values.$exists() ? values : {})));
  }

  setDefaultAccountFields(
    customerId: string,
    values: CustomerSharedFields
  ): Promise<void> {
    return this.angularFire
      .object(`/${CUSTOMERS_PATH}/${customerId}/defaultAccountFields`)
      .set(firebaseJSON(values));
  }

  referenceValid(reference: string, customerId?: string): Observable<boolean> {
    if (!reference || !reference.length) {
      return of(true);
    }

    return this.angularFire
      .getList(`/${CUSTOMERS_PATH}`, ref =>
        ref.orderByChild('reference').equalTo(reference)
      )
      .pipe(
        take(1),
        map((foundReferences: Customer[]) => {
          if (customerId) {
            const foundCustomerIndex = foundReferences.findIndex(
              customer => customer.$key !== customerId
            );
            return !(foundCustomerIndex > -1);
          }

          return !foundReferences.length;
        })
      );
  }

  getBaseUrl(urlSegments: UrlSegment[], path: string): string {
    return urlSegments.reduce(
      (acc, urlSegment) => acc + `/${urlSegment.path}`,
      path
    );
  }

  public getCustomerDisplayName(fields: CustomerSharedFields): string {
    if (fields.firstName && fields.lastName) {
      return `${fields.company} / ${fields.firstName} ${fields.lastName}`;
    } else {
      return fields.company;
    }
  }

  public getCustomerByRelationshipManager(
    relationshipManagerId: string
  ): Observable<Customer[]> {
    return this.angularFire.getList(`/${CUSTOMERS_PATH}`, ref =>
      ref
        .orderByChild('roles/relationshipManager')
        .equalTo(relationshipManagerId)
    );
  }
}
