import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

import {
  TypedFormControl,
  TypedFormGroup
} from 'src/app/shared/reactive-forms';
import { CHART_DEFAULT_OPTIONS } from '../../../shared/charts/charts';
import { CustomerBudget } from '../../customer-accounts/customer-account-budget/customer-account-budget.interface';
import { Change } from '../../../shared/shared.interface';

@Component({
  selector: 'ease-customer-budget',
  templateUrl: './customer-budget.component.html',
  styleUrls: ['./customer-budget.component.scss']
})
export class CustomerBudgetComponent implements OnInit {
  @Input()
  currencyCode: string = 'CAD';
  @Input()
  set settings(settings: CustomerBudget) {
    if (settings) {
      this.settingsForm.patchValue(settings);
      this.oldSettings = this.settingsForm.value;
    }
  }
  @Output()
  save: EventEmitter<Change<Partial<CustomerBudget>>> = new EventEmitter<
    Change<Partial<CustomerBudget>>
  >();

  public settingsForm: TypedFormGroup<CustomerBudget> = this.formBuilder.group<
    TypedFormControl<CustomerBudget>
  >({
    enabled: this.formBuilder.control(null),
    budget: this.formBuilder.control(null, {
      validators: [Validators.min(1), Validators.pattern(/^[0-9]\d*$/)]
    }),
    lowerLimit: this.formBuilder.control(10),
    upperLimit: this.formBuilder.control(10),
    snoozeUntil: this.formBuilder.control(null),
    snoozeType: this.formBuilder.control(null)
  });
  public oldSettings: Partial<CustomerBudget>;
  public chartOptions = CHART_DEFAULT_OPTIONS;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.oldSettings = this.settingsForm.value;
  }

  get upperLimitDelta() {
    return this.percentageOfBudget(this.settingsForm.controls.upperLimit.value);
  }

  get lowerLimitDelta() {
    return this.percentageOfBudget(this.settingsForm.controls.lowerLimit.value);
  }

  percentageOfBudget(value = 0) {
    return value * 0.01 * this.settingsForm.controls.budget.value;
  }

  toggleEnableAlerts(event: MatCheckboxChange) {
    if (event.checked) {
      this.settingsForm.controls.budget.setValidators([
        Validators.min(1),
        Validators.pattern(/^[0-9]\d*$/),
        Validators.required
      ]);
    } else {
      this.settingsForm.controls.budget.setValidators([
        Validators.min(1),
        Validators.pattern(/^[0-9]\d*$/)
      ]);
    }
    this.settingsForm.controls.budget.updateValueAndValidity();
  }

  onSubmit() {
    if (this.settingsForm.valid) {
      this.save.emit({
        before: this.oldSettings,
        after: this.settingsForm.value
      });
    }
  }

  onCancel() {
    this.settingsForm.patchValue(this.oldSettings);
    this.settingsForm.markAsPristine();
  }
}
