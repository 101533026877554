import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { Account, ResMetric } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';

@Component({
  selector: 'ease-res-popover',
  templateUrl: './res-popover.component.html',
  styleUrls: ['./res-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResPopoverComponent implements OnInit {
  @Input() account: Account;
  public responsivenessMetric: ResMetric;

  constructor(public accountDashboardService: AccountDashboardService) {}

  ngOnInit(): void {
    if (this.account && this.account.resMetric) {
      this.responsivenessMetric = this.account.resMetric;
    }
  }
}
