<mat-form-field class="!block" [floatLabel]="floatLabel">
  <mat-label *ngIf="label"
    >{{ label }}
    <ng-container *ngIf="isOpened">
      <span *ngIf="selectedControl.value?.length"> - </span>
      <ng-template
        [ngTemplateOutlet]="selectTrigger || defaultSelectTrigger"
      ></ng-template>
    </ng-container>
  </mat-label>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <ng-container
    *ngIf="
      sortedOptions$ | async | search: searchControl.value as filteredOptions
    "
  >
    <mat-select
      [placeholder]="placeholder"
      multiple
      disableOptionCentering
      panelClass="mat-advanced-multi-selector"
      [formControl]="selectedControl"
      (openedChange)="matSelectOpenedChange($event)"
    >
      <div class="sticky top-0 z-1 bg-white px-3 pt-1 text-sm">
        <mat-form-field floatLabel="never" class="!-mb-2 !w-full">
          <mat-icon matPrefix>search</mat-icon>
          <input
            type="text"
            placeholder="Search"
            matInput
            [formControl]="searchControl"
          />
          <button
            *ngIf="searchControl.value"
            mat-button
            mat-icon-button
            matSuffix
            (click)="searchControl.reset()"
          >
            <mat-icon class="mat-icon-18 !top-px !m-auto">close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <mat-select-trigger>
        <ng-template
          [ngTemplateOutlet]="selectTrigger || defaultSelectTrigger"
        ></ng-template>
      </mat-select-trigger>

      <ng-container *ngIf="filteredOptions?.length; else empty">
        <virtual-scroller
          #scroll
          [items]="filteredOptions"
          [enableUnequalChildrenSizes]="true"
        >
          <ng-container *ngFor="let option of scroll.viewPortItems">
            <div
              class="selector-option flex items-center justify-center hover:bg-gray-100"
            >
              <mat-option
                class="!flex-1 !bg-transparent"
                [value]="option.value"
                [disabled]="option?.disabled"
                (click)="selectIndividual(option)"
              >
                <ng-template
                  [ngTemplateOutlet]="viewValueTemp || defaultViewValueTemp"
                  [ngTemplateOutletContext]="{ $implicit: option.viewValue }"
                >
                </ng-template>
              </mat-option>

              <button
                mat-button
                [disabled]="option?.disabled"
                class="!flex-none !rounded-none !py-1.5"
                (click)="selectOnly(option)"
              >
                <span class="font-normal">ONLY</span>
              </button>
            </div>
          </ng-container>
        </virtual-scroller>
      </ng-container>

      <div class="sticky bottom-0">
        <div
          *ngIf="searchControl.value && filteredOptions?.length"
          class="mb-1 flex items-center justify-center py-2"
        >
          <button
            mat-raised-button
            class="!mx-2 !p-1.5 !text-xs"
            (click)="selectAllFiltered(filteredOptions, 'select')"
          >
            Select All Filtered
          </button>
          <button
            mat-raised-button
            class="!mx-2 !p-1.5 !text-xs"
            (click)="selectAllFiltered(filteredOptions, 'unselect')"
          >
            Unselect All Filtered
          </button>
        </div>

        <div
          class="flex items-center justify-between border-t border-gray-300 bg-white px-3 py-2"
        >
          <div class="mr-2" *ngIf="customBottomElements">
            <ng-template
              [ngTemplateOutlet]="customBottomElements"
            ></ng-template>
          </div>

          <div class="!text-xs">
            <ng-container *ngIf="customBottomActions">
              <ng-template
                [ngTemplateOutlet]="customBottomActions"
              ></ng-template>
            </ng-container>

            <mat-checkbox
              #selectAllCheckbox
              class="ml-2"
              [formControl]="selectAllControl"
              [indeterminate]="someSelected"
              >Select All</mat-checkbox
            >
          </div>
        </div>
      </div>
    </mat-select>
  </ng-container>
</mat-form-field>

<ng-template #empty>
  <div class="p-3 text-center text-xs">No results found</div>
</ng-template>

<ng-template #defaultViewValueTemp let-value>
  {{ value }}
</ng-template>

<ng-template #defaultSelectTrigger>
  <ng-container *ngIf="selectedControl.value as selected">
    {{ firstViewValue }}
    <span *ngIf="selected?.length > 1" class="text-sm">
      (+{{ selected?.length - 1 }}
      {{ selected?.length > 2 ? 'others' : 'other' }})
    </span>
  </ng-container>
</ng-template>
