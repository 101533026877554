<div
  class="flex flex-col items-center justify-center lg:flex-row lg:items-start"
>
  <div class="task-budget__item">
    <div class="task-budget__meta capitalize text-gray-400">
      <div>
        Target Cost To Date<mat-icon
          class="mat-icon-14 ml-1 cursor-pointer text-gray-700"
          matTooltip="Amount that should have been spent to remain on target"
          >help</mat-icon
        >
      </div>
    </div>
    <div class="task-budget__stat">
      {{ task?.expectedSpend | currency: 'USD':'symbol':'1.0-2' }}
    </div>
  </div>
  <div class="task-budget__item">
    <div class="task-budget__meta text-gray-400">
      Actual Cost To Date
      <mat-icon
        class="mat-icon-14 ml-1 cursor-pointer text-gray-700"
        matTooltip="Total spend for the month or the date range defined"
        >help</mat-icon
      >
    </div>
    <div class="task-budget__stat">
      {{ task?.spend | currency: 'USD':'symbol':'1.0-2' }}
      <button
        matTooltip="View Breakdown"
        class="task-budget__breakdown-toggle"
        *ngIf="task?.adwordsSpend || task?.lsaSpend || task?.lsaSpend"
        (click)="costBreakdownToggle.toggle()"
        mat-icon-button
      >
        <mat-icon>{{
          costBreakdownToggle?.isHidden ? 'expand_more' : 'expand_less'
        }}</mat-icon>
      </button>
      <ease-toggle #costBreakdownToggle="toggle">
        <ng-container *ngIf="!costBreakdownToggle?.isHidden">
          <div
            *ngIf="task?.adwordsSpend"
            class="mt-2 flex flex-row items-center justify-center text-sm"
          >
            <ease-account-icon
              class="mr-1"
              [size]="16"
              [accountType]="'adwords'"
            ></ease-account-icon>
            {{ task?.adwordsSpend | currency: 'USD':'symbol':'1.0-2' }}
          </div>
          <div
            *ngIf="task?.lsaSpend"
            class="mt-2 flex flex-row items-center justify-center text-sm"
          >
            <ease-account-icon
              class="mr-1"
              [size]="16"
              [accountType]="'lsa'"
            ></ease-account-icon>
            {{ task?.lsaSpend | currency: 'USD':'symbol':'1.0-2' }}
          </div>
          <div
            *ngIf="task?.bingSpend"
            class="mt-2 flex flex-row items-center justify-center text-sm"
          >
            <ease-account-icon
              class="mr-1"
              [size]="16"
              [accountType]="'bing'"
            ></ease-account-icon>
            {{ task?.bingSpend | currency: 'USD':'symbol':'1.0-2' }}
          </div>
        </ng-container>
      </ease-toggle>
      <div class="mt-2 text-sm text-red-500">
        {{ task?.spendDeltaDirection === 'under' ? '&minus;' : '+'
        }}{{ task?.spendDelta | currency: 'USD':'symbol':'1.0-2' }}
        <span class="ml-1">({{ task?.spendDeltaPercent }}%)</span>
      </div>
    </div>
  </div>
</div>
<div class="task-budget__divider task-budget__divider--horizontal"></div>
<div
  class="flex flex-col items-center justify-center lg:flex-row lg:items-start"
>
  <div class="task-budget__item">
    <div class="task-budget__meta text-gray-400">
      Total Budget
      <mat-icon
        class="mat-icon-14 ml-1 cursor-pointer text-gray-700"
        matTooltip="Budget at the time this alert was created"
        >help</mat-icon
      >
    </div>
    <div class="task-budget__stat">
      {{ task?.budget | currency: 'USD':'symbol':'1.0-2' }}
    </div>
  </div>
  <div class="task-budget__item">
    <div *ngIf="task?.managedBudget" class="mb-2">
      <div class="task-budget__meta text-gray-400">
        <ease-account-icon
          class="mr-1"
          [size]="16"
          [accountType]="'adwords'"
        ></ease-account-icon>
        Budget
        <mat-icon
          class="mat-icon-14 ml-1 cursor-pointer text-gray-700"
          matTooltip="Formula: Total budget - (Projected GLS Spend + Projected Bing Spend)"
          >help</mat-icon
        >
      </div>
      <div class="task-budget__stat">
        {{ task?.managedBudget | currency: 'USD':'symbol':'1.0-2' }}
        <button
          matTooltip="View Details"
          class="task-budget__breakdown-toggle"
          (click)="budgetBreakdownToggle.toggle()"
          mat-icon-button
        >
          <mat-icon>{{
            budgetBreakdownToggle?.isHidden ? 'expand_more' : 'expand_less'
          }}</mat-icon>
        </button>
        <ease-toggle #budgetBreakdownToggle="toggle">
          <ng-container *ngIf="!budgetBreakdownToggle?.isHidden">
            <div
              class="task-budget__divider task-budget__divider--horizontal"
            ></div>
            <div class="text-right">
              <span matTooltip="Total Budget" class="mt-2 text-sm">{{
                task?.budget | currency: 'USD':'symbol':'1.0-2'
              }}</span>
              <span
                matTooltip="Projected Bing Spend"
                *ngIf="task?.projectedBingSpend !== undefined"
                class="mt-2 flex flex-row items-center justify-end text-sm"
              >
                <strong>-</strong>
                <ease-account-icon
                  class="mx-1"
                  [size]="16"
                  [accountType]="'bing'"
                ></ease-account-icon>
                <mat-icon class="mat-icon-14 mr-1">trending_up</mat-icon>
                {{
                  task?.projectedBingSpend | currency: 'USD':'symbol':'1.0-2'
                }}
              </span>
              <span
                matTooltip="Projected GLS Spend"
                *ngIf="task?.projectedLsaSpend !== undefined"
                class="mt-2 flex flex-row items-center justify-end text-sm"
              >
                <strong>-</strong>
                <ease-account-icon
                  class="mx-1"
                  [size]="16"
                  [accountType]="'lsa'"
                ></ease-account-icon>
                <mat-icon class="mat-icon-14 mr-1">trending_up</mat-icon>
                {{ task?.projectedLsaSpend | currency: 'USD':'symbol':'1.0-2' }}
              </span>
            </div>
          </ng-container>
        </ease-toggle>
      </div>
    </div>
  </div>
  <div class="task-budget__item">
    <div class="task-budget__meta text-gray-400">
      Sugg.
      <ease-account-icon
        class="mx-1"
        [size]="16"
        [accountType]="'adwords'"
      ></ease-account-icon>
      Daily Budget
      <mat-icon
        class="mat-icon-14 ml-1 cursor-pointer text-gray-700"
        matTooltip="Suggested Daily Budget = Ads Budget &divide; Remaining Days In Calendar Month"
        >help</mat-icon
      >
    </div>
    <div class="task-budget__stat">
      {{ task?.suggestedBudget | currency: 'USD':'symbol':'1.0-2' }}
    </div>
  </div>
</div>
