import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { MetricsModule } from '../../../shared/metrics/metrics.module';
import { AccountIconModule } from '../../../shared/account-icon/account-icon.module';
import { SharedPipesModule } from '../../../shared/shared-pipes.module';
import { CustomerLsaSummaryComponent } from './customer-lsa-summary/customer-lsa-summary.component';
import { CustomerLsaSummaryDialogComponent } from './customer-lsa-summary-dialog/customer-lsa-summary-dialog.component';

@NgModule({
  declarations: [
    CustomerLsaSummaryComponent,
    CustomerLsaSummaryDialogComponent
  ],
  imports: [AccountIconModule, SharedModule, MetricsModule, SharedPipesModule],
  exports: [CustomerLsaSummaryComponent, CustomerLsaSummaryDialogComponent]
})
export class CustomerLsaSummaryModule {}
