import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { EaseNotification } from '../notification.interface';

@Component({
  selector: 'ease-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPanelComponent {
  @Input()
  notification: EaseNotification;

  @Output()
  dismiss: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public isExpanded: boolean = false;

  get limitCount() {
    return this.isExpanded ? null : 3;
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  onClick(event: MouseEvent) {
    this.clicked.emit(event);
  }

  onDismiss() {
    this.dismiss.emit();
  }
}
