<div class="divide-y-2 divide-solid divide-gray-300">
  <div *ngFor="let section of items" class="py-8 first:pt-0 last:pb-0">
    <div class="flex items-center justify-start">
      <div
        class="mr-2 flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-white"
      >
        <mat-icon class="mat-icon-14">local_mall</mat-icon>
      </div>
      <span class="font-semibold"
        >{{ section.accountName }} ({{ section.accountId }})</span
      >
      <a
        class="!min-w-[30px] !p-1"
        mat-button
        matTooltip="View in Merchant Center"
        [href]="
          'https://accounts.google.com/AccountChooser?service=merchants&passive=1209600&continue=' +
          'https://merchants.google.com/mc/overview?a=' +
          section.accountId +
          '&flowEntry=ServiceLogin&prompt=select_account'
        "
        target="_blank"
        ><mat-icon class="mat-icon-20">open_in_new</mat-icon></a
      >
    </div>

    <div class="divide-y-2 divide-solid divide-gray-100">
      <div
        *ngIf="
          section?.accountLevelIssue?.criticals?.length ||
          section?.accountLevelIssue?.errors?.length
        "
      >
        <h5 class="my-4 ml-1.5">
          Account Issues ({{
            section?.accountLevelIssue?.criticals?.length +
              section?.accountLevelIssue?.errors?.length
          }})
        </h5>

        <ng-container
          *ngFor="let issue of section.accountLevelIssue.criticals"
          [ngTemplateOutlet]="accountLevelIssue"
          [ngTemplateOutletContext]="{ $implicit: issue }"
        ></ng-container>

        <ng-container
          *ngFor="let issue of section.accountLevelIssue.errors"
          [ngTemplateOutlet]="accountLevelIssue"
          [ngTemplateOutletContext]="{ $implicit: issue }"
        ></ng-container>
      </div>

      <div
        *ngIf="
          section?.productLevelIssue?.disapproved?.length ||
          section?.productLevelIssue?.demoted?.length ||
          section?.productLevelIssue?.unaffected?.length
        "
      >
        <h5 class="my-4 ml-1.5">
          Product Issues ({{
            section?.productLevelIssue?.disapproved?.length +
              section?.productLevelIssue?.demoted?.length +
              section?.productLevelIssue?.unaffected?.length
          }})
        </h5>

        <ng-container
          *ngFor="let issue of section.productLevelIssue.disapproved"
          [ngTemplateOutlet]="productLevelIssue"
          [ngTemplateOutletContext]="{ $implicit: issue }"
        ></ng-container>

        <ng-container
          *ngFor="let issue of section.productLevelIssue.unaffected"
          [ngTemplateOutlet]="productLevelIssue"
          [ngTemplateOutletContext]="{ $implicit: issue }"
        ></ng-container>

        <ng-container
          *ngFor="let issue of section.productLevelIssue.demoted"
          [ngTemplateOutlet]="productLevelIssue"
          [ngTemplateOutletContext]="{ $implicit: issue }"
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #accountLevelIssue let-issue>
  <div class="my-4 flex justify-center">
    <div class="flex-none justify-center pl-1.5 pt-[1px]">
      <mat-icon
        class="mat-icon-20"
        [matTooltip]="'Severity: ' + issue.severity"
        [ngClass]="{
          'text-red-500': issue.severity === 'critical',
          'text-amber-800': issue.severity === 'error'
        }"
      >
        {{ issue.severity === 'critical' ? 'error_outline' : '' }}
        {{ issue.severity === 'error' ? 'warning_amber' : '' }}
      </mat-icon>
    </div>
    <span class="text-md mx-2 flex-grow">{{ issue.title }}</span>
    <a
      *ngIf="issue?.documentation"
      class="flex-1 self-center pb-[2px] text-xs text-blue-500 sm:flex-none"
      [href]="issue?.documentation"
      target="_blank"
      >Learn more</a
    >
  </div>
</ng-template>

<ng-template #productLevelIssue let-issue>
  <div class="my-4 flex justify-center">
    <div class="flex-none justify-center pl-1.5 pt-[1px]">
      <mat-icon
        class="mat-icon-20 ml-1"
        [matTooltip]="'Servability: ' + issue.servability"
        [ngClass]="{
          'text-red-500': issue.servability === 'disapproved',
          'text-amber-800':
            issue.servability === 'demoted' ||
            issue.servability === 'unaffected'
        }"
      >
        {{ issue.servability === 'disapproved' ? 'error_outline' : '' }}
        {{ issue.servability === 'demoted' ? 'trending_down' : '' }}
        {{ issue.servability === 'unaffected' ? 'warning_amber' : '' }}
      </mat-icon>
    </div>

    <span class="text-md mx-2 flex-grow"
      >{{ issue.detail }}
      <span *ngIf="issue?.numItems" class="text-xs italic"
        >(Affected items: <span class="underline">{{ issue?.numItems }}</span
        >)</span
      ></span
    >
    <a
      *ngIf="issue?.documentation"
      class="flex-1 self-center pb-[2px] text-xs text-blue-500 sm:flex-none"
      [href]="issue?.documentation"
      target="_blank"
      >Learn more</a
    >
  </div>
</ng-template>
