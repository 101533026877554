<ng-container *ngIf="task$ | async as task">
  <ng-container *ngIf="showTask$ | async; else loading">
    <ease-drawer-sidebar class="h-full min-h-0 flex-1">
      <mat-drawer-container
        class="flex h-full flex-row"
        [@.disabled]="true"
        autosize
      >
        <mat-drawer-content>
          <ease-task-view
            *ngIf="offset$ | async as offset"
            easeScrollSpyScrollContainer
            [task]="task"
            [offset]="offset.value"
            class="h-full min-h-0 overflow-auto"
          ></ease-task-view>
        </mat-drawer-content>
        <mat-drawer
          #drawer
          class="!border-gray-300 drop-shadow-md md:w-[40%] md:max-w-[600px]"
          position="end"
        >
          <ease-task-sidebar
            class="h-full min-h-0 overflow-auto"
          ></ease-task-sidebar>
        </mat-drawer>
      </mat-drawer-container>
    </ease-drawer-sidebar>
  </ng-container>
</ng-container>

<ng-template #windowSubtitleTemplate let-context="templateContext">
  <div class="flex flex-row items-center">
    <ease-entity-icon
      class="mr-1"
      [size]="16"
      [entity]="{
        entityType: context?.entityType,
        accountType: context?.accountType
      }"
    ></ease-entity-icon>
    <div class="truncate text-xs">
      {{ context?.entityName }}
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="flex h-full items-center">
    <ease-state-loading [message]="'Loading task'"></ease-state-loading>
  </div>
</ng-template>
