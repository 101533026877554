import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';

import { AppService } from '../app.service';
import { UserService } from '../users/user.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private appService: AppService,
    private userService: UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const url = state.url;
    return this.checkLogin(url);
  }

  canLoad(route: Route): Observable<boolean> {
    const url = window.location.pathname;
    return this.checkLogin(url);
  }

  checkLogin(redirectUrl: string): Observable<boolean> {
    return this.appService.isLoggedIn$.pipe(
      take(1),
      switchMap(isLoggedIn => {
        if (!isLoggedIn) {
          console.log('[AuthGuard] redirecting to login');

          // Get previous page for redirection after sign in
          this.authService.redirectToLogin({ redirectUrl, reload: false });
          return of(false);
        } else {
          // If logged in, also wait for currentUser$ to emit before
          // allowing navigation by emitting `true`
          return this.userService.currentUser$.pipe(
            map(() => true),
            take(1)
          );
        }
      })
    );
  }
}
