import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'input[easeAutofocus], textarea[easeAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  constructor(public elementRef: ElementRef) {}

  ngAfterViewInit() {
    setTimeout(() => this.elementRef.nativeElement.focus());
  }
}
