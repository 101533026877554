import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  FirebaseDbService,
  ListAsObject
} from 'src/app/shared/firebase-db.service';
import { TASK_STATUSES_PATH } from '../../shared/firebase-paths';
import { TaskService } from '../task.service';
import { firebaseJSON } from '../../shared/utils/functions';
import { TaskStatus } from './task-status.interface';

@Injectable({ providedIn: 'root' })
export class TaskStatusService {
  public statusObjectGetter: Observable<ListAsObject<TaskStatus>>;
  private taskStatuses$ = this.angularFire
    .getList(`/${TASK_STATUSES_PATH}`)
    .pipe(shareReplay(1));

  constructor(
    private angularFire: FirebaseDbService,
    private taskService: TaskService
  ) {
    this.statusObjectGetter = this.angularFire
      .getObject(`/${TASK_STATUSES_PATH}`)
      .pipe(
        map(status => (status.$exists() ? status : {})),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
  }

  getAllAsObject(): Observable<ListAsObject<TaskStatus>> {
    return this.statusObjectGetter;
  }

  getAll(includeNoStatus = false): Observable<TaskStatus[]> {
    return this.taskStatuses$.pipe(
      map((statuses: TaskStatus[]) => {
        const noStatusExists = statuses.some(
          phase => phase.$key === 'noStatus'
        );

        if (!includeNoStatus || noStatusExists) {
          return statuses;
        }

        statuses.unshift({
          $key: 'noStatus',
          name: 'No Status',
          color: 'grey'
        });

        return statuses;
      })
    );
  }

  get(statusId: string) {
    return this.angularFire
      .getObject(`/${TASK_STATUSES_PATH}/${statusId}`)
      .pipe(map(status => (status.$exists() ? status : null)));
  }

  create(status: Partial<TaskStatus>): firebase.database.ThenableReference {
    return this.angularFire
      .list(`/${TASK_STATUSES_PATH}`)
      .push(firebaseJSON(status));
  }

  update(statusId: string, statusFields: Partial<TaskStatus>): Promise<void> {
    return this.angularFire
      .object(`/${TASK_STATUSES_PATH}/${statusId}`)
      .update(firebaseJSON(statusFields));
  }

  validateName(name: string): Observable<boolean> {
    return this.angularFire
      .getList(`/${TASK_STATUSES_PATH}`, ref =>
        ref.orderByChild('name').equalTo(name)
      )
      .pipe(
        take(1),
        map((statuses: TaskStatus[]) =>
          statuses && statuses.length ? false : true
        )
      );
  }

  remove(statusId: string) {
    return this.taskService.getForStatus(statusId).pipe(
      take(1),
      switchMap(tasks =>
        Promise.all(tasks.map(task => this.taskService.setStatus(task.$key)))
      ),
      switchMap(() =>
        this.angularFire.object(`/${TASK_STATUSES_PATH}/${statusId}`).remove()
      ),
      take(1)
    );
  }
}
