import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { TypedFormArray } from 'src/app/shared/reactive-forms';
import { FileUploaderFile } from 'src/app/shared/file-uploader/file-uploader.interface';
import { AccountBuilderForm } from '../account-builder.interface';

@Component({
  selector: 'ease-ad-set-item',
  templateUrl: './ad-set-item.component.html',
  styleUrls: ['./ad-set-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdSetItemComponent implements OnInit {
  @Input() adSetControl: typeof this.adSetsControl.controls[0];
  @Input() adSetsControl: AccountBuilderForm['controls']['adSets'];
  @Input() dropdownName: boolean = false;
  @Input() names: string[];
  @Input() index: number;
  @Input() collapsed: boolean = false;
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  get filesArray(): TypedFormArray<FileUploaderFile> {
    return this.adSetControl.controls.files;
  }

  public names$: Observable<{ text: string; disabled: boolean }[]>;
  public validAdsDimensions = [
    '1024x768',
    '1024x90',
    '120x600',
    '120x60',
    '160x600',
    '200x200',
    '200x446',
    '220x90',
    '240x400',
    '240x133',
    '250x250',
    '250x360',
    '292x30',
    '300x1050',
    '300x250',
    '300x50',
    '300x600',
    '300x100',
    '300x31',
    '300x57',
    '320x480',
    '320x50',
    '320x100',
    '336x280',
    '375x50',
    '414x736',
    '425x600',
    '468x60',
    '480x32',
    '480x320',
    '580x400',
    '728x90',
    '736x414',
    '750x100',
    '750x200',
    '750x300',
    '768x1024',
    '88x31',
    '930x180',
    '950x90',
    '960x90',
    '970x250',
    '970x90',
    '970x66',
    '980x120',
    '980x90',
    '1200x1200',
    '1200x300',
    '1200x628'
  ];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.names$ = combineLatest([
      of(this.names),
      this.adSetsControl.valueChanges.pipe(startWith(this.adSetsControl.value))
    ]).pipe(
      map(([names, adSets]) =>
        names.map(name => {
          const disabled = adSets.findIndex(set => set.name === name) !== -1;
          return { text: name, disabled };
        })
      )
    );
  }

  onDelete() {
    this.delete.emit();
  }

  collapse() {
    this.collapsed = true;
    this.cdr.detectChanges();
  }
}
