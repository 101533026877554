import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual, pickBy, orderBy, merge } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  pairwise,
  Subject
} from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

import { TypedFormGroup } from 'src/app/shared/reactive-forms';
import { ConfirmService } from '../../../shared/confirm/confirm.service';
import { TaskModelState } from '../../task.model';
import { ChecklistActionButtonEvent } from './task-checklist-actions.service';
import { TaskChecklistTemplateService } from './task-checklist-template.service';
import {
  TaskChecklistActionButton,
  TaskChecklistCheckbox,
  TaskChecklistCheckboxInput,
  TaskChecklistCheckboxList,
  TaskChecklistCheckboxRadio,
  TaskChecklistData,
  TaskChecklistTemplate,
  TaskChecklistTemplateField,
  TaskChecklistTemplateFieldOptions
} from './task-checklist.interface';

@UntilDestroy()
@Component({
  selector: 'ease-task-checklist',
  templateUrl: './task-checklist.component.html',
  styleUrls: ['./task-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskChecklistComponent implements OnInit {
  @Input()
  set checklistData(checklistData: TaskChecklistData) {
    this._checklistData = checklistData || {};

    if (this.form) {
      this.incomingDataSource$.next(this.checklistData);
    }
  }

  get checklistData(): TaskChecklistData {
    return this._checklistData;
  }

  @Input()
  set checklistTemplate(checklistTemplate: TaskChecklistTemplate) {
    this._checklistTemplate = checklistTemplate;
    this.checklistTemplateFields$.next(checklistTemplate.fields);
  }

  get checklistTemplate(): TaskChecklistTemplate {
    return this._checklistTemplate;
  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setChecklistFormGroupState();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input() taskState: TaskModelState;
  @Output()
  checklistChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  checklistOnRemove: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  actionTriggered: EventEmitter<ChecklistActionButtonEvent> = new EventEmitter<ChecklistActionButtonEvent>();
  @Input() taskId: string;
  @Input() previewMode: boolean = false;

  public form: TypedFormGroup<TaskChecklistData>;
  private _checklistTemplate: TaskChecklistTemplate;
  private _checklistData: TaskChecklistData;
  private _disabled: boolean;
  private incomingDataSource$: Subject<TaskChecklistData> =
    new Subject<TaskChecklistData>();
  private incomingData$: Observable<TaskChecklistData> =
    this.incomingDataSource$
      .asObservable()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  private checklistTemplateFields$: BehaviorSubject<
    TaskChecklistTemplateField[]
  > = new BehaviorSubject<TaskChecklistTemplateField[]>([]);
  public checklistTemplateFieldsForRender: TaskChecklistTemplateField[];

  constructor(
    private cdr: ChangeDetectorRef,
    private confirmService: ConfirmService,
    public taskChecklistTemplateService: TaskChecklistTemplateService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    /**
     * (Re)Initialize checklist form group when template fields are ready or changed
     */
    this.checklistTemplateFields$
      .pipe(untilDestroyed(this))
      .subscribe(fields => {
        this.checklistTemplateFieldsForRender =
          this.generateGroupingFieldsView(fields);

        this.form = this.formBuilder.group({});
        this.generateControls();
        this.setChecklistFormGroupState();
      });

    this.checklistFormDataUpdater();
    this.checklistFormDataReceiver();
  }

  /**
   * Listen and emit checklist data for Firebase saving
   */
  private checklistFormDataUpdater(): void {
    /**
     * To avoid unrelated overwriting, listen and compare to
     * get the differences for saving only.
     *
     * Example of usage case, two different users are editing
     * a different field in the same task checklist and
     * save at the same time.
     *
     *  ✘ User A => checklist A => field A => save checklist A(overwrite user B changes)
     *  ✘ User B => checklist A => field B => save checklist A(overwrite user A changes)
     * 	✔ User A => checklist A => field A => save field A
     * 	✔ User B => checklist A => field B => save field B
     */
    combineLatest([
      this.form.valueChanges.pipe(
        startWith(this.form.value)
      ) as Observable<TaskChecklistData>,
      // Subscribe incomingData to ensure the latest value is available for pairwise
      this.incomingData$.pipe(startWith(this.checklistData))
    ])
      .pipe(
        untilDestroyed(this),
        pairwise(),
        // Stop any operations if changes are NOT made by current user
        filter(
          ([[formChangesPrevious], [formChangesCurrent]]) =>
            !isEqual(formChangesPrevious, formChangesCurrent)
        ),
        map(
          ([
            [formChangesPrevious, incomingDataPrevious],
            [formChangesCurrent]
          ]) => {
            // Always merge to get the latest previous if any "new" incomingData
            const previousValue = merge(
              formChangesPrevious,
              incomingDataPrevious
            );
            return pickBy(
              formChangesCurrent,
              (v, k) => !isEqual(previousValue[k], v)
            );
          }
        )
      )
      .subscribe(fieldToSave => {
        /**
         * Testing behaviors:
         *
         *  User A ┐                  ┌ field A => ✘ save field A & field B(incorrect saving behavior)
         *         |=> checklist A => |
         *  User B ┘                  └ field B => ✔ save field B(proper saving behavior)
         */
        this.checklistChanged.emit(fieldToSave);
      });
  }

  /**
   * Listen and patch incoming checklist data for immediately displaying
   */
  private checklistFormDataReceiver(): void {
    /**
     * Once received new data, patch immediately to avoid outdated
     * comparison for the next "checklistChanged" emission
     */
    this.incomingData$.pipe(untilDestroyed(this)).subscribe(incomingData => {
      /**
       * Important:
       * `emitEvent: false` is required here, otherwise we will
       * encounter situations with infinite loops. E.g.
       *
       * form changes -> save to firebase -> we receive firebase change ->
       * we patch value -> form changes -> infinite loop
       *
       * By choosing to not emit the event, we avoid this situation. But, this
       * also means using OnPush change detection is difficult to impossible
       * because of how the child checklist items render their values.
       *
       * Also, to avoid the latency of item state(view), trigger the view change
       * detection that all users would see any changes immediately.
       *
       * See:
       * https://stackoverflow.com/questions/53640559/change-detection-does-not-trigger-when-the-formgroup-values-change
       */
      this.form.patchValue(incomingData, { emitEvent: false });
      this.cdr.detectChanges();
    });
  }

  /**
   * To avoid race condition, set this after the form group is
   * generated or after when state changed. If a task is
   * completed or reopened, the view would be reliable.
   */
  setChecklistFormGroupState(): void {
    if (this.form) {
      this.disabled
        ? this.form.disable({
            emitEvent: false
          })
        : this.form.enable({
            emitEvent: false
          });
    }
  }

  generateGroupingFieldsView(
    templateValue: TaskChecklistTemplateField[]
  ): TaskChecklistTemplateField[] {
    let grouping = false;
    const newTemplateValue: TaskChecklistTemplateField[] = [];

    templateValue.forEach(field => {
      // If found a sectionHeaderDivider and is not grouping enabled, enable grouping.
      if (field.type === 'sectionHeadingDivider' && !grouping) {
        grouping = true;
        return newTemplateValue.push({ ...field, fields: [] });

        // If found a sectionHeaderDivider and is grouping enabled, push a new header and group inside the new divider.
      } else if (field.type === 'sectionHeadingDivider' && grouping) {
        return newTemplateValue.push({ ...field, fields: [] });

        // If not sectionHeaderDivider and still grouping, push inside last divider group.
      } else if (grouping) {
        if (newTemplateValue.length) {
          return newTemplateValue[newTemplateValue.length - 1].fields.push(
            field
          );
        }

        // For everything else push inside accumulator.
      } else {
        return newTemplateValue.push(field);
      }
    });

    return newTemplateValue;
  }

  generateControls(): void {
    return orderBy(this.checklistTemplate.fields, 'order').forEach(field => {
      if (field.key) {
        switch (field.type) {
          case 'checklistCheckbox':
            this.form.addControl(
              field.key,
              this.createCheckboxGroup(
                this.checklistData && this.checklistData[field.key]
                  ? this.checklistData[field.key]
                  : {}
              )
            );
            break;

          case 'checkboxRadio':
            this.form.addControl(
              field.key,
              this.createCheckboxRadioGroup(
                this.checklistData && this.checklistData[field.key]
                  ? this.checklistData[field.key]
                  : {}
              )
            );
            break;

          case 'checkboxInput':
            this.form.addControl(
              field.key,
              this.createCheckboxInputGroup(
                this.checklistData && this.checklistData[field.key]
                  ? this.checklistData[field.key]
                  : {}
              )
            );
            break;

          case 'checkboxList':
            this.form.addControl(
              field.key,
              this.createCheckboxListGroup(
                this.checklistData && this.checklistData[field.key]
                  ? this.checklistData[field.key]
                  : {},
                field.templateOptions
              )
            );
            break;

          case 'actionButton':
            this.form.addControl(
              field.key,
              this.createActionButtonGroup(
                this.checklistData && this.checklistData[field.key]
                  ? this.checklistData[field.key]
                  : {}
              )
            );
            break;

          case 'inputHorizontal':
            this.form.addControl(
              field.key,
              new FormControl(
                this.checklistData && this.checklistData[field.key]
                  ? this.checklistData[field.key]
                  : null,
                {
                  updateOn: 'blur'
                }
              )
            );
            break;

          default:
            this.form.addControl(
              field.key,
              new FormControl(
                this.checklistData && this.checklistData[field.key]
                  ? this.checklistData[field.key]
                  : null
              )
            );
            break;
        }
      }
    });
  }

  createCheckboxGroup(
    data: TaskChecklistData
  ): TypedFormGroup<TaskChecklistCheckbox> {
    return this.formBuilder.group({
      checked: this.formBuilder.control(
        data && data.checked ? data.checked : null
      ),
      disabled: this.formBuilder.control(
        data && data.disabled ? data.disabled : null
      ),
      completedAt: this.formBuilder.control(
        data && data.completedAt ? data.completedAt : null
      ),
      completedBy: this.formBuilder.control(
        data && data.completedBy ? data.completedBy : null
      ),
      note: this.formBuilder.group({
        body: this.formBuilder.control(
          data && data.note && data.note.body ? data.note.body : null
        ),
        createdAt: this.formBuilder.control(
          data && data.note && data.note.createdAt ? data.note.createdAt : null
        ),
        createdBy: this.formBuilder.control(
          data && data.note && data.note.createdBy ? data.note.createdBy : null
        )
      })
    });
  }

  createCheckboxRadioGroup(
    data: TaskChecklistData
  ): TypedFormGroup<TaskChecklistCheckboxRadio> {
    return this.formBuilder.group({
      checked: this.formBuilder.control(
        data && data.checked ? data.checked : null
      ),
      disabled: this.formBuilder.control(
        data && data.disabled ? data.disabled : null
      ),
      completedAt: this.formBuilder.control(
        data && data.completedAt ? data.completedAt : null
      ),
      completedBy: this.formBuilder.control(
        data && data.completedBy ? data.completedBy : null
      ),
      radio: this.formBuilder.control(data && data.radio ? data.radio : null)
    });
  }

  createCheckboxInputGroup(
    data: TaskChecklistData
  ): TypedFormGroup<TaskChecklistCheckboxInput> {
    return this.formBuilder.group({
      checked: this.formBuilder.control(
        data && data.checked ? data.checked : null
      ),
      disabled: this.formBuilder.control(
        data && data.disabled ? data.disabled : null
      ),
      completedAt: this.formBuilder.control(
        data && data.completedAt ? data.completedAt : null
      ),
      completedBy: this.formBuilder.control(
        data && data.completedBy ? data.completedBy : null
      ),
      value: this.formBuilder.control(data && data.value ? data.value : null, {
        updateOn: 'blur'
      })
    });
  }

  createCheckboxListGroup(
    data: TaskChecklistData,
    templateOptions: TaskChecklistTemplateFieldOptions
  ): TypedFormGroup<TaskChecklistCheckboxList> {
    const group: TypedFormGroup<TaskChecklistCheckboxList> =
      this.formBuilder.group({
        checked: this.formBuilder.control(
          data && data.checked ? data.checked : null
        ),
        disabled: this.formBuilder.control(
          data && data.disabled ? data.disabled : null
        ),
        completedAt: this.formBuilder.control(
          data && data.completedAt ? data.completedAt : null
        ),
        completedBy: this.formBuilder.control(
          data && data.completedBy ? data.completedBy : null
        )
      });

    if (templateOptions.options) {
      templateOptions.options.forEach(option => {
        group.addControl(
          option.key,
          this.formBuilder.group({
            checked: this.formBuilder.control(
              data && data[option.key] ? data[option.key].checked : null
            )
          })
        );
      });
    }

    return group;
  }

  createActionButtonGroup(
    value: TaskChecklistData
  ): TypedFormGroup<TaskChecklistActionButton> {
    return this.formBuilder.group({
      message: this.formBuilder.control(
        value && value.message ? value.message : null
      ),
      pending: this.formBuilder.control(
        value && value.pending ? value.pending : null
      ),
      disabled: this.formBuilder.control(
        value && value.disabled ? value.disabled : null
      ),
      error: this.formBuilder.control(
        value && value.error ? value.error : null
      ),
      success: this.formBuilder.control(
        value && value.success ? value.success : null
      ),
      completedAt: this.formBuilder.control(
        value && value.completedAt ? value.completedAt : null
      ),
      completedBy: this.formBuilder.control(
        value && value.completedBy ? value.completedBy : null
      ),
      data: this.formBuilder.control(value && value.data ? value.data : null)
    });
  }

  removeChecklist(): void {
    this.confirmService
      .confirm({
        message: 'Are you sure you want to remove this checklist?',
        confirmText: 'Delete',
        cancelText: 'Cancel'
      })
      .then(
        confirmResult =>
          confirmResult.confirm &&
          this.checklistOnRemove.emit(this.checklistTemplate.$key)
      );
  }
}
