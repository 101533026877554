import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostBinding,
  Output,
  EventEmitter
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { createWidget } from '@typeform/embed';

@Component({
  selector: 'ease-typeform-embed-dialog',
  templateUrl: './typeform-embed-dialog.component.html',
  styleUrls: ['./typeform-embed-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeformEmbedDialogComponent implements AfterViewInit, OnInit {
  @Input() title: string;
  @Input() formId: string;
  @Input() hidden: Record<string, string> = {};
  @ViewChild('embedTarget') embedTarget: ElementRef;
  @HostBinding('class') class = 'flex flex-col h-full';
  @Output() submitted: EventEmitter<string> = new EventEmitter<string>();

  constructor(private dialogRef: MatDialogRef<TypeformEmbedDialogComponent>) {}

  ngOnInit(): void {
    if (!this.formId) {
      throw new Error(
        'TypeformEmbedDialogComponent must receive a formId to work properly'
      );
    }
  }

  ngAfterViewInit(): void {
    createWidget(this.formId, {
      container: this.embedTarget.nativeElement,
      hidden: this.hidden,
      onSubmit: ({ responseId }) => {
        this.submitted.emit(responseId);
        this.dialogRef.close(responseId);
      }
    });
  }
}
