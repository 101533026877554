import { Type } from '@angular/core';
import { AccountBuilderComponent } from '../../accounts/account-builder/account-builder.component';
import { WindowConfig } from './window-options';
import { WindowPaneCreateAnnouncementComponent } from './window-pane-create-announcement/window-pane-create-announcement.component';
import { WindowPaneCreateFundingComponent } from './window-pane-create-funding/window-pane-create-funding.component';
import { WindowPaneCreateTaskComponent } from './window-pane-create-task/window-pane-create-task.component';
import { WindowPaneSendEmailComponent } from './window-pane-send-email/window-pane-send-email.component';
import { WindowPaneViewTaskComponent } from './window-pane-view-task/window-pane-view-task.component';

export type WindowType =
  | 'createTask'
  | 'viewTask'
  | 'sendEmail'
  | 'createAnnouncement'
  | 'accountBuilder'
  | 'createFunding';

type WindowsList = Record<WindowType, WindowDefinition>;

interface WindowDefinition {
  component: Type<any>;
  config: Omit<WindowConfig<any>, 'type'>;
}

export const windowTypes: WindowsList = {
  createTask: {
    component: WindowPaneCreateTaskComponent,
    config: {
      title: 'Create Task',
      icon: 'edit'
    }
  },
  viewTask: {
    component: WindowPaneViewTaskComponent,
    config: {
      title: 'View Task',
      icon: 'list_alt',
      firstToggleState: 'MAXIMIZED',
      allowDuplicates: false,
      routeable: true
    }
  },
  sendEmail: {
    component: WindowPaneSendEmailComponent,
    config: {
      title: 'Send Email',
      icon: 'email'
    }
  },
  createAnnouncement: {
    component: WindowPaneCreateAnnouncementComponent,
    config: {
      title: 'Create Announcement',
      icon: 'campaign'
    }
  },
  accountBuilder: {
    component: AccountBuilderComponent,
    config: {
      icon: 'bolt',
      title: 'Account Builder',
      allowDuplicates: false
    }
  },
  createFunding: {
    component: WindowPaneCreateFundingComponent,
    config: {
      title: 'Add New Funding',
      icon: 'card_giftcard',
      allowDuplicates: false
    }
  }
};
