import { Component, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'ease-grid-cell-renderer',
  templateUrl: './grid-cell-renderer.component.html',
  styleUrls: ['./grid-cell-renderer.component.scss']
})
export class GridCellRendererComponent implements ICellRendererAngularComp {
  template: TemplateRef<any>;
  templateContext: { $implicit: any; params: ICellRendererParams };

  refresh(params: ICellRendererParams): boolean {
    this.templateContext = {
      $implicit: params.value,
      params
    };
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.template = params['ngTemplate'];
    this.refresh(params);
  }
}
