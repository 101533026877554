<div class="flex flex-col gap-3 bg-white p-4 pb-0 md:flex-row">
  <div class="md:w-[80%]">
    <div
      size="sm"
      easeMarkdown="- Each {{ entity }} should be separated by a new {{
        method === 'upload' ? 'row' : 'line'
      }}"
    ></div>
    <div size="sm" easeMarkdown="- Column headers are **required**"></div>
    <div
      *ngIf="entity === 'customer' || entity === 'prospect'"
      size="sm"
      easeMarkdown="- For customers and prospects, the Customer Id (SKCID) column is **required**"
    ></div>
    <div
      *ngIf="entity === 'account'"
      size="sm"
      easeMarkdown="- For accounts, the Account Id (CID) and Account Type columns are **required**"
    ></div>
    <div
      size="sm"
      easeMarkdown="- Each column needs to be separated by a {{
        separator
      }}, no spaces"
    ></div>
    <div size="sm" easeMarkdown="**Example**" class="mt-2"></div>
    <ng-container *ngIf="entity === 'customer' || entity === 'prospect'">
      <div
        *ngIf="separator === 'comma'"
        size="sm"
        easeMarkdown="```
Customer Id,Fee Amount,Fee Label
SKC1002004006,110,GLS Spend
SKC1002004008,130,SK Fee
SKC1002004012,140,SK Fee"
      ></div>
      <div
        *ngIf="separator === 'tab'"
        size="sm"
        easeMarkdown="```
Customer Id Fee Amount  Fee Label
SKC1002004006 110 GLS Spend
SKC1002004008 130 SK Fee
SKC1002004012 140 SK Fee"
      ></div>
    </ng-container>
    <ng-container *ngIf="entity === 'account'">
      <div
        *ngIf="separator === 'comma'"
        size="sm"
        easeMarkdown="```
Account Type,Account Id,Fee Amount,Fee Label
bing,1369941254,100,SK Fee
lsa,2611360423,110,GLS Spend
adwords,3189668642,90,Adwords Spend"
      ></div>
      <div
        *ngIf="separator === 'tab'"
        size="sm"
        easeMarkdown="```
Account Type  Account Id  Fee Amount  Fee Label
bing  1369941254 100 SK Fee
lsa 2611360423  110  GLS Spend
adwords 3189668642  90  Adwords Spend"
      ></div>
    </ng-container>
  </div>
  <div>
    <div
      size="sm"
      easeMarkdown='- Any extra columns are treated as "custom data" and can be dynamically inserted as variables on the text editor below'
    ></div>
    <ng-container
      *ngTemplateOutlet="
        callout;
        context: {
          content:
            'From the example, the columns &quot;Fee Amount&quot; and &quot;Fee Label&quot; are treated as custom data'
        }
      "
    ></ng-container>
    <div
      size="sm"
      easeMarkdown='- All column headers are automatically transformed to be "variable friendly"'
      class="mt-2"
    ></div>
    <ng-container *ngIf="entity === 'customer' || entity === 'prospect'">
      <ng-container
        *ngTemplateOutlet="
          callout;
          context: {
            content:
              'From the example, the column headers will be transformed as `{customerId}`, `{feeAmout}`, and `{feeLabel}` respectively'
          }
        "
      ></ng-container>
    </ng-container>
    <ng-container *ngIf="entity === 'account'">
      <ng-container
        *ngTemplateOutlet="
          callout;
          context: {
            content:
              'From the example, the column headers will be transformed as `{accountType}`, `{accountId}`, `{feeAmout}`, and `{feeLabel}` respectively'
          }
        "
      ></ng-container>
    </ng-container>
  </div>
</div>
<div class="px-2 pb-4 text-center">
  <div
    size="sm"
    easeMarkdown="**Still having trouble?** Use this [Google sheet](https://docs.google.com/spreadsheets/d/1CgPes_2T1RuNGTgAxj42NrgHtuTV_EAZtmaJqsDt0lk/edit?usp=sharing) for examples and templates."
  ></div>
</div>

<ng-template #callout let-content="content">
  <div class="ml-5 flex flex-row rounded-md bg-gray-100 p-2 italic">
    <mat-icon class="mat-icon-16 mr-1 text-amber-700">lightbulb</mat-icon>
    <div [easeMarkdown]="content" size="sm"></div>
  </div>
</ng-template>
