import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { SharedFormModule } from '../shared-form.module';
import { MaterialChipsInputComponent } from './material-chips-input/material-chips-input.component';

@NgModule({
  imports: [SharedModule, SharedFormModule],
  declarations: [MaterialChipsInputComponent],
  exports: [MaterialChipsInputComponent]
})
export class MaterialChipsInputModule {}
