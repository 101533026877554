<div
  class="flex flex-col gap-4 sm:flex-row"
  ngFileDrop
  (uploadOutput)="onUploadOutput($event)"
  [uploadInput]="uploadInput"
  [ngClass]="{ 'is-drag-over': dragOver }"
>
  <ease-sheet-panel class="flex-1">
    <ease-sheet-content>
      <ng-container *ngIf="templateDetails$ | async; else noTemplateSelected">
        <div
          class="mb-2"
          *ngIf="
            !disableVariablesEdit &&
            hasSharedVariables &&
            emails.length > 1 &&
            layoutStyle === 'vertical'
          "
        >
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <strong>Shared Variables</strong>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ease-email-shared-variables
                [hiddenVariableFields]="hiddenVariableFields"
                [sharedEmailVariablesControl]="sharedEmailVariables"
                [useSharedValuesControl]="useSharedValuesControl"
                (copySharedEmailVariablesToAllRecipients)="
                  copySharedEmailVariablesToAllRecipients()
                "
              ></ease-email-shared-variables>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <mat-accordion [multi]="true">
          <mat-expansion-panel
            *ngFor="let emailGroup of emailControlForms; let i = index"
            [expanded]="true"
            [formGroup]="emailGroup"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="items-center justify-start">
                <span *ngIf="!emailGroup.valid" class="flex flex-row">
                  <mat-icon class="mat-icon-18 mr-1" color="warn">
                    error_outline
                  </mat-icon>
                </span>
                <span
                  class="w-0 flex-1 truncate"
                  [ngClass]="{ 'text-red-500': !emailGroup.valid }"
                  >{{
                    emailGroup.controls.To.value || 'Select recipient below'
                  }}</span
                >
              </mat-panel-title>
              <mat-panel-description class="items-center justify-end">
                <button
                  type="button"
                  class="remove-recipient"
                  mat-icon-button
                  *ngIf="emails.length > 1"
                  (click)="removeRecipient(i)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="mb-2"><strong>Recipients</strong></div>
            <mat-form-field
              floatLabel="always"
              [ngClass]="{ '!hidden': emailsWithOtherRecipients.has(i) }"
            >
              <mat-label>To (Required)</mat-label>
              <mat-select
                [panelClass]="'contact-selector-panel'"
                class="contact-selector"
                #contactSelector
                (selectionChange)="
                  onRecipientSelectChange($event, i, customRecipient)
                "
                placeholder="Select a contact"
                formControlName="To"
              >
                <mat-select-trigger>
                  {{ emailGroup.controls.To.value }}
                </mat-select-trigger>
                <mat-option
                  *ngFor="let contact of customerContacts"
                  [value]="contact.email"
                >
                  <div class="flex flex-row">
                    <div class="flex-1">
                      {{ contact.firstName }} {{ contact.lastName }}
                    </div>
                    <ease-contact-type-view
                      [type]="contact.type"
                    ></ease-contact-type-view>
                  </div>
                </mat-option>
                <mat-option value="other">Other</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              floatLabel="always"
              [ngClass]="{ '!hidden': !emailsWithOtherRecipients.has(i) }"
              class="mb-4"
            >
              <mat-label>To (Required)</mat-label>
              <input
                #customRecipient
                placeholder="Email address"
                formControlName="To"
                matInput
              />
              <mat-hint
                class="cursor-pointer !text-blue-500"
                (click)="selectFromContacts(i, contactSelector)"
                ><a>Select from contacts</a></mat-hint
              >
            </mat-form-field>
            <ease-material-chips-input
              [floatLabel]="'always'"
              [showNextSuggestions]="true"
              [suggestionsOnly]="false"
              [items]="customerContactsSuggestions"
              [chipsFilters]="chipsFilters"
              [control]="$any(emailGroup).controls.CC"
              [placeholder]="'Comma-separated emails'"
              [label]="'CC (Optional)'"
              class="mb-1"
            >
            </ease-material-chips-input>
            <ease-material-chips-input
              [floatLabel]="'always'"
              [showNextSuggestions]="true"
              [suggestionsOnly]="false"
              [items]="customerContactsSuggestions"
              [chipsFilters]="chipsFilters"
              [control]="$any(emailGroup).controls.BCC"
              [placeholder]="'Comma-separated emails'"
              [label]="'BCC (Optional)'"
              class="mb-1"
            >
            </ease-material-chips-input>
            <div
              *ngIf="
                !disableVariablesEdit &&
                !useSharedValuesControl.value &&
                emailGroup.controls.To.value &&
                emailGroup.controls.To.valid
              "
            >
              <div class="mb-2">
                <strong>Variables</strong>
              </div>
              <ease-email-variables
                [hiddenVariables]="hiddenVariableFields"
                [controls]="emailGroup.controls.Data"
              ></ease-email-variables>
              <button
                type="button"
                (click)="copySharedEmailVariablesToRecipient(i)"
                class="-ml-5"
                *ngIf="
                  !useSharedValuesControl.value &&
                  hasSharedVariables &&
                  emails.length > 1
                "
                mat-button
              >
                <mat-icon>file_copy</mat-icon>
                <span>Copy shared values</span>
              </button>
            </div>
            <div *ngIf="listId">
              <mat-slide-toggle
                color="primary"
                formControlName="AddRecipientsToList"
              >
                Add recipients to email journey
              </mat-slide-toggle>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="mt-5">
          <button type="button" (click)="addRecipient()" mat-button>
            <mat-icon>add</mat-icon>
            <span>Add Separate Recipient</span>
          </button>

          <input
            id="attachFile"
            type="file"
            class="hidden"
            ngFileSelect
            (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput"
            multiple
          />
          <label for="attachFile" class="email-upload-button">
            <a mat-button>
              <mat-icon>attach_file</mat-icon>
              <span>Attach Files</span>
            </a>
          </label>
        </div>
        <mat-list *ngIf="attachments.length > 0" dense>
          <h3 mat-subheader>Attachments</h3>
          <mat-list-item *ngFor="let file of attachments">
            <mat-icon mat-list-icon>description</mat-icon>
            <h4 class="flex flex-row items-center justify-start" mat-line>
              <span>{{ file.name }}</span>
              <mat-icon
                title="Remove attachment"
                (click)="removeFile(file.id)"
                class="mat-icon-18 ml-2 cursor-pointer"
                >close</mat-icon
              >
            </h4>
            <p mat-line>{{ file.size | filesize }}</p>
          </mat-list-item>
        </mat-list>
      </ng-container>
    </ease-sheet-content>
  </ease-sheet-panel>

  <div
    *ngIf="
      !disableVariablesEdit &&
      hasSharedVariables &&
      emails.length > 1 &&
      layoutStyle === 'horizontal'
    "
    class="flex-1"
  >
    <h4>Shared Variables</h4>
    <ease-email-shared-variables
      [hiddenVariableFields]="hiddenVariableFields"
      [sharedEmailVariablesControl]="sharedEmailVariables"
      [useSharedValuesControl]="useSharedValuesControl"
      (copySharedEmailVariablesToAllRecipients)="
        copySharedEmailVariablesToAllRecipients()
      "
    ></ease-email-shared-variables>
  </div>
</div>

<ng-template #noTemplateSelected>
  <h4 class="m-0 text-center">
    {{
      isWorking
        ? 'Loading templates...'
        : 'Select a template above to get started'
    }}
  </h4>
</ng-template>
