import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { EaseMaterialModule } from '../../shared/ease-material.module';
import { TaskWindowerModule } from '../../shared/window/window-pane-view-task/task-windower.module';
import { ContextMenuModule } from '../../shared/context-menu/context-menu.module';
import { ListSelectorModule } from '../../shared/list-selector/list-selector.module';
import { PopoverModule } from '../../shared/popover/popover.module';
import { SharedFormModule } from '../../shared/shared-form.module';
import { SharedModule } from '../../shared/shared.module';
import { UserSelectorInputModule } from '../../shared/user-selector-input/user-selector-input.module';
import { TaskBoardListModule } from '../task-board-list/task-board-list.module';
import { TaskItemActionMenuModule } from '../task-dashboards/task-dashboards-widgets/task-item-action-menu/task-item-action-menu.module';
import { TaskPanelsModule } from '../task-panels/task-panels.module';
import { TaskPhaseModule } from '../task-phase/task-phase.module';
import { TaskStatusModule } from '../task-status/task-status.module';
import { TaskSummaryModule } from '../task-summary/task-summary.module';
import { TaskUserMenuModule } from '../task-user-menu/task-user-menu.module';
import { SubtaskHelpPopoverComponent } from './subtask-help-popover/subtask-help-popover.component';
import { SubtaskItemComponent } from './subtask-item/subtask-item.component';
import { SubtaskSelectorComponent } from './subtask-selector/subtask-selector.component';
import { ProjectOverviewComponent } from './project-overview.component';
import { SubtaskProgressComponent } from './subtask-progress/subtask-progress.component';

@NgModule({
  declarations: [
    ProjectOverviewComponent,
    SubtaskItemComponent,
    SubtaskHelpPopoverComponent,
    SubtaskSelectorComponent,
    SubtaskProgressComponent
  ],
  exports: [
    ProjectOverviewComponent,
    SubtaskHelpPopoverComponent,
    SubtaskSelectorComponent,
    SubtaskProgressComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    EaseMaterialModule,
    TaskSummaryModule,
    TaskWindowerModule,
    SharedFormModule,
    SatPopoverModule,
    PopoverModule,
    VirtualScrollerModule,
    TaskPanelsModule,
    TaskUserMenuModule,
    DragDropModule,
    TaskBoardListModule,
    TaskStatusModule,
    TaskPhaseModule,
    UserSelectorInputModule,
    TaskItemActionMenuModule,
    ContextMenuModule,
    ListSelectorModule
  ]
})
export class TaskProjectModule {}
