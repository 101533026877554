<ease-sheet class="popover popover-metric shadow-md">
  <div [ngClass]="optiscoreMetric?.ragStatusColor">
    <div class="header metric-background">
      <span class="description" *ngIf="optiscoreMetric?.ragStatusDescription">
        {{ optiscoreMetric?.ragStatusDescription }}
      </span>

      <span class="description" *ngIf="!optiscoreMetric?.ragStatusDescription">
        Not enough data to display optiscore
      </span>
    </div>
  </div>
  <div class="details">
    <div class="row flex flex-row">
      <div
        class="column detail flex flex-1 flex-col items-center justify-center"
      >
        <ease-metric-simple
          label="Optiscore"
          [value]="optiscoreMetric.optiscore + '%'"
        ></ease-metric-simple>
      </div>
    </div>
    <div
      matTooltip="This account has a high impact on our Agency-level optiscore"
      class="bg-gray-100 text-center"
      *ngIf="
        optiscoreMetric.optiscoreWeight >
        accountDashboardService.highImpactOptiscoreWeight
      "
    >
      <span class="flex items-center justify-center p-1 text-xs">
        <mat-icon class="mat-icon-16 mr-1">stars</mat-icon
        ><strong>High Impact Account</strong>
      </span>
    </div>
    <div class="flex items-center py-4 px-2">
      <mat-icon class="mat-icon-16 mr-2 flex-none">info</mat-icon>
      <div class="text-xs">
        This score is a snapshot as of yesterday and is not updated in real-time
        like in the Ads UI.
      </div>
    </div>
    <div class="row" *ngIf="optiscoreMetric?.metadata?.chartData.labels.length">
      <ease-chart
        [height]="200"
        [data]="optiscoreMetric?.metadata?.chartData"
        [type]="'line'"
        [options]="accountDashboardService.percentageChartOptions"
      >
      </ease-chart>
    </div>
  </div>
</ease-sheet>
