import { NgModule } from '@angular/core';

import { AccountIconModule } from '../../../shared/account-icon/account-icon.module';
import { DatepickerModule } from '../../../shared/datepicker/datepicker.module';
import { SharedFormModule } from '../../../shared/shared-form.module';
import { SharedModule } from '../../../shared/shared.module';
import { SharedPipesModule } from '../../../shared/shared-pipes.module';
import { ChartsModule } from '../../../shared/charts/charts.module';
import { CustomerAccountBudgetWrapperComponent } from '../customer-account-budget-wrapper/customer-account-budget-wrapper.component';
import { CustomerAccountBudgetGroupComponent } from './customer-account-budget-group/customer-account-budget-group.component';
import { CustomerAccountBudgetComponent } from './customer-account-budget/customer-account-budget.component';

@NgModule({
  imports: [
    AccountIconModule,
    ChartsModule,
    DatepickerModule,
    SharedFormModule,
    SharedModule,
    SharedPipesModule
  ],
  declarations: [
    CustomerAccountBudgetComponent,
    CustomerAccountBudgetGroupComponent,
    CustomerAccountBudgetWrapperComponent
  ],
  exports: [
    CustomerAccountBudgetComponent,
    CustomerAccountBudgetGroupComponent,
    CustomerAccountBudgetWrapperComponent
  ]
})
export class CustomerAccountBudgetModule {}
