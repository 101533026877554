<div class="max-w-[330px] bg-white px-3 py-2 text-xs text-gray-800">
  <!-- Subtasks description -->
  <p class="mb-2">
    Subtasks are tasks that belong to a larger task. They can be completed
    independently of the parent task.
  </p>

  <p class="mb-2">
    Subtasks are useful for breaking down larger projects into smaller pieces.
    They are useful for tracking the progress of a larger task by independently
    managing the subtasks.
  </p>

  <div class="mb-1 font-bold">Limitations:</div>
  <ul class="mb-2 space-y-1">
    <li class="flex flex-row items-start justify-start">
      <mat-icon class="mat-icon-14 mr-2">block</mat-icon>
      <div>Subtasks must be based on a task template</div>
    </li>
    <li class="flex flex-row items-start justify-start">
      <mat-icon class="mat-icon-14 mr-2">block</mat-icon>
      <div>Subtasks cannot have subtasks</div>
    </li>
    <li class="flex flex-row items-start justify-start">
      <mat-icon class="mat-icon-14 mr-2">block</mat-icon>
      <div>Subtasks cannot be scheduled</div>
    </li>
    <li class="flex flex-row items-start justify-start">
      <mat-icon class="mat-icon-14 mr-2">block</mat-icon>
      <div>
        Subtasks and their parent must be associated with the same customer or
        account
      </div>
    </li>
  </ul>
</div>
