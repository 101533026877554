import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatChip, MatChipInputEvent } from '@angular/material/chips';

import {
  ListDisplayGroup,
  ListDisplayItem,
  ListDisplayItemValue
} from '../../shared.interface';
import { ChipFilterFn } from '../material-chips-input.interface';

@UntilDestroy()
@Component({
  selector: 'ease-material-chips-input',
  templateUrl: './material-chips-input.component.html',
  styleUrls: ['./material-chips-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialChipsInputComponent implements OnInit {
  @Input()
  allowDuplicates: boolean = false;
  @Input()
  addOnBlur: boolean = true;
  @Input()
  floatLabel: string;
  @Input()
  label: string;
  @Input()
  chipsFilters: ChipFilterFn[] = [];
  @Input()
  control: FormControl<ListDisplayItemValue[]>;
  @Input()
  set items(items: ListDisplayItem[] | ListDisplayGroup[]) {
    if (items && items.length) {
      const sample = items[0] as ListDisplayGroup;
      this.grouped =
        sample.name && (sample.items !== null || sample.items !== undefined);
    }
    this._items = items || [];
    this.getChildren(items);

    this.updateSelectedItems();
  }

  get items(): ListDisplayItem[] | ListDisplayGroup[] {
    return this._items;
  }

  @Input()
  placeholder: string = 'Add an item...';
  @Input()
  removable: boolean = true;
  @Input()
  selectable: boolean = true;
  @Input()
  showNextSuggestions: boolean = false;
  @Input()
  set disabled(status: boolean) {
    if (status) {
      this.autocompleteControl.disable();
    }
    this._disabled = status;
  }

  get disabled(): boolean {
    return this._disabled;
  }
  @Input()
  suggestionsOnly: boolean = true;
  @Input() hint: string;
  @ViewChild('autocompleteInput', {
    read: MatAutocompleteTrigger,
    static: true
  })
  autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('autocompleteInput', { static: true })
  autocompleteInput: ElementRef;
  @ContentChild('chipTemplate') chipTemplate: TemplateRef<MatChip>;
  private _disabled: boolean = false;
  private itemsUpdated$: Subject<void> = new Subject<void>();
  private _items: ListDisplayItem[] | ListDisplayGroup[] = [];
  public children: ListDisplayItem[];
  public filteredItems$: Observable<ListDisplayItem[] | ListDisplayGroup[]>;
  public autocompleteControl: FormControl<ListDisplayItemValue> =
    new FormControl({
      value: null,
      disabled: this.disabled
    });
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  get selectedValues(): ListDisplayItemValue[] {
    return this.selectedItems.map(item => item.value);
  }

  public selectedItems: ListDisplayItem[] = [];
  public grouped = false;

  constructor() {}

  ngOnInit() {
    if (!this.control) {
      throw new Error(
        'You must pass a FormControl to MaterialChipsInputComponent'
      );
    }

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateSelectedItems();
    });

    this.filteredItems$ = combineLatest([
      this.autocompleteControl.valueChanges.pipe(startWith(null)),
      this.itemsUpdated$.pipe(startWith(null))
    ]).pipe(map(([term]: [string, void]) => this.updateFilteredItems(term)));

    this.updateSelectedItems();
  }

  getChildren(items: ListDisplayItem[] | ListDisplayGroup[]) {
    if (this.grouped) {
      this.children = (items as ListDisplayGroup[]).reduce(
        (siblings, child) => [...siblings, ...child.items],
        []
      );
    } else {
      this.children = items as ListDisplayItem[];
    }
  }

  userAddedChip(event: MatChipInputEvent) {
    if (!this.suggestionsOnly) {
      const value = event.value || '';
      this.addUserChip(value);
    }
  }

  addUserChip(value: ListDisplayItemValue) {
    if (value) {
      let item: ListDisplayItem;

      if (typeof value == 'number') {
        item = {
          value,
          viewValue: `${value}`
        };
      } else {
        item = { value: value.trim(), viewValue: value.trim() };
      }

      this.add(item);
    }
  }

  add(item: ListDisplayItem): boolean {
    let shouldAdd = true;

    if (this.chipsFilters && this.chipsFilters.length) {
      shouldAdd = this.chipsFilters.every(filterFn => filterFn(item));
    }

    if (!this.allowDuplicates && shouldAdd) {
      shouldAdd = !this.selectedValues.includes(item.value);
    }

    if (shouldAdd) {
      this.autocompleteControl.setErrors(null);
      this.selectedItems.push(item);

      setTimeout(() => (this.autocompleteInput.nativeElement.value = ''));
      this.control.setValue(this.selectedValues);
      this.itemsUpdated$.next();
    } else {
      this.autocompleteControl.setErrors({ invalid: true });
    }

    return shouldAdd;
  }

  remove(item: ListDisplayItem): void {
    const index = this.selectedItems.indexOf(item);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.control.setValue(this.selectedValues);
      this.itemsUpdated$.next();
    }
  }

  onPaste(event: ClipboardEvent) {
    if (!this.suggestionsOnly) {
      event.clipboardData
        .getData('Text')
        .split(/;|,|\n/)
        .forEach(value => this.addUserChip(value));
    }
  }

  filterItems(items: ListDisplayItem[], term?: string): ListDisplayItem[] {
    const notSelectedFilter = (item: ListDisplayItem): boolean =>
      !this.selectedItems.some(
        selectedItem => selectedItem.value === item.value
      );
    const matchesTermFilter = (item: ListDisplayItem): boolean =>
      term
        ? item.viewValue.toLowerCase().indexOf(term.toLowerCase()) > -1
        : true;

    return items.filter(
      item => notSelectedFilter(item) && matchesTermFilter(item)
    );
  }

  updateFilteredItems(term?: string): ListDisplayGroup[] | ListDisplayItem[] {
    const filteredItems: ListDisplayGroup[] | ListDisplayItem[] = this.grouped
      ? this.items.map(group => ({
          ...group,
          items: this.filterItems(group.items, term)
        }))
      : this.filterItems(this.items as ListDisplayItem[], term);

    return filteredItems;
  }

  updateSelectedItems() {
    const selectedItems: (ListDisplayItemValue | ListDisplayItem)[] = this
      .control
      ? this.control.value
      : [];

    if (this.suggestionsOnly) {
      this.selectedItems =
        selectedItems && selectedItems.length && this.children?.length
          ? this.children.filter(item => selectedItems.includes(item.value))
          : [];
    } else {
      selectedItems.forEach(item => {
        if (typeof item === 'string' || typeof item === 'number') {
          this.addUserChip(item);
        } else if (item.value) {
          this.addUserChip(item.value);
        }
      });
    }

    this.itemsUpdated$.next();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const added = this.add({
      value: event.option.value,
      viewValue: event.option.viewValue
    });

    if (added && this.showNextSuggestions) {
      this.openAutocompletePanel();
    }
  }

  openAutocompletePanel() {
    setTimeout(() => {
      this.autocompleteInput.nativeElement.focus();
      this.autocompleteTrigger._onChange('');
      this.autocompleteTrigger.openPanel();
    });
  }
}
