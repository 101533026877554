import { NgModule } from '@angular/core';

import { SharedFormModule } from '../../../shared/shared-form.module';
import { SharedModule } from '../../../shared/shared.module';
import { SharedPipesModule } from '../../../shared/shared-pipes.module';
import { JobsDialogModule } from '../../../jobs/jobs-dialog/jobs-dialog.module';
import { MarkdownModule } from '../../../shared/markdown/markdown.module';
import { RoleSelectorModule } from '../../../shared/role-selector/role-selector.module';
import { IndexerModule } from '../../../shared/indexer/indexer.module';
import { TypeformEmbedModule } from '../../../shared/typeform-embed/typeform-embed.module';
import { TaskChecklistComponent } from './task-checklist.component';
import { TaskChecklistGroupComponent } from './task-checklist-group/task-checklist-group.component';
import { TaskChecklistCheckboxComponent } from './task-checklist-checkbox/task-checklist-checkbox.component';
import { TaskChecklistCheckboxInputComponent } from './task-checklist-checkbox-input/task-checklist-checkbox-input.component';
import { TaskChecklistCheckboxListComponent } from './task-checklist-checkbox-list/task-checklist-checkbox-list.component';
import { TaskChecklistCheckboxRadioComponent } from './task-checklist-checkbox-radio/task-checklist-checkbox-radio.component';
import { TaskChecklistActionbuttonComponent } from './task-checklist-actionbutton/task-checklist-actionbutton.component';
import { ChecklistFieldsRendererComponent } from './checklist-fields-renderer/checklist-fields-renderer.component';
import { GetHideExpressionClassPipe } from './checklist-fields-renderer/get-hide-expression-class.pipe';

@NgModule({
  imports: [
    SharedModule,
    SharedPipesModule,
    SharedFormModule,
    JobsDialogModule,
    MarkdownModule,
    RoleSelectorModule,
    IndexerModule,
    TypeformEmbedModule
  ],
  declarations: [
    TaskChecklistComponent,
    TaskChecklistGroupComponent,
    TaskChecklistCheckboxComponent,
    TaskChecklistCheckboxInputComponent,
    TaskChecklistCheckboxListComponent,
    TaskChecklistCheckboxRadioComponent,
    TaskChecklistActionbuttonComponent,
    ChecklistFieldsRendererComponent,
    GetHideExpressionClassPipe
  ],
  exports: [TaskChecklistGroupComponent, TaskChecklistComponent]
})
export class TaskChecklistModule {}
