<div class="p-input-filled" #defaultAnchor>
  <mat-form-field
    *ngIf="!customAnchor"
    (click)="!isReadOnly ? calendarPopover.open() : null"
  >
    <input
      [value]="control.value | date: 'MM/dd/y'"
      [disabled]="control.disabled"
      [placeholder]="placeholder"
      [readonly]="true"
      matInput
    />
    <button
      [ngClass]="{ '!invisible': !control.value }"
      [disabled]="!control.value"
      mat-icon-button
      matSuffix
      (click)="resetCalendar($event)"
    >
      <mat-icon class="mat-icon-16">close</mat-icon>
    </button>
    <mat-icon class="mat-icon-16" matSuffix>today</mat-icon>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  </mat-form-field>

  <sat-popover
    #calendarPopover
    [anchor]="customAnchor ? customAnchor : defaultAnchor"
    [verticalAlign]="verticalAlign"
    [horizontalAlign]="horizontalAlign"
    [hasBackdrop]="hasBackdrop"
    (closed)="closed.emit($event)"
  >
    <ng-container *ngIf="calendarPopover.isOpen()">
      <ng-container *ngIf="regionHolidays$ | async as regionHolidays">
        <p-calendar
          [formControl]="control"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [defaultDate]="defaultDate"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [yearRange]="
            holidayService.currentYear + ':' + holidayService.maxYear
          "
          (onSelect)="writeValue($event); calendarPopover.close()"
          [inline]="true"
          [selectOtherMonths]="true"
          styleClass="picker"
          class="primeng"
        >
          <ng-template pTemplate="date" let-date>
            <!-- date.month starts at 0 -- while date.day and date.year starts at 1 -->
            <ng-container
              *ngIf="
                (regionHolidays?.holidays)[
                  date.month + 1 + '/' + date.day + '/' + date.year
                ] as holiday;
                else noHolidays
              "
            >
              <div
                class="calendar-day"
                *easeRichTooltip="holidayTooltip; anchorClass: 'w-full h-full'"
              >
                <div class="day">{{ date.day }}</div>

                <div class="holiday-indicators">
                  <span
                    *ngFor="let country of holiday.countries | keyvalue"
                    class="indicator"
                    [ngClass]="country.key"
                  ></span>
                </div>
              </div>

              <ng-template #holidayTooltip>
                <div class="holiday-tooltip flex flex-col">
                  <div
                    *ngFor="let meta of holiday.tooltip"
                    class="item flex flex-row"
                  >
                    <div>
                      <div
                        class="indicator mr-1"
                        [ngClass]="meta.country"
                      ></div>
                    </div>
                    <div>
                      <div class="font-medium">{{ meta.name }}</div>
                      <div class="text-grey">{{ meta.location }}</div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>

            <ng-template #noHolidays>
              <div class="calendar-day">
                <div class="day">{{ date.day }}</div>
              </div>
            </ng-template>
          </ng-template>

          <ng-template pTemplate="footer">
            <div class="holiday-legend">
              <div
                *ngFor="let country of regionHolidays.countries | keyvalue"
                class="item"
              >
                <div class="indicator" [ngClass]="country.key"></div>
                <div class="label">
                  Holiday in
                  <ng-container [ngSwitch]="country.key">
                    <span *ngSwitchCase="'CA'">Canada</span>
                    <span *ngSwitchCase="'US'">USA</span>
                    <span *ngSwitchCase="'ZA'">South Africa</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>
        </p-calendar>
      </ng-container>
    </ng-container>
  </sat-popover>
</div>
