import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import difference from 'lodash-es/difference';

import { ListSelectorComponent } from '../list-selector/list-selector.component';
import { UserService } from '../../users/user.service';
import {
  UserGroup,
  UserGroupsService
} from '../../users/user-groups/user-groups.service';
import { UserSelected, UserSelectorItemModel } from './user-selector.interface';

@Component({
  selector: 'ease-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSelectorComponent implements OnInit {
  @Input()
  set selectedUsers(users: string[]) {
    this.updateSelectedUsers(users || []);
    this.selectedGroups = this.getSelectedGroups(users);
  }
  @Input()
  selectIcon: string;
  @Input()
  onlyUsers: boolean = false;
  @Output()
  selected: EventEmitter<UserSelected> = new EventEmitter<UserSelected>();
  @Output()
  starToggled: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  deselected: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('userSelector', { static: true })
  userSelector: ListSelectorComponent;

  public selectedUsersArray: string[] = [];
  public selectedUsersObject: { [userId: string]: boolean } = {};
  public selectedGroups: Observable<string[]>;
  public users$: Observable<UserSelectorItemModel[]>;
  public userGroups$: Observable<UserGroup[]>;
  private userGroupsArray: UserGroup[];

  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private userGroupsService: UserGroupsService
  ) {}

  ngOnInit(): void {
    this.users$ = combineLatest([
      this.userService.users,
      this.onlyUsers ? of([]) : this.userGroupsService.getAll()
    ]).pipe(
      map(([users, userGroups]) => {
        this.userGroupsArray = userGroups;
        users.map((user: UserSelectorItemModel) => (user.type = 'user'));
        userGroups.map(
          (group: UserSelectorItemModel) => (group.type = 'group')
        );
        return users.concat(userGroups) as UserSelectorItemModel[];
      })
    );
  }

  updateSelectedUsers(selectedUsers: string[]) {
    this.getSelectedGroups(selectedUsers)
      .pipe(take(1))
      .subscribe(selectedGroups => {
        this.selectedUsersArray = [...selectedUsers, ...selectedGroups];
        this.selectedUsersObject = this.selectedUsersArray.reduce(
          (acc: { [userId: string]: boolean }, userId: string) => {
            acc[userId] = true;
            return acc;
          },
          {}
        );

        this.cdr.detectChanges();
      });
  }

  getSelectedGroups(usersAssigned: string[]) {
    return this.userGroupsService
      .getAll()
      .pipe(
        map(userGroups =>
          userGroups
            .filter(
              userGroup =>
                difference(userGroup.users, usersAssigned).length === 0
            )
            .map(userGroup => userGroup.$key)
        )
      );
  }

  focusInput() {
    this.userSelector && this.userSelector.focusInput();
  }

  clearInput() {
    this.userSelector && this.userSelector.clearInput();
  }

  onSelectUser(user: string): void {
    const groupItem = this.checkIsGroup(user);
    if (groupItem) {
      groupItem.users.map(userId =>
        this.selected.emit({
          userId,
          method: 'group'
        })
      );
    } else {
      this.selected.emit({ userId: user, method: 'direct' });
    }
  }

  onDeselectUser(userId: string) {
    const groupItem = this.checkIsGroup(userId);

    if (groupItem) {
      groupItem.users.map(user => {
        this.deselected.emit(user);
      });
    } else {
      this.deselected.emit(userId);
    }
  }

  checkIsGroup(groupId: string) {
    return this.userGroupsArray.find(userGroup => userGroup.$key === groupId);
  }
}
