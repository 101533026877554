import { combineLatest, Observable, of } from 'rxjs';

import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { CustomerAccountsService } from '../customer-accounts/customer-accounts.service';
import { CUSTOMER_WEBSITES_PATH } from '../../shared/firebase-paths';
import { SkSyncHttpService } from '../../shared/sksync-http.service';
import {
  CombinedCustomerWebsites,
  CustomerWebsite,
  SkSyncCustomerWebsitesResponse,
  WebsiteRepository
} from './customer-websites.interface';

@Injectable({ providedIn: 'root' })
export class CustomerWebsitesService {
  constructor(
    private angularFire: FirebaseDbService,
    private customerAccountsService: CustomerAccountsService,
    private skSyncHttp: SkSyncHttpService
  ) {}

  create(customerId: string, website: Partial<CustomerWebsite>): Promise<any> {
    return this.angularFire
      .list(`/${CUSTOMER_WEBSITES_PATH}/${customerId}`)
      .push(website) as any;
  }

  getAll(customerId: string): Observable<CombinedCustomerWebsites> {
    const adwordsWebsites$ = this.getAdwordsWebsites(customerId).pipe(
      catchError(err => {
        const caughtResponse: {
          repositories: WebsiteRepository[];
          websites: CustomerWebsite[];
        } = { repositories: [], websites: [] };
        return of(caughtResponse);
      })
    );
    const manualWebsites$ = this.getManualWebsites(customerId);

    return combineLatest([adwordsWebsites$, manualWebsites$]).pipe(
      map(([adwordsSites, manualSites]) => {
        const validManualSites = manualSites.filter(
          siteInfo =>
            !adwordsSites ||
            !adwordsSites.websites ||
            !adwordsSites.websites.some(
              awSiteInfo => siteInfo.domain === awSiteInfo.domain
            )
        );

        const returnValue = {
          ...adwordsSites,
          manualSites: validManualSites
        };

        return returnValue;
      })
    );
  }

  remove(customerId: string, websiteId: string): Promise<void> {
    return this.angularFire
      .list(`/${CUSTOMER_WEBSITES_PATH}/${customerId}/${websiteId}`)
      .remove();
  }

  private getManualWebsites(customerId: string): Observable<CustomerWebsite[]> {
    return this.angularFire.getList(`/${CUSTOMER_WEBSITES_PATH}/${customerId}`);
  }

  private getAdwordsWebsites(
    customerId: string
  ): Observable<SkSyncCustomerWebsitesResponse> {
    return this.customerAccountsService.getAdwordsAccounts(customerId).pipe(
      take(1),
      map(adwordsAccounts =>
        adwordsAccounts
          .filter(account => account.status === 'ONLINE')
          .map(account => account.$key)
      ),
      switchMap(accountIds =>
        this.skSyncHttp.post(
          `/adwords/websites`,
          {
            payload: {
              adwords: accountIds,
              customerId
            }
          },
          { displayError: false }
        )
      )
    );
  }
}
