import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Account, ResMetric } from '../../account-dashboard.interface';

@Component({
  selector: 'ease-res-metric-cell',
  templateUrl: './res-metric-cell.component.html',
  styleUrls: ['./res-metric-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResMetricCellComponent implements AgRendererComponent {
  public account: Account;
  public isGroup: boolean;
  public responsivenessMetric: ResMetric;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.account = params.data;
    this.isGroup = params.node.group;

    if (this.account) {
      this.responsivenessMetric = this.account.resMetric;
    }
  }

  refresh(params: ICellRendererParams) {
    return true;
  }
}
