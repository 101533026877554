import {
  Directive,
  HostListener,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ContextMenuService } from './context-menu.service';

@UntilDestroy()
@Directive({
  selector: '[easeContextMenu]',
  exportAs: 'easeContextMenu'
})
export class ContextMenuDirective implements OnDestroy {
  @Input() easeContextMenu: TemplateRef<any>;
  @Input() set easeContextData(data: any) {
    this._easeContextData = data;

    if (this.isOpen) {
      this.contextMenuService.updateData(data);
    }
  }
  get easeContextData(): any {
    return this._easeContextData;
  }
  @Input() easeContextMenuDisabled: boolean = false;
  @HostListener('contextmenu', ['$event']) onClick($event) {
    $event.preventDefault();
    this.open($event);
  }

  private _easeContextData: any;
  private isOpen: boolean = false;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private contextMenuService: ContextMenuService
  ) {}

  public open(mouseEvent: MouseEvent) {
    this.contextMenuService.open(
      mouseEvent,
      this.easeContextData,
      this.easeContextMenu,
      this.viewContainerRef,
      this.easeContextMenuDisabled
    );

    this.isOpen = true;
  }

  close() {
    this.contextMenuService.close();
    this.isOpen = false;
  }

  ngOnDestroy() {
    this.close();
  }
}
