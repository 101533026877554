import {
  Component,
  ChangeDetectionStrategy,
  ContentChild,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ease-task-body-section',
  templateUrl: './task-body-section.component.html',
  styleUrls: ['./task-body-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBodySectionComponent {
  @ContentChild('content') content: TemplateRef<any>;
  constructor() {}
}
