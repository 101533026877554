<ng-container>
  <form
    [formGroup]="finderForm"
    *ngIf="results$ | async as results"
    [hidden]="!showFinder"
  >
    <mat-form-field [appearance]="appearance">
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <input
        type="text"
        [placeholder]="placeholder"
        matInput
        formControlName="query"
        [matAutocomplete]="autocomplete"
        #finder
        #trigger="matAutocompleteTrigger"
      />
      <mat-autocomplete
        #autocomplete="matAutocomplete"
        class="!max-h-[50vh]"
        classlist="!max-h-[50vh]"
        (optionSelected)="onItemSelect($event)"
        (opened)="opened.emit()"
        (closed)="onPanelClose()"
      >
        <div class="px-2 pt-3">
          <!-- Show empty state if there are no results yet-->
          <mat-option disabled *ngIf="!results?.length || !resultsExists">
            {{
              !results?.length
                ? 'Type above to start searching…'
                : results?.length && !resultsExists && 'No Results Found'
            }}
          </mat-option>

          <ng-container *ngIf="results?.length && resultsExists">
            <mat-optgroup
              *ngFor="let group of results"
              class="[&_.mat-optgroup-label]:hidden"
            >
              <!-- Group Labels -->
              <ng-container
                [ngSwitch]="
                  group.request_params?.collection_name | stripTypesensePrefix
                "
              >
                <ng-container *ngIf="group.found">
                  <!-- Customer / Prospect Group Label -->
                  <ng-container
                    *ngSwitchCase="'customers'"
                    [ngTemplateOutlet]="searchGroupLabel"
                    [ngTemplateOutletContext]="{
                      $implicit: {
                        icon:
                          (group?.hits)[0]?.document?.phase === 'customer'
                            ? 'person_pin'
                            : 'ring_volume',
                        label: (group?.hits)[0]?.document?.phase
                          ? ((group?.hits)[0]?.document?.phase + 's'
                            | capitalize)
                          : null
                      }
                    }"
                  >
                  </ng-container>
                  <!--  Account Group Label -->
                  <ng-container
                    *ngSwitchCase="'accounts'"
                    [ngTemplateOutlet]="searchGroupLabel"
                    [ngTemplateOutletContext]="{
                      $implicit: {
                        icon: 'device_hub',
                        label:
                          group.request_params?.collection_name
                          | stripTypesensePrefix
                          | capitalize
                      }
                    }"
                  ></ng-container>
                  <!-- Contact Group Label -->
                  <ng-container
                    *ngSwitchCase="'contacts'"
                    [ngTemplateOutlet]="searchGroupLabel"
                    [ngTemplateOutletContext]="{
                      $implicit: {
                        icon: 'contacts',
                        label:
                          group.request_params?.collection_name
                          | stripTypesensePrefix
                          | capitalize
                      }
                    }"
                  >
                  </ng-container>
                </ng-container>
              </ng-container>

              <mat-option
                class="!h-9 rounded-lg font-medium !text-gray-800"
                *ngFor="let hit of group.hits"
                [value]="{
                  section: group,
                  item: hit.document
                }"
              >
                <div class="flex flex-row items-center justify-start">
                  <mat-icon
                    *ngIf="
                      selectedResults && selectedResults[hit.document.$key]
                    "
                    class="mr-2"
                    >done</mat-icon
                  >
                  <ng-container
                    [ngSwitch]="
                      group.request_params?.collection_name
                        | stripTypesensePrefix
                    "
                  >
                    <!--  Default option renderer -->
                    <ng-container *ngSwitchDefault="">
                      {{ hit.document.name }}
                    </ng-container>

                    <!--  accounts option renderer -->

                    <ng-container *ngSwitchCase="'accounts'">
                      <div class="flex flex-row items-center justify-start">
                        <ease-account-icon
                          [accountType]="hit.document.accountType"
                          [size]="16"
                          class="mr-2"
                        ></ease-account-icon>
                        <div class="mr-2 flex-1 truncate">
                          {{ hit.document.name }}
                        </div>
                        <div class="flex-none text-sm text-gray-400">
                          {{ hit.document.accountId }}
                        </div>
                      </div>
                    </ng-container>

                    <!-- Contacts option renderer -->

                    <ng-container *ngSwitchCase="'contacts'">
                      <div
                        class="mr-2 flex flex-1 flex-row items-center justify-start truncate"
                      >
                        <span
                          *ngIf="
                            hit.document.firstName ||
                              hit.document.middleName ||
                              hit.document.lastName;
                            else noContactName
                          "
                          >{{ hit.document.firstName }}
                          {{ hit.document.middleName }}
                          {{ hit.document.lastName }}</span
                        >
                        <ng-template #noContactName
                          ><span class="text-gray-400"
                            >(No Name)</span
                          ></ng-template
                        >
                        <span
                          class="mx-2 flex flex-row items-center justify-start text-sm font-normal"
                          *ngIf="hit.document.email"
                          ><mat-icon class="mat-icon-14 mr-1">email</mat-icon>
                          {{ hit.document.email }}</span
                        >
                        <span
                          class="mx-2 flex flex-row items-center justify-start text-sm font-normal"
                          *ngIf="hit.document.phone"
                          ><mat-icon class="mat-icon-14 mr-1">phone</mat-icon>
                          {{ hit.document.phone }}</span
                        >
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </div>

        <div
          class="sticky bottom-0 flex w-full flex-col items-center divide-y divide-gray-300 border-t border-solid border-gray-300 bg-white p-4 sm:flex-row sm:gap-3 sm:divide-y-0 [&>.mat-checkbox_.mat-checkbox-layout]:w-full sm:[&>.mat-checkbox_.mat-checkbox-layout]:w-auto [&>.mat-checkbox_.mat-checkbox-layout_.mat-checkbox-label]:flex-1 sm:[&>.mat-checkbox_.mat-checkbox-layout_.mat-checkbox-label]:flex-auto"
          *ngIf="showSearchTypeFilter"
        >
          <button
            mat-button
            (click)="toggleMobileFilter()"
            class="w-full sm:!hidden"
          >
            Show/Hide Filters
          </button>
          <ng-container *ngIf="showMobileFilters$ | async">
            <mat-checkbox
              *ngFor="
                let typeControl of searchTypesControls.controls;
                let i = index
              "
              color="primary"
              class="w-full py-2 sm:w-auto sm:py-0"
              [formControl]="$any(typeControl.get('value'))"
              >{{ typeControl.get('name').value | decamelize }}
            </mat-checkbox>

            <span class="flex-1"></span>
            <mat-checkbox
              color="primary"
              class="w-full py-2 sm:w-auto sm:py-0"
              formControlName="includeOffline"
              *ngIf="includeOffline"
              >Offline / Lost</mat-checkbox
            >
          </ng-container>
        </div>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</ng-container>

<!-- Selected option renderer -->

<ng-container *ngIf="selectedOption$ | async as selectedOption">
  <div *ngIf="selectedOption && !showFinder" (click)="enableFinder()">
    <mat-form-field [appearance]="appearance">
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <input
        type="text"
        matInput
        [value]="selectedOption.entityId"
        [readonly]="true"
        class="hidden"
        [disabled]="disabled"
      />

      <div matInput>
        <ng-container
          [ngTemplateOutlet]="selectedRenderer"
          [ngTemplateOutletContext]="{ $implicit: selectedOption }"
        ></ng-container>
      </div>

      <button
        matSuffix
        mat-icon-button
        matTooltip="Clear"
        (click)="clearSelected()"
        *ngIf="showClearButton && selectedOption.entityId"
      >
        <mat-icon class="mat-icon-18 !top-0 !m-auto">close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</ng-container>

<ng-template #selectedRenderer let-selected>
  <ng-container [ngSwitch]="selected.entityType">
    <ng-container *ngSwitchCase="'customer'">
      <div class="ml-2 flex flex-row items-center justify-start gap-1">
        <mat-icon class="mat-icon-16 text-gray-600">person_pin</mat-icon>
        <span class="mr-2 truncate">{{ selected.entityName }}</span>
        <div class="flex-none text-sm text-gray-400">
          ({{ selected.entityId }})
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'prospect'">
      <div class="ml-2 flex flex-row items-center justify-start gap-1">
        <mat-icon class="mat-icon-16 text-gray-600">ring_volume</mat-icon>
        <span class="mr-2 truncate">{{ selected.entityName }}</span>
        <div class="flex-none text-sm text-gray-400">
          ({{ selected.entityId }})
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'account'">
      <div class="ml-2 flex flex-row items-center justify-start gap-1">
        <ease-account-icon
          [accountType]="selected.accountType"
          [size]="16"
          class="mr-2"
        ></ease-account-icon>
        <div class="mr-2 truncate">
          {{ selected.entityName }}
        </div>
        <div class="flex-none text-sm text-gray-400">
          ({{ selected.entityId }})
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'contacts'">
      <div class="ml-2 flex flex-row items-center justify-start gap-1">
        <span *ngIf="selected.entityName; else noContactName">{{
          selected.entityName
        }}</span>
        <ng-template #noContactName
          ><span class="text-gray-400">(No Name)</span></ng-template
        >
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="h-3">{{ selected.entityName }}</div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #searchGroupLabel let-labelGroup>
  <div
    class="my-2 ml-3 flex flex-row items-center gap-2 text-sm font-bold text-gray-500"
    *ngIf="labelGroup.label"
  >
    <mat-icon class="mat-icon-18">{{ labelGroup.icon }}</mat-icon>
    <span>{{ labelGroup.label }}</span>
  </div>
</ng-template>

<ng-template #resultsTemplate let-results></ng-template>
