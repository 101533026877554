<ng-content select="ease-chart-header"></ng-content>
<div [ngStyle]="{ 'height.px': height }">
  <canvas
    #chart
    baseChart
    [type]="'line'"
    [datasets]="(data$ | async)?.datasets"
    [labels]="(data$ | async)?.labels"
    [legend]="false"
    [options]="options"
  >
  </canvas>
</div>
<ng-content select="ease-chart-summary"></ng-content>
