/**
 * Note: Functions in this file are intended to be shared with the Firebase
 * Cloud Functions located in /functions/src/
 *
 * It is important to be mindful of the module types we import here, as
 * es6 imports will cause the Firebase function deploys to fail.
 *
 * E.g. Avoid imports from es-only libraries like lodash-es.
 */

import {
  ADWORDS_ACCOUNT_RULES_PATH,
  BING_ACCOUNT_RULES_PATH,
  LSA_ACCOUNT_RULES_PATH,
  TASKS_OPEN_PATH,
  USER_ASSIGNED_TASKS_PATH,
  USER_SUBSCRIBED_TASKS_PATH
} from '../firebase-paths';
import { SelectMethod } from '../user-selector/user-selector.interface';

export const getAssignWrites = (
  taskId: string,
  userId: string,
  assignedById: string = '0',
  method: SelectMethod = 'direct'
) => {
  let writeOps = {};

  /**
   * 1. Unsubscribe user
   * 2. Assign user
   * 3. Optionally, and if assigner is not assigned to the task, subscribe assigner
   */
  if (taskId && userId) {
    writeOps = { ...getUnsubscribeWrites(taskId, userId) };
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/assigned/${userId}`] = true;
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/assignedBy/${userId}`] =
      assignedById;
    writeOps[`${USER_ASSIGNED_TASKS_PATH}/${userId}/${taskId}/assignedBy`] =
      assignedById;
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/delegateMethod/${userId}`] = method;
  }

  return writeOps;
};

export const getUnassignWrites = (
  taskId: string,
  userId: string
): { [index: string]: any } => {
  const writeOps = {};

  if (taskId && userId) {
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/assigned/${userId}`] = null;
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/assignedBy/${userId}`] = null;
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/delegateMethod/${userId}`] = null;
    writeOps[`${USER_ASSIGNED_TASKS_PATH}/${userId}/${taskId}`] = null;
  }

  return writeOps;
};

export const getSubscribeWrites = (
  taskId: string,
  userId: string,
  method: SelectMethod
): { [index: string]: any } => {
  let writeOps = {};

  /**
   * 1. Unassign user from task
   * 2. Subscribe user to task
   */
  if (taskId && userId) {
    writeOps = {
      ...getUnassignWrites(taskId, userId)
    };
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/subscribed/${userId}`] = true;
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/delegateMethod/${userId}`] = method;
    writeOps[`${USER_SUBSCRIBED_TASKS_PATH}/${userId}/${taskId}`] = true;
  }

  return writeOps;
};

export const getUnsubscribeWrites = (
  taskId: string,
  userId: string
): { [index: string]: any } => {
  const writeOps = {};

  if (taskId && userId) {
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/subscribed/${userId}`] = null;
    writeOps[`${USER_SUBSCRIBED_TASKS_PATH}/${userId}/${taskId}`] = null;
    writeOps[`${TASKS_OPEN_PATH}/${taskId}/delegateMethod/${userId}`] = null;
  }

  return writeOps;
};

export const getRulesPathForAccountType = (accountType: string): string => {
  switch (accountType) {
    case 'adwords':
      return ADWORDS_ACCOUNT_RULES_PATH;

    case 'lsa':
      return LSA_ACCOUNT_RULES_PATH;

    case 'bing':
      return BING_ACCOUNT_RULES_PATH;
  }
};

export const promiseAllN = async (queue, concurrency) => {
  let index = 0;
  const results = [];

  // Run a pseudo-thread
  const execThread = async () => {
    while (index < queue.length) {
      const curIndex = index++;
      // Use of `curIndex` is important because `index` may change after await is resolved
      results[curIndex] = await queue[curIndex]();
    }
  };

  // Start threads
  const threads = [];
  for (let thread = 0; thread < concurrency; thread++) {
    threads.push(execThread());
  }
  await Promise.all(threads);
  return results;
};

export const decamelize = (text: string, separator = ' ') => {
  if (typeof text === 'undefined' || text === null) {
    return '';
  }

  if (!(typeof text === 'string' && typeof separator === 'string')) {
    throw new TypeError(
      `The 'text' and 'separator' arguments should be of type 'string'. Received: ${typeof text} ${text}`
    );
  }

  if (text.length < 2) {
    return text.toLowerCase();
  }

  const replacement = `$1${separator}$2`;

  // Split lowercase sequences followed by uppercase character
  const decamelized = text.replace(
    /([\p{Lowercase_Letter}\d])(\p{Uppercase_Letter})/gu,
    replacement
  );

  // Split multiple uppercase characters followed by one or more lowercase characters
  return decamelized
    .replace(
      /(\p{Uppercase_Letter})(\p{Uppercase_Letter}\p{Lowercase_Letter}+)/gu,
      replacement
    )
    .toLowerCase()
    .split(separator)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(separator);
};
