import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UserSelectorInputModule } from 'src/app/shared/user-selector-input/user-selector-input.module';
import { TaskUserMenuModule } from '../task-user-menu/task-user-menu.module';

import { TaskAssignedUsersComponent } from './task-assigned-users.component';

@NgModule({
  imports: [
    DragDropModule,
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
    TaskUserMenuModule,
    UserSelectorInputModule
  ],
  declarations: [TaskAssignedUsersComponent],
  exports: [TaskAssignedUsersComponent]
})
export class TaskAssignedUsersModule {}
