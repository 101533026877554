import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(value: any[], term: string, searchKey?: string) {
    if (term && term.length > 0 && value) {
      term = term.toLowerCase();

      return value.filter(item => {
        if (typeof item === 'string') {
          return item.toLowerCase().includes(term);
        } else if (typeof item === 'object' && searchKey && item[searchKey]) {
          return item[searchKey].toLowerCase().includes(term);
        } else {
          return JSON.stringify(item).toLowerCase().includes(term);
        }
      });
    }
    return value;
  }
}
