import { animate, style, AnimationMetadata } from '@angular/animations';

interface Style {
  [property: string]: string | number;
}

const defaultTiming = '0.5s ease';
const shortTiming = '0.25s ease';
const boxShadow =
  '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)';

/**
 * Wrapper styles and animations
 */

export const defaultStyle: Style = {
  height: '77px'
};

export const unitSetStyle: Style = {
  height: '52px'
};

export const emptyStyle: Style = {
  height: '0px'
};

export const toDefault: AnimationMetadata[] = [
  animate(defaultTiming, style(defaultStyle))
];

export const toUnitSet: AnimationMetadata[] = [
  animate(defaultTiming, style(unitSetStyle))
];

export const toEmpty: AnimationMetadata[] = [
  animate(defaultTiming, style(emptyStyle))
];

/**
 * Card styles and animations
 */

export const inactiveStyle: Style = {
  top: '8px',
  transform: 'scale(0.98)',
  zIndex: '1',
  pointerEvents: 'none'
};

export const prevStyle: Style = {
  ...inactiveStyle,
  boxShadow,
  zIndex: '0'
};

export const nextStyle: Style = {
  ...prevStyle,
  top: '4px',
  transform: 'scale(0.99)',
  zIndex: '2'
};

export const activeStyle: Style = {
  top: '0px',
  transform: 'scale(1)',
  boxShadow,
  zIndex: '3',
  pointerEvents: 'initial'
};

export const soloStyle: Style = {
  ...activeStyle,
  top: '1px'
};

export const topStyle: Style = {
  top: '-40px',
  zIndex: '4'
};

export const readStyle: Style = {
  top: '40px',
  opacity: '0'
};

export const bringForwardSteps: AnimationMetadata[] = [
  animate(defaultTiming, style(activeStyle))
];

export const bringToFrontSteps: AnimationMetadata[] = [
  animate(shortTiming, style(topStyle)),
  animate(shortTiming, style(activeStyle))
];

export const sendBackwardSteps: AnimationMetadata[] = [
  animate(defaultTiming, style(nextStyle))
];

export const sendToBackSteps: AnimationMetadata[] = [
  animate(shortTiming, style(topStyle)),
  animate(shortTiming, style(inactiveStyle))
];

export const sendToNextSteps: AnimationMetadata[] = [
  animate(shortTiming, style(topStyle)),
  animate(shortTiming, style(nextStyle))
];

export const toPrev: AnimationMetadata[] = [
  animate(defaultTiming, style(prevStyle))
];

export const toActive: AnimationMetadata[] = [
  animate(defaultTiming, style(activeStyle))
];

export const toNext: AnimationMetadata[] = [
  animate(defaultTiming, style(nextStyle))
];

export const toInactive: AnimationMetadata[] = [
  animate(defaultTiming, style(inactiveStyle))
];

export const markedAsRead: AnimationMetadata[] = [
  animate(defaultTiming, style(readStyle))
];

export const newToAnything: AnimationMetadata[] = [
  animate(shortTiming, style(topStyle))
];
