import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MarkdownModule } from '../../markdown/markdown.module';
import { SharedModule } from '../../shared.module';
import { SharedPipesModule } from '../../shared-pipes.module';
import { TaskWindowerModule } from '../../window/window-pane-view-task/task-windower.module';
import { EntityIconModule } from '../../entity-icon/entity-icon.module';
import { FeedItemCompletedTaskComponent } from './feed-item-completed-task.component';

@NgModule({
  imports: [
    EntityIconModule,
    FormsModule,
    MarkdownModule,
    SharedModule,
    SharedPipesModule,
    TaskWindowerModule
  ],
  declarations: [FeedItemCompletedTaskComponent],
  exports: [FeedItemCompletedTaskComponent]
})
export class FeedItemCompletedTaskModule {}
