import { NgModule } from '@angular/core';

import { CustomerMessageDetailsDialogModule } from '../customer-message-details-dialog/customer-message-details-dialog.module';
import { CustomerMessagePreviewDirective } from './customer-message-preview.directive';

@NgModule({
  imports: [CustomerMessageDetailsDialogModule],
  declarations: [CustomerMessagePreviewDirective],
  exports: [CustomerMessagePreviewDirective]
})
export class CustomerMessagePreviewModule {}
