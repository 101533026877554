import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { AccountBuilderForm } from '../account-builder.interface';

export interface AdPreview {
  headline: string;
  description: string;
  finalUrl: string;
}

@UntilDestroy()
@Component({
  selector: 'ease-search-campaign-preview',
  templateUrl: './search-campaign-preview.component.html',
  styleUrls: ['./search-campaign-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchCampaignPreviewComponent implements OnInit {
  @Input() set: AccountBuilderForm['controls']['searchSets']['controls'][0];
  public ad$: Observable<AdPreview>;
  constructor() {}

  ngOnInit(): void {
    this.ad$ = interval(4000).pipe(
      startWith(this.generateRandomAd()),
      untilDestroyed(this),
      map(() => this.generateRandomAd())
    );
  }

  generateRandomAd(): AdPreview {
    const headlines = this.set.controls.headlines.value.map(
      headline => headline.text
    );
    const descriptions = [...this.set.controls.descriptions.value];

    return {
      finalUrl:
        this.set.controls.finalUrl.value ||
        'https://yoururlwillbehere.com/careers',
      headline: this.generateRandomText(headlines, ' | '),
      description: this.generateRandomText(descriptions)
    };
  }

  generateRandomText(text: string[], separator: string = ' '): string {
    return text
      .map(() => text.splice(Math.floor(Math.random() * text.length), 1)[0])
      .slice(0, 3)
      .join(separator);
  }
}
