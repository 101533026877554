import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import flatten from 'lodash-es/flatten';
import { SatPopover } from '@ncstate/sat-popover';

import { CustomerAccountsService } from '../../../customers/customer-accounts/customer-accounts.service';
import { ScheduledTask } from '../../../tasks/scheduled-tasks/scheduled-task.interface';
import { ScheduledTaskService } from '../../../tasks/scheduled-tasks/scheduled-task.service';
import { TaskModel } from '../../../tasks/task.model';
import { TaskService } from '../../../tasks/task.service';
import { EntityMetadata } from '../../shared.interface';

@Component({
  selector: 'ease-customer-tasks-popover',
  templateUrl: './customer-tasks-popover.component.html',
  styleUrls: ['./customer-tasks-popover.component.scss']
})
export class CustomerTasksPopoverComponent implements OnInit {
  @HostBinding('class') hostClass = 'block  lg:w-[600px]';

  @Input()
  set customerId(customerId: string) {
    this.getCustomerTasks(customerId);
  }

  @Input()
  excludeTasks: string[] = [];

  private allCustomerMeta$: Observable<EntityMetadata[]>;
  private allCustomerAccountMeta$: Observable<EntityMetadata[]>;

  public openTasks$: Observable<TaskModel[]>;
  public scheduledTasks$: Observable<ScheduledTask[]>;

  constructor(
    private customerAccountService: CustomerAccountsService,
    private popover: SatPopover,
    private scheduledTaskService: ScheduledTaskService,
    private taskService: TaskService
  ) {}

  ngOnInit() {}

  getCustomerTasks(customerId: string) {
    this.allCustomerAccountMeta$ = this.customerAccountService
      .getCustomerAccountMeta(customerId)
      .pipe(
        map(accountMetas =>
          accountMetas.map(meta => ({
            accountType: meta.accountType,
            entityId: meta.accountId,
            entityType: 'account'
          }))
        )
      );

    this.allCustomerMeta$ = combineLatest([
      of(customerId),
      this.allCustomerAccountMeta$
    ]).pipe(
      map(([currentCustomerId, customerAccountIds]) => [
        { entityId: currentCustomerId } as EntityMetadata,
        ...customerAccountIds
      ]),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.openTasks$ = this.allCustomerMeta$
      .pipe(
        switchMap(allIds =>
          combineLatest(allIds.map(meta => this.taskService.getForEntity(meta)))
        )
      )
      .pipe(
        map(taskArrs =>
          flatten(taskArrs).filter(
            task => !this.excludeTasks.includes(task.$key)
          )
        )
      );

    this.scheduledTasks$ = this.allCustomerMeta$
      .pipe(
        switchMap(allIds =>
          combineLatest(
            allIds.map(meta => this.scheduledTaskService.getForEntity(meta))
          )
        )
      )
      .pipe(
        map(taskArrs =>
          flatten(taskArrs).filter(
            task => !this.excludeTasks.includes(task.$key)
          )
        ),
        map(tasks => this.scheduledTaskService.sortScheduledTasks(tasks))
      );
  }

  onOpen(): void {
    this.popover.close();
  }
}
