import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ease-sheet-panel',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./sheet-panel.component.scss']
})
export class SheetPanelComponent {
  @HostBinding('class') hostClass =
    'block bg-gray-50 border-t border-solid border-gray-200 border-b first:border-t-0';
}
