import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import omit from 'lodash-es/omit';
import orderBy from 'lodash-es/orderBy';
import pick from 'lodash-es/pick';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { stringify } from 'qs';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  EaseUnwrappedSnapshot,
  FirebaseDbService
} from 'src/app/shared/firebase-db.service';
import { environment } from 'src/environments/environment';
import {
  CONTACT_CUSTOMERS_PATH,
  CUSTOMER_CONTACTS_PATH
} from '../../shared/firebase-paths';
import { ContactService } from '../../contacts/contact.service';
import { firebaseJSON } from '../../shared/utils/functions';
import { SNACKBAR_DURATION_ERROR } from '../../shared/constants';
import { ContactModel } from '../../contacts/contact.model';
import { SkSyncHttpService } from '../../shared/sksync-http.service';
import { ReportsUserModel } from '../../shared/reporting-app/user.model';
import { TypesenseContactFieldList } from '../../shared/search/search.interface';
import {
  CustomerContactMetaModel,
  CustomerContactModel,
  CustomerContactType,
  CustomerContactTypes
} from './customer-contact.model';

const contactMetaFields = ['type', 'isStarred'];

@Injectable({ providedIn: 'root' })
export class CustomerContactsService {
  public customerContactsRef: firebase.database.Reference;
  private contactCustomersRef: firebase.database.Reference;
  public types: CustomerContactTypes = {
    sales: {
      icon: 'work',
      label: 'Sales/Admin'
    },
    account: {
      icon: 'info',
      label: 'Account'
    },
    billing: {
      icon: 'credit_card',
      label: 'Billing'
    },
    tda: {
      icon: 'contact_support',
      label: 'Digital Advisor'
    }
  };

  constructor(
    private angularFire: FirebaseDbService,
    private contactService: ContactService,
    private skSyncHttp: SkSyncHttpService,
    private matSnackBar: MatSnackBar
  ) {
    this.customerContactsRef = this.angularFire.database.ref(
      CUSTOMER_CONTACTS_PATH
    );
    this.contactCustomersRef = this.angularFire.database.ref(
      CONTACT_CUSTOMERS_PATH
    );
  }

  get(
    customerId: string,
    includeReportsAppUsage: boolean = false
  ): Observable<CustomerContactModel[]> {
    const customerContactMeta$: Observable<CustomerContactMetaModel[]> =
      this.angularFire.getList(`/${CUSTOMER_CONTACTS_PATH}/${customerId}`);

    const customerContacts$: Observable<EaseUnwrappedSnapshot<ContactModel>[]> =
      this.angularFire.getList(`/${CUSTOMER_CONTACTS_PATH}/${customerId}`).pipe(
        switchMap((contactsMeta: CustomerContactMetaModel[]) => {
          if (contactsMeta?.length) {
            return combineLatest(
              contactsMeta.map(contact => this.contactService.get(contact.$key))
            );
          } else {
            return of([]);
          }
        })
      );

    return combineLatest([
      customerContactMeta$,
      customerContacts$,
      includeReportsAppUsage
        ? this.getReportsUsers(customerId)
        : of([] as ReportsUserModel[])
    ]).pipe(
      map(([customerMeta, customerContacts, reportsUsers]) => {
        const combinedContacts: CustomerContactModel[] = customerContacts.map(
          contact => {
            const reportingApp = reportsUsers.find(user => {
              if (
                (user.email && user.email.toLowerCase()) ===
                (contact.email && contact.email.toLowerCase())
              ) {
                return user;
              }
            });

            return Object.assign(
              {},
              contact,
              { $key: contact.$key },
              customerMeta.find(meta => meta.$key === contact.$key),
              { reportingApp }
            );
          }
        );

        return orderBy(
          combinedContacts,
          [
            contact => contact.isStarred || false,
            contact => !!contact.firstName,
            contact => contact.firstName || ''
          ],
          ['desc', 'desc', 'asc']
        );
      })
    );
  }

  update(customerId: string, contact: CustomerContactModel): Promise<any> {
    const contactId = contact.$key;
    const customerContactMeta = this.getCustomerContactMeta(contact);
    const contactData = omit(firebaseJSON(contact), contactMetaFields);
    this.customerContactsRef
      .child(`${customerId}/${contactId}`)
      .update(customerContactMeta);
    return this.contactService.save(contactId, contactData as ContactModel);
  }

  add(
    customerId: string,
    contact: CustomerContactModel | TypesenseContactFieldList
  ): Promise<any> {
    const contactId = contact.$key;
    const existingContact$ = this.angularFire.getObject(
      `/${CUSTOMER_CONTACTS_PATH}/${customerId}/${contactId}`
    );

    /**
     * Check whether this contact is already applied to the customer, only set
     * default metadata if it doesn't exist to prevent clobbering existing data
     */
    return firstValueFrom(existingContact$).then(doesContactExist => {
      if (!doesContactExist.$value) {
        return this.createAssociation(customerId, contactId, contact);
      } else {
        return Promise.resolve();
      }
    });
  }

  create(customerId: string, contact: CustomerContactModel): PromiseLike<any> {
    const contactData = omit(firebaseJSON(contact), contactMetaFields);

    return this.contactService
      .create(contactData as ContactModel)
      .then(async contactSnap => {
        const contactId = contactSnap.key;
        return this.createAssociation(customerId, contactId, contact);
      });
  }

  async createAssociation(
    customerId: string,
    contactId: string,
    contact: CustomerContactModel | TypesenseContactFieldList
  ): Promise<void> {
    const customerContactMeta = this.getCustomerContactMeta(contact);

    await Promise.all([
      this.customerContactsRef.child(`${customerId}/${contactId}`).update({
        type: customerContactMeta.type || {
          account: false,
          billing: false
        },
        isStarred: customerContactMeta.isStarred || false
      }),
      this.contactCustomersRef.child(`${contactId}/${customerId}`).set(true)
    ]);
  }

  getCustomerContactMeta(
    contact: CustomerContactModel | TypesenseContactFieldList
  ): CustomerContactMetaModel {
    return pick(contact, contactMetaFields) as CustomerContactMetaModel;
  }

  removeAssociation(
    customerId: string,
    contact: CustomerContactModel
  ): Promise<any> {
    const contactId = contact.$key;
    return Promise.all([
      this.customerContactsRef.child(`${customerId}/${contactId}`).remove(),
      this.contactCustomersRef.child(`${contactId}/${customerId}`).remove()
    ]);
  }

  async removeAll(customerId: string): Promise<void> {
    if (customerId) {
      return this.angularFire
        .object(`/${CUSTOMER_CONTACTS_PATH}/${customerId}`)
        .remove();
    }
  }

  setStarred(
    customerId: string,
    contactId: string,
    value: boolean
  ): Promise<any> {
    return this.customerContactsRef
      .child(`${customerId}/${contactId}/isStarred`)
      .set(value);
  }

  mergeCustomers(sourceCustomerId: string, destinationCustomerId: string) {
    return firstValueFrom(
      this.angularFire.getObject(
        `/${CUSTOMER_CONTACTS_PATH}/${sourceCustomerId}`
      )
    )
      .then(contacts => (contacts.$exists() ? contacts : {}))
      .then(async contacts => {
        const contactIds = Object.keys(contacts);

        await Promise.all(
          contactIds.map(contactId =>
            Promise.all([
              this.contactCustomersRef
                .child(contactId)
                .child(sourceCustomerId)
                .remove(),
              this.contactCustomersRef
                .child(contactId)
                .child(destinationCustomerId)
                .set(true)
            ])
          )
        );

        await this.angularFire
          .object(`/${CUSTOMER_CONTACTS_PATH}/${destinationCustomerId}`)
          .update(firebaseJSON(contacts));
      })
      .then(() =>
        this.angularFire
          .object(`/${CUSTOMER_CONTACTS_PATH}/${sourceCustomerId}`)
          .remove()
      );
  }

  getReportsRegisterLink(contactId: string): Observable<string> {
    return combineLatest([
      this.contactService.get(contactId),
      this.contactService.getCustomers(contactId)
    ]).pipe(
      take(1),
      map(
        ([contactInfo, relatedCustomers]) =>
          `${environment.REPORTS_APP_URL}/admin/create-user?${stringify({
            action: 'register',
            ...contactInfo,
            customers: relatedCustomers
          })}`
      )
    );
  }

  getReportsUserSettingLink(uid: string): string {
    return `${environment.REPORTS_APP_URL}/admin/edit-user/${uid}`;
  }

  getReportsUsers(customerId: string): Observable<ReportsUserModel[]> {
    return this.skSyncHttp
      .get(`/reporting-app/${customerId}/users`, {}, { displayError: false })
      .pipe(
        catchError(() => {
          this.matSnackBar.open(
            'There was an error retrieving Reports App details for contacts',
            'Close',
            {
              duration: SNACKBAR_DURATION_ERROR
            }
          );
          return of([]);
        })
      );
  }

  async hasContactTypes(
    customerId: string,
    contactTypes: CustomerContactType[]
  ): Promise<boolean> {
    const customerContactTypes = await firstValueFrom(
      this.get(customerId).pipe(
        map(contacts =>
          contacts.reduce((acc, curr) => {
            if (curr?.type) {
              const types = Object.keys(curr.type).filter(
                type => curr.type[type]
              );

              // Merge and remove duplication for later contact type check
              acc = [...new Set([...acc, ...types])];
            }
            return acc;
          }, [])
        )
      )
    );

    if (customerContactTypes?.length) {
      return customerContactTypes.some(type => contactTypes.includes(type));
    } else {
      return false;
    }
  }
}
