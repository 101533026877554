<ng-container *ngIf="!isGroup">
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    matTooltip="Account Actions"
    [satPopoverAnchor]="snoozePopover"
  >
    <mat-icon class="mat-icon-20">more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="snoozePopover.toggle()" *ngIf="!isSnoozed">
      <mat-icon>snooze</mat-icon>
      <span>Snooze Account</span>
    </button>
    <button mat-menu-item (click)="openBudgetManager()">
      <mat-icon>monetization_on</mat-icon>
      <span>Open Budget Manager</span>
    </button>
    <button mat-menu-item (click)="showRelatedAccounts()">
      <mat-icon>search</mat-icon>
      <span>View Related Accounts</span>
    </button>
    <button mat-menu-item (click)="createTask('account')">
      <mat-icon>library_add</mat-icon>
      <span>Create Task</span>
    </button>
    <button mat-menu-item (click)="goToCustomer()">
      <mat-icon>person_pin</mat-icon>
      <span>Go To Customer</span>
    </button>
    <button mat-menu-item (click)="openAccountFeed()">
      <mat-icon>list_alt</mat-icon>
      <span>Open Account Feed</span>
    </button>
    <button mat-menu-item (click)="logCommunication()">
      <mat-icon>speaker_notes</mat-icon>
      <span>Log Communication</span>
    </button>
    <button mat-menu-item (click)="createMessage()">
      <mat-icon>email</mat-icon>
      <span>New Message</span>
    </button>
  </mat-menu>
</ng-container>

<ng-container *ngIf="isGroup">
  <button
    mat-icon-button
    class="customer-more-button"
    (click)="snoozePopover.toggle()"
    aria-label="Snooze Accounts"
    *ngIf="!isSnoozed"
    matTooltip="Snooze all accounts"
    [satPopoverAnchor]="snoozePopover"
  >
    <mat-icon class="mat-icon-18">snooze</mat-icon>
  </button>
  <button
    *ngIf="hasLsa"
    mat-icon-button
    class="customer-more-button"
    (click)="openLsaSummary()"
    aria-label="GLS Summary"
    matTooltip="GLS Summary"
  >
    <mat-icon class="mat-icon-18">verified_user</mat-icon>
  </button>
  <button
    mat-icon-button
    class="customer-more-button"
    [matMenuTriggerFor]="menu"
    matTooltip="Customer Actions"
  >
    <mat-icon class="mat-icon-16">more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="createTask('customer')">
      <mat-icon>library_add</mat-icon>
      <span>Create Task</span>
    </button>
    <button
      mat-menu-item
      (click)="tasksPopover.open()"
      [satPopoverAnchor]="tasksPopover"
    >
      <mat-icon>playlist_add_check</mat-icon>
      <span>View Related Tasks</span>
    </button>
    <button mat-menu-item (click)="goToCustomer()">
      <mat-icon>person_pin</mat-icon>
      <span>Go To Customer</span>
    </button>
    <button mat-menu-item (click)="openAccountFeed()">
      <mat-icon>list_alt</mat-icon>
      <span>Open Customer Feed</span>
    </button>
    <button mat-menu-item (click)="logCommunication()">
      <mat-icon>speaker_notes</mat-icon>
      <span>Log Communication</span>
    </button>
    <button mat-menu-item (click)="createMessage()">
      <mat-icon>email</mat-icon>
      <span>New Message</span>
    </button>
  </mat-menu>
</ng-container>

<sat-popover #tasksPopover [hasBackdrop]="true">
  <ease-customer-tasks-popover
    *ngIf="tasksPopover.isOpen()"
    [customerId]="customerId"
  >
  </ease-customer-tasks-popover>
</sat-popover>

<sat-popover [autoFocus]="true" [hasBackdrop]="true" #snoozePopover>
  <ease-snooze-popover
    [account]="account"
    [cellParams]="params"
    (snoozeSuccess)="snoozePopover.close()"
    (showRelatedAccounts)="showRelatedAccounts()"
    *ngIf="snoozePopover.isOpen()"
  ></ease-snooze-popover>
</sat-popover>
