import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { Account, OsMetric } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';

@Component({
  selector: 'ease-os-metric-cell',
  templateUrl: './os-metric-cell.component.html',
  styleUrls: ['./os-metric-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OsMetricCellComponent implements AgRendererComponent {
  public account: Account;
  public isGroup: boolean;
  public optiscoreMetric: OsMetric;

  constructor(public accountDashboardService: AccountDashboardService) {}

  agInit(params: ICellRendererParams): void {
    this.account = params.data;
    this.isGroup = params.node.group;

    if (this.account) {
      this.optiscoreMetric = this.account.osMetric;
    }
  }

  refresh(params: ICellRendererParams) {
    return true;
  }
}
