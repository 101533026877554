import { Injectable } from '@angular/core';
import * as MarkdownIt from 'markdown-it';
import * as MarkdownItMark from 'markdown-it-mark';
import * as MarkdownItUnderline from 'markdown-it-underline';
import * as MarkDownFlowDockPlugin from 'markdown-it-flowdock';
import TurndownService from 'turndown';
import * as turndownPluginGfm from 'turndown-plugin-gfm';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { UserService } from 'src/app/users/user.service';
import { UserGroupsService } from 'src/app/users/user-groups/user-groups.service';
import { ProseSize, MentionSuggestion } from './markdown.interface';

@Injectable({ providedIn: 'root' })
export class MarkdownService {
  private markdownIt = new MarkdownIt({
    breaks: true,
    linkify: true
  })
    .use(MarkDownFlowDockPlugin)
    .use(MarkdownItMark)
    .use(MarkdownItUnderline);
  private turndownService: TurndownService;
  public urlTest = /https?:\/\/[\S]+/;

  constructor(
    private userService: UserService,
    private userGroupsService: UserGroupsService
  ) {
    // Remember old renderer, if overriden, or proxy to default renderer
    const newRenderer = (tokens, idx, options, env, self) =>
      self.renderToken(tokens, idx, options);
    const defaultRender =
      this.markdownIt.renderer.rules.link_open || newRenderer;

    this.markdownIt.renderer.rules.link_open = (
      tokens,
      idx,
      options,
      env,
      self
    ) => {
      // If you are sure other plugins can't add `target` - drop check below
      const hrefIndex = tokens[idx].attrIndex('href');
      const href = tokens[idx].attrs[hrefIndex][1];
      const external = /^https?:\/\/.+$/.test(href);
      if (external) {
        const targetIndex = tokens[idx].attrIndex('target');
        if (targetIndex < 0) {
          tokens[idx].attrPush(['target', '_blank']); // add new attribute
        } else {
          tokens[idx].attrs[targetIndex][1] = '_blank'; // replace value of existing attr
        }
      }

      // pass token to default renderer.
      return defaultRender(tokens, idx, options, env, self);
    };

    // override MarkDownFlowDockPlugin's mention rule to match tiptap's mention node
    this.markdownIt.renderer.rules.mention = (tokens, idx) => {
      const tag = tokens[idx].content;
      const markup = tokens[idx].markup;
      return `<span class="font-bold text-blue-500" data-mention="${tag}" contenteditable="false">${markup}${tag}</span>`;
    };

    this.turndownService = new TurndownService();
    this.initTurndownOptions();
  }

  /**
   * Converts markdown into HTML for rendering
   *
   * @param input markdown
   * @returns the HTML equivalent if markdown was provided. Else, returns an empty string
   */
  render(input: string, inline?: boolean): string {
    if (!input) {
      return '';
    }
    /**
     * Since all underscores were converted to UTF-8 encoding to get around
     * an issue in our HTML->Markdown conversion, we now convert them back to
     * `_` for rendering. Reference: https://github.com/domchristie/turndown/issues/324
     */
    const markdown = input.replace(/%5F/gm, '_');
    return inline
      ? this.markdownIt.renderInline(markdown)
      : this.markdownIt.render(markdown);
  }

  /**
   * Sets of turndown rules and plugins to match
   * markdown-it's rendering
   */
  initTurndownOptions() {
    this.turndownService.addRule('strikethrough', {
      filter: ['s'],
      replacement: content => `~~${content}~~`
    });
    this.turndownService.addRule('mark', {
      filter: ['mark'],
      replacement: content => `==${content}==`
    });
    this.turndownService.addRule('underline', {
      filter: ['u'],
      replacement: content =>
        // skip unnecessary underline on links
        this.urlTest.test(content) ? `${content}` : `_${content}_`
    });
    this.turndownService.addRule('italic', {
      filter: ['em'],
      replacement: content => `*${content}*`
    });

    // table and <p> overrides
    this.turndownService.use([turndownPluginGfm.tables]).addRule('table', {
      filter: node => node.nodeName === 'TABLE',
      replacement: content => {
        content = content.replaceAll('\n\n', '');
        return content;
      }
    });

    // overrides Turndown's `<pre>` rule that has issues when the block is just after a list
    this.turndownService.addRule('fenceAllPreformattedText', {
      filter: ['pre'],
      replacement: (content, node, options) =>
        `\n${options.fence}\n${content}\n${options.fence}\n`
    });
  }

  toMarkdown(html: string): string {
    /**
     * We intentionally convert undersscores to UTF-8 encoding to get around an
     * on-going issue with Turndown, the library we use for HTML->Markdown conversion.
     * https://github.com/domchristie/turndown/issues/324
     */
    html = html.replace(/_/gm, '%5F');

    return this.turndownService.turndown(html);
  }

  getSuggestions(): Observable<MentionSuggestion[]> {
    return combineLatest([
      this.userService.users,
      this.userService.avatars,
      this.userGroupsService.getAll()
    ]).pipe(
      map(([users, avatars, userGroups]) =>
        users
          .reduce((acc: any[], user) => {
            const toPush = Object.assign(
              user,
              avatars.find(userAvatar => userAvatar.$key === user.$key),
              { type: 'user' }
            );
            acc.push(toPush);
            return acc;
          }, [])
          .concat(
            userGroups.reduce((acc: any[], userGroup) => {
              const toPush = Object.assign(userGroup, {
                email: `${userGroup.$key}@searchkings.ca`,
                type: 'group'
              });
              acc.push(toPush);
              return acc;
            }, [])
          )
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  /**
   * Replacer function that adds spaces to either ends of a link
   * if it's directly beside a non-whitespace character. This makes
   * sure that we don't encounter parsing issues when content is
   * converted to markdown then back to HTML.
   *
   * This takes into account the possibility where the link is beside
   * a non-breaking space character, &nbsp;
   *
   * @param match the whole match, not needed but passed by default on String.replace()
   * @param leadingChar the adjacent character on the left side of the link
   * @param link
   * @param trailingChar the adjacent character on the right side of the link
   * @returns
   */
  isolateLinks(
    match: string,
    leadingChar: string,
    link: string,
    trailingChar: string
  ): string {
    let isolatedLink = link;

    isolatedLink = leadingChar
      ? leadingChar === '&nbsp;'
        ? ` ${isolatedLink}`
        : `${leadingChar} ${isolatedLink}`
      : isolatedLink;
    isolatedLink = trailingChar
      ? trailingChar === '&nbsp;'
        ? `${isolatedLink} `
        : `${isolatedLink} ${trailingChar}`
      : isolatedLink;

    return isolatedLink;
  }

  /**
   * Makes necessary transformations to avoid issues width
   * HTML - Markdown conversions (and vice versa).
   * - removes unnecessary marks on <br> tags
   * - isolates links
   * - transforms all instances of `<a href="url1">url2</a>` to `url2`.
   *   This step is important to avoid a current issue with markdown-it-flowdock:
   *   https://github.com/flowdock/markdown-it-flowdock/issues/7
   *
   * @param html
   * @returns HTML that's safe to convert to markdown
   */
  prepareContent(html: string): string {
    const markedBrTest = /(?:<\w*>\s*)*(<br\/?>)(?:\s*<\/\w*>)*/gm;
    const linkTest =
      /((?:&nbsp;)|\S){0,1}(<\s*a[^>]*>(?:.*?)<\s*\/\s*a>)((?:&nbsp;)|\S){0,1}/gm;
    const anchoredUrlTest = new RegExp(
      `<a\\s[="\\s\\w_]*href="(?:${this.urlTest.source})">((?:<\\w*?>)*(?:${this.urlTest.source})(?:<\\/\\w*?>)*)<\\/a>`,
      'gm'
    );

    return html
      .replace(markedBrTest, '$1')
      .replace(linkTest, this.isolateLinks)
      .replace(anchoredUrlTest, '$1');
  }

  getProseClass(size: ProseSize): string {
    switch (size) {
      case 'sm':
        return 'prose prose-sm';
      case 'lg':
        return 'prose prose-lg';
      case 'xl':
        return 'prose prose-xl';
      case '2xl':
        return 'prose prose-2xl';
      default:
        return 'prose';
    }
  }

  /**
   * Having a '#' at the end of a URL causes issues on markdownIt.render(). We avoid
   * the issue by removing/ignoring that character on render, a character that serves
   * no purpose
   *
   * @param url
   * @returns
   */
  sanitizeUrl(url: string): string {
    //TODO: remove log once we see the data that's passed causing the error .replace() is not a function
    //https://github.com/SearchKings/ease-ngx/issues/2039
    console.log(url, typeof url);
    return url.replace(
      /(https?:\/\/[\S]+)(#)$/gm,
      (raw, withoutNumberSignAtTheEnd) => withoutNumberSignAtTheEnd
    );
  }
}
