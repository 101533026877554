import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { SkSyncHttpService } from 'src/app/shared/sksync-http.service';
import { ChartMetric } from '../../shared/charts/charts.interface';
import { ConfirmService } from '../../shared/confirm/confirm.service';
import { CUSTOMER_RULES_PATH } from '../../shared/firebase-paths';
import { Change } from '../../shared/shared.interface';
import { firebaseJSON } from '../../shared/utils/functions';
import { SNACKBAR_DURATION_SUCCESS } from '../../shared/constants';
import { CustomerBudget } from '../customer-accounts/customer-account-budget/customer-account-budget.interface';
import { CustomerAccountRulesService } from '../customer-accounts/customer-account-rules.service';
import { CustomerRuleMetricsParams } from './customer-rules.interface';

@Injectable({ providedIn: 'root' })
export class CustomerRulesService {
  constructor(
    private angularFire: FirebaseDbService,
    private confirmService: ConfirmService,
    private customerAccountRulesService: CustomerAccountRulesService,
    private matSnackBar: MatSnackBar,
    private skSyncHttp: SkSyncHttpService
  ) {}

  getAll(customerId: string): Observable<any> {
    return this.angularFire.getObject(`/${CUSTOMER_RULES_PATH}/${customerId}`);
  }

  get(customerId: string, ruleName: string): Observable<any> {
    return this.angularFire.getObject(
      `/${CUSTOMER_RULES_PATH}/${customerId}/${ruleName}`
    );
  }

  getMetrics(
    customerId: string,
    params: Partial<CustomerRuleMetricsParams>
  ): Observable<ChartMetric[]> {
    return this.skSyncHttp.get<ChartMetric[]>(
      `/customers/${customerId}/metrics`,
      {
        params
      }
    );
  }

  update(
    customerId: string,
    ruleName: string,
    ruleSettings: any,
    showConfirmation: boolean = true
  ): Promise<any> {
    return this.angularFire
      .object(`/${CUSTOMER_RULES_PATH}/${customerId}/${ruleName}`)
      .update(firebaseJSON(ruleSettings))
      .then(
        () =>
          showConfirmation &&
          this.matSnackBar.open('Settings saved', 'Close', {
            duration: SNACKBAR_DURATION_SUCCESS
          })
      );
  }

  async updateBudget(
    customerId: string,
    budgetChange: Change<Partial<CustomerBudget>>
  ): Promise<any> {
    if (!budgetChange.before.enabled && budgetChange.after.enabled) {
      const hasAccountBudgetsEnabled =
        await this.customerAccountRulesService.assertCustomerAccountBudgetsEnabled(
          customerId
        );

      if (hasAccountBudgetsEnabled.enabled) {
        const confirmResult = await this.confirmService.confirm(
          {
            title: 'Disable account-level budgets rules?',
            message:
              'Leaving account-level budget rules enabled can result in duplicate alerts, but may be desirable if you need fine-grained control.',
            confirmText: 'Yes, disable them',
            confirmColor: 'primary',
            cancelText: 'No, leave them enabled'
          },
          { disableClose: true, width: '450px' }
        );

        if (confirmResult.confirm) {
          await Promise.all(
            hasAccountBudgetsEnabled.accounts.map(accountMeta =>
              this.customerAccountRulesService.update(accountMeta, 'budget', {
                enabled: false
              })
            )
          );
        }
      }
    }

    return this.update(customerId, 'budget', budgetChange.after).then(() =>
      this.matSnackBar.open('Settings saved', 'Close', {
        duration: SNACKBAR_DURATION_SUCCESS
      })
    );
  }

  async remove(customerId: string) {
    if (customerId) {
      return this.angularFire
        .object(`/${CUSTOMER_RULES_PATH}/${customerId}`)
        .remove();
    }
  }
}
