import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { AccountMetadata } from '../../account-dashboard.interface';

@Component({
  selector: 'ease-alert-cell-popover',
  templateUrl: './alert-cell-popover.component.html',
  styleUrls: ['./alert-cell-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertCellPopoverComponent implements OnInit {
  @Input() alert: AccountMetadata;

  constructor() {}

  ngOnInit() {}
}
