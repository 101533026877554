<div class="my-2 flex flex-col gap-2 py-2 text-gray-600">
  <div class="flex flex-row items-center gap-2">
    <ease-avatar class="self-start" [userId]="item?.createdBy"></ease-avatar>

    <div class="flex flex-1 flex-col gap-1 overflow-hidden">
      <div class="text-[13px] leading-5">
        <span class="font-bold">
          {{ users[item?.createdBy]?.name }}
        </span>
        sent to {{ item.recipientsCount | i18nPlural: messagePluralMapping }}
      </div>

      <div
        (click)="detailsToggle.toggle()"
        class="flex cursor-pointer flex-row items-center gap-1 text-[13px] font-medium leading-5 text-gray-600"
      >
        <mat-icon class="mat-icon-16">email</mat-icon>
        {{ item.smartEmailName }}
        <mat-icon class="mat-icon-16">
          {{ detailsToggle.isHidden ? 'expand_more' : 'expand_less' }}
        </mat-icon>
      </div>
      <ease-toggle #detailsToggle="toggle">
        <ng-container *ngIf="!detailsToggle.isHidden">
          <div class="flex flex-col gap-2 py-1 text-xs">
            <div
              *ngFor="let message of item.messages | keyvalue"
              [easeCustomerMessagePreview]="message.key"
              class="flex cursor-pointer flex-col border-l-2 border-solid border-gray-300 p-1 py-1 pl-2"
            >
              <div class="flex flex-row gap-1">
                TO:
                <span class="font-medium text-blue-500">{{
                  message.value.to
                }}</span>
              </div>

              <div
                *ngIf="message.value.cc"
                class="flex flex-row gap-1 truncate"
              >
                CC:
                <span class="truncate font-medium text-blue-500">
                  {{ message.value.cc }}
                </span>
              </div>

              <div
                *ngIf="message.value.bcc"
                class="flex flex-row gap-1 truncate"
              >
                BCC:
                <span class="truncate font-medium text-blue-500">
                  {{ message.value.bcc }}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </ease-toggle>

      <div class="text-xs leading-5 text-gray-600">
        {{ item?.createdAt | amDateFormat: dateFormat }}
      </div>
    </div>
  </div>
</div>
