import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { TypedFormGroup } from 'src/app/shared/reactive-forms';
import {
  AdwordsAccount,
  BingAccount,
  LsaAccount
} from '../../../customers/customer-accounts/customer-accounts.interface';
import { CustomerAccountsService } from '../../../customers/customer-accounts/customer-accounts.service';
import { PlanFrequency } from '../../../customers/customer-billing/customer-billing.interface';
import { CustomerBillingService } from '../../../customers/customer-billing/customer-billing.service';
import { AddFundingFormValue, FundingForm } from '../funding.interface';
import { FundingService } from '../funding.service';
import {
  FundingManagerFeeListItem,
  FundingManagerListChild,
  FundingManagerListParent
} from './funding-manager.interface';
@Component({
  selector: 'ease-funding-manager',
  templateUrl: './funding-manager.component.html',
  styleUrls: ['./funding-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundingManagerComponent implements OnInit {
  @Input() fundingForm: TypedFormGroup<FundingForm>;
  @Input() customerId: string;

  public allAccounts$: Observable<
    [AdwordsAccount[], LsaAccount[], BingAccount[]]
  >;
  public accountsSpendList$: Observable<FundingManagerListParent[]>;
  public managementList$: Observable<FundingManagerFeeListItem[]>;
  public planFrequencies$: Observable<PlanFrequency[]>;
  public object: ObjectConstructor = Object;

  get fundingDetailsNotes(): FormControl<string> {
    return this.fundingForm.controls.fundingDetails.controls.notes;
  }

  get fundingSpend(): TypedFormGroup<AddFundingFormValue> {
    return this.fundingForm.controls.fundingSpend;
  }

  private accountTypeNamingMapping = {
    adwords: 'Google Ads',
    lsa: 'Google Local Services',
    bing: 'Bing'
  };

  constructor(
    private customerBillingService: CustomerBillingService,
    private customerAccountService: CustomerAccountsService,
    private formBuilder: FormBuilder,
    public fundingService: FundingService
  ) {}

  ngOnInit() {
    this.planFrequencies$ = this.customerBillingService
      .getPlans('searchkingsservices')
      .pipe(
        map(([plan]) => plan.planFrequencies),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.allAccounts$ = combineLatest([
      this.customerAccountService.getAdwordsAccounts(this.customerId),
      this.customerAccountService.getLsaAccounts(this.customerId),
      this.customerAccountService.getBingAccounts(this.customerId)
    ]).pipe(shareReplay({ refCount: true }));

    this.accountsSpendList$ = this.allAccounts$.pipe(
      map(allAccounts => {
        const accountSelectArray: FundingManagerListParent[] = [];
        allAccounts.forEach(accountList => {
          if (accountList.length) {
            const accountType = accountList[0].$accountType;
            const nestedItems: FundingManagerListChild[] = [];

            accountList.forEach(account =>
              nestedItems.push({
                displayValue: account.name,
                value: account.$key,
                accountType
              })
            );

            accountSelectArray.push({
              displayValue: this.accountTypeNamingMapping[accountType],
              accountType,
              nestedItems
            });
          }
        });

        return accountSelectArray;
      })
    );

    this.managementList$ = this.fundingService.getFundingTypes().pipe(
      map(types =>
        types.reduce((acc: FundingManagerFeeListItem[], type) => {
          if (type.billingAction) {
            acc.push({
              displayValue: type.name,
              accountType: type.billingPlanProduct.code,
              value: this.customerId,
              billingAction: type.billingAction
            });
          }
          return acc;
        }, [])
      )
    );
  }

  addSpend(
    accountValue: FundingManagerListChild | FundingManagerFeeListItem,
    spendType: 'platform' | 'management'
  ) {
    const spendGroup = this.fundingSpend.controls[`${spendType}Spend`];

    if (!spendGroup.controls[accountValue.accountType]) {
      spendGroup.addControl(
        accountValue.accountType,
        this.formBuilder.group({})
      );
    }

    if (
      !spendGroup.controls[accountValue.accountType].controls[
        accountValue.value
      ]
    ) {
      spendGroup.controls[accountValue.accountType].addControl(
        accountValue.value,
        this.formBuilder.group({
          accountName: this.formBuilder.control(accountValue.displayValue, {
            validators: Validators.required
          }),
          id: this.formBuilder.control(accountValue.value),
          accountType: this.formBuilder.control(accountValue.accountType),
          fundingType: this.formBuilder.control(spendType),
          amount: this.formBuilder.control(null, {
            validators: Validators.required
          })
        })
      );

      if (
        spendType === 'management' &&
        (accountValue as FundingManagerFeeListItem)?.billingAction ===
          'subscription'
      ) {
        spendGroup.controls[accountValue.accountType].controls[
          accountValue.value
        ].addControl(
          'frequencyId',
          this.formBuilder.control(null, { validators: Validators.required })
        );
      }
    }
  }

  removeSpend(
    accountType: string,
    accountId: string,
    spendType: 'platform' | 'management'
  ) {
    const spendGroup = this.fundingSpend.controls[`${spendType}Spend`];

    /**
     * If a formgroup exists for the account type, remove the
     * specified account control from this group
     */
    if (spendGroup.controls[accountType]) {
      spendGroup.controls[accountType].removeControl(accountId);
    }

    /**
     * If there are no more controls (accounts) left in this account
     * type group, remove the entire group
     */
    if (!Object.keys(spendGroup.controls?.[accountType] || {})?.length) {
      spendGroup.removeControl(accountType);
    }
  }
}
