import { NgModule } from '@angular/core';

import { MarkdownModule } from '../../markdown/markdown.module';
import { PopoverModule } from '../popover.module';
import { SharedFormModule } from '../../shared-form.module';
import { SharedModule } from '../../shared.module';
import { AccountIconModule } from '../../account-icon/account-icon.module';
import { WindowCloseModule } from '../../window/window-close/window-close.module';
import { CompleteTaskPopoverComponent } from './complete-task-popover.component';

@NgModule({
  imports: [
    AccountIconModule,
    MarkdownModule,
    PopoverModule,
    SharedModule,
    SharedFormModule,
    WindowCloseModule
  ],
  declarations: [CompleteTaskPopoverComponent],
  exports: [CompleteTaskPopoverComponent, PopoverModule]
})
export class CompleteTaskPopoverModule {}
