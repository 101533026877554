import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ease-sheet-content',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./sheet-content.component.scss']
})
export class SheetContentComponent {
  @Input() size: string = 'md';
  @HostBinding('class') get hostClass() {
    const defaultClasses = 'block';
    let attachClass = '';

    switch (this.size) {
      case 'xs':
        attachClass = 'p-1';
        break;
      case 'sm':
        attachClass = 'p-2';
        break;
      case 'md':
        attachClass = 'p-5';
        break;
      case 'lg':
        attachClass = 'p-10';
        break;
      case 'xs sm':
        attachClass = 'py-1 px-2';
        break;
      case 'sm md':
        attachClass = 'py-2 px-5';
        break;
      case 'md lg':
        attachClass = 'py-5 px-10';
        break;
    }

    return defaultClasses + ' ' + attachClass;
  }
}
