<div
  class="checklist-checkbox"
  [ngClass]="{
    'checklist-checkbox--checked': group?.controls?.checked?.value
  }"
  [formGroup]="group"
>
  <mat-icon
    matTooltip="Checking this box will perform the action described automatically"
    *ngIf="templateOptions?.actionKey"
    class="action-icon"
    >offline_bolt</mat-icon
  >
  <mat-checkbox
    #checkbox
    [checked]="group?.controls?.checked?.value"
    [disabled]="group?.controls?.checked?.disabled"
    (click)="clickCheckbox(checkbox)"
    color="primary"
  >
    <span
      [ngClass]="{
        'line-through decoration-gray-600': group?.controls?.checked?.value
      }"
      [easeMarkdown]="templateOptions?.groupLabel"
    ></span>
  </mat-checkbox>
  <div
    class="flex flex-row gap-2"
    *ngFor="let checkboxItem of templateOptions?.options | objectToIterable"
    [formGroup]="group?.controls[checkboxItem?.key] || emptyNestedCheckboxGroup"
  >
    <mat-checkbox color="primary" formControlName="checked">
      {{ checkboxItem?.label }}
    </mat-checkbox>
  </div>
  <div class="checklist-meta">
    <div
      class="ml-6 flex items-center"
      [ngClass]="{ '!ml-[29px] md:ml-0': templateOptions?.actionKey }"
      *ngIf="group?.controls?.completedBy?.value"
    >
      <div>{{ group.controls.completedBy.value }}</div>
    </div>
    <div
      *ngIf="group?.controls?.completedAt?.value"
      class="flex items-center text-[11px] leading-snug text-gray-600"
    >
      <mat-icon class="mat-icon-12 mr-0.5 mb-px !hidden md:!block"
        >done</mat-icon
      >
      {{ group.controls.completedAt.value | amDateFormat: 'MMM D, h:mm a' }}
    </div>
  </div>
</div>
