import { NgModule } from '@angular/core';

import { ListGroupComponent } from './list-group/list-group.component';
import { ListGroupHeaderComponent } from './list-group-header/list-group-header.component';
import { ListGroupItemComponent } from './list-group-item/list-group-item.component';

@NgModule({
  declarations: [
    ListGroupComponent,
    ListGroupHeaderComponent,
    ListGroupItemComponent
  ],
  exports: [
    ListGroupComponent,
    ListGroupHeaderComponent,
    ListGroupItemComponent
  ]
})
export class ListGroupModule {}
