import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';

import { TaskEditFormModule } from 'src/app/tasks/task-edit-form/task-edit-form.module';
import { TaskPhaseModule } from 'src/app/tasks/task-phase/task-phase.module';
import { TaskStatusModule } from 'src/app/tasks/task-status/task-status.module';
import { TaskUserMenuModule } from 'src/app/tasks/task-user-menu/task-user-menu.module';
import { BulkTargetingModule } from '../../bulk-targeting/bulk-targeting.module';
import { EntityIconModule } from '../../entity-icon/entity-icon.module';
import { FinderModule } from '../../finder/finder.module';
import { MarkdownModule } from '../../markdown/markdown.module';
import { SharedFormModule } from '../../shared-form.module';
import { SharedPipesModule } from '../../shared-pipes.module';
import { SharedModule } from '../../shared.module';
import { UserSelectorInputModule } from '../../user-selector-input/user-selector-input.module';

import { AdvancedAutocompleteModule } from '../../advanced-autocomplete/advanced-autocomplete.module';
import { TaskWindowerModule } from '../window-pane-view-task/task-windower.module';
import { CreateTaskBulkQueueComponent } from './create-task-bulk/create-task-bulk-queue/create-task-bulk-queue.component';
import { CreateTaskBulkComponent } from './create-task-bulk/create-task-bulk.component';
import { CreateTaskSingleComponent } from './create-task-single/create-task-single.component';
import { WindowPaneCreateTaskComponent } from './window-pane-create-task.component';

@NgModule({
  imports: [
    AdvancedAutocompleteModule,
    AgGridModule,
    BulkTargetingModule,
    CommonModule,
    DragDropModule,
    EntityIconModule,
    FinderModule,
    MarkdownModule,
    SharedFormModule,
    SharedModule,
    SharedPipesModule,
    TaskPhaseModule,
    TaskStatusModule,
    TaskEditFormModule,
    TaskUserMenuModule,
    UserSelectorInputModule,
    AdvancedAutocompleteModule,
    TaskWindowerModule
  ],
  declarations: [
    CreateTaskBulkComponent,
    CreateTaskBulkQueueComponent,
    CreateTaskSingleComponent,
    WindowPaneCreateTaskComponent
  ],
  exports: [WindowPaneCreateTaskComponent]
})
export class WindowPaneCreateTaskModule {}
