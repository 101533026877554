import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { SatPopoverModule } from '@ncstate/sat-popover';

import { CustomerAccountBudgetModule } from 'src/app/customers/customer-accounts/customer-account-budget/customer-account-budget.module';
import { CustomerFeedModule } from 'src/app/customers/customer-feed/customer-feed.module';
import { SharedModule } from '../../../shared/shared.module';
import { GridCellsModule } from '../grid-cells/grid-cells.module';
import { DashboardPopoversModule } from '../dashboard-popovers/dashboard-popovers.module';
import { DetailAccountRowsModule } from '../detail-account-rows/detail-account-rows.module';
import { MetricsModule } from '../../../shared/metrics/metrics.module';
import { BreakpointsModule } from '../../../shared/breakpoints.module';

import { AccountFeedDialogComponent } from '../account-feed-dialog/account-feed-dialog.component';
import { AccountBudgetDialogComponent } from '../account-budget-dialog/account-budget-dialog.component';
import { AccountDashboardTableComponent } from './account-dashboard-table.component';

@NgModule({
  imports: [
    AgGridModule,
    BreakpointsModule,
    CommonModule,
    CustomerAccountBudgetModule,
    CustomerFeedModule,
    DashboardPopoversModule,
    DetailAccountRowsModule,
    GridCellsModule,
    MetricsModule,
    SatPopoverModule,
    SharedModule
  ],
  declarations: [
    AccountBudgetDialogComponent,
    AccountDashboardTableComponent,
    AccountFeedDialogComponent
  ],
  exports: [AccountDashboardTableComponent]
})
export class AccountDashboardTableModule {}
