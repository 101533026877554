<ease-sheet
  *ngIf="notes$ | async as taskNotes"
  class="flex flex-1 flex-col rounded-none"
>
  <ng-container *ngIf="users | async as allUsers">
    <ease-sheet-panel class="!border-0">
      <ease-sheet-content
        *ngIf="pinnedNotes$ | async as pinnedNotes"
        size="md"
        class="!p-0"
      >
        <div class="relative z-1" [ngClass]="{ hidden: !pinnedNotes?.length }">
          <div
            class="flex max-h-[35vh] flex-1 flex-col overflow-y-auto overflow-x-hidden px-4"
          >
            <ng-container *ngIf="!hidingPinnedStatus">
              <ease-feed-item-note
                *ngFor="let note of pinnedNotes; trackBy: getKey"
                [users]="allUsers"
                [taskId]="taskId"
                [note]="note"
                [dateFormat]="'MMM D, h:mm a'"
                [editable]="note.$isEditable"
                (save)="updateNote({ item: note, change: $event })"
                [nowTime]="nowTime"
                [currentUser]="userService.currentUser.$key"
                [canPin]="true"
                [canReact]="true"
                [canShare]="true"
              >
              </ease-feed-item-note>
            </ng-container>
          </div>

          <button
            (click)="toggleHidingPinnedNotes()"
            class="!h-10 w-full !rounded-none !text-[13px] !text-gray-800"
            mat-button
          >
            {{ hidingPinnedStatus ? 'Show' : 'Hide' }} Pinned Notes
            <mat-icon class="ml-2">
              {{ hidingPinnedStatus ? 'expand_more' : 'expand_less' }}
            </mat-icon>
          </button>
        </div>
      </ease-sheet-content>
    </ease-sheet-panel>

    <ease-sheet-panel
      class="relative flex-1 border-gray-300 bg-gray-100 bg-bottom bg-no-repeat"
    >
      <div
        class="absolute top-1/2 bottom-0 left-0 right-0 text-center"
        *ngIf="!taskNotes?.length"
      >
        <h3 class="my-0 text-2xl font-light text-gray-500">
          {{
            !taskNotes?.length && completed ? 'No Activity' : 'No Activity Yet!'
          }}
        </h3>
        <p class="mt-2 text-sm font-normal text-gray-600">
          {{
            !taskNotes?.length && completed
              ? 'Re-open this task to add a note.'
              : 'Add your note below'
          }}
        </p>
      </div>
      <ease-sheet-content class="!h-full !p-0" size="md">
        <div class="scroller-wrapper h-full">
          <virtual-scroller
            #scroll
            [items]="taskNotes"
            [enableUnequalChildrenSizes]="true"
          >
            <ease-feed-item-note
              *ngFor="let note of scroll.viewPortItems; trackBy: getKey"
              [users]="allUsers"
              [taskId]="taskId"
              [note]="note"
              [dateFormat]="'MMM D, h:mm a'"
              [editable]="note.$isEditable"
              (save)="updateNote({ item: note, change: $event })"
              [nowTime]="nowTime"
              [currentUser]="userService.currentUser.$key"
              [canPin]="true"
              [canReact]="true"
              [canShare]="true"
            >
            </ease-feed-item-note>
          </virtual-scroller>
        </div>
      </ease-sheet-content>
    </ease-sheet-panel>
  </ng-container>

  <ease-sheet-content *ngIf="!completed" class="flex-none">
    <ease-markdown-form
      [persistenceKey]="'taskNotes-' + taskId"
      #markdownForm
      [allowMentions]="true"
      [(ngModel)]="newNoteBody"
      (save)="addNote(newNoteBody)"
      [saveText]="'Add Task Note'"
      [hideActionsUntilEntry]="true"
      [disabled]="isWorking"
      placeholder="Add a note, @mention users"
      size="sm"
      [autoCollapse]="true"
    >
    </ease-markdown-form>
  </ease-sheet-content>
</ease-sheet>
