import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { MultiSearchRequestsSchema } from 'typesense/lib/Typesense/MultiSearch';
import { SNACKBAR_DURATION_SUCCESS } from '../shared/constants';
import { FirebaseDbService } from '../shared/firebase-db.service';
import { USER_TASK_BACKPACKS_PATH } from '../shared/firebase-paths';
import { SearchUtils } from '../shared/search/search-utils';
import { UserService } from '../users/user.service';
import { TaskDashboardsViewService } from './task-dashboards/task-dashboards-view/task-dashboards-view.service';

@Injectable({
  providedIn: 'root'
})
export class BackpackService {
  public backpack$: Observable<Record<string, boolean>>;
  public backpack: Record<string, boolean>;
  private maxBackpackItems: number = 50;
  private currentBackpackSize: number = 0;
  constructor(
    private angularFire: FirebaseDbService,
    private userService: UserService,
    private matSnackbar: MatSnackBar,
    private taskDashboardViewService: TaskDashboardsViewService
  ) {
    this.backpack$ = this.get().pipe(
      tap(tasks => {
        this.currentBackpackSize = Object.keys(tasks).length;
        this.backpack = tasks;
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  async add(taskId: string, userId?: string): Promise<void> {
    if (this.currentBackpackSize <= this.maxBackpackItems) {
      await this.angularFire
        .object(
          `${USER_TASK_BACKPACKS_PATH}/${
            userId ? userId : this.userService.currentUser.$key
          }/${taskId}`
        )
        .set(true);

      this.taskDashboardViewService.refresh();
      this.matSnackbar.open('Task added to backpack', 'Okay', {
        duration: SNACKBAR_DURATION_SUCCESS
      });
    } else {
      this.matSnackbar.open(
        `Max reached. You can only add ${this.maxBackpackItems} tasks to your backpack. Remove tasks from backpack first.`,
        'Okay'
      );
    }
  }

  get(userId?: string): Observable<Record<string, boolean>> {
    return userId
      ? this.angularFire.getObject(`${USER_TASK_BACKPACKS_PATH}/${userId}`)
      : this.userService.currentUser$.pipe(
          switchMap(currentUser => this.get(currentUser.$key))
        );
  }

  getAll(): Observable<Record<string, Record<string, boolean>>> {
    return this.angularFire.getObject(USER_TASK_BACKPACKS_PATH);
  }

  async remove(taskId: string, userId?: string): Promise<void> {
    await this.angularFire
      .object(
        `${USER_TASK_BACKPACKS_PATH}/${
          userId ? userId : this.userService.currentUser.$key
        }/${taskId}`
      )
      .remove();

    this.taskDashboardViewService.refresh();

    this.matSnackbar.open('Task removed from backpack', 'Okay', {
      duration: SNACKBAR_DURATION_SUCCESS
    });
  }

  async backpackHandleHook(
    queries: MultiSearchRequestsSchema
  ): Promise<MultiSearchRequestsSchema> {
    return {
      searches: await Promise.all(
        queries.searches.map(async query => {
          // Check if the 'filter_by' property of the searchParams object includes the string 'backpackOwner'
          if (query?.filter_by?.includes('backpackOwner')) {
            // Use a regular expression to extract a user ID from the 'filter_by' string
            const regex = /backpackOwner:=([A-Za-z0-9_~-]+)/;
            const userId =
              regex.exec(query?.filter_by)[1] === 'CURRENT_USER'
                ? this.userService.currentUser.$key
                : regex.exec(query?.filter_by)[1];

            // Retrieve the backpack object for the specified user ID
            const backpack = await firstValueFrom(this.get(userId));

            // Modify the 'filter_by' property of the searchParams object by replacing the original value
            // with a new value generated by calling the SearchUtils.in() function with 'id' and the keys
            // of the backpack object as arguments.

            query.filter_by = query.filter_by.replace(
              regex,
              SearchUtils.in('id', Object.keys(backpack))
            );
          }

          return query;
        })
      )
    };
  }
}
