import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PortalModule } from '@angular/cdk/portal';

import { StopEventModule } from '../stop-event/stop-event.module';
import { SheetComponent } from './sheet/sheet.component';
import { SheetContentComponent } from './sheet-content/sheet-content.component';
import { SheetDividerComponent } from './sheet-divider/sheet-divider.component';
import {
  SheetExpandComponent,
  SheetExpandContentDirective,
  SheetExpanderDirective
} from './sheet-expander/sheet-expander.component';
import { SheetFooterComponent } from './sheet-footer/sheet-footer.component';
import { SheetGroupComponent } from './sheet-group/sheet-group.component';
import { SheetHeaderComponent } from './sheet-header/sheet-header.component';
import { SheetPanelComponent } from './sheet-panel/sheet-panel.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    PortalModule,
    StopEventModule
  ],
  declarations: [
    SheetComponent,
    SheetContentComponent,
    SheetDividerComponent,
    SheetExpandComponent,
    SheetExpandContentDirective,
    SheetExpanderDirective,
    SheetFooterComponent,
    SheetGroupComponent,
    SheetHeaderComponent,
    SheetPanelComponent
  ],
  exports: [
    SheetComponent,
    SheetContentComponent,
    SheetDividerComponent,
    SheetExpandComponent,
    SheetExpandContentDirective,
    SheetExpanderDirective,
    SheetFooterComponent,
    SheetGroupComponent,
    SheetHeaderComponent,
    SheetPanelComponent
  ]
})
export class SheetsModule {}
