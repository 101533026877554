import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { ButMetric } from '../account-dashboard.interface';

@Pipe({
  name: 'butSuggestedBudget'
})
export class ButSuggestedBudgetPipe implements PipeTransform {
  transform(butMetric: ButMetric): any {
    return this.getSuggestedDailyBudget(butMetric);
  }

  private getSuggestedDailyBudget(butMetic: ButMetric): number {
    const budget = parseFloat(`${butMetic.budget}`);
    const cost = parseFloat(`${butMetic.butCostToDate}`);

    if (budget <= cost) {
      return 0;
    }

    const today = moment().date();
    const remainingBudget = budget - cost;
    const lastDayOfMonth = moment().endOf('month').date();
    const daysRemaining = lastDayOfMonth - today;
    const suggestedBudget = remainingBudget / daysRemaining;

    return suggestedBudget || 0;
  }
}
