import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TimezoneDateFormatPipe } from '../timezone-date-format.pipe';
import { AvatarInitialsPipe } from './avatar-initials.pipe';
import { AvatarRendererComponent } from './avatar-renderer.component';
import { AvatarComponent } from './avatar.component';
import { AvatarAwayTooltipPipe } from './avatar-renderer.awaytooltip.pipe';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [
    AvatarInitialsPipe,
    AvatarAwayTooltipPipe,
    AvatarRendererComponent,
    AvatarComponent
  ],
  exports: [AvatarInitialsPipe, AvatarRendererComponent, AvatarComponent],
  providers: [TimezoneDateFormatPipe]
})
export class AvatarModule {}
