import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { TaskModel } from '../../../task.model';

@Component({
  selector: 'ease-task-budget-trending',
  templateUrl: './task-budget-trending.component.html',
  styleUrls: [
    '../task-budget.component.scss',
    './task-budget-trending.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBudgetTrendingComponent implements OnInit {
  @Input()
  task: TaskModel;

  constructor() {}

  ngOnInit() {}
}
