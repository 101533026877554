<ease-sheet
  class="flex h-full min-h-0 flex-1 flex-col overflow-auto bg-gray-100"
>
  <mat-toolbar *ngIf="!showingNoteForm" class="text-gray-600">
    <mat-toolbar-row *ngIf="isSearching">
      <mat-form-field floatLabel="never">
        <input
          (blur)="handleSearchBlur()"
          placeholder="Search for anything"
          type="text"
          [formControl]="searchControl"
          #searchInput
          matInput
          maxlength="512"
        />
        <mat-icon matPrefix>search</mat-icon>
        <mat-icon class="cursor-pointer" (click)="toggleSearch()" matSuffix
          >clear</mat-icon
        >
      </mat-form-field>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="!isSearching" class="!bg-gray-50 text-gray-600">
      <button
        (click)="openCommunicationDialog()"
        mat-icon-button
        title="Log Communications"
      >
        <mat-icon>speaker_notes</mat-icon>
      </button>
      <button (click)="showNoteForm()" mat-icon-button title="Add Note">
        <mat-icon>note_add</mat-icon>
      </button>

      <mat-form-field
        class="mat-form-field-flexible mx-3 overflow-hidden text-gray-800"
      >
        <mat-select
          [compareWith]="compareSelectedAccount"
          [(value)]="_selectedAccount"
          (selectionChange)="onAccountSelect($event)"
        >
          <mat-option value="all">All activity</mat-option>
          <mat-option
            *ngFor="
              let account of customerFeedAccounts$ | async;
              trackBy: getKey
            "
            [value]="account"
          >
            <div class="flex flex-row items-center justify-start">
              <ease-account-icon
                class="mr-1"
                [size]="18"
                [accountType]="account.$accountType"
              ></ease-account-icon>
              {{ account.name }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        (click)="filterSelect.open()"
        mat-icon-button
        [matTooltip]="'Activity Filters'"
      >
        <span
          *ngIf="activityFilterControl?.value?.length"
          class="absolute right-1 top-1.5 h-3 w-3 rounded-full border-2 border-solid border-white bg-red-600"
        ></span>
        <mat-icon>filter_alt</mat-icon>
      </button>
      <mat-select
        [formControl]="activityFilterControl"
        #filterSelect
        [disableOptionCentering]="true"
        multiple
        class="filterSelect"
      >
        <mat-option
          *ngFor="let option of filterOptions"
          [value]="option.value"
          [ngClass]="{ '!hidden': option.hidden }"
          >{{ option.viewValue }}</mat-option
        >
      </mat-select>
      <button title="Search Feed" (click)="toggleSearch()" mat-icon-button>
        <mat-icon>search</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <ease-sheet-content *ngIf="showingNoteForm" class="relative flex-none">
    <form [formGroup]="noteForm" (ngSubmit)="addNote()">
      <ng-container
        *ngIf="customerFeedAccounts$ | async as customerFeedAccounts"
      >
        <mat-form-field *ngIf="customerFeedAccounts?.length">
          <mat-select
            placeholder="Apply note to account"
            [compareWith]="compareSelectedAccount"
            [(value)]="_selectedAccount"
            (selectionChange)="onAccountSelect($event)"
          >
            <mat-option value="all">Applies to all accounts</mat-option>
            <mat-option
              *ngFor="let account of customerFeedAccounts; trackBy: getKey"
              [value]="account"
            >
              <div class="flex flex-row items-center justify-start">
                <ease-account-icon
                  class="mr-1"
                  [size]="18"
                  [accountType]="account.$accountType"
                ></ease-account-icon>
                {{ account.name }}
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ease-markdown-editor
        [easeMentions]="true"
        formControlName="body"
        placeholder="Add a feed note"
        size="sm"
        [autoFocus]="true"
      ></ease-markdown-editor>
      <div class="mt-2 flex flex-col items-start md:flex-row md:items-center">
        <mat-checkbox
          color="primary"
          formControlName="sticky"
          [easeDisableControl]="_selectedAccount && _selectedAccount !== 'all'"
          class="py-2 md:py-0"
        >
          Make Sticky
        </mat-checkbox>
        <div class="hidden flex-1 md:block"></div>
        <div class="flex flex-row items-center self-end py-2 md:py-0">
          <button type="button" (click)="hideNoteForm()" mat-button>
            Cancel
          </button>
          <button
            type="submit"
            [disabled]="noteForm.invalid || isAddingNote"
            mat-button
            color="primary"
          >
            {{ isAddingNote ? 'Adding...' : 'Add Feed Note' }}
          </button>
        </div>
      </div>
    </form>
  </ease-sheet-content>
  <ease-sheet-panel
    [easeActionScroll]
    #actionScroll="actionScroll"
    class="flex-1 overflow-y-auto overflow-x-hidden border-gray-300 bg-gray-100"
  >
    <ease-sheet-content size="md" class="!p-0">
      <div class="container-md">
        <ng-container *ngIf="stickyNotes$ | async as stickyNotes">
          <div
            *ngIf="stickyNotes?.length && !isSearching"
            class="overflow-hidden border-b border-solid border-gray-300 bg-gray-50"
          >
            <div *ngIf="!hidingStickyNotes" class="px-4 py-0">
              <ease-feed-item-sticky-note
                *ngFor="let note of stickyNotes; trackBy: getKey"
                [dateFormat]="'MMM D, YYYY'"
                [note]="note"
                (remove)="removeStickyNote(note)"
              >
              </ease-feed-item-sticky-note>
            </div>
            <button
              (click)="toggleHidingStickyNotes()"
              class="!h-10 w-full !rounded-none !text-[13px] !text-gray-800"
              mat-button
            >
              {{ hidingStickyNotes ? 'Show' : 'Hide' }} Sticky Notes
              <mat-icon class="ml-2">
                {{ hidingStickyNotes ? 'expand_more' : 'expand_less' }}
              </mat-icon>
            </button>
          </div>
        </ng-container>

        <div
          *ngIf="activityFilterControl.value?.length"
          class="flex flex-row items-center justify-center bg-white py-1"
        >
          <span class="mr-2 text-sm italic text-gray-600">
            Activity Filter(s) are enabled
          </span>
          <button
            mat-button
            color="primary"
            class="text-base"
            (click)="resetFilters()"
          >
            Clear filters
          </button>
        </div>

        <div class="px-4 py-0">
          <!--
            Live feed for items added to Firebase after page load
            Only show when not searching or filtering by item type(s)
          -->
          <ng-container
            *ngIf="!isSearching && !activityFilterControl.value?.length"
          >
            <ease-feed-item
              *ngFor="
                let item of customerFeedService.liveFeed$ | async | slice: 0:10;
                trackBy: getKey
              "
              [item]="item"
              [users]="users"
              (save)="onItemSave($event)"
            >
            </ease-feed-item>
          </ng-container>
          <!-- Searchable feed of all items in Typesense -->
          <ease-feed-item
            *ngFor="
              let item of customerFeedService.feed$ | async;
              trackBy: getKey
            "
            [item]="item"
            [users]="users"
            (save)="onItemSave($event)"
          >
          </ease-feed-item>
          <div class="flex flex-row items-center justify-center">
            <ng-container
              *ngIf="
                customerFeedService.loading$ | async as feedLoading;
                else feedLoaded
              "
            >
              <button class="!my-3" mat-stroked-button>Loading...</button>
            </ng-container>
            <ng-template #feedLoaded>
              <button
                (click)="loadMoreItems()"
                [disabled]="!customerFeedService.hasMoreItems"
                class="!my-3"
                mat-stroked-button
              >
                {{
                  customerFeedService.hasMoreItems
                    ? 'Load more items'
                    : 'No more items'
                }}
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </ease-sheet-content>
  </ease-sheet-panel>
</ease-sheet>
