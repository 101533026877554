import { Injectable, OnDestroy } from '@angular/core';
import { ColDef, RowDataUpdatedEvent } from 'ag-grid-community';
import { camelCase } from 'lodash';

import { CustomerAccountsService } from 'src/app/customers/customer-accounts/customer-accounts.service';
import { CustomersService } from 'src/app/customers/customers.service';
import { firstValueFrom } from 'rxjs';
import { GridEntity } from '../grid-toolbar/grid-targeting/grid-targeting.interface';
import {
  ParsedUserInput,
  Separator,
  TargetMember,
  UserInputTarget
} from './bulk-targeting.interface';

@Injectable()
export class BulkTargetingService implements OnDestroy {
  private isDestroyed = false;

  constructor(
    private customerAccountsService: CustomerAccountsService,
    private customersService: CustomersService
  ) {}

  /**
   * Removes line break characters that can be potentially be added
   * on CSV/TSV exports.
   *
   * @param data
   * @returns the trimmed string
   */
  trimLineBreaks(data: string): string {
    return data?.replace(/[\r\n]/g, '');
  }

  /**
   * Splits pasted data into rows (per new line)
   *
   * @returns
   */
  parse(params: {
    data: string;
    entity: GridEntity;
    separator: Separator;
  }): ParsedUserInput {
    const { data = '', separator, entity } = params;

    /**
     * Parse the data and convert it from a string to a string[][].
     * Don't include any empty rows (where all cells are empty).
     */
    const rawRowData = data.split('\n').reduce((table, line) => {
      const row: string[] = line.split(separator);
      const isEmpty = !row.filter(cell => cell)?.length;
      return isEmpty ? table : [...table, row];
    }, []);

    /**
     * Prepare column headers before ingest
     */
    const headers = rawRowData[0].map(header => camelCase(header));
    delete rawRowData[0];

    /**
     * Build rowData for ag-grid ingest. From a string[][] -> {fieldName: value}[]
     */
    const rowData = [];

    rawRowData.forEach(cell => {
      const rowCells = headers.reduce((acc, curr, index) => {
        const cellTrimmed = this.trimLineBreaks(cell[index]);
        return { ...acc, [this.getFieldName(entity, curr)]: cellTrimmed };
      }, {});

      rowData.push({
        name: null,
        ...rowCells
      } as UserInputTarget);
    });

    return { headers, rowData };
  }

  getFieldName(entity: GridEntity, columnHeader: string): string {
    switch (entity) {
      case 'customer':
      case 'prospect':
        return columnHeader === 'customerId' ? 'id' : columnHeader;
      case 'account':
        switch (columnHeader) {
          case 'accountId':
            return 'id';
          case 'accountType':
            return 'type';
          default:
            return columnHeader;
        }
      default:
        return columnHeader;
    }
  }

  /**
   * Dynamically defines Ag-grid columns for a given entityType and array of headers
   *
   * @param entityType
   * @param headers
   * @returns the column definitions
   */
  getColumnDefinitions(entityType: GridEntity, headers: string[]): ColDef[] {
    return [
      {
        headerName:
          entityType === 'account' ? '{accountName}' : '{customerName}',
        field: 'name',
        // explicitly provide colId for rowNode.setDataValue()
        colId: 'name',
        flex: 1,
        minWidth: 360,
        valueFormatter: params =>
          params.data?.name
            ? params.data.name
            : `*Invalid ID ${entityType === 'account' ? '/ type' : '/ phase'}`
      },
      ...headers.map(columnHeader => ({
        headerName: `{${columnHeader}}`,
        field: this.getFieldName(entityType, columnHeader),
        hide: !columnHeader
      }))
    ];
  }

  getOverlayLoadingTemplate(entityType: GridEntity): string {
    return `<span class="ag-overlay-loading-center">Please wait while we're getting the ${entityType}s</span>`;
  }

  /**
   * Gets the entity for each row on an Ag-grid table based on the entityType set
   * - if customer/prospect, requires `id` column defined
   * - if account, requires both `id` and `type` columns defined
   *
   * @param entityType 'customer' | 'prospect' | 'account'
   * @param params
   * @returns a promise of a boolean -- if there were any invalid entities
   */
  async getEntities(
    entityType: GridEntity,
    params: RowDataUpdatedEvent
  ): Promise<boolean> {
    params.api.showLoadingOverlay();

    let hasInvalidEntity = false;

    const rowRecord: {
      [rowId: string]: { id: string; type?: string };
    } = {};

    /**
     * Hashmap to store entity metadata per rowId so it's easier to update
     * specific cells
     */
    params.api.forEachNode(row => {
      rowRecord[row.id] = {
        id: row.data.id,
        type: row.data.type
      };
    });

    for (const rowId of Object.keys(rowRecord)) {
      if (this.isDestroyed) {
        return;
      }

      const { id, type } = rowRecord[rowId];

      switch (entityType) {
        case 'prospect':
        case 'customer':
          if (id) {
            const customer = await firstValueFrom(
              this.customersService.get(id)
            );
            customer.phase === entityType && customer.name
              ? params.api.getRowNode(rowId).setDataValue('name', customer.name)
              : (hasInvalidEntity = true);
          }
          break;
        case 'account':
          if (id && type) {
            const account: any = await firstValueFrom(
              this.customerAccountsService.getAccount({
                accountId: id,
                accountType: type
              })
            );
            account.name
              ? params.api.getRowNode(rowId).setDataValue('name', account.name)
              : (hasInvalidEntity = true);
          }
      }
    }

    params.api.hideOverlay();
    return hasInvalidEntity;
  }

  /**
   * Filters and destructures the row to follow the TargetMember interface
   * - intentionally skip any invalid entities
   * - extra any custom data included and contain them in the "customData" property
   */
  getTargetDataFromGrid(rowData: UserInputTarget[]): TargetMember[] {
    return rowData
      .filter(row => row.name)
      .map(member => {
        const { id, name, type, ...customData } = member;

        return {
          id,
          name,
          type,
          customData
        };
      });
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }
}
