import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ease-sheet',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./sheet.component.scss']
})
export class SheetComponent {
  @HostBinding('class') hostClass =
    'relative flex flex-col text-gray-800 rounded-md bg-white';
}
