<div *ngIf="task" class="w-full flex-1">
  <ng-container *ngIf="sharedLayoutService.isCompact$ | async as isMobile">
    <div
      #header
      class="flex flex-col gap-2 bg-gray-50 p-3 text-sm text-gray-600 md:gap-6 md:p-6"
    >
      <div class="flex flex-col">
        <ng-container *ngIf="parentTask$ | async as parentTask">
          <div
            class="flex cursor-pointer flex-row items-center justify-start gap-1 text-sm"
            [easeTaskWindower]="parentTask.$key"
          >
            <mat-icon class="mat-icon-16">folder</mat-icon>
            <div class="font-bold">{{ parentTask.name }}</div>
          </div>
        </ng-container>

        <div class="flex flex-row items-start">
          <mat-icon class="mat-icon-20 mt-1 mb-3 mr-2" *ngIf="task.children"
            >folder</mat-icon
          >
          <mat-icon class="mat-icon-20 mt-0.5 mb-3 mr-2" *ngIf="task.parent"
            >subdirectory_arrow_right</mat-icon
          >

          <ease-toggle
            #editTaskToggle="toggle"
            class="mb-1 flex flex-1 flex-row items-center gap-3"
          >
            <ease-inplace-edit
              class="task-editor flex-1"
              [(ngModel)]="task.name"
              (ngModelChange)="saveTask(task)"
              (click)="editTaskToggle.show()"
              (inputBlur)="editTaskToggle.hide()"
            >
            </ease-inplace-edit>
            <ng-container
              [ngTemplateOutlet]="taskActions"
              [ngTemplateOutletContext]="{ $implicit: isMobile }"
            ></ng-container>
          </ease-toggle>
        </div>

        <ease-toggle
          #editEntityToggle="toggle"
          class="mb-2 flex flex-row items-center md:mb-0"
        >
          <ng-container *ngIf="editEntityToggle.isHidden; else editEntity">
            <ng-container
              [ngTemplateOutlet]="entity"
              [ngTemplateOutletContext]="{ $implicit: isMobile }"
            ></ng-container>
            <button
              [matMenuTriggerFor]="entityMenu"
              matTooltip="More Actions"
              class="!ml-2 !h-8 !w-8 !rounded-md"
              mat-icon-button
              mat-stroked-button
              [satPopoverAnchor]
              #entityPopoverAnchor="satPopoverAnchor"
            >
              <mat-icon class="mat-icon-18">more_vert</mat-icon>
            </button>

            <mat-menu #entityMenu="matMenu">
              <button mat-menu-item [matMenuTriggerFor]="copy">
                <mat-icon class="mat-icon-18">content_copy</mat-icon>
                <span>Copy</span>
              </button>
              <mat-divider class="!my-2 !mx-3"></mat-divider>
              <button
                *ngIf="hasEditableScheduleTemplate"
                (click)="scheduledDatesPopover.open()"
                mat-menu-item
              >
                <mat-icon class="mat-icon-18">date_range</mat-icon>
                <span>More Scheduled Dates</span>
              </button>
              <button (click)="tasksPopover.open()" mat-menu-item>
                <mat-icon class="mat-icon-18">playlist_add_check</mat-icon>
                <span>Related Tasks</span>
              </button>
              <button (click)="websitesPopover.open()" mat-menu-item>
                <mat-icon class="mat-icon-18">language</mat-icon>
                <span>Websites</span>
              </button>
              <button (click)="amdPopover.open()" mat-menu-item>
                <mat-icon class="mat-icon-18">dashboard</mat-icon>
                <span>Accounts Dashboard</span>
              </button>
              <ng-container *ngIf="!isCompleted">
                <mat-divider class="!my-2 !mx-3"></mat-divider>
                <button (click)="editEntityToggle.show()" mat-menu-item>
                  <mat-icon class="mat-icon-18">edit</mat-icon>
                  <span>Change Customer / Account</span>
                </button>
              </ng-container>
            </mat-menu>

            <mat-menu #copy="matMenu">
              <button
                [cdkCopyToClipboard]="customerName$ | async"
                (click)="showMessage()"
                mat-menu-item
              >
                <mat-icon class="mat-icon-18">content_copy</mat-icon>
                <span>Customer Name</span>
              </button>
              <button
                [cdkCopyToClipboard]="customerId$ | async"
                (click)="showMessage()"
                mat-menu-item
              >
                <mat-icon class="mat-icon-18">content_copy</mat-icon>
                <span>Customer ID</span>
              </button>
              <button
                *ngIf="task.entityType === 'account'"
                [cdkCopyToClipboard]="task.entityName"
                (click)="showMessage()"
                mat-menu-item
              >
                <mat-icon class="mat-icon-18">content_copy</mat-icon>
                <span>Account Name</span>
              </button>
              <button
                *ngIf="task.entityType === 'account'"
                [cdkCopyToClipboard]="task.entityId"
                (click)="showMessage()"
                mat-menu-item
              >
                <mat-icon class="mat-icon-18">content_copy</mat-icon>
                <span>Account ID</span>
              </button>
            </mat-menu>

            <sat-popover
              #scheduledDatesPopover
              [anchor]="entityPopoverAnchor"
              verticalAlign="below"
              horizontalAlign="start"
              [hasBackdrop]="true"
            >
              <ease-task-scheduled-dates
                *ngIf="scheduledDatesPopover.isOpen()"
                [taskId]="task.scheduledTaskTemplateId"
              >
              </ease-task-scheduled-dates>
            </sat-popover>

            <sat-popover
              #tasksPopover
              [anchor]="entityPopoverAnchor"
              verticalAlign="below"
              horizontalAlign="start"
              [hasBackdrop]="true"
            >
              <ease-customer-tasks-popover
                *ngIf="tasksPopover.isOpen()"
                [customerId]="customerId$ | async"
                [excludeTasks]="[task.$key]"
              >
              </ease-customer-tasks-popover>
            </sat-popover>

            <sat-popover
              #websitesPopover
              [anchor]="entityPopoverAnchor"
              verticalAlign="below"
              horizontalAlign="start"
              [hasBackdrop]="true"
            >
              <ease-customer-websites-popover
                *ngIf="websitesPopover.isOpen()"
                [customerId]="customerId$ | async"
              >
              </ease-customer-websites-popover>
            </sat-popover>

            <sat-popover
              #amdPopover
              [anchor]="entityPopoverAnchor"
              panelClass="amd-widget-popover"
              verticalAlign="below"
              horizontalAlign="start"
              [hasBackdrop]="true"
            >
              <ease-sheet *ngIf="amdPopover.isOpen()">
                <ease-popover-header>Account Dashboard</ease-popover-header>
                <ease-customer-dashboard-widget-accounts-dashboard-shared-table
                  class="w-auto"
                  [customerId$]="customerId$"
                ></ease-customer-dashboard-widget-accounts-dashboard-shared-table>
              </ease-sheet>
            </sat-popover>
          </ng-container>

          <ng-template #editEntity>
            <ease-finder
              [searchTypes]="['prospects', 'customers', 'accounts']"
              (selected)="onEntitySelected($event)"
              [ngModel]="{
                entityType: task.entityType,
                entityId: task.entityId,
                accountType: task.accountType
              }"
              class="entity-editor mr-2 flex-1"
            >
            </ease-finder>
            <button
              *ngIf="task.entityId"
              (click)="editEntityToggle.hide(); onEntityDelete()"
              matTooltip="Remove from Customer / Account"
              class="!mr-1 !h-8 !w-8 !rounded-md"
              mat-icon-button
              mat-stroked-button
            >
              <mat-icon class="mat-icon-18">delete</mat-icon>
            </button>
            <button
              (click)="editEntityToggle.hide()"
              matTooltip="Cancel"
              class="!h-8 !w-8 !rounded-md"
              mat-icon-button
              mat-stroked-button
            >
              <mat-icon class="mat-icon-18">block</mat-icon>
            </button>
          </ng-template>
        </ease-toggle>

        <div class="flex flex-row items-center gap-2">
          <mat-icon class="mat-icon-16">web</mat-icon>
          <ease-task-board-list-blob
            [boardId]="task.board"
            [listId]="task.list"
            [enableActions]="true"
          ></ease-task-board-list-blob>
        </div>

        <div class="flex h-8 flex-row items-center gap-2">
          <mat-icon class="mat-icon-16">access_time</mat-icon>
          <div>
            {{ task.createdAt | amDateFormat: 'MMM D YYYY, h:mm a' }}
            <span *ngIf="users && users[task.createdBy]" class="font-medium">
              by {{ users[task.createdBy].name }}
            </span>
          </div>
        </div>
      </div>

      <div *ngIf="!task.state" class="flex flex-col gap-2">
        <ease-task-summary [task]="task"></ease-task-summary>
        <ease-task-assigned-users [task]="task"></ease-task-assigned-users>
      </div>
    </div>

    <div
      *ngIf="showCompact$ | async"
      class="sticky top-0 z-50 flex flex-row items-center gap-3 overflow-hidden text-ellipsis whitespace-nowrap bg-gray-200 p-3 text-gray-700 sm:px-6"
      #compactHeader
    >
      <div class="flex flex-1 flex-col gap-1 overflow-hidden">
        <div
          (click)="scrollToTop()"
          class="cursor-pointer text-base font-medium text-gray-800"
        >
          {{ task.name }}
        </div>
        <ng-container
          [ngTemplateOutlet]="entity"
          [ngTemplateOutletContext]="{ $implicit: isMobile }"
        ></ng-container>
      </div>
      <ng-container
        [ngTemplateOutlet]="taskActions"
        [ngTemplateOutletContext]="{ $implicit: isMobile }"
      ></ng-container>
    </div>
  </ng-container>

  <ease-task-body
    [task]="task"
    [users]="users"
    [compactHeaderHeight]="compactHeader?.nativeElement.offsetHeight"
    [customerId]="customerId$ | async"
    [isSticky]="showCompact$ | async"
  ></ease-task-body>
</div>

<ng-template #entity let-isMobile>
  <div
    [ngSwitch]="!!task.entityId"
    class="flex flex-row items-center gap-2 overflow-hidden text-sm"
    [ngClass]="{ 'text-xs': isMobile.active }"
  >
    <ng-container *ngSwitchCase="true">
      <div
        (click)="goToEntity(task.entityType, task.entityId, task.accountType)"
        matTooltip="View Customer"
        class="flex cursor-pointer flex-row items-center gap-2 overflow-hidden"
      >
        <ease-entity-icon
          [entity]="{
            entityType: task.entityType,
            accountType: task.accountType
          }"
          [size]="18"
        >
        </ease-entity-icon>
        <span class="truncate font-medium">
          {{ task.entityName }}
        </span>
      </div>
      <span
        [cdkCopyToClipboard]="task.entityId"
        (click)="showMessage()"
        matTooltip="Copy ID to clipboard"
        class="cursor-pointer"
        [ngClass]="{ hidden: isMobile.active }"
      >
        ({{ task.entityId }})
      </span>
    </ng-container>
    <span *ngSwitchDefault> No Account Associated </span>
  </div>
</ng-template>

<ng-template #taskActions let-isMobile>
  <div [ngSwitch]="!!task.completedAt" class="flex flex-row">
    <button
      *ngSwitchCase="true"
      (click)="taskService.uncomplete(task)"
      matTooltip="Re-Open Task"
      class="!mr-1 !h-8 !w-8 !rounded-md"
      mat-icon-button
      mat-stroked-button
    >
      <mat-icon class="mat-icon-18">history</mat-icon>
    </button>
    <ng-container *ngSwitchDefault>
      <button
        [matMenuTriggerFor]="taskMenu"
        matTooltip="More Actions"
        class="!h-8 !w-8 !rounded-md md:!mr-1"
        mat-icon-button
        mat-stroked-button
        [satPopoverAnchor]
        #taskPopoverAnchor="satPopoverAnchor"
      >
        <mat-icon class="mat-icon-18">more_vert</mat-icon>
      </button>
      <button
        (click)="completeTaskPopover.open()"
        [matTooltip]="
          !task.parent && task.children
            ? 'Complete Project'
            : !task.children && task.parent
            ? 'Complete Subtask'
            : 'Complete Task'
        "
        class="!h-8 !w-8 !rounded-md"
        [ngClass]="{ '!hidden': isMobile.active }"
        mat-icon-button
        mat-stroked-button
        [satPopoverAnchor]
        #completeTaskPopoverAnchor="satPopoverAnchor"
      >
        <mat-icon class="mat-icon-18">check</mat-icon>
      </button>

      <mat-menu #taskMenu="matMenu" xPosition="before">
        <button
          *ngIf="task.entityId"
          (click)="createEmailWindow()"
          mat-menu-item
        >
          <mat-icon class="mat-icon-18">email</mat-icon>
          <span>Email Customer</span>
        </button>
        <button (click)="checklistTemplatePopover.open()" mat-menu-item>
          <mat-icon class="mat-icon-18">list_alt</mat-icon>
          <span>Add Checklist</span>
        </button>
        <button
          *ngIf="!task.parent && (task?.children || {} | keyvalue).length === 0"
          (click)="subtaskSelector.open()"
          mat-menu-item
        >
          <mat-icon class="mat-icon-18">link</mat-icon>
          <span>Attach to project</span>
        </button>

        <button *ngIf="task.parent" (click)="detachSubtask(task)" mat-menu-item>
          <mat-icon class="mat-icon-18">link_off</mat-icon>
          <span>Detach from project</span>
        </button>
        <button
          *ngIf="hasEditableScheduleTemplate"
          (click)="onScheduledTaskEdit(task?.scheduledTaskTemplateId)"
          mat-menu-item
        >
          <mat-icon class="mat-icon-18">alarm</mat-icon>
          <span>Edit Scheduled Task</span>
        </button>
        <mat-divider class="!my-4 !mx-3"></mat-divider>
        <button (click)="moveTaskPopover.open()" mat-menu-item>
          <mat-icon class="mat-icon-18">compare_arrows</mat-icon>
          <span>Move Task</span>
        </button>
        <button
          *ngIf="permissionsService.hasPermission('destroyTasks')"
          (click)="destroy()"
          mat-menu-item
        >
          <mat-icon class="mat-icon-18">delete</mat-icon>
          <span>Destroy Task</span>
        </button>
        <ng-container *ngIf="isMobile.active">
          <mat-divider class="!my-4 !mx-3"></mat-divider>
          <button (click)="completeTaskPopover.open()" mat-menu-item>
            <mat-icon class="mat-icon-18">check</mat-icon>
            <span
              >Complete
              {{
                !task.parent && task.children
                  ? 'Project'
                  : !task.children && task.parent
                  ? 'Subtask'
                  : 'Task'
              }}</span
            >
          </button>
        </ng-container>
      </mat-menu>

      <sat-popover
        #checklistTemplatePopover
        [anchor]="taskPopoverAnchor"
        verticalAlign="below"
        horizontalAlign="end"
        [hasBackdrop]="true"
      >
        <ease-checklist-templates-popover
          *ngIf="checklistTemplatePopover.isOpen()"
          [task]="task"
          (selected)="checklistTemplatePopover.close()"
        ></ease-checklist-templates-popover>
      </sat-popover>

      <sat-popover
        #moveTaskPopover
        [anchor]="taskPopoverAnchor"
        verticalAlign="below"
        horizontalAlign="end"
        [hasBackdrop]="true"
      >
        <ease-task-move-popover
          [task]="task"
          *ngIf="moveTaskPopover.isOpen()"
        ></ease-task-move-popover>
      </sat-popover>

      <sat-popover
        #completeTaskPopover
        [anchor]="completeTaskPopoverAnchor"
        verticalAlign="below"
        horizontalAlign="end"
        [hasBackdrop]="true"
      >
        <ease-complete-task-popover
          *ngIf="completeTaskPopover.isOpen()"
          [taskId]="task.$key"
        ></ease-complete-task-popover>
      </sat-popover>

      <sat-popover
        #subtaskSelector
        [anchor]="taskPopoverAnchor"
        verticalAlign="below"
        horizontalAlign="end"
        [hasBackdrop]="true"
      >
        <ease-subtask-selector
          *ngIf="subtaskSelector.isOpen()"
          [task]="task"
        ></ease-subtask-selector>
      </sat-popover>
    </ng-container>
  </div>
</ng-template>
