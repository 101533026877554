import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';

import { TASK_NOTES_PATH } from '../../shared/firebase-paths';
import { UserService } from '../../users/user.service';
import { firebaseJSON } from '../../shared/utils/functions';

@Injectable({
  providedIn: 'root'
})
export class TaskNotesReactionService {
  constructor(
    private angularFireService: AngularFireDatabase,
    private userService: UserService
  ) {}

  addReactionToNote(
    taskId: string,
    noteId: string,
    emoji: EmojiData
  ): Promise<[void, void]> {
    return Promise.all([
      this.angularFireService
        .object(
          `${TASK_NOTES_PATH}/${taskId}/${noteId}/reactions/${emoji.unified}`
        )
        .update(
          firebaseJSON({
            id: emoji.id,
            shortName: emoji.shortName,
            colons: emoji.colons
          })
        ),
      this.angularFireService
        .object(
          `${TASK_NOTES_PATH}/${taskId}/${noteId}/reactions/${emoji.unified}/users/${this.userService.currentUser.$key}`
        )
        .set({ shortName: this.userService.currentUser.name.split(' ')[0] })
    ]);
  }

  removeUserFromReaction(
    taskId: string,
    noteId: string,
    emoji: EmojiData
  ): Promise<void> {
    return this.angularFireService
      .object(
        `${TASK_NOTES_PATH}/${taskId}/${noteId}/reactions/${emoji.unified}/users/${this.userService.currentUser.$key}`
      )
      .remove();
  }

  removeReaction(
    taskId: string,
    noteId: string,
    emoji: EmojiData
  ): Promise<void> {
    return this.angularFireService
      .object(
        `${TASK_NOTES_PATH}/${taskId}/${noteId}/reactions/${emoji.unified}`
      )
      .remove();
  }
}
