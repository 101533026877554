import { NgModule } from '@angular/core';

import { SharedFormModule } from '../../shared-form.module';
import { SharedModule } from '../../shared.module';
import { PopoverModule } from '../popover.module';
import { TaskPriorityPopoverComponent } from './task-priority-popover.component';

@NgModule({
  imports: [SharedModule, SharedFormModule, PopoverModule],
  declarations: [TaskPriorityPopoverComponent],
  exports: [TaskPriorityPopoverComponent]
})
export class TaskPriorityPopoverModule {}
