import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import get from 'lodash-es/get';

import { CustomerMessagesService } from '../customer-messages.service';
import { EmailMessageDetails } from '../../../shared/emails/email.interface';

@Component({
  selector: 'ease-customer-message-details-dialog',
  templateUrl: './customer-message-details-dialog.component.html',
  styleUrls: ['./customer-message-details-dialog.component.scss']
})
export class CustomerMessageDetailsDialogComponent implements AfterViewInit {
  @Input()
  messageId: string;
  @ViewChild('previewTemplateEl', { static: true })
  previewTemplateEl: ElementRef;

  public isWorking: boolean = false;
  public isLoading: boolean = true;
  public message: EmailMessageDetails;
  public domPreview: Document;
  public domParser = new DOMParser();

  constructor(private customerMessagesService: CustomerMessagesService) {}

  ngAfterViewInit() {
    const iframe = this.previewTemplateEl.nativeElement as HTMLIFrameElement;
    this.customerMessagesService
      .getDetails(this.messageId)
      .then(message => {
        setTimeout(() => (this.isLoading = false));

        this.message = message;
        const body = get(message, 'Message.Body.Html');

        if (body) {
          this.domPreview = this.domParser.parseFromString(body, 'text/html');

          const scripts = this.domPreview.querySelectorAll('script');
          const trackingImg =
            this.domPreview.querySelector('img[src*="o.gif"]');

          if (scripts.length) {
            Array.from(scripts).forEach(scriptEl =>
              scriptEl.parentElement.removeChild(scriptEl)
            );
          }

          if (trackingImg) {
            trackingImg.parentElement.removeChild(trackingImg);
          }

          iframe.src = 'about:blank';
          iframe.onload = () => {
            iframe.contentDocument.write(
              this.domPreview.querySelector('html').innerHTML
            );
          };
        }
      })
      .catch(() => setTimeout(() => (this.isLoading = false)));
  }

  async resend() {
    this.isWorking = true;
    await this.customerMessagesService.resend(this.messageId);
    this.isWorking = false;
  }
}
