import { Injectable } from '@angular/core';
import { shareReplay, Subject } from 'rxjs';

import { Indexer, IndexerRelation } from './indexer.interface';

@Injectable({
  providedIn: 'root'
})
export class IndexerService {
  private activeIndexerSource: Subject<Indexer> = new Subject<Indexer>();
  public activeIndexer$ = this.activeIndexerSource
    .asObservable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private relationSource: Subject<IndexerRelation> =
    new Subject<IndexerRelation>();
  public relation$ = this.relationSource
    .asObservable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  constructor() {}

  setRelation(relation: IndexerRelation): void {
    this.relationSource.next(relation);
  }

  setActiveIndexer(indexer: Indexer): void {
    this.activeIndexerSource.next(indexer);
  }
}
