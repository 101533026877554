import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { WindowService } from 'src/app/shared/window/window.service';
import { CreateCustomerCommunicationComponent } from 'src/app/customers/customer-communication/create-customer-communication/create-customer-communication.component';
import { SNACKBAR_DURATION_SUCCESS } from 'src/app/shared/constants';
import { Account, CommunicationDates } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';
import { CustomerLsaSummaryDialogComponent } from '../../../../customers/customer-accounts/customer-lsa-summary/customer-lsa-summary-dialog/customer-lsa-summary-dialog.component';
import { CustomerMessagesService } from '../../../../customers/customer-messages/customer-messages.service';

@UntilDestroy()
@Component({
  selector: 'ease-more-actions-cell',
  templateUrl: './more-actions-cell.component.html',
  styleUrls: ['./more-actions-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreActionsCellComponent implements AgRendererComponent {
  public account: Account;
  public isGroup: boolean;
  public params: ICellRendererParams;
  public isSnoozed: boolean;

  constructor(
    private accountDashboardService: AccountDashboardService,
    private customerMessagesService: CustomerMessagesService,
    private matDialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private windowService: WindowService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.account = params.data;
    this.isGroup = params.node.group;
    this.isSnoozed = this.isGroup
      ? params.node.allLeafChildren.every(childRow => !!childRow.data.snoozed)
      : params.node && params.node.data && params.node.data.snoozed;
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  get customerId(): string {
    return (
      this.account?.customerId ||
      this.params.node.allLeafChildren[0].data.customerId
    );
  }

  get hasLsa(): boolean {
    return (
      this.account?.hasLsa || this.params.node.allLeafChildren[0]?.data?.hasLsa
    );
  }

  openBudgetManager(): void {
    this.accountDashboardService.openBudgetManagerDialog(
      this.account.accountId,
      this.account.accountType,
      this.account.accountName
    );
  }

  createMessage(): void {
    this.customerMessagesService.create(this.customerId);
  }

  createTask(type: 'customer' | 'account'): void {
    switch (type) {
      case 'customer':
        this.windowService.create({
          type: 'createTask',
          data: {
            entityId: this.params.node.allLeafChildren[0].data.customerId,
            entityName: this.params.node.allLeafChildren[0].data.customerName,
            entityType: 'customer'
          }
        });
        break;
      case 'account':
        this.windowService.create({
          type: 'createTask',
          data: {
            accountType: this.account.accountType,
            entityId: this.account.accountId,
            entityName: this.account.accountName,
            entityType: 'account'
          }
        });
    }
  }

  goToCustomer(): void {
    window.open(`/customers/${this.customerId}`, '_blank');
  }

  logCommunication(): void {
    const dialogRef = this.matDialog.open(CreateCustomerCommunicationComponent);
    dialogRef.componentInstance.customerId = this.customerId;
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((newCommunicationDates: CommunicationDates) => {
        if (newCommunicationDates && this.params?.data) {
          /**
           * If success, update communication dates locally so we don't have to reload the whole table.
           * - refresh parent row if row is in a group
           * - else, refresh the account's row
           */
          this.params.data.lastCommunicationAt =
            newCommunicationDates.lastCommunicationAt;
          this.params.data.nextCommunicationAt =
            newCommunicationDates.nextCommunicationAt;
          this.params.api.refreshCells({
            rowNodes: [
              this.params.node.parent
                ? this.params.node.parent
                : this.params.node
            ],
            force: true
          });

          this.matSnackbar.open(`Logged customer communication`, 'close', {
            duration: SNACKBAR_DURATION_SUCCESS
          });
        }
      });
  }

  openAccountFeed(): void {
    const accountFeedDialog = this.accountDashboardService.openAccountFeed();

    if (this.account) {
      accountFeedDialog.componentInstance.viewingEntity = {
        customerId: this.customerId,
        account: {
          accountType: this.account.accountType,
          accountId: this.account.accountId
        }
      };
    } else {
      accountFeedDialog.componentInstance.viewingEntity = {
        customerId: this.customerId
      };
    }
  }

  openLsaSummary(): void {
    const instance = this.matDialog.open(CustomerLsaSummaryDialogComponent, {
      width: '70vw'
    });
    instance.componentInstance.customerId = this.customerId;
  }

  showRelatedAccounts(): void {
    this.accountDashboardService.filtersForm.controls.search.patchValue(
      this.account?.customerId ||
        this.params.node.allLeafChildren[0].data.customerId
    );

    if (this.params?.api) {
      this.params.columnApi.applyColumnState({});
    }
  }
}
