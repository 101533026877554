<div
  class="flex flex-row items-center text-[13px] font-medium"
  *ngIf="board$ | async as board"
>
  <span
    [matTooltip]="enableActions ? 'View Board' : null"
    class="cursor-pointer"
    (click)="enableActions && goToBoard(board)"
  >
    {{ board.name }}
  </span>
  <ng-container *ngIf="list$ | async as list">
    <mat-icon class="mat-icon-16">chevron_right</mat-icon>
    <span
      [matTooltip]="enableActions ? 'View List' : null"
      class="cursor-pointer"
      (click)="enableActions && goToBoard(board, list)"
    >
      {{ list.name }}
    </span>
  </ng-container>
</div>
