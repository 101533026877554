import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HotkeysHelpComponent } from '@ngneat/hotkeys';

import { AdminPermissionsService } from '../../../admin/admin-roles/admin-permissions.service';
import { UserService } from '../../../users/user.service';
import { WindowService } from '../../window/window.service';
import { SharedLayoutService } from '../../shared-layout.service';
import { environment } from '../../../../environments/environment';
import { AdwordsManagerLinksDialogComponent } from '../../../accounts/adwords-manager-links-dialog/adwords-manager-links-dialog.component';

@Component({
  selector: 'ease-app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppNavComponent implements OnInit {
  @HostBinding('class') class =
    'max-w-[240px] justify-center bg-blue-gray-800 flex flex-1 flex-col items-stretch h-full overflow-hidden';
  @HostBinding('class.collapsed')
  get isCollapsed() {
    return this.isMobile ? false : this._isCollapsed;
  }
  @Input() isMobile: boolean;
  @Output() navigated: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _isCollapsed = !!JSON.parse(localStorage.getItem(`appNavCollapsed`));
  public homeTooltip = `(${environment.VERSION['version']}-${environment.VERSION['hash']})`;
  public routerLinkActiveOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    fragment: 'ignored',
    paths: 'exact'
  };

  constructor(
    private dialog: MatDialog,
    private windowService: WindowService,
    public permissionService: AdminPermissionsService,
    public userService: UserService,
    public sharedLayoutService: SharedLayoutService
  ) {}

  ngOnInit(): void {}

  createTask() {
    this.navigated.emit();
    this.windowService.create({ type: 'createTask' });
  }

  onNavigated(event: Event) {
    this.navigated.emit();

    /**
     * To avoid race condition with window-pane "updateRoute", only minimize
     * Ease windows if user click the active route(page) again
     *
     * Note, timeout is added for a case like same active path with
     * queryParams when routing(create customer popover)
     */
    if (event && this.isActiveRoute(event.currentTarget as HTMLElement)) {
      setTimeout(() => this.windowService.minimizeAllWindows());
    }
  }

  isActiveRoute(target: HTMLElement): boolean {
    return target.classList.contains('active');
  }

  openKeyboardDialog() {
    const ref = this.dialog.open(HotkeysHelpComponent, {
      width: '700px',
      maxHeight: '80vh',
      panelClass: 'help-panel'
    });
    ref.componentInstance.dimiss.subscribe(() => ref.close());
  }

  openAdwordsManagerLinksDialog() {
    this.dialog.open(AdwordsManagerLinksDialogComponent, {
      width: '80vw',
      maxWidth: '1100px'
    });
  }

  toggleCollapse() {
    this._isCollapsed = !this._isCollapsed;
    localStorage.setItem('appNavCollapsed', `${this._isCollapsed}`);
  }
}
