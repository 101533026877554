import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges
} from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';
import { KeyValue } from '@angular/common';

import { CustomerMessageGroup } from '../../../customers/customer-messages/customer-messages.interface';
import { CustomerMessagesService } from '../../../customers/customer-messages/customer-messages.service';

@Component({
  selector: 'ease-task-customer-message-groups',
  templateUrl: './task-customer-message-groups.component.html',
  styleUrls: ['./task-customer-message-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCustomerMessageGroupsComponent implements OnChanges {
  @HostBinding('class') class = 'flex flex-col h-full';
  @Input()
  customerId: string;
  @Input()
  messageGroupIds: Array<KeyValue<any, any>> = [];

  public customerMessageGroups$: Observable<CustomerMessageGroup[]>;
  public recipientsPluralMapping = {
    '=0': 'no recipients',
    '=1': '1 recipient',
    other: '# recipients'
  };
  constructor(private customerMessagesService: CustomerMessagesService) {}

  ngOnChanges() {
    if (this.customerId && this.messageGroupIds) {
      this.customerMessageGroups$ = combineLatest(
        this.messageGroupIds.map(id =>
          this.customerMessagesService.getMessageGroup(this.customerId, id.key)
        )
      ).pipe(
        map(messageGroups => orderBy(messageGroups, ['createdAt'], ['desc']))
      );
    } else {
      this.customerMessageGroups$ = of([]);
    }
  }
}
