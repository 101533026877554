<form [formGroup]="editForm" (ngSubmit)="onSave()">
  <mat-form-field [ngClass]="{'!hidden': !isEditing}">
    <input
      type="text"
      (blur)="onInputBlur()"
      formControlName="editInput"
      #myInput
      matInput
    />
  </mat-form-field>
  <span *ngIf="!isEditing"> {{value}} </span>
</form>
