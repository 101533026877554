<ease-sheet class="popover popover-metric shadow-md">
  <div [ngClass]="callQualityMetric?.ragStatusColor">
    <div class="header metric-background">
      <span class="description" *ngIf="callQualityMetric?.ragStatusDescription">
        {{ callQualityMetric?.ragStatusDescription }}
      </span>

      <span
        class="description"
        *ngIf="!callQualityMetric?.ragStatusDescription"
      >
        Not enough data to calculate call quality
      </span>
    </div>
  </div>
  <div class="details">
    <div class="row flex flex-row">
      <div class="column detail flex-1">
        <ease-metric-simple
          label="Call Quality"
          [value]="callQualityMetric.callQuality + '%'"
          [differenceNumeric]="
            highQualityCalls +
            ' of ' +
            callQualityMetric.totalUniqueCalls +
            ' unique calls were good'
          "
        ></ease-metric-simple>
      </div>
    </div>
    <div
      class="row"
      *ngIf="callQualityMetric?.metadata?.chartData.labels.length"
    >
      <ease-chart
        [height]="200"
        [data]="callQualityMetric?.metadata?.chartData"
        [type]="'line'"
        [options]="accountDashboardService.percentageChartOptions"
      >
      </ease-chart>
    </div>
  </div>
</ease-sheet>
