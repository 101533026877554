import { NgModule } from '@angular/core';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { SharedPipesModule } from '../shared-pipes.module';
import { ListSelectorComponent } from './list-selector.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    SharedModule,
    SharedPipesModule,
    VirtualScrollerModule
  ],
  declarations: [ListSelectorComponent],
  exports: [ListSelectorComponent]
})
export class ListSelectorModule {}
