import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TextFieldModule } from '@angular/cdk/text-field';
import { DatepickerModule } from './datepicker/datepicker.module';
import { FormErrorsModule } from './form-errors/form-errors.module';
import { InplaceEditModule } from './inplace-edit/inplace-edit.module';
import { SkSelectModule } from './select/sk-select.module';
import { DisableControlModule } from './disable-control/disable-control.module';

@NgModule({
  exports: [
    DatepickerModule,
    DisableControlModule,
    FormErrorsModule,
    FormsModule,
    InplaceEditModule,
    ReactiveFormsModule,
    SkSelectModule,
    TextFieldModule
  ]
})
export class SharedFormModule {}
