<ng-container *ngIf="showSummary">
  <ease-chart
    *ngIf="summary?.chartData?.labels?.length"
    [data]="summary?.chartData"
    [type]="'line'"
    [options]="chartOptions"
  >
    <ease-chart-header>Cost</ease-chart-header>
  </ease-chart>
</ng-container>

<form [formGroup]="settingsForm" (ngSubmit)="onSubmit()">
  <div class="p-5">
    <div class="form-group-sm">
      <mat-form-field>
        <input
          formControlName="budget"
          [placeholder]="
            settingsForm.controls.customDateRange.value
              ? 'Custom Date Range Budget'
              : 'Monthly Budget'
          "
          type="text"
          matInput
        />
        <mat-error
          easeFormErrors
          [errorMessages]="{
            min:
              'Value must be at least ' +
              settingsForm.controls.budget?.errors?.min?.min,
            pattern: 'Budgets may only contain whole numbers'
          }"
          [control]="settingsForm.controls.budget"
        ></mat-error>
      </mat-form-field>
    </div>
    <ng-container *ngIf="allowCustomDateRange">
      <div class="form-group-sm">
        <mat-checkbox formControlName="customDateRange" color="primary"
          >Enable custom date range</mat-checkbox
        >
      </div>
      <div
        *ngIf="settingsForm.controls.customDateRange.value"
        class="flex flex-row gap-4"
      >
        <ease-datepicker
          [max]="settingsForm.controls.endAt.value"
          formControlName="startAt"
          class="flex-1"
          placeholder="Budget start date"
        ></ease-datepicker>
        <ease-datepicker
          [min]="settingsForm.controls.startAt.value"
          formControlName="endAt"
          class="flex-1"
          placeholder="Budget end date"
        ></ease-datepicker>
      </div>
    </ng-container>
    <div class="form-group-sm">
      <mat-checkbox formControlName="enabled" color="primary"
        >Enable budget utilization
        <mat-icon
          class="mat-icon-14"
          matTooltip="Turns on budget utilization metric in the dashboard"
          >help</mat-icon
        ></mat-checkbox
      >
    </div>
  </div>
  <ease-sheet-footer>
    <button (click)="onCancel()" type="button" mat-button>Cancel</button>
    <button
      type="submit"
      color="primary"
      [disabled]="settingsForm.invalid"
      mat-button
    >
      Save
    </button>
  </ease-sheet-footer>
</form>
