<ng-container *ngIf="entityList$ | async as entityList; else loading">
  <ng-container *ngIf="entityList.length; else noEntity">
    <mat-tab-group>
      <mat-tab label="Single user">
        <div class="p-4">
          <div class="mb-2">
            Select one user to be the new
            <strong>{{ role.label }}</strong
            >.
          </div>

          <mat-form-field appearance="outline">
            <mat-label>New {{ role.label }}</mat-label>
            <mat-select [formControl]="replaceUserControl">
              <mat-option
                *ngFor="let user of filteredUsers$ | async"
                [value]="user"
              >
                {{ user.name }}
              </mat-option>
            </mat-select>
            <mat-error
              easeFormErrors
              [control]="replaceUserControl"
            ></mat-error>
          </mat-form-field>

          <div class="mb-2">
            <span
              class="cursor-pointer text-sm text-blue-500 underline"
              (click)="showList = !showList"
            >
              {{ showList ? 'Hide' : 'Show' }}
              {{ entityListControl['controls'].length }}
              {{ entity.entityType
              }}{{ entityListControl['controls'].length > 1 ? 's' : '' }}
            </span>
          </div>
          <div
            class="mb-2 rounded-md border border-gray-300 py-2"
            *ngIf="showList"
          >
            <virtual-scroller
              #scroll
              [items]="entityListControl['controls']"
              [enableUnequalChildrenSizes]="true"
              class="h-40"
            >
              <div
                class="flex flex-row items-center justify-center space-x-2 p-2"
                *ngFor="let entityGroup of scroll.viewPortItems"
              >
                <div class="icon">
                  <ease-entity-icon
                    class="mr-2"
                    [entity]="{
                      entityType: entity.entityType,
                      accountType: entityGroup?.controls?.accountType?.value
                    }"
                    [size]="24"
                  >
                  </ease-entity-icon>
                </div>
                <div class="name flex-1 font-semibold text-gray-800">
                  {{ entityGroup?.controls?.entityName.value }}
                </div>
                <div class="skid text-sm text-gray-500">
                  {{ entityGroup?.controls?.entityId.value }}
                </div>
              </div>
            </virtual-scroller>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Select individually">
        <div class="customer-lists pt-3">
          <virtual-scroller
            #scroll
            [items]="entityListControl['controls']"
            [enableUnequalChildrenSizes]="true"
            class="h-96"
          >
            <div
              class="flex flex-row items-center justify-center space-x-2"
              *ngFor="let entityGroup of scroll.viewPortItems; let i = index"
            >
              <div class="icon">
                <ease-entity-icon
                  class="mr-2"
                  [entity]="{
                    entityType: entity.entityType,
                    accountType: entityGroup?.controls?.accountType?.value
                  }"
                  [size]="24"
                >
                </ease-entity-icon>
              </div>
              <div class="customer-information flex-1">
                <div class="name font-semibold text-gray-800">
                  {{ entityGroup?.controls?.entityName?.value }}
                </div>
                <div class="skid text-sm text-gray-500">
                  {{ entityGroup?.controls?.entityId?.value }}
                </div>
              </div>
              <div class="user-assign">
                <mat-form-field appearance="outline">
                  <mat-label>New {{ role.label }}</mat-label>
                  <mat-select
                    [formControl]="entityGroup?.controls?.destinationUserId"
                  >
                    <mat-option
                      *ngFor="let user of filteredUsers$ | async"
                      [value]="user.$key"
                    >
                      {{ user.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    easeFormErrors
                    [control]="entityGroup?.controls?.destinationUserId"
                  ></mat-error>
                </mat-form-field>
              </div>
            </div>
          </virtual-scroller>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="p-3">
    <mat-spinner [mode]="'indeterminate'" diameter="24"> </mat-spinner>
  </div>
</ng-template>

<ng-template #noEntity>
  <div class="p-4 italic text-gray-700">
    {{ user.name }} isn't a {{ role.label }} for any {{ entity.entityType }}s
  </div>
</ng-template>
