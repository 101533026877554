import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  take
} from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';

import { FeedItem } from 'src/app/shared/feed-items/feed-item.interface';
import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { FeedItemChange } from '../../shared/feed-items/feed-item/feed-item.component';
import { FeedItemService } from '../../shared/feed-items/feed-item.service';
import { MarkdownFormComponent } from '../../shared/markdown/markdown-form/markdown-form.component';
import { TaskService } from '../task.service';
import { UserService } from '../../users/user.service';
import { WindowPane } from '../../shared/window/window-pane/window-pane';
import { TaskNote } from './task-notes.interface';

@UntilDestroy()
@Component({
  selector: 'ease-task-notes',
  templateUrl: './task-notes.component.html',
  styleUrls: ['./task-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskNotesComponent implements OnInit, AfterViewInit {
  @HostBinding('class') hostClass = 'flex flex-col flex-1';
  @Input()
  completed: boolean = false;
  @Input()
  set taskId(val: string) {
    this._taskId = val;
    this.taskId$.next(val);
    if (this.markdownForm) {
      this.markdownForm.clear();
    }

    this.hidingPinStorageName = `hidingPinnedNotes-${this.taskId}`;
    this.angularFire
      .getServerTimestamp()
      .then(timestamp => (this.nowTime = timestamp));
  }

  get taskId(): string {
    return this._taskId;
  }

  @ViewChild('markdownForm')
  private markdownForm: MarkdownFormComponent;
  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;

  public newNoteBody: string = '';
  public notes$: Observable<TaskNote[]>;
  public pinnedNotes$: Observable<TaskNote[]>;
  public users: any = {};
  public isWorking: boolean;
  public hidingPinnedStatus: boolean = false;
  public hidingPinStorageName: string;
  public nowTime: number;
  private _taskId: string;
  private taskId$: ReplaySubject<string> = new ReplaySubject<string>();

  constructor(
    private angularFire: FirebaseDbService,
    private feedItemService: FeedItemService,
    private taskService: TaskService,
    private windowPane: WindowPane<string>,
    public userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    if (localStorage.getItem(this.hidingPinStorageName)) {
      this.hidingPinnedStatus = !!JSON.parse(
        localStorage.getItem(this.hidingPinStorageName)
      );
    }

    this.users = this.userService.usersAsObject.pipe(take(1));

    this.pinnedNotes$ = this.taskId$.pipe(
      switchMap(taskId =>
        taskId ? this.taskService.getPinnedNotes(taskId) : of([])
      ),
      map((pinnedNotes: TaskNote[]) =>
        pinnedNotes.length
          ? this.applyAdditionalProps(pinnedNotes)
          : pinnedNotes
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.notes$ = combineLatest([
      this.taskId$.pipe(
        startWith(null),
        switchMap(taskId =>
          taskId ? this.taskService.getNotes(taskId) : of([])
        )
      ),
      this.windowPane.onActivatedRouteChanged.pipe(
        startWith(null),
        /**
         * To avoid over-mapping notes below map() statement,
         * only using the valid activatedRoute
         */
        filter(activatedRoute => !!activatedRoute)
      )
    ]).pipe(
      map(([notes, activatedRoute]: [TaskNote[], ActivatedRoute]) =>
        notes.length ? this.applyAdditionalProps(notes, activatedRoute) : notes
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  ngAfterViewInit(): void {
    this.pinnedNotes$
      .pipe(startWith(null), pairwise(), untilDestroyed(this))
      .subscribe(([prevPinnedNotes, currentPinnedNotes]) => {
        if (currentPinnedNotes?.length > prevPinnedNotes?.length) {
          this.hidingPinnedStatus = false;
        }

        if (!currentPinnedNotes?.length) {
          this.hidingPinnedStatus = false;
          localStorage.removeItem(this.hidingPinStorageName);
        }
      });
  }

  private applyAdditionalProps(
    notes: TaskNote[],
    activatedRoute?: ActivatedRoute
  ): TaskNote[] {
    const queryParamsNoteId = activatedRoute?.snapshot.paramMap.get('noteId');

    return notes.map(note => {
      note.$isEditable =
        !this.completed &&
        this.feedItemService.editableByType(note as FeedItem, 'note');

      /**
       * Route testing with noteId examples, it should highlight and scroll to the note:
       * http://easeUrlxxxx/tasks/taskIdxxx?noteId=123
       *
       * - From noteId's task NOT in any of window panes
       * - From noteId's task in OPEN(shrink) state in window pane
       * - From noteId's task in MINIMIZED state in window pane
       *
       * To getting properly window outlet data, the redirection and route
       * updating from: @see {@link TaskWindowRedirectGuard.canActivate()}
       */
      if (queryParamsNoteId && note.$key === queryParamsNoteId) {
        note.$isHighlighted = true;
        this.scrollToNote(note, () => {
          /**
           * When we've finished scrolling to the note,
           * clear the noteId from the params list to avoid
           * continuously scrolling to it every time a window
           * is re-opened
           */
          const { noteId, ...paramsWithoutNote } =
            activatedRoute?.snapshot.params || {};

          this.router.navigate(['.', paramsWithoutNote], {
            relativeTo: activatedRoute
          });
        });
      }

      return note;
    });
  }

  addNote(body: string): void {
    this.isWorking = true;
    this.taskService.addNote(this.taskId, { body }).then(() => {
      this.isWorking = false;
      this.newNoteBody = '';
      this.markdownForm && this.markdownForm.clear();
    });

    this.virtualScroller && this.virtualScroller.scrollToPosition(0, 0);
  }

  updateNote(feedChange: FeedItemChange): void {
    this.taskService.updateNote(
      this.taskId,
      feedChange.item.$key,
      feedChange.change
    );
  }

  private scrollToNote(note: TaskNote, animationCallback = () => {}): void {
    setTimeout(
      () =>
        this.virtualScroller &&
        this.virtualScroller.scrollInto(note, true, 0, 0, () => {
          animationCallback();
        })
    );
  }

  getKey(index: number, currentValue: TaskNote): string | number {
    return currentValue ? currentValue.$key : index;
  }

  toggleHidingPinnedNotes(): void {
    this.hidingPinnedStatus = !this.hidingPinnedStatus;

    localStorage.setItem(
      this.hidingPinStorageName,
      JSON.stringify(this.hidingPinnedStatus)
    );
  }
}
