import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  firstValueFrom,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  switchMap
} from 'rxjs';

import { WindowService } from '../../shared/window/window.service';
import { TaskModel } from '../task.model';
import { TaskService } from '../task.service';

@Component({
  selector: 'ease-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectOverviewComponent implements OnInit {
  @Input() set task(task: TaskModel) {
    this._task = task;
    this.isSubtask = !!task.parent;
    this.taskSource.next(task);
  }

  get task(): TaskModel {
    return this._task;
  }

  private _task: TaskModel;
  private taskSource: ReplaySubject<TaskModel> = new ReplaySubject();
  public childrenOrSiblingTasks$: Observable<TaskModel[]>;
  private task$: Observable<TaskModel> = this.taskSource.asObservable();
  public currentOrParentTask$: Observable<TaskModel>;
  public createMode = false;
  public isSubtask: boolean;

  constructor(
    private taskService: TaskService,
    private windowService: WindowService
  ) {}

  ngOnInit(): void {
    // Retrieve Parent Task
    this.currentOrParentTask$ = this.task$.pipe(
      switchMap(task =>
        this.isSubtask ? this.taskService.get(task.parent) : of(task)
      ),
      shareReplay(1)
    );

    // Retrieve Subtasks or Related Tasks
    this.childrenOrSiblingTasks$ = this.currentOrParentTask$.pipe(
      switchMap(parentTask => this.taskService.getChildren(parentTask.$key)),
      shareReplay(1)
    );
  }

  async createTask() {
    const currentOrParentTask = await firstValueFrom(this.currentOrParentTask$);
    const window = await this.windowService.create({
      type: 'createTask',
      title: `Create Sub Task for ${currentOrParentTask.name}`,
      highlighted: true,
      data: {
        entityId: currentOrParentTask.entityId,
        entityType: currentOrParentTask.entityType,
        entityName: currentOrParentTask.entityName,
        ...(currentOrParentTask.accountType
          ? { accountType: currentOrParentTask.accountType }
          : {}),
        parent: currentOrParentTask.$key
      }
    });

    const subtaskCreated = await firstValueFrom(window.onClose).then(
      windowResult => windowResult.closeResult
    );
    if (subtaskCreated) {
      await this.windowService.create({
        type: 'viewTask',
        data: this.task.$key,
        state: 'MAXIMIZED'
      });
    }
  }

  getKey(index: number, task: TaskModel): string | number {
    return task ? task?.$key : index;
  }
}
