import { NgModule } from '@angular/core';

import { ColorBlobModule } from '../../shared/color-blob/color-blob.module';
import { SharedModule } from '../../shared/shared.module';
import { TaskPhaseComponent } from '../task-modules/task-phase/task-phase.component';
import { TaskPhasePopoverModule } from '../../shared/popover/task-phase/task-phase-popover.module';
import { TaskPhaseSelectorModule } from './task-phase-selector/task-phase-selector.module';
import { TaskPhaseBlobComponent } from './task-phase-blob/task-phase-blob.component';

@NgModule({
  imports: [
    ColorBlobModule,
    TaskPhasePopoverModule,
    TaskPhaseSelectorModule,
    SharedModule
  ],
  declarations: [TaskPhaseComponent, TaskPhaseBlobComponent],
  exports: [
    TaskPhaseBlobComponent,
    TaskPhaseComponent,
    TaskPhaseSelectorModule,
    TaskPhasePopoverModule
  ]
})
export class TaskPhaseModule {}
