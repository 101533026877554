<div class="banner theme-info mb-5 flex flex-row items-center justify-center">
  <mat-icon class="mr-2">info</mat-icon>
  <span
    >If these campaigns can run without negative keywords, add a label:
    <strong>No Neg KW</strong></span
  >
</div>
<ease-list-group size="sm">
  <ease-list-group-item *ngFor="let campaign of items">{{
    campaign.campaignName
  }}</ease-list-group-item>
</ease-list-group>
