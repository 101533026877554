import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { HttpClient } from '@angular/common/http';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  EaseUnwrappedSnapshot,
  FirebaseDbService,
  ListAsObject
} from 'src/app/shared/firebase-db.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { orderBy } from 'lodash-es';

import { environment } from '../../environments/environment';
import {
  blobToFile,
  firebaseJSON,
  initialsFromName
} from '../shared/utils/functions';
import {
  SNACKBAR_DURATION_ERROR,
  SNACKBAR_DURATION_SUCCESS
} from '../shared/constants';
import {
  USERS_PATH,
  USER_AVATARS_PATH,
  USER_SETTINGS_PATH
} from '../shared/firebase-paths';
import { ListDisplayItem } from '../shared/shared.interface';
import { EMAIL_REGEXP } from '../shared/shared-validators';
import { MappedCache } from '../shared/mapped-cache';
import { AuthService } from '../auth/auth.service';
import {
  UserAvatarModel,
  UserModel,
  UserModes,
  UserRetirePayload
} from './user.model';

@Injectable({ providedIn: 'root' })
export class UserService {
  public users: Observable<UserModel[]>;
  public usersAll: Observable<UserModel[]>;
  public emails: Observable<ListDisplayItem[]>;
  public avatarsAsObject$: Observable<ListAsObject<UserAvatarModel>>;
  public avatars: Observable<UserAvatarModel[]>;
  public usersAsObject: Observable<{ [userId: string]: UserModel }>;
  public currentUser$: Observable<UserModel>;
  public currentUserStatus$: Observable<string>;
  public currentUser: UserModel;
  public currentUserRegion: ReplaySubject<string> = new ReplaySubject<string>();
  private avatarsById: MappedCache<UserAvatarModel> = new MappedCache({
    size: 150
  });

  constructor(
    private angularFire: FirebaseDbService,
    private authService: AuthService,
    private afStorage: AngularFireStorage,
    private afFunctions: AngularFireFunctions,
    private http: HttpClient,
    private matSnackBar: MatSnackBar
  ) {
    this.users = this.angularFire
      .getList(`/${USERS_PATH}`, ref =>
        ref.orderByChild('status').equalTo('active')
      )
      .pipe(shareReplay({ refCount: true, bufferSize: 1 })) as Observable<
      UserModel[]
    >;

    this.usersAll = this.angularFire
      .getList(`/${USERS_PATH}`)
      .pipe(shareReplay({ refCount: true, bufferSize: 1 })) as Observable<
      UserModel[]
    >;

    this.emails = this.users.pipe(
      map(contacts => {
        const validEmails = contacts.filter(
          contact => contact?.email && EMAIL_REGEXP.test(contact.email)
        );
        const orderedEmails = orderBy(
          validEmails,
          [contact => contact.name],
          ['asc']
        );
        return orderedEmails.map(contact => ({
          value: contact.email,
          viewValue: `${contact.name || ''} (${contact.email})`
        }));
      })
    );

    this.avatarsAsObject$ = this.angularFire
      .getObject(`/${USER_AVATARS_PATH}`)
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.avatars = this.angularFire
      .getList(`/${USER_AVATARS_PATH}`)
      .pipe(shareReplay({ refCount: true, bufferSize: 1 })) as Observable<
      UserAvatarModel[]
    >;

    this.usersAsObject = this.angularFire
      .getObject(`/${USERS_PATH}`)
      .pipe(shareReplay(1));

    this.currentUser$ = this.authService.firebaseUser$.pipe(
      filter(authState => !!authState),
      switchMap(authState => this.get(this.getUserIdForEnvironment(authState))),
      tap(user => {
        this.currentUser = user;

        if (user) {
          this.currentUserRegion.next(user.region || 'canada');
        }
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.currentUserStatus$ = this.currentUser$.pipe(
      map(user => user?.status),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    /**
     * Once user status changed from active, logout user
     */
    this.currentUserStatus$
      .pipe(filter(userStatus => userStatus !== 'active'))
      .subscribe(() => this.authService.logout());
  }

  get(id: string): Observable<EaseUnwrappedSnapshot<UserModel>> {
    return this.angularFire.getObject<UserModel>(`/${USERS_PATH}/${id}`);
  }

  getUserIdForEnvironment(authData?): string {
    return environment.userId
      ? environment.userId
      : this.getUserIdFromAuthData(authData);
  }

  getUserIdFromAuthData(authData: firebase.User): string {
    return authData.uid.split(':')[1] || authData.uid;
  }

  getAvatar(userId: string): Observable<UserAvatarModel> {
    return this.avatarsById.get(
      userId,
      this.angularFire.getObject(`/${USER_AVATARS_PATH}/${userId}`)
    );
  }

  update(userId: string, values: Partial<UserModel>): Promise<void> {
    return this.angularFire
      .object(`/${USERS_PATH}/${userId}`)
      .update(firebaseJSON(values));
  }

  updateAvatar(avatarFile?: File, userId?: string): Promise<void> {
    if (avatarFile && avatarFile.size > 100000) {
      this.matSnackBar.open(
        'Avatar file too big, must be less than 100kB',
        'Close',
        {
          duration: SNACKBAR_DURATION_ERROR
        }
      );
    } else {
      const getFile = avatarFile
        ? Promise.resolve(avatarFile)
        : firstValueFrom(
            this.http.get('/assets/images/avatars/default.png', {
              responseType: 'blob'
            })
          ).then(blob => blobToFile(blob, 'default.png'));

      return getFile.then(async file => {
        const userToModify$ = userId
          ? this.get(userId).pipe(filter(user => user.$exists()))
          : this.currentUser$;

        const userToUpdate = await firstValueFrom(userToModify$);
        const uploadTaskResult = await this.afStorage.upload(
          `avatars/${userToUpdate.$key}/${file.name}`,
          file,
          {
            cacheControl: 'public, max-age=31536000'
          }
        );

        const userAvatar = {
          name: userToUpdate.name,
          initials: initialsFromName(userToUpdate.name),
          avatar: await uploadTaskResult.ref.getDownloadURL()
        };

        await this.angularFire
          .object(`/${USER_AVATARS_PATH}/${userToUpdate.$key}`)
          .set(userAvatar);

        this.matSnackBar.open('Avatar updated', 'Close', {
          duration: SNACKBAR_DURATION_SUCCESS
        });
      });
    }
  }

  updateUserModes(userModes: UserModes<number>): Promise<void> {
    return this.angularFire
      .object(`/${USERS_PATH}/${this.currentUser.$key}/mode`)
      .update(firebaseJSON(userModes));
  }

  getHomepage(): Observable<string> {
    return this.currentUser$.pipe(
      take(1),
      switchMap(user =>
        this.angularFire
          .getObject(`/${USER_SETTINGS_PATH}/${user.$key}/homepage`)
          .pipe(
            map(homepage =>
              homepage.$exists() ? homepage.$value : '/dashboards'
            )
          )
      )
    );
  }

  setHomepage(settingValue: string): Promise<any> {
    return this.angularFire
      .object(`/${USER_SETTINGS_PATH}/${this.currentUser.$key}/homepage`)
      .set(settingValue);
  }

  getLoginPermission(email: string) {
    return this.afFunctions
      .httpsCallable('userManagement-getDisabledStatus')(email)
      .pipe(map(result => !result));
  }

  setUserAuthStatus(email: string, disabled: boolean) {
    return this.afFunctions.httpsCallable('userManagement-setAuthStatus')({
      email,
      disabled
    });
  }

  retireUser(retireData: UserRetirePayload) {
    return this.afFunctions.httpsCallable('userManagement-retireUser')(
      retireData
    );
  }
}
