import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NegativeKeywordsService } from '../../../negative-keywords/negative-keywords.service';
import { NEGATIVE_KEYWORDS_LISTS_PATH } from '../../../shared/firebase-paths';
import { ListDisplayItem } from '../../../shared/shared.interface';

@Component({
  selector: 'ease-negative-keywords-selector',
  templateUrl: './negative-keywords-selector.component.html',
  styleUrls: ['./negative-keywords-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NegativeKeywordsSelectorComponent implements OnInit {
  @Input() control: FormControl<string[]> = new FormControl(null);
  public negativeKeywordLists$: Observable<ListDisplayItem[]>;

  constructor(private negativeKeywordsService: NegativeKeywordsService) {}

  ngOnInit(): void {
    this.negativeKeywordLists$ = this.negativeKeywordsService
      .getAll(NEGATIVE_KEYWORDS_LISTS_PATH)
      .pipe(
        map(lists =>
          lists.map(list => ({
            value: list.$key,
            viewValue: list.name
          }))
        )
      );
  }
}
