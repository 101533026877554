import { NgModule } from '@angular/core';

import { PopoverModule } from 'src/app/shared/popover/popover.module';
import { MarkdownModule } from '../../shared/markdown/markdown.module';
import { SharedFormModule } from '../../shared/shared-form.module';
import { SharedModule } from '../../shared/shared.module';
import { CreateCustomerCommunicationComponent } from './create-customer-communication/create-customer-communication.component';

@NgModule({
  imports: [MarkdownModule, PopoverModule, SharedFormModule, SharedModule],
  declarations: [CreateCustomerCommunicationComponent]
})
export class CustomerCommunicationModule {}
