<ease-material-chips-input
  [control]="selectedRoles"
  [items]="allRoles"
  [showNextSuggestions]="true"
  [disabled]="disabled"
  label="Assigned Roles"
  placeholder="Assign a user by role"
  class="w-full md:max-w-[420px]"
  #matChipInput
>
  <ng-template #chipTemplate let-role="item">
    <mat-chip
      [selectable]="matChipInput.selectable"
      [removable]="matChipInput.removable"
      (removed)="matChipInput.remove(role)"
    >
      {{ role.viewValue }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </ng-template>
</ease-material-chips-input>
