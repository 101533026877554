import { Injectable } from '@angular/core';
import {
  MultiSearchRequestSchema,
  MultiSearchResponse
} from 'typesense/lib/Typesense/MultiSearch';
import { SearchUtils } from '../search/search-utils';

import { SearchService } from '../search/search.service';

@Injectable({ providedIn: 'root' })
export class FinderService {
  constructor(private searchService: SearchService) {}

  search(
    q: string,
    types: string[],
    includeOffline = false
  ): Promise<MultiSearchResponse<any>> {
    if (!q || !!!types.length) {
      return Promise.resolve({ results: [] });
    }

    const resultsLimit = types.length >= 3 ? 5 : 8;
    const searches: MultiSearchRequestSchema[] = types.map(
      type =>
        ({
          collection: type === 'prospects' ? 'customers' : type,
          q,
          per_page: resultsLimit,
          filter_by: this.getFiltersForType(type, includeOffline)
        } as MultiSearchRequestSchema)
    );

    return this.searchService.createMultiple({ searches });
  }

  private getFiltersForType(type: string, includeOffline: boolean): string {
    const filterStrings: string[] = [];

    if (type === 'prospects') {
      filterStrings.push(SearchUtils.exactEqualTo('phase', 'prospect'));
    }

    if (type === 'customers') {
      filterStrings.push(SearchUtils.notEqualTo('phase', 'prospect'));
    }

    if (
      ['accounts', 'customers', 'prospects'].includes(type) &&
      !includeOffline
    ) {
      filterStrings.push(
        SearchUtils.notIn('status', ['OFFLINE', 'PAUSED', 'LOST'])
      );
    }

    return filterStrings.length
      ? SearchUtils.joinAnd(filterStrings)
      : undefined;
  }
}
