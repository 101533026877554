import { NgModule } from '@angular/core';
import { NgChartsModule } from 'ng2-charts';

import { AccountIconModule } from '../account-icon/account-icon.module';
import { SharedModule } from '../shared.module';
import { ChartComponent } from './chart/chart.component';
import { ChartHeaderComponent } from './chart-header/chart-header.component';
import { ChartGroupComponent } from './chart-group/chart-group.component';
import { ChartSummaryComponent } from './chart-summary/chart-summary.component';

@NgModule({
  imports: [AccountIconModule, NgChartsModule, SharedModule],
  declarations: [
    ChartComponent,
    ChartHeaderComponent,
    ChartGroupComponent,
    ChartSummaryComponent
  ],
  exports: [
    ChartComponent,
    ChartGroupComponent,
    ChartHeaderComponent,
    ChartSummaryComponent
  ]
})
export class ChartsModule {}
