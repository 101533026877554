import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FirebaseDbService } from 'src/app/shared/firebase-db.service';
import { USER_ROLES_PATH } from '../../shared/firebase-paths';

export interface UserRole {
  $key?: string;
  label: string;
  scope: string;
}

@Injectable({ providedIn: 'root' })
export class UserRolesService {
  constructor(private angularFire: FirebaseDbService) {}

  create(roleKey: string, roleMeta: UserRole): Promise<void> {
    return this.angularFire
      .object(`/${USER_ROLES_PATH}/${roleKey}`)
      .set(roleMeta);
  }

  getAll(): Observable<UserRole[]> {
    return this.angularFire.getList(`/${USER_ROLES_PATH}/`, ref =>
      ref.orderByChild('scope')
    );
  }

  get(roleKey: string): Observable<UserRole> {
    return this.angularFire.getObject(`/${USER_ROLES_PATH}/${roleKey}`);
  }

  getForScope(scope: string): Observable<UserRole[]> {
    return this.angularFire.getList(`/${USER_ROLES_PATH}/`, ref =>
      ref.orderByChild('scope').equalTo(scope)
    );
  }
}
