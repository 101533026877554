import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as moment from 'moment';
import {
  BehaviorSubject,
  defer,
  firstValueFrom,
  Observable,
  ReplaySubject
} from 'rxjs';

import { switchMap, tap } from 'rxjs';
import { DatepickerComponent } from 'src/app/shared/datepicker/datepicker.component';
import { UserSelected } from 'src/app/shared/user-selector/user-selector.interface';
import { TaskService } from 'src/app/tasks/task.service';
import { SharedLayoutService } from '../../../../shared/shared-layout.service';
import {
  getAssignWrites,
  getSubscribeWrites,
  getUnassignWrites,
  getUnsubscribeWrites
} from '../../../../shared/utils/firebase-functions-common';
import { UserService } from '../../../../users/user.service';
import { BackpackService } from '../../../backpack.service';
import { TaskModel } from '../../../task.model';
import { TaskItemActionMenuService } from './task-item-action-menu.service';

@UntilDestroy()
@Component({
  selector: 'ease-task-item-action-menu',
  templateUrl: './task-item-action-menu.component.html',
  styleUrls: ['./task-item-action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskItemActionMenuComponent implements OnInit, OnDestroy {
  @Input() set taskId(taskId: string) {
    this._taskId = taskId;
    this.taskIdSource.next(taskId);
  }

  get taskId(): string {
    return this._taskId;
  }

  private _taskId: string;

  @ViewChildren(SatPopover) satPopovers: QueryList<SatPopover>;
  @ViewChildren(DatepickerComponent)
  datePickers: QueryList<DatepickerComponent>;
  @Output() popoverClose: EventEmitter<void> = new EventEmitter();

  public tempAssigned: string[] = [];
  public tempSubscribed: string[] = [];
  private userWrites = {};
  private completeOperation$: BehaviorSubject<void> = new BehaviorSubject<void>(
    null
  );
  private taskIdSource: ReplaySubject<string> = new ReplaySubject<string>();
  private taskId$: Observable<string> = this.taskIdSource.asObservable();
  public task$: Observable<TaskModel>;
  public now: Date = moment().startOf('day').toDate();

  constructor(
    private taskService: TaskService,
    public taskItemActionMenuService: TaskItemActionMenuService,
    public sharedLayoutService: SharedLayoutService,
    private userService: UserService,
    public backpackService: BackpackService
  ) {}

  ngOnInit(): void {
    this.task$ = this.taskId$.pipe(
      switchMap(taskId => this.taskService.get(taskId)),
      tap(task => {
        this.tempAssigned = task.$displayAssigned || [];
        this.tempSubscribed = task.$displaySubscribed || [];
      })
    );
  }

  handlePopoverOpen(popover: SatPopover, event: MouseEvent): void {
    this.closeAllPopovers();
    popover.open();
    event.stopPropagation();
  }

  closeAllPopovers(): void {
    this.satPopovers.forEach(popover => {
      if (popover.isOpen()) {
        popover.close();
      }
    });

    this.datePickers.forEach(datePicker => {
      if (datePicker.calendar.isOpen()) {
        datePicker.calendar.close();
      }
    });
  }

  /**
   * When a user is assigned/subscribed, store the necessary Firebase writes
   * under their userId, to be used for execution when the popover closes
   *
   * @param userSelected User that was assigned/subscribed
   */
  async onUserAdd(
    userSelected: UserSelected,
    actionType: 'assign' | 'subscribe'
  ): Promise<void> {
    const taskId = await firstValueFrom(this.taskId$);
    const { userId, method } = userSelected;

    switch (actionType) {
      case 'assign':
        this.tempAssigned = [...this.tempAssigned, userSelected.userId];

        /**
         * Subscribe the assigner if:
         * - they're not already assigned or subscribed
         * - they're not the assignee
         */
        const assigner = this.userService.currentUser.$key;
        const isUserOnTask =
          this.tempAssigned.includes(assigner) ||
          this.tempSubscribed.includes(assigner);
        const subAssignerWrites =
          isUserOnTask || assigner === userId
            ? {}
            : getSubscribeWrites(taskId, assigner, 'direct');

        this.userWrites[userSelected.userId] = {
          ...getAssignWrites(taskId, userId, assigner, method),
          ...subAssignerWrites
        };
        break;

      case 'subscribe':
        this.tempSubscribed = [...this.tempSubscribed, userSelected.userId];
        this.userWrites[userSelected.userId] = getSubscribeWrites(
          taskId,
          userId,
          method
        );
        break;
    }
  }

  /**
   * When a user is unassigned/unsubscribed, store the necessary Firebase writes
   * under their userId, to be used for execution when the popover closes
   *
   * @param userId User ID that was unassigned/unsubscribed
   */
  async onUserRemove(
    userId: string,
    actionType: 'unassign' | 'unsubscribe'
  ): Promise<void> {
    const taskId = await firstValueFrom(this.taskId$);
    switch (actionType) {
      case 'unassign':
        this.tempAssigned = this.tempAssigned.filter(user => user !== userId);
        this.userWrites[userId] = getUnassignWrites(taskId, userId);
        break;
      case 'unsubscribe':
        this.tempSubscribed = this.tempSubscribed.filter(
          user => user !== userId
        );
        this.userWrites[userId] = getUnsubscribeWrites(taskId, userId);
        break;
    }
  }

  async onStatusChange(statusId: string): Promise<void> {
    const taskId = await firstValueFrom(this.taskId$);
    this.taskItemActionMenuService.addOperation(
      taskId,
      defer(() => this.taskService.setStatus(taskId, statusId))
    );
  }

  async onPhaseChange(phaseId: string): Promise<void> {
    const taskId = await firstValueFrom(this.taskId$);
    this.taskItemActionMenuService.addOperation(
      taskId,
      defer(() => this.taskService.setPhase(taskId, phaseId))
    );
  }

  async updateTask(taskData: Partial<TaskModel>): Promise<void> {
    const task = await firstValueFrom(this.task$);
    if (await this.taskService.allowDueDateChange(task, taskData.dueDate)) {
      this.taskItemActionMenuService.addOperation(
        task.$key,
        defer(() => this.taskService.update(task.$key, taskData))
      );
      this.taskItemActionMenuService.executeOperations(task);
    }
  }

  /**
   * When the assign/subscribe popover is closed and there are pending assign-related writes,
   * Build up a final list of writes, and then add an operation to the queue that is
   * a single Firebase .update() call, running them all at once
   */
  async actionTypePopoverClosed() {
    const task = await firstValueFrom(this.task$);
    if (Object.keys(this.userWrites).length) {
      let writes = {};
      for (const [_, userWrites] of Object.entries(this.userWrites)) {
        writes = { ...writes, ...(userWrites as any) };
      }
      this.taskItemActionMenuService.addOperation(
        task.$key,
        defer(() => this.taskService.ref.update(writes))
      );
      this.userWrites = {};
      await this.taskItemActionMenuService.executeOperations(task, false);
    }
  }

  async popoverClosed(allowUndo: boolean = true): Promise<void> {
    const task = await firstValueFrom(this.task$);
    if (this.taskItemActionMenuService.operations[task.$key]?.length) {
      await this.taskItemActionMenuService.executeOperations(task, allowUndo);
      this.popoverClose.emit();
    }
  }

  /**
   * When the complete popover indicates a 'complete' operation has started,
   * add an operation that will take the first value from the this.completeOperation$
   * After this subject emits a value, the operation is considered finished
   */
  async onCompleteStart() {
    const taskId = await firstValueFrom(this.taskId$);
    this.taskItemActionMenuService.addOperation(
      taskId,
      defer(async () => firstValueFrom(this.completeOperation$))
    );
  }

  /**
   * When the complete popover indicates a 'complete' operation has finished,
   * push a value to the completeOperation$ subject. This will mark the
   * operation added above as finished and remove the loading indicator
   */
  onCompleted() {
    this.completeOperation$.next();
    this.popoverClose.emit();
  }

  async toggleBackpack() {
    const taskId = await firstValueFrom(this.taskId$);
    const backpack = await firstValueFrom(this.backpackService.backpack$);

    backpack[taskId]
      ? await this.backpackService.remove(taskId)
      : await this.backpackService.add(taskId);

    this.popoverClose.emit();
  }

  async ngOnDestroy(): Promise<void> {
    this.closeAllPopovers();
  }
}
