import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { TaskModel } from '../../../task.model';

@Component({
  selector: 'ease-task-budget-basic',
  templateUrl: './task-budget-basic.component.html',
  styleUrls: [
    '../task-budget.component.scss',
    './task-budget-basic.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBudgetBasicComponent implements OnInit {
  @Input()
  task: TaskModel;

  constructor() {}

  ngOnInit() {}
}
