import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserModel } from '../../../users/user.model';
import { UserSelected } from '../../../shared/user-selector/user-selector.interface';
import { TaskModel } from '../../task.model';
import { TaskService } from '../../task.service';

@Component({
  selector: 'ease-subtask-item',
  templateUrl: './subtask-item.component.html',
  styleUrls: ['./subtask-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubtaskItemComponent implements OnInit {
  @Input() previewMode: boolean = false;
  @Input()
  users: { [userId: string]: UserModel };
  @Input() set task(task: TaskModel) {
    this.assigned.setValue(task?.$displayAssigned || []);
    this._task = task;
  }
  get task(): TaskModel {
    return this._task;
  }
  @Input() isParent: boolean;
  @Input() currentlyViewing: boolean;

  private _task: TaskModel;

  public assigned: FormControl<string[]> = new FormControl([]);
  public isEditable: boolean = false;
  public todayTimestamp = new Date().setHours(0, 0, 0, 0);

  constructor(public taskService: TaskService) {}

  ngOnInit(): void {}

  assign(taskId, selected: UserSelected): void {
    this.taskService.assign({ taskId, ...selected });
  }

  subscribe(taskId, selected: UserSelected): void {
    this.taskService.subscribe({ taskId, ...selected });
  }
}
