<ng-container *ngIf="rowData; else loading">
  <div class="mb-6">
    <div
      class="mb-2 flex h-3 flex-row overflow-hidden rounded-full bg-gray-200"
    >
      <div
        *ngFor="let item of summary | keyvalue"
        class="h-3"
        [ngClass]="statusBarBg[item.key]"
        [ngStyle]="{ 'flex-basis': (item.value / rowData.length) * 100 + '%' }"
      ></div>
    </div>
    <div class="flex flex-row justify-center gap-8">
      <div
        *ngFor="let item of summary | keyvalue"
        class="flex flex-row items-center"
      >
        <div class="mr-2 h-3 w-3" [ngClass]="statusBarBg[item.key]"></div>
        <div class="text-sm capitalize">
          <span class="font-medium">{{ item.key }}</span>
          <span class="ml-1 italic text-gray-500">({{ item.value }})</span>
        </div>
      </div>
    </div>
  </div>

  <ag-grid-angular
    [rowData]="rowData"
    [gridOptions]="gridOptions"
    class="ag-theme-material w-full flex-grow"
  >
  </ag-grid-angular>
</ng-container>

<ng-template #loading>
  <ease-state-loading
    message="Preparing bulk task creation queue..."
  ></ease-state-loading>
</ng-template>
