import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { TaskShoppingAdIssue } from './task-shopping-ad-issue.interface';

@Component({
  selector: 'ease-task-shopping-ad-issue',
  templateUrl: './task-shopping-ad-issue.component.html',
  styleUrls: ['./task-shopping-ad-issue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskShoppingAdIssueComponent implements OnInit {
  @Input() items: TaskShoppingAdIssue[];

  constructor() {}

  ngOnInit(): void {}
}
