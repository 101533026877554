import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { CalendarModule } from 'primeng/calendar';

import { EaseMaterialModule } from '../ease-material.module';
import { FormErrorsModule } from '../form-errors/form-errors.module';
import { RichTooltipModule } from '../rich-tooltip/rich-tooltip.module';
import { DatepickerComponent } from './datepicker.component';

@NgModule({
  imports: [
    CalendarModule,
    CommonModule,
    EaseMaterialModule,
    FormErrorsModule,
    ReactiveFormsModule,
    RichTooltipModule,
    SatPopoverModule
  ],
  declarations: [DatepickerComponent],
  exports: [DatepickerComponent]
})
export class DatepickerModule {}
