import { ConfigurationOptions } from 'typesense/lib/Typesense/Configuration';

const typesenseConfiguration: Record<
  'staging' | 'production',
  ConfigurationOptions
> = {
  staging: {
    nodes: [
      {
        host: 'vc0ayhrj1q5kuwdtp-1.a1.typesense.net',
        port: 443,
        protocol: 'https'
      }
    ],
    apiKey: 'doTsZIU8aJW5OOmWSP40l6rQ7NMZ2x6I'
  },
  production: {
    nearestNode: {
      host: '1z7fkr2y0lsw3nm5p.a1.typesense.net',
      port: 443,
      protocol: 'https'
    },
    nodes: [
      {
        host: '1z7fkr2y0lsw3nm5p-1.a1.typesense.net',
        port: 443,
        protocol: 'https'
      },
      {
        host: '1z7fkr2y0lsw3nm5p-2.a1.typesense.net',
        port: 443,
        protocol: 'https'
      },
      {
        host: '1z7fkr2y0lsw3nm5p-3.a1.typesense.net',
        port: 443,
        protocol: 'https'
      }
    ],
    apiKey: 'IUmElQmrCndoySJfyplcM1gG6yDcZsBJ'
  }
};

/**
 * Get Typesense configuration for a given environment
 *
 * @param env The environment to retrieve the configuration for
 * @param adminApiKey Optional Admin API key to override the default search-only key with
 * @returns Typesense configuration
 */
export const getTypesenseConfiguration = (
  env: keyof typeof typesenseConfiguration,
  adminApiKey?: string
): ConfigurationOptions => {
  const baseConfig = typesenseConfiguration[env];

  if (adminApiKey) {
    baseConfig.apiKey = adminApiKey;
  }

  return baseConfig;
};
