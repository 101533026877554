<div [style.opacity]="disabled ? 0.2 : 1">
  <ease-markdown-editor
    #markdownEditor
    [persistenceKey]="persistenceKey"
    (ngModelChange)="onChange($event)"
    [easeMentions]="allowMentions"
    [placeholder]="placeholder"
    [(ngModel)]="currentValue"
    [size]="size"
    [autoCollapse]="autoCollapse"
  ></ease-markdown-editor>
  <div
    class="mt-2 flex flex-row items-center justify-end"
    [ngClass]="{ hidden: hideActionsUntilEntry && !currentValue?.length }"
  >
    <button (click)="onRevert()" mat-button>{{ cancelText }}</button>
    <button (click)="onSave()" color="primary" [disabled]="disabled" mat-button>
      {{ saveText }}
    </button>
  </div>
</div>
