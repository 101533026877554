import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Announcement } from 'src/app/announcements/announcements.interface';
import { AnnouncementsDetailComponent } from 'src/app/announcements/announcements-detail/announcements-detail.component';

@Component({
  selector: 'ease-announcements-popover-item',
  templateUrl: './announcements-popover-item.component.html',
  styleUrls: ['./announcements-popover-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsPopoverItemComponent implements OnInit {
  @Input() announcement: Announcement;

  constructor(private matDialog: MatDialog) {}

  ngOnInit(): void {}

  openDetails() {
    const dialogRef = this.matDialog.open(AnnouncementsDetailComponent, {
      width: '80vw',
      height: '80vh',
      panelClass: 'trim-dialog'
    });
    dialogRef.componentInstance.announcementId = this.announcement.$key;
  }
}
