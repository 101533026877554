import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Change } from '../../../shared/shared.interface';
import { CustomerBudget } from '../../../customers/customer-accounts/customer-account-budget/customer-account-budget.interface';

@Component({
  selector: 'ease-account-budget-dialog',
  templateUrl: './account-budget-dialog.component.html',
  styleUrls: ['./account-budget-dialog.component.scss']
})
export class AccountBudgetDialogComponent implements OnInit {
  @Input() accountId: string;
  @Input() accountType: string;
  @Input() accountName: string;

  constructor(public dialogRef: MatDialogRef<AccountBudgetDialogComponent>) {}

  ngOnInit() {}

  save(event: Change<Partial<CustomerBudget>>) {
    this.dialogRef.close(event);
  }
}
