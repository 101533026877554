import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[easeActionScroll]',
  exportAs: 'actionScroll'
})
export class ActionScrollDirective {
  @Input()
  easeActionScroll: any;
  @Input()
  direction: string = 'up';
  private container;

  constructor(private elementRef: ElementRef) {
    this.container = this.elementRef.nativeElement;
  }

  scroll() {
    this.container.scrollTop =
      this.direction === 'up' ? 0 : this.container.scrollHeight;
  }
}
