<div class="wrapper" [style]="'min-height:' + minHeight + 'px;'">
  <mat-progress-spinner
    class="spinner"
    [diameter]="24"
    color="primary"
    mode="indeterminate"
  >
  </mat-progress-spinner>
  <p *ngIf="message" class="message">{{ message }}</p>
</div>
