import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { SatPopover } from '@ncstate/sat-popover';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';

import { UserService } from 'src/app/users/user.service';
import { ConfirmService } from '../confirm/confirm.service';
import { GridTargetingService } from '../grid-toolbar/grid-targeting/grid-targeting.service';
import { WindowPane } from '../window/window-pane/window-pane';
import { Target, TargetMember } from './bulk-targeting.interface';

@UntilDestroy()
@Component({
  selector: 'ease-bulk-targeting',
  templateUrl: './bulk-targeting.component.html',
  styleUrls: ['./bulk-targeting.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BulkTargetingComponent
    }
  ]
})
export class BulkTargetingComponent implements OnInit {
  @Input() title = 'Linked Entities';
  @Input() emptyMessage = 'No linked entities yet';
  @Input() targetLevels: Target['level'][] = [];
  @Input() disabled = false;
  @Input() onlyUsers: boolean;
  @ViewChild('userPopover') userPopover: SatPopover;
  public level: FormControl<Target['level']> = new FormControl(
    { value: null, disabled: this.disabled },
    Validators.required
  );
  public currentLevel: Target['level'];
  public members: FormControl<TargetMember[]> = new FormControl(
    { value: [], disabled: this.disabled },
    Validators.required
  );
  private touched = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private confirmService: ConfirmService,
    public gridTargetingService: GridTargetingService,
    public userService: UserService,
    public windowPane: WindowPane<void>
  ) {}

  ngOnInit(): void {
    this.level.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(level => {
        if (this.currentLevel !== level) {
          if (this.currentLevel !== 'company' && this.members.value?.length) {
            this.confirmService
              .confirm({
                message: `Are you sure you want to switch target levels? All currently targeted ${this.currentLevel}s will be lost.`,
                confirmText: `Switch`,
                cancelText: 'Cancel'
              })
              .then(confirmResult => {
                if (confirmResult.confirm) {
                  this.setLevel(level);
                } else {
                  this.level.setValue(this.currentLevel);
                }
              });
          } else {
            this.setLevel(level);
          }
        }
      });

    this.members.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onChange(this.getValue()));

    // Disable toggling between different target levels when modifying ag-grid filters
    this.gridTargetingService
      .getState()
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        switch (state) {
          case 'MODIFYING':
            this.gridTargetingService.getActiveWindow().id ===
            this.windowPane.id
              ? this.level.disable()
              : this.level.enable();
            break;
          default:
            this.level.disabled && this.level.enable();
        }
      });
  }

  setLevel(level: Target['level']) {
    this.markAsTouched();
    this.currentLevel = level;

    // reset form control to remove existing control value accessors
    this.members.reset([]);
    this.cdr.detectChanges();

    switch (level) {
      case 'company':
        this.members.setValue([{ id: this.userService.currentUser.region }], {
          emitEvent: false
        });
        break;
      case 'user':
        this.members.setValue([{ id: this.userService.currentUser.$key }], {
          emitEvent: false
        });
        break;
    }

    this.onChange(this.getValue());
  }

  getValue(): Target {
    return {
      level: this.level.value,
      members: this.members.value
    };
  }

  /**
   * ControlValueAccessor
   */
  writeValue(target: Target) {
    this.level.setValue(target ? target.level : null);
    this.members.setValue(target?.members || []);
  }

  onChange = value => {};

  onTouched = () => {};

  registerOnChange(onChangeFn) {
    this.onChange = onChangeFn;
  }

  registerOnTouched(onTouchedFn) {
    this.onTouched = onTouchedFn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
