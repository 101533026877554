<ng-container *ngIf="!isGroup && budgetUtilization">
  <div
    class="metric pointer flex flex-row"
    [ngClass]="budgetUtilization.ragStatusColor"
    (click)="metricInfo.toggle()"
    [satPopoverAnchor]="metricInfo"
  >
    <ease-metric-graph-bar
      [value]="budgetUtilization.butPercentageToDate"
      class="flex-1"
    ></ease-metric-graph-bar>
  </div>
  <!--POPOVER-->
  <sat-popover [autoFocus]="false" [hasBackdrop]="true" #metricInfo>
    <ease-budget-utilization-popover
      *ngIf="metricInfo.isOpen()"
      [budgetUtilization]="budgetUtilization"
      [accountBudget]="accountBudget"
      (openBudget)="openBudgetManager()"
    ></ease-budget-utilization-popover
  ></sat-popover>
  <!--end of POPOVER-->
</ng-container>

<ng-container *ngIf="isGroup && params.value">
  {{ params.value }}%
</ng-container>
