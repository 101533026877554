import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Options, RRule } from 'rrule';
import { combineLatest, firstValueFrom, Observable, Timestamp } from 'rxjs';
import { map, startWith, take, tap, timestamp } from 'rxjs/operators';

import { ListModel } from '../boards/list.model';
import { ListService } from '../boards/list.service';
import { CustomerAccountsService } from '../customers/customer-accounts/customer-accounts.service';
import { CustomersService } from '../customers/customers.service';
import { ConfirmService } from '../shared/confirm/confirm.service';
import { EntityUtilsService } from '../shared/entity-utils.service';
import { MarkdownVariableService } from '../shared/markdown/markdown-variable.service';
import { CustomData } from '../shared/markdown/markdown.interface';
import { EntityMetadata } from '../shared/shared.interface';
import { UserSelected } from '../shared/user-selector/user-selector.interface';
import { UserService } from '../users/user.service';
import { TaskTemplateFields } from '../admin/task-templates/task-template.interface';
import { TaskRuleForm, TaskTemplateFieldsGroup } from './task-form.interface';
import { TaskFormValidators } from './task-form.validators';
import { IncomingRule, ScheduledFrequency } from './task.interface';
import { CreatingTaskModel } from './task.model';
import { TaskService } from './task.service';
import { SubtasksService } from './task-project/subtasks.service';

@Injectable({ providedIn: 'root' })
export class TaskFormService {
  private SCHEDULED_FREQUENCIES: ScheduledFrequency[] = [
    { text: 'Does not repeat', value: '1_NO_REPEAT' },
    { text: 'Every day', value: '1_DAILY' },
    { text: 'Every 2 days', value: 2 },
    { text: 'Every 3 days', value: 3 },
    { text: 'Every 7 days', value: 7 },
    { text: 'Every 14 days', value: 14 },
    { text: 'Every 30 days', value: 30 },
    { text: 'Every 60 days', value: 60 },
    { text: 'Every 90 days', value: 90 }
  ];
  private FALLBACK_CUSTOMER_ID: string = 'SKC1002005226'; // SearchKings

  constructor(
    private confirmService: ConfirmService,
    private customerAccountsService: CustomerAccountsService,
    private customersService: CustomersService,
    private entityUtilsService: EntityUtilsService,
    private formBuilder: FormBuilder,
    private listService: ListService,
    private markdownVariableService: MarkdownVariableService,
    private taskService: TaskService,
    private userService: UserService,
    private subtasksService: SubtasksService
  ) {}

  /**
   * Returns only the entity id, type, name, and account type
   * for a given to avoid merging the same properties into a task
   *
   * @param entity
   * @returns an object containing entityId, entityType, entityName, accountType(only for account)
   */
  getEntityMeta(entity: EntityMetadata): Omit<EntityMetadata, 'phase'> {
    const { entityId, entityType, entityName, accountType } = entity || {};

    return { entityId, entityType, entityName, accountType };
  }

  getTaskForm(
    isTemplate: boolean,
    isPreScheduled: boolean
  ): TaskTemplateFieldsGroup {
    return this.formBuilder.group<TaskTemplateFieldsGroup['controls']>(
      {
        accountType: this.formBuilder.control(null),
        assigned: this.formBuilder.control(
          isTemplate || isPreScheduled
            ? []
            : [{ userId: this.userService.currentUser?.$key, method: 'direct' }]
        ),
        assignCurrentUser: this.formBuilder.control(false),
        assignedRoles: this.formBuilder.control({}),
        subscribed: this.formBuilder.control([]),
        board: this.formBuilder.control(null, {
          validators: Validators.required
        }),
        checklist: this.formBuilder.control(null),
        description: this.formBuilder.control(null),
        entityId: this.formBuilder.control(null),
        entityType: this.formBuilder.control(null),
        entityName: this.formBuilder.control(null),
        list: this.formBuilder.control(null, {
          validators: Validators.required
        }),
        name: this.formBuilder.control(null, {
          validators: Validators.required
        }),
        dueDate: this.formBuilder.control(null),
        startDate: this.formBuilder.control(null),
        scheduled: this.formBuilder.control(false),
        rrule: this.formBuilder.control(null),
        status: this.formBuilder.control(null),
        phase: this.formBuilder.control(null),
        parent: this.formBuilder.control(null),
        childrenTemplates: this.formBuilder.control([]),
        childrenOverrides: this.formBuilder.array<
          FormControl<TaskTemplateFields>
        >([])
      },
      {
        validators: TaskFormValidators.assignedWatchersRequired,
        asyncValidators: isTemplate
          ? null
          : TaskFormValidators.entityRoleValidations(this.entityUtilsService)
      }
    );
  }

  /**
   * Reset controls to their initial values. Primarily used when
   * toggling from single to bulk task forms (and vice versa) to
   * avoid complicating form validation.
   *
   * @param templateControl
   * @param taskForm
   */
  reset(
    templateControl: FormControl<string>,
    taskForm: TaskTemplateFieldsGroup
  ) {
    templateControl.reset();
    taskForm.reset({
      accountType: null,
      assigned: [
        { userId: this.userService.currentUser.$key, method: 'direct' }
      ],
      assignedRoles: {},
      board: null,
      checklist: null,
      description: ' ',
      entityId: null,
      entityType: null,
      entityName: null,
      list: null,
      name: null,
      dueDate: null,
      startDate: null,
      scheduled: false,
      rrule: null,
      status: null,
      phase: null
    });
  }

  /**
   * Get's all the lists on a given board that allows tasks to be created
   *
   * @param boardId
   * @returns
   */
  getWritableBoardList(boardId: string): Observable<ListModel[]> {
    return this.listService
      .getForBoard(boardId)
      .pipe(map(lists => lists.filter(list => list.canCreateTasks)));
  }

  /**
   * Gets the initial list for a given array of lists.
   * - if there's already selected, and if it exists in the array, return the listId of that list
   * - otherwise, return the listId of the first list in the array
   *
   * @param lists
   * @param listId Optional. The currently selected list.
   * @returns
   */
  getInitialList(lists: ListModel[], listId?: string): string {
    const foundList = !listId
      ? false
      : lists.find(list => list.$key === listId);
    return foundList ? listId : lists[0].$key;
  }

  /**
   * Builds and returns standard form controls for scheduling tasks
   *
   * @returns
   */
  getRruleForm(): TaskRuleForm {
    return this.formBuilder.group({
      dtstart: this.formBuilder.control(null),
      freq: this.formBuilder.control(RRule.DAILY, {
        validators: Validators.required
      }),
      interval: this.formBuilder.control(null, {
        validators: Validators.min(1)
      }),
      count: this.formBuilder.control(null, { validators: Validators.min(1) })
    });
  }

  getScheduledFrequencies(): ScheduledFrequency[] {
    return this.SCHEDULED_FREQUENCIES;
  }

  /**
   * Parses task schedule form controls and returns the formatted
   * RRule for ingest.
   *
   * @param fields
   * @returns
   */
  getTaskRule(fields: Options): string {
    const rrule = new RRule(
      Object.assign({}, fields, {
        dtstart: fields.dtstart ? new Date(fields.dtstart) : null,
        interval: fields.interval
          ? parseInt(fields.interval as unknown as string, 10)
          : null
      })
    );
    return rrule.toString();
  }

  /**
   * Parses an RRule string and gets the appropriate task schedule
   * field values
   *
   * @param rrule
   * @returns
   */
  getIncomingRule(rrule: string): IncomingRule {
    let interval: string | number;
    const rruleOptions = RRule.parseString(rrule);

    if (rruleOptions.count === 1 && rruleOptions.interval === 1) {
      interval = '1_NO_REPEAT';
    } else if (rruleOptions.interval === 1) {
      interval = '1_DAILY';
    } else {
      interval = rruleOptions.interval;
    }

    return {
      dtstart: rruleOptions.dtstart,
      interval,
      count: rruleOptions.count || null
    };
  }

  getCountFromInterval(interval: string | number): number | null {
    return interval === '1_NO_REPEAT' ? 1 : null;
  }

  /**
   * Toggles task schedule related form controls
   *
   * @param taskForm
   * @param ruleForm
   * @param scheduled
   * @param isTemplate
   */
  toggleScheduled(
    taskForm: TaskTemplateFieldsGroup,
    ruleForm: TaskRuleForm,
    scheduled: boolean,
    isTemplate: boolean
  ) {
    const now = moment().startOf('day').toDate();
    const rrule = taskForm.controls.rrule;
    const interval = ruleForm.controls.interval;
    const count = ruleForm.controls.count;
    const dtstart = ruleForm.controls.dtstart;

    if (scheduled) {
      rrule.enable({ emitEvent: false });

      if (!interval.value) {
        dtstart.setValue(now, { emitEvent: false });
        interval.setValue(`1_NO_REPEAT`, { emitEvent: false });
        count.setValue(this.getCountFromInterval(`1_NO_REPEAT`));
      }

      isTemplate
        ? dtstart.disable({ emitEvent: false })
        : dtstart.enable({ emitEvent: false });
    } else {
      dtstart.setValue(null, { emitEvent: false });
      interval.setValue(null, { emitEvent: false });
      rrule.disable({ emitEvent: false });
    }
  }

  async getAssignedRoles(task: CreatingTaskModel): Promise<CreatingTaskModel> {
    let assignedRoles = Object.keys(task.assignedRoles);

    if (assignedRoles.length) {
      /**
       * Get users from roles
       */
      const entityRoles = {};
      let accountCustomerId = null;

      if (task.entityType === 'account') {
        const linkedAccount = {
          accountId: task.entityId,
          accountType: task.accountType
        };
        const accountMeta = await firstValueFrom(
          this.customerAccountsService.getAccount(linkedAccount)
        );

        accountCustomerId = accountMeta['customerId'];
        entityRoles['accountManager'] = accountMeta['roles']?.['manager'];
        entityRoles['accountReviewer'] = accountMeta['roles']?.['reviewer'];
      }

      // only request the customer meta if 'relationshipManager' was assigned
      const hasCustomerRole = assignedRoles.includes('relationshipManager');
      if (
        hasCustomerRole &&
        (accountCustomerId ||
          task.entityType === 'customer' ||
          task.entityType === 'prospect')
      ) {
        const { roles: customerRoles } = await firstValueFrom(
          this.customersService.get(accountCustomerId || task.entityId)
        );
        entityRoles['relationshipManager'] = customerRoles?.relationshipManager;
      }

      // Exclude roles where we were not able to find a user definition for that role
      // on the given entity (account/customer)
      assignedRoles = assignedRoles.filter(role => !!entityRoles[role]);

      const assigned: UserSelected[] = assignedRoles.map(role => ({
        userId: entityRoles[role],
        method: 'direct'
      }));

      /**
       * Update task properties. Creates an array from a Set to remove duplicates.
       */
      const uniqueAssignedSet = new Set([...task.assigned, ...assigned]);
      task.assigned = [...uniqueAssignedSet];
    }

    return task;
  }

  async doCreate(fields: CreatingTaskModel, isBulk: boolean): Promise<string> {
    /**
     * Immediately get the users from the assigned roles if there were any and only if
     * the task is not scheduled. Otherwise, postpone this step until the task is actually created
     * to get updated user roles.
     */
    if (fields.assignedRoles && !fields.scheduled) {
      fields = await this.getAssignedRoles(fields);
    }

    const taskId = await this.taskService.create(fields, !isBulk);

    return taskId;
  }

  getFallbackCustomer(): Observable<Omit<EntityMetadata, 'phase'>> {
    return this.customersService.get(this.FALLBACK_CUSTOMER_ID).pipe(
      take(1),
      map(customer => ({
        entityId: customer.$key,
        entityType: 'customer',
        entityName: customer.name,
        accountType: null
      }))
    );
  }

  async createTask(params: {
    fields: any;
    isBulk?: boolean;
    customData?: CustomData;
  }): Promise<string> {
    let { fields } = params;
    const { isBulk, customData } = params;

    /**
     * If the user has applied a checklist, format it in the expected format.
     */
    if (fields.checklist) {
      fields.checklists = {
        [fields.checklist]: {
          name: 'placeholder'
        }
      };

      fields.checklist = null;
    }

    if (!fields.entityId) {
      const fallbackCustomer = await firstValueFrom(this.getFallbackCustomer());

      const confirmResult = await this.confirmService.confirm({
        message: `A task must be associated to a customer or account, would you like to link ${fallbackCustomer.entityName} and proceed?`,
        confirmText: `Proceed with ${fallbackCustomer.entityName}`,
        cancelText: 'Go back',
        confirmColor: 'primary'
      });

      if (confirmResult.confirm) {
        fields = { ...fields, ...fallbackCustomer };
      } else {
        return null;
      }
    }

    // Populate any variables used
    const { entityId, entityType, accountType } = fields;
    fields.description = await this.markdownVariableService.populateVariables(
      fields.description,
      { entityId, entityType, accountType },
      customData
    );

    const taskId = await this.doCreate(fields, isBulk);

    const { childrenOverrides, childrenTemplates, ...parentTaskFields } =
      fields;

    // Create subtasks
    if (childrenOverrides?.length) {
      for (const subtask of childrenOverrides) {
        subtask.entityId = parentTaskFields.entityId;
        subtask.entityName = parentTaskFields.entityName;
        subtask.entityType = parentTaskFields.entityType;
        subtask.accountType = parentTaskFields.accountType;
        subtask.parent = taskId;

        const subtaskId = await this.createTask({
          fields: subtask,
          isBulk: true
        });

        if (subtaskId) {
          await this.subtasksService.attach(taskId, subtaskId);
        }
      }
    }

    return taskId;
  }

  /**
   * Cleans up both the assigned and subscribed fields and makes sure
   * that a user doesn't exist in both lists.
   *
   * @param assignedControl
   * @param subscribedControl
   * @returns an observable that must be subscribed to
   */
  dedupeUserControls(
    assignedControl: FormControl<UserSelected[]>,
    subscribedControl: FormControl<UserSelected[]>
  ): Observable<Timestamp<UserSelected[]>[]> {
    return combineLatest([
      assignedControl.valueChanges.pipe(
        startWith(assignedControl.value),
        timestamp()
      ),
      subscribedControl.valueChanges.pipe(
        startWith(subscribedControl.value),
        timestamp()
      )
    ]).pipe(
      tap(([assigned, subscribed]) => {
        /**
         * Wrap each emission with a timestamp to check who triggered
         * the observable and who needs deduping.
         */
        const [triggerer, stale] =
          assigned.timestamp > subscribed.timestamp
            ? [assignedControl, subscribedControl]
            : [subscribedControl, assignedControl];
        triggerer.value.forEach(triggerUser => {
          const isUserDuped = stale.value?.some(
            staleUser => staleUser.userId === triggerUser.userId
          );
          if (isUserDuped) {
            const deduped = stale.value.filter(
              staleUser => staleUser.userId !== triggerUser.userId
            );
            stale.setValue(deduped, { emitEvent: false });
          }
        });
      })
    );
  }

  /**
   * Subscribes the assigner (current user) if:
   * - they're not assigned
   * - they're not subscribed to the task
   *
   * @param assignedControl
   * @param subscribedControl
   */
  subscribeAssigner(
    assignedControl: FormControl<UserSelected[]>,
    subscribedControl: FormControl<UserSelected[]>
  ): void {
    const assignerId = this.userService.currentUser.$key;
    const assigned = assignedControl.value;
    const isAssigned = assigned.some(user => user.userId === assignerId);
    const subscribed = subscribedControl.value;
    const isSubscribed = subscribed.some(user => user.userId === assignerId);

    if (!isAssigned && !isSubscribed) {
      subscribedControl.setValue(
        [...subscribed, { userId: assignerId, method: 'direct' }],
        { emitEvent: false }
      );
    }
  }
}
