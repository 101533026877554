import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AccountIconModule } from '../account-icon/account-icon.module';
import { CustomerMessagePreviewModule } from '../../customers/customer-messages/customer-message-preview/customer-message-preview.module';
import { MarkdownModule } from '../markdown/markdown.module';
import { SharedModule } from '../shared.module';
import { SharedPipesModule } from '../shared-pipes.module';
import { FeedItemBudgetChangeComponent } from './feed-item-budget-change/feed-item-budget-change.component';
import { FeedItemCommunicationComponent } from './feed-item-communication/feed-item-communication.component';
import { FeedItemCompletedTaskModule } from './feed-item-completed-task/feed-item-completed-task.module';
import { FeedItemComponent } from './feed-item/feed-item.component';
import { FeedItemMessageGroupComponent } from './feed-item-message/feed-item-message-group.component';
import { FeedItemNoteModule } from './feed-item-note/feed-item-note.module';
import { FeedItemPlanChangeComponent } from './feed-item-plan-change/feed-item-plan-change.component';
import { FeedItemStatusChangeComponent } from './feed-item-status-change/feed-item-status-change.component';
import { FeedItemStickyNoteComponent } from './feed-item-sticky-note/feed-item-sticky-note.component';
import { FeedItemSnoozedStateComponent } from './feed-item-snoozed-state/feed-item-snoozed-state.component';
import { FeedItemRoleChangeComponent } from './feed-item-role-change/feed-item-role-change.component';

@NgModule({
  imports: [
    AccountIconModule,
    CustomerMessagePreviewModule,
    FeedItemCompletedTaskModule,
    FeedItemNoteModule,
    FormsModule,
    MarkdownModule,
    RouterModule,
    SharedModule,
    SharedPipesModule
  ],
  declarations: [
    FeedItemBudgetChangeComponent,
    FeedItemCommunicationComponent,
    FeedItemComponent,
    FeedItemPlanChangeComponent,
    FeedItemMessageGroupComponent,
    FeedItemSnoozedStateComponent,
    FeedItemStatusChangeComponent,
    FeedItemStickyNoteComponent,
    FeedItemRoleChangeComponent
  ],
  exports: [FeedItemComponent, FeedItemStickyNoteComponent]
})
export class FeedItemsModule {}
