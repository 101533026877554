<ng-container *ngIf="!isGroup && optiscoreMetric">
  <div
    class="metric pointer flex flex-row items-center justify-start"
    (click)="metricInfo.toggle()"
    [satPopoverAnchor]="metricInfo"
    [ngClass]="optiscoreMetric.ragStatusColor"
  >
    <div class="metric-details metric-color flex-none">
      <strong>{{ optiscoreMetric.optiscore }}%</strong>
    </div>
    <mat-icon
      matTooltip="High Impact"
      *ngIf="
        optiscoreMetric.optiscoreWeight >
        accountDashboardService.highImpactOptiscoreWeight
      "
      class="mat-icon-16 ml-2"
      >stars</mat-icon
    >
  </div>

  <sat-popover [autoFocus]="false" [hasBackdrop]="true" #metricInfo>
    <ease-os-popover
      *ngIf="metricInfo.isOpen()"
      [account]="account"
    ></ease-os-popover>
  </sat-popover>
</ng-container>
