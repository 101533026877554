<div
  class="avatar relative flex flex-col justify-center rounded-full bg-gray-700 text-center font-bold text-white"
  [ngClass]="sizeStyle[size]"
  [attr.data-initials]="showInitials ? user?.initials : null"
>
  <div
    *ngIf="status"
    [matTooltip]="status?.mode | avatarAwayToolTip"
    [ngClass]="[showInitials ? '-top-1.5' : '-bottom-1.5']"
    class="absolute -right-1.5 z-1 p-1"
  >
    <div
      class="block h-3 w-3 rounded-full border-2"
      [ngClass]="[
        status?.mode?.away ? 'bg-gray-600' : 'bg-green-600',
        status?.borderColor ? status?.borderColor : 'border-white'
      ]"
    ></div>
  </div>
  <img
    class="block w-full rounded-full"
    [ngClass]="sizeStyle[size]"
    [class.opacity-50]="status?.mode?.away"
    [matTooltip]="showTooltip ? user?.name : null"
    [attr.src]="user?.avatar"
  />
</div>
