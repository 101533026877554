import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { Account, OsMetric } from '../../account-dashboard.interface';
import { AccountDashboardService } from '../../account-dashboard.service';
@Component({
  selector: 'ease-os-tab-content',
  templateUrl: './os-tab-content.component.html',
  styleUrls: ['./os-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OsTabContentComponent implements OnInit {
  @Input() account: Account;
  public optiscoreMetric: OsMetric;

  constructor(public accountDashboardService: AccountDashboardService) {}

  ngOnInit() {
    this.optiscoreMetric = this.account.osMetric;
  }
}
