import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ease-contact-phone-icon',
  templateUrl: './contact-phone-icon.component.html',
  styleUrls: ['./contact-phone-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactPhoneIconComponent {
  @Input()
  phoneType: string;
  @Input()
  size: number = 14;
}
