import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SatPopoverModule } from '@ncstate/sat-popover';
import { ChartsModule } from '../../../shared/charts/charts.module';
import { DashboardPopoversModule } from '../dashboard-popovers/dashboard-popovers.module';
import { MetricsModule } from '../../../shared/metrics/metrics.module';
import { SharedModule } from '../../../shared/shared.module';

import { ButSuggestedBudgetPipeModule } from '../pipes/but-suggested-budget.module';
import { ButTabContentComponent } from './but-tab-content/but-tab-content.component';
import { ComTabContentComponent } from './com-tab-content/com-tab-content.component';
import { CplTabContentComponent } from './cpl-tab-content/cpl-tab-content.component';
import { CqTabContentComponent } from './cq-tab-content/cq-tab-content.component';
import { DetailAccountRowRendererComponent } from './detail-account-row-renderer/detail-account-row-renderer.component';
import { ResTabContentComponent } from './res-tab-content/res-tab-content.component';
import { OsTabContentComponent } from './os-tab-content/os-tab-content.component';

@NgModule({
  imports: [
    ChartsModule,
    CommonModule,
    DashboardPopoversModule,
    MetricsModule,
    ReactiveFormsModule,
    SatPopoverModule,
    SharedModule,
    ButSuggestedBudgetPipeModule
  ],
  declarations: [
    ButTabContentComponent,
    ComTabContentComponent,
    CplTabContentComponent,
    CqTabContentComponent,
    DetailAccountRowRendererComponent,
    ResTabContentComponent,
    OsTabContentComponent
  ],
  exports: [DetailAccountRowRendererComponent]
})
export class DetailAccountRowsModule {}
