<ease-sheet class="flex flex-1 flex-row overflow-y-auto overflow-x-hidden">
  <div class="flex flex-1 flex-col">
    <ease-funding-manager
      #fundingManager
      [customerId]="data.customerId"
      [fundingForm]="fundingForm"
      class="flex-1 overflow-y-auto overflow-x-hidden"
    ></ease-funding-manager>
    <ease-funding-totals
      class="shadow-md"
      [fundingTotals]="fundingTotals$ | async"
    ></ease-funding-totals>
  </div>
  <ease-email-composer
    #emailComposer
    *ngIf="emailsDetailsFormArray"
    [disableVariablesEdit]="true"
    [templateId$]="templateId$"
    [layoutStyle]="'vertical'"
    [data]="data"
    [emailsFormArray]="emailsDetailsFormArray"
    (variablesLoaded)="recalculateVariables()"
    (toRecipientChange)="recalculateVariables()"
    class="flex-1"
  ></ease-email-composer>
</ease-sheet>

<ease-sheet-footer class="flex-none">
  <ng-container *ngIf="templateDetails$ | async as templateDetails">
    <a
      mat-button
      target="_blank"
      [href]="templateDetails?.Properties?.HtmlPreviewUrl"
      *ngIf="templateDetails?.Properties?.HtmlPreviewUrl"
    >
      <mat-icon>open_in_new</mat-icon> <span>View Preview</span>
    </a>
  </ng-container>
  <div class="flex-1"></div>
  <button easeWindowClose mat-button>Cancel</button>
  <button
    [disabled]="
      fundingForm.invalid ||
      fundingDetailsFormGroup.controls.availableBalance.value <
        fundingDetailsFormGroup.controls.fundingTotal.value ||
      isWorking
    "
    color="primary"
    mat-button
    (click)="createFunding()"
  >
    {{ isWorking ? 'Processing...' : 'Finalize Budget and Send Email' }}
  </button>
</ease-sheet-footer>

<ng-template #noFundingTypes>
  <div class="error-types">No funding types found</div>
</ng-template>
